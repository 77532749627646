import React, { useEffect, useState } from "react";
import {
  updateFormContentPreview,
  getFormContentPreview,
  imageUploadClientPreview,
} from "../../../../api/services/services";
import { Button, Snackbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import { Bucket } from "../../../../api/config/apiConfig";
import ContactDetailField from "../../../molecules/ContactDetailField";
import Input from "../../../atoms/Input";
import DynamicText from "../../../molecules/DynamicText";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import html2pdf from "html2pdf.js";
import { useLocation } from "react-router-dom";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

const DeliveryReceiptDownload = ({ formContent, surveyData }) => {
  const [formContentId, setFormContentId] = useState("");

  const [isEdit, setIsEdit] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const urlData = new URLSearchParams(location.search);
  const email = urlData.get("email");
  const surveyId = urlData.get("surveyId");
  const formId = urlData.get("formId");
  const token = urlData.get("token");

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // 11th, 12th, 13th, etc.
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  // Helper function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const ordinalSuffix = getOrdinalSuffix(day);

    return `${day}${ordinalSuffix} ${month}, ${year}`;
  };

  return (
    <>
      <div className="mx-4">
        {/* <MainTemplate> */}
        <div className="d-flex align-items-center justify-content-center">
          <p className="ps-0 mb-0 head-top">Delivery Receipt</p>
        </div>
        {/* file detail*/}
        <div className="contact-detail d-flex mt-2">
          {/* left col */}
          <div
            className={`left-col ${!isEdit ? " w-75" : "w-50 pe-5"} text-start`}
          >
            <Typography variant="body1" gutterBottom>
              {/* <ContactDetailField
                label="File Ref"
                isEdit={isEdit}
                type="text"
                minWidth={"120px"}
                value={formContent?.fileRef}
                disabled
              /> */}
              <div className="d-flex">
                <p
                  className="p-word mb-1 text-start"
                  style={{ minWidth: "80px", fontSize: "11px" }}
                >
                  <b>File Ref:</b>
                </p>

                <p className="p-word mb-1 text-start">
                  <span className="ms-1 ">
                    {" "}
                    {formContent?.fileRef ? formContent?.fileRef : "-"}
                  </span>
                </p>
              </div>
              {/* <ContactDetailField
                label="Customer"
                isEdit={isEdit}
                type="text"
                minWidth={"120px"}
                value={formContent?.clientName}
                
              /> */}

              <div className="d-flex">
                <p
                  className="p-word mb-1 text-start"
                  style={{ minWidth: "80px", fontSize: "11px" }}
                >
                  <b>Customer:</b>
                </p>

                <p className="p-word mb-1 text-start">
                  <span className="ms-1 ">
                    {" "}
                    {formContent?.clientName ? formContent?.clientName : "-"}
                  </span>
                </p>
              </div>
            </Typography>
          </div>
          {/* right col */}
          <div
            className={`right-col ${
              !isEdit ? "w-25" : "w-50 ps-5"
            }  d-flex flex-column align-items-end pe-2`}
          >
            <div className="">
              <Typography
                variant="body1"
                className="text-start w-100"
                gutterBottom
              >
                {/* <ContactDetailField
                  label="Date"
                  isEdit={isEdit}
                  type="date"
                  wrapperStyle="justify-content-start"
                  minWidth={"150px"}
                  value={formContent?.date}
                /> */}
                <div className="d-flex">
                  <p
                    className="p-word mb-1 text-start"
                    style={{ minWidth: "80px", fontSize: "11px" }}
                  >
                    <b>Date:</b>
                  </p>

                  <p className="p-word mb-1 text-start">
                    <span className="ms-1 ">
                      {" "}
                      {formContent?.date ? formatDate(formContent?.date) : "-"}
                    </span>
                  </p>
                </div>
                {/* <ContactDetailField 
                  label="Organization"
                  isEdit={isEdit}
                  type="text"
                  wrapperStyle="justify-content-start"
                  minWidth={"150px"}
                  value={formContent?.organization}
                /> */}

                <div className="d-flex">
                  <p
                    className="p-word mb-1 text-start"
                    style={{ minWidth: "80px", fontSize: "11px" }}
                  >
                    <b>Organization:</b>
                  </p>

                  <p className="p-word mb-1 text-start">
                    <span className="ms-1 ">
                      {" "}
                      {formContent?.organization
                        ? formContent?.organization
                        : "-"}
                    </span>
                  </p>
                </div>
              </Typography>
            </div>
          </div>
        </div>
        {/* /file detail */}

        <TableContainer component={Paper} className="mb-0 mt-2">
          <Table>
            <TableBody>
              {/* Origin Address */}
              {formContent?.originAddress?.trim()?.length > 0 && (
                <TableRow>
                  <TableCell
                    className="border p-1 w-25"
                    style={{ fontSize: "10px" }}
                  >
                    <b>Origin Address</b>
                  </TableCell>
                  <TableCell
                    className="border p-1 w-75 text-start"
                    style={{ fontSize: "10px" }}
                  >
                    {isEdit ? (
                      <Input
                        id={"shipper"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Shipper"}
                        value={formContent?.originAddress}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.originAddress
                          ? formContent?.originAddress
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              )}

              {/* destination Address */}
              {formContent?.destinationAddress?.trim()?.length > 0 && (
                <TableRow>
                  <TableCell
                    className="border p-1 w-25"
                    style={{ fontSize: "10px" }}
                  >
                    <b>Destination Address</b>
                  </TableCell>
                  <TableCell
                    className="border p-1 w-75 text-start"
                    style={{ fontSize: "10px" }}
                  >
                    {isEdit ? (
                      <Input
                        id={"shipper"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Shipper"}
                        value={formContent?.destinationAddress}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.destinationAddress
                          ? formContent?.destinationAddress
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              )}

              {/* commodity */}
              {formContent?.commodity?.trim()?.length > 0 && (
                <TableRow>
                  <TableCell
                    className="border p-1 w-25"
                    style={{ fontSize: "10px" }}
                  >
                    <b>Commodity</b>
                  </TableCell>
                  <TableCell
                    className="border p-1 w-75 text-start"
                    style={{ fontSize: "10px" }}
                  >
                    {isEdit ? (
                      <Input
                        id={"shipper"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Shipper"}
                        value={formContent?.commodity}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.commodity ? formContent?.commodity : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              )}

              {/* noOfPackages */}
              {formContent?.noOfPackages?.trim()?.length > 0 && (
                <TableRow>
                  <TableCell
                    className="border p-1 w-25"
                    style={{ fontSize: "10px" }}
                  >
                    <b>No. of Packages</b>
                  </TableCell>
                  <TableCell
                    className="border p-1 w-75 text-start"
                    style={{ fontSize: "10px" }}
                  >
                    {isEdit ? (
                      <Input
                        id={"shipper"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Shipper"}
                        value={formContent?.noOfPackages}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.noOfPackages
                          ? formContent?.noOfPackages
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              )}

              {/* modeOfShippment */}
              {formContent?.modeOfShippment?.trim()?.length > 0 && (
                <TableRow>
                  <TableCell
                    className="border p-1 w-25"
                    style={{ fontSize: "10px" }}
                  >
                    <b>Mode Of Shippment</b>
                  </TableCell>
                  <TableCell
                    className="border p-1 w-75 text-start"
                    style={{ fontSize: "10px" }}
                  >
                    {isEdit ? (
                      <Input
                        id={"shipper"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Shipper"}
                        value={formContent?.modeOfShippment}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.modeOfShippment
                          ? formContent?.modeOfShippment
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              )}

              {/* weightVolume */}
              {formContent?.weightVolume?.trim()?.length > 0 && (
                <TableRow>
                  <TableCell
                    className="border p-1 w-25"
                    style={{ fontSize: "10px" }}
                  >
                    <b>Weight/Volume</b>
                  </TableCell>
                  <TableCell
                    className="border p-1 w-75 text-start"
                    style={{ fontSize: "10px" }}
                  >
                    {isEdit ? (
                      <Input
                        id={"shipper"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Shipper"}
                        value={formContent?.weightVolume}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.weightVolume
                          ? formContent?.weightVolume
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              )}

              {/* Truck No. */}
              {formContent?.truckNo > 0 && (
                <TableRow>
                  <TableCell
                    className="border p-1 w-25"
                    style={{ fontSize: "10px" }}
                  >
                    <b>Truck No.</b>
                  </TableCell>
                  <TableCell
                    className="border p-1 w-75 text-start"
                    style={{ fontSize: "10px" }}
                  >
                    {isEdit ? (
                      <Input
                        id={"shipper"}
                        type="number"
                        style="full-border-field bg-app"
                        label={"Shipper"}
                        value={formContent?.truckNo}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>{formContent?.truckNo ? formContent?.truckNo : "-"}</>
                    )}
                  </TableCell>
                </TableRow>
              )}

              {/* container */}
              {formContent?.container && (
                <TableRow>
                  <TableCell
                    className="border p-1 w-25"
                    style={{ fontSize: "10px" }}
                  >
                    <b>Container</b>
                  </TableCell>
                  <TableCell
                    className="border p-1 w-75 text-start"
                    style={{ fontSize: "10px" }}
                  >
                    {isEdit ? (
                      <Input
                        id={"shipper"}
                        type="number"
                        style="full-border-field bg-app"
                        label={"Shipper"}
                        value={formContent?.container}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.container ? formContent?.container : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {/*  Origin Address */}
        {/* <div className="mt-5 pe-1">
          <Typography variant="body1" className="text-start" gutterBottom>
            <div className="table-text-wrapper mb-2 d-flex justify-content-between">
              <div className="w-50">
                <label htmlFor="packingDate" className="fw-bold">
                  Origin Address
                </label>
              </div>
              {!isEdit && (
                <span className="table-text w-50 text-end">
                  {formContent?.originAddress
                    ? formContent?.originAddress
                    : "-"}{" "}
                </span>
              )}
              {isEdit && (
                <Input
                  id="originAddress "
                  type="text"
                  label="Origin Address"
                  style="full-border-field"
                  value={formContent?.originAddress}
                  inline={{ width: "-webkit-fill-available" }}
                />
              )}
            </div>
          </Typography>
          <hr />
        </div> */}
        {/* / Origin Address */}

        {/*  Destination Address */}
        {/* <div className="mt-5 pe-1">
          <Typography variant="body1" className="text-start" gutterBottom>
            <div className="table-text-wrapper mb-2 d-flex justify-content-between">
              <div className="w-50">
                <label htmlFor="packingDate" className="fw-bold">
                  Destination Address
                </label>
              </div>
              {!isEdit && (
                <span className="table-text w-50 text-end">
                  {formContent?.destinationAddress
                    ? formContent?.destinationAddress
                    : "-"}
                </span>
              )}
              {isEdit && (
                <Input
                  id="estinationAddress"
                  type="text"
                  label="Destination Address"
                  style="full-border-field"
                  value={formContent?.destinationAddress}
                  inline={{ width: "-webkit-fill-available" }}
                />
              )}
            </div>
          </Typography>
          <hr />
        </div> */}
        {/* /Destination Address */}

        {/*  Commodity */}
        {/* <div className="mt-5 pe-1">
          <Typography variant="body1" className="text-start" gutterBottom>
            <div className="table-text-wrapper mb-2 d-flex justify-content-between">
              <div className="w-50">
                <label htmlFor="packingDate" className="fw-bold">
                  Commodity
                </label>
              </div>
              {!isEdit && (
                <span className="table-text w-50 text-end">
                  {formContent?.commodity ? formContent?.commodity : "-"}{" "}
                </span>
              )}
              {isEdit && (
                <Input
                  id="cmmodity"
                  type="text"
                  label="Commodity"
                  style="full-border-field"
                  value={formContent?.commodity}
                  inline={{ width: "-webkit-fill-available" }}
                />
              )}
            </div>
          </Typography>
          <hr />
        </div> */}
        {/* /Commodity */}

        {/*  No of Packages */}
        {/* <div className="mt-5 pe-1">
          <Typography variant="body1" className="text-start" gutterBottom>
            <div className="table-text-wrapper mb-2 d-flex justify-content-between">
              <div className="w-50">
                <label htmlFor="packingDate" className="fw-bold">
                  No. of Packages
                </label>
              </div>
              {!isEdit && (
                <span className="table-text w-50 text-end">
                  {formContent?.noOfPackages ? formContent?.noOfPackages : "-"}
                </span>
              )}
              {isEdit && (
                <Input
                  id="noOfPackages"
                  type="text"
                  label="No of Packages"
                  style="full-border-field"
                  value={formContent?.noOfPackages}
                  inline={{ width: "-webkit-fill-available" }}
                />
              )}
            </div>
          </Typography>
          <hr />
        </div> */}
        {/* /No of Packages */}

        {/*  Mode of Shipment */}
        {/* <div className="mt-5 pe-1">
          <Typography variant="body1" className="text-start" gutterBottom>
            <div className="table-text-wrapper mb-2 d-flex justify-content-between">
              <div className="w-50">
                <label htmlFor="packingDate" className="fw-bold">
                  Mode of Shipment
                </label>
              </div>
              {!isEdit && (
                <span className="table-text w-50 text-end">
                  {formContent?.modeOfShippment
                    ? formContent?.modeOfShippment
                    : "-"}
                </span>
              )}
              {isEdit && (
                <Input
                  id="modeOfShipment"
                  type="text"
                  label="Mode of Shipment"
                  style="full-border-field"
                  value={formContent?.modeOfShippment}
                  inline={{ width: "-webkit-fill-available" }}
                />
              )}
            </div>
          </Typography>
          <hr />
        </div> */}
        {/* /Mode of Shipment */}

        {/*  Weight/Volume */}
        {/* <div className="mt-5 pe-1">
          <Typography variant="body1" className="text-start" gutterBottom>
            <div className="table-text-wrapper mb-2 d-flex justify-content-between">
              <div className="w-50">
                <label htmlFor="packingDate" className="fw-bold">
                  Weight/Volume
                </label>
              </div>
              {!isEdit && (
                <span className="table-text w-50 text-end">
                  {formContent?.weightVolume ? formContent?.weightVolume : "-"}{" "}
                </span>
              )}
              {isEdit && (
                <Input
                  id="WeightVolume"
                  type="number"
                  label="Weight/Volume"
                  style="full-border-field"
                  value={formContent?.weightVolume}
                  inline={{ width: "-webkit-fill-available" }}
                />
              )}
            </div>
          </Typography>
          <hr />
        </div> */}
        {/* /Weight/Volume*/}

        {/*  Truck No */}
        {/* <div className="mt-5 pe-1">
          <Typography variant="body1" className="text-start" gutterBottom>
            <div className="table-text-wrapper mb-2 d-flex justify-content-between">
              <div className="w-50">
                <label htmlFor="packingDate" className="fw-bold">
                  Truck No.
                </label>
              </div>
              {!isEdit && (
                <span className="table-text w-50 text-end">
                  {formContent?.truckNo ? formContent?.truckNo : "-"}
                </span>
              )}
              {isEdit && (
                <Input
                  id="truckNo"
                  type="number"
                  label="Truck No"
                  style="full-border-field"
                  value={formContent?.truckNo}
                  inline={{ width: "-webkit-fill-available" }}
                />
              )}
            </div>
          </Typography>
          <hr />
        </div> */}
        {/* /Truck No*/}

        {/*  Container */}
        {/* <div className="mt-5 pe-1">
          <Typography variant="body1" className="text-start" gutterBottom>
            <div className="table-text-wrapper mb-2 d-flex justify-content-between">
              <div className="w-50">
                <label htmlFor="packingDate" className="fw-bold">
                  Container
                </label>
              </div>
              {!isEdit && (
                <span className="table-text w-50 text-end">
                  {formContent?.container ? formContent?.container : "-"}
                </span>
              )}
              {isEdit && (
                <Input
                  id="Container"
                  type="number"
                  label="Container"
                  style="full-border-field"
                  value={formContent?.container}
                  inline={{ width: "-webkit-fill-available" }}
                />
              )}
            </div>
          </Typography>
        </div> */}
        {/* /Container*/}

        {/* Remarks */}
        {formContent?.remarks?.trim()?.length > 0 &&
        <div className="mt-3 pe-1">
          <Typography variant="body1" className="text-start" gutterBottom>
            <DynamicText
              label="Remarks:"
              textAreaLabel="Remarks..."
              isEdit={isEdit}
              value={formContent?.remarks}
            />
          </Typography>
        </div>
        }
        {/* /Remakes */}

        {/* Signatures */}
        <Row className="mt-1">
          <Col sm={6} className="">
            <div
              style={{ height: "90px" }}
              className="d-flex justify-content-center align-items-end"
            >
              {formContent.clientSign != "" &&
                formContent.clientSign != undefined && (
                  <img
                    src={`${Bucket}${formContent.clientSign}`}
                    height={80}
                    width={200}
                  />
                )}
            </div>
            <p className="mb-0">{"_______________________________"}</p>
            <p className="my-0">
              <b>{"Customer/Authorized"}</b>
            </p>
           
            {""}
          </Col>
          <Col sm={6} className="">
            <div
              style={{ height: "90px" }}
              className="d-flex justify-content-center align-items-end"
            >
              {formContent?.adminSign?.trim()?.length == 0 ? (
                <>
                  {/* {isEdit && (
                      <div className="d-flex justify-content-center align-items-end">
                        <label
                          htmlFor="uploadSign"
                          className="active-btn ms-2 w-200 d-flex align-items-center justify-content-center cursor-pointer btn"
                        >
                          {uploadText}
                        </label>
                        <input
                          type="file"
                          hidden
                          id="uploadSign"
                          name="uploadSign"
                          onChange={(e) => {
                            if (!isLoading) uploadFile(e);
                          }}
                        />
                      </div>
                    )} */}
                </>
              ) : (
                <img
                  src={`${Bucket}${formContent.adminSign}`}
                  height={80}
                  width={200}
                />
              )}
            </div>
            <p className="mb-0">{"_______________________________"}</p>
            <p className="my-0">
              <b>{"Shelozon Movers Packers & Logistics Pvt Ltd."}</b>
            </p>
          
            {""}
          </Col>
        </Row>

        {/* </MainTemplate> */}
      </div>

      {/* snackbar */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={
            snackbarMessage?.includes("successful") ? "success" : "error"
          }
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      {/* /snackbar */}
    </>
  );
};

export default DeliveryReceiptDownload;
