import React, { useEffect, useState } from "react";
import {
  updateFormContentPreview,
  getFormContentPreview,
  imageUploadClientPreview,
} from "../../../../api/services/services";
import { Button, Snackbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import { Bucket } from "../../../../api/config/apiConfig";
import Input from "../../../atoms/Input";
import TextArea from "../../../atoms/Textarea";
import ContactDetailField from "../../../molecules/ContactDetailField";
import { useLocation } from "react-router-dom";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
// import QuotationFormDownload from "./download";
import html2pdf from "html2pdf.js";
import CustomCheckbox from "../../../atoms/CustomCheckbox";
import DynamicText from "../../../molecules/DynamicText";

const CrewInstructionDownload = ({ surveyData, formContent }) => {
  const [isEdit, setIsEdit] = useState(false);

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // 11th, 12th, 13th, etc.
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  // Helper function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const ordinalSuffix = getOrdinalSuffix(day);

    return `${day}${ordinalSuffix} ${month}, ${year}`;
  };

  return (
    <>
      <div className="mx-4">
        {/* <MainTemplate> */}
        {/* header */}
        <div className="d-block d-sm-flex align-items-center pb-1 pt-0 ">
          <div className="d-flex align-items-center justify-content-center w-100">
            <p className="ps-0 mb-0 head-top"> Crew Instruction Document</p>
          </div>
        </div>

        {/* /header */}
        {/* crew Details */}
        <div className="mt-0 pe-1 mb-4">
          <Typography variant="body1" className="text-start" gutterBottom>
            <div>
              <Typography variant="body1" gutterBottom>
                <div className="d-flex">
                  <p className="p-word mb-0 text-start" minWidth={"170px"}>
                    <b>File Ref:</b>
                  </p>

                  <p className="p-word mb-0 text-start">
                    <span className="ms-1 ">
                      {" "}
                      {formContent?.fielRef ? formContent?.fielRef : "-"}
                    </span>
                  </p>
                </div>

                {/* <ContactDetailField
                  label="File Ref"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"170px"}
                  value={formContent?.fielRef}
                  disabled={true}
                /> */}
                <p className="p-word mb-0 text-start">
                  <b>Customer Name:</b>
                  <span className="ms-1 ">
                    {" "}
                    {formContent?.customerName
                      ? formContent?.customerName
                      : "-"}
                  </span>
                </p>
                {/* <ContactDetailField
                  label="Customer Name"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"170px"}
                  value={formContent?.customerName}
                /> */}
                <p className="p-word mb-0 text-start">
                  <b> Organization:</b>
                  <span className="ms-1 ">
                    {" "}
                    {formContent?.organization
                      ? formContent?.organization
                      : "-"}
                  </span>
                </p>
                {/* <ContactDetailField
                  label="Organization"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"170px"}
                  value={formContent?.organization}
                /> */}
                <p className="p-word mb-0 text-start">
                  <b>Origin Address:</b>
                  <span className="ms-1">
                    {" "}
                    {formContent?.originAddress
                      ? formContent?.originAddress
                      : "-"}
                  </span>
                </p>
                {/* <ContactDetailField
                  label="Origin Address"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"170px"}
                  value={formContent?.originAddress}
                /> */}

                <p className="p-word mb-0 text-start">
                  <b>Destination Address:</b>
                  <span className="ms-1">
                    {" "}
                    {formContent?.destinationAddress
                      ? formContent?.destinationAddress
                      : "-"}
                  </span>
                </p>
                {/* <ContactDetailField
                  label="Destination Address"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"170px"}
                  value={formContent?.destinationAddress}
                /> */}

                <p className="p-word mb-0 text-start">
                  <b>Date:</b>
                  <span className="ms-1">
                    {" "}
                    {formContent?.date ? formatDate(formContent?.date) : "-"}
                  </span>
                </p>

                <p className="p-word mb-0 text-start">
                  <b>Document Date:</b>
                  <span className="ms-1">
                    {" "}
                    {formContent?.date
                      ? formatDate(formContent?.documentdate)
                      : "-"}
                  </span>
                </p>
              </Typography>
            </div>
          </Typography>
        </div>
        {/* /crew Details */}

        {/* Description of Services */}
        <Typography variant="body1" className="text-start" gutterBottom>
          <div className="mb-2">
            <div className="d-flex justify-content-center">
              <label
                htmlFor="Description of Services"
                className="fw-bold mb-2"
                style={{ fontSize: "12px" }}
              >
                Description of Services
              </label>
            </div>
            <div className="row crew">
              <div className="col-3 mb-0">
                <CustomCheckbox
                  style="mb-1"
                  id="preMoveSurvey"
                  label="Pre Move Survey"
                  value={formContent?.preMoveSurcey}
                  path={""}
                  disabled={!isEdit ? true : false}
                />
              </div>
              <div className="col-3 mb-0">
                <CustomCheckbox
                  style="mb-1"
                  id="localMove"
                  label="Local Move"
                  value={formContent?.localMove}
                  path={""}
                  disabled={!isEdit ? true : false}
                />
              </div>
              <div className="col-3 mb-0">
                <CustomCheckbox
                  style="mb-1"
                  id="InternationalMove"
                  label="International Move"
                  value={formContent?.internationalMove}
                  path={""}
                  disabled={!isEdit ? true : false}
                />
              </div>
              <div className="col-3 mb-0">
                <CustomCheckbox
                  style="mb-1"
                  id="Road"
                  label="Road"
                  value={formContent?.road}
                  path={""}
                  disabled={!isEdit ? true : false}
                />
              </div>
              <div className="col-3 mb-0">
                <CustomCheckbox
                  style="mb-1"
                  id="Assembling"
                  label="Assembling"
                  value={formContent?.assembling}
                  path={""}
                  disabled={!isEdit ? true : false}
                />
              </div>
              <div className="col-3 mb-0">
                <CustomCheckbox
                  style="mb-1"
                  id="debrisRemoval"
                  label="Debris Removal"
                  value={formContent?.debrisRemoval}
                  path={""}
                  disabled={!isEdit ? true : false}
                />
              </div>
              <div className="col-3 mb-0">
                <CustomCheckbox
                  style="mb-1"
                  id="Payments"
                  label="Payments"
                  value={formContent?.payments}
                  path={""}
                  disabled={!isEdit ? true : false}
                />
              </div>
              <div className="col-3 mb-0">
                <CustomCheckbox
                  style="mb-1"
                  id="Documents"
                  label="Documents"
                  value={formContent?.documents}
                  path={""}
                  disabled={!isEdit ? true : false}
                />
              </div>
              <div className="col-3 mb-0">
                <CustomCheckbox
                  style="mb-1"
                  id="Air"
                  label="Air"
                  value={formContent?.air}
                  path={""}
                  disabled={!isEdit ? true : false}
                />
              </div>
              <div className="col-3 mb-0">
                <CustomCheckbox
                  style="mb-1"
                  id="Unloading"
                  label="Unloading"
                  value={formContent?.unloading}
                  path={""}
                  disabled={!isEdit ? true : false}
                />
              </div>
              <div className="col-3 mb-0">
                <CustomCheckbox
                  style="mb-1"
                  id="Loading"
                  label="Loading"
                  value={formContent?.loading}
                  path={""}
                  disabled={!isEdit ? true : false}
                />
              </div>
              <div className="col-3 mb-0">
                <CustomCheckbox
                  style="mb-1"
                  id="Invoice"
                  label="Invoice"
                  value={formContent?.invoice}
                  path={""}
                  disabled={!isEdit ? true : false}
                />
              </div>
              <div className="col-3 mb-0">
                <CustomCheckbox
                  style="mb-1"
                  id="Delivery"
                  label="Delivery"
                  value={formContent?.delivery}
                  path={""}
                  disabled={!isEdit ? true : false}
                />
              </div>
              <div className="col-3 mb-0">
                <CustomCheckbox
                  style="mb-1"
                  id="Sea"
                  label="Sea"
                  value={formContent?.sea}
                  path={""}
                  disabled={!isEdit ? true : false}
                />
              </div>
              <div className="col-3 mb-0">
                <CustomCheckbox
                  style="mb-1"
                  id="Packing"
                  label="Packing"
                  value={formContent?.packing}
                  path={""}
                  disabled={!isEdit ? true : false}
                />
              </div>

              <div className="col-3 mb-0">
                <CustomCheckbox
                  style="mb-1"
                  id="Unpacking"
                  label="Unpacking"
                  value={formContent?.unpacking}
                  path={""}
                  disabled={!isEdit ? true : false}
                />
              </div>
            </div>
          </div>
        </Typography>
        {/* /Description of Services */}
        {/* date and time*/}
        <div className="contact-detail d-flex mt-3 download">
          {/* left col */}
          <div className={`left-col ${!isEdit ? " w-50" : "w-50"} text-start`}>
            <Typography variant="body1" gutterBottom style={{ width: "80%" }}>
              <ContactDetailField
                label="Job Start Date"
                isEdit={isEdit}
                type="date"
                value={formContent?.jobStartDate}
                minWidth={"125px"}
              />
              <ContactDetailField
                label="Job End Date"
                isEdit={isEdit}
                type="date"
                value={formContent?.jobEndDate}
                minWidth={"125px"}
              />
            </Typography>
          </div>
          {/* right col */}
          <div
            className={`right-col ${
              !isEdit ? "w-50 pe-5 " : "w-50 pe-2"
            }  d-flex flex-column align-items-end justify-content-end text-end`}
          >
            {isEdit ? (
              <Typography
                variant="body1"
                className="text-start "
                gutterBottom
                style={{ width: "80%" }}
              >
                <ContactDetailField
                  label="Job Start Time"
                  isEdit={isEdit}
                  type="time"
                  value={formContent?.jobStartTime}
                  minWidth={"125px"}
                />
                <ContactDetailField
                  label="Job End Time"
                  isEdit={isEdit}
                  type="time"
                  value={formContent?.jobEndTime}
                  minWidth={"125px"}
                />
              </Typography>
            ) : (
              <div>
                <Typography
                  variant="body1"
                  className="text-start "
                  gutterBottom
                  style={{ width: "80%" }}
                >
                  <ContactDetailField
                    label="Job Start Time"
                    isEdit={isEdit}
                    type="time"
                    value={formContent?.jobStartTime}
                    minWidth={"90px"}
                  />
                  <ContactDetailField
                    label="Job End Time"
                    isEdit={isEdit}
                    type="time"
                    value={formContent?.jobEndTime}
                    minWidth={"90px"}
                  />
                </Typography>
              </div>
            )}
          </div>
        </div>

        {/* /date and time */}
        {/* Standard Exclusions */}
        {formContent?.exclusions?.trim()?.length != 0 && (
          <div className="mt-3 pe-1 mb-5 download-standard">
            <Typography
              variant="body1"
              className="text-start "
              gutterBottom
              style={{ fontSize: "11px" }}
            >
              <DynamicText
                label="Remarks:"
                textAreaLabel="Remarks..."
                isEdit={isEdit}
                value={formContent?.exclusions}
              />
            </Typography>
          </div>
        )}
        {/* /Standard Exclusions */}

        {/* Signatures */}
        <Row className="mt-5download-standard">
          <Col sm={6} className="mt-5">
            <div
              style={{ height: "90px" }}
              className="d-flex justify-content-center align-items-end"
            >
              {formContent?.moveCordinatorSign?.trim()?.length == 0 ? (
                <></>
              ) : (
                <img
                  src={`${Bucket}${formContent?.moveCordinatorSign}`}
                  height={80}
                  width={200}
                />
              )}
            </div>

            <p className="mb-0">{"_______________________________"}</p>
            <p className="my-0">
              <b>{"Move Coordinator"}</b>
            </p>

            {""}
          </Col>
          <Col sm={6} className="mt-5">
            <div
              style={{ height: "90px" }}
              className="d-flex justify-content-center align-items-end"
            >
              {formContent?.packingManagerSign?.trim()?.length == 0 ? (
                <></>
              ) : (
                <img
                  src={`${Bucket}${formContent.packingManagerSign}`}
                  height={80}
                  width={200}
                />
              )}
            </div>
            <p className="mb-0">{"_______________________________"}</p>
            <p className="my-0">
              <b>{"Packing Manager"}</b>
            </p>

            {""}
          </Col>

          {/* {formContent?.clients?.map((cli, ind) => {
            return (
              <Col sm={6} className="mt-5">
                <div style={{ height: "90px" }}>
                  {cli.sign != "" && cli.sign != undefined && (
                    <img src={`${Bucket}${cli.sign}`} height={80} width={140} />
                  )}
                </div>

                <p>{"_______________________________"}</p>
                <p className="my-1">
                  <b>{cli?.email}</b>
                </p>
                <p className="mt-0">{"(Sign Required)"}</p>
                {""}
              </Col>
            );
          })} */}
        </Row>
      </div>
    </>
  );
};

export default CrewInstructionDownload;
