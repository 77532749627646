import React, { useEffect, useState } from "react";
import {
  getSingleSurvey,
  addFormContent,
  updateFormContent,
  getFormContent,
  surveyResultPreview,
  imageUpload,
} from "../../../api/services/services";
import backArrow from "../../../imgs/BackArrow.svg";
import MainTemplate from "../../templates/MainTemplate";
import { useNavigate } from "react-router-dom";
import { Button, Snackbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import { Bucket } from "../../../api/config/apiConfig";
import Input from "../../atoms/Input";
import ContactDetailField from "../../molecules/ContactDetailField";
import "./style.css";
import { Table, Box, CircularProgress } from "@mui/material";
import DynamicText from "../../molecules/DynamicText";
import { useParams } from "react-router-dom";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import html2pdf from "html2pdf.js";
import removeImg from "../../../imgs/remove.png";
import PrintComponent from "../PrintComponent";
import WeightTicketDownload from "./download";
import editIcn from "../../../imgs/edit.png";
import trashIcn from "../../../imgs/trash.png";
import LoadingModal from "../../molecules/LoadingModal";
import moment from "moment";
import MySignatureCanvas from "../../molecules/signature_canvas";
import SuperAdminVerifationModal from "../PrebidAgreement/creation/super_admin_verification_modal";
import { sendSuperAdminVerificationForEdit } from "../PrebidAgreement/creation/service";

const WeightTicketFormPage = () => {
  const { surveyId, id, flg } = useParams();
  const [surveyData, setSurveyData] = useState(null);
  const navigate = useNavigate();

  const [formContentId, setFormContentId] = useState("");
  const [isEdit, setIsEdit] = useState(flg == "1" ? true : false);

  const [isLoading, setIsLoading] = useState(false);
  const [formContent, setFormContent] = useState({
    dataRows: [
      {
        noOfPieces: "",
        grossWeight: 0,
        netWeight: 0,
        volWeight: 0,
        length: 0,
        width: 0,
        height: 0,
        cft: 0,
        cbm: 0,
        CFTbyCBM: 0,
      },
    ],
    customerName: "",
    customerEmail: "",
    refNo: "",
    airShippment: "Islamabad",
    date: "",
    originAddress: "",
    destination: "",
    shipmentMode: "",
    belongsToA: "",
    belongsToB: "",
    weightMasterName: "",
    weightMasterSign: "",
  });

  var totalNoOfPieces = 0;
  var totalGrossWeight = 0;
  var totalNetWeight = 0;
  var totalVolWeight = 0;
  var totalCFT = 0;
  var totalCBM = 0;
  var CMS = 0;
  var totalCFTbyCBM = 0;
  var totalCMS = 0;

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleAddNewDataRows = () => {
    let fC = { ...formContent };
    const values = [...formContent?.dataRows];

    values.push({
      noOfPieces: "",
      grossWeight: 0,
      netWeight: 0,
      volWeight: 0,
      length: 0,
      width: 0,
      height: 0,
      cft: 0,
      cbm: 0,
      CFTbyCBM: 0,
    });
    fC.dataRows = values;

    setFormContent(fC);
  };

  const handleRemoveDataRows = (ind) => {
    let fC = { ...formContent };
    const values = [...formContent?.dataRows];
    values.splice(ind, 1);
    fC.dataRows = values;
    setFormContent(fC);
  };

  //
  const getSurveyPreview = () => {
    surveyResultPreview(surveyId)
      .then((response) => {
        setSurveyData(response?.data?.data?.surveyDetail);
        let values = { ...formContent };
        values.customerName = `${response?.data?.data?.surveyDetail?.name}`;
        values.customerEmail = `${response?.data?.data?.surveyDetail?.email}`;
        values.refNo = `${response?.data?.data?.surveyDetail?.id}`;
        values.originAddress = `${response?.data?.data?.surveyDetail?.loadPlaceStAddress}, ${response?.data?.data?.surveyDetail?.loadPlaceCity}, ${response?.data?.data?.surveyDetail?.loadPlaceCountry}`;
        values.destination = `${response?.data?.data?.surveyDetail?.unloadPlaceStAddress}, ${response?.data?.data?.surveyDetail?.unloadPlaceCity}, ${response?.data?.data?.surveyDetail?.unloadPlaceCountry}`;

        getFormContent("be5690e1-df05-4791-a214-2235c757f0f3", surveyId)
          .then((res) => {
            values.shipmentMode = res?.data?.data?.content?.transportationMode
              ? res?.data?.data?.content?.transportationMode
              : "";

            setFormContent(values);
            setIsLoading(false);
          })
          .catch((error) => {
            // setSnackbarMessage(error?.response?.data?.message);
            // setOpenSnackbar(true);

            if (error?.response?.data?.statusCode == 401) {
              localStorage.clear();
              window.location.pathname = "/login";
            }

            setIsLoading(false);
            setFormContent(values);
          });
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }
      });
  };

  //
  const getSurvey = () => {
    getSingleSurvey(surveyId)
      .then((response) => {
        if (response?.data?.data?.status == "submitted") getSurveyPreview();
        else {
          setSurveyData(response?.data?.data);
          let values = { ...formContent };
          values.customerName = `${response?.data?.data?.name}`;
          values.customerEmail = `${response?.data?.data?.email}`;
          values.refNo = `${response?.data?.data?.id}`;
          values.originAddress = `${response?.data?.data?.loadPlaceStAddress}, ${response?.data?.data?.loadPlaceCity}, ${response?.data?.data?.loadPlaceCountry}`;
          values.destination = `${response?.data?.data?.unloadPlaceStAddress}, ${response?.data?.data?.unloadPlaceCity}, ${response?.data?.data?.unloadPlaceCountry}`;

          getFormContent("be5690e1-df05-4791-a214-2235c757f0f3", surveyId)
            .then((res) => {
              values.shipmentMode = res?.data?.data?.content?.transportationMode
                ? res?.data?.data?.content?.transportationMode
                : "";

              setFormContent(values);
              setIsLoading(false);
            })
            .catch((error) => {
              // setSnackbarMessage(error?.response?.data?.message);
              // setOpenSnackbar(true);

              if (error?.response?.data?.statusCode == 401) {
                localStorage.clear();
                window.location.pathname = "/login";
              }

              setIsLoading(false);
              setFormContent(values);
              setMediaKeysToRemove([]);
            });
        }
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }
      });
  };

  //
  const addContent = () => {
    setIsLoading(true);
    const body = {
      formId: id,
      surveyId: surveyId,
      content: formContent,
      mediaKeys: mediaKeysToSend,
    };
    addFormContent(body)
      .then(() => {
        setSnackbarMessage("Weight Ticket Form has been added successfully!");
        setOpenSnackbar(true);
        navigate(-1);

        setIsLoading(false);

        setMediaKeysToSend([]);
        setMediaKeysToRemove([]);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  //
  const updateContent = () => {
    setIsLoading(true);
    const body = {
      formId: id,
      surveyId: surveyId,
      content: formContent,
      mediaKeys: mediaKeysToSend,
      removedMediaKeys: mediaKeysToRemove,
    };
    updateFormContent(body, formContentId)
      .then((res) => {
        setSnackbarMessage("Weight Ticket Form has been updated successfully!");
        setOpenSnackbar(true);
        getContent();
        setIsEdit(false);

        setIsLoading(false);
        setMediaKeysToSend([]);
        setMediaKeysToRemove([]);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  //
  const getContent = () => {
    setIsLoading(true);
    getFormContent(id, surveyId)
      .then((res) => {
        setFormContentId(res.data.data.uuid);
        setFormContent(res.data.data.content);
        setIsLoading(false);
        setMediaKeysToRemove([]);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
        setMediaKeysToRemove([]);
      });
  };

  useEffect(() => {
    if (flg == "1") getSurvey();
    else getContent();
  }, []);

  const [show, setShow] = useState(false);
  const options = {
    // resolution: Resolution.HIGH,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      format: "letter",
      // default is 'portrait'
      orientation: "portrait",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/png",
      qualityRatio: 1,
    },
    overrides: {
      pdf: {
        compress: true,
      },
    },
  };

  //
  const generatePdf = () => {
    setShow(true);

    setTimeout(() => {
      const content = document.getElementById("content-to-convert"); // Replace with the ID of the element you want to convert to PDF

      const currentDateTime = new Date();
      const filename = `survey_${currentDateTime.toISOString()}.pdf`;

      const pdfOptions = {
        margin: 5,
        filename: filename,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
        }, // Set the page size here
      };

      let temp = html2pdf().from(content).set(pdfOptions).outputPdf();
      temp.save(filename);

      setShow(false);
    }, 3500);
  };

  const [downloadText, setDownloadText] = useState("download");

  const getTargetElement = () => document.getElementById("content-id");

  const printAction = () => {
    const PAGE_HEIGHT = 1045;
    const printElement = document.getElementById("print-component");
    if (printElement) {
      printElement.classList.add("temp-class-for-height");
      const height = printElement.clientHeight;
      const numberOfPage = Math.ceil(height / PAGE_HEIGHT);
      const heightWithSingleHeader = numberOfPage * PAGE_HEIGHT;
      let requiredHeight = heightWithSingleHeader;
      if (numberOfPage > 1) {
        const headerHeight =
          printElement.getElementsByTagName("thead")?.[0]?.clientHeight;
        const footerHeight =
          printElement.getElementsByTagName("tfoot")?.[0]?.clientHeight;
        requiredHeight -= (numberOfPage - 1) * (headerHeight + footerHeight);
      }
      printElement.style.height = `${requiredHeight}px`;
      printElement.classList.remove("temp-class-for-height");
    }
    window.print();
    if (printElement) {
      printElement.style.height = `auto`;
    }
  };

  const [uploadText, setUploadText] = useState("Upload Sign");

  const [mediaKeysToSend, setMediaKeysToSend] = useState([]);
  const [mediaKeysToRemove, setMediaKeysToRemove] = useState([]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isSignCanvas, setSignCanvas] = useState(false);

  //
  const uploadFile = async (file) => {
    setIsLoading(true);

    handleOpen();
    setUploadText("Uploading...");
    const fd = new FormData();
    fd.append("image", file);

    imageUpload(fd)
      .then((res) => {
        let values = { ...formContent };
        if (formContent.weightMasterSign?.trim()?.length != 0) {
          let dlt = [...mediaKeysToRemove];
          dlt.push(values.weightMasterSign);
          setMediaKeysToRemove(dlt);
        }

        values.weightMasterSign = res?.data?.data?.key;
        setFormContent(values);

        let md = [...mediaKeysToSend];
        md.push(res?.data?.data?.key);
        setMediaKeysToSend(md);

        setIsLoading(false);
        setUploadText("Upload Sign");
        handleClose();
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setUploadText("Upload Sign");

        setIsLoading(false);
        handleClose();
      });
  };

  const [isVerified, setIsVerified] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);

  const sendEditRequestToAdmin = () => {
    setIsEditLoading(true);
    sendSuperAdminVerificationForEdit()
      .then((response) => {
        setIsVerified(true);
        setIsEditLoading(false);
      })
      .catch((error) => {
        setIsVerified(false);
        setIsEditLoading(false);
      });
  };

  return (
    <div>
      <MainTemplate>
        <div className="no-print">
          <div>
            <div className="d-block d-sm-flex align-items-center py-4">
              <div className="d-flex align-items-center ">
                <div
                  className="light-blue-color back-bg cursor-pointer"
                  onClick={() => navigate(-1)}
                >
                  <img src={backArrow} height="24" width="24" />{" "}
                </div>
                <div>
                  <p className="main-head ps-3 mb-0">Weight Ticket</p>
                </div>
              </div>
              <div className="d-flex ms-auto justify-content-center mt-2 mt-sm-0">
                {/*Edit Button */}
                {!isEdit && (
                  <Button
                    className="active-btn"
                    onClick={() => {
                      sendEditRequestToAdmin();
                    }}
                    disabled={isEditLoading}
                  >
                    Edit
                  </Button>
                )}

                {/* Cancel button */}
                {isEdit && flg == "2" && (
                  <Button
                    className="active-btn ms-3"
                    onClick={() => {
                      getContent();
                      setIsEdit(false);
                    }}
                    disabled={isLoading}
                  >
                    Cancel
                  </Button>
                )}

                {/* Save Button */}
                {isEdit && (
                  <Button
                    className="active-btn ms-3"
                    onClick={() => {
                      if (flg == "1") addContent();
                      else updateContent();
                    }}
                    disabled={isLoading}
                  >
                    Save
                  </Button>
                )}

                {/* Download Button */}
                {!isEdit && (
                  <Button
                    className="active-btn ms-3 px-0"
                    onClick={() => {
                      setIsLoading(true);
                      // printAction();
                      var tempTitle = document.title;
                      document.title = `Weight Ticket-${
                        formContent?.customerName
                      }-${moment(new Date()).format("DD.MM.YYYY")}`;

                      window.print();
                      document.title = tempTitle;
                      setIsLoading(false);
                    }}
                    disabled={isLoading}
                  >
                    Save as PDF
                  </Button>
                )}
              </div>
            </div>
          </div>
          {/* contact detail*/}
          <div className="contact-detail row mt-2">
            {/* left col */}
            <div
              className={`left-col ${
                !isEdit ? " w-50" : "w-50 pe-5"
              } text-start`}
            >
              <Typography variant="body1" gutterBottom>
                <ContactDetailField
                  label="Our Ref"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"120px"}
                  value={formContent?.refNo}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.refNo = e.target.value;
                    setFormContent(values);
                  }}
                  disabled
                />
                {/* <ContactDetailField
                  label="Air Shipment"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"120px"}
                  value={formContent?.airShippment}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.airShippment = e.target.value;
                    setFormContent(values);
                  }}
                /> */}
                <ContactDetailField
                  label="Date"
                  isEdit={isEdit}
                  type="date"
                  minWidth={"120px"}
                  value={formContent?.date}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.date = e.target.value;
                    setFormContent(values);
                  }}
                />
              </Typography>
            </div>
            {/* right col */}
            <div
              className={`right-col ${
                !isEdit ? "w-50" : "w-50 ps-5"
              }  d-flex flex-column align-items-end pe-2`}
            >
              <div
                className=""
                style={isEdit ? { width: "-webkit-fill-available" } : {}}
              >
                <Typography
                  variant="body1"
                  className="text-start w-100"
                  gutterBottom
                >
                  <ContactDetailField
                    label="Origin Address"
                    isEdit={isEdit}
                    type="text"
                    wrapperStyle="justify-content-start"
                    value={formContent?.originAddress}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.originAddress = e.target.value;
                      setFormContent(values);
                    }}
                    minWidth={"130px"}
                  />
                  <ContactDetailField
                    label="Destination"
                    isEdit={isEdit}
                    type="text"
                    wrapperStyle="justify-content-start"
                    value={formContent?.destination}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.destination = e.target.value;
                      setFormContent(values);
                    }}
                    minWidth={"130px"}
                  />
                  <ContactDetailField
                    label="Shipment Mode"
                    isEdit={isEdit}
                    type="text"
                    wrapperStyle="justify-content-start"
                    value={formContent?.shipmentMode}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.shipmentMode = e.target.value;
                      setFormContent(values);
                    }}
                    minWidth={"130px"}
                  />
                </Typography>
              </div>
            </div>
          </div>
          {/* /contact detail */}

          <div className="mt-5 pe-1 d-flex justify-content-start flex-wrap align-items-baseline">
            <div>
              <p className="mb-2">
                <b>
                  This is to certify that the air shipment of used household
                  goods & personal effects belonging to
                </b>
              </p>
            </div>
            {isEdit ? (
              <input
                type={"text"}
                // className={`${style || "input-field "}`}
                className={`mx-3 input-field`}
                // placeholder={label}
                value={formContent?.belongsToA}
                onChange={(e) => {
                  let values = { ...formContent };
                  values.belongsToA = e.target.value;
                  setFormContent(values);
                }}
                style={{ width: "300px" }}
              />
            ) : (
              <div>
                <p className="mb-2 mx-2 text-decoration-underline">
                  <b>{formContent?.belongsToA}</b>
                </p>
              </div>
            )}
            <div>
              <p className="mb-2">
                <b>C/O</b>
              </p>
            </div>
            {isEdit ? (
              <input
                type={"text"}
                // className={`${style || "input-field "}`}
                className={`mx-3 input-field`}
                // placeholder={label}
                value={formContent?.belongsToB}
                onChange={(e) => {
                  let values = { ...formContent };
                  values.belongsToB = e.target.value;
                  setFormContent(values);
                }}
                style={{ width: "300px" }}
              />
            ) : (
              <div>
                <p className="mb-2 mx-2 text-decoration-underline">
                  <b>{formContent?.belongsToB}</b>
                </p>
              </div>
            )}
            <div>
              <p className="mb-2">
                <b>{formContent?.originAddress}.</b>
              </p>
            </div>
          </div>

          {/* DynamicText */}
          {/* <div className="mt-5 pe-1 mb-5">
          <Typography variant="body1" className="text-start" gutterBottom>
            <DynamicText
              label="This is to certify that the air shipment of used household goods & personal effects belonging to:"
              value="is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500sis simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
              isEdit={isEdit}
            />
          </Typography>
        </div> */}
          {/* /DynamicText */}

          {formContent?.dataRows.map((row, index) => {
            totalNoOfPieces = Number(totalNoOfPieces) + Number(row?.noOfPieces);
            totalGrossWeight =
              Number(totalGrossWeight) + Number(row?.grossWeight);
            totalNetWeight = Number(totalNetWeight) + Number(row?.netWeight);
            totalVolWeight = Number(totalVolWeight) + Number(row?.volWeight);
            totalCFT = Number(totalCFT) + Number(row?.cft);
            totalCBM = Number(totalCBM) + Number(row?.cbm);
            totalCFTbyCBM = Number(totalCFTbyCBM) + Number(row?.CFTbyCBM);
            totalCMS =
              Number(totalCMS) +
              Number(row?.length) * Number(row?.width) * Number(row?.height);

            return <></>;
          })}

          {/* table */}
          <Typography variant="body1" className="text-start mt-4" gutterBottom>
            <label htmlFor="" className="fw-bold mb-3">
              Measuring & weights are as follows:
            </label>
          </Typography>

          {/*  table */}
          <div className="overflow-x-auto mb-5 Weight-Ticket-Table">
            <Table
              responsive
              // striped
              // bordered
              // hover
              style={{ overflowX: "auto" }}
            >
              {/* Table Header */}
              <thead className="bg-white">
                <tr>
                  <th className="border p-3 text-start min-w-150">
                    No of Pieces
                  </th>
                  <th className="border p-3 text-start min-w-100">
                    Gross weight in KGS
                  </th>
                  <th className="border p-3 text-start min-w-100">
                    Net weight in KGS
                  </th>
                  <th className="border p-3 text-start min-w-100">
                    Vol weight in KGS
                  </th>
                  <th className="border p-3 text-center" colSpan={3}>
                    Size In Inches
                  </th>
                  <th className="border p-3 min-w-100 text-start">CFT/CBM</th>
                  {isEdit && <th className="border p-3"></th>}
                </tr>
              </thead>
              <tbody className="bg-white">
                {formContent?.dataRows?.length > 0 ? (
                  <>
                    {formContent?.dataRows.map((row, index) => (
                      <tr key={index}>
                        <td className="border p-3 text-start">
                          {isEdit ? (
                            <Input
                              id="condition"
                              label="No of pieces"
                              type="number"
                              style="full-border-field"
                              value={row?.noOfPieces}
                              onChange={(e) => {
                                const values = { ...formContent };
                                const valuesRows = [...formContent?.dataRows];
                                valuesRows[index].noOfPieces = e.target.value;
                                values.dataRows = valuesRows;
                                setFormContent(values);
                              }}
                              inline={{ width: "-webkit-fill-available" }}
                            />
                          ) : row.noOfPieces ? (
                            row.noOfPieces
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className="border p-3 text-start">
                          {isEdit ? (
                            <Input
                              id="condition"
                              label="Gross weight"
                              type="number"
                              style="full-border-field"
                              value={row?.grossWeight}
                              onChange={(e) => {
                                const newValue = e.target.value.replace(
                                  /^0+/,
                                  ""
                                );
                                const values = { ...formContent };
                                const valuesRows = [...formContent?.dataRows];
                                valuesRows[index].grossWeight =
                                  newValue != "" ? newValue : 0;
                                values.dataRows = valuesRows;
                                setFormContent(values);
                              }}
                              inline={{ width: "-webkit-fill-available" }}
                            />
                          ) : row.grossWeight ? (
                            `${row.grossWeight} KGS`
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className="border p-3 text-start">
                          {isEdit ? (
                            <Input
                              id="condition"
                              label="Net weight"
                              type="number"
                              style="full-border-field"
                              value={row?.netWeight}
                              onChange={(e) => {
                                const newValue = e.target.value.replace(
                                  /^0+/,
                                  ""
                                );
                                const values = { ...formContent };
                                const valuesRows = [...formContent?.dataRows];
                                valuesRows[index].netWeight =
                                  newValue != "" ? newValue : 0;
                                values.dataRows = valuesRows;
                                setFormContent(values);
                              }}
                              inline={{ width: "-webkit-fill-available" }}
                            />
                          ) : row.netWeight ? (
                            `${row.netWeight} KGS`
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className="border p-3 text-start">
                          {isEdit ? (
                            <Input
                              id="condition"
                              label="Vol weight"
                              type="number"
                              style="full-border-field"
                              value={row?.volWeight}
                              onChange={(e) => {
                                const newValue = e.target.value.replace(
                                  /^0+/,
                                  ""
                                );
                                const values = { ...formContent };
                                const valuesRows = [...formContent?.dataRows];
                                valuesRows[index].volWeight =
                                  newValue != "" ? newValue : 0;
                                values.dataRows = valuesRows;
                                setFormContent(values);
                              }}
                              inline={{ width: "-webkit-fill-available" }}
                            />
                          ) : row.volWeight ? (
                            `${row.volWeight} KGS`
                          ) : (
                            "-"
                          )}
                        </td>
                        {/* Size In CMS columns */}
                        <td className="border p-3 text-start">
                          {isEdit ? (
                            <Input
                              id="condition"
                              label="Length in inches"
                              type="number"
                              style="full-border-field"
                              value={row?.length}
                              onChange={(e) => {
                                const newValue = e.target.value.replace(
                                  /^0+/,
                                  ""
                                );
                                const values = { ...formContent };
                                const valuesRows = [...formContent?.dataRows];
                                valuesRows[index].length =
                                  newValue != "" ? newValue : 0;
                                // valuesRows[index].cft =
                                //   (Number(newValue) *
                                //     Number(valuesRows[index].height) *
                                //     Number(valuesRows[index].width)) /
                                //   28316.846;
                                // valuesRows[index].cbm =
                                //   (Number(newValue) *
                                //     Number(valuesRows[index].height) *
                                //     Number(valuesRows[index].width)) /
                                //   1000000;
                                valuesRows[index].cft =
                                  (Number(newValue) *
                                    Number(valuesRows[index].height) *
                                    Number(valuesRows[index].width)) /
                                  1728;
                                valuesRows[index].cbm =
                                  valuesRows[index].cft / 35;

                                values.dataRows = valuesRows;
                                setFormContent(values);
                              }}
                              inline={{ width: "-webkit-fill-available" }}
                            />
                          ) : row.length ? (
                            `L : ${row.length}″`
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className="border p-3 text-start">
                          {isEdit ? (
                            <Input
                              id="condition"
                              label="Width in inches"
                              type="number"
                              style="full-border-field"
                              value={row?.width}
                              onChange={(e) => {
                                const newValue = e.target.value.replace(
                                  /^0+/,
                                  ""
                                );
                                const values = { ...formContent };
                                const valuesRows = [...formContent?.dataRows];
                                valuesRows[index].width =
                                  newValue != "" ? newValue : 0;
                                // valuesRows[index].cft =
                                //   (Number(newValue) *
                                //     Number(valuesRows[index].height) *
                                //     Number(valuesRows[index].length)) /
                                //   28316.846;
                                // valuesRows[index].cbm =
                                //   (Number(newValue) *
                                //     Number(valuesRows[index].height) *
                                //     Number(valuesRows[index].length)) /
                                //   1000000;
                                valuesRows[index].cft =
                                  (Number(newValue) *
                                    Number(valuesRows[index].height) *
                                    Number(valuesRows[index].length)) /
                                  1728;
                                valuesRows[index].cbm =
                                  valuesRows[index].cft / 35;
                                values.dataRows = valuesRows;
                                setFormContent(values);
                              }}
                              inline={{ width: "-webkit-fill-available" }}
                            />
                          ) : row.width ? (
                            `W : ${row.width}″`
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className="border p-3 text-start">
                          {isEdit ? (
                            <Input
                              id="condition"
                              label="Height in inches"
                              type="number"
                              style="full-border-field"
                              value={row?.height}
                              onChange={(e) => {
                                const newValue = e.target.value.replace(
                                  /^0+/,
                                  ""
                                );
                                const values = { ...formContent };
                                const valuesRows = [...formContent?.dataRows];
                                valuesRows[index].height =
                                  newValue != "" ? newValue : 0;
                                // valuesRows[index].cft =
                                //   (Number(newValue) *
                                //     Number(valuesRows[index].length) *
                                //     Number(valuesRows[index].width)) /
                                //   28316.846;
                                // valuesRows[index].cbm =
                                //   (Number(newValue) *
                                //     Number(valuesRows[index].length) *
                                //     Number(valuesRows[index].width)) /
                                //   1000000;
                                valuesRows[index].cft =
                                  (Number(newValue) *
                                    Number(valuesRows[index].length) *
                                    Number(valuesRows[index].width)) /
                                  1728;
                                valuesRows[index].cbm =
                                  valuesRows[index].cft / 35;
                                values.dataRows = valuesRows;
                                setFormContent(values);
                              }}
                              inline={{ width: "-webkit-fill-available" }}
                            />
                          ) : row.height ? (
                            `H : ${row.height}″`
                          ) : (
                            "-"
                          )}
                        </td>
                        {/* End of Size In CMS columns */}
                        <td className="border p-3 text-start">
                          {isEdit ? (
                            <Input
                              id="condition"
                              label="CFT/CBM"
                              type="text"
                              style="full-border-field"
                              value={`${row?.cft?.toFixed(
                                4
                              )}/${row?.cbm?.toFixed(4)}`}
                              onChange={(e) => {
                                const newValue = e.target.value.replace(
                                  /^0+/,
                                  ""
                                );
                                const values = { ...formContent };
                                const valuesRows = [...formContent?.dataRows];
                                valuesRows[index].CFTbyCBM =
                                  newValue != "" ? newValue : 0;
                                values.dataRows = valuesRows;
                                setFormContent(values);
                              }}
                              inline={{ width: "-webkit-fill-available" }}
                              disabled
                            />
                          ) : row.CFTbyCBM ? (
                            `${row.cft}/${row.cbm}`
                          ) : (
                            "-"
                          )}
                        </td>
                        {isEdit && (
                          <td className="border p-3 text-start">
                            <img
                              src={removeImg}
                              height={18}
                              width={18}
                              className="cursor-pointer"
                              onClick={() => handleRemoveDataRows(index)}
                            />
                          </td>
                        )}
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td className="text-center" colSpan={9}>
                      {isLoading ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "10px 0px",
                          }}
                        >
                          <CircularProgress
                            style={{
                              color: "#00aff0",
                              height: "25px",
                              width: "25px",
                            }}
                          />
                        </Box>
                      ) : (
                        <div className="d-flex justify-content-center">
                          <p className="my-3">No Records to Show</p>
                        </div>
                      )}
                    </td>
                  </tr>
                )}

                <tr style={{ height: "85px" }}>
                  <td
                    className="pt-3 pb-0 ps-0 text-start"
                    style={{ background: "#F4F5FA" }}
                    colSpan={8}
                  >
                    <label className="fw-bold"> Total:</label>
                  </td>
                </tr>
                <tr className="bg-white mt-5">
                  <td className="border p-3 text-start">
                    {totalNoOfPieces ? `${totalNoOfPieces}` : "-"}
                  </td>
                  <td className="border p-3 text-start">
                    {totalGrossWeight ? `${totalGrossWeight} KGS` : "-"}
                  </td>
                  <td className="border p-3 text-start">
                    {totalNetWeight ? `${totalNetWeight} KGS` : "-"}
                  </td>
                  <td className="border p-3 text-start">
                    {totalVolWeight ? `${totalVolWeight} KGS` : "-"}
                  </td>
                  <td className="border p-3 text-center" colSpan={3}>
                    {totalCMS ? `${totalCMS}` : "-"}
                  </td>
                  <td className="border p-3 text-start">
                    {totalCFT || totalCBM
                      ? `${totalCFT?.toFixed(4)}/${totalCBM?.toFixed(4)}`
                      : "-"}
                  </td>
                  {isEdit && <td className="border p-3 text-start"></td>}
                </tr>
              </tbody>
            </Table>
          </div>
          {/* /table */}

          <Row className="mb-5">
            <Col className="col-12 text-end">
              {isEdit && (
                <Button
                  className="add-new-btn ms-auto"
                  onClick={() => {
                    handleAddNewDataRows();
                  }}
                >
                  Add New
                </Button>
              )}
            </Col>
          </Row>

          {/* Signatures */}
          <Row className="mt-5 pt-5 pb-5">
            <Col sm={6} className="mt-5 pt-5">
              <div
                style={{ height: "90px" }}
                className="d-flex justify-content-center align-items-end"
              >
                {formContent?.weightMasterSign?.trim()?.length == 0 ? (
                  <>
                    {isEdit && (
                      <div className="d-flex justify-content-center align-items-end">
                        <label
                          htmlFor="uploadSign"
                          className="active-btn ms-2 w-200 d-flex align-items-center justify-content-center cursor-pointer btn"
                        >
                          {uploadText}
                        </label>
                        <input
                          type="button"
                          hidden
                          id="uploadSign"
                          name="uploadSign"
                          onClick={() => {
                            setSignCanvas(true);
                          }}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="d-flex align-items-start">
                    <img
                      src={`${Bucket}${formContent.weightMasterSign}`}
                      height={80}
                      width={200}
                    />
                    {isEdit && (
                      <>
                        <label
                          htmlFor="editSign"
                          className=" ms-2  d-flex align-items-center justify-content-center cursor-pointer btn p-0"
                        >
                          <img
                            src={editIcn}
                            height={18}
                            width={18}
                            className="ms-2 cursor-pointer"
                          />
                        </label>
                        <input
                          type="button"
                          hidden
                          id="editSign"
                          name="uploadSign"
                          onClick={() => {
                            setSignCanvas(true);
                          }}
                        />
                        <img
                          src={trashIcn}
                          height={18}
                          width={18}
                          className="ms-2 cursor-pointer"
                          onClick={() => {
                            let keysToDelete = [...mediaKeysToRemove];
                            keysToDelete.push(formContent.weightMasterSign);
                            setMediaKeysToRemove(keysToDelete);

                            let values = { ...formContent };
                            values.weightMasterSign = "";
                            setFormContent(values);
                          }}
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
              <p className="my-0" style={{ height: "10px" }}></p>
              <p>{"_______________________________"}</p>

              <p className="my-1">
                <b>{"Weight Master "}</b>
              </p>
            </Col>

            <Col sm={6} className="mt-5 pt-5">
              <div style={{ height: "90px" }}></div>
              {isEdit ? (
                <input
                  type={"text"}
                  // className={`${style || "input-field "}`}
                  className={`mx-3 input-field mb-3`}
                  // placeholder={label}
                  value={formContent?.weightMasterName}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.weightMasterName = e.target.value;
                    setFormContent(values);
                  }}
                  style={{ width: "300px" }}
                />
              ) : (
                <div>
                  <p className="my-0" style={{ height: "10px" }}>
                    {formContent?.weightMasterName
                      ? formContent?.weightMasterName
                      : "-"}
                  </p>
                  <p>{"_______________________________"}</p>
                </div>
              )}
              <p className="my-1">
                <b>{"Weight Master Name"}</b>
              </p>

              {""}
            </Col>
          </Row>

          {/* snackbar */}
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000} // Adjust the duration as needed
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              severity={
                snackbarMessage?.includes("successful") ? "success" : "error"
              }
              onClose={handleCloseSnackbar}
            >
              {snackbarMessage}
            </MuiAlert>
          </Snackbar>
          {/* snackbar */}
        </div>

        <PrintComponent>
          <WeightTicketDownload
            id="content-id"
            surveyData={surveyData}
            formContent={formContent}
          />
        </PrintComponent>

        <LoadingModal open={open} handleClose={handleClose} />
        {/* Add signature Modal */}
        {isSignCanvas && (
          <MySignatureCanvas
            open={isSignCanvas}
            onClose={() => {
              setSignCanvas(false);
            }}
            onSuccess={(file) => {
              if (!isLoading) uploadFile(file);
            }}
          />
        )}
        {isVerified && (
          <SuperAdminVerifationModal
            open={isVerified}
            onClose={() => {
              setIsVerified(false);
            }}
            onSuccess={() => {
              setIsEdit(true);
              setIsVerified(false);
            }}
            onError={() => {
              // shareWithClient(emails);
              setSnackbarMessage("Invalid Confirm Pin");
              setOpenSnackbar(true);
            }}
          />
        )}
      </MainTemplate>
    </div>
  );
};
export default WeightTicketFormPage;
