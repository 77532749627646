import React, { useEffect, useState } from "react";
import backArrow from "../../../imgs/BackArrow.svg";
import MainTemplate from "../../templates/MainTemplate";
import { useNavigate } from "react-router-dom";
import { Button, Snackbar } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import InfoInput from "../../atoms/InfoInput";
import { updateSurvey } from "../../../api/services/services";
import MuiAlert from "@mui/material/Alert";
import { getSingleSurvey } from "../../../api/services/services";
import { initiateSurvey } from "../../../api/services/services";
import TextField from "@mui/material/TextField";

const InitiateSurvey = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    description: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const inputLabelProps = {
    shrink: true,
    style: {
      color: "black", // Set the color to black
      fontWeight: "600", // Set the font weight to bold
      fontSize: "15px",
    },
  };

  const inputProps = {
    style: {
      //   height: "45px", // Set the height to 40 pixels
      padding: "8px 8px",
    },
  };

  const [showEmailError, setShowEmailError] = useState(false);

  const handleSubmit = () => {
    // Validate email and password
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isEmailValid = emailRegex.test(formData?.email);

    if (isEmailValid) {
      setIsLoading(true);
      initiateSurvey(formData)
        .then(() => {
          setSnackbarMessage("Survey request has been submitted successfully!");
          setOpenSnackbar(true);
          setTimeout(() => {
            navigate("/");
          }, 1500);
        })
        .catch((error) => {
          setIsLoading(false);

          if (error?.response?.data?.statusCode == 401) {
            localStorage.clear();
            window.location.pathname = "/login";
          } else if (error?.response?.data?.statusCode == 1002) {
            // setSnackbarMessage("Token has been expired");
            // setOpenSnackbar(true);
          } else {
            setSnackbarMessage(error?.response?.data?.message);
            setOpenSnackbar(true);
          }
        });
    } else {
      setShowEmailError(true);
    }
  };

  const disableSubmit =
    formData?.name?.trim() == "" ||
    formData?.email?.trim() == "" ||
    formData?.phone?.trim() == "" ||
    formData?.description?.trim() == "";

  return (
    <>
      <MainTemplate>
        <div className="d-block d-sm-flex align-items-center py-4">
          <div className="d-flex align-items-center w-100">
            <div
              className="light-blue-color back-bg cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <img src={backArrow} height="24" width="24" />{" "}
            </div>
            <div>
              <p className="main-head ps-3 mb-0">Survey Request</p>
            </div>
            <div className="d-flex ms-auto">
              <Button
                className="active-btn "
                disabled={disableSubmit || isLoading}
                onClick={() => handleSubmit()}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          {/* <Row className="mx-1 mt-4">
            <Col sm={12} md={12} className="text-start px-0 fn">
              <b>Details</b>
            </Col>
          </Row> */}

          <Grid
            container
            className="bg-white my-4 mx-0  p-3 py-4"
            style={{ borderRadius: "5px" }}
          >
            <Grid item xs={12} sm={6} className="mb-4 pe-4 mt-3">
              <InfoInput
                type="text"
                value={formData.name}
                placeholder="Enter Your Name..."
                onChange={(e) => {
                  const values = { ...formData };
                  values.name = e.target.value;
                  setFormData(values);
                }}
                // error={doorToDoorError}
                name={"Name"}
                inline={{ width: "-webkit-fill-available" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="mb-4 pe-4 mt-3 text-start">
              <InfoInput
                type="text"
                value={formData.email}
                placeholder="Enter Email Address..."
                onChange={(e) => {
                  const values = { ...formData };
                  values.email = e.target.value;
                  setFormData(values);
                }}
                // error={doorToDoorError}
                name={"Email"}
                inline={{ width: "-webkit-fill-available" }}
              />
              {showEmailError && (
                <p
                  className="text-danger pt-1 ps-1"
                  style={{ fontSize: "14px", fontWeight: "700" }}
                >
                  {" "}
                  Enter a valid Email Address
                </p>
              )}
            </Grid>

            <Grid item xs={12} sm={6} className="mb-4 pe-4">
              <InfoInput
                type="number"
                value={formData.phone}
                placeholder="Enter Phone No..."
                onChange={(e) => {
                  const values = { ...formData };
                  values.phone = e.target.value;
                  setFormData(values);
                }}
                // error={doorToDoorError}
                name={"Phone No"}
                inline={{ width: "-webkit-fill-available" }}
              />
            </Grid>

            <Grid item xs={12} sm={12} className="mb-4 pe-4">
              <TextField
                rows={3} // Set the number of rows to control the height
                type="text"
                label="Description"
                variant="outlined"
                placeholder="Write Message.."
                fullWidth
                value={formData?.description}
                onChange={(e) => {
                  const values = { ...formData };
                  values.description = e.target.value;
                  setFormData(values);
                }}
                //   error={error}
                //   helperText={error && "Please enter reason"}
                InputLabelProps={inputLabelProps}
                InputProps={inputProps}
                multiline
                required
              />
            </Grid>
          </Grid>
        </form>
      </MainTemplate>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={
            snackbarMessage?.includes("successful") ? "success" : "error"
          }
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
};
export default InitiateSurvey;
