import React, { useEffect, useState } from "react";
import { Button, Modal, Backdrop, Fade } from "@mui/material";
import Grid from "@mui/material/Grid";
import successImg from "../../imgs/succes.svg";

const SuccessModal = ({ open, onClose, email, head, sub }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="modal-container">
          {" "}
          {/* CSS class for centering */}
          <div className="modal-content assign-modal px-4">
            <div className="d-flex justify-content-center">
              <img
                src={successImg}
                alt="success"
                width="68"
                height="68"
                className="my-4"
              />
            </div>
            {/* Modal content goes here */}
            <p className="modal-head mb-0">{head}</p>
            <p className="modal-subhead mx-3">
              {sub} <span style={{color: '#00aff0'}}>{email}.</span>
            </p>

            <Grid container spacing={2} className="mt-1">
              <Grid item xs={12}>
                <Button
                  type="button"
                  variant="contained"
                  className="submit-btn mt-3 mb-3"
                  onClick={() => onClose()}
                >
                  Continue
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default SuccessModal;
