// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-app{
    margin-left: 240px !important;
    min-height: 90vh;
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
}

@media only screen and (max-width: 992px) {
    .main-app{
      margin-left: 0px !important;
    } 
}

.app-toggler-mg{
  margin-left: 0px !important;
}

.menu-icon-margin{
  margin-left: -198px !important;
}

`, "",{"version":3,"sources":["webpack://./src/components/templates/MainTemplate/style.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,gBAAgB;IAChB,gCAAgC;IAChC,+BAA+B;AACnC;;AAEA;IACI;MACE,2BAA2B;IAC7B;AACJ;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".main-app{\n    margin-left: 240px !important;\n    min-height: 90vh;\n    padding-right: 1.5rem !important;\n    padding-left: 1.5rem !important;\n}\n\n@media only screen and (max-width: 992px) {\n    .main-app{\n      margin-left: 0px !important;\n    } \n}\n\n.app-toggler-mg{\n  margin-left: 0px !important;\n}\n\n.menu-icon-margin{\n  margin-left: -198px !important;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
