// Create a Molecule for the login form
import React, { useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import EmailInput from "../atoms/EmailInput";
import PasswordInput from "../atoms/PasswordInput";
import "./style.css";

const LoginForm = ({ onSubmit }) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate email and password
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isEmailValid = emailRegex.test(email);

    setEmailError(!isEmailValid);

    if (isEmailValid) {
      onSubmit({ email });
    }
  };

  const isButtonDisabled = !email;

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <EmailInput
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={emailError}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            className={
              isButtonDisabled
                ? "disabled-btn mt-3 mb-3 text-capitalize"
                : "submit-btn mt-3 mb-3 text-capitalize"
            }
            disabled={isButtonDisabled}
          >
           Continue
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default LoginForm;
