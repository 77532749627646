// Create a Molecule for the Payment Plan form
import React, { useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import PaymentInput from "../atoms/PaymentInput";
import "./style.css";

const PaymentPlanForm = ({ onSubmit, emailToRegister }) => {
  const [doorToDoor, setDoorToDoor] = useState("");
  const [doorToDoorError, setDoorToDoorError] = useState(false);

  const [doorToPort, setDoorToPort] = useState("");
  const [doorToPortError, setDoorToPortError] = useState(false);

  const [portToDoor, setPortToDoor] = useState("");
  const [portToDoorError, setPortToDoorError] = useState(false);

  const [impExp, setImpExp] = useState("");
  const [impExpError, setImpExpError] = useState(false);

  const [transt, setTranst] = useState("");
  const [transtError, setTranstError] = useState(false);

  const [ship, setShip] = useState("");
  const [shipError, setShipError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    onSubmit({ doorToDoor, doorToPort, portToDoor, transt, impExp, ship });
  };

  const isConfirmButtonDisabled = doorToDoor === '' || doorToPort === '' || portToDoor === '' || transt === '' || impExp === '' || ship === ''

  return (
    <form onSubmit={handleSubmit}>
      <Grid container  className="bg-white my-4 mx-0  p-3 py-4"  style={{borderRadius: '5px'}}>
        <Grid item xs={12} sm={6} className="mb-4 pe-4">
          <PaymentInput
            value={doorToDoor}
            onChange={(e) => {
              setDoorToDoor(e.target.value);
            }}
            error={doorToDoorError}
            name={"Door to Door"}
          />
        </Grid>
        <Grid item xs={12} sm={6} className="mb-4 pe-4">
          <PaymentInput
            value={doorToPort}
            onChange={(e) => {
              setDoorToPort(e.target.value);
            }}
            error={doorToPortError}
            name={"Door to Port"}
          />
        </Grid>

        <Grid item xs={12} sm={6} className="mb-4 pe-4">
          <PaymentInput
            value={portToDoor}
            onChange={(e) => {
              setPortToDoor(e.target.value);
            }}
            error={portToDoorError}
            name={"Port to Door"}
          />
        </Grid>

        <Grid item xs={12} sm={6} className="mb-4 pe-4">
          <PaymentInput
            value={transt}
            onChange={(e) => {
              setTranst(e.target.value);
            }}
            error={transtError}
            name={"Transportation Services"}
          />
        </Grid>
        <Grid item xs={12} sm={6} className="mb-4 pe-4">
          <PaymentInput
            value={impExp}
            onChange={(e) => {
              setImpExp(e.target.value);
            }}
            error={impExpError}
            name={"Vehicle Import & Export Services"}
          />
        </Grid>
        <Grid item xs={12} sm={6} className="mb-4 pe-4">
          <PaymentInput
            value={ship}
            onChange={(e) => {
              setShip(e.target.value);
            }}
            error={shipError}
            name={"Shipping & Packing Services"}
          />
        </Grid>

        <Grid item xs={12} sm={6} className="mb-4 pe-4">
          <Button
            type="submit"
            variant="contained"
            className={
              isConfirmButtonDisabled
                ? "disabled-btn mt-1 text-capitalize"
                : "submit-btn mt-1 text-capitalize"
            }
            disabled={isConfirmButtonDisabled}
          >
            Send Quotation
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default PaymentPlanForm;
