import React, { useEffect, useState } from "react";
import {
  updateFormContentPreview,
  getFormContentPreview,
  imageUploadClientPreview,
} from "../../../../api/services/services";
import backArrow from "../../../../imgs/BackArrow.svg";
import { Button, Snackbar, Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import { Bucket } from "../../../../api/config/apiConfig";
import ContactDetailField from "../../../molecules/ContactDetailField";
import TextArea from "../../../atoms/Textarea";
import DynamicCheckbox from "../../../molecules/DynamicCheckbox";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import Input from "../../../atoms/Input";
import removeImg from "../../../../imgs/remove.png";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import html2pdf from "html2pdf.js";
import { useParams } from "react-router-dom";
import { ToWords } from "to-words";
import { useLocation } from "react-router-dom";

const InvoiceDownload = ({ formContent, surveyData }) => {
  const [formContentId, setFormContentId] = useState("");

  const [isEdit, setIsEdit] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const urlData = new URLSearchParams(location.search);
  const email = urlData.get("email");
  const surveyId = urlData.get("surveyId");
  const formId = urlData.get("formId");
  const token = urlData.get("token");

  var totalAmount = 0;

  const words = new ToWords();

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // 11th, 12th, 13th, etc.
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  // Helper function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const ordinalSuffix = getOrdinalSuffix(day);

    return `${day}${ordinalSuffix} ${month}, ${year}`;
  };

  return (
    <>
      <div className="mx-4">
        {/* <MainTemplate> */}
        <div className="d-flex align-items-center justify-content-center mb-1">
          <p className="ps-0 mb-0 head-top">Invoice</p>
        </div>

        {/* customer detail*/}
        <div className={`${isEdit ? "p-3 px-0 rounded mb-3 pt-2" : ""}`}>
          <div className="contact-detail d-flex mt-0">
            {/* left col */}
            <div className={`left-col w-100 text-start`}>
              <Typography variant="body1" gutterBottom>
                {/* <ContactDetailField
                  label="Invoice No."
                  isEdit={isEdit}
                  type="text"
                  minWidth={"160px"}
                  value={formContent?.invoiceNo}
                  disabled
                /> */}
                <div className="d-flex">
                  <p
                    className="p-word mb-1 text-start"
                    style={{ minWidth: "100px", fontSize: "11px" }}
                  >
                    <b>Invoice No.:</b>
                  </p>

                  <p className="p-word mb-1 text-start">
                    <span className="ms-1 ">
                      {" "}
                      {formContent?.invoiceNo ? formContent?.invoiceNo : "-"}
                    </span>
                  </p>
                </div>

                {/* <ContactDetailField
                  label="Shelozon Vendor #"
                  isEdit={isEdit}
                  type="number"
                  minWidth={"160px"}
                  value={formContent?.vendorNo}
                /> */}
                <div className="d-flex">
                  <p
                    className="p-word mb-1 text-start"
                    style={{ minWidth: "100px", fontSize: "11px" }}
                  >
                    <b>Shelozon Vendor #:</b>
                  </p>

                  <p className="p-word mb-1 text-start">
                    <span className="ms-1 ">
                      {" "}
                      {formContent?.vendorNo ? formContent?.vendorNo : "-"}
                    </span>
                  </p>
                </div>

                {/* <ContactDetailField
                  label="To"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"160px"}
                  value={formContent?.to}
                /> */}
                <div className="d-flex">
                  <p
                    className="p-word mb-1 text-start"
                    style={{ minWidth: "100px", fontSize: "11px" }}
                  >
                    <b>To:</b>
                  </p>

                  <p className="p-word mb-1 text-start">
                    <span className="ms-1 ">
                      {" "}
                      {formContent?.to ? formContent?.to : "-"}
                    </span>
                  </p>
                </div>
                {/* <ContactDetailField
                  label="For"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"160px"}
                  value={formContent?.for}
                /> */}
                <div className="d-flex">
                  <p
                    className="p-word mb-1 text-start"
                    style={{ minWidth: "100px", fontSize: "11px" }}
                  >
                    <b>For:</b>
                  </p>

                  <p className="p-word mb-1 text-start">
                    <span className="ms-1 ">
                      {" "}
                      {formContent?.for ? formContent?.for : "-"}
                    </span>
                  </p>
                </div>
              </Typography>
            </div>

            <div
              className={`right-col w-100 d-flex justify-content-end align-items-baseline`}
            >
              {/* <Typography variant="body1" gutterBottom>
                <ContactDetailField
                  label="Date"
                  isEdit={isEdit}
                  type="date"
                  value={formContent?.date}
                />
              </Typography> */}
              <div className="d-flex">
                <p
                  className="p-word mb-1 text-start"
                  style={{ fontSize: "11px" }}
                >
                  <b>Date:</b>
                </p>

                <p className="p-word mb-1 text-start">
                  <span className="ms-1 ">
                    {" "}
                    {formContent?.date ? formatDate(formContent?.date) : "-"}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* /customer detail */}

        <>
          {formContent?.dataRows?.map((row, index) => {
            totalAmount = totalAmount + row?.amount * row?.quantity;
            return <></>;
          })}
        </>

        <Table responsive className="mt-1">
          {/* Table Header */}
          <thead className="bg-white ">
            <tr>
              <th
                className="border p-1 text-start"
                style={{ width: "10%", fontSize: "10px" }}
              >
                Item No.
              </th>
              <th
                className="border p-1 text-start"
                style={{ width: "30%", fontSize: "10px" }}
              >
                Description
              </th>
              <th
                className="border p-1 text-start"
                style={{ width: "20%", fontSize: "10px" }}
              >
                Quantity
              </th>
              <th
                className="border p-1 text-start"
                style={{ width: "20%", fontSize: "10px" }}
              >
                Amount in PKR/USD/EURO
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {formContent?.dataRows?.length > 0 ? (
              <>
                {formContent?.dataRows.map((row, index) => (
                  <tr key={index}>
                    <td
                      className="border p-1 text-start"
                      style={{ fontSize: "9px" }}
                    >
                      {index + 1}
                    </td>
                    <td
                      className="border p-1 text-start"
                      style={{ fontSize: "9px" }}
                    >
                      {isEdit ? (
                        <Input
                          id="description"
                          label="Description"
                          type="text"
                          style="full-border-field"
                          value={row?.description}
                          inline={{ width: "-webkit-fill-available" }}
                        />
                      ) : row.description ? (
                        row.description
                      ) : (
                        "-"
                      )}
                    </td>
                    <td
                      className="border p-1 text-start"
                      style={{ fontSize: "9px" }}
                    >
                      {isEdit ? (
                        <Input
                          id="amount"
                          label="Qty"
                          type="number"
                          style="full-border-field"
                          value={row?.quantity}
                          inline={{ width: "-webkit-fill-available" }}
                        />
                      ) : row?.quantity ? (
                        row?.quantity
                      ) : (
                        "-"
                      )}
                    </td>

                    <td
                      className="border p-1 text-start"
                      style={{ fontSize: "9px" }}
                    >
                      {isEdit ? (
                        <Input
                          id="amount"
                          label="Price in PKR"
                          type="number"
                          style="full-border-field"
                          value={row?.amount}
                          inline={{ width: "-webkit-fill-available" }}
                        />
                      ) : row?.amount ? (
                        row?.amount
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td
                    className="border p-1 text-start"
                    style={{ fontSize: "11px" }}
                    colSpan={4}
                  >
                    {" "}
                    <b>Grand Total</b>
                  </td>
                </tr>
                <tr>
                  <td
                    className="border p-1 text-start"
                    style={{ fontSize: "9px" }}
                  >
                    <b>-</b>
                  </td>
                  <td
                    className="border p-1 text-start"
                    style={{ fontSize: "9px" }}
                    colSpan={2}
                  >
                    <b>
                      {formContent?.totalAmountinEnglish
                        ? formContent?.totalAmountinEnglish
                        : "-"}{" "}
                    </b>

                    {/* <b> {words?.convert(totalAmount, { currency: true })}
                    </b> */}
                  </td>
                  <td
                    className="border p-1 text-start"
                    style={{ fontSize: "9px" }}
                  >
                    {formContent?.totalAmount ? formContent?.totalAmount : "-"}
                  </td>
                </tr>
              </>
            ) : (
              <tr>
                <td className="text-center" colSpan={9}>
                  {isLoading ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "10px 0px",
                      }}
                    >
                      <CircularProgress
                        style={{
                          color: "#00aff0",
                          height: "25px",
                          width: "25px",
                        }}
                      />
                    </Box>
                  ) : (
                    <div
                      className="d-flex justify-content-center"
                      style={{ fontSize: "9px" }}
                    >
                      <p className="my-3">No Records to Show</p>
                    </div>
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        {/* Bank Details */}
        <div className="mt-1 pe-1 mb-0 row tst">
          <Typography
            variant="body1"
            className="text-start col-12"
            gutterBottom
          >
            <div className="mb-0">
              <label
                htmlFor="to"
                className="mb-1 fw-bold"
                style={{ fontSize: "10px" }}
              >
                Bank Details:
              </label>
              <Typography variant="body1" gutterBottom>
                {/* <ContactDetailField
                  label="Account Title"
                  isEdit={isEdit}
                  type="text"
                  style="fw-normal"
                  value={formContent?.bankDetails?.title}
                  minWidth={"160px"}
                /> */}
                <div className="d-flex align-items-center">
                  <p
                    className="p-word mb-1 text-start"
                    style={{ minWidth: "100px", fontSize: "10px" }}
                  >
                    <b>Account Title:</b>
                  </p>

                  <p className="p-word mb-1 text-start">
                    <span className="ms-1 ">
                      {" "}
                      {formContent?.bankDetails?.title
                        ? formContent?.bankDetails?.title
                        : "-"}
                    </span>
                  </p>
                </div>
                {/* <ContactDetailField
                  label=" IBAN No"
                  isEdit={isEdit}
                  type="text"
                  style="fw-normal"
                  value={formContent?.bankDetails?.iban}
                  minWidth={"160px"}
                /> */}
                <div className="d-flex align-items-center">
                  <p
                    className="p-word mb-1 text-start"
                    style={{ minWidth: "100px", fontSize: "10px" }}
                  >
                    <b> IBAN No:</b>
                  </p>

                  <p className="p-word mb-1 text-start">
                    <span className="ms-1 ">
                      {" "}
                      {formContent?.bankDetails?.iban
                        ? formContent?.bankDetails?.iban
                        : "-"}
                    </span>
                  </p>
                </div>
                {/* <ContactDetailField
                  label="Swift Code"
                  isEdit={isEdit}
                  type="text"
                  style="fw-normal"
                  value={formContent?.bankDetails?.swiftCode}
                  minWidth={"160px"}
                /> */}
                <div className="d-flex align-items-center">
                  <p
                    className="p-word mb-1 text-start"
                    style={{ minWidth: "100px", fontSize: "10px" }}
                  >
                    <b> Swift Code:</b>
                  </p>

                  <p className="p-word mb-1 text-start">
                    <span className="ms-1 ">
                      {" "}
                      {formContent?.bankDetails?.swiftCode
                        ? formContent?.bankDetails?.swiftCode
                        : "-"}
                    </span>
                  </p>
                </div>
                {/* <ContactDetailField
                  label="Branch Name & Code"
                  isEdit={isEdit}
                  type="text"
                  style="fw-normal"
                  value={formContent?.bankDetails?.branchNameCode}
                  minWidth={"160px"}
                /> */}
                <div className="d-flex align-items-center">
                  <p
                    className="p-word mb-0 text-start"
                    style={{ minWidth: "100px", fontSize: "10px" }}
                  >
                    <b> Branch Name & Code:</b>
                  </p>

                  <p className="p-word mb-0 text-start">
                    <span className="ms-1 ">
                      {" "}
                      {formContent?.bankDetails?.branchNameCode
                        ? formContent?.bankDetails?.branchNameCode
                        : "-"}
                    </span>
                  </p>
                </div>
              </Typography>
            </div>
          </Typography>
        </div>
        {/* /Bank Details */}

        {/*  Terms & Conditions:*/}
        {formContent?.termsAndConditions?.trim()?.length > 0 && (
          <div className=" pe-1 ">
            <Typography
              variant="body1"
              className="text-start mt-0"
              gutterBottom
            >
              <div className="mb- d-flex">
                <label
                  htmlFor="instructions"
                  className={`fw-bold ${isEdit ? "mb-3" : "mb-0"}`}
                >
                  Terms & Conditions:
                </label>
                {!isEdit ? (
                  <p className="ps-1 mb-0">
                    {formContent?.termsAndConditions
                      ? formContent?.termsAndConditions
                      : "-"}
                  </p>
                ) : (
                  <TextArea
                    rows="3"
                    label="Terms & Conditions..."
                    id="instructions"
                    value={formContent?.termsAndConditions}
                  />
                )}
              </div>
            </Typography>
          </div>
        )}
        {/* / Terms & Conditions */}

        {/*  Validity*/}
        {formContent?.validity?.trim()?.length > 0 && (
          <div className="mt-2 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="mb-2 d-flex">
                <label
                  htmlFor="instructions"
                  className={`fw-bold ${isEdit ? "mb-3" : "mb-1"}`}
                >
                  Validity:
                </label>
                {!isEdit ? (
                  <p className="ps-1 mb-0">
                    {formContent?.validity ? formContent?.validity : "-"}
                  </p>
                ) : (
                  <TextArea
                    rows="3"
                    label="Terms & Conditions..."
                    id="instructions"
                    value={formContent?.validity}
                  />
                )}
              </div>
            </Typography>
          </div>
        )}
        {/* / Validity */}

        <Row className="mt-1">
          <Col sm={6} className=""></Col>

          <Col sm={6} className="">
            <div
              style={{ height: "90px" }}
              className="d-flex justify-content-center align-items-end"
            >
              {formContent?.adminSign?.trim()?.length == 0 ? (
                <div className="d-flex justify-content-center align-items-end"></div>
              ) : (
                <img
                  src={`${Bucket}${formContent.adminSign}`}
                  height={80}
                  width={200}
                />
              )}
            </div>
            <p className="mb-0">{"_______________________________"}</p>
            <p className="my-0">
              <b>{"Shelozon Movers Packers & Logistics Pvt Ltd"}</b>
            </p>

            {""}
          </Col>
        </Row>

        {/* </MainTemplate> */}
      </div>
    </>
  );
};

export default InvoiceDownload;
