// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.remove-div{
    height: 20px;
    width: 20px;
    border-radius: 50% !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.add-new-btn {
    width: 110px !important;
    height: 35px !important;
    padding: 15px !important;
    border-radius: 28px !important;
    gap: 9.43px !important;
    background-color: #00aff0 !important;
    color: #fff !important;
    font-family: Aspira !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    line-height: 21px !important;
    letter-spacing: 0px !important;
    text-align: center !important;
    text-transform: capitalize !important;
  }`, "",{"version":3,"sources":["webpack://./src/components/pages/WoodenLiftPage/style.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,6BAA6B;IAC7B,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;IACvB,uBAAuB;IACvB,wBAAwB;IACxB,8BAA8B;IAC9B,sBAAsB;IACtB,oCAAoC;IACpC,sBAAsB;IACtB,8BAA8B;IAC9B,0BAA0B;IAC1B,2BAA2B;IAC3B,4BAA4B;IAC5B,8BAA8B;IAC9B,6BAA6B;IAC7B,qCAAqC;EACvC","sourcesContent":[".remove-div{\n    height: 20px;\n    width: 20px;\n    border-radius: 50% !important;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.add-new-btn {\n    width: 110px !important;\n    height: 35px !important;\n    padding: 15px !important;\n    border-radius: 28px !important;\n    gap: 9.43px !important;\n    background-color: #00aff0 !important;\n    color: #fff !important;\n    font-family: Aspira !important;\n    font-size: 13px !important;\n    font-weight: 600 !important;\n    line-height: 21px !important;\n    letter-spacing: 0px !important;\n    text-align: center !important;\n    text-transform: capitalize !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
