import React, { useEffect, useState } from "react";
import {
  updateFormContentPreview,
  getFormContentPreview,
  imageUploadClientPreview,
} from "../../../../api/services/services";
import { Button, Snackbar, Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import { Bucket } from "../../../../api/config/apiConfig";
import ContactDetailField from "../../../molecules/ContactDetailField";
import TextArea from "../../../atoms/Textarea";
import DynamicCheckbox from "../../../molecules/DynamicCheckbox";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import html2pdf from "html2pdf.js";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

const DeliveryRequestDownload = ({ formContent, surveyData }) => {
  const [formContentId, setFormContentId] = useState("");

  const [isEdit, setIsEdit] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const urlData = new URLSearchParams(location.search);
  const email = urlData.get("email");
  const surveyId = urlData.get("surveyId");
  const formId = urlData.get("formId");
  const token = urlData.get("token");

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // 11th, 12th, 13th, etc.
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  // Helper function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const ordinalSuffix = getOrdinalSuffix(day);

    return `${day}${ordinalSuffix} ${month}, ${year}`;
  };

  return (
    <>
      <div className="mx-4">
        <div className="d-flex align-items-center justify-content-center">
          <p className="ps-0 mb-0 head-top">Delivery Request Form</p>
        </div>

        {/* customer detail*/}
        <div className={`${isEdit ? "p-3 px-0 rounded mb-5" : ""}`}>
          <div className="contact-detail d-flex mt-2">
            {/* left col */}
            <div className={`left-col w-100 text-start`}>
              <Typography variant="body1" gutterBottom>
                {/* <ContactDetailField
                  label="Customer Name"
                  isEdit={isEdit}
                  type="text"
                  value={formContent?.clientName}
                /> */}
                <div className="d-flex">
                  <p
                    className="p-word mb-1 text-start"
                    style={{ minWidth: "80px", fontSize: "11px" }}
                  >
                    <b>Customer Name:</b>
                  </p>

                  <p className="p-word mb-1 text-start">
                    <span className="ms-1 ">
                      {" "}
                      {formContent?.clientName ? formContent?.clientName : "-"}
                    </span>
                  </p>
                </div>
                {/* <ContactDetailField
                  label="Date"
                  isEdit={isEdit}
                  type="date"
                  value={formContent?.date}
                /> */}
                <div className="d-flex">
                  <p
                    className="p-word mb-1 text-start"
                    style={{ minWidth: "80px", fontSize: "11px" }}
                  >
                    <b>Date:</b>
                  </p>

                  <p className="p-word mb-1 text-start">
                    <span className="ms-1 ">
                      {" "}
                      {formContent?.date ? formatDate(formContent?.date) : "-"}
                    </span>
                  </p>
                </div>
              </Typography>
            </div>
          </div>
        </div>
        {/* /customer detail */}

        {/*  Delivery Address:*/}
        <div className="mt-2 pe-1 ">
          <Typography variant="body1" className="text-start" gutterBottom>
            <div className="mb-1">
              <label
                htmlFor="deliveryAddress"
                className={`fw-bold ${isEdit ? "mb-3" : "mb-1"}`}
              >
                Delivery Address:
              </label>
              {!isEdit ? (
                <p>
                  {formContent?.deliveryAddress
                    ? formContent?.deliveryAddress
                    : "-"}
                </p>
              ) : (
                <TextArea
                  rows="3"
                  label="Delivery Address..."
                  id="deliveryAddress"
                  value={formContent?.deliveryAddress}
                />
              )}
            </div>
          </Typography>
        </div>
        {/* / Delivery Address */}

        <div className="mt-2 pe-1 d-flex justify-content-start">
          <p className="mb-0">
            <b>
              Which dates and time suits you to start packing of your goods?
            </b>
          </p>
        </div>

        {/*  Date & Time:*/}
        <div className="mt-0 pe-1 d-flex">
          {isEdit ? (
            <>
              <div
                className={`left-col ${!isEdit ? " w-50" : "w-50"} text-start`}
              >
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{ width: "80%" }}
                >
                  <ContactDetailField
                    label="Packing Date"
                    isEdit={isEdit}
                    type="date"
                    value={formContent?.deliveryDate}
                    minWidth={"125px"}
                  />
                </Typography>
              </div>
              <div
                className={`right-col ${
                  !isEdit ? "w-50 pe-5 " : "w-50 pe-2"
                }  d-flex flex-column align-items-end justify-content-end text-end`}
              >
                <Typography
                  variant="body1"
                  className="text-start "
                  gutterBottom
                  style={{ width: "80%" }}
                >
                  <ContactDetailField
                    label="Packing Time"
                    isEdit={isEdit}
                    type="time"
                    value={formContent?.deliveryTime}
                    minWidth={"125px"}
                  />
                </Typography>
              </div>
            </>
          ) : (
            <>
              <p className="mb-1">
                {formatDate(formContent?.deliveryDate)}{' - '}  {formContent?.deliveryTime}
              </p>
            </>
          )}
        </div>
        {/* / Date & Time */}

        {/* permissions */}
        <div className="mt-2 pe-1">
          <Typography variant="body1" className="text-start" gutterBottom>
            <DynamicCheckbox
              label="Is there any vehicle/crew permission/security access/permit required of our team/vehicle at your residence?"
              checkId1="check1"
              checkLabel1="Yes"
              checkId2="check2"
              checkLabel2="No"
              value1={formContent?.permissions}
              value2={formContent?.permissions}
              disabled={!isEdit ? true : false}
            />
          </Typography>
        </div>
        {/* /permissions */}

        {/*  If yes, please advise requirement::*/}
        {formContent?.permissions && (
          <div className="mt-1 pe-1 ">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="mb-0">
                <label
                  htmlFor="advise"
                  className={`fw-bold ${isEdit ? "mb-3" : "mb-1"}`}
                >
                  If yes, please advise requirement::
                </label>
                {!isEdit ? (
                  <p>
                    {formContent?.adviseRequirement
                      ? formContent?.adviseRequirement
                      : "-"}
                  </p>
                ) : (
                  <TextArea
                    rows="3"
                    label="If yes, please advise requirement..."
                    id="advise"
                    value={formContent?.adviseRequirement}
                  />
                )}
              </div>
            </Typography>
          </div>
        )}
        {/* / If yes, please advise requirement: */}

        {/*  1st priority items to receive according to the packing list*/}
        <div className="mt-1 pe-1 ">
          <Typography variant="body1" className="text-start" gutterBottom>
            <div className="mb-0">
              <label
                htmlFor="firstPriority"
                className={`fw-bold ${isEdit ? "mb-3" : "mb-1"}`}
              >
                1st priority items to receive according to the packing list:
              </label>
              {!isEdit ? (
                <p>
                  {formContent?.firstPriority
                    ? formContent?.firstPriority
                    : "-"}
                </p>
              ) : (
                <TextArea
                  rows="3"
                  label="1st priority items to receive according to the packing list..."
                  id="firstPriority"
                  value={formContent?.firstPriority}
                />
              )}
            </div>
          </Typography>
        </div>
        {/* / 1st priority items to receive according to the packing list */}

        {/*  2nd priority items to receive according to the packing list*/}
        <div className="mt-2 pe-1 ">
          <Typography variant="body1" className="text-start" gutterBottom>
            <div className="mb-0">
              <label
                htmlFor="secondPriority"
                className={`fw-bold ${isEdit ? "mb-3" : "mb-1"}`}
              >
                2nd priority items to receive according to the packing list:
              </label>
              {!isEdit ? (
                <p>
                  {formContent?.secondPriority
                    ? formContent?.secondPriority
                    : "-"}
                </p>
              ) : (
                <TextArea
                  rows="3"
                  label="2nd priority items to receive according to the packing list..."
                  id="secondPriority"
                  value={formContent?.secondPriority}
                />
              )}
            </div>
          </Typography>
        </div>
        {/* / 2nd priority items to receive according to the packing list */}

        {/*  3rd priority items to receive according to the packing list*/}
        <div className="mt-2 pe-1 ">
          <Typography variant="body1" className="text-start" gutterBottom>
            <div className="mb-0">
              <label
                htmlFor="thirdPriority"
                className={`fw-bold ${isEdit ? "mb-3" : "mb-1"}`}
              >
                3rd priority items to receive according to the packing list:
              </label>
              {!isEdit ? (
                <p>
                  {formContent?.thirdPriority
                    ? formContent?.thirdPriority
                    : "-"}
                </p>
              ) : (
                <TextArea
                  rows="3"
                  label="3rd priority items to receive according to the packing list..."
                  id="thirdPriority"
                  value={formContent?.thirdPriority}
                />
              )}
            </div>
          </Typography>
        </div>
        {/* / 3rd priority items to receive according to the packing list */}

        {/* Residence rooms*/}
        <div className="mt-2 pe-1">
          <Typography variant="body1" className="text-start" gutterBottom>
            <DynamicCheckbox
              label="Residence’s rooms are marked / labeled with suitable Info/Numbers/Colors to place items in."
              checkId1="check1"
              checkLabel1="Yes"
              checkId2="check2"
              checkLabel2="No"
              value1={formContent?.suitableInfo}
              value2={formContent?.suitableInfo}
              disabled={!isEdit ? true : false}
            />
          </Typography>
        </div>
        {/* Residence rooms */}

        {/*  Customer Suggestions/Remarks if any:*/}
        <div className="mt-2 pe-1 ">
          <Typography variant="body1" className="text-start" gutterBottom>
            <div className="mb-0">
              <label
                htmlFor="suggestion"
                className={`fw-bold ${isEdit ? "mb-3" : "mb-1"}`}
              >
                Customer Suggestions/Remarks if any:
              </label>
              {!isEdit ? (
                <p>
                  {formContent?.suggestions ? formContent?.suggestions : "-"}
                </p>
              ) : (
                <TextArea
                  rows="3"
                  label="Customer Suggestions/Remarks if any..."
                  id="suggestion"
                  value={formContent?.suggestions}
                />
              )}
            </div>
          </Typography>
        </div>
        {/* / Customer Suggestions/Remarks if any */}

        {/*  Office Instructions/remarks:*/}
        <div className="mt-2 pe-1 mb-1">
          <Typography variant="body1" className="text-start" gutterBottom>
            <div className="mb-0">
              <label
                htmlFor="instructions"
                className={`fw-bold ${isEdit ? "mb-3" : "mb-1"}`}
              >
                Office Instructions/remarks:
              </label>
              {!isEdit ? (
                <p>
                  {formContent?.officeInstructions
                    ? formContent?.officeInstructions
                    : "-"}
                </p>
              ) : (
                <TextArea
                  rows="3"
                  label="Office Instructions/remarks..."
                  id="instructions"
                  value={formContent?.officeInstructions}
                  disabled={true}
                />
              )}
            </div>
          </Typography>
        </div>
        {/* / Office Instructions/remarks */}

        {/* Signatures */}
        <Row className="mt-1">
          <Col sm={6} className="">
            <div
              style={{ height: "90px" }}
              className="d-flex justify-content-center align-items-end"
            >
              {formContent.clientSign != "" &&
                formContent.clientSign != undefined && (
                  <img
                    src={`${Bucket}${formContent.clientSign}`}
                    height={80}
                    width={200}
                  />
                )}
            </div>
            <p className="mb-0">{"_______________________________"}</p>
            <p className="my-0">
              <b>{"Customer/Authorized"}</b>
            </p>
           
            {""}
          </Col>
          <Col sm={6} className="">
            <div
              style={{ height: "90px" }}
              className="d-flex justify-content-center align-items-end"
            >
              {formContent?.adminSign?.trim()?.length == 0 ? (
                <></>
              ) : (
                <img
                  src={`${Bucket}${formContent.adminSign}`}
                  height={80}
                  width={200}
                />
              )}
            </div>
            <p className="mb-0">{"_______________________________"}</p>
            <p className="my-0">
              <b>{"Shelozon Movers Packers & Logistics Pvt Ltd."}</b>
            </p>
           
            {""}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DeliveryRequestDownload;
