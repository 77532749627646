import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import Input from "../../../atoms/Input";
import ContactDetailField from "../../../molecules/ContactDetailField";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import DynamicText from "../../../molecules/DynamicText";
import { Bucket } from "../../../../api/config/apiConfig";

const WorksheetImportDownload = ({ surveyData, formContent }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);

  return (
    <>
      <div className="mx-4">
        <div>
          <div className="d-block d-sm-flex align-items-center pb-1 pt-0 ">
            <div className="d-flex align-items-center justify-content-center w-100">
              <p className="ps-0 mb-0 head-top"> Work Sheet Import</p>
            </div>
          </div>
        </div>
        {/* file detail*/}
        <div className="contact-detail d-flex mt-1">
          {/* left col */}
          <div
            className={`left-col ${!isEdit ? " w-50" : "w-50 pe-5"} text-start`}
          >
            <Typography variant="body1" gutterBottom>
              {/* <ContactDetailField
                  label="Name"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"120px"}
                  value={formContent?.customerName}
                /> */}
              <p className="p-word mb-1 text-start">
                <b> Name:</b>{" "}
                <span className="ms-0">
                  {" "}
                  {formContent?.customerName ? formContent?.customerName : "-"}
                </span>
              </p>
              {/* <ContactDetailField
                  label="Organization"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"120px"}
                  value={formContent?.organization}
                /> */}
              <p className="p-word mb-1 text-start">
                <b> Organization:</b>{" "}
                <span className="ms-0">
                  {" "}
                  {formContent?.organization ? formContent?.organization : "-"}
                </span>
              </p>
              {/* <ContactDetailField
                  label="Delivery Address"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"120px"}
                  value={formContent?.deliveryAddres}
                /> */}

              <p className="p-word mb-1 text-start">
                <b> Delivery Address:</b>{" "}
                <span className="ms-0">
                  {" "}
                  {formContent?.deliveryAddres
                    ? formContent?.deliveryAddres
                    : "-"}
                </span>
              </p>
            </Typography>
          </div>
          {/* right col */}
          <div
            className={`right-col ${
              !isEdit ? "w-50" : "w-50 ps-5"
            }  d-flex flex-column align-items-end pe-2`}
          >
            <div
              className=""
              style={isEdit ? { width: "-webkit-fill-available" } : {}}
            >
              <Typography
                variant="body1"
                className="text-start w-100"
                gutterBottom
              >
                {/* <ContactDetailField
                    label="File No. "
                    isEdit={isEdit}
                    type="text"
                    wrapperStyle="justify-content-start"
                    minWidth={"90px"}
                    value={formContent?.fileNo}
                    disabled={true}
                  /> */}
                <p className="p-word mb-1 text-start">
                  <b> File No.:</b>{" "}
                  <span className="ms-0">
                    {" "}
                    {formContent?.fileNo ? formContent?.fileNo : "-"}
                  </span>
                </p>
                {/* <ContactDetailField
                    label="Contact No."
                    isEdit={isEdit}
                    type="number"
                    wrapperStyle="justify-content-start"
                    minWidth={"90px"}
                    value={
                      formContent?.contactNo != "null" &&
                      formContent?.contactNo != undefined
                        ? formContent?.contactNo
                        : ""
                    }
                  /> */}

                <p className="p-word mb-1 text-start">
                  <b> Contact No.:</b>{" "}
                  <span className="ms-0">
                    {" "}
                    {formContent?.contactNo != "null" &&
                    formContent?.contactNo != undefined
                      ? formContent?.contactNo
                      : ""}
                  </span>
                </p>
                {/* <ContactDetailField
                    label="Fax"
                    isEdit={isEdit}
                    type="number"
                    wrapperStyle="justify-content-start"
                    minWidth={"90px"}
                    value={formContent?.fax}
                  /> */}
                <p className="p-word mb-1 text-start">
                  <b> Fax:</b>{" "}
                  <span className="ms-0">
                    {" "}
                    {formContent?.fax ? formContent?.fax : ""}
                  </span>
                </p>
                {/* <ContactDetailField
                    label="Email"
                    isEdit={isEdit}
                    type="text"
                    wrapperStyle="justify-content-start"
                    minWidth={"90px"}
                    value={formContent?.email}
                  /> */}

                <p className="p-word mb-0 text-start">
                  <b> Email:</b>{" "}
                  <span className="ms-0">
                    {" "}
                    {formContent?.email ? formContent?.email : ""}
                  </span>
                </p>
              </Typography>
            </div>
          </div>
        </div>
        {/* table */}
        <TableContainer component={Paper} className="mt-3">
          <Table>
            <TableBody>
              {/* Entitlement1 */}
              <TableRow>
                <TableCell className="border p-1 w-25">
                  <b>Entitlement</b>
                </TableCell>
                <TableCell className="border p-1 w-75 text-start">
                  {isEdit ? (
                    <Input
                      id={"shipper"}
                      type="text"
                      style="full-border-field bg-app"
                      label={"Entitlement"}
                      value={formContent?.entitlement1}
                      inline={{ width: "-webkit-fill-available" }}
                    />
                  ) : (
                    <>
                      {formContent?.entitlement1
                        ? formContent?.entitlement1
                        : "-"}
                    </>
                  )}
                </TableCell>
              </TableRow>
              {/* Estimated Weight */}
              <TableRow>
                <TableCell className="border p-1 w-25">
                  <b>Estimated Weight</b>
                </TableCell>
                <TableCell className="border p-1 w-75 text-start">
                  {isEdit ? (
                    <Input
                      id={"consignee"}
                      type="text"
                      style="full-border-field bg-app"
                      label={"Estimated Weight"}
                      value={formContent?.estimatedWeight}
                      inline={{ width: "-webkit-fill-available" }}
                    />
                  ) : (
                    <>
                      {formContent?.estimatedWeight
                        ? `${formContent?.estimatedWeight}`
                        : "-"}
                    </>
                  )}
                </TableCell>
              </TableRow>
              {/* Actual Weight */}
              <TableRow>
                <TableCell className="border p-1 w-25">
                  <b>Actual Weight</b>
                </TableCell>
                <TableCell className="border p-1 w-75 text-start">
                  {isEdit ? (
                    <Input
                      id={"consignee"}
                      type="text"
                      style="full-border-field bg-app"
                      label={"Actual Weight"}
                      value={formContent?.actualWeight}
                      inline={{ width: "-webkit-fill-available" }}
                    />
                  ) : (
                    <>
                      {formContent?.actualWeight
                        ? `${formContent?.actualWeight}`
                        : "-"}
                    </>
                  )}
                </TableCell>
              </TableRow>
              {/* Entitlement1 */}
              {/* <TableRow>
                  <TableCell className="border p-1 w-25">
                    <b>Entitlement</b>
                  </TableCell>
                  <TableCell className="border p-1 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"shipper"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Entitlement"}
                        value={formContent?.entitlement2}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.entitlement2
                          ? formContent?.entitlement2
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow> */}
              {/* Estimated Volume */}
              <TableRow>
                <TableCell className="border p-1 w-25">
                  <b>Estimated Volume</b>
                </TableCell>
                <TableCell className="border p-1 w-75 text-start">
                  {isEdit ? (
                    <Input
                      id={"consignee"}
                      type="number"
                      style="full-border-field bg-app"
                      label={"Estimated Volume"}
                      value={formContent?.estimatedVolume}
                      inline={{ width: "-webkit-fill-available" }}
                    />
                  ) : (
                    <>
                      {formContent?.estimatedVolume ? (
                        <>
                          {Number(formContent?.estimatedVolume)?.toFixed(2)} m
                          <sup>3</sup>
                        </>
                      ) : (
                        "-"
                      )}
                    </>
                  )}
                </TableCell>
              </TableRow>
              {/* Actual Volume */}
              <TableRow>
                <TableCell className="border p-1 w-25">
                  <b>Actual Volume</b>
                </TableCell>
                <TableCell className="border p-1 w-75 text-start">
                  {isEdit ? (
                    <Input
                      id={"consignee"}
                      type="number"
                      style="full-border-field bg-app"
                      label={"Actual Volume"}
                      value={formContent?.actualVolume}
                      inline={{ width: "-webkit-fill-available" }}
                    />
                  ) : (
                    <>
                      {formContent?.actualVolume ? (
                        <>
                          {Number(formContent?.actualVolume)?.toFixed(2)} m
                          <sup>3</sup>
                        </>
                      ) : (
                        "-"
                      )}
                    </>
                  )}
                </TableCell>
              </TableRow>
              {/* Delivery Started Date */}
              <TableRow>
                <TableCell className="border p-1 w-25">
                  <b>Delivery Started Date</b>
                </TableCell>
                <TableCell className="border p-1 w-75 text-start">
                  {isEdit ? (
                    <Input
                      id={"description"}
                      type="date"
                      style="full-border-field bg-app"
                      label={"Delivery Started Date"}
                      value={formContent?.deliveryStartedDate}
                      inline={{ width: "-webkit-fill-available" }}
                    />
                  ) : (
                    <>
                      {formContent?.deliveryStartedDate
                        ? formContent?.deliveryStartedDate
                        : "-"}
                    </>
                  )}
                </TableCell>
              </TableRow>
              {/* Delivery Completed Date */}
              <TableRow>
                <TableCell className="border p-1 w-25">
                  <b>Delivery Completed Date</b>
                </TableCell>
                <TableCell className="border p-1 w-75 text-start">
                  {isEdit ? (
                    <Input
                      id={"description"}
                      type="date"
                      style="full-border-field bg-app"
                      label={"Delivery Completed Date"}
                      value={formContent?.deliveryCompletedDate}
                      inline={{ width: "-webkit-fill-available" }}
                    />
                  ) : (
                    <>
                      {formContent?.deliveryCompletedDate
                        ? formContent?.deliveryCompletedDate
                        : "-"}
                    </>
                  )}
                </TableCell>
              </TableRow>

              {/* Shifting Started Date */}
              <TableRow>
                <TableCell className="border p-1 w-25">
                  <b>Shifting Started Date</b>
                </TableCell>
                <TableCell className="border p-1 w-75 text-start">
                  {isEdit ? (
                    <Input
                      id={"description"}
                      type="date"
                      style="full-border-field bg-app"
                      label={"Shifting Started Date"}
                      value={formContent?.shiftingStartedDate}
                      inline={{ width: "-webkit-fill-available" }}
                    />
                  ) : (
                    <>
                      {formContent?.shiftingStartedDate
                        ? formContent?.shiftingStartedDate
                        : "-"}
                    </>
                  )}
                </TableCell>
              </TableRow>
              {/* Shifting Completed Date */}
              <TableRow>
                <TableCell className="border p-1 w-25">
                  <b>Shifting Completed Date</b>
                </TableCell>
                <TableCell className="border p-1 w-75 text-start">
                  {isEdit ? (
                    <Input
                      id={"description"}
                      type="date"
                      style="full-border-field bg-app"
                      label={"Shifting Completed Date"}
                      value={formContent?.shiftingCompletedDate}
                      inline={{ width: "-webkit-fill-available" }}
                    />
                  ) : (
                    <>
                      {formContent?.shiftingCompletedDate
                        ? formContent?.shiftingCompletedDate
                        : "-"}
                    </>
                  )}
                </TableCell>
              </TableRow>
              {/* Warehouse In Date */}
              <TableRow>
                <TableCell className="border p-1 w-25">
                  <b>Warehouse In Date</b>
                </TableCell>
                <TableCell className="border p-1 w-75 text-start">
                  {isEdit ? (
                    <Input
                      id={"gross-weight"}
                      type="date"
                      style="full-border-field bg-app"
                      label={"Warehouse In Date"}
                      value={formContent?.wareHouseInDate}
                      inline={{ width: "-webkit-fill-available" }}
                    />
                  ) : (
                    <>
                      {formContent?.wareHouseInDate
                        ? formContent?.wareHouseInDate
                        : "-"}
                    </>
                  )}
                </TableCell>
              </TableRow>

              {/* Warehouse Out Date */}
              <TableRow>
                <TableCell className="border p-1 w-25">
                  <b>Warehouse Out Date</b>
                </TableCell>
                <TableCell className="border p-1 w-75 text-start">
                  {isEdit ? (
                    <Input
                      id={"gross-weight"}
                      type="date"
                      style="full-border-field bg-app"
                      label={"Warehouse Out Date"}
                      value={formContent?.wareHouseOutDate}
                      inline={{ width: "-webkit-fill-available" }}
                    />
                  ) : (
                    <>
                      {formContent?.wareHouseOutDate
                        ? formContent?.wareHouseOutDate
                        : "-"}
                    </>
                  )}
                </TableCell>
              </TableRow>
              {/* Shipment Date */}
              <TableRow>
                <TableCell className="border p-1 w-25">
                  <b>Shipment Date</b>
                </TableCell>
                <TableCell className="border p-1 w-75 text-start">
                  {isEdit ? (
                    <Input
                      id={"port-of-discharge"}
                      type="date"
                      style="full-border-field bg-app"
                      label={"Shipment Date"}
                      value={formContent?.shipmentDate}
                      inline={{ width: "-webkit-fill-available" }}
                    />
                  ) : (
                    <>
                      {formContent?.shipmentDate
                        ? formContent?.shipmentDate
                        : "-"}
                    </>
                  )}
                </TableCell>
              </TableRow>

              {/* ORIGIN/Destination Country */}
              <TableRow>
                <TableCell className="border p-1 w-25">
                  <b>Origin / Destination</b>
                </TableCell>
                <TableCell className="border p-1 w-75 text-start">
                  {isEdit ? (
                    <Input
                      id={"country"}
                      type="text"
                      style="full-border-field bg-app"
                      label={"Origin / Destination"}
                      value={formContent?.originDestination}
                      inline={{ width: "-webkit-fill-available" }}
                    />
                  ) : (
                    <>
                      {formContent?.originDestination
                        ? formContent?.originDestination
                        : "-"}
                    </>
                  )}
                </TableCell>
              </TableRow>

              {/* BOL/AWB No. */}
              <TableRow>
                <TableCell className="border p-1 w-25">
                  <b>BOL/AWB No.</b>
                </TableCell>
                <TableCell className="border p-1 w-75 text-start">
                  {isEdit ? (
                    <Input
                      id={"remarks"}
                      type="text"
                      style="full-border-field bg-app"
                      label={"BOL/AWB No."}
                      value={formContent?.bolAWBNo}
                      inline={{ width: "-webkit-fill-available" }}
                    />
                  ) : (
                    <>{formContent?.bolAWBNo ? formContent?.bolAWBNo : "-"}</>
                  )}
                </TableCell>
              </TableRow>

              {/* Container No. */}
              <TableRow>
                <TableCell className="border p-1 w-25">
                  <b>Container No.</b>
                </TableCell>
                <TableCell className="border p-1 w-75 text-start">
                  {isEdit ? (
                    <Input
                      id={"remarks"}
                      type="text"
                      style="full-border-field bg-app"
                      label={"Container No."}
                      value={formContent?.containerNo}
                      inline={{ width: "-webkit-fill-available" }}
                    />
                  ) : (
                    <>
                      {formContent?.containerNo
                        ? formContent?.containerNo
                        : "-"}
                    </>
                  )}
                </TableCell>
              </TableRow>

              {/* Vessel */}
              <TableRow>
                <TableCell className="border p-1 w-25">
                  <b>Vessel</b>
                </TableCell>
                <TableCell className="border p-1 w-75 text-start">
                  {isEdit ? (
                    <Input
                      id={"remarks"}
                      type="text"
                      style="full-border-field bg-app"
                      label={"Vessel"}
                      value={formContent?.vessel}
                      inline={{ width: "-webkit-fill-available" }}
                    />
                  ) : (
                    <>{formContent?.vessel ? formContent?.vessel : "-"}</>
                  )}
                </TableCell>
              </TableRow>

              {/* ETD/ETA */}
              <TableRow>
                <TableCell className="border p-1 w-25">
                  <b>ETD/ETA</b>
                </TableCell>
                <TableCell className="border p-1 w-75 text-start">
                  {isEdit ? (
                    <Input
                      id={"remarks"}
                      type="time"
                      style="full-border-field bg-app"
                      label={"ETD/ETA"}
                      value={formContent?.ETDbyETA}
                      inline={{ width: "-webkit-fill-available" }}
                    />
                  ) : (
                    <>{formContent?.ETDbyETA ? formContent?.ETDbyETA : "-"}</>
                  )}
                </TableCell>
              </TableRow>

              {/* Airline */}
              <TableRow>
                <TableCell className="border p-1 w-25">
                  <b>Airline</b>
                </TableCell>
                <TableCell className="border p-1 w-75 text-start">
                  {isEdit ? (
                    <Input
                      id={"remarks"}
                      type="text"
                      style="full-border-field bg-app"
                      label={"Airline"}
                      value={formContent?.airline}
                      inline={{ width: "-webkit-fill-available" }}
                    />
                  ) : (
                    <>{formContent?.airline ? formContent?.airline : "-"}</>
                  )}
                </TableCell>
              </TableRow>

              {/* Flight No. */}
              <TableRow>
                <TableCell className="border p-1 w-25">
                  <b>Flight No.</b>
                </TableCell>
                <TableCell className="border p-1 w-75 text-start">
                  {isEdit ? (
                    <Input
                      id={"remarks"}
                      type="text"
                      style="full-border-field bg-app"
                      label={"Flight No."}
                      value={formContent?.flightNo}
                      inline={{ width: "-webkit-fill-available" }}
                    />
                  ) : (
                    <>{formContent?.flightNo ? formContent?.flightNo : "-"}</>
                  )}
                </TableCell>
              </TableRow>

              {/* {data.map((name, index) => (
                <TableRow key={index}>
                  <TableCell className="border p-1 w-25">{name}</TableCell>
                  <TableCell className="border p-3 w-75 text-center">
                    {isEdit ? (
                      name === "Volume/Gross Weight" ? (
                        <Input
                          id={name}
                          type="number"
                          style="full-border-field"
                          label={name}
                        />
                      ) : (
                        <Input
                          id={name}
                          type="text"
                          style="full-border-field"
                          label={name}
                        />
                      )
                    ) : (
                      "-"
                    )}
                  </TableCell>
                </TableRow>
              ))} */}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Remarks */}
        {formContent?.remarks?.trim()?.length != 0 && (
          <div className="mt-1 pe-1 mb-0">
            <Typography
              variant="body1"
              className="text-start"
              gutterBottom
              style={{ fontSize: "10px" }}
            >
              <DynamicText
                label="Remarks / Instructions (If any)"
                textAreaLabel="Reamarks"
                isEdit={isEdit}
                value={formContent?.remarks}
                onChange={(e) => {
                  let values = { ...formContent };
                  values.remarks = e.target.value;
                }}
              />
            </Typography>
          </div>
        )}
        {/* /Office Instructions/remarks */}
        {/* /table */}

        <Row className="mt-1">
          <Col sm={6} className="">
            <div
              style={{ height: "90px" }}
              className="d-flex justify-content-center align-items-end"
            >
              {formContent?.managerSign?.trim()?.length == 0 ? (
                <></>
              ) : (
                <img
                  src={`${Bucket}${formContent?.managerSign}`}
                  height={80}
                  width={200}
                />
              )}
            </div>

            <p className="mb-0">{"_______________________________"}</p>
            <p className="my-0">
              <b>{"Manager Operations"}</b>
            </p>

            {""}
          </Col>
          <Col sm={6} className="">
            <div
              style={{ height: "90px" }}
              className="d-flex justify-content-center align-items-end"
            >
              {formContent?.assistOperSign?.trim()?.length == 0 ? (
                <></>
              ) : (
                <img
                  src={`${Bucket}${formContent.assistOperSign}`}
                  height={80}
                  width={200}
                />
              )}
            </div>
            <p className="mb-0">{"_______________________________"}</p>
            <p className="my-0">
              <b>{"Assistant Operations"}</b>
            </p>

            {""}
          </Col>
        </Row>
      </div>
    </>
  );
};
export default WorksheetImportDownload;
