import React, { useEffect, useState } from "react";
import {
  getSingleSurvey,
  surveyResultPreview,
  updateFormContentPreview,
  getFormContentPreview,
  imageUploadClientPreview,
} from "../../../../api/services/services";
import { Button, Snackbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import { Bucket } from "../../../../api/config/apiConfig";
import Input from "../../../atoms/Input";
import TextArea from "../../../atoms/Textarea";
import ContactDetailField from "../../../molecules/ContactDetailField";
import { useLocation } from "react-router-dom";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import QuotationFormDownload from "./download";
import html2pdf from "html2pdf.js";
import PrintComponent from "../../PrintComponent";
import callIcon from "../../../../imgs/call.svg";
import calenderIcon from "../../../../imgs/calendar.svg";
import calenderMove from "../../../../imgs/calendarMinus.svg";
import emailIcon from "../../../../imgs/email.svg";
import location from "../../../../imgs/location.svg";
import moment from "moment";
import MySignatureCanvas from "../../../molecules/signature_canvas";

const ClientQuote = () => {
  const [formContent, setFormContent] = useState({});
  const [formContentId, setFormContentId] = useState("");

  const [surveyData, setSurveyData] = useState([]);

  const [isEdit, setIsEdit] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [isSignCanvas, setSignCanvas] = useState(false);

  const location = useLocation();
  const urlData = new URLSearchParams(location.search);
  const email = urlData.get("email");
  const surveyId = urlData.get("surveyId");
  const formId = urlData.get("formId");
  const token = urlData.get("token");

  var totalAmount = 0;

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  //
  const updateContent = (data, md) => {
    setIsLoading(true);

    let media = [];
    if (formContent?.clientSign != "") media[0] = formContent?.clientSign;

    const body = {
      formContentId: formContentId,
      content: data ? data : formContent,
      mediaKeys: md,
      removedMediaKeys: [],
    };
    updateFormContentPreview(
      surveyId,
      formId,
      email,
      token,
      body,
      formContentId
    )
      .then((res) => {
        setSnackbarMessage("Quotation Form has been accepted successfully!");

        setOpenSnackbar(true);
        getContent();
        setIsEdit(false);

        setIsLoading(false);
        setUploadText("Upload Signature & Quote Acceptance");
        setMediaKeysToSend([]);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          setIsValid(false);
        }
        setUploadText("Upload Signature & Quote Acceptance");
        setIsLoading(false);
      });
  };

  const [isValid, setIsValid] = useState(false);

  //
  const getContent = () => {
    setIsLoading(true);
    setMediaKeysToSend([]);
    getFormContentPreview(surveyId, formId, email, token)
      .then((res) => {
        setIsValid(true);
        setFormContentId(res.data.data.uuid);
        setFormContent(res.data.data.content);

        setIsLoading(false);
      })
      .catch((error) => {
        setIsValid(false);
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        setIsLoading(false);
      });
  };

  useEffect(() => {
    getContent();
  }, []);

  const [show, setShow] = useState(false);
  const options = {
    // resolution: Resolution.HIGH,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      format: "letter",
      // default is 'portrait'
      orientation: "portrait",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/png",
      qualityRatio: 1,
    },
    overrides: {
      pdf: {
        compress: true,
      },
    },
  };

  //
  const generatePdf = () => {
    setShow(true);

    setTimeout(() => {
      const content = document.getElementById("content-to-convert"); // Replace with the ID of the element you want to convert to PDF

      const currentDateTime = new Date();
      const filename = `survey_${currentDateTime.toISOString()}.pdf`;

      const pdfOptions = {
        margin: 5,
        filename: filename,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
        }, // Set the page size here
      };

      let temp = html2pdf().from(content).set(pdfOptions).outputPdf();
      temp.save(filename);

      setShow(false);
    }, 3500);
  };

  const [downloadText, setDownloadText] = useState("download");

  const getTargetElement = () => document.getElementById("content-id");

  const [uploadText, setUploadText] = useState(
    "Upload Signature & Quote Acceptance"
  );
  const [mediaKeysToSend, setMediaKeysToSend] = useState([]);
  //
  const uploadFile = async (file) => {

    setIsLoading(true);

    setUploadText("Uploading...");
    const fd = new FormData();
    fd.append("image", file);

    imageUploadClientPreview(fd, surveyId, formId, email, token)
      .then((res) => {
        let values = { ...formContent };
        values.clientSign = res?.data?.data?.key;
        setFormContent(values);

        let md = [...mediaKeysToSend];
        md.push(res?.data?.data?.key);
        setMediaKeysToSend(md);

        setTimeout(() => {
          updateContent(values, md);
        }, 1500);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          setIsValid(false);
        }

        setUploadText("Upload Signature & Quote Acceptance");

        setIsLoading(false);
      });

  };

  const printAction = () => {
    const PAGE_HEIGHT = 1045; // Ensure this matches your actual printable area height
    const printElement = document.getElementById("print-component");

    if (printElement) {
      printElement.classList.add("temp-class-for-height");
      const height = printElement.getBoundingClientRect().height - 500;
      const numberOfPages = Math.ceil(height / PAGE_HEIGHT);
      let requiredHeight = numberOfPages * PAGE_HEIGHT;

      if (numberOfPages > 1) {
        const header = printElement.getElementsByTagName("thead")[0];
        const footer = printElement.getElementsByTagName("tfoot")[0];
        const headerHeight = header ? header.getBoundingClientRect().height : 0;
        const footerHeight = footer ? footer.getBoundingClientRect().height : 0;
        requiredHeight -= (numberOfPages - 1) * (headerHeight + footerHeight);
      }

      printElement.style.height = `${requiredHeight}px`;
      printElement.classList.remove("temp-class-for-height");
    }

    window.print();

    if (printElement) {
      printElement.style.height = `auto`;
    }
  };

  let movableItems = 0;
  let nonmovableItems = 0;

  //
  const getSurvey = () => {
    getSingleSurvey(surveyId)
      .then((response) => {
        // if (response?.data?.data?.status == "submitted") getSurveyPreview();
        // else {
        setSurveyData(response?.data?.data);
        // }
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode == 401) {
          setIsValid(false);
        }
      });
  };

  //
  const getSurveyPreview = () => {
    surveyResultPreview(surveyId)
      .then((response) => {
        setSurveyData(response?.data?.data?.surveyDetail);
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode == 401) {
          setIsValid(false);
        }
      });
  };

  useEffect(() => {
    getSurvey();
  }, []);

  const convertDate = (unixTimestamp) => {
    const date = new Date(unixTimestamp * 1000); // Multiply by 1000 to convert from seconds to milliseconds
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1 and format with leading zero
    const dd = String(date.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // 11th, 12th, 13th, etc.
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  // Helper function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const ordinalSuffix = getOrdinalSuffix(day);

    return `${day}${ordinalSuffix} ${month}, ${year}`;
  };

  return (
    <>
      {isValid ? (
        <div className="mx-3 mx-md-5">
          {/* <MainTemplate> */}
          <div>
            <div className="no-print">
              <div className="d-flex align-items-center py-4">
                <p className="main-head ps-0 mb-0 w-50 text-end">QUOTATION</p>
                <div className="d-flex ms-auto">
                  {formContent?.clientSign?.trim()?.length == 0 && (
                    <div className="">
                      <label
                        htmlFor="uploadSign"
                        className="active-btn ms-2 w-225 d-flex align-items-center justify-content-center cursor-pointer btn py-2 quote-btnn"
                      >
                        {uploadText}
                      </label>
                      <input
                        type="button"
                        hidden
                        id="uploadSign"
                        name="uploadSign"
                        onClick={() => {
                          setSignCanvas(true)
                        }}
                      />
                    </div>
                  )}

                  {/* Download Button */}
                  <Button
                    className="pr-btn ms-3 w-150 quote-btnn"
                    // onClick={() => generatePdf()}
                    onClick={() => {
                      setIsLoading(true);
                      // printAction();
                      var tempTitle = document.title;
                      document.title = `Quotation-${formContent?.to}-${moment(
                        new Date()
                      ).format("DD.MM.YYYY")}`;

                      window.print();
                      document.title = tempTitle;
                      setIsLoading(false);

                      // setShow(true);
                      // setDownloadText("Downloading..");
                      // setTimeout(() => {
                      //   // toPDF()
                      //   generatePDF(getTargetElement, options);
                      // }, 500);
                      // setTimeout(() => {
                      //   setDownloadText("Download");
                      //   setShow(false);
                      // }, 1000);
                    }}
                    disabled={isLoading}
                  >
                    {"Save as PDF"}
                  </Button>
                </div>
              </div>

              {/* <div className="d-flex align-items-center py-4">
              <div className="d-flex align-items-center ">
                <p className="main-head ps-0 mb-0">Quotation Form</p>
              </div>
              <div className="d-flex ms-auto justify-content-center mt-2 mt-sm-0">
                {formContent?.clientSign?.trim()?.length == 0 && (
                  <>
                    <label
                      htmlFor="uploadSign"
                      className="active-btn ms-2 w-200 d-flex align-items-center justify-content-center cursor-pointer btn"
                    >
                      {uploadText}
                    </label>
                    <input
                      type="file"
                      hidden
                      id="uploadSign"
                      name="uploadSign"
                      onChange={(e) => {
                        if (!isLoading) uploadFile(e);
                      }}
                    />
                  </>
                )}

                {/* Download Button */}
              {/* <Button
                  className="pr-btn ms-3 w-150"
                  // onClick={() => generatePdf()}
                  onClick={() => {
                    setIsLoading(true);
                    // printAction();
                    window.print();
                    setIsLoading(false);

                    // setShow(true);
                    // setDownloadText("Downloading..");
                    // setTimeout(() => {
                    //   // toPDF()
                    //   generatePDF(getTargetElement, options);
                    // }, 500);
                    // setTimeout(() => {
                    //   setDownloadText("Download");
                    //   setShow(false);
                    // }, 1000);
                  }}
                  disabled={isLoading}
                >
                  {"Save as PDF"}
                </Button> */}
              {/* </div>
            </div> */}
              {/* contact detail*/}
              <div className="contact-detail d-flex mt-2">
                {/* left col */}
                <div
                  className={`left-col ${!isEdit ? " w-75" : "w-50 pe-5"
                    } text-start`}
                >
                  <Typography variant="body1" gutterBottom>
                    <ContactDetailField
                      label="To"
                      isEdit={isEdit}
                      type="text"
                      value={formContent?.to}
                      onChange={(e) => {
                        console.log(e.target.value);
                        let values = { ...formContent };
                        values.to = e.target.value;
                        setFormContent(values);
                      }}
                    />
                    <ContactDetailField
                      label="Location"
                      isEdit={isEdit}
                      type="text"
                      value={formContent?.location}
                      onChange={(e) => {
                        console.log(e.target.value);
                        let values = { ...formContent };
                        values.location = e.target.value;
                        setFormContent(values);
                      }}
                    />
                    <ContactDetailField
                      label="Email"
                      isEdit={isEdit}
                      type="email"
                      value={formContent?.email}
                      onChange={(e) => {
                        let values = { ...formContent };
                        values.email = e.target.value;
                        setFormContent(values);
                      }}
                    />
                  </Typography>
                </div>
                {/* right col */}
                <div
                  className={`right-col ${!isEdit ? "w-25" : "w-50 ps-5"
                    }  d-flex flex-column align-items-end pe-2`}
                >
                  <div className="">
                    <Typography
                      variant="body1"
                      className="text-start w-100"
                      gutterBottom
                    >
                      <ContactDetailField
                        label="Date"
                        isEdit={isEdit}
                        type="date"
                        wrapperStyle="justify-content-start"
                        value={formContent?.date}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.date = e.target.value;
                          setFormContent(values);
                        }}
                      />
                      {/* <ContactDetailField
                      label="Invoice #"
                      isEdit={isEdit}
                      type="number"
                      wrapperStyle="justify-content-start"
                      value={formContent?.invoiceNo}
                      onChange={(e) => {
                        let values = { ...formContent };
                        values.invoiceNo = e.target.value;
                        setFormContent(values);
                      }}
                    /> */}
                      <ContactDetailField
                        label="Tel"
                        isEdit={isEdit}
                        type="number"
                        wrapperStyle="justify-content-start"
                        value={formContent?.phoneNo}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.phoneNo = e.target.value;
                          setFormContent(values);
                        }}
                      />
                    </Typography>
                  </div>
                </div>
              </div>
              {/* /contact detail */}

              {/* info */}
              <div className="mt-5 pe-1">
                <Typography variant="body1" className="text-start" gutterBottom>
                  <div className="mb-2">
                    <label
                      htmlFor="to"
                      className={`fw-bold ${isEdit ? "mb-3" : "mb-1"}`}
                    >
                      Dear {formContent?.recipient},
                    </label>
                    {!isEdit ? (
                      <p>{formContent?.details ? formContent?.details : "-"}</p>
                    ) : (
                      <TextArea
                        rows="3"
                        label="Details here..."
                        id="description"
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.details = e.target.value;
                          setFormContent(values);
                        }}
                        value={formContent?.details}
                      />
                    )}
                  </div>
                </Typography>
              </div>
              {/* /info */}

              {/* description */}
              <div className="mt-5 pe-1">
                <Typography variant="body1" className="text-start" gutterBottom>
                  <div
                    className={`table-text-wrapper mb-2 d-flex ${isEdit ? "flex-column" : ""
                      } justify-content-between`}
                  >
                    <label
                      htmlFor="description"
                      className={`fw-bold  ${isEdit ? "mb-3" : "mb-1"}`}
                    >
                      Description
                    </label>
                    {!isEdit ? (
                      <span className="table-text w-50 text-end">
                        {formContent?.description
                          ? formContent?.description
                          : "-"}
                      </span>
                    ) : (
                      <TextArea
                        rows="3"
                        label="Description..."
                        id="description"
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.description = e.target.value;
                          setFormContent(values);
                        }}
                        value={formContent?.description}
                      />
                    )}
                  </div>
                </Typography>
                <hr />
              </div>
              {/* /description */}

              {/* Service Required */}
              <div className="mt-5 pe-1">
                <Typography variant="body1" className="text-start" gutterBottom>
                  <div
                    className={`table-text-wrapper mb-2 d-flex ${isEdit ? "flex-column" : ""
                      } justify-content-between`}
                  >
                    <label
                      htmlFor="serviceRequired"
                      className={`fw-bold  ${isEdit ? "mb-3" : "mb-1"}`}
                    >
                      Service Required
                    </label>
                    {!isEdit && (
                      <span className="table-text w-50 text-end">
                        {formContent?.serviceRequired
                          ? formContent?.serviceRequired
                          : "-"}
                      </span>
                    )}
                    {isEdit && (
                      <TextArea
                        rows="3"
                        label="Service Required..."
                        id="serviceRequired"
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.serviceRequired = e.target.value;
                          setFormContent(values);
                        }}
                        value={formContent?.serviceRequired}
                      />
                    )}
                  </div>
                </Typography>
                <hr />
              </div>
              {/* /Service Required */}

              {/* Requested Packing Date */}
              <div className="mt-5 pe-1">
                <Typography variant="body1" className="text-start" gutterBottom>
                  <div className="table-text-wrapper mb-2 d-flex justify-content-between">
                    <div className="w-50">
                      <label htmlFor="packingDate" className="fw-bold mb-1">
                        Tentative/Requested Packing Date
                      </label>
                    </div>
                    {!isEdit && (
                      <span className="table-text w-50 text-end">
                        {formContent?.packingDate
                          ? formatDate(formContent?.packingDate)
                          : "-"}
                      </span>
                    )}
                    {isEdit && (
                      <Input
                        id="packingDate"
                        type="date"
                        style="full-border-field"
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.packingDate = e.target.value;
                          setFormContent(values);
                        }}
                        value={formContent?.packingDate}
                      />
                    )}
                  </div>
                </Typography>
                <hr />
              </div>
              {/* /Requested Packing Date */}

              {/* Origin Address */}
              <div className="mt-5 pe-1">
                <Typography variant="body1" className="text-start" gutterBottom>
                  <div
                    className={`table-text-wrapper mb-2 d-flex ${isEdit ? "flex-column" : ""
                      } justify-content-between`}
                  >
                    <label
                      htmlFor="serviceRequired"
                      className={`fw-bold  ${isEdit ? "mb-3" : "mb-1"}`}
                    >
                      Origin Address
                    </label>
                    {!isEdit && (
                      <span className="table-text w-50 text-end">
                        {" "}
                        {formContent?.originAddress
                          ? formContent?.originAddress
                          : "-"}
                      </span>
                    )}
                    {isEdit && (
                      <TextArea
                        rows="3"
                        label="Origin Address..."
                        id="originAddress"
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.originAddress = e.target.value;
                          setFormContent(values);
                        }}
                        value={formContent?.originAddress}
                      />
                    )}
                  </div>
                </Typography>
                <hr />
              </div>
              {/* /Origin Address */}

              {/* Destination Address */}
              <div className="mt-5 pe-1">
                <Typography variant="body1" className="text-start" gutterBottom>
                  <div
                    className={`table-text-wrapper mb-2 d-flex ${isEdit ? "flex-column" : ""
                      } justify-content-between`}
                  >
                    <label
                      htmlFor="serviceRequired"
                      className={`fw-bold  ${isEdit ? "mb-3" : "mb-1"}`}
                    >
                      Destination Address
                    </label>
                    {!isEdit && (
                      <span className="table-text w-50 text-end">
                        {formContent?.destinationAddress
                          ? formContent?.destinationAddress
                          : "-"}
                      </span>
                    )}
                    {isEdit && (
                      <TextArea
                        rows="3"
                        label="Destination Address..."
                        id="destinationAddress"
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.destinationAddress = e.target.value;
                          setFormContent(values);
                        }}
                        value={formContent?.destinationAddress}
                      />
                    )}
                  </div>
                </Typography>
                <hr />
              </div>
              {/* /Destination Address */}

              {/* Estimated Weight */}
              <div className="mt-5 pe-1">
                <Typography variant="body1" className="text-start" gutterBottom>
                  <div className="table-text-wrapper mb-2 d-flex justify-content-between">
                    <label htmlFor="estimatedWeight" className="fw-bold mb-1">
                      Estimated Weight
                    </label>
                    {!isEdit && (
                      <span className="table-text w-50 text-end">
                        {formContent?.estimatedWeight
                          ? `${formContent?.estimatedWeight}`
                          : "-"}
                      </span>
                    )}
                    {isEdit && (
                      <Input
                        id="estimatedWeight"
                        label="Estimated Weight..."
                        type="text"
                        style="full-border-field"
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.estimatedWeight = e.target.value;
                          setFormContent(values);
                        }}
                        value={formContent?.estimatedWeight}
                      />
                    )}
                  </div>
                </Typography>
                <hr />
              </div>
              {/* /Estimated Weight */}

              {/* Estimated Volume */}
              <div className="mt-5 pe-1">
                <Typography variant="body1" className="text-start" gutterBottom>
                  <div className="table-text-wrapper mb-2 d-flex justify-content-between">
                    <label htmlFor="estimatedVolume" className="fw-bold mb-1">
                      Estimated Volume
                    </label>
                    {!isEdit ? (
                      <span className="table-text w-50 text-end">
                        {formContent?.estimatedVolume ? (
                          <>
                            {Number(formContent?.estimatedVolume)?.toFixed(2)} m{" "}
                            <sup>3</sup>{" "}
                          </>
                        ) : (
                          "-"
                        )}
                      </span>
                    ) : (
                      <Input
                        id="estimatedVolume"
                        label="Estimated Volume..."
                        type="text"
                        style="full-border-field"
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.estimatedVolume = e.target.value;
                          setFormContent(values);
                        }}
                        value={formContent?.estimatedVolume}
                      />
                    )}
                  </div>
                </Typography>
                <hr />
              </div>
              {/* /Estimated Volume */}

              {/* Transportation Mode */}
              <div className="mt-5 pe-1">
                <Typography variant="body1" className="text-start" gutterBottom>
                  <div className="table-text-wrapper mb-2 d-flex justify-content-between">
                    <label
                      htmlFor="transportationMode"
                      className="fw-bold mb-1"
                    >
                      Transportation Mode
                    </label>
                    {!isEdit ? (
                      <span className="table-text w-50 text-end">
                        {formContent?.transportationMode
                          ? formContent?.transportationMode
                          : "-"}
                      </span>
                    ) : (
                      <Input
                        id="transportationMode"
                        label="Transportation Mode..."
                        type="text"
                        style="full-border-field"
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.transportationMode = e.target.value;
                          setFormContent(values);
                        }}
                        value={formContent?.transportationMode}
                      />
                    )}
                  </div>
                </Typography>
                <hr />
              </div>
              {/* /Transportation Mode */}

              {/* Charges up to MOSCOW airport */}
              <div className="mt-5 pe-1">
                <Typography variant="body1" className="text-start" gutterBottom>
                  <div className="table-text-wrapper mb-2 d-flex justify-content-between">
                    <label htmlFor="MoscowCharges" className="fw-bold mb-1">
                      Complete Charges
                    </label>
                    {!isEdit ? (
                      <span className="table-text w-50 text-end">
                        {formContent?.charges ? formContent?.charges : "-"}
                      </span>
                    ) : (
                      <Input
                        id="MoscowCharges"
                        label="Complete Charges..."
                        type="number"
                        style="full-border-field"
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.charges = e.target.value;
                          setFormContent(values);
                        }}
                        value={formContent?.charges}
                      />
                    )}
                  </div>
                </Typography>
                <hr />
              </div>
              {/* /Charges up to MOSCOW airport*/}

              {/* Quote Validity */}
              <div className="mt-5 pe-1">
                <Typography variant="body1" className="text-start" gutterBottom>
                  <div className="mb-2">
                    <label
                      htmlFor="quoteValidity"
                      className={`fw-bold ${isEdit ? "mb-3" : "mb-1"}`}
                    >
                      Quote Validity:
                    </label>
                    {!isEdit ? (
                      <p>
                        {formContent?.quoteValidity
                          ? formContent?.quoteValidity
                          : "-"}
                      </p>
                    ) : (
                      <TextArea
                        rows="3"
                        label="Quote Validity..."
                        id="quoteValidity"
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.quoteValidity = e.target.value;
                          setFormContent(values);
                        }}
                        value={formContent?.quoteValidity}
                      />
                    )}
                  </div>
                </Typography>
              </div>
              {/* /Quote Validity */}

              {/* Price Include at Origin */}
              <div className="mt-5 pe-1">
                <Typography variant="body1" className="text-start" gutterBottom>
                  <div className="mb-2">
                    <label
                      htmlFor="priceInclude"
                      className={`fw-bold ${isEdit ? "mb-3" : "mb-1"}`}
                    >
                      Price Include at Origin:
                    </label>
                    {!isEdit ? (
                      <p>
                        {formContent?.priceIncludeAtOrigin
                          ? formContent?.priceIncludeAtOrigin
                          : "-"}
                      </p>
                    ) : (
                      <TextArea
                        rows="3"
                        label="Price Include at Origin..."
                        id="priceInclude"
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.priceIncludeAtOrigin = e.target.value;
                          setFormContent(values);
                        }}
                        value={formContent?.priceIncludeAtOrigin}
                      />
                    )}
                  </div>
                </Typography>
              </div>
              {/* /Price Include at Origin */}

              {/* Standard Exclusions */}
              <div className="mt-5 pe-1">
                <Typography variant="body1" className="text-start" gutterBottom>
                  <div className="mb-2">
                    <label
                      htmlFor="standardExclusions"
                      className={`fw-bold ${isEdit ? "mb-3" : "mb-1"}`}
                    >
                      Standard Exclusions:
                    </label>
                    {!isEdit ? (
                      <p>
                        {formContent?.standardExclusions
                          ? formContent?.standardExclusions
                          : "-"}
                      </p>
                    ) : (
                      <TextArea
                        rows="3"
                        label="Standard Exclusions..."
                        id="standardExclusions"
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.standardExclusions = e.target.value;
                          setFormContent(values);
                        }}
                        value={formContent?.standardExclusions}
                      />
                    )}
                  </div>
                </Typography>
              </div>
              {/* /Standard Exclusions: */}

              {/* Insurance */}
              <div className="mt-5 pe-1">
                <Typography variant="body1" className="text-start" gutterBottom>
                  <div className="mb-2">
                    <label
                      htmlFor="insurance"
                      className={`fw-bold ${isEdit ? "mb-3" : "mb-1"}`}
                    >
                      Insurance:
                    </label>
                    {!isEdit ? (
                      <p>
                        {formContent?.insurance ? formContent?.insurance : "-"}{" "}
                      </p>
                    ) : (
                      <TextArea
                        rows="3"
                        label="Insurance..."
                        id="insurance"
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.insurance = e.target.value;
                          setFormContent(values);
                        }}
                        value={formContent?.insurance}
                      />
                    )}
                  </div>
                </Typography>
              </div>
              {/* /Insurance: */}

              {/* Documents Required */}
              <div className="mt-5 pe-1">
                <Typography variant="body1" className="text-start" gutterBottom>
                  <div className="mb-2">
                    <label
                      htmlFor="documentsRequired"
                      className={`fw-bold ${isEdit ? "mb-3" : "mb-1"}`}
                    >
                      Documents Required:
                    </label>
                    {!isEdit ? (
                      <p>
                        {formContent?.documentsRequired
                          ? formContent?.documentsRequired
                          : "-"}
                      </p>
                    ) : (
                      <TextArea
                        rows="3"
                        label="Documents Required..."
                        id="documentsRequired"
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.documentsRequired = e.target.value;
                          setFormContent(values);
                        }}
                        value={formContent?.documentsRequired}
                      />
                    )}
                  </div>
                </Typography>
              </div>
              {/* /Documents Required */}

              {/* Payment Terms */}
              <div className="mt-5 pe-1">
                <Typography variant="body1" className="text-start" gutterBottom>
                  <div className="mb-2">
                    <label
                      htmlFor="paymentTerms"
                      className={`fw-bold ${isEdit ? "mb-3" : "mb-1"}`}
                    >
                      Payment Terms:
                    </label>
                    {!isEdit ? (
                      <p>
                        {formContent?.paymentTerms
                          ? formContent?.paymentTerms
                          : "-"}
                      </p>
                    ) : (
                      <TextArea
                        rows="3"
                        label="Payment Terms..."
                        id="paymentTerms"
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.paymentTerms = e.target.value;
                          setFormContent(values);
                        }}
                        value={formContent?.paymentTerms}
                      />
                    )}
                  </div>
                </Typography>
              </div>
              {/* /Payment Terms: */}

              {/* Bank Details */}
              <div className="mt-5 pe-1 mb-5 row">
                <Typography
                  variant="body1"
                  className="text-start col-8"
                  gutterBottom
                >
                  <div className="mb-2">
                    <label htmlFor="to" className="mb-2 fw-bold">
                      Bank Details:
                    </label>
                    <Typography variant="body1" gutterBottom>
                      <ContactDetailField
                        label=" IBAN No"
                        isEdit={isEdit}
                        type="text"
                        style="fw-normal"
                        value={formContent?.bankDetails?.iban}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.bankDetails.iban = e.target.value;
                          setFormContent(values);
                        }}
                      />
                      <ContactDetailField
                        label="Account Title"
                        isEdit={isEdit}
                        type="text"
                        style="fw-normal"
                        value={formContent?.bankDetails?.title}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.bankDetails.title = e.target.value;
                          setFormContent(values);
                        }}
                      />
                      <ContactDetailField
                        label="Swift Code"
                        isEdit={isEdit}
                        type="text"
                        style="fw-normal"
                        value={formContent?.bankDetails?.swiftCode}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.bankDetails.swiftCode = e.target.value;
                          setFormContent(values);
                        }}
                      />
                      <ContactDetailField
                        label="Branch Name & Code"
                        isEdit={isEdit}
                        type="text"
                        style="fw-normal"
                        value={formContent?.bankDetails?.branchNameCode}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.bankDetails.branchNameCode = e.target.value;
                          setFormContent(values);
                        }}
                      />
                    </Typography>
                  </div>
                </Typography>
              </div>
              {/* /Bank Details */}

              {/* Signatures */}
              <Row className="mt-5 pt-5 mb-10">
                <Col sm={6} className="mt-5 pt-5">
                  <div
                    style={{ height: "90px" }}
                    className="d-flex justify-content-center align-items-end"
                  >
                    {formContent.clientSign != "" &&
                      formContent.clientSign != undefined && (
                        <img
                          src={`${Bucket}${formContent.clientSign}`}
                          height={80}
                          width={200}
                        />
                      )}
                  </div>

                  <p>{"_______________________________"}</p>
                  <p className="my-1">
                    <b>{"QUOTATION Acceptance"}</b>
                  </p>

                  {""}
                </Col>

                <Col sm={6} className="mt-5 pt-5">
                  <div
                    style={{ height: "90px" }}
                    className="d-flex justify-content-center align-items-end"
                  >
                    {formContent?.adminSign?.trim()?.length == 0 ? (
                      <div className="d-flex justify-content-center align-items-end"></div>
                    ) : (
                      <img
                        src={`${Bucket}${formContent.adminSign}`}
                        height={80}
                        width={200}
                      />
                    )}
                  </div>
                  <p>{"_______________________________"}</p>
                  <p className="my-1">
                    <b>{"Shelozon Movers Packers & Logistics Pvt Ltd"}</b>
                  </p>

                  {""}
                </Col>
              </Row>

              {/* {surveyData?.status == "submitted" && (
              <Row className="mx-0 px-2 py-3 preview-card mb-4" style={{marginTop: '120px'}}>
                <Row>
                  <Col md={12} className="text-start">
                    <h3 className="fn">
                      <b>{surveyData?.name}'s Move</b>
                    </h3>
                  </Col>
                </Row>
                <Row style={{ justifyContent: "space-between" }}>
                  <Col sm={6} md={3} className="mb-2">
                    <div className="d-flex align-items-start">
                      <div className="white-circle mt-1">
                        <img src={emailIcon} className="img-pr" />
                      </div>
                      <div className="ps-2">
                        <p className="surve-name mb-0">
                          <b>Email</b>
                        </p>
                        <p className="surve-email mb-0 p-word">
                          {surveyData?.email}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col sm={6} md={2} className="mb-2">
                    <div className="d-flex align-items-start">
                      <div className="white-circle mt-1">
                        <img src={callIcon} className="img-pr" />
                      </div>
                      <div className="ms-2">
                        <p className="surve-name mb-0">Phone No.</p>
                        <p className="surve-email mb-0 p-word">
                          {surveyData?.loadPlacePhone}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col sm={6} md={3} className="mb-2">
                    <div className="d-flex align-items-start">
                      <div className="white-circle mt-1">
                        <img
                          src={location}
                          height={24}
                          width={24}
                          className="img-pr"
                        />
                      </div>
                      <div className="ms-2">
                        <p className="surve-name mb-0">Location</p>
                        <p className="surve-email mb-0 p-word">
                          {surveyData?.loadPlaceStAddress}
                        </p>
                      </div>
                    </div>
                  </Col>

                  <Col sm={6} md={2} className="mb-2">
                    <div className="d-flex align-items-start">
                      <div className="white-circle mt-1">
                        <img src={calenderIcon} className="img-pr" />
                      </div>
                      <div className="ms-2">
                        <p className="surve-name mb-0">Created Date</p>
                        <p className="surve-email mb-0 p-word">
                          {surveyData?.createdAt
                            ? convertDate(surveyData?.createdAt)
                            : "-"}
                        </p>
                      </div>
                    </div>
                  </Col>

                  <Col sm={6} md={2} className="mb-2">
                    <div className="d-flex align-items-start">
                      <div className="white-circle mt-1">
                        <img src={calenderMove} className="img-pr" />
                      </div>
                      <div className="ms-2">
                        <p className="surve-name mb-0">Move Date</p>
                        <p className="surve-email mb-0 p-word">
                          {surveyData?.loadDate
                            ? convertDate(surveyData?.loadDate)
                            : "-"}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Row>
            )} */}

              {/* {surveyData?.status == "submitted" && (
              <>
                {surveyData?.surveyResult?.surveyRooms?.length > 0 && (
                  <Row className="mx-1 mb-3">
                    <Col sm={12} md={12} className="text-start px-0 ">
                      <h4><b>Rooms Detail</b></h4>
                    </Col>
                  </Row>
                )}
              </>
            )} */}

              {/* {surveyData?.surveyResult?.surveyRooms?.length > 0 &&
              surveyData?.surveyResult?.surveyRooms?.map((sr, ind) => {
                return (
                  <Row
                    className="bg-white my-4 mx-0  p-3 py-4 mb-4"
                    style={{ borderRadius: "10px" }}
                  >
                    <Row className="">
                      <Col sm={12} md={12} className="text-start px-0 left-col">
                        <h4 className="fn text-capitalize">
                          <b>
                            {ind + 1}
                            {". "}
                            {sr?.name}
                          </b>
                        </h4>
                      </Col>
                    </Row>
                    <Row>
                      {/* Images */}
              {/* <Col sm={6} md={6} className="px-10">
                        <div className="d-flex flex-wrap">
                          {sr?.images?.map((image) => {
                            return (
                              <>
                                <div>
                                  <img
                                    src={Bucket + image}
                                    height={290}
                                    width={210}
                                    // style={{ borderRadius: "10px" }}
                                    className="me-2 mb-2"
                                  />
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </Col> */}

              {/* Details */}
              {/* <Col sm={6} md={6} className="px-10 ">
                        <Row> */}
              {/* Moving */}
              {/* <Col
                            sm={12}
                            md={12}
                            className="px-0 bg-white card-border"
                          > */}
              {/* <div className="bg-white card-border"> */}
              {/* <Row
                              className="px-3 mx-0"
                              style={{
                                backgroundColor: "#00aff0 ",
                                color: "white",
                              }}
                            >
                              <Col
                                sm={12}
                                md={12}
                                className="text-start px-0 left-col"
                              >
                                <h5 className="fn text-capitalize my-2">
                                  <b>Moving</b>
                                </h5>
                              </Col>
                            </Row> */}
              {/* 
                            {sr?.roomItems?.map((item) => {
                              if (item?.type == "movable")
                                movableItems = movableItems + 1;
                              if (item?.type == "nonmovable")
                                nonmovableItems = nonmovableItems + 1;

                              return <></>;
                            })} */}
              {/* 
                            {movableItems > 0 ? (
                              <>
                                {sr?.roomItems?.map((item) => {
                                  return (
                                    <>
                                      {item?.type == "movable" && (
                                        <Row className="detail-row mx-3 detail-row-mg">
                                          <Col
                                            xs={7}
                                            sm={6}
                                            md={7}
                                            className="text-start px-0 left-col pt-2 card-item"
                                          >
                                            {item?.name ? item?.name : "-"}
                                          </Col>
                                          <Col
                                            xs={2}
                                            sm={2}
                                            md={2}
                                            className="text-end px-0 left-col pt-2 card-item"
                                            style={{ fontWeight: "500" }}
                                          >
                                            {item?.quantity
                                              ? `${item?.quantity}x`
                                              : "-"}
                                          </Col>
                                          <Col
                                            xs={3}
                                            sm={4}
                                            md={3}
                                            className="text-end px-0 right-col pt-2 card-item"
                                          >
                                            {item?.cbm ? item?.cbm : "-"} cm
                                            <sup>3</sup>
                                          </Col>
                                        </Row>
                                      )}
                                    </>
                                  );
                                })}
                              </>
                            ) : (
                              <Row className="detail-row mx-3 text-center">
                                <i>No Items</i>
                              </Row>
                            )} */}

              {/* </div> */}
              {/* </Col> */}

              {/* Not Moving */}
              {/* <Col
                            sm={12}
                            md={12}
                            className="px-0 bg-white card-border mt-4"
                          > */}
              {/* <div className="bg-white card-border"> */}
              {/* <Row
                              className="px-3 mx-0"
                              style={{
                                backgroundColor: "#00aff0 ",
                                color: "white",
                              }}
                            >
                              <Col
                                sm={12}
                                md={12}
                                className="text-start px-0 left-col"
                              >
                                <h5 className="fn text-capitalize my-2">
                                  <b>Not Moving</b>
                                </h5>
                              </Col>
                            </Row> */}
              {/* {nonmovableItems > 0 ? (
                              <>
                                {sr?.roomItems?.map((item) => {
                                  return (
                                    <>
                                      {item?.type == "nonmovable" && (
                                        <Row className="detail-row mx-3 detail-row-mg">
                                          <Col
                                            xs={7}
                                            sm={6}
                                            md={7}
                                            className="text-start px-0 left-col pt-2 card-item"
                                          >
                                            {item?.name ? item?.name : "-"}
                                          </Col>
                                          <Col
                                            xs={2}
                                            sm={2}
                                            md={2}
                                            className="text-end px-0 left-col pt-2 card-item"
                                            style={{ fontWeight: "500" }}
                                          >
                                            {item?.quantity
                                              ? `${item?.quantity}x`
                                              : "-"}
                                          </Col>
                                          <Col
                                            xs={3}
                                            sm={4}
                                            md={3}
                                            className="text-end px-0 right-col pt-2 card-item"
                                          >
                                            {item?.cbm ? item?.cbm : "-"} cm
                                            <sup>3</sup>
                                          </Col>
                                        </Row>
                                      )}
                                    </>
                                  );
                                })}
                              </>
                            ) : (
                              <Row className="detail-row mx-3 text-center">
                                <i>No Items</i>
                              </Row>
                            )} */}

              {/* </div> */}
              {/* </Col> */}

              {/* Packing Material */}
              {/* <Col
                            sm={12}
                            md={12}
                            className="px-0 bg-white card-border mt-4"
                          > */}
              {/* <div className="bg-white card-border"> */}
              {/* <Row
                              className="px-3 mx-0"
                              style={{
                                backgroundColor: "#00aff0 ",
                                color: "white",
                              }}
                            >
                              <Col
                                sm={12}
                                md={12}
                                className="text-start px-0 left-col"
                              >
                                <h5 className="fn text-capitalize my-2">
                                  <b>Packing Material</b>
                                </h5>
                              </Col>
                            </Row> */}

              {/* {sr?.packingMaterial?.map((pack) => {
                              return (
                                <>
                                  <Row className="detail-row mx-3 detail-row-mg">
                                    <Col
                                      xs={7}
                                      sm={6}
                                      md={7}
                                      className="text-start px-0 left-col pt-2 card-item"
                                    >
                                      {pack?.name ? pack?.name : "-"}
                                    </Col>
                                    <Col
                                      xs={2}
                                      sm={2}
                                      md={2}
                                      className="text-end px-0 left-col pt-2 card-item"
                                      style={{ fontWeight: "500" }}
                                    >
                                      {pack?.quantity
                                        ? `${pack?.quantity}x`
                                        : "-"}
                                    </Col>
                                    <Col
                                      xs={3}
                                      sm={4}
                                      md={3}
                                      className="text-end px-0 right-col pt-2 card-item"
                                    >
                                      {pack?.cbm ? pack?.cbm : "-"} cm
                                      <sup>3</sup>
                                    </Col>
                                  </Row>
                                </>
                              );
                            })} */}

              {/* </div> */}
              {/* </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Row>
                );
              })} */}
            </div>
          </div>

          {/* </MainTemplate> */}
        </div>
      ) : (
        <>
          {isLoading ? (
            <div> Loading...</div>
          ) : (
            <div> Ooops... Something went wrong...</div>
          )}
        </>
      )}

      <PrintComponent>
        <QuotationFormDownload
          id="content-id"
          surveyData={surveyData}
          formContent={formContent}
        />
      </PrintComponent>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={
            snackbarMessage?.includes("successful") ? "success" : "error"
          }
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      {/* Add signature Modal */}
      {isSignCanvas && (
        <MySignatureCanvas
          open={isSignCanvas}
          onClose={() => {
            setSignCanvas(false);
          }}
          onSuccess={(file) => {
            if (!isLoading) uploadFile(file);
          }}
        />
      )}
    </>
  );
};
export default ClientQuote;
