import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './style.css'; // Create this CSS file for additional styling

const LazyImage = ({ src, alt, placeholderSrc, height, width }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <div className="image-wrapper" style={{ height, width }}>
      {!loaded && (
        <img
          src={placeholderSrc}
          alt="placeholder"
          className="placeholder"
        />
      )}
      <LazyLoadImage
        alt={alt}
        src={src}
        effect="blur"
        afterLoad={() => setLoaded(true)}
        className={`main-image ${loaded ? 'loaded' : 'loading'}`}
        height={height}
        width={width}
      />
    </div>
  );
};

export default LazyImage;
