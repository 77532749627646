import React, { useEffect, useState } from "react";
import {
  updateFormContentPreview,
  getFormContentPreview,
  imageUploadClientPreview,
} from "../../../../api/services/services";
import { Button, Snackbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import { Bucket } from "../../../../api/config/apiConfig";
import ContactDetailField from "../../../molecules/ContactDetailField";
import Input from "../../../atoms/Input";
import DynamicText from "../../../molecules/DynamicText";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import html2pdf from "html2pdf.js";
import { useParams } from "react-router-dom";
import FourOptionsDynamicCheckbox from "../../../molecules/FourOptionsCheckbox";
// import "./style.css";
import { useLocation } from "react-router-dom";
import PrintComponent from "../../PrintComponent";
import LoadUnloadDownload from "./download";
import moment from "moment";

const LoadUnloadReportClientSide = () => {
  const [formContent, setFormContent] = useState({});
  const [formContentId, setFormContentId] = useState("");

  const [surveyData, setSurveyData] = useState([])

  const [isEdit, setIsEdit] = useState(true);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const urlData = new URLSearchParams(location.search);
  const email = urlData.get("email");
  const surveyId = urlData.get("surveyId");
  const formId = urlData.get("formId");
  const token = urlData.get("token");

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  //
  const updateContent = (data) => {
    setIsLoading(true);

    let media = [];
    if (formContent?.clientSign != "") media[0] = formContent?.clientSign;

    const body = {
      formContentId: formContentId,
      content: data ? data : formContent,
      mediaKeys: media,
      removedMediaKeys: [],
    };
    updateFormContentPreview(
      surveyId,
      formId,
      email,
      token,
      body,
      formContentId
    )
      .then((res) => {
        if (data != undefined)
          setSnackbarMessage("Signature has been added successfully!");
        else
          setSnackbarMessage("LOAD/UNLOAD Form has been updated successfully!");

        setOpenSnackbar(true);
        getContent();
        setIsEdit(false);

        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          setIsValid(false);
        }

        setIsLoading(false);
      });
  };

  const [isValid, setIsValid] = useState(false);

  //
  const getContent = () => {
    setIsLoading(true);
    getFormContentPreview(surveyId, formId, email, token)
      .then((res) => {
        setIsValid(true);
        setFormContentId(res.data.data.uuid);
        setFormContent(res.data.data.content);

        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          setIsValid(false);
        }

        setIsLoading(false);
      });
  };

  useEffect(() => {
    getContent();
  }, []);

  const [show, setShow] = useState(false);
  const options = {
    // resolution: Resolution.HIGH,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      format: "letter",
      // default is 'portrait'
      orientation: "portrait",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/png",
      qualityRatio: 1,
    },
    overrides: {
      pdf: {
        compress: true,
      },
    },
  };

  //
  const generatePdf = () => {
    setShow(true);

    setTimeout(() => {
      const content = document.getElementById("content-to-convert"); // Replace with the ID of the element you want to convert to PDF

      const currentDateTime = new Date();
      const filename = `survey_${currentDateTime.toISOString()}.pdf`;

      const pdfOptions = {
        margin: 5,
        filename: filename,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
        }, // Set the page size here
      };

      let temp = html2pdf().from(content).set(pdfOptions).outputPdf();
      temp.save(filename);

      setShow(false);
    }, 3500);
  };

  const [downloadText, setDownloadText] = useState("download");

  const getTargetElement = () => document.getElementById("content-id");

  //
  //
  const uploadFile = async (event) => {
    event.preventDefault();

    if (event.target.files.length > 0) {
      const fd = new FormData();
      fd.append("image", event.target.files[0]);

      imageUploadClientPreview(fd, surveyId, formId, email, token)
        .then((res) => {
          let values = { ...formContent };
          values.clientSign = res?.data?.data?.key;
          setFormContent(values);

          setTimeout(() => {
            updateContent(values);
          }, 1500);
          setIsLoading(false);
        })
        .catch((error) => {
          setSnackbarMessage(error?.response?.data?.message);
          setOpenSnackbar(true);

          setIsValid(false);

          setIsLoading(false);
        });
    }
  };

  const printAction = () => {
    const PAGE_HEIGHT = 1045;
    const printElement = document.getElementById("print-component");
    if (printElement) {
      printElement.classList.add("temp-class-for-height");
      const height = printElement.clientHeight - 2000;
      const numberOfPage = Math.ceil(height / PAGE_HEIGHT);
      const heightWithSingleHeader = numberOfPage * PAGE_HEIGHT;
      let requiredHeight = heightWithSingleHeader;
      if (numberOfPage > 1) {
        const headerHeight =
          printElement.getElementsByTagName("thead")?.[0]?.clientHeight;
        const footerHeight =
          printElement.getElementsByTagName("tfoot")?.[0]?.clientHeight;
        requiredHeight -= (numberOfPage - 1) * (headerHeight + footerHeight);
      }
      printElement.style.height = `${requiredHeight}px`;
      printElement.classList.remove("temp-class-for-height");
    }
    window.print();
    if (printElement) {
      printElement.style.height = `auto`;
    }
  };

  return (
    <>
      {isValid ? (
        <div className="mx-3 mx-md-5 no-print" style={{ minHeight: "100vh" }}>

        <div className="d-flex align-items-center py-4">
              <p
                className="main-head ps-0 mb-0 text-end w-50"
                
              >
               Load Unload Report
              </p>
              <div className="d-flex ms-auto justify-content-center mt-2 mt-sm-0">
              {/*Edit Button */}
              {!isEdit && (
                <Button
                  className="active-btn"
                  onClick={() => {
                    setIsEdit(true);
                  }}
                  disabled={isLoading}
                >
                  Edit
                </Button>
              )}

              {/* Cancel button */}
              {isEdit && (
                <Button
                  className="active-btn ms-3"
                  onClick={() => {
                    getContent();
                    setIsEdit(false);
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
              )}

              {/* Save Button */}
              {isEdit && (
                <Button
                  className="active-btn ms-3"
                  onClick={() => {
                    updateContent();
                  }}
                  disabled={isLoading}
                >
                  Save
                </Button>
              )}

              {/* Download Button */}
              {!isEdit && (
                <Button
                  className="active-btn ms-3 px-0"
                  onClick={() => {
                    setIsLoading(true);
                      // printAction();
                      var tempTitle = document.title;
                      document.title = `Load Unload Report-${
                        formContent?.clientName
                      }-${moment(new Date()).format("DD.MM.YYYY")}`;

                      window.print();
                      document.title = tempTitle;
                      setIsLoading(false);
                  }}
                  disabled={isLoading}
                >
                  {"Save as PDF"}
                </Button>
              )}
            </div>
            </div>


          {/* <div className="d-block d-sm-flex align-items-center py-4">
            <p className="main-head mb-0">Load Unload Report</p>

            <div className="d-flex ms-auto justify-content-center mt-2 mt-sm-0">
              {/*Edit Button */}
              {/* {!isEdit && (
                <Button
                  className="active-btn"
                  onClick={() => {
                    setIsEdit(true);
                  }}
                  disabled={isLoading}
                >
                  Edit
                </Button>
              )} */}

              {/* Cancel button */}
              {/* {isEdit && (
                <Button
                  className="active-btn ms-3"
                  onClick={() => {
                    getContent();
                    setIsEdit(false);
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
              )} */}

              {/* Save Button */}
              {/* {isEdit && (
                <Button
                  className="active-btn ms-3"
                  onClick={() => {
                    updateContent();
                  }}
                  disabled={isLoading}
                >
                  Save
                </Button>
              )} */}

              {/* Download Button */}
              {/* {!isEdit && (
                <Button
                  className="active-btn ms-3 px-0"
                  onClick={() => {
                    setIsLoading(true);
                      // printAction();
                      var tempTitle = document.title;
                      document.title = `Load Unload Report-${
                        formContent?.clientName
                      }-${moment(new Date()).format("DD.MM.YYYY")}`;

                      window.print();
                      document.title = tempTitle;
                      setIsLoading(false);
                  }}
                  disabled={isLoading}
                >
                  {"Save as PDF"}
                </Button>
              )}
            </div>
          </div>  */}

          {/* file detail*/}
          <div className="contact-detail d-flex mt-4">
            {/* left col */}
            <div
              className={`left-col ${
                !isEdit ? " w-50" : "w-50 pe-5"
              } text-start`}
            >
              <Typography variant="body1" gutterBottom>
                <ContactDetailField
                  label="File Ref"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"160px"}
                  value={formContent?.fileRef}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.fileRef = e.target.value;
                    setFormContent(values);
                  }}
                  disabled
                />
                <ContactDetailField
                  label="Customer Name"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"160px"}
                  value={formContent?.clientName}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.clientName = e.target.value;
                    setFormContent(values);
                  }}
                  disabled
                />

                <ContactDetailField
                  label="Driver Name"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"160px"}
                  value={formContent?.driverName}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.driverName = e.target.value;
                    setFormContent(values);
                  }}
                  disabled
                />

                <ContactDetailField
                  label="Contact No. "
                  isEdit={isEdit}
                  type="number"
                  minWidth={"160px"}
                  value={formContent?.contactNo}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.contactNo = e.target.value;
                    setFormContent(values);
                  }}
                  disabled
                />
                <ContactDetailField
                  label="ID Card No. "
                  isEdit={isEdit}
                  type="number"
                  minWidth={"160px"}
                  value={formContent?.idNo}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.idNo = e.target.value;
                    setFormContent(values);
                  }}
                  disabled
                />
                <ContactDetailField
                  label="Origin Address"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"160px"}
                  value={formContent?.originAddress}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.originAddress = e.target.value;
                    setFormContent(values);
                  }}
                  disabled
                />
              </Typography>
            </div>
            {/* right col */}
            <div
              className={`right-col ${
                !isEdit ? "w-50" : "w-50 ps-5"
              }  d-flex flex-column align-items-end pe-2`}
            >
              <div
                className=""
                style={isEdit ? { width: "-webkit-fill-available" } : {}}
              >
                <Typography
                  variant="body1"
                  className="text-start w-100"
                  gutterBottom
                >
                  <ContactDetailField
                    label="Date"
                    isEdit={isEdit}
                    type="date"
                    wrapperStyle="justify-content-start"
                    minWidth={"150px"}
                    value={formContent?.date}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.date = e.target.value;
                      setFormContent(values);
                    }}
                    disabled
                  />
                  <ContactDetailField
                    label="Organization"
                    isEdit={isEdit}
                    type="text"
                    wrapperStyle="justify-content-start"
                    minWidth={"150px"}
                    value={formContent?.organization}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.organization = e.target.value;
                      setFormContent(values);
                    }}
                    disabled
                  />
                  <ContactDetailField
                    label="Serial No. "
                    isEdit={isEdit}
                    type="text"
                    wrapperStyle="justify-content-start"
                    minWidth={"150px"}
                    value={formContent?.serialNo}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.serialNo = e.target.value;
                      setFormContent(values);
                    }}
                    disabled
                  />
                  <ContactDetailField
                    label="Truck  No. "
                    isEdit={isEdit}
                    type="text"
                    wrapperStyle="justify-content-start"
                    minWidth={"150px"}
                    value={formContent?.truckNo}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.truckNo = e.target.value;
                      setFormContent(values);
                    }}
                    disabled
                  />
                  <ContactDetailField
                    label="Container No. "
                    isEdit={isEdit}
                    type="text"
                    wrapperStyle="justify-content-start"
                    minWidth={"150px"}
                    value={formContent?.containerNo}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.containerNo = e.target.value;
                      setFormContent(values);
                    }}
                    disabled
                  />
                  <ContactDetailField
                    label="Destination Address"
                    isEdit={isEdit}
                    type="text"
                    minWidth={"160px"}
                    value={formContent?.destinationAddress}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.destinationAddress = e.target.value;
                      setFormContent(values);
                    }}
                    disabled
                  />
                </Typography>
              </div>
            </div>
          </div>
          {/* /file detail */}

          {/*  */}
          {/* Shipment Mode */}
          <div className="mt-5 pe-1 pt-3">
            <Typography variant="body1" className="text-start" gutterBottom>
              <FourOptionsDynamicCheckbox
                label="Shipment Mode"
                checkId1="check1"
                checkLabel1="Import"
                checkId2="check2"
                checkLabel2="Export"
                checkId3="check3"
                checkLabel3="Local"
                checkId4="chec42"
                checkLabel4="Storage"
                value1={formContent?.shipmentMode == "import" ? true : false}
                value2={formContent?.shipmentMode == "export" ? true : false}
                value3={formContent?.shipmentMode == "local" ? true : false}
                value4={formContent?.shipmentMode == "storage" ? true : false}
                onChange1={(e) => {
                  let values = { ...formContent };
                  values.shipmentMode = "import";
                  setFormContent(values);
                }}
                onChange2={(e) => {
                  let values = { ...formContent };
                  values.shipmentMode = "export";
                  setFormContent(values);
                }}
                onChange3={(e) => {
                  let values = { ...formContent };
                  values.shipmentMode = "local";
                  setFormContent(values);
                }}
                onChange4={(e) => {
                  let values = { ...formContent };
                  values.shipmentMode = "storage";
                  setFormContent(values);
                }}
                disabled={true}
              />
            </Typography>
          </div>
          {/* /Shipment Mode */}

          {/* Nature of Shipment */}
          <div className="mt-3 pe-1 ">
            <Typography variant="body1" className="text-start" gutterBottom>
              <FourOptionsDynamicCheckbox
                label="Nature of Shipment"
                checkId1="check1"
                checkLabel1="Loose Items"
                checkId2="check2"
                checkLabel2="Lift Vans"
                checkId3="check3"
                checkLabel3="Vehicle"
                checkId4="chec42"
                checkLabel4="Bike"
                value1={
                  formContent?.shipmentNature == "loose-items" ? true : false
                }
                value2={
                  formContent?.shipmentNature == "lift-vans" ? true : false
                }
                value3={formContent?.shipmentNature == "vehicle" ? true : false}
                value4={formContent?.shipmentNature == "bike" ? true : false}
                onChange1={(e) => {
                  let values = { ...formContent };
                  values.shipmentNature = "loose-items";
                  setFormContent(values);
                }}
                onChange2={(e) => {
                  let values = { ...formContent };
                  values.shipmentNature = "lift-vans";
                  setFormContent(values);
                }}
                onChange3={(e) => {
                  let values = { ...formContent };
                  values.shipmentNature = "vehicle";
                  setFormContent(values);
                }}
                onChange4={(e) => {
                  let values = { ...formContent };
                  values.shipmentNature = "bike";
                  setFormContent(values);
                }}
                disabled={true}
              />
            </Typography>
          </div>
          {/* Nature of Shipment */}

          <div className="d-flex flex-wrap mt-5">
            {formContent?.containers?.map((cn, ind) => {
              return (
                <div
                  className={`cursor-pointer ${
                    cn ? "blue-container-div" : "container-div"
                  }`}
                  onClick={() => {
                    if (isEdit) {
                      let values = { ...formContent };
                      let contValues = [...formContent?.containers];
                      contValues[ind] = !contValues[ind];
                      values.containers = contValues;
                      setFormContent(values);
                    }
                  }}
                >
                  {" "}
                  {ind + 1}
                </div>
              );
            })}
          </div>

          {/*   Double No. */}
          <div className="mt-5 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex justify-content-between">
                <div className="w-50">
                  <label htmlFor="packingDate" className="fw-bold">
                    Double No.
                  </label>
                </div>
                {!isEdit && (
                  <span className="table-text w-50 text-end">
                    {formContent?.doubleNo ? formContent?.doubleNo : "-"}{" "}
                  </span>
                )}
                {isEdit && (
                  <Input
                    id="originAddress "
                    type="text"
                    label="Double No. separated by commas"
                    style="full-border-field  bg-white"
                    value={formContent?.doubleNo}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.doubleNo = e.target.value;
                      setFormContent(values);
                    }}
                    inline={{ width: "-webkit-fill-available" }}
                    disabled={true}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* /  Double No. */}
          {/*  Missing No. */}
          <div className="mt-5 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex justify-content-between">
                <div className="w-50">
                  <label htmlFor="packingDate" className="fw-bold">
                    Missing No.
                  </label>
                </div>
                {!isEdit && (
                  <span className="table-text w-50 text-end">
                    {formContent?.missingNo ? formContent?.missingNo : "-"}
                  </span>
                )}
                {isEdit && (
                  <Input
                    id="estinationAddress"
                    type="text"
                    label="Missing No. separated by commas"
                    style="full-border-field  bg-white"
                    value={formContent?.missingNo}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.missingNo = e.target.value;
                      setFormContent(values);
                    }}
                    inline={{ width: "-webkit-fill-available" }}
                    disabled={true}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* /Missing No.s */}
          {/*  Apparent damages No. */}
          <div className="mt-5 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex justify-content-between">
                <div className="w-50">
                  <label htmlFor="packingDate" className="fw-bold">
                    Apparent damages No.
                  </label>
                </div>
                {!isEdit && (
                  <span className="table-text w-50 text-end">
                    {formContent?.damagesNo ? formContent?.damagesNo : "-"}{" "}
                  </span>
                )}
                {isEdit && (
                  <Input
                    id="cmmodity"
                    type="text"
                    label=" Apparent damages No. separated by commas"
                    style="full-border-field bg-white"
                    value={formContent?.damagesNo}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.damagesNo = e.target.value;
                      setFormContent(values);
                    }}
                    inline={{ width: "-webkit-fill-available" }}
                    disabled={true}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* /Apparent damages No. */}

          {/* Remarks */}
          <div className="mt-5 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <DynamicText
                label="Remarks:"
                textAreaLabel="Remarks..."
                isEdit={isEdit}
                value={formContent?.remarks}
                onChange={(e) => {
                  let values = { ...formContent };
                  values.remarks = e.target.value;
                  setFormContent(values);
                }}
                disabled={true}
              />
            </Typography>
          </div>
          {/* /Remakes */}

          {/* Signatures */}
          <Row className="mt-5 pt-5 no-print">
            <Col sm={6} className="mt-5 pt-5">
              <div
                style={{ height: "90px" }}
                className="d-flex justify-content-center align-items-end"
              >
                {formContent?.supervisorSign?.trim()?.length == 0 ? (
                  <>
                   
                  </>
                ) : (
                  <img
                    src={`${Bucket}${formContent?.supervisorSign}`}
                    height={80}
                    width={200}
                  />
                )}
              </div>

              <p>{"_______________________________"}</p>
              <p className="my-1">
                <b>{"Supervisor/Carrier"}</b>
              </p>
            
              {""}
            </Col>
            <Col sm={6} className="mt-5 pt-5">
              <div
                style={{ height: "90px" }}
                className="d-flex justify-content-center align-items-end"
              >
                {formContent?.ownerSign?.trim()?.length == 0 ? (
                  <>
                    
                  </>
                ) : (
                  <img
                    src={`${Bucket}${formContent.ownerSign}`}
                    height={80}
                    width={200}
                  />
                )}
              </div>
              <p>{"_______________________________"}</p>
              <p className="my-1">
                <b>{"Owner/Authorized"}</b>
              </p>
              
              {""}
            </Col>
          </Row>

          {/* snackbar */}
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000} // Adjust the duration as needed
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              severity={
                snackbarMessage?.includes("successful") ? "success" : "error"
              }
              onClose={handleCloseSnackbar}
            >
              {snackbarMessage}
            </MuiAlert>
          </Snackbar>
          {/* /snackbar */}
          {/* </MainTemplate> */}
        </div>
      ) : (
        <>
          {isLoading ? (
            <div> Loading...</div>
          ) : (
            <div> Ooops... Something went wrong...</div>
          )}
        </>
      )}

      <PrintComponent>
        <LoadUnloadDownload surveyData={surveyData} formContent={formContent} />
      </PrintComponent>

      {/* snackbar */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={
            snackbarMessage?.includes("successful") ? "success" : "error"
          }
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      {/* /snackbar */}
    </>
  );
};

export default LoadUnloadReportClientSide;
