// src/routing/PrivateRoute.js
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import CreateAccountPage from "../components/pages/CreateAccountPage";
import ForgetPasswordPage from "../components/pages/ForgetPasswordPage";
import TwoFactorAuthPage from "../components/pages/TwoFactorAuthPage";
import ResetPasswordPage from "../components/pages/ResetPasswordPage";
import LoginPage from "../components/pages/LoginPage";
import Dashboard from "../components/pages/Dashboard";
import SurveyDetailsPage from "../components/pages/SurveyDetailsPage";
import Quotations from "../components/pages/Quotations";
import Roles from "../components/pages/RoleManagementPage";
import SurveyPreviewPage from "../components/pages/SurveyPreviewPage";
import PreviewQuotePage from "../components/pages/PreviewQuotePage";
import QuotationFormPage from "../components/pages/QuotationFormPage";
import CrewInstructionFormPage from "../components/pages/CrewInstructionFormPage";
import PackingRequestPage from "../components/pages/PackingRequestPage";
import InvoicePage from "../components/pages/Invoice";
import DeliveryReceiptPage from "../components/pages/DeliveryReceipt";
import WoodenLiftPage from "../components/pages/WoodenLiftPage";
import ItemConditionReportPage from "../components/pages/ItemConditionReportPage";
import DeliveryRequestPage from "../components/pages/DeliveryRequestPage";
import PackingMaterialIssuedPage from "../components/pages/PackingMaterialIssuedPage";
import WeightTicketFormPage from "../components/pages/WeightTicketPage";
import ShippingInstructionsPage from "../components/pages/ShippingInstructionsPage";
import WorksheetImportPage from "../components/pages/WorksheetImportPage";
import ClientQuote from "../components/pages/QuotationFormPage/ClientSide/ClientQuote";
import CrewInstructionClientSide from "../components/pages/CrewInstructionFormPage/ClientSide";
import AcknowledgementReceiptPage from "../components/pages/AcknowledgementReceipt";
import InsuranceServicesPage from "../components/pages/InsuranceServicesPages";
import InitiateSurvey from "../components/pages/InitiateSurvey";

import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Routes,
  useLocation,
} from "react-router-dom";
import { Fragment } from "react";
import MoveCheckList from "../components/pages/AddedFiles";
import PackingRequestPagePreview from "../components/pages/PackingRequestPage/ClientSide";
import ShippingInstructionsClientSide from "../components/pages/ShippingInstructionsPage/ClientSide";
import ItemConditionReportClientSide from "../components/pages/ItemConditionReportPage/ClientSide";
import DeliveryReceiptClientSide from "../components/pages/DeliveryReceipt/ClientSide";
import DeliveryRequestClientSide from "../components/pages/DeliveryRequestPage/ClientSide";
import AcknowledgementReceiptClientSide from "../components/pages/AcknowledgementReceipt/ClientSide";
import InvoiceClientSide from "../components/pages/Invoice/ClientSide";
import WoodenLiftPageClientSide from "../components/pages/WoodenLiftPage/ClientSide";
import WorksheetImportPageClientSide from "../components/pages/WorksheetImportPage/ClientSide";
import LoadUnloadReport from "../components/pages/LoadUnloadReport";
import LoadUnloadReportClientSide from "../components/pages/LoadUnloadReport/ClientSide";
import PackingInventoryPage from "../components/pages/PackingInventoryPage";
import PackingInventoryClientSide from "../components/pages/PackingInventoryPage/ClientSide";
import CompleteSurvey from "../components/pages/CompleteSurvey";
import ClientSurveyPreview from "../components/pages/SurveyPreviewPage/ClientSide";
import ListingPrebid from "../components/pages/PrebidAgreement/listing";
import CreateUpdatePrebid from "../components/pages/PrebidAgreement/creation";
import ClientPreview from "../components/pages/PrebidAgreement/client_preview";
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const PrivateRoute = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const currentUser = localStorage.getItem("shelUserData");

  return (
    <Router>
      <Fragment>
        <ScrollToTop />
        <Routes>
          {isLoggedIn || currentUser ? (
            <>
              <Route exact path="/" element={<Dashboard />} />
              <Route exact path="/survey/:id" element={<SurveyDetailsPage />} />
              <Route
                exact
                path="/survey/incomplete/:id"
                element={<CompleteSurvey />}
              />
              <Route exact path="/quotations" element={<Quotations />} />
              <Route
                exact
                path="/survey-request"
                element={<InitiateSurvey />}
              />

              <Route exact path="/role-management" element={<Roles />} />
              <Route
                exact
                path="/survey-preview/:id"
                element={<SurveyPreviewPage />}
              />
              <Route
                exact
                path="/survey/quotation-form/:flg/:surveyId/:id"
                element={<QuotationFormPage />}
              />
              <Route
                exact
                path="/survey/crew-instruction-document/:flg/:surveyId/:id"
                element={<CrewInstructionFormPage />}
              />
              <Route
                exact
                path="/survey/packing-request-form/:flg/:surveyId/:id"
                element={<PackingRequestPage />}
              />
              <Route
                exact
                path="/survey/item-condition-report-at-origin/:flg/:surveyId/:id"
                element={<ItemConditionReportPage />}
              />
              <Route
                exact
                path="/survey/shipping-instructions/:flg/:surveyId/:id"
                element={<ShippingInstructionsPage />}
              />
              <Route
                exact
                path="/survey/delivery-receipt/:flg/:surveyId/:id"
                element={<DeliveryReceiptPage />}
              />
              <Route
                exact
                path="/survey/delivery-request-form/:flg/:surveyId/:id"
                element={<DeliveryRequestPage />}
              />
              <Route
                exact
                path="/survey/acknowledge-receipt/:flg/:surveyId/:id"
                element={<AcknowledgementReceiptPage />}
              />
              <Route
                exact
                path="/survey/invoice/:flg/:surveyId/:id"
                element={<InvoicePage />}
              />
              <Route
                exact
                path="/survey/packing-material-issued-sheet/:flg/:surveyId/:id"
                element={<PackingMaterialIssuedPage />}
              />
              <Route
                exact
                path="/survey/weight-ticket/:flg/:surveyId/:id"
                element={<WeightTicketFormPage />}
              />
              <Route
                exact
                path="/survey/wooden-lift-van-quality-check-report/:flg/:surveyId/:id"
                element={<WoodenLiftPage />}
              />
              <Route
                exact
                path="/survey/worksheet-import/:flg/:surveyId/:id"
                element={<WorksheetImportPage />}
              />
              <Route
                exact
                path="/survey/load-unload-report/:flg/:surveyId/:id"
                element={<LoadUnloadReport />}
              />
              <Route
                exact
                path="/survey/packing-inventory/:flg/:surveyId/:id"
                element={<PackingInventoryPage />}
              />
              <Route
                exact
                path="/survey/inter-trans-insurance-services/:flg/:surveyId/:id"
                element={<InsuranceServicesPage />}
              />

              {/* <Route exact path="/survey/inbound-move-checklist-form-01/:id" element={<FirstInboundMovePage />} />
              <Route exact path="/survey/inbound-move-checklist-form-02/:id" element={<SecondInboundMovePage />} />
              <Route exact path="/survey/outbound-move-checklist-form-01/:id" element={<FirstOutboundMovePage />} />
              <Route exact path="/survey/outbound-move-checklist-form-02/:id" element={<SecondOutboundMovePage />} /> */}

              <Route
                exact
                path="/survey/move-checklist/:surveyId"
                element={<MoveCheckList />}
              />

              <Route
                exact
                path="/prebid-agreement-list"
                element={<ListingPrebid />}
              />

              <Route
                exact
                path="/create-update-prebid/:uuid"
                element={<CreateUpdatePrebid />}
              />

              <Route
                exact
                path="/prebid-client-preview/:token/:email/:uuid"
                element={<ClientPreview />}
              />
            </>
          ) : (
            <>
              <Route exact path="/onboarding" element={<CreateAccountPage />} />
              <Route
                exact
                path="/two-factor-auth"
                element={<TwoFactorAuthPage />}
              />
              <Route
                exact
                path="/forget-password"
                element={<ForgetPasswordPage />}
              />
              <Route
                exact
                path="/reset-password"
                element={<ResetPasswordPage />}
              />
              <Route exact path="/login" element={<LoginPage />} />
            </>
          )}
          <Route exact path="/preview-quote" element={<PreviewQuotePage />} />
          <Route
            exact
            path="/preview-survey"
            element={<ClientSurveyPreview />}
          />
          <Route
            exact
            path="/quotation-form-preview"
            element={<ClientQuote />}
          />
          <Route
            exact
            path="/crew-instruction-document-preview"
            element={<CrewInstructionClientSide />}
          />
          <Route
            exact
            path="/packing-request-form-preview"
            element={<PackingRequestPagePreview />}
          />
          <Route
            exact
            path="/shipping-instructions"
            element={<ShippingInstructionsClientSide />}
          />
          <Route
            exact
            path="/item-condition-report-at-origin"
            element={<ItemConditionReportClientSide />}
          />
          <Route
            exact
            path="/delivery-receipt"
            element={<DeliveryReceiptClientSide />}
          />
          <Route
            exact
            path="/delivery-request-form"
            element={<DeliveryRequestClientSide />}
          />
          <Route
            exact
            path="/acknowledge-receipt"
            element={<AcknowledgementReceiptClientSide />}
          />
          <Route
            exact
            path="/invoice-preview"
            element={<InvoiceClientSide />}
          />
          <Route
            exact
            path="/wooden-lift-van-quality-check-report-preview"
            element={<WoodenLiftPageClientSide />}
          />
          <Route
            exact
            path="/worksheet-import-preview"
            element={<WorksheetImportPageClientSide />}
          />
          <Route
            exact
            path="/load-unload-report-preview"
            element={<LoadUnloadReportClientSide />}
          />
          <Route
            exact
            path="/packing-inventory-preview"
            element={<PackingInventoryClientSide />}
          />
          <Route
            exact
            path="/inter-trans-insurance-services-preview"
            element={<InsuranceServicesPage />}
          />

          <Route
            path="/*"
            element={<Navigate to={isLoggedIn ? "/" : "/login"} />}
          />
        </Routes>
      </Fragment>
    </Router>
  );
};

export default PrivateRoute;
