import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const PasswordInput = ({ value, onChange, error, fieldName }) => {
  const inputLabelProps = {
    shrink: true,
    style: {
      color: "black", // Set the color to red
      fontWeight: "bold", // Set the font weight to bold
      fontSize: '15px'
    },
  };

  const inputProps = {
    style: {
      height: "45px", // Set the height to 45 pixels
    },
  };
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <TextField
      type={showPassword ? "text" : "password"}
      label={fieldName}
      variant="outlined"
      placeholder="Enter password"
      fullWidth
      value={value}
      onChange={onChange}
      error={error}
      helperText={error && "Password must be 8-15 characters with a mix of lowercase letters, uppercase letters, numbers, and symbols"}
      InputLabelProps={inputLabelProps}
      InputProps={{
        style: {
          height: "45px", // Set the height to 40 pixels
        },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={togglePasswordVisibility}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      className="custom-textfield"
      required
    />
  );
};

export default PasswordInput;
