import React, { useEffect, useState } from "react";
import {
  updateFormContentPreview,
  getFormContentPreview,
  imageUploadClientPreview,
} from "../../../../api/services/services";
import { Button, Snackbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import { Bucket } from "../../../../api/config/apiConfig";
import ContactDetailField from "../../../molecules/ContactDetailField";
import Input from "../../../atoms/Input";
import DynamicText from "../../../molecules/DynamicText";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import html2pdf from "html2pdf.js";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import PrintComponent from "../../PrintComponent";
import AcknowledgementReceiptDownload from "./download";
import TextArea from "../../../atoms/Textarea";
import moment from "moment";

const AcknowledgementReceiptClientSide = () => {
  const [formContent, setFormContent] = useState({});
  const [formContentId, setFormContentId] = useState("");
  const [surveyData, setSurveyData] = useState([]);

  const [isEdit, setIsEdit] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const urlData = new URLSearchParams(location.search);
  const email = urlData.get("email");
  const surveyId = urlData.get("surveyId");
  const formId = urlData.get("formId");
  const token = urlData.get("token");

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  //
  const updateContent = (data) => {
    setIsLoading(true);

    let media = [];
    if (formContent?.clientSign != "") media[0] = formContent?.clientSign;

    const body = {
      formContentId: formContentId,
      content: data,
      mediaKeys: media,
      removedMediaKeys: [],
    };
    updateFormContentPreview(
      surveyId,
      formId,
      email,
      token,
      body,
      formContentId
    )
      .then((res) => {
        setSnackbarMessage("Signature has been added successfully!");
        setOpenSnackbar(true);
        getContent();
        setIsEdit(false);

        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          setIsValid(false);
        }

        setIsLoading(false);
      });
  };

  const [isValid, setIsValid] = useState(false);

  //
  const getContent = () => {
    setIsLoading(true);
    getFormContentPreview(surveyId, formId, email, token)
      .then((res) => {
        setIsValid(true);
        setFormContentId(res.data.data.uuid);
        setFormContent(res.data.data.content);

        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          setIsValid(false);
        }

        setIsLoading(false);
      });
  };

  useEffect(() => {
    getContent();
  }, []);

  const [show, setShow] = useState(false);
  const options = {
    // resolution: Resolution.HIGH,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      format: "letter",
      // default is 'portrait'
      orientation: "portrait",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/png",
      qualityRatio: 1,
    },
    overrides: {
      pdf: {
        compress: true,
      },
    },
  };

  //
  const generatePdf = () => {
    setShow(true);

    setTimeout(() => {
      const content = document.getElementById("content-to-convert"); // Replace with the ID of the element you want to convert to PDF

      const currentDateTime = new Date();
      const filename = `survey_${currentDateTime.toISOString()}.pdf`;

      const pdfOptions = {
        margin: 5,
        filename: filename,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
        }, // Set the page size here
      };

      let temp = html2pdf().from(content).set(pdfOptions).outputPdf();
      temp.save(filename);

      setShow(false);
    }, 3500);
  };

  const [downloadText, setDownloadText] = useState("download");

  const getTargetElement = () => document.getElementById("content-id");

  //
  //
  const uploadFile = async (event) => {
    event.preventDefault();

    if (event.target.files.length > 0) {
      const fd = new FormData();
      fd.append("image", event.target.files[0]);

      imageUploadClientPreview(fd, surveyId, formId, email, token)
        .then((res) => {
          let values = { ...formContent };
          values.clientSign = res?.data?.data?.key;
          setFormContent(values);

          setTimeout(() => {
            updateContent(values);
          }, 1500);
          setIsLoading(false);
        })
        .catch((error) => {
          setSnackbarMessage(error?.response?.data?.message);
          setOpenSnackbar(true);

          setIsValid(false);

          setIsLoading(false);
        });
    }
  };

  const printAction = () => {
    const PAGE_HEIGHT = 1045;
    const printElement = document.getElementById("print-component");
    if (printElement) {
      printElement.classList.add("temp-class-for-height");
      const height = printElement.clientHeight;
      const numberOfPage = Math.ceil(height / PAGE_HEIGHT);
      const heightWithSingleHeader = numberOfPage * PAGE_HEIGHT;
      let requiredHeight = heightWithSingleHeader;
      if (numberOfPage > 1) {
        const headerHeight =
          printElement.getElementsByTagName("thead")?.[0]?.clientHeight;
        const footerHeight =
          printElement.getElementsByTagName("tfoot")?.[0]?.clientHeight;
        requiredHeight -= (numberOfPage - 1) * (headerHeight + footerHeight);
      }
      printElement.style.height = `${requiredHeight}px`;
      printElement.classList.remove("temp-class-for-height");
    }
    window.print();
    if (printElement) {
      printElement.style.height = `auto`;
    }
  };

  return (
    <>
      {isValid ? (
        <div className="mx-3 mx-md-5 no-print" style={{ minHeight: "100vh" }}>
          {/* <MainTemplate> */}

          <div className="d-flex align-items-center py-4">
            <p
              className="main-head ps-0 mb-0 text-end"
              style={{ width: "60%" }}
            >
              Acknowledgement Receipt
            </p>
            <div className="d-flex ms-auto justify-content-center mt-2 mt-sm-0">
              {/* Download Button */}
              {!isEdit && (
                <Button
                  className="active-btn ms-3 px-2"
                  onClick={() => {
                    setIsLoading(true);
                    // printAction();
                    var tempTitle = document.title;
                    document.title = `Acknowledgement Receipt-${
                      formContent?.clientName
                    }-${moment(new Date()).format("DD.MM.YYYY")}`;

                    window.print();
                    document.title = tempTitle;
                    setIsLoading(false);
                  }}
                  disabled={isLoading}
                >
                  {"Save as PDF"}
                </Button>
              )}
            </div>
          </div>

          {/* <div className="d-block d-sm-flex align-items-center py-4">
            <div className="d-flex align-items-center ">
              <p className="main-head mb-0">Acknowledgement Receipt</p>
            </div>
            <div className="d-flex ms-auto justify-content-center mt-2 mt-sm-0">
              {/* Download Button */}
          {/* {!isEdit && (
                <Button
                  className="active-btn ms-3 px-2"
                  onClick={() => {
                    setIsLoading(true);
                      // printAction();
                      var tempTitle = document.title;
                      document.title = `Acknowledgement Receipt-${
                        formContent?.clientName
                      }-${moment(new Date()).format("DD.MM.YYYY")}`;

                      window.print();
                      document.title = tempTitle;
                      setIsLoading(false);
                  }}
                  disabled={isLoading}
                >
                {'Save as PDF'}
                </Button>
              )}
            </div>
          </div> */}

          {/* file detail*/}
          <div className="contact-detail d-flex mt-4">
            {/* left col */}
            <div
              className={`left-col ${
                !isEdit ? " w-75" : "w-50 pe-5"
              } text-start`}
            >
              <Typography
                variant="body1"
                className="text-start w-100"
                gutterBottom
              >
                <ContactDetailField
                  label="Date"
                  isEdit={isEdit}
                  type="date"
                  wrapperStyle="justify-content-start"
                  minWidth={"180px"}
                  value={formContent?.date}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.date = e.target.value;
                    setFormContent(values);
                  }}
                />
                <ContactDetailField
                  label="Received From"
                  isEdit={isEdit}
                  type="text"
                  wrapperStyle="justify-content-start"
                  minWidth={"180px"}
                  value={formContent?.receivedFrom}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.receivedFrom = e.target.value;
                    setFormContent(values);
                  }}
                />
              </Typography>
            </div>
          </div>
          {/* /file detail */}
          {/*  Address */}
          <div className="mt-3 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex ">
                <div>
                  <label
                    htmlFor="packingDate"
                    className="fw-bold"
                    style={{ width: "200px" }}
                  >
                    Address
                  </label>
                </div>
                {!isEdit && (
                  <span className="table-text ">
                    {formContent?.address ? formContent?.address : "-"}{" "}
                  </span>
                )}
                {isEdit && (
                  <Input
                    id="Address"
                    type="text"
                    label="Address"
                    style="full-border-field"
                    value={formContent?.address}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.address = e.target.value;
                      setFormContent(values);
                    }}
                    inline={{ width: "-webkit-fill-available" }}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* / Address */}
          {/*  On Account Of */}
          <div className="mt-3 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex ">
                <div>
                  <label
                    htmlFor="packingDate"
                    className="fw-bold"
                    style={{ width: "200px" }}
                  >
                    On Account of
                  </label>
                </div>
                {!isEdit && (
                  <span className="table-text ">
                    {formContent?.onAccountOf ? formContent?.onAccountOf : "-"}
                  </span>
                )}
                {isEdit && (
                  <TextArea
                    id="on-account-of"
                    type="text"
                    label="On Account of"
                    style=""
                    value={formContent?.onAccountOf}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.onAccountOf = e.target.value;
                      setFormContent(values);
                    }}
                    inline={{ width: "-webkit-fill-available" }}
                    rows={4}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* /On Account Of */}
          {/*  Amount */}
          <div className="mt-3 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex">
                <div>
                  <label
                    htmlFor="packingDate"
                    className="fw-bold"
                    style={{ width: "200px" }}
                  >
                    Amount
                  </label>
                </div>
                {!isEdit && (
                  <span className="table-text ">
                    {formContent?.amount ? formContent?.amount : "-"}{" "}
                  </span>
                )}
                {isEdit && (
                  <Input
                    id="ammount"
                    type="number"
                    label="Amount"
                    style="full-border-field"
                    value={formContent?.amount}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.amount = e.target.value;
                      setFormContent(values);
                    }}
                    inline={{ width: "-webkit-fill-available" }}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* /Amount */}
          {/*  Cash/Cheque # */}
          <div className="mt-3 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex ">
                <div>
                  <label
                    htmlFor="packingDate"
                    className="fw-bold"
                    style={{ width: "200px" }}
                  >
                    Cash/Cheque #
                  </label>
                </div>
                {!isEdit && (
                  <span className="table-text ">
                    {formContent?.chequeNo ? formContent?.chequeNo : "-"}
                  </span>
                )}
                {isEdit && (
                  <Input
                    id="cheque-no"
                    type="text"
                    label="Cash/Cheque #"
                    style="full-border-field"
                    value={formContent?.chequeNo}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.chequeNo = e.target.value;
                      setFormContent(values);
                    }}
                    inline={{ width: "-webkit-fill-available" }}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* /Cash/Cheque # */}
          {/*  Drawn on Bank */}
          <div className="mt-3 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex ">
                <div>
                  <label
                    htmlFor="packingDate"
                    className="fw-bold"
                    style={{ width: "200px" }}
                  >
                    Drawn on Bank
                  </label>
                </div>
                {!isEdit && (
                  <span className="table-text ">
                    {formContent?.drawnOnBank ? formContent?.drawnOnBank : "-"}
                  </span>
                )}
                {isEdit && (
                  <Input
                    id="drawn-on-bank"
                    type="text"
                    label="Drawn on Bank"
                    style="full-border-field"
                    value={formContent?.drawnOnBank}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.drawnOnBank = e.target.value;
                      setFormContent(values);
                    }}
                    inline={{ width: "-webkit-fill-available" }}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* /Drawn on Bank */}
          {/*  In words */}
          <div className="mt-3 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex ">
                <div>
                  <label
                    htmlFor="packingDate"
                    className="fw-bold"
                    style={{ width: "200px" }}
                  >
                    In words
                  </label>
                </div>
                {!isEdit && (
                  <span className="table-text ">
                    {formContent?.inWords ? formContent?.inWords : "-"}{" "}
                  </span>
                )}
                {isEdit && (
                  <Input
                    id="in-words"
                    type="text"
                    label="In words"
                    style="full-border-field"
                    value={formContent?.inWords}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.inWords = e.target.value;
                      setFormContent(values);
                    }}
                    inline={{ width: "-webkit-fill-available" }}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* /In words*/}
          {/*  Received By */}
          <div className="mt-3 pe-1 ">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex ">
                <div>
                  <label
                    htmlFor="packingDate"
                    className="fw-bold"
                    style={{ width: "200px" }}
                  >
                    Received By
                  </label>
                </div>
                {!isEdit && (
                  <span className="table-text ">
                    {formContent?.receivedBy ? formContent?.receivedBy : "-"}
                  </span>
                )}
                {isEdit && (
                  <TextArea
                    id="truckNo"
                    type="text"
                    label="Received By"
                    style=""
                    value={formContent?.receivedBy}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.receivedBy = e.target.value;
                      setFormContent(values);
                    }}
                    inline={{ width: "-webkit-fill-available" }}
                    rows={4}
                  />
                )}
              </div>
            </Typography>
            <hr className="mb-10" />
          </div>
          {/* /Received By*/}

          {/* snackbar */}
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000} // Adjust the duration as needed
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              severity={
                snackbarMessage?.includes("successful") ? "success" : "error"
              }
              onClose={handleCloseSnackbar}
            >
              {snackbarMessage}
            </MuiAlert>
          </Snackbar>
          {/* /snackbar */}
          {/* </MainTemplate> */}
        </div>
      ) : (
        <>
          {isLoading ? (
            <div> Loading...</div>
          ) : (
            <div> Ooops... Something went wrong...</div>
          )}
        </>
      )}

      <PrintComponent>
        <AcknowledgementReceiptDownload
          surveyData={surveyData}
          formContent={formContent}
        />
      </PrintComponent>

      {/* snackbar */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={
            snackbarMessage?.includes("successful") ? "success" : "error"
          }
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      {/* /snackbar */}
    </>
  );
};

export default AcknowledgementReceiptClientSide;
