import React, { useRef } from "react";
import { Button, Modal, Backdrop, Fade } from "@mui/material";
import crossImg from "../../imgs/cross.png";

import SignaturePad from "react-signature-canvas";

const MySignatureCanvas = ({ open, onClose, onSuccess }) => {
  const sigCanvas = useRef({});

  function dataURLtoBlob(dataURL) {
    let array, binary, i, len;
    binary = atob(dataURL.split(",")[1]);
    array = [];
    i = 0;
    len = binary.length;
    while (i < len) {
      array.push(binary.charCodeAt(i));
      i++;
    }
    return new Blob([new Uint8Array(array)], {
      type: "image/png",
    });
  }

  // Function to convert Blob to File
  function blobToFile(blob, fileName) {
    return new File([blob], fileName, {
      type: blob.type,
      lastModified: Date.now(),
    });
  }

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      onSuccess(file);
      onClose();
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="mx-3"
      >
        <Fade in={open}>
          <div className="modal-container">
            {" "}
            {/* CSS class for centering */}
            <div className="modal-content assign-modal px-4">
              <div className="d-flex">
                <img
                  src={crossImg}
                  height="26"
                  width="26"
                  className="ms-auto cursor-pointer"
                  onClick={() => onClose()}
                />{" "}
              </div>
              {/* Modal content goes here */}
              <p className="modal-head mb-0">Draw your Signature</p>

              <SignaturePad
                ref={sigCanvas}
                canvasProps={{
                  className: "signatureCanvas",
                }}
              />

              <Button
                className="add-new-btn ms-auto"
                onClick={() => {
                  sigCanvas.current.clear();
                }}
              >
                clear
              </Button>
              <Button
                className="add-new-btn ms-auto"
                onClick={() => {
                  const dataURL = sigCanvas.current.toDataURL("image/png");
                  const blob = dataURLtoBlob(dataURL);
                  const file = blobToFile(blob, "signature.png");
                  onSuccess(file);
                  onClose();
                }}
              >
                upload
              </Button>

              <Button
                className="add-new-btn ms-auto"
                onClick={handleButtonClick}
              >
                File Upload
                <input
                  type="file"
                  ref={fileInputRef}
                  hidden
                  onChange={handleFileChange}
                />
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default MySignatureCanvas;
