// Create a Molecule for the Payment Plan form
import React, { useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import PaymentInput from "../atoms/PaymentInput";
import "./style.css";

const SeaPaymentPlanForm = ({ onSubmit, emailToRegister }) => {
  // for twenty ft
  const [doorToDoor, setDoorToDoor] = useState("");
  const [doorToDoorError, setDoorToDoorError] = useState(false);

  const [doorToPort, setDoorToPort] = useState("");
  const [doorToPortError, setDoorToPortError] = useState(false);

  const [portToDoor, setPortToDoor] = useState("");
  const [portToDoorError, setPortToDoorError] = useState(false);

  const [impExp, setImpExp] = useState("");
  const [impExpError, setImpExpError] = useState(false);

  const [transt, setTranst] = useState("");
  const [transtError, setTranstError] = useState(false);

  const [ship, setShip] = useState("");
  const [shipError, setShipError] = useState(false);

  //    for forty ft
  const [doorToDoor1, setDoorToDoor1] = useState("");
  const [doorToDoorError1, setDoorToDoorError1] = useState(false);

  const [doorToPort1, setDoorToPort1] = useState("");
  const [doorToPortError1, setDoorToPortError1] = useState(false);

  const [portToDoor1, setPortToDoor1] = useState("");
  const [portToDoorError1, setPortToDoorError1] = useState(false);

  const [impExp1, setImpExp1] = useState("");
  const [impExpError1, setImpExpError1] = useState(false);

  const [transt1, setTranst1] = useState("");
  const [transtError1, setTranstError1] = useState(false);

  const [ship1, setShip1] = useState("");
  const [shipError1, setShipError1] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    onSubmit({ doorToDoor, doorToPort, portToDoor, transt, impExp, ship, doorToDoor1, doorToPort1, portToDoor1, transt1, impExp1, ship1 });
  };

  const isConfirmButtonDisabled =
    doorToDoor === "" ||
    doorToPort === "" ||
    portToDoor === "" ||
    transt === "" ||
    impExp === "" ||
    ship === "" ||
    doorToDoor1 === "" ||
    doorToPort1 === "" ||
    portToDoor1 === "" ||
    transt1 === "" ||
    impExp1 === "" ||
    ship1 === "";

  return (
    <form onSubmit={handleSubmit}>
      <Grid
        container
        className="bg-white my-4 mx-0  p-3 py-4"
        style={{ borderRadius: "5px" }}
      >
        <Grid item xs={12} className="mb-4 pe-4 text-start fn">
          {" "}
          <b>1. Twenty Feet Container</b>
        </Grid>

        <Grid item xs={12} sm={6} className="mb-4 pe-4">
          <PaymentInput
            value={doorToDoor}
            onChange={(e) => {
              setDoorToDoor(e.target.value);
            }}
            error={doorToDoorError}
            name={"Door to Door"}
          />
        </Grid>
        <Grid item xs={12} sm={6} className="mb-4 pe-4">
          <PaymentInput
            value={doorToPort}
            onChange={(e) => {
              setDoorToPort(e.target.value);
            }}
            error={doorToPortError}
            name={"Door to Port"}
          />
        </Grid>

        <Grid item xs={12} sm={6} className="mb-4 pe-4">
          <PaymentInput
            value={portToDoor}
            onChange={(e) => {
              setPortToDoor(e.target.value);
            }}
            error={portToDoorError}
            name={"Port to Door"}
          />
        </Grid>

        <Grid item xs={12} sm={6} className="mb-4 pe-4">
          <PaymentInput
            value={transt}
            onChange={(e) => {
              setTranst(e.target.value);
            }}
            error={transtError}
            name={"Transportation Services"}
          />
        </Grid>
        <Grid item xs={12} sm={6} className="mb-4 pe-4">
          <PaymentInput
            value={impExp}
            onChange={(e) => {
              setImpExp(e.target.value);
            }}
            error={impExpError}
            name={"Vehicle Import & Export Services"}
          />
        </Grid>
        <Grid item xs={12} sm={6} className="mb-4 pe-4">
          <PaymentInput
            value={ship}
            onChange={(e) => {
              setShip(e.target.value);
            }}
            error={shipError}
            name={"Shipping & Packing Services"}
          />
        </Grid>

        <Grid item xs={12} className="mb-4 pe-4 text-start fn">
          {" "}
          <b>2. Forty Feet Container</b>
        </Grid>

        <Grid item xs={12} sm={6} className="mb-4 pe-4">
          <PaymentInput
            value={doorToDoor1}
            onChange={(e) => {
              setDoorToDoor1(e.target.value);
            }}
            error={doorToDoorError1}
            name={"Door to Door"}
          />
        </Grid>
        <Grid item xs={12} sm={6} className="mb-4 pe-4">
          <PaymentInput
            value={doorToPort1}
            onChange={(e) => {
              setDoorToPort1(e.target.value);
            }}
            error={doorToPortError1}
            name={"Door to Port"}
          />
        </Grid>

        <Grid item xs={12} sm={6} className="mb-4 pe-4">
          <PaymentInput
            value={portToDoor1}
            onChange={(e) => {
              setPortToDoor1(e.target.value);
            }}
            error={portToDoorError1}
            name={"Port to Door"}
          />
        </Grid>

        <Grid item xs={12} sm={6} className="mb-4 pe-4">
          <PaymentInput
            value={transt1}
            onChange={(e) => {
              setTranst1(e.target.value);
            }}
            error={transtError1}
            name={"Transportation Services"}
          />
        </Grid>
        <Grid item xs={12} sm={6} className="mb-4 pe-4">
          <PaymentInput
            value={impExp1}
            onChange={(e) => {
              setImpExp1(e.target.value);
            }}
            error={impExpError1}
            name={"Vehicle Import & Export Services"}
          />
        </Grid>
        <Grid item xs={12} sm={6} className="mb-4 pe-4">
          <PaymentInput
            value={ship1}
            onChange={(e) => {
              setShip1(e.target.value);
            }}
            error={shipError1}
            name={"Shipping & Packing Services"}
          />
        </Grid>

        <Grid item xs={12} sm={6} className="mb-4 pe-4">
          <Button
            type="submit"
            variant="contained"
            className={
              isConfirmButtonDisabled
                ? "disabled-btn mt-1 text-capitalize"
                : "submit-btn mt-1 text-capitalize"
            }
            disabled={isConfirmButtonDisabled}
          >
            Send Quotation
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default SeaPaymentPlanForm;
