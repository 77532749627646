import React, { useEffect, useState } from "react";
import {
  getSingleSurvey,
  addFormContent,
  updateFormContent,
  getFormContent,
  surveyResultPreview,
  sharePreviewLink,
  imageUpload,
} from "../../../api/services/services";
import backArrow from "../../../imgs/BackArrow.svg";
import MainTemplate from "../../templates/MainTemplate";
import { useNavigate } from "react-router-dom";
import { Button, Snackbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import { Bucket } from "../../../api/config/apiConfig";
import ContactDetailField from "../../molecules/ContactDetailField";
import CustomCheckbox from "../../atoms/CustomCheckbox";
import DynamicText from "../../molecules/DynamicText";
import { useParams } from "react-router-dom";
import ShareWithClientsModal from "../../molecules/ShareWithClients";
import PrintComponent from "../PrintComponent";
import CrewInstructionDownload from "./ClientSide/download";
import editIcn from "../../../imgs/edit.png";
import trashIcn from "../../../imgs/trash.png";
import LoadingModal from "../../molecules/LoadingModal";
import moment from "moment";
import MySignatureCanvas from "../../molecules/signature_canvas";
import { sendSuperAdminVerificationForEdit } from "../PrebidAgreement/creation/service";
import SuperAdminVerifationModal from "../PrebidAgreement/creation/super_admin_verification_modal";

const CrewInstructionFormPage = () => {
  const { surveyId, id, flg } = useParams();
  const [surveyData, setSurveyData] = useState(null);
  const navigate = useNavigate();

  const [formContentId, setFormContentId] = useState("");
  const [isEdit, setIsEdit] = useState(flg == "1" ? true : false);

  const [isLoading, setIsLoading] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isShare, setIsShare] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const [formContent, setFormContent] = useState({
    fielRef: "",
    customerName: "",
    customerEmail: "",
    organization: "",
    originAddress: "",
    destinationAddress: "",
    date: "",
    documentdate: "",
    preMoveSurcey: false,
    localMove: false,
    internationalMove: false,
    road: false,
    assembling: false,
    debrisRemoval: false,
    payments: false,
    documents: false,
    air: false,
    loading: false,
    unloading: false,
    invoice: false,
    delivery: false,
    sea: false,
    packing: false,
    unpacking: false,
    jobStartDate: "",
    jobEndDate: "",
    jobStartTime: "",
    jobEndTime: "",
    exclusions: "",
    clients: [],
    moveCordinatorSign: "",
    packingManagerSign: "",
  });

  const [isSignCanvas, setSignCanvas] = useState(false);
  const [whichSign, setWhichSign] = useState("");

  //
  const getSurveyPreview = () => {
    surveyResultPreview(surveyId)
      .then((response) => {
        setSurveyData(response?.data?.data?.surveyDetail);

        let values = { ...formContent };
        values.fielRef = response?.data?.data?.surveyDetail?.id;
        values.originAddress = `${response?.data?.data?.surveyDetail?.loadPlaceStAddress}, ${response?.data?.data?.surveyDetail?.loadPlaceCity}, ${response?.data?.data?.surveyDetail?.loadPlaceCountry}`;
        values.destinationAddress = `${response?.data?.data?.surveyDetail?.unloadPlaceStAddress}, ${response?.data?.data?.surveyDetail?.unloadPlaceCity}, ${response?.data?.data?.surveyDetail?.unloadPlaceCountry}`;
        values.customerName = `${response?.data?.data?.surveyDetail?.name}`;
        values.customerEmail = `${response?.data?.data?.surveyDetail?.email}`;
        setFormContent(values);
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }
      });
  };

  //
  const getSurvey = () => {
    getSingleSurvey(surveyId)
      .then((response) => {
        if (response?.data?.data?.status == "submitted") getSurveyPreview();
        else {
          setSurveyData(response?.data?.data);
          let values = { ...formContent };

          values.fielRef = response?.data?.data?.id;
          values.originAddress = `${response?.data?.data?.loadPlaceStAddress}, ${response?.data?.data?.loadPlaceCity}, ${response?.data?.data?.loadPlaceCountry}`;
          values.destinationAddress = `${response?.data?.data?.unloadPlaceStAddress}, ${response?.data?.data?.unloadPlaceCity}, ${response?.data?.data?.unloadPlaceCountry}`;
          values.customerName = `${response?.data?.data?.name}`;
          values.customerEmail = `${response?.data?.data?.email}`;

          setFormContent(values);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }
      });
  };

  const [mediaKeysToSend, setMediaKeysToSend] = useState([]);
  const [mediaKeysToRemove, setMediaKeysToRemove] = useState([]);

  //
  const addContent = () => {
    let media = [];

    if (formContent?.moveCordinatorSign != "")
      media[0] = formContent?.moveCordinatorSign;
    if (formContent?.packingManagerSign != "")
      media[1] = formContent?.packingManagerSign;

    let newMedia = [];
    let mdInd = 0;
    media?.map((md, ind) => {
      if (md != null) {
        newMedia[mdInd] = md;
        mdInd++;
      }
    });

    setIsLoading(true);
    const body = {
      formId: id,
      surveyId: surveyId,
      content: formContent,
      mediaKeys: mediaKeysToSend,
    };
    addFormContent(body)
      .then(() => {
        setSnackbarMessage(
          "Crew Instruction Document has been added successfully!"
        );
        setOpenSnackbar(true);
        navigate(-1);

        setIsLoading(false);
        setMediaKeysToSend([]);
        setMediaKeysToRemove([]);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  //
  const updateContent = (data) => {
    setIsLoading(true);

    let media = [];
    if (formContent?.moveCordinatorSign != "")
      media[0] = formContent?.moveCordinatorSign;
    if (formContent?.packingManagerSign != "")
      media[1] = formContent?.packingManagerSign;

    let newMedia = [];
    let mdInd = 0;
    media?.map((md, ind) => {
      if (md != null) {
        newMedia[mdInd] = md;
        mdInd++;
      }
    });
    const body = {
      formId: id,
      surveyId: surveyId,
      content: data,
      mediaKeys: mediaKeysToSend,
      removedMediaKeys: mediaKeysToRemove,
    };
    updateFormContent(body, formContentId)
      .then((res) => {
        setSnackbarMessage(
          "Crew Instruction Document has been updated successfully!"
        );
        setOpenSnackbar(true);
        getContent();
        setIsEdit(false);

        setIsLoading(false);
        setMediaKeysToSend([]);
        setMediaKeysToRemove([]);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        // if (error?.response?.data?.statusCode == 401) {
        //   localStorage.clear();
        //   window.location.pathname = "/login";
        // }

        setIsLoading(false);
      });
  };

  //
  const getContent = () => {
    setIsLoading(true);
    setMediaKeysToSend([]);
    setMediaKeysToRemove([]);
    getFormContent(id, surveyId)
      .then((res) => {
        setFormContentId(res.data.data.uuid);
        setFormContent(res.data.data.content);
        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (flg == "1") getSurvey();
    else getContent();
  }, []);

  //
  const shareWithClient = (data) => {
    let ems = [];
    let emsInd = 0;
    data.map((dt) => {
      if (dt.trim().length != 0) {
        ems[emsInd] = dt;
        emsInd++;
      }
    });

    setIsLoading(true);
    const body = {
      surveyId: surveyId,
      formId: id,
      emails: ems,
      formRoute: "crew-instruction-document-preview",
    };
    sharePreviewLink(body)
      .then(() => {
        let values = { ...formContent };
        ems?.map((em, ind) => {
          values.clients.push({
            email: em,
            sign: "",
          });
        });
        setSnackbarMessage(
          "Crew Instruction Form has been shared with the respective emails successfully!"
        );
        setOpenSnackbar(true);

        updateContent(values);

        setIsLoading(false);
        setIsShare(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  const printAction = () => {
    const PAGE_HEIGHT = 1045;
    const printElement = document.getElementById("print-component");
    if (printElement) {
      printElement.classList.add("temp-class-for-height");
      const height = printElement.clientHeight - 500;
      const numberOfPage = Math.ceil(height / PAGE_HEIGHT);
      const heightWithSingleHeader = numberOfPage * PAGE_HEIGHT;
      let requiredHeight = heightWithSingleHeader;
      if (numberOfPage > 1) {
        const headerHeight =
          printElement.getElementsByTagName("thead")?.[0]?.clientHeight;
        const footerHeight =
          printElement.getElementsByTagName("tfoot")?.[0]?.clientHeight;
        requiredHeight -= (numberOfPage - 1) * (headerHeight + footerHeight);
      }
      printElement.style.height = `${requiredHeight}px`;
      printElement.classList.remove("temp-class-for-height");
    }
    window.print();
    if (printElement) {
      printElement.style.height = `auto`;
    }
  };

  const [uploadText1, setUploadText1] = useState("Upload Sign");
  const [uploadText2, setUploadText2] = useState("Upload Sign");

  //
  const uploadFile = async (file) => {
    setIsLoading(true);

    handleOpen();
    if (whichSign == "move") setUploadText1("Uploading...");
    if (whichSign == "manager") setUploadText2("Uploading...");

    const fd = new FormData();
    fd.append("image", file);

    imageUpload(fd)
      .then((res) => {
        let values = { ...formContent };
        let dlt = [...mediaKeysToRemove];
        if (whichSign == "move") {
          if (formContent.moveCordinatorSign?.trim()?.length != 0)
            dlt.push(formContent.moveCordinatorSign);
          values.moveCordinatorSign = res?.data?.data?.key;
        }
        if (whichSign == "manager") {
          if (formContent.packingManagerSign?.trim()?.length != 0)
            dlt.push(formContent.packingManagerSign);
          values.packingManagerSign = res?.data?.data?.key;
        }

        setMediaKeysToRemove(dlt);
        setFormContent(values);

        let md = [...mediaKeysToSend];
        md.push(res?.data?.data?.key);
        setMediaKeysToSend(md);

        setIsLoading(false);

        setUploadText1("Upload Sign");
        setUploadText2("Upload Sign");
        handleClose();
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setUploadText1("Upload Sign");
        setUploadText2("Upload Sign");

        setIsLoading(false);
        handleClose();
      });
  };

  const [isVerified, setIsVerified] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);

  const sendEditRequestToAdmin = () => {
    setIsEditLoading(true);
    sendSuperAdminVerificationForEdit()
      .then((response) => {
        setIsVerified(true);
        setIsEditLoading(false);
      })
      .catch((error) => {
        setIsVerified(false);
        setIsEditLoading(false);
      });
  };

  return (
    <>
      <MainTemplate>
        {/* header */}
        <div className="no-print">
          <div className="no-print">
            <div className="d-block d-sm-flex align-items-center py-4">
              <div className="d-flex align-items-center ">
                <div
                  className="light-blue-color back-bg cursor-pointer "
                  onClick={() => navigate(-1)}
                >
                  <img src={backArrow} height="24" width="24" />{" "}
                </div>
                <div>
                  <p className="main-head ps-3 mb-0">
                    Crew Instruction Document
                  </p>
                </div>
              </div>
              <div className="d-flex ms-auto justify-content-center mt-2 mt-sm-0">
                {/*Edit Button */}
                {!isEdit && (
                  <Button
                    className="active-btn"
                    onClick={() => {
                      sendEditRequestToAdmin();
                    }}
                    disabled={isEditLoading}
                  >
                    Edit
                  </Button>
                )}

                {/*Share Button */}
                {!isEdit && (
                  <Button
                    className="active-btn ms-3"
                    onClick={() => {
                      setIsShare(true);
                    }}
                    disabled={isLoading}
                  >
                    Share
                  </Button>
                )}

                {/* cancel button */}
                {isEdit && flg == "2" && (
                  <Button
                    className="active-btn ms-3"
                    onClick={() => {
                      getContent();
                      setIsEdit(false);
                    }}
                    disabled={isLoading}
                  >
                    Cancel
                  </Button>
                )}

                {/* Save Button */}
                {isEdit && (
                  <Button
                    className="active-btn ms-3"
                    onClick={() => {
                      if (flg == "1") addContent();
                      else updateContent(formContent);
                    }}
                    disabled={isLoading}
                  >
                    Save
                  </Button>
                )}

                {/* Download Button */}
                {!isEdit && (
                  <Button
                    className="active-btn ms-3 px-0"
                    onClick={() => {
                      setIsLoading(true);
                      // printAction();
                      var tempTitle = document.title;
                      document.title = `Crew Instruction-${
                        formContent?.customerName
                      }-${moment(new Date()).format("DD.MM.YYYY")}`;

                      window.print();
                      document.title = tempTitle;
                      setIsLoading(false);
                    }}
                    disabled={isLoading}
                  >
                    {"Save as PDF"}
                  </Button>
                )}
              </div>
            </div>
          </div>
          {/* /header */}
          {/* crew Details */}
          <div className="mt-5 pe-1 mb-5 no-print">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div>
                <Typography variant="body1" gutterBottom>
                  <ContactDetailField
                    label="File Ref"
                    isEdit={isEdit}
                    type="text"
                    minWidth={"170px"}
                    value={formContent?.fielRef}
                    disabled={true}
                  />
                  <ContactDetailField
                    label="Customer Name"
                    isEdit={isEdit}
                    type="text"
                    minWidth={"170px"}
                    value={formContent?.customerName}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.customerName = e.target.value;
                      setFormContent(values);
                    }}
                  />
                  <ContactDetailField
                    label="Organization"
                    isEdit={isEdit}
                    type="text"
                    minWidth={"170px"}
                    value={formContent?.organization}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.organization = e.target.value;
                      setFormContent(values);
                    }}
                  />
                  <ContactDetailField
                    label="Origin Address"
                    isEdit={isEdit}
                    type="text"
                    minWidth={"170px"}
                    value={formContent?.originAddress}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.originAddress = e.target.value;
                      setFormContent(values);
                    }}
                  />
                  <ContactDetailField
                    label="Destination Address"
                    isEdit={isEdit}
                    type="text"
                    minWidth={"170px"}
                    value={formContent?.destinationAddress}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.destinationAddress = e.target.value;
                      setFormContent(values);
                    }}
                  />
                  <ContactDetailField
                    label="Date"
                    isEdit={isEdit}
                    type="date"
                    minWidth={"170px"}
                    value={formContent?.date}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.date = e.target.value;
                      setFormContent(values);
                    }}
                  />
                  <ContactDetailField
                    label="Document Date"
                    isEdit={isEdit}
                    type="date"
                    minWidth={"170px"}
                    value={formContent?.documentdate}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.documentdate = e.target.value;
                      setFormContent(values);
                    }}
                  />
                </Typography>
              </div>
            </Typography>
          </div>
          {/* /crew Details */}

          {/* Description of Services */}
          <Typography
            variant="body1"
            className="text-start no-print"
            gutterBottom
          >
            <div className="mb-2">
              <label htmlFor="Description of Services" className="fw-bold mb-4">
                Description of Services
              </label>
              <div className="row crew">
                <div className="col-md-3 col-sm-6">
                  <CustomCheckbox
                    style="mb-3"
                    id="preMoveSurvey"
                    label="Pre Move Survey"
                    value={formContent?.preMoveSurcey}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.preMoveSurcey = e.target.checked;
                      setFormContent(values);
                    }}
                    path={""}
                    disabled={!isEdit ? true : false}
                  />
                </div>
                <div className="col-md-3 col-sm-6">
                  <CustomCheckbox
                    style="mb-3"
                    id="localMove"
                    label="Local Move"
                    value={formContent?.localMove}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.localMove = e.target.checked;
                      setFormContent(values);
                    }}
                    path={""}
                    disabled={!isEdit ? true : false}
                  />
                </div>
                <div className="col-md-3 col-sm-6">
                  <CustomCheckbox
                    style="mb-3"
                    id="InternationalMove"
                    label="International Move"
                    value={formContent?.internationalMove}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.internationalMove = e.target.checked;
                      setFormContent(values);
                    }}
                    path={""}
                    disabled={!isEdit ? true : false}
                  />
                </div>
                <div className="col-md-3 col-sm-6">
                  <CustomCheckbox
                    style="mb-3"
                    id="Road"
                    label="Road"
                    value={formContent?.road}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.road = e.target.checked;
                      setFormContent(values);
                    }}
                    path={""}
                    disabled={!isEdit ? true : false}
                  />
                </div>
                <div className="col-md-3 col-sm-6">
                  <CustomCheckbox
                    style="mb-3"
                    id="Assembling"
                    label="Assembling"
                    value={formContent?.assembling}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.assembling = e.target.checked;
                      setFormContent(values);
                    }}
                    path={""}
                    disabled={!isEdit ? true : false}
                  />
                </div>
                <div className="col-md-3 col-sm-6">
                  <CustomCheckbox
                    style="mb-3"
                    id="debrisRemoval"
                    label="Debris Removal"
                    value={formContent?.debrisRemoval}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.debrisRemoval = e.target.checked;
                      setFormContent(values);
                    }}
                    path={""}
                    disabled={!isEdit ? true : false}
                  />
                </div>
                <div className="col-md-3 col-sm-6">
                  <CustomCheckbox
                    style="mb-3"
                    id="Payments"
                    label="Payments"
                    value={formContent?.payments}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.payments = e.target.checked;
                      setFormContent(values);
                    }}
                    path={""}
                    disabled={!isEdit ? true : false}
                  />
                </div>
                <div className="col-md-3 col-sm-6">
                  <CustomCheckbox
                    style="mb-3"
                    id="Documents"
                    label="Documents"
                    value={formContent?.documents}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.documents = e.target.checked;
                      setFormContent(values);
                    }}
                    path={""}
                    disabled={!isEdit ? true : false}
                  />
                </div>
                <div className="col-md-3 col-sm-6">
                  <CustomCheckbox
                    style="mb-3"
                    id="Air"
                    label="Air"
                    value={formContent?.air}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.air = e.target.checked;
                      setFormContent(values);
                    }}
                    path={""}
                    disabled={!isEdit ? true : false}
                  />
                </div>
                <div className="col-md-3 col-sm-6">
                  <CustomCheckbox
                    style="mb-3"
                    id="Unloading"
                    label="Unloading"
                    value={formContent?.unloading}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.unloading = e.target.checked;
                      setFormContent(values);
                    }}
                    path={""}
                    disabled={!isEdit ? true : false}
                  />
                </div>
                <div className="col-md-3 col-sm-6">
                  <CustomCheckbox
                    style="mb-3"
                    id="Loading"
                    label="Loading"
                    value={formContent?.loading}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.loading = e.target.checked;
                      setFormContent(values);
                    }}
                    path={""}
                    disabled={!isEdit ? true : false}
                  />
                </div>
                <div className="col-md-3 col-sm-6">
                  <CustomCheckbox
                    style="mb-3"
                    id="Invoice"
                    label="Invoice"
                    value={formContent?.invoice}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.invoice = e.target.checked;
                      setFormContent(values);
                    }}
                    path={""}
                    disabled={!isEdit ? true : false}
                  />
                </div>
                <div className="col-md-3 col-sm-6">
                  <CustomCheckbox
                    style="mb-3"
                    id="Delivery"
                    label="Delivery"
                    value={formContent?.delivery}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.delivery = e.target.checked;
                      setFormContent(values);
                    }}
                    path={""}
                    disabled={!isEdit ? true : false}
                  />
                </div>
                <div className="col-md-3 col-sm-6">
                  <CustomCheckbox
                    style="mb-3"
                    id="Sea"
                    label="Sea"
                    value={formContent?.sea}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.sea = e.target.checked;
                      setFormContent(values);
                    }}
                    path={""}
                    disabled={!isEdit ? true : false}
                  />
                </div>
                <div className="col-md-3 col-sm-6">
                  <CustomCheckbox
                    style="mb-3"
                    id="Packing"
                    label="Packing"
                    value={formContent?.packing}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.packing = e.target.checked;
                      setFormContent(values);
                    }}
                    path={""}
                    disabled={!isEdit ? true : false}
                  />
                </div>

                <div className="col-md-3 col-sm-6">
                  <CustomCheckbox
                    style="mb-3"
                    id="Unpacking"
                    label="Unpacking"
                    value={formContent?.unpacking}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.unpacking = e.target.checked;
                      setFormContent(values);
                    }}
                    path={""}
                    disabled={!isEdit ? true : false}
                  />
                </div>
              </div>
            </div>
          </Typography>
          {/* /Description of Services */}
          {/* date and time*/}
          <div className="no-print">
            <div className="contact-detail d-flex mt-5">
              {/* left col */}
              <div
                className={`left-col ${!isEdit ? " w-50" : "w-50"} text-start `}
              >
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{ width: "80%" }}
                >
                  <ContactDetailField
                    label="Job Start Date"
                    isEdit={isEdit}
                    type="date"
                    value={formContent?.jobStartDate}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.jobStartDate = e.target.value;
                      setFormContent(values);
                    }}
                    minWidth={"125px"}
                  />
                  <ContactDetailField
                    label="Job End Date"
                    isEdit={isEdit}
                    type="date"
                    value={formContent?.jobEndDate}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.jobEndDate = e.target.value;
                      setFormContent(values);
                    }}
                    minWidth={"125px"}
                  />
                </Typography>
              </div>
              {/* right col */}
              <div
                className={`right-col ${
                  !isEdit ? "w-50 pe-5 " : "w-50 pe-2"
                }  d-flex flex-column align-items-end justify-content-end text-end`}
              >
                {isEdit ? (
                  <Typography
                    variant="body1"
                    className="text-start "
                    gutterBottom
                    style={{ width: "80%" }}
                  >
                    <ContactDetailField
                      label="Job Start Time"
                      isEdit={isEdit}
                      type="time"
                      value={formContent?.jobStartTime}
                      onChange={(e) => {
                        let values = { ...formContent };
                        values.jobStartTime = e.target.value;
                        setFormContent(values);
                      }}
                      minWidth={"125px"}
                    />
                    <ContactDetailField
                      label="Job End Time"
                      isEdit={isEdit}
                      type="time"
                      value={formContent?.jobEndTime}
                      onChange={(e) => {
                        let values = { ...formContent };
                        values.jobEndTime = e.target.value;
                        setFormContent(values);
                      }}
                      minWidth={"125px"}
                    />
                  </Typography>
                ) : (
                  <div>
                    <Typography
                      variant="body1"
                      className="text-start "
                      gutterBottom
                      style={{ width: "80%" }}
                    >
                      <ContactDetailField
                        label="Job Start Time"
                        isEdit={isEdit}
                        type="time"
                        value={formContent?.jobStartTime}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.jobStartTime = e.target.value;
                          setFormContent(values);
                        }}
                        minWidth={"125px"}
                      />
                      <ContactDetailField
                        label="Job End Time"
                        isEdit={isEdit}
                        type="time"
                        value={formContent?.jobEndTime}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.jobEndTime = e.target.value;
                          setFormContent(values);
                        }}
                        minWidth={"125px"}
                      />
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* /date and time */}
          {/* Standard Exclusions */}
          <div className="mt-4 pe-1 mb-5 no-print">
            <Typography variant="body1" className="text-start" gutterBottom>
              <DynamicText
                label="Remarks:"
                textAreaLabel="Remarks..."
                isEdit={isEdit}
                value={formContent?.exclusions}
                onChange={(e) => {
                  let values = { ...formContent };
                  values.exclusions = e.target.value;
                  setFormContent(values);
                }}
              />
            </Typography>
          </div>
          {/* /Standard Exclusions */}

          <Row className="mt-5 pt-5 no-print">
            <Col sm={6} className="mt-5 pt-5">
              <div
                style={{ height: "90px" }}
                className="d-flex justify-content-center align-items-end"
              >
                {formContent?.moveCordinatorSign?.trim()?.length == 0 ? (
                  <>
                    {isEdit && (
                      <div className="d-flex justify-content-center align-items-end">
                        <label
                          htmlFor="uploadSign"
                          className="active-btn ms-2 w-200 d-flex align-items-center justify-content-center cursor-pointer btn"
                        >
                          {uploadText1}
                        </label>
                        <input
                          type="button"
                          hidden
                          id="uploadSign"
                          name="uploadSign"
                          onClick={() => {
                            setWhichSign("move");
                            setSignCanvas(true);
                          }}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="d-flex align-items-start">
                    <img
                      src={`${Bucket}${formContent?.moveCordinatorSign}`}
                      height={80}
                      width={200}
                    />
                    {isEdit && (
                      <>
                        <label
                          htmlFor="editSign1"
                          className=" ms-2  d-flex align-items-center justify-content-center cursor-pointer btn p-0"
                        >
                          <img
                            src={editIcn}
                            height={18}
                            width={18}
                            className="ms-2 cursor-pointer"
                          />
                        </label>
                        <input
                          type="button"
                          hidden
                          id="editSign1"
                          name="uploadSign"
                          onClick={() => {
                            setWhichSign("move");
                            setSignCanvas(true);
                          }}
                        />
                        <img
                          src={trashIcn}
                          height={18}
                          width={18}
                          className="ms-2 cursor-pointer"
                          onClick={() => {
                            let keysToDelete = [...mediaKeysToRemove];
                            keysToDelete.push(formContent?.moveCordinatorSign);
                            setMediaKeysToRemove(keysToDelete);

                            let values = { ...formContent };
                            values.moveCordinatorSign = "";
                            setFormContent(values);
                          }}
                        />
                      </>
                    )}
                  </div>
                )}
              </div>

              <p>{"_______________________________"}</p>
              <p className="my-1">
                <b>{"Move Coordinator"}</b>
              </p>

              {""}
            </Col>
            <Col sm={6} className="mt-5 pt-5">
              <div
                style={{ height: "90px" }}
                className="d-flex justify-content-center align-items-end"
              >
                {formContent?.packingManagerSign?.trim()?.length == 0 ? (
                  <>
                    {isEdit && (
                      <div className="d-flex justify-content-center align-items-end">
                        <label
                          htmlFor="uploadSign2"
                          className="active-btn ms-2 w-200 d-flex align-items-center justify-content-center cursor-pointer btn"
                        >
                          {uploadText2}
                        </label>
                        <input
                          type="button"
                          hidden
                          id="uploadSign2"
                          name="uploadSign2"
                          onClick={() => {
                            setWhichSign("manager");
                            setSignCanvas(true);
                          }}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="d-flex align-items-start">
                    <img
                      src={`${Bucket}${formContent.packingManagerSign}`}
                      height={80}
                      width={200}
                    />
                    {isEdit && (
                      <>
                        <label
                          htmlFor="editSign2"
                          className=" ms-2  d-flex align-items-center justify-content-center cursor-pointer btn p-0"
                        >
                          <img
                            src={editIcn}
                            height={18}
                            width={18}
                            className="ms-2 cursor-pointer"
                          />
                        </label>
                        <input
                          type="button"
                          hidden
                          id="editSign2"
                          name="uploadSign"
                          onClick={() => {
                            setWhichSign("manager");
                            setSignCanvas(true);
                          }}
                        />
                        <img
                          src={trashIcn}
                          height={18}
                          width={18}
                          className="ms-2 cursor-pointer"
                          onClick={() => {
                            let keysToDelete = [...mediaKeysToRemove];
                            keysToDelete.push(formContent.packingManagerSign);
                            setMediaKeysToRemove(keysToDelete);

                            let values = { ...formContent };
                            values.packingManagerSign = "";
                            setFormContent(values);
                          }}
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
              <p>{"_______________________________"}</p>
              <p className="my-1">
                <b>{"Packing Manager"}</b>
              </p>

              {""}
            </Col>
          </Row>
        </div>

        <PrintComponent>
          <CrewInstructionDownload
            surveyData={surveyData}
            formContent={formContent}
          />
        </PrintComponent>

        {/* snackbar */}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000} // Adjust the duration as needed
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={
              snackbarMessage?.includes("successful") ? "success" : "error"
            }
            onClose={handleCloseSnackbar}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>

        {/* Add Role Modal */}
        {isShare && (
          <ShareWithClientsModal
            open={isShare}
            onClose={() => {
              setIsShare(false);
            }}
            onSuccess={(emails) => {
              shareWithClient(emails);
            }}
          />
        )}

        {/* Add signature Modal */}
        {isSignCanvas && (
          <MySignatureCanvas
            open={isSignCanvas}
            onClose={() => {
              setSignCanvas(false);
            }}
            onSuccess={(file) => {
              if (!isLoading) uploadFile(file);
            }}
          />
        )}

        {isVerified && (
          <SuperAdminVerifationModal
            open={isVerified}
            onClose={() => {
              setIsVerified(false);
            }}
            onSuccess={() => {
              setIsEdit(true);
              setIsVerified(false);
            }}
            onError={() => {
              // shareWithClient(emails);
              setSnackbarMessage("Invalid Confirm Pin");
              setOpenSnackbar(true);
            }}
          />
        )}

        <LoadingModal open={open} handleClose={handleClose} />
      </MainTemplate>
    </>
  );
};
export default CrewInstructionFormPage;
