import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Backdrop,
  Fade,
  Snackbar,
  InputLabel,
} from "@mui/material";
import crossImg from "../../imgs/cross.png";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { updateStatus } from "../../api/services/services";
import Grid from "@mui/material/Grid";
import ReasonInput from "../atoms/ReasonInput";
import MuiAlert from "@mui/material/Alert";

const RejectModal = ({ open, onClose, onSucces, id }) => {
  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  //
  const reject = () => {
    const body = {
      status: "rejected",
      reason: reason,
    };

    updateStatus(id, body)
      .then((response) => {
        onSucces();
        setReason('')
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = '/login'
        }

        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);
        
        console.error(error?.response?.data?.message);
      });
  };

  useEffect(() => {}, []);

  const disableReject = reason.trim().length === 0;

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="modal-container">
            {" "}
            {/* CSS class for centering */}
            <div className="modal-content assign-modal px-4">
              <div className="d-flex">
                <img
                  src={crossImg}
                  height="26"
                  width="26"
                  className="ms-auto cursor-pointer"
                  onClick={() => onClose()}
                />{" "}
              </div>
              {/* Modal content goes here */}
              <p className="modal-head mb-0">Reject Survey</p>
              <p className="modal-subhead">
                Are you sure you want to reject this survey?
              </p>

              {/*  */}
              <Grid item xs={12} className="mt-3">
                <ReasonInput
                  value={reason}
                  onChange={(e) => {
                    setReason(e.target.value);
                  }}
                  error={reasonError}
                />
              </Grid>

              <Grid container spacing={2} className="mt-2">
                <Grid item xs={12}>
                  <Button
                    type="button"
                    variant="contained"
                    className={
                      disableReject
                        ? "disabled-btn mt-1 mb-2"
                        : "submit-btn mt-1 mb-2"
                    }
                    disabled={disableReject}
                    onClick={() => {
                      reject();
                    }}
                  >
                    Reject
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="button"
                    variant="contained"
                    className="cancel-btn mb-3"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </Fade>
      </Modal>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={
            snackbarMessage?.includes("successful") ? "success" : "error"
          }
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default RejectModal;
