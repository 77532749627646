import React, { useEffect, useState } from "react";
import {
  updateFormContentPreview,
  getFormContentPreview,
  imageUploadClientPreview,
} from "../../../../api/services/services";
import { Button, Snackbar, Box } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import { Bucket } from "../../../../api/config/apiConfig";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import Input from "../../../atoms/Input";
import removeImg from "../../../../imgs/remove.png";
import { useParams } from "react-router-dom";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import html2pdf from "html2pdf.js";
import { useLocation } from "react-router-dom";
import PrintComponent from "../../PrintComponent";
import ItemConditionDownload from "./download";
import moment from "moment";
import MySignatureCanvas from "../../../molecules/signature_canvas";

const ItemConditionReportClientSide = () => {
  const [surveyData, setSurveyData] = useState(null);
  const [formContent, setFormContent] = useState({});

  const [formContentId, setFormContentId] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const urlData = new URLSearchParams(location.search);
  const email = urlData.get("email");
  const surveyId = urlData.get("surveyId");
  const formId = urlData.get("formId");
  const token = urlData.get("token");

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  //
  const updateContent = (content, mediaKys) => {
    setIsLoading(true);
    const body = {
      formContentId: formContentId,
      content: content,
      mediaKeys: mediaKys,
      removedMediaKeys: [],
    };
    updateFormContentPreview(
      surveyId,
      formId,
      email,
      token,
      body,
      formContentId
    )
      .then((res) => {
        if (email == formContent?.clientEmail)
          setSnackbarMessage("Signature has been added successfully!");
        else
          setSnackbarMessage(
            "Items Condition Report has been updated successfully!"
          );

        setOpenSnackbar(true);
        getContent();
        setIsEdit(false);

        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          setIsValid(false);
        }

        setIsLoading(false);
      });
  };

  const [isValid, setIsValid] = useState(false);

  //
  const getContent = () => {
    setIsLoading(true);
    getFormContentPreview(surveyId, formId, email, token)
      .then((res) => {
        setIsValid(true);
        setFormContentId(res.data.data.uuid);
        setFormContent(res.data.data.content);

        setIsLoading(false);
      })
      .catch((error) => {
        setIsValid(false);
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          setIsValid(false);
        }

        setIsLoading(false);
      });
  };

  useEffect(() => {
    getContent();
  }, []);

  const [show, setShow] = useState(false);
  const options = {
    // resolution: Resolution.HIGH,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      format: "letter",
      // default is 'portrait'
      orientation: "portrait",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/png",
      qualityRatio: 1,
    },
    overrides: {
      pdf: {
        compress: true,
      },
    },
  };

  //
  const generatePdf = () => {
    setShow(true);

    setTimeout(() => {
      const content = document.getElementById("content-to-convert"); // Replace with the ID of the element you want to convert to PDF

      const currentDateTime = new Date();
      const filename = `survey_${currentDateTime.toISOString()}.pdf`;

      const pdfOptions = {
        margin: 5,
        filename: filename,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
        }, // Set the page size here
      };

      let temp = html2pdf().from(content).set(pdfOptions).outputPdf();
      temp.save(filename);

      setShow(false);
    }, 3500);
  };

  const [downloadText, setDownloadText] = useState("download");

  const getTargetElement = () => document.getElementById("content-id");
  const [mediaKeysToSend, setMediaKeysToSend] = useState();

  const [isSignCanvas, setSignCanvas] = useState(false);

  //
  const uploadFile = async (file) => {
    const fd = new FormData();
    fd.append("image", file);

    imageUploadClientPreview(fd, surveyId, formId, email, token)
      .then((res) => {
        let values = { ...formContent };
        values.clientSign = res?.data?.data?.key;
        setFormContent(values);

        let md = [];
        md.push(res?.data?.data?.key);
        // setMediaKeysToSend(md);

        setTimeout(() => {
          updateContent(values, md);
        }, 1500);
        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        setIsValid(false);

        setIsLoading(false);
      });

  };

  const handleAddNewDataRows = () => {
    const values = { ...formContent };
    const valuesRows = [...formContent?.dataRows];
    valuesRows.push({
      no: "",
      description: "",
      condition: "",
      disclamer: "",
    });
    values.dataRows = valuesRows;

    setFormContent(values);
  };

  const handleRemoveDataRows = (ind) => {
    const values = { ...formContent };
    const valuesRows = [...formContent?.dataRows];
    valuesRows.splice(ind, 1);
    values.dataRows = valuesRows;

    setFormContent(values);
  };

  const printAction = () => {
    const PAGE_HEIGHT = 1045;
    const printElement = document.getElementById("print-component");
    if (printElement) {
      printElement.classList.add("temp-class-for-height");
      const height = printElement.clientHeight;
      const numberOfPage = Math.ceil(height / PAGE_HEIGHT);
      const heightWithSingleHeader = numberOfPage * PAGE_HEIGHT;
      let requiredHeight = heightWithSingleHeader;
      if (numberOfPage > 1) {
        const headerHeight =
          printElement.getElementsByTagName("thead")?.[0]?.clientHeight;
        const footerHeight =
          printElement.getElementsByTagName("tfoot")?.[0]?.clientHeight;
        requiredHeight -= (numberOfPage - 1) * (headerHeight + footerHeight);
      }
      printElement.style.height = `${requiredHeight}px`;
      printElement.classList.remove("temp-class-for-height");
    }
    window.print();
    if (printElement) {
      printElement.style.height = `auto`;
    }
  };

  return (
    <>
      {isValid ? (
        <div className="mx-3 mx-md-5 no-print" style={{ minHeight: "100vh" }}>
          {/* <MainTemplate> */}

          <div className="d-flex align-items-center py-4">
            <p
              className="main-head ps-0 mb-0 text-end"
              style={{ width: "60%" }}
            >
              Item Condition Report at Origin
            </p>
            <div className="d-flex ms-auto justify-content-center mt-2 mt-sm-0">
              {/*Edit Button */}
              {!isEdit && email != formContent?.clientEmail && (
                <Button
                  className="active-btn"
                  onClick={() => {
                    setIsEdit(true);
                  }}
                  disabled={isLoading}
                >
                  Edit
                </Button>
              )}

              {/* Cancel button */}
              {isEdit && (
                <Button
                  className="active-btn ms-3"
                  onClick={() => {
                    getContent();
                    setIsEdit(false);
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
              )}

              {/* Save Button */}
              {isEdit && (
                <Button
                  className="active-btn ms-3"
                  onClick={() => {
                    updateContent(formContent, []);
                  }}
                  disabled={isLoading}
                >
                  Save
                </Button>
              )}

              {/* Upload Sign */}
              {email == formContent?.clientEmail &&
                formContent?.clientSign?.trim()?.length == 0 && (
                  <>
                    <label
                      htmlFor="uploadSign"
                      className="active-btn ms-3 w-150 d-flex align-items-center justify-content-center cursor-pointer btn"
                    >
                      Upload Sign
                    </label>
                    <input
                      type="button"
                      hidden
                      id="uploadSign"
                      name="uploadSign"
                      onClick={() => {
                        setSignCanvas(true);
                      }}
                    />
                  </>
                )}

              {/* Download Button */}
              {!isEdit && (
                <Button
                  className="active-btn ms-3 px-0"
                  onClick={() => {
                    setIsLoading(true);
                    // printAction();
                    var tempTitle = document.title;
                    document.title = `Item Condition Report at Origin-${formContent?.clientName
                      }-${moment(new Date()).format("DD.MM.YYYY")}`;

                    window.print();
                    document.title = tempTitle;
                    setIsLoading(false);
                  }}
                  disabled={isLoading}
                >
                  {"Save as PDF"}
                </Button>
              )}
            </div>
          </div>

          {/* <div className="d-block d-sm-flex align-items-center py-4">
            <div className="d-flex align-items-center ">
              <p className="main-head ps-0 mb-0">
                Item Condition Report at Origin
              </p>
            </div>
            <div className="d-flex ms-auto justify-content-center mt-2 mt-sm-0">
              {/*Edit Button */}
          {/* {!isEdit && email != formContent?.clientEmail && (
                <Button
                  className="active-btn"
                  onClick={() => {
                    setIsEdit(true);
                  }}
                  disabled={isLoading}
                >
                  Edit
                </Button>
              )} */}

          {/* Cancel button */}
          {/* {isEdit && (
                <Button
                  className="active-btn ms-3"
                  onClick={() => {
                    getContent();
                    setIsEdit(false);
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
              )} */}

          {/* Save Button */}
          {/* {isEdit && (
                <Button
                  className="active-btn ms-3"
                  onClick={() => {
                    updateContent(formContent, []);
                  }}
                  disabled={isLoading}
                >
                  Save
                </Button>
              )} */}

          {/* Upload Sign */}
          {/* {email == formContent?.clientEmail &&
                formContent?.clientSign?.trim()?.length == 0 && (
                  <>
                    <label
                      htmlFor="uploadSign"
                      className="active-btn ms-3 w-150 d-flex align-items-center justify-content-center cursor-pointer btn"
                    >
                      Upload Sign
                    </label>
                    <input
                      type="file"
                      hidden
                      id="uploadSign"
                      name="uploadSign"
                      onChange={(e) => uploadFile(e)}
                    />
                  </>
                )} */}

          {/* Download Button */}
          {/* {!isEdit && (
                <Button
                  className="active-btn ms-3 px-0"
                  onClick={() => {
                    setIsLoading(true);
                      // printAction();
                      var tempTitle = document.title;
                      document.title = `Item Condition Report at Origin-${
                        formContent?.clientName
                      }-${moment(new Date()).format("DD.MM.YYYY")}`;

                      window.print();
                      document.title = tempTitle;
                      setIsLoading(false);
                  }}
                  disabled={isLoading}
                >
                  {"Save as PDF"}
                </Button>
              )}
            </div> 
          </div> */}

          <Grid container className="pe-1 my-4 ">
            <TableContainer component={Paper} className="pb-3">
              <Table>
                {/* Table Header */}
                <TableHead>
                  <TableRow>
                    <TableCell className="table-head min-w-100">
                      Item No.
                    </TableCell>
                    <TableCell className="table-head min-w-400">
                      Item Description
                    </TableCell>
                    <TableCell className="table-head min-w-100">
                      Present Condition
                    </TableCell>
                    <TableCell className="table-head min-w-150">
                      Disclaimer Box
                    </TableCell>
                    {isEdit && (
                      <TableCell className="table-head min-w-25"></TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formContent?.dataRows?.length > 0 ? (
                    <>
                      {formContent?.dataRows.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell> {index + 1}</TableCell>
                          <TableCell>
                            {isEdit ? (
                              <>
                                <Input
                                  id="condition"
                                  label="Present Condition..."
                                  type="text"
                                  style="full-border-field"
                                  value={row?.description}
                                  onChange={(e) => {
                                    const values = { ...formContent };
                                    const valuesRows = [
                                      ...formContent?.dataRows,
                                    ];
                                    valuesRows[index].description =
                                      e.target.value;
                                    values.dataRows = valuesRows;
                                    setFormContent(values);
                                  }}
                                  inline={{ width: "-webkit-fill-available" }}
                                />
                              </>
                            ) : row?.description ? (
                              row?.description
                            ) : (
                              "-"
                            )}
                          </TableCell>
                          <TableCell>
                            {isEdit ? (
                              <Input
                                id="condition"
                                label="Present Condition..."
                                type="text"
                                style="full-border-field"
                                value={row?.condition}
                                onChange={(e) => {
                                  const values = { ...formContent };
                                  const valuesRows = [...formContent?.dataRows];
                                  valuesRows[index].condition = e.target.value;
                                  values.dataRows = valuesRows;
                                  setFormContent(values);
                                }}
                                inline={{ width: "-webkit-fill-available" }}
                              />
                            ) : row?.condition ? (
                              row?.condition
                            ) : (
                              "-"
                            )}
                          </TableCell>
                          <TableCell>
                            {isEdit ? (
                              <Input
                                id="disclamer"
                                label="Disclamer..."
                                type="text"
                                style="full-border-field"
                                value={row?.disclamer}
                                onChange={(e) => {
                                  const values = { ...formContent };
                                  const valuesRows = [...formContent?.dataRows];
                                  valuesRows[index].disclamer = e.target.value;
                                  values.dataRows = valuesRows;
                                  setFormContent(values);
                                }}
                                inline={{ width: "-webkit-fill-available" }}
                              />
                            ) : row?.disclamer ? (
                              row?.disclamer
                            ) : (
                              "-"
                            )}
                          </TableCell>
                          {isEdit && (
                            <TableCell>
                              <img
                                src={removeImg}
                                height={18}
                                width={18}
                                className="cursor-pointer"
                                onClick={() => handleRemoveDataRows(index)}
                              />
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </>
                  ) : (
                    <TableRow className="text-center">
                      <TableCell colSpan={6}>
                        {isLoading ? (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              margin: "10px 0px",
                            }}
                          >
                            <CircularProgress
                              style={{
                                color: "#00aff0",
                                height: "25px",
                                width: "25px",
                              }}
                            />
                          </Box>
                        ) : (
                          <div className="d-flex justify-content-center">
                            <p className="my-3">No Records to Show</p>
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Row className="mb-5">
            <Col className="col-12 text-end">
              {isEdit && (
                <Button
                  className="add-new-btn ms-auto"
                  onClick={() => {
                    handleAddNewDataRows();
                  }}
                  disabled={isLoading}
                >
                  Add New
                </Button>
              )}
            </Col>
          </Row>

          {/* Signatures */}
          <Row className="mt-5 pt-5">
            <Col sm={6} className="mt-5 pt-5">
              <div
                style={{ height: "90px" }}
                className="d-flex justify-content-center align-items-end"
              >
                {formContent.clientSign != "" &&
                  formContent.clientSign != undefined && (
                    <img
                      src={`${Bucket}${formContent.clientSign}`}
                      height={80}
                      width={200}
                    />
                  )}
              </div>
              <p>{"_______________________________"}</p>
              <p className="mt-1 mb-1">
                <b>{"Customer/Authorized"}</b>
              </p>

              {""}
            </Col>
            <Col sm={6} className="mt-5 pt-5">
              <div
                style={{ height: "90px" }}
                className="d-flex justify-content-center align-items-end"
              >
                <img
                  src={`${Bucket}${formContent?.supervisorSign}`}
                  height={80}
                  width={200}
                />
              </div>
              <p>{"_______________________________"}</p>
              <p className="my-1">
                <b>{"Supervisor"}</b>
              </p>

              {""}
            </Col>
          </Row>

          {/* </MainTemplate> */}
        </div>
      ) : (
        <>
          {isLoading ? (
            <div> Loading...</div>
          ) : (
            <div> Ooops... Something went wrong...</div>
          )}
        </>
      )}

      <PrintComponent>
        <ItemConditionDownload
          surveyData={surveyData}
          formContent={formContent}
        />
      </PrintComponent>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={
            snackbarMessage?.includes("successful") ? "success" : "error"
          }
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

       {/* Add signature Modal */}
       {isSignCanvas && (
          <MySignatureCanvas
            open={isSignCanvas}
            onClose={() => {
              setSignCanvas(false);
            }}
            onSuccess={(file) => {
              if (!isLoading) uploadFile(file);
            }}
          />
        )}
    </>
  );
};
export default ItemConditionReportClientSide;
