import React, { useEffect, useState } from "react";
import MainTemplate from "../../templates/MainTemplate";
import { Button } from "react-bootstrap";
import { Tabs, Tab, Box, Grid, Snackbar } from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { Stack, Pagination, PaginationItem } from "@mui/material";
import { getAdmins, getSurveyors } from "../../../api/services/services";
import AddRoleForm from "../../molecules/AddRoleForm";
import SuccessRole from "../../molecules/SuccessRole";
import MuiAlert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
// import Paper from '@mui/material/Paper';
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import "./style.css";

const Roles = () => {
  const [addRoleModal, setAddRoleModal] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [value, setValue] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [success, setSuccess] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const searchContainerStyle = {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: "10px", // Adjust as needed
    margin: "25px 5px 0px 5px",
    boxShadow: "none",
    // width: '25%'
  };

  const searchIconStyle = {
    padding: "10px",
    color: "gray",
  };

  const inputBaseStyle = {
    flex: 1,
    padding: "5px 10px",
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  //
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  //
  const handleRowsPerPageChange = (event) => {
    const newSize = parseInt(event.target.value);
    setItemsPerPage(newSize);
    setCurrentPage(1); // Reset to the first page when the page size changes
  };

  //
  const handleChange = (event, newValue) => {
    setCurrentPage(1);
    setValue(newValue);
  };

  // Admin List
  const getList = () => {
    setData([]);
    setIsLoading(true);
    getAdmins(currentPage, itemsPerPage, search)
      .then((response) => {
        setIsLoading(false);
        setData(response?.data?.data?.admins);
        let x = Math.ceil(response?.data?.data?.totalRecords / itemsPerPage);
        setTotalPages(x);
      })
      .catch((error) => {
        setIsLoading(false);
        setData([]);
        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = '/login'
      }
        else if (error?.response?.data?.statusCode == 1002) {
          //   setSnackbarMessage("Token has been expired");
          //   setOpenSnackbar(true);
        }  else {
          if (error?.response?.data?.message) {
            setSnackbarMessage(error?.response?.data?.message);
            setOpenSnackbar(true);
          }
        }
      });
  };

  const [search, setSearch] = useState("");

  //   Surveyors List
  const getAllSurveyors = () => {
    setData([]);
    setIsLoading(true);
    getSurveyors(currentPage, itemsPerPage, false, search)
      .then((response) => {
        setIsLoading(false);
        setData(response?.data?.data?.surveyers);
        let x = Math.ceil(response?.data?.data?.totalRecords / itemsPerPage);
        setTotalPages(x);
      })
      .catch((error) => {
        setIsLoading(false);
        setData([]);
        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = '/login'
      }
        else if (error?.response?.data?.message) {
          setSnackbarMessage(error?.response?.data?.message);
          setOpenSnackbar(true);
        }
      });
  };

  //
  const convertDate = (unixTimestamp) => {
    const date = new Date(unixTimestamp * 1000); // Multiply by 1000 to convert from seconds to milliseconds
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1 and format with leading zero
    const dd = String(date.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  useEffect(() => {
    if (value == 0) getList();
    else getAllSurveyors();
  }, [currentPage, itemsPerPage, value, search]);

  return (
    <>
      <MainTemplate>
        <div className="d-flex align-items-center py-4">
          <p className="main-head ps-1 mb-0">Role Management</p>

          <div className="ms-auto">
            <Button
              className="role-btn me-1 d-none d-sm-block"
              onClick={() => {
                console.log(true);
                setAddRoleModal(true);
              }}
            >
              Add New Role
            </Button>
            <Button
              className="role-btn me-1 d-block d-sm-none"
              onClick={() => {
                console.log(true);
                setAddRoleModal(true);
              }}
            >
              Add Role
            </Button>
          </div>
        </div>
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            centered
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "#00aff0", // Replace 'red' with your desired color
              },
            }}
          >
            <Tab label="ADMINS" className="tab-width" />
            <Tab label="SURVEYORS" className="tab-width" />
          </Tabs>
          {/* Content for ADMINS when it's selected */}
          {(value === 0 || value === 1) && (
            <div>
              <Paper component="form" style={searchContainerStyle}>
                <IconButton style={searchIconStyle} aria-label="search">
                  <SearchIcon />
                </IconButton>
                <InputBase
                  placeholder="Search..."
                  style={inputBaseStyle}
                  inputProps={{ "aria-label": "search" }}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Paper>
              {/* Table Data */}
              <Grid container className="px-1 my-4">
                <TableContainer component={Paper} className="">
                  <Table>
                    {/* Table Header */}
                    <TableHead>
                      <TableRow>
                        <TableCell className="table-head min-w-100">
                          Serial No.
                        </TableCell>
                        <TableCell className="table-head min-w-150">
                          Name
                        </TableCell>
                        <TableCell className="table-head">Email</TableCell>
                        <TableCell className="table-head min-w-150">
                          Created Date
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* Slice the data array to display the current page */}
                      {/* data
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        ) */}
                      {data?.length > 0 ? (
                        <>
                          {data.map((row, index) => (
                            <TableRow key={index} style={{ height: "55px" }}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>{row.name}</TableCell>
                              <TableCell>{row.email}</TableCell>
                              <TableCell>
                                {convertDate(row.createdAt)}
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      ) : (
                        <TableRow className="text-center">
                          <TableCell colSpan={4}>
                            {isLoading ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  margin: "10px 0px",
                                }}
                              >
                                <CircularProgress
                                  style={{
                                    color: "#00aff0",
                                    height: "25px",
                                    width: "25px",
                                  }}
                                />
                              </Box>
                            ) : (
                              <div className="d-flex justify-content-center">
                                <p className="my-3">No Records to Show</p>
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* Pagination controls */}
                {/* Render the pagination component with custom icons */}
                <Grid container className="px-1 my-4">
                  <Grid item xs={12} sm={6} className="">
                    <div className="d-flex justify-content-center justify-content-sm-start">
                      <FormControl
                        sx={{
                          minWidth: 120,
                          margin: "8px 0px",
                          backgroundColor: "#fff",
                          textAlign: "center",
                        }}
                      >
                        <Select
                          value={itemsPerPage}
                          onChange={handleRowsPerPageChange}
                          displayEmpty
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                        >
                          <MenuItem value="10">10</MenuItem>
                          <MenuItem value="25">25</MenuItem>
                          <MenuItem value="50">50</MenuItem>
                        </Select>
                      </FormControl>
                      <p className="pt-3 px-2  d-md-block">Entries per page</p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} className="">
                    <div className="d-flex justify-content-center justify-content-sm-end">
                      <Stack spacing={2} className="mt-3">
                        <Pagination
                          count={totalPages}
                          page={currentPage}
                          onChange={handlePageChange}
                          color="primary"
                          renderItem={(item) => (
                            <PaginationItem
                              component="button"
                              onClick={() => handlePageChange(null, item.page)}
                              {...item}
                              className="mb-1"
                            />
                          )}
                          prevIcon={<ArrowCircleLeftIcon />}
                          nextIcon={<ArrowCircleRightIcon />}
                        />
                      </Stack>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          )}
        </Box>

        {/* Add Role Modal */}
        {addRoleModal && (
          <AddRoleForm
            open={addRoleModal}
            onClose={() => {
              setAddRoleModal(false);
            }}
            onSuccess={() => {
              setAddRoleModal(false);
              setSuccess(true);
              if (value === 1) getAllSurveyors();
              else getList();
            }}
          />
        )}

        {/* Success Modal */}
        <SuccessRole open={success} onClose={() => setSuccess(false)} />

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000} // Adjust the duration as needed
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={
              snackbarMessage?.includes("successful") ? "success" : "error"
            }
            onClose={handleCloseSnackbar}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </MainTemplate>
    </>
  );
};

export default Roles;
