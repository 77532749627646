// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.down-head {
    font-family: Aspira !important;
    font-size: 28px !important;
    font-weight: 700 !important;
    line-height: 43px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
  }`, "",{"version":3,"sources":["webpack://./src/components/pages/DownloadPdfPage/style.css"],"names":[],"mappings":";AACA;IACI,8BAA8B;IAC9B,0BAA0B;IAC1B,2BAA2B;IAC3B,4BAA4B;IAC5B,8BAA8B;IAC9B,2BAA2B;EAC7B","sourcesContent":["\n.down-head {\n    font-family: Aspira !important;\n    font-size: 28px !important;\n    font-weight: 700 !important;\n    line-height: 43px !important;\n    letter-spacing: 0em !important;\n    text-align: left !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
