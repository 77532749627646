import React, { useEffect, useState } from "react";
import { Button, Modal, Backdrop, Fade, Snackbar } from "@mui/material";
import crossImg from "../../imgs/cross.png";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import userIcon from "../../imgs/userIcon.svg";
import {
  getSurveyors,
  assignSurveyor
} from "../../api/services/services";
import Grid from "@mui/material/Grid";
import MuiAlert from "@mui/material/Alert";

const AssignSurveyorModal = ({ open, onClose, id, onSucces }) => {
  const [surveyorsData, setSurveyorsData] = useState(null);
  const [selectedSurveyor, setSelectedSurveyor] = useState(null);
  const [disableAssign, setDisableAssign] = useState(true);
  const [email, setEmail] = useState(null);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  //
  const getAllSurveyors = () => {
    getSurveyors(1, 20, true, '')
      .then((response) => {
        setSurveyorsData(response?.data?.data?.surveyers);
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = '/login'
      }
        console.error("Error :", error?.response?.data);
      });
  };

  //
  const assign = () => {
    const body = {
      surveyerId: selectedSurveyor,
      surveyId: id,
    };

    assignSurveyor(body)
      .then(() => {
        onSucces(email);
      })
      .catch((error) => {
        console.error("Error assigning:", error?.response?.data);
        if(error?.message?.data == undefined){
        onSucces(email);
        }
        else{
          if (error?.response?.data?.statusCode == 401) {
            localStorage.clear();
            window.location.pathname = '/login'
          }
          else if(error?.response?.data?.message){
          setSnackbarMessage(error?.response?.data?.message);
          setOpenSnackbar(true);
          }
        }
      });
  };

  useEffect(() => {
    getAllSurveyors();
  }, []);

  return (
    <>
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="modal-container">
          {" "}
          {/* CSS class for centering */}
          <div className="modal-content assign-modal px-4">
            <div className="d-flex">
              <img
                src={crossImg}
                height="26"
                width="26"
                className="ms-auto cursor-pointer"
                onClick={() => onClose()}
              />{" "}
            </div>
            {/* Modal content goes here */}
            <p className="modal-head mb-0">Assign Surveyor</p>
            <p className="modal-subhead">Select surveyor to assign survey</p>

            {/*  */}
            <FormControl sx={{ m: 1, minWidth: 120, margin: "8px 0px" }}>
              <Select
                // value={'abc'}
                onChange={(e) => {
                  console.log()
                  setSelectedSurveyor(e.target.value?.uuid);
                  setEmail(e.target.value?.email);
                  setDisableAssign(false);
                }}
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                }}
                // placeholder="Select Surveyor.."
              >
                <MenuItem value={""} disabled>
                  Select Surveyor
                </MenuItem>
                {surveyorsData?.length == 0 ? (
                  <>
                    <MenuItem value={"abc"} disabled>
                      No data to select..
                    </MenuItem>
                  </>
                ) : (
                  surveyorsData?.map((sr, index) => {
                    return (
                      <MenuItem value={sr}>
                        <div className="d-flex align-items-center">
                          <img src={userIcon} height={42} width={42} />
                          <div className="ms-2">
                            <p className="surve-name mb-0">{sr?.name}</p>
                            <p className="surve-email mb-0">{sr?.email}</p>
                          </div>
                        </div>
                      </MenuItem>
                    );
                  })
                )}
              </Select>
            </FormControl>

            <Grid container spacing={2} className="mt-4">
              <Grid item xs={12}>
                <Button
                  type="button"
                  variant="contained"
                  className={
                    disableAssign
                      ? "disabled-btn mt-3 mb-3"
                      : "submit-btn mt-3 mb-3"
                  }
                  disabled={disableAssign}
                  onClick={() => {
                    assign();
                  }}
                >
                  Assign
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Fade>
    </Modal>

{/*  */}
    <Snackbar
          open={openSnackbar}
          autoHideDuration={6000} // Adjust the duration as needed
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={
              snackbarMessage?.includes("successful") ? "success" : "error"
            }
            onClose={handleCloseSnackbar}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
    </>
  );
};

export default AssignSurveyorModal;
