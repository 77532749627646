import React, { useState } from "react";
import { Container, Typography, Snackbar } from "@mui/material";
import ForgetPasswordForm from "../../molecules/ForgetPasswordForm";
// import './style.css'
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import logo from "../../../imgs/logo-final.png";
import success from "../../../imgs/succes.svg";
import Button from "@mui/material/Button";
import { forgotPassword } from "../../../api/services/services";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";

const ForgetPasswordPage = () => {
  const [isForget, setIsForget] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const navigate = useNavigate();

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleForgetPassword = (formData) => {
    const body = {
      email: formData?.email,
    };

    forgotPassword(body)
      .then((response) => {
        setIsForget(false);
        setIsSuccess(true);
      })
      .catch((error) => {
        console.error("Error creating password:", error);
        setSnackbarMessage(error.response.data.message);
        setOpenSnackbar(true);
      });

    // Handle forget password here, formData will contain email and password
    console.log("Login data:", formData);
  };

  return (
    <div className="login-bg">
      <Container
        maxWidth="xs"
        sx={{
          display: "flex",
          alignItems: "center",
          minHeight: "100vh",
          justifyContent: "center",
        }}
      >
        {isForget && (
          <Card
            sx={{pb: 1 }}
            style={{ borderRadius: "15px" }}
            className="auth-card mx-2"
          >
            <CardContent>
              <img
                src={logo}
                alt="Shezlon Logo"
                width="260"
                height="70"
                className="mt-2"
              />
              <Typography variant="p" align="center" gutterBottom>
              <p className="title p-0 mb-1 mt-3"  style={{position: 'relative',
                zIndex: '999'}}>Forget Password</p>
              </Typography>
              <Typography variant="p" align="center" gutterBottom>
                <p className="desc mb-5"> Enter your email to continue</p>
              </Typography>
              <ForgetPasswordForm onSubmit={handleForgetPassword} />
            </CardContent>
          </Card>
        )}

        {isSuccess && (
          <Card
            sx={{ py: 3, pb: 1 }}
            style={{ borderRadius: "15px" }}
            className="success-card mx-2"
          >
            <CardContent>
              <img
                src={success}
                alt="Shezlon Logo"
                width="84"
                height="84"
                className="mb-4"
              />
              <Typography variant="p" align="center" gutterBottom>
                <h4 className="success-title p-0 mb-1 text-capitalize">
                  Email sent successfully
                </h4>
              </Typography>
              <Typography variant="p" align="center" gutterBottom>
                <p className="desc mb-5">
                  {" "}
                  You will get email if an account with this email exists
                </p>
              </Typography>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  className="submit-btn mt-1 mb-3 text-capitalize"
                  onClick={() => navigate("/login")}
                >
                  Go to Email and Continue
                </Button>
              </Grid>
            </CardContent>
          </Card>
        )}
      </Container>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={
            snackbarMessage?.includes("successful") ? "success" : "error"
          }
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default ForgetPasswordPage;
