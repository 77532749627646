import React from "react";
import TextField from "@mui/material/TextField";
import "./style.css";

const NameInput = ({ value, onChange, error }) => {
  const inputLabelProps = {
    shrink: true,
    style: {
      color: "black", // Set the color to red
      fontWeight: "600", // Set the font weight to bold
      fontSize: '15px'
    },
  };

  const inputProps = {
    style: {
      height: "45px", // Set the height to 40 pixels
    },
  };
  return (
    <TextField
      type="text"
      label="Name"
      variant="outlined"
      placeholder="Enter name"
      fullWidth
      value={value}
      onChange={onChange}
      error={error}
      helperText={error && "Please enter a valid email address"}
      InputLabelProps={inputLabelProps}
      InputProps={inputProps}
      required
    />
  );
};

export default NameInput;
