import axiosInstance from "../../../../api/config/axiosConfig";

let token = localStorage.getItem("shelLoginToken");

export const getList = (page, perPage, search) => {
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return axiosInstance.get(
    search.length == 0
      ? `/admin/prebid/list?page=${page}&pageSize=${perPage}`
      : `/admin/prebid/list?page=${page}&pageSize=${perPage}&search=${search}`,
    {
      headers: headers,
    }
  );
};

export const deletePrebid = (uuid) => {
  let headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  return axiosInstance.delete(`/admin/prebid/${uuid}`, {
    headers: headers,
  });
};
