// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.title {
  font-weight: 700;
  font-size: 28px;
}

.desc {
  font-weight: 400;
  font-size: 14px;
  color: #999999;
}

.download {
  font-family: Proxima Nova;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/CreateAccountPage/style.css"],"names":[],"mappings":";AACA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;AACrB","sourcesContent":["\n.title {\n  font-weight: 700;\n  font-size: 28px;\n}\n\n.desc {\n  font-weight: 400;\n  font-size: 14px;\n  color: #999999;\n}\n\n.download {\n  font-family: Proxima Nova;\n  font-size: 20px;\n  font-weight: 600;\n  line-height: 24px;\n  letter-spacing: 0em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
