import React, { useEffect, useState } from "react";
import {
  updateFormContentPreview,
  getFormContentPreview,
  imageUploadClientPreview,
} from "../../../../api/services/services";

import MainTemplate from "../../../templates/MainTemplate";
import { useNavigate } from "react-router-dom";
import { Button, Snackbar, Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import { Bucket } from "../../../../api/config/apiConfig";
import ContactDetailField from "../../../molecules/ContactDetailField";
import TextArea from "../../../atoms/Textarea";
import DynamicCheckbox from "../../../molecules/DynamicCheckbox";
import Table from "@mui/material/Table";
import CircularProgress from "@mui/material/CircularProgress";
import Input from "../../../atoms/Input";
import removeImg from "../../../../imgs/remove.png";
import { useParams } from "react-router-dom";
import { ToWords } from "to-words";
import { useLocation } from "react-router-dom";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import html2pdf from "html2pdf.js";
import WoodenLiftPageDownload from "./download";
import PrintComponent from "../../PrintComponent";
import moment from "moment";
import MySignatureCanvas from "../../../molecules/signature_canvas";

const WoodenLiftPageClientSide = () => {
  const [formContent, setFormContent] = useState({});
  const [formContentId, setFormContentId] = useState("");

  const [surveyData, setSurveyData] = useState([]);

  const [isEdit, setIsEdit] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const urlData = new URLSearchParams(location.search);
  const email = urlData.get("email");
  const surveyId = urlData.get("surveyId");
  const formId = urlData.get("formId");
  const token = urlData.get("token");

  var totalAmount = 0;

  const [mediaKeysToSend, setMediaKeysToSend] = useState([]);

  const words = new ToWords();

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  //
  const updateContent = (data) => {
    setIsLoading(true);

    let media = [];
    if (formContent?.clientSign != "") media[0] = formContent?.clientSign;

    const body = {
      formContentId: formContentId,
      content: data ? data : formContent,
      mediaKeys: mediaKeysToSend,
      removedMediaKeys: [],
    };
    updateFormContentPreview(
      surveyId,
      formId,
      email,
      token,
      body,
      formContentId
    )
      .then((res) => {
        if (data != undefined)
          setSnackbarMessage("Signature has been added successfully!");
        else
          setSnackbarMessage(
            "Wooden Lift Van Quality Check Report has been updated successfully!"
          );

        setOpenSnackbar(true);
        getContent();
        setIsEdit(false);

        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        setIsLoading(false);
      });
  };

  const [isValid, setIsValid] = useState(false);

  //
  const getContent = () => {
    setIsLoading(true);
    getFormContentPreview(surveyId, formId, email, token)
      .then((res) => {
        setIsValid(true);
        setFormContentId(res.data.data.uuid);
        setFormContent(res.data.data.content);

        setIsLoading(false);
        setMediaKeysToSend([]);
      })
      .catch((error) => {
        setIsValid(false);
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        setIsLoading(false);
        setMediaKeysToSend([]);
      });
  };

  useEffect(() => {
    getContent();
  }, []);

  const [show, setShow] = useState(false);
  const options = {
    // resolution: Resolution.HIGH,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      format: "letter",
      // default is 'portrait'
      orientation: "portrait",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/png",
      qualityRatio: 1,
    },
    overrides: {
      pdf: {
        compress: true,
      },
    },
  };

  //
  const generatePdf = () => {
    setShow(true);

    setTimeout(() => {
      const content = document.getElementById("content-to-convert"); // Replace with the ID of the element you want to convert to PDF

      const currentDateTime = new Date();
      const filename = `survey_${currentDateTime.toISOString()}.pdf`;

      const pdfOptions = {
        margin: 5,
        filename: filename,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
        }, // Set the page size here
      };

      let temp = html2pdf().from(content).set(pdfOptions).outputPdf();
      temp.save(filename);

      setShow(false);
    }, 3500);
  };

  const [downloadText, setDownloadText] = useState("download");

  const getTargetElement = () => document.getElementById("content-id");

  const printAction = () => {
    const PAGE_HEIGHT = 1045;
    const printElement = document.getElementById("print-component");
    if (printElement) {
      printElement.classList.add("temp-class-for-height");
      const height = printElement.clientHeight;
      const numberOfPage = Math.ceil(height / PAGE_HEIGHT);
      const heightWithSingleHeader = numberOfPage * PAGE_HEIGHT;
      let requiredHeight = heightWithSingleHeader;
      if (numberOfPage > 1) {
        const headerHeight =
          printElement.getElementsByTagName("thead")?.[0]?.clientHeight;
        const footerHeight =
          printElement.getElementsByTagName("tfoot")?.[0]?.clientHeight;
        requiredHeight -= (numberOfPage - 1) * (headerHeight + footerHeight);
      }
      printElement.style.height = `${requiredHeight}px`;
      printElement.classList.remove("temp-class-for-height");
    }
    window.print();
    if (printElement) {
      printElement.style.height = `auto`;
    }
  };

  const [uploadText1, setUploadText1] = useState("Upload Sign");
  const [uploadText2, setUploadText2] = useState("Upload Sign");

  const [isSignCanvas, setSignCanvas] = useState(false);
  const [whichSign, setWhichSign] = useState("");


  //
  const uploadFile = async (file) => {

    setIsLoading(true);


    if (whichSign == "manager") setUploadText1("Uploading...");
    if (whichSign == "carpenter") setUploadText2("Uploading...");

    const fd = new FormData();
    fd.append("image", file);

    imageUploadClientPreview(fd, surveyId, formId, email, token)
      .then((res) => {
        let values = { ...formContent };
        if (whichSign == "manager")
          values.managerWarehouseSign = res?.data?.data?.key;
        if (whichSign == "carpenter") values.carpenterSign = res?.data?.data?.key;
        setFormContent(values);

        let md = [...mediaKeysToSend];
        md.push(res?.data?.data?.key);
        setMediaKeysToSend(md);

        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setUploadText1("Upload Sign");
        setUploadText2("Upload Sign");

        setIsLoading(false);
      });

  };

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // 11th, 12th, 13th, etc.
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  // Helper function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const ordinalSuffix = getOrdinalSuffix(day);

    return `${day}${ordinalSuffix} ${month}, ${year}`;
  };

  return (
    <>
      {isValid ? (
        <>
          <div className="mx-3 mx-md-5 no-print" style={{ minHeight: "100vh" }}>
            {/* <MainTemplate> */}

            <div className="d-flex align-items-center py-4">
              <p
                className="main-head ps-0 mb-0 text-end"
                style={{ width: "63%" }}
              >
                Wooden Lift Van Quality Check Report
              </p>
              <div className="d-flex ms-auto justify-content-center mt-2 mt-sm-0">
                {/*Edit Button */}
                {!isEdit && (
                  <Button
                    className="active-btn"
                    onClick={() => {
                      setIsEdit(true);
                    }}
                    disabled={isLoading}
                  >
                    Edit
                  </Button>
                )}

                {/* Cancel button */}
                {isEdit && (
                  <Button
                    className="active-btn ms-3"
                    onClick={() => {
                      getContent();
                      setIsEdit(false);
                    }}
                    disabled={isLoading}
                  >
                    Cancel
                  </Button>
                )}

                {/* Save Button */}
                {isEdit && (
                  <Button
                    className="active-btn ms-3"
                    onClick={() => {
                      updateContent();
                    }}
                    disabled={isLoading}
                  >
                    Save
                  </Button>
                )}

                {/* Download Button */}
                {!isEdit && (
                  <Button
                    className="active-btn ms-3 px-0"
                    onClick={() => {
                      setIsLoading(true);
                      // printAction();
                      var tempTitle = document.title;
                      document.title = `Wooden Lift Van Quality Check Report-${formContent?.customerName
                        }-${moment(new Date()).format("DD.MM.YYYY")}`;

                      window.print();
                      document.title = tempTitle;
                      setIsLoading(false);
                    }}
                    disabled={isLoading}
                  >
                    Save as PDF
                  </Button>
                )}
              </div>
            </div>

            {/* <div className="d-block d-sm-flex align-items-center py-4">
              <div className="d-flex align-items-center ">
                <p className="main-head mb-0">
                  Wooden Lift Van Quality Check Report
                </p>
              </div>
              <div className="d-flex ms-auto justify-content-center mt-2 mt-sm-0">
                {/*Edit Button */}
            {/* {!isEdit && (
                  <Button
                    className="active-btn"
                    onClick={() => {
                      setIsEdit(true);
                    }}
                    disabled={isLoading}
                  >
                    Edit
                  </Button>
                )} */}

            {/* Cancel button */}
            {/* {isEdit && (
                  <Button
                    className="active-btn ms-3"
                    onClick={() => {
                      getContent();
                      setIsEdit(false);
                    }}
                    disabled={isLoading}
                  >
                    Cancel
                  </Button>
                )} */}

            {/* Save Button */}
            {/* {isEdit && (
                  <Button
                    className="active-btn ms-3"
                    onClick={() => {
                      updateContent();
                    }}
                    disabled={isLoading}
                  >
                    Save
                  </Button>
                )} */}

            {/* Download Button */}
            {/* {!isEdit && (
                  <Button
                    className="active-btn ms-3 px-0"
                    onClick={() => {
                      setIsLoading(true);
                      // printAction();
                      var tempTitle = document.title;
                      document.title = `Wooden Lift Van Quality Check Report-${
                        formContent?.customerName
                      }-${moment(new Date()).format("DD.MM.YYYY")}`;

                      window.print();
                      document.title = tempTitle;
                      setIsLoading(false);
                    }}
                    disabled={isLoading}
                  >
                    Save as PDF
                  </Button>
                )} */}
            {/* </div>
            </div>  */}

            {/* customer detail*/}
            <div className={`${isEdit ? "p-3 px-0 rounded mb-5" : ""}`}>
              <div className="contact-detail d-flex mt-2 row">
                {/* left col */}
                <div className={`left-col col-12 col-md-6 text-start`}>
                  <Typography variant="body1" gutterBottom>
                    <ContactDetailField
                      label="Customer Name"
                      isEdit={isEdit}
                      type="text"
                      minWidth={"120px"}
                      value={formContent?.customerName}
                      onChange={(e) => {
                        let values = { ...formContent };
                        values.customerName = e.target.value;
                        setFormContent(values);
                      }}
                      disabled
                    />
                    <ContactDetailField
                      label="File Ref"
                      isEdit={isEdit}
                      type="text"
                      minWidth={"120px"}
                      value={formContent?.fileRef}
                      onChange={(e) => {
                        let values = { ...formContent };
                        values.fileRef = e.target.value;
                        setFormContent(values);
                      }}
                      disabled
                    />
                  </Typography>
                </div>
              </div>
            </div>
            {/* /customer detail */}

            <div className="overflow-x-auto mb-5 mt-5">
              <Table
                responsive
                // striped
                // bordered
                // hover
                style={{ overflowX: "auto" }}
              >
                {/* Table Header */}
                <thead
                  className="bg-white"
                // className="bg-body-tertiary"
                >
                  <tr>
                    <th className="border p-3 text-start min-w-150">
                      {" "}
                      No. of Wooden lift vans
                    </th>
                    <th className="border p-3 text-start min-w-400">
                      Lift van or Box Sizes IN/OUT
                    </th>
                    <th className="border p-3 text-start">Tare weight</th>
                    <th className="border p-3 text-start min-w-100">
                      Manufacturer Date
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {formContent?.dataRows?.length > 0 ? (
                    <>
                      {formContent?.dataRows?.map((row, index) => {
                        return (
                          <tr>
                            <td className="border p-3 text-start">
                              {index + 1}
                            </td>
                            <td className="border p-3 text-start">
                              {isEdit ? (
                                <Input
                                  id="condition"
                                  label="Lift van or Box Sizes.."
                                  type="text"
                                  style="full-border-field"
                                  value={row?.liftVanBoxSizes}
                                  onChange={(e) => {
                                    const values = { ...formContent };
                                    const valuesRows = [
                                      ...formContent?.dataRows,
                                    ];
                                    valuesRows[index].liftVanBoxSizes =
                                      e.target.value;
                                    values.dataRows = valuesRows;
                                    setFormContent(values);
                                  }}
                                  inline={{ width: "-webkit-fill-available" }}
                                  disabled={true}
                                />
                              ) : row.liftVanBoxSizes ? (
                                row.liftVanBoxSizes
                              ) : (
                                "-"
                              )}
                            </td>
                            <td className="border p-3 text-start min-w-100">
                              {isEdit ? (
                                <>
                                  <Input
                                    id="condition"
                                    label="Tare weight..."
                                    type="text"
                                    style="full-border-field"
                                    value={row?.tareWt}
                                    onChange={(e) => {
                                      const values = { ...formContent };
                                      const valuesRows = [
                                        ...formContent?.dataRows,
                                      ];
                                      valuesRows[index].tareWt = e.target.value;
                                      values.dataRows = valuesRows;
                                      setFormContent(values);
                                    }}
                                    inline={{ width: "-webkit-fill-available" }}
                                    disabled={true}
                                  />
                                </>
                              ) : row.tareWt ? (
                                `${row.tareWt}`
                              ) : (
                                "-"
                              )}
                            </td>
                            <td className="border p-3 text-start min-w-100">
                              {isEdit ? (
                                <>
                                  <Input
                                    id="condition"
                                    label="Date..."
                                    type="date"
                                    style="full-border-field"
                                    value={row?.date}
                                    onChange={(e) => {
                                      const values = { ...formContent };
                                      const valuesRows = [
                                        ...formContent?.dataRows,
                                      ];
                                      valuesRows[index].date = e.target.value;
                                      values.dataRows = valuesRows;
                                      setFormContent(values);
                                    }}
                                    inline={{ width: "-webkit-fill-available" }}
                                    disabled={true}
                                  />
                                </>
                              ) : row.date ? (
                                formatDate(row.date)
                              ) : (
                                "-"
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <tr>
                      <td className="text-center" colSpan={isEdit ? 5 : 4}>
                        {isLoading ? (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              margin: "10px 0px",
                            }}
                          >
                            <CircularProgress
                              style={{
                                color: "#00aff0",
                                height: "25px",
                                width: "25px",
                              }}
                            />
                          </Box>
                        ) : (
                          <div className="d-flex justify-content-center">
                            <p className="my-3">No Records to Show</p>
                          </div>
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>

            <Row>
              <Col sm={12} md={8} className="mb-4">
                <Typography variant="body1" className="text-start" gutterBottom>
                  <DynamicCheckbox
                    label="1. The correct size of lift van was measured."
                    checkId1="check1"
                    checkLabel1="Yes"
                    checkId2="check2"
                    checkLabel2="No"
                    value1={formContent?.correctSize}
                    value2={formContent?.correctSize}
                    onChange1={(e) => {
                      let values = { ...formContent };
                      values.correctSize = true;
                      setFormContent(values);
                    }}
                    onChange2={(e) => {
                      let values = { ...formContent };
                      values.correctSize = false;
                      setFormContent(values);
                    }}
                    disabled={true}
                  />
                </Typography>
              </Col>
              <Col sm={12} md={4} className="mb-4">
                <div className="">
                  <Typography
                    variant="body1"
                    className="text-start"
                    gutterBottom
                  >
                    <DynamicCheckbox
                      label="2. The crates build with new ply wood sheets."
                      checkId1="check1"
                      checkLabel1="Yes"
                      checkId2="check2"
                      checkLabel2="No"
                      value1={formContent?.plyWood}
                      value2={formContent?.plyWood}
                      onChange1={(e) => {
                        let values = { ...formContent };
                        values.plyWood = true;
                        setFormContent(values);
                      }}
                      onChange2={(e) => {
                        let values = { ...formContent };
                        values.plyWood = false;
                        setFormContent(values);
                      }}
                      disabled={true}
                    />
                  </Typography>
                </div>
              </Col>
              <Col sm={12} md={8} className="mb-4">
                <Typography variant="body1" className="text-start" gutterBottom>
                  <DynamicCheckbox
                    label="3. The crates build with new wood bars."
                    checkId1="check1"
                    checkLabel1="Yes"
                    checkId2="check2"
                    checkLabel2="No"
                    value1={formContent?.woodBars}
                    value2={formContent?.woodBars}
                    onChange1={(e) => {
                      let values = { ...formContent };
                      values.woodBars = true;
                      setFormContent(values);
                    }}
                    onChange2={(e) => {
                      let values = { ...formContent };
                      values.woodBars = false;
                      setFormContent(values);
                    }}
                    disabled={true}
                  />
                </Typography>
              </Col>
              <Col sm={12} md={4} className="mb-4">
                <div className="">
                  <Typography
                    variant="body1"
                    className="text-start"
                    gutterBottom
                  >
                    <DynamicCheckbox
                      label="4. All the parts and joints are well together."
                      checkId1="check1"
                      checkLabel1="Yes"
                      checkId2="check2"
                      checkLabel2="No"
                      value1={formContent?.partsWellTogether}
                      value2={formContent?.partsWellTogether}
                      onChange1={(e) => {
                        let values = { ...formContent };
                        values.partsWellTogether = true;
                        setFormContent(values);
                      }}
                      onChange2={(e) => {
                        let values = { ...formContent };
                        values.partsWellTogether = false;
                        setFormContent(values);
                      }}
                      disabled={true}
                    />
                  </Typography>
                </div>
              </Col>
              <Col sm={12} md={8} className="mb-4">
                <Typography variant="body1" className="text-start" gutterBottom>
                  <DynamicCheckbox
                    label="5. Is there any holes/cracks found in the crate?"
                    checkId1="check1"
                    checkLabel1="Yes"
                    checkId2="check2"
                    checkLabel2="No"
                    value1={formContent?.holesCrakes}
                    value2={formContent?.holesCrakes}
                    onChange1={(e) => {
                      let values = { ...formContent };
                      values.holesCrakes = true;
                      setFormContent(values);
                    }}
                    onChange2={(e) => {
                      let values = { ...formContent };
                      values.holesCrakes = false;
                      setFormContent(values);
                    }}
                    disabled={true}
                  />
                </Typography>
              </Col>
              <Col sm={12} md={4} className="mb-4">
                <div className="">
                  <Typography
                    variant="body1"
                    className="text-start"
                    gutterBottom
                  >
                    <DynamicCheckbox
                      label="6. If yes, has the holes/cracks filled up with silicon?"
                      checkId1="check1"
                      checkLabel1="Yes"
                      checkId2="check2"
                      checkLabel2="No"
                      value1={formContent?.holesFilledWithSilicon}
                      value2={formContent?.holesFilledWithSilicon}
                      onChange1={(e) => {
                        let values = { ...formContent };
                        values.holesFilledWithSilicon = true;
                        setFormContent(values);
                      }}
                      onChange2={(e) => {
                        let values = { ...formContent };
                        values.holesFilledWithSilicon = false;
                        setFormContent(values);
                      }}
                      disabled={true}
                    />
                  </Typography>
                </div>
              </Col>
              <Col sm={12} md={8} className="mb-4">
                <Typography variant="body1" className="text-start" gutterBottom>
                  <DynamicCheckbox
                    label="7. The crates fully waterproofed from inside with plastic sheets."
                    checkId1="check1"
                    checkLabel1="Yes"
                    checkId2="check2"
                    checkLabel2="No"
                    value1={formContent?.cratesWaterproof}
                    value2={formContent?.cratesWaterproof}
                    onChange1={(e) => {
                      let values = { ...formContent };
                      values.cratesWaterproof = true;
                      setFormContent(values);
                    }}
                    onChange2={(e) => {
                      let values = { ...formContent };
                      values.cratesWaterproof = false;
                      setFormContent(values);
                    }}
                    disabled={true}
                  />
                </Typography>
              </Col>
              <Col sm={12} md={4} className="mb-4">
                <div className="">
                  <Typography
                    variant="body1"
                    className="text-start"
                    gutterBottom
                  >
                    <DynamicCheckbox
                      label="8. The crates roof top has intact with tin sheet."
                      checkId1="check1"
                      checkLabel1="Yes"
                      checkId2="check2"
                      checkLabel2="No"
                      value1={formContent?.cratesRoof}
                      value2={formContent?.cratesRoof}
                      onChange1={(e) => {
                        let values = { ...formContent };
                        values.cratesRoof = true;
                        setFormContent(values);
                      }}
                      onChange2={(e) => {
                        let values = { ...formContent };
                        values.cratesRoof = false;
                        setFormContent(values);
                      }}
                      disabled={true}
                    />
                  </Typography>
                </div>
              </Col>
              <Col sm={12} md={8} className="mb-4">
                <Typography variant="body1" className="text-start" gutterBottom>
                  <DynamicCheckbox
                    label="9 .The signs and shipper details are clearly mentioned on crates"
                    checkId1="check1"
                    checkLabel1="Yes"
                    checkId2="check2"
                    checkLabel2="No"
                    value1={formContent?.clearlyMentioned}
                    value2={formContent?.clearlyMentioned}
                    onChange1={(e) => {
                      let values = { ...formContent };
                      values.clearlyMentioned = true;
                      setFormContent(values);
                    }}
                    onChange2={(e) => {
                      let values = { ...formContent };
                      values.clearlyMentioned = false;
                      setFormContent(values);
                    }}
                    disabled={true}
                  />
                </Typography>
              </Col>
              <Col sm={12} md={4} className="mb-4">
                <div className="">
                  <Typography
                    variant="body1"
                    className="text-start"
                    gutterBottom
                  >
                    <DynamicCheckbox
                      label="10. The crates tie-up with steel bindings all around."
                      checkId1="check1"
                      checkLabel1="Yes"
                      checkId2="check2"
                      checkLabel2="No"
                      value1={formContent?.tiedUpCrates}
                      value2={formContent?.tiedUpCrates}
                      onChange1={(e) => {
                        let values = { ...formContent };
                        values.tiedUpCrates = true;
                        setFormContent(values);
                      }}
                      onChange2={(e) => {
                        let values = { ...formContent };
                        values.tiedUpCrates = false;
                        setFormContent(values);
                      }}
                      disabled={true}
                    />
                  </Typography>
                </div>
              </Col>
              <Col sm={12} md={8} className="mb-4">
                <Typography variant="body1" className="text-start" gutterBottom>
                  <DynamicCheckbox
                    label="11. The tare weight of the lift van noted."
                    checkId1="check1"
                    checkLabel1="Yes"
                    checkId2="check2"
                    checkLabel2="No"
                    value1={formContent?.tareWeightNoted}
                    value2={formContent?.tareWeightNoted}
                    onChange1={(e) => {
                      let values = { ...formContent };
                      values.tareWeightNoted = true;
                      setFormContent(values);
                    }}
                    onChange2={(e) => {
                      let values = { ...formContent };
                      values.tareWeightNoted = false;
                      setFormContent(values);
                    }}
                    disabled={true}
                  />
                </Typography>
              </Col>
            </Row>

            {/*  Carpenter Remarks*/}
            <div className="mt-5 pe-1">
              <Typography variant="body1" className="text-start" gutterBottom>
                <div className="mb-2">
                  <label
                    htmlFor="carpenterRemarks"
                    className={`fw-bold ${isEdit ? "mb-3" : "mb-1"}`}
                  >
                    Carpenter Remarks:
                  </label>
                  {!isEdit ? (
                    <p>
                      {formContent?.carpenterRemarks
                        ? formContent?.carpenterRemarks
                        : "-"}
                    </p>
                  ) : (
                    <TextArea
                      rows="3"
                      label="Carpenter remarks..."
                      id="carpenterRemarks"
                      value={formContent?.carpenterRemarks}
                      onChange={(e) => {
                        let values = { ...formContent };
                        values.carpenterRemarks = e.target.value;
                        setFormContent(values);
                      }}
                    />
                  )}
                </div>
              </Typography>
            </div>
            {/* / Carpenter Remarks */}

            {/*  Manager Warehouse remarks:*/}
            <div className="my-5 pe-1 ">
              <Typography variant="body1" className="text-start" gutterBottom>
                <div className="mb-2">
                  <label
                    htmlFor="managerWarehouse"
                    className={`fw-bold ${isEdit ? "mb-3" : "mb-1"}`}
                  >
                    Manager Warehouse Remarks:
                  </label>
                  {!isEdit ? (
                    <p>
                      {formContent?.managerRemarks
                        ? formContent?.managerRemarks
                        : "-"}
                    </p>
                  ) : (
                    <TextArea
                      rows="3"
                      label="Manager warehouse remarks:..."
                      id="managerWarehouse"
                      value={formContent?.managerRemarks}
                      onChange={(e) => {
                        let values = { ...formContent };
                        values.managerRemarks = e.target.value;
                        setFormContent(values);
                      }}
                    />
                  )}
                </div>
              </Typography>
            </div>
            {/* / Manager Warehouse remarks */}

            {/* Signatures */}
            <Row className="mt-5 pt-5 no-print">
              <Col sm={6} className="mt-5 pt-5">
                <div
                  style={{ height: "90px" }}
                  className="d-flex justify-content-center align-items-end"
                >
                  {formContent?.managerWarehouseSign?.trim()?.length == 0 ? (
                    <>
                      {isEdit && (
                        <div className="d-flex justify-content-center align-items-end">
                          <label
                            htmlFor="uploadSign"
                            className="active-btn ms-2 w-200 d-flex align-items-center justify-content-center cursor-pointer btn"
                          >
                            {uploadText1}
                          </label>
                          <input
                            type="button"
                            hidden
                            id="uploadSign"
                            name="uploadSign"
                            onClick={() => {
                              setWhichSign("manager");
                              setSignCanvas(true);
                            }}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <img
                      src={`${Bucket}${formContent?.managerWarehouseSign}`}
                      height={80}
                      width={200}
                    />
                  )}
                </div>

                <p className="mb-0">{"_______________________________"}</p>
                <p className="my-1">
                  <b>{"Manager Warehouse"}</b>
                </p>

                {""}
              </Col>
              <Col sm={6} className="mt-5 pt-5">
                <div
                  style={{ height: "90px" }}
                  className="d-flex justify-content-center align-items-end"
                >
                  {formContent?.carpenterSign?.trim()?.length == 0 ? (
                    <>
                      {isEdit && (
                        <div className="d-flex justify-content-center align-items-end">
                          <label
                            htmlFor="uploadSign2"
                            className="active-btn ms-2 w-200 d-flex align-items-center justify-content-center cursor-pointer btn"
                          >
                            {uploadText2}
                          </label>
                          <input
                            type="button"
                            hidden
                            id="uploadSign2"
                            name="uploadSign2"
                            onClick={() => {
                              setWhichSign("carpenter");
                              setSignCanvas(true);
                            }}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <img
                      src={`${Bucket}${formContent.carpenterSign}`}
                      height={80}
                      width={200}
                    />
                  )}
                </div>
                <p className="mb-0">{"_______________________________"}</p>
                <p className="my-1">
                  <b>{"Carpenter"}</b>
                </p>

                {""}
              </Col>
            </Row>

            <Snackbar
              open={openSnackbar}
              autoHideDuration={6000} // Adjust the duration as needed
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <MuiAlert
                elevation={6}
                variant="filled"
                severity={
                  snackbarMessage?.includes("successful") ? "success" : "error"
                }
                onClose={handleCloseSnackbar}
              >
                {snackbarMessage}
              </MuiAlert>
            </Snackbar>
            {/* </MainTemplate> */}
          </div>

          <PrintComponent>
            <WoodenLiftPageDownload
              surveyData={surveyData}
              formContent={formContent}
            />
          </PrintComponent>
        </>
      ) : (
        <>
          {isLoading ? (
            <div> Loading...</div>
          ) : (
            <div> Ooops... Something went wrong...</div>
          )}
        </>
      )}

      {/* Add signature Modal */}
      {isSignCanvas && (
        <MySignatureCanvas
          open={isSignCanvas}
          onClose={() => {
            setSignCanvas(false);
          }}
          onSuccess={(file) => {
            if (!isLoading) uploadFile(file);
          }}
        />
      )}

      {/* snackbar */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={
            snackbarMessage?.includes("successful") ? "success" : "error"
          }
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      {/* /snackbar */}
    </>
  );
};

export default WoodenLiftPageClientSide;
