// src/components/atoms/QRCode.js

import React from "react";
import QRCode from "react-qr-code";

function QRCodeAtom({ value, width, height }) {
  return <QRCode value={value} size={width || height} />;
}

export default QRCodeAtom;
