import React, { useState } from 'react';
import Grid from "@mui/material/Grid";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import surveyIcon from "../../imgs/survey-icon.png"

const CustomCard = ({ primColor, secondColor, figure, text }) => {
  return (
   
            <Grid item xs={2} >
              <Card className='card'>
                <CardContent className="text-center" gutterBottom >
                <div style={{height:'0px', textAlign: '-webkit-center'}}>
                  <div
                    className="content-ellipse p-1"
                    style={{ transform: "translate(0px, -40px)" }}
                  >
                    <div className={`outer-ellipse p-1 mx-auto ${primColor}`} >
                      <div className={`inner-ellipse ${secondColor}`}>
                        <img
                          src={surveyIcon}
                          height="26"
                          width="26"
                          className="m-1 text-center "
                        />
                      </div>
                    </div>
                  </div>
                  </div>
                  <Typography className="card-header mt-4 " gutterBottom>
                   {figure}
                  </Typography>
                  <Typography className="card-header-sec pt-0" gutterBottom>
                    {text}
                  </Typography>
                  <Typography variant="h5" component="div"></Typography>
                </CardContent>
              </Card>
            </Grid>
           
        
  );
};

export default CustomCard;
