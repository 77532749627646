import Input from "../atoms/Input";

const ContactDetailField = ({
  label,
  value,
  isEdit,
  style,
  type,
  wrapperStyle,
  onChange,
  disabled,
  minWidth
}) => {

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // 11th, 12th, 13th, etc.
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  // Helper function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const ordinalSuffix = getOrdinalSuffix(day);

    return `${day}${ordinalSuffix} ${month}, ${year}`;
  };

  return (
    <div
      className={`${
        isEdit ? "mb-4" : "mb-2"
      } d-flex align-items-baseline contactDetail-label ${wrapperStyle || ""} `}
    >
      <label
        htmlFor={label}
        className={` me-3 ${isEdit ? "w-25" : ""} ${style || "fw-bold"}`}
        style={{minWidth: minWidth ? minWidth : '100px'}}
      >
       <b> {label}:</b>
      </label>
      {!isEdit ? (
        <>
        {type == 'date' ? 
        <span>{value ? formatDate(value) : '-'}</span>
        :
        <span>{value ? value : '-'}</span>
        }
        </>
      ) : (
        <input
        type={type}
        // className={`${style || "input-field "}`}
        className={`input-field`}
        value={value}
        onChange={onChange}
        id={label}
        placeholder={label}
        disabled={disabled ? true : false}
        data-date-format="DD-MM-YYYY"
      />
      )}
    </div>
  );
};

export default ContactDetailField;
