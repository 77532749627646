import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getPrebidPreview,
  imageUploadClientPreview,
  UpdateClientSignaturePrebid,
} from "./service";
import Typography from "@mui/material/Typography";
import ContactDetailField from "../../../molecules/ContactDetailField";
import TextArea from "../../../atoms/Textarea";
import { Col, Row, Stack } from "react-bootstrap";
import LazyImage from "../../LazyLoadImage";
import { Bucket } from "../../../../api/config/apiConfig";
import { Button } from "@mui/material";
import gifLoading from "../../../../imgs/giphy.gif";
import moment from "moment";
import PrintComponent from "../../PrintComponent";
import PrebidFormDownload from "../creation/save_as_pdf";
import MySignatureCanvas from "../../../molecules/signature_canvas";

const ClientPreview = () => {
  const { token, email, uuid } = useParams();

  const [formContent, setFormContent] = useState({
    issueDate: "",
    notice: "",
    contractPrice: "",
    date: "",
    importantNote: "",
    carDescription: {
      make: "",
      model: "",
      year: "",
      grade: "",
      specification: "",
      mileage: "",
      images: [],
    },
    firstParty: {
      name: "",
      email: "",
      mofaIdNo: "",
      sign: "",
      organization: "",
    },
    secondParty: {
      name: "",
      email: "",
      mofaIdNo: "",
      sign: "",
      organization: "",
    },
  });

  const [getter, setGetter] = useState(false);

  const [isEdit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const getPrebid = async () => {
    if (uuid)
      await getPrebidPreview(token, uuid, email)
        .then((_) => {
          setFormContent(_.data.data);
        })
        .catch((e) => {
          console.log(e);
        });
  };

  /// this method update password for second party
  const updateSignature = (key) => {
    let values = { ...formContent };
    values.firstParty.sign = key;
    setFormContent(values);
    setUploadText("Upload Signature for prebid Acceptance");
    setIsLoading(false);
    updateSignatures();
  };

  const uploadFile = async (file) => {
    const fd = new FormData();
    fd.append("image", file);

    imageUploadClientPreview(token, uuid, email, fd)
      .then((res) => {
        updateSignature(res?.data?.data?.key);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);
        setIsLoading(false);
      });
  };

  const updateSignatures = async () => {
    if (uuid)
      await UpdateClientSignaturePrebid(token, uuid, email, formContent)
        .then((_) => {
          setGetter(!getter);
        })
        .catch((e) => {
          console.log(e);
        });
  };

  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    getPrebid();
  }, [getter]);

  const [isSignCanvas, setSignCanvas] = useState(false);
  const [uploadText, setUploadText] = useState(
    "Upload Signature & Prebid Acceptance"
  );
  return (
    <>
      <PrintComponent>
        <PrebidFormDownload formContent={formContent} />
      </PrintComponent>
      <div className="no-print" style={{ padding: 50 }}>
        <div className="no-print">
          <div className="d-flex align-items-center py-4">
            <p className="main-head ps-0 mb-0 w-50 text-end">
              Prebid Agreement
            </p>
            <div className="d-flex ms-auto">
              {formContent?.firstParty.email == email &&
                formContent?.firstParty.sign.length == 0 && (
                  <div className="">
                    <label
                      htmlFor="uploadSign"
                      className="active-btn ms-2 w-225 d-flex align-items-center justify-content-center cursor-pointer btn py-2 quote-btnn"
                    >
                      {uploadText}
                    </label>
                    <input
                      type="button"
                      hidden
                      id="uploadSign"
                      name="uploadSign"
                      onClick={() => {
                        setSignCanvas(true);
                      }}
                    />
                  </div>
                )}

              {/* Download Button */}
              <Button
                className="pr-btn ms-3 w-150 quote-btnn"
                // onClick={() => generatePdf()}
                onClick={() => {
                  setIsLoading(true);
                  // printAction();
                  var tempTitle = document.title;
                  document.title = `Quotation-${
                    formContent?.issueDate
                  }-${moment(new Date()).format("DD.MM.YYYY")}`;
                  window.print();
                  document.title = tempTitle;
                  setIsLoading(false);
                }}
                disabled={isLoading}
              >
                {"Save as PDF"}
              </Button>
            </div>
          </div>
        </div>

        {/* contact detail*/}
        <div className="no-print">
          <div className="contact-detail d-flex mt-2 no-print">
            <div
              className={`left-col ${
                !isEdit ? " w-75" : "w-50 pe-5"
              } text-start`}
            >
              <Typography variant="body1" gutterBottom>
                <ContactDetailField
                  label="Issue Date"
                  isEdit={isEdit}
                  type="Date"
                  value={formContent?.issueDate}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.issueDate = e.target.value;
                    setFormContent(values);
                  }}
                />
              </Typography>
            </div>
            {/* right col */}
          </div>
        </div>

        <div className="no-print">
          <div className="contact-detail d-flex mt-2 no-print">
            <div
              className={`left-col ${
                !isEdit ? " w-75" : "w-50 pe-5"
              } text-start`}
            >
              <Typography variant="body1" gutterBottom>
                <ContactDetailField
                  label="Contract Price"
                  isEdit={isEdit}
                  type="text"
                  value={formContent?.contractPrice}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.contractPrice = e.target.value;
                    setFormContent(values);
                  }}
                />
              </Typography>
            </div>
            {/* right col */}
          </div>
        </div>

        <div className="no-print">
          <div className="contact-detail d-flex mt-2 no-print">
            <div
              className={`left-col ${
                !isEdit ? " w-75" : "w-50 pe-5"
              } text-start`}
            >
              <Typography variant="body1" gutterBottom>
                <ContactDetailField
                  label="Date"
                  isEdit={isEdit}
                  type="Date"
                  value={formContent?.date}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.date = e.target.value;
                    setFormContent(values);
                  }}
                />
              </Typography>
            </div>
            {/* right col */}
          </div>
        </div>
        <div className="mt-5 pe-1 no-print">
          <Typography variant="body1" className="text-start" gutterBottom>
            <div
              className={`table-text-wrapper mb-2 d-flex ${
                isEdit ? "flex-column" : ""
              } justify-content-between`}
            >
              <label
                htmlFor="confidentiality"
                className={`fw-bold  ${isEdit ? "mb-3" : "mb-1"}`}
              >
                Confidentiality Notice
              </label>
              {!isEdit ? (
                <span className="table-text w-50 text-end">
                  {formContent?.notice ? formContent?.notice : "-"}
                </span>
              ) : (
                <TextArea
                  rows="3"
                  label="Confidentiality Notice..."
                  id="confidentialityNotice"
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.notice = e.target.value;
                    setFormContent(values);
                  }}
                  value={formContent?.notice}
                />
              )}
            </div>
          </Typography>
          <hr />
        </div>
        <div className="mt-5 pe-1 no-print">
          <Typography variant="body1" className="text-start" gutterBottom>
            <div
              className={`table-text-wrapper mb-2 d-flex ${
                isEdit ? "flex-column" : ""
              } justify-content-between`}
            >
              <label
                htmlFor="confidentiality"
                className={`fw-bold  ${isEdit ? "mb-3" : "mb-1"}`}
              >
                Important Note
              </label>
              {!isEdit ? (
                <span className="table-text w-50 text-end">
                  {formContent?.importantNote
                    ? formContent?.importantNote
                    : "-"}
                </span>
              ) : (
                <TextArea
                  rows="3"
                  label="Important note..."
                  id="importantNote"
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.importantNote = e.target.value;
                    setFormContent(values);
                  }}
                  value={formContent?.importantNote}
                />
              )}
            </div>
          </Typography>
          <hr />
        </div>
        {/* /description */}

        <div className="no-print">
          <div className="contact-detail d-flex mt-2 no-print">
            <div
              className={`left-col ${
                !isEdit ? " w-75" : "w-50 pe-5"
              } text-start`}
            >
              <label
                htmlFor="confidentiality"
                className={`fw-bold  ${isEdit ? "mb-3" : "mb-1"}`}
              >
                Car Description
              </label>
              <Typography variant="body1" gutterBottom>
                <ContactDetailField
                  label="Make"
                  isEdit={isEdit}
                  type="text"
                  value={formContent?.carDescription.make}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.carDescription.make = e.target.value;
                    setFormContent(values);
                  }}
                />
              </Typography>

              <Typography variant="body1" gutterBottom>
                <ContactDetailField
                  label="Model"
                  isEdit={isEdit}
                  type="text"
                  value={formContent?.carDescription.model}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.carDescription.model = e.target.value;
                    setFormContent(values);
                  }}
                />
              </Typography>

              <Typography variant="body1" gutterBottom>
                <ContactDetailField
                  label="Year"
                  isEdit={isEdit}
                  type="text"
                  value={formContent?.carDescription.year}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.carDescription.year = e.target.value;
                    setFormContent(values);
                  }}
                />
              </Typography>

              <Typography variant="body1" gutterBottom>
                <ContactDetailField
                  label="Specification"
                  isEdit={isEdit}
                  type="text"
                  value={formContent?.carDescription.specification}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.carDescription.specification = e.target.value;
                    setFormContent(values);
                  }}
                />
              </Typography>

              <Typography variant="body1" gutterBottom>
                <ContactDetailField
                  label="Mileage"
                  isEdit={isEdit}
                  type="text"
                  value={formContent?.carDescription.mileage}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.carDescription.mileage = e.target.value;
                    setFormContent(values);
                  }}
                />
              </Typography>
            </div>
            {/* right col */}
          </div>
        </div>

        <Row
          className="bg-white my-4 mx-0  p-3 py-4 mb-4"
          style={{ borderRadius: "10px" }}
        >
          <Row>
            {/* Images */}
            {/* <Col sm={6} md={6} className="px-10"> */}
            <div className="d-flex flex-wrap">
              {formContent?.carDescription.images?.map((image, index) => {
                return (
                  <Stack key={index}>
                    <LazyImage
                      key={index}
                      src={`${Bucket}${image}`}
                      placeholderSrc={gifLoading}
                      height={290}
                      width={210}
                      alt={`Image ${index}`}
                    />
                  </Stack>
                );
              })}
            </div>
          </Row>
        </Row>

        <Row className="mt-5 pt-5 pb-5 no-print">
          <Col sm={6} className="mt-5 pt-5">
            {" "}
            <div className="no-print">
              <div className="contact-detail d-flex mt-2 no-print">
                <div
                  className={`left-col ${
                    !isEdit ? " w-75" : "w-50 pe-5"
                  } text-start`}
                >
                  <label
                    htmlFor="confidentiality"
                    className={`fw-bold  ${isEdit ? "mb-3" : "mb-1"}`}
                  >
                    1st Party (Buyer)
                  </label>

                  <Typography variant="body1" gutterBottom>
                    <ContactDetailField
                      label="Name"
                      isEdit={isEdit}
                      type="text"
                      value={formContent?.firstParty.name}
                      onChange={(e) => {
                        let values = { ...formContent };
                        values.firstParty.name = e.target.value;
                        setFormContent(values);
                      }}
                    />
                  </Typography>

                  <Typography variant="body1" gutterBottom>
                    <ContactDetailField
                      label="Email"
                      isEdit={isEdit}
                      type="text"
                      value={formContent?.firstParty.email}
                      onChange={(e) => {
                        let values = { ...formContent };
                        values.firstParty.email = e.target.value;
                        setFormContent(values);
                      }}
                    />
                  </Typography>

                  <Typography variant="body1" gutterBottom>
                    <ContactDetailField
                      label="MOFA ID No"
                      isEdit={isEdit}
                      type="text"
                      value={formContent?.firstParty.mofaIdNo}
                      onChange={(e) => {
                        let values = { ...formContent };
                        values.firstParty.mofaIdNo = e.target.value;
                        setFormContent(values);
                      }}
                    />
                  </Typography>

                  <Typography variant="body1" gutterBottom>
                    <ContactDetailField
                      label="Organization"
                      isEdit={isEdit}
                      type="text"
                      value={formContent?.firstParty.organization}
                      onChange={(e) => {
                        let values = { ...formContent };
                        values.firstParty.organization = e.target.value;
                        setFormContent(values);
                      }}
                    />
                  </Typography>

                  <Col sm={6} className="mt-5 pt-5">
                    <div className="d-flex justify-content-center align-items-end">
                      {formContent.firstParty.sign != "" &&
                        formContent.firstParty.sign != undefined && (
                          <img
                            src={`${Bucket}${formContent.firstParty.sign}`}
                            height={80}
                            width={200}
                          />
                        )}
                    </div>

                    <p>{"_______________________________"}</p>
                    <p className="my-1">
                      <b>{"Buyer Signature"}</b>
                    </p>

                    {""}
                  </Col>
                </div>
                {/* right col */}
              </div>
            </div>
          </Col>

          <Col sm={6} className="mt-5 pt-5">
            <div className="no-print">
              <div className="contact-detail d-flex mt-2 no-print">
                <div
                  className={`left-col ${
                    !isEdit ? " w-75" : "w-50 pe-5"
                  } text-start`}
                >
                  <label
                    htmlFor="confidentiality"
                    className={`fw-bold  ${isEdit ? "mb-3" : "mb-1"}`}
                  >
                    2nd Party
                  </label>

                  <Typography variant="body1" gutterBottom>
                    <ContactDetailField
                      label="Name"
                      isEdit={isEdit}
                      type="text"
                      value={formContent?.secondParty.name}
                      onChange={(e) => {
                        let values = { ...formContent };
                        values.secondParty.name = e.target.value;
                        setFormContent(values);
                      }}
                    />
                  </Typography>

                  <Typography variant="body1" gutterBottom>
                    <ContactDetailField
                      label="Email"
                      isEdit={isEdit}
                      type="text"
                      value={formContent?.secondParty.email}
                      onChange={(e) => {
                        let values = { ...formContent };
                        values.secondParty.email = e.target.value;
                        setFormContent(values);
                      }}
                    />
                  </Typography>

                  <Typography variant="body1" gutterBottom>
                    <ContactDetailField
                      label="MOFA ID No"
                      isEdit={isEdit}
                      type="text"
                      value={formContent?.secondParty.mofaIdNo}
                      onChange={(e) => {
                        let values = { ...formContent };
                        values.secondParty.mofaIdNo = e.target.value;
                        setFormContent(values);
                      }}
                    />
                  </Typography>

                  <Typography variant="body1" gutterBottom>
                    <ContactDetailField
                      label="Organization"
                      isEdit={isEdit}
                      type="text"
                      value={formContent?.secondParty.organization}
                      onChange={(e) => {
                        let values = { ...formContent };
                        values.secondParty.organization = e.target.value;
                        setFormContent(values);
                      }}
                    />
                  </Typography>

                  <Col sm={6} className="mt-5 pt-5">
                    <div className="d-flex align-items-start">
                      <img
                        src={`${Bucket}${formContent.secondParty.sign}`}
                        height={80}
                        width={200}
                      />
                    </div>
                    <p>{"_______________________________"}</p>
                    <p className="my-1">
                      <b>{"Shelozon Movers Packers & Logistics Pvt Ltd"}</b>
                    </p>

                    {""}
                  </Col>
                </div>
                {/* right col */}
              </div>
            </div>
          </Col>
        </Row>
      </div>

      {isSignCanvas && (
        <MySignatureCanvas
          open={isSignCanvas}
          onClose={() => {
            setSignCanvas(false);
          }}
          onSuccess={(file) => {
            setUploadText("Uploading...");
            if (!isLoading) uploadFile(file, updateSignature);
          }}
        />
      )}
    </>
  );
};

export default ClientPreview;
