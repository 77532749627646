import React, { useEffect, useState } from "react";
import {
  getSingleSurvey,
  addFormContent,
  updateFormContent,
  getFormContent,
  surveyResultPreview,
} from "../../../api/services/services";
import backArrow from "../../../imgs/BackArrow.svg";
import MainTemplate from "../../templates/MainTemplate";
// import "./style.css";
import { useNavigate } from "react-router-dom";
import { Button, Snackbar, Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import { Bucket } from "../../../api/config/apiConfig";
import ContactDetailField from "../../molecules/ContactDetailField";
import TextArea from "../../atoms/Textarea";
import DynamicCheckbox from "../../molecules/DynamicCheckbox";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import Input from "../../atoms/Input";
import removeImg from "../../../imgs/remove.png";
import { useParams } from "react-router-dom";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import html2pdf from "html2pdf.js";
import PackingMaterialDownload from "./ClientSide.jsx";
import PrintComponent from "../PrintComponent";
import moment from "moment/moment.js";
import SuperAdminVerifationModal from "../PrebidAgreement/creation/super_admin_verification_modal";
import { sendSuperAdminVerificationForEdit } from "../PrebidAgreement/creation/service";

const PackingMaterialIssuedPage = () => {
  const { surveyId, id, flg } = useParams();
  const [surveyData, setSurveyData] = useState(null);
  const navigate = useNavigate();

  const [formContentId, setFormContentId] = useState("");
  const [isEdit, setIsEdit] = useState(flg == "1" ? true : false);

  const [isLoading, setIsLoading] = useState(false);
  const [formContent, setFormContent] = useState({
    dataRows: [
      // {
      //   description: "",
      //   date1: "",
      //   date2: "",
      //   date3: "",
      //   date4: "",
      //   date5: "",
      //   date6: "",
      //   date7: "",
      //   qtyUsed: "",
      //   qtyReturned: "",
      // },
    ],
    customerName: "",
    customerEmail: "",
    packingShifting: "",
    vehicles: "",
    packers: "",
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleAddNewDataRows = () => {
    let fC = { ...formContent };
    const values = [...formContent?.dataRows];

    values.push({
      packingMaterial: "",
      date1: "",
      date2: "",
      date3: "",
      date4: "",
      date5: "",
      date6: "",
      date7: "",
      qtyUsed: "",
      qtyReturned: "",
    });
    fC.dataRows = values;

    setFormContent(fC);
  };

  const handleRemoveDataRows = (ind) => {
    let fC = { ...formContent };
    const values = [...formContent?.dataRows];
    values.splice(ind, 1);
    fC.dataRows = values;
    setFormContent(fC);
  };

  //
  const getSurveyPreview = () => {
    surveyResultPreview(surveyId)
      .then((response) => {
        setSurveyData(response?.data?.data?.surveyDetail);
        let values = { ...formContent };
        values.customerName = `${response?.data?.data?.surveyDetail?.name}`;
        values.customerEmail = `${response?.data?.data?.surveyDetail?.email}`;

        // let items = [];

        // let itemsInd = 0;

        // // console.log(response?.data?.data?.surveyDetail?.surveyResult?.surveyRooms)
        // response?.data?.data?.surveyDetail?.surveyResult?.surveyRooms?.forEach(
        //   (rm, ind) => {
        //     // console.log(rm?.roomItems);
        //     rm?.roomItems?.forEach((rmIt) => {
        //       // console.log(itemsInd);
        //       // console.log(values.dataRows);

        //       // Adding a new object to the dataRows array
        //       values.dataRows.push({ description: rmIt?.name });
        //       itemsInd += 1;
        //     });
        //   }
        // );
        // // console.log(values)

        setFormContent(values);
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }
      });
  };

  //
  const getSurvey = () => {
    getSingleSurvey(surveyId)
      .then((response) => {
        if (response?.data?.data?.status == "submitted") getSurveyPreview();
        else {
          setSurveyData(response?.data?.data);
          let values = { ...formContent };
          values.customerName = `${response?.data?.data?.name}`;
          values.customerEmail = `${response?.data?.data?.email}`;

          setFormContent(values);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }
      });
  };

  //
  const addContent = () => {
    setIsLoading(true);
    const body = {
      formId: id,
      surveyId: surveyId,
      content: formContent,
      mediaKeys: [],
    };
    addFormContent(body)
      .then(() => {
        setSnackbarMessage(
          "Packing Material Issued Sheet has been added successfully!"
        );
        setOpenSnackbar(true);
        navigate(-1);

        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  //
  const updateContent = () => {
    setIsLoading(true);
    const body = {
      formId: id,
      surveyId: surveyId,
      content: formContent,
      mediaKeys: [],
      removedMediaKeys: [],
    };
    updateFormContent(body, formContentId)
      .then((res) => {
        setSnackbarMessage(
          "Packing Material Issued Sheet has been updated successfully!"
        );
        setOpenSnackbar(true);
        getContent();
        setIsEdit(false);

        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  //
  const getContent = () => {
    setIsLoading(true);
    getFormContent(id, surveyId)
      .then((res) => {
        setFormContentId(res.data.data.uuid);
        setFormContent(res.data.data.content);
        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (flg == "1") getSurvey();
    else getContent();
  }, []);

  const printAction = () => {
    const PAGE_HEIGHT = 1045;
    const printElement = document.getElementById("print-component");
    if (printElement) {
      printElement.classList.add("temp-class-for-height");
      const height = printElement.clientHeight;
      const numberOfPage = Math.ceil(height / PAGE_HEIGHT);
      const heightWithSingleHeader = numberOfPage * PAGE_HEIGHT;
      let requiredHeight = heightWithSingleHeader;
      if (numberOfPage > 1) {
        const headerHeight =
          printElement.getElementsByTagName("thead")?.[0]?.clientHeight;
        const footerHeight =
          printElement.getElementsByTagName("tfoot")?.[0]?.clientHeight;
        requiredHeight -= (numberOfPage - 1) * (headerHeight + footerHeight);
      }
      printElement.style.height = `${requiredHeight}px`;
      printElement.classList.remove("temp-class-for-height");
    }
    window.print();
    if (printElement) {
      printElement.style.height = `auto`;
    }
  };

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // 11th, 12th, 13th, etc.
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  // Helper function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    const ordinalSuffix = getOrdinalSuffix(day);

    return `${day}${ordinalSuffix} ${month}, ${year}`;
  };

  const [isVerified, setIsVerified] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);

  const sendEditRequestToAdmin = () => {
    setIsEditLoading(true);
    sendSuperAdminVerificationForEdit()
      .then((response) => {
        setIsVerified(true);
        setIsEditLoading(false);
      })
      .catch((error) => {
        setIsVerified(false);
        setIsEditLoading(false);
      });
  };

  return (
    <>
      <MainTemplate>
        <div className="no-print">
          <div className="d-block d-sm-flex align-items-center py-4">
            <div className="d-flex align-items-center ">
              <div
                className="light-blue-color back-bg cursor-pointer"
                onClick={() => navigate(-1)}
              >
                <img src={backArrow} height="24" width="24" />{" "}
              </div>
              <div>
                <p className="main-head ps-3 mb-0 mt-2">
                  Packing Material Issued Sheet
                </p>
              </div>
            </div>
            <div className="d-flex ms-auto justify-content-center mt-2 mt-sm-0">
              {/*Edit Button */}
              {!isEdit && (
                <Button
                  className="active-btn"
                  onClick={() => {
                    sendEditRequestToAdmin();
                  }}
                  disabled={isEditLoading}
                >
                  Edit
                </Button>
              )}

              {/* Cancel button */}
              {isEdit && flg == "2" && (
                <Button
                  className="active-btn ms-3"
                  onClick={() => {
                    getContent();
                    setIsEdit(false);
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
              )}

              {/* Save Button */}
              {isEdit && (
                <Button
                  className="active-btn ms-3"
                  onClick={() => {
                    if (flg == "1") addContent();
                    else updateContent();
                  }}
                  disabled={isLoading}
                >
                  Save
                </Button>
              )}

              {/* Download Button */}
              {!isEdit && (
                <Button
                  className="active-btn ms-3 px-0"
                  onClick={() => {
                    setIsLoading(true);
                    // printAction();
                    var tempTitle = document.title;
                    document.title = `Packing Material Issued Sheet-${
                      formContent?.customerName
                    }-${moment(new Date()).format("DD.MM.YYYY")}`;

                    window.print();
                    document.title = tempTitle;
                    setIsLoading(false);
                  }}
                  disabled={isLoading}
                >
                  {"Save as PDF"}
                </Button>
              )}
            </div>
          </div>

          {/*  table */}
          <div className="overflow-x-auto mb-5 Weight-Ticket-Table">
            <Table
              responsive
              // striped
              // bordered
              // hover
              style={{ overflowX: "auto" }}
            >
              {/* Table Header */}
              <thead
                // className="bg-white"
                className="bg-body-tertiary"
              >
                <tr>
                  <th className="border p-3 text-start min-w-25"> No.</th>
                  <th className="border p-3 text-start min-w-250">Item Name</th>
                  <th className="border p-3 text-start min-w-100">
                    {/* Issued Packing Material Date Wise */} Date Issue
                  </th>
                  <th className="border p-3 text-start min-w-100">
                    Qty Issued
                  </th>
                  <th className="border p-3 text-start min-w-100">Qty Used</th>

                  <th className="border p-3 text-start min-w-100">
                    Qty Returned
                  </th>
                  {isEdit && <th className="border p-3"></th>}
                </tr>
              </thead>
              <tbody className="bg-white">
                {formContent?.dataRows?.length > 0 ? (
                  <>
                    {formContent?.dataRows?.map((row, index) => {
                      return (
                        <tr>
                          <td className="border p-3 text-start">{index + 1}</td>
                          <td className="border p-3 text-start">
                            {isEdit ? (
                              <Input
                                id="condition"
                                label="Description..."
                                type="text"
                                style="full-border-field"
                                value={row?.description}
                                onChange={(e) => {
                                  const values = { ...formContent };
                                  const valuesRows = [...formContent?.dataRows];
                                  valuesRows[index].description =
                                    e.target.value;
                                  values.dataRows = valuesRows;
                                  setFormContent(values);
                                }}
                                inline={{ width: "-webkit-fill-available" }}
                              />
                            ) : row.description ? (
                              row.description
                            ) : (
                              "-"
                            )}
                          </td>
                          <td className="border p-3 text-start min-w-100">
                            {isEdit ? (
                              <>
                                <Input
                                  id="condition"
                                  label="Date..."
                                  type="date"
                                  style="full-border-field"
                                  value={row?.date1}
                                  onChange={(e) => {
                                    const values = { ...formContent };
                                    const valuesRows = [
                                      ...formContent?.dataRows,
                                    ];
                                    valuesRows[index].date1 = e.target.value;
                                    values.dataRows = valuesRows;
                                    setFormContent(values);
                                  }}
                                  inline={{ width: "-webkit-fill-available" }}
                                />
                              </>
                            ) : row.date1 ? (
                              formatDate(row.date1)
                            ) : (
                              "-"
                            )}
                          </td>
                          {/* <td className="border p-3 text-start min-w-100">
                            {isEdit ? (
                              <>
                                <Input
                                  id="condition"
                                  label="Date..."
                                  type="date"
                                  style="full-border-field"
                                  value={row?.date2}
                                  onChange={(e) => {
                                    const values = { ...formContent };
                                    const valuesRows = [
                                      ...formContent?.dataRows,
                                    ];
                                    valuesRows[index].date2 = e.target.value;
                                    values.dataRows = valuesRows;
                                    setFormContent(values);
                                  }}
                                  inline={{ width: "-webkit-fill-available" }}
                                />
                              </>
                            ) : row.date2 ? (
                              formatDate(row.date2)
                            ) : (
                              "-"
                            )}
                          </td> */}
                          {/* <td className="border p-3 text-start min-w-100">
                            {isEdit ? (
                              <>
                                <Input
                                  id="condition"
                                  label="Date..."
                                  type="date"
                                  style="full-border-field"
                                  value={row?.date3}
                                  onChange={(e) => {
                                    const values = { ...formContent };
                                    const valuesRows = [
                                      ...formContent?.dataRows,
                                    ];
                                    valuesRows[index].date3 = e.target.value;
                                    values.dataRows = valuesRows;
                                    setFormContent(values);
                                  }}
                                  inline={{ width: "-webkit-fill-available" }}
                                />
                              </>
                            ) : row.date3 ? (
                              formatDate(row.date3)
                            ) : (
                              "-"
                            )}
                          </td> */}
                          {/* <td className="border p-3 text-start min-w-100">
                            {isEdit ? (
                              <>
                                <Input
                                  id="condition"
                                  label="Date..."
                                  type="date"
                                  style="full-border-field"
                                  value={row?.date4}
                                  onChange={(e) => {
                                    const values = { ...formContent };
                                    const valuesRows = [
                                      ...formContent?.dataRows,
                                    ];
                                    valuesRows[index].date4 = e.target.value;
                                    values.dataRows = valuesRows;
                                    setFormContent(values);
                                  }}
                                  inline={{ width: "-webkit-fill-available" }}
                                />
                              </>
                            ) : row.date4 ? (
                              formatDate(row.date4)
                            ) : (
                              "-"
                            )}
                          </td> */}
                          {/* <td className="border p-3 text-start min-w-100">
                            {isEdit ? (
                              <>
                                <Input
                                  id="condition"
                                  label="Date..."
                                  type="date"
                                  style="full-border-field"
                                  value={row?.date5}
                                  onChange={(e) => {
                                    const values = { ...formContent };
                                    const valuesRows = [
                                      ...formContent?.dataRows,
                                    ];
                                    valuesRows[index].date5 = e.target.value;
                                    values.dataRows = valuesRows;
                                    setFormContent(values);
                                  }}
                                  inline={{ width: "-webkit-fill-available" }}
                                />
                              </>
                            ) : row.date5 ? (
                              formatDate(row.date5)
                            ) : (
                              "-"
                            )}
                          </td> */}
                          {/* <td className="border p-3 text-start min-w-100">
                            {isEdit ? (
                              <>
                                <Input
                                  id="condition"
                                  label="Date..."
                                  type="date"
                                  style="full-border-field"
                                  value={row?.date6}
                                  onChange={(e) => {
                                    const values = { ...formContent };
                                    const valuesRows = [
                                      ...formContent?.dataRows,
                                    ];
                                    valuesRows[index].date6 = e.target.value;
                                    values.dataRows = valuesRows;
                                    setFormContent(values);
                                  }}
                                  inline={{ width: "-webkit-fill-available" }}
                                />
                              </>
                            ) : row.date6 ? (
                              formatDate(row.date6)
                            ) : (
                              "-"
                            )}
                          </td> */}
                          {/* <td className="border p-3 text-start min-w-100">
                            {isEdit ? (
                              <>
                                <Input
                                  id="condition"
                                  label="Date..."
                                  type="date"
                                  style="full-border-field"
                                  value={row?.date7}
                                  onChange={(e) => {
                                    const values = { ...formContent };
                                    const valuesRows = [
                                      ...formContent?.dataRows,
                                    ];
                                    valuesRows[index].date7 = e.target.value;
                                    values.dataRows = valuesRows;
                                    setFormContent(values);
                                  }}
                                  inline={{ width: "-webkit-fill-available" }}
                                />
                              </>
                            ) : row.date7 ? (
                              formatDate(row.date7)
                            ) : (
                              "-"
                            )}
                          </td> */}

                          <td className="border p-3 text-start">
                            {isEdit ? (
                              <>
                                <Input
                                  id="condition"
                                  label="QTY issued..."
                                  type="number"
                                  className="full-border-field"
                                  style="full-border-field"
                                  value={row?.qtyIssued}
                                  onChange={(e) => {
                                    const values = { ...formContent };
                                    const valuesRows = [
                                      ...formContent?.dataRows,
                                    ];
                                    valuesRows[index].qtyIssued =
                                      e.target.value;
                                    values.dataRows = valuesRows;
                                    setFormContent(values);
                                  }}
                                  inline={{ width: "-webkit-fill-available" }}
                                />
                              </>
                            ) : row?.qtyIssued ? (
                              row?.qtyIssued
                            ) : (
                              "-"
                            )}
                          </td>

                          <td className="border p-3 text-start ">
                            {isEdit ? (
                              <>
                                <Input
                                  id="condition"
                                  label="QTY Used..."
                                  type="number"
                                  className="full-border-field"
                                  style="full-border-field"
                                  value={
                                    Number(row?.qtyIssued) -
                                    Number(row?.qtyReturned)
                                  }
                                  onChange={(e) => {
                                    const values = { ...formContent };
                                    const valuesRows = [
                                      ...formContent?.dataRows,
                                    ];
                                    valuesRows[index].qtyUsed = e.target.value;
                                    values.dataRows = valuesRows;
                                    setFormContent(values);
                                  }}
                                  inline={{ width: "-webkit-fill-available" }}
                                  disabled
                                />
                              </>
                            ) : (
                              <>
                                {row.qtyIssued || row?.qtyReturned ? (
                                  <p className="p-word mb-0">
                                    {Number(row.qtyIssued) -
                                      Number(row?.qtyReturned)}
                                  </p>
                                ) : (
                                  "-"
                                )}
                              </>
                            )}
                          </td>

                          <td className="border p-3 text-start">
                            {isEdit ? (
                              <>
                                <Input
                                  id="condition"
                                  label="QTY returned..."
                                  type="number"
                                  className="full-border-field"
                                  style="full-border-field"
                                  value={row?.qtyReturned}
                                  onChange={(e) => {
                                    const values = { ...formContent };
                                    const valuesRows = [
                                      ...formContent?.dataRows,
                                    ];
                                    valuesRows[index].qtyReturned =
                                      e.target.value;
                                    values.dataRows = valuesRows;
                                    setFormContent(values);
                                  }}
                                  inline={{ width: "-webkit-fill-available" }}
                                />
                              </>
                            ) : row.qtyReturned ? (
                              row.qtyReturned
                            ) : (
                              "-"
                            )}
                          </td>
                          {isEdit && (
                            <td className="border p-3 text-start">
                              <img
                                src={removeImg}
                                height={18}
                                width={18}
                                className="cursor-pointer"
                                onClick={() => handleRemoveDataRows(index)}
                              />
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <tr>
                    <td className="text-center" colSpan={isEdit ? 12 : 11}>
                      {isLoading ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "10px 0px",
                          }}
                        >
                          <CircularProgress
                            style={{
                              color: "#00aff0",
                              height: "25px",
                              width: "25px",
                            }}
                          />
                        </Box>
                      ) : (
                        <div className="d-flex justify-content-center">
                          <p className="my-3">No Records to Show</p>
                        </div>
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>

          <Row className="mb-5">
            <Col className="col-12 text-end">
              {isEdit && (
                <Button
                  className="add-new-btn ms-auto"
                  onClick={() => {
                    handleAddNewDataRows();
                  }}
                >
                  Add New
                </Button>
              )}
            </Col>
          </Row>

          {/* Details */}
          <div className="mt-3 pe-1 mb-5 row">
            <Typography
              variant="body1"
              className="text-start col-12 col-md-8"
              gutterBottom
            >
              <div className="mb-2">
                <Typography variant="body1" gutterBottom>
                  <ContactDetailField
                    label="Customer Name/Organization"
                    isEdit={isEdit}
                    type="text"
                    style="fw-normal"
                    value={formContent?.customerName}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.customerName = e.target.value;
                      setFormContent(values);
                    }}
                    minWidth={"340px"}
                  />
                  <ContactDetailField
                    label=" Packing/Shifting/Storage/Others -Date wise"
                    isEdit={isEdit}
                    type="text"
                    style="fw-normal"
                    value={formContent?.packingShifting}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.packingShifting = e.target.value;
                      setFormContent(values);
                    }}
                    minWidth={"340px"}
                  />
                  <ContactDetailField
                    label="Vehicles used for the move"
                    isEdit={isEdit}
                    type="text"
                    style="fw-normal"
                    value={formContent?.vehicles}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.vehicles = e.target.value;
                      setFormContent(values);
                    }}
                    minWidth={"340px"}
                  />
                  <ContactDetailField
                    label="Packers used for the move"
                    isEdit={isEdit}
                    type="text"
                    style="fw-normal"
                    value={formContent?.packers}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.packers = e.target.value;
                      setFormContent(values);
                    }}
                    minWidth={"340px"}
                  />
                </Typography>
              </div>
            </Typography>
          </div>
          {/* / Details */}

          {/* Signatures */}
          {/* {!isEdit && (
            <Row className="mt-5 pt-5 pb-5">
              <Col sm={6} className="mt-5 pt-5">
                <div style={{ height: "90px" }}></div>
                <p>{"_______________________________"}</p>
                <p className="my-1">
                  <b>{"Packing Supervisor"}</b>
                </p>
                <p className="mt-1">{"(Sign Required)"}</p>
                {""}
              </Col>
              <Col sm={6} className="mt-5 pt-5">
                <div style={{ height: "90px" }}></div>
                <p>{"_______________________________"}</p>
                <p className="my-1">
                  <b>{"W/H Supervisor"}</b>
                </p>
                <p className="mt-1">{"(Sign Required)"}</p>
                {""}
              </Col>
            </Row>
          )} */}
        </div>

        <PrintComponent>
          <PackingMaterialDownload
            id="content-id"
            surveyData={surveyData}
            formContent={formContent}
          />
        </PrintComponent>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000} // Adjust the duration as needed
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={
              snackbarMessage?.includes("successful") ? "success" : "error"
            }
            onClose={handleCloseSnackbar}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>

        {isVerified && (
          <SuperAdminVerifationModal
            open={isVerified}
            onClose={() => {
              setIsVerified(false);
            }}
            onSuccess={() => {
              setIsEdit(true);
              setIsVerified(false);
            }}
            onError={() => {
              // shareWithClient(emails);
              setSnackbarMessage("Invalid Confirm Pin");
              setOpenSnackbar(true);
            }}
          />
        )}
      </MainTemplate>
    </>
  );
};
export default PackingMaterialIssuedPage;
