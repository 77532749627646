import React, { useEffect, useState } from "react";
import {
  getSingleSurvey,
  updateStatus,
  surveyResultPreview,
  getSurveyFormList,
  addFormContent,
  updateFormContent,
  getFormContent,
  imageUpload,
} from "../../../api/services/services";
import backArrow from "../../../imgs/BackArrow.svg";
import MainTemplate from "../../templates/MainTemplate";
import { useNavigate } from "react-router-dom";
import { Button, Snackbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import MuiAlert from "@mui/material/Alert";
import { Bucket } from "../../../api/config/apiConfig";
import "./style.css";
import ContactDetailField from "../../molecules/ContactDetailField";
import CustomCheckbox from "../../atoms/CustomCheckbox";
import DynamicText from "../../molecules/DynamicText";
import { useParams } from "react-router-dom";
import eye from "../../../imgs/photo.png";
import upload from "../../../imgs/upload.png";
import { Visibility } from "@mui/icons-material";
import LoadingModal from "../../molecules/LoadingModal";
import trashImg from "../../../imgs/trash.png";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import downloadIcon from "../../../imgs/download.png";

const MoveCheckList = () => {
  const { surveyId, id, flg } = useParams();

  const [surveyData, setSurveyData] = useState(null);
  const navigate = useNavigate();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [openLightbox, setOpenLightbox] = React.useState(false);
  const [index, setIndex] = React.useState(-1);
  const [slides, setSlides] = useState([]);

  const [formContent, setFormContent] = useState({
    originAddress: "",
    destinationAddress: "",
    removalDate: "",
    arrangedBy: "",
    wayBillOfOriginAgent: "",
    wayBillOfBooker: "",
    oceanBillOfLoading: "",
    airWayBill: "",
    remarks: "",
    otherDocuments: [],
    refNo: {
      refPart1: "",
      refPart2: "",
    },
    clientName: "",
  });

  const [formContentId, setFormContentId] = useState("");
  const [isEdit, setIsEdit] = useState(flg == "1" ? true : false);
  const [mediaKeysToSend, setMediaKeysToSend] = useState([]);
  const [mediaKeysToRemove, setMediaKeysToRemove] = useState([]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const [filesList, setFilesList] = useState([
    {
      name: "Quotation Form",
      value: "survey/quotation-form",
      id: "be5690e1-df05-4791-a214-2235c757f0f3",
      isAdded: false,
    },
    {
      name: "Crew Instruction Document",
      value: "survey/crew-instruction-document",
      id: "3dcad948-1923-4a77-aecf-35c44f610753",
      isAdded: false,
    },
    {
      name: "Packing Request Form",
      value: "survey/packing-request-form",
      id: "795ed973-d542-45bb-b63d-f29f0c0f1693",
      isAdded: false,
    },
    {
      name: "Packing Material Issued Sheet",
      value: "survey/packing-material-issued-sheet",
      id: "178c68ff-0f74-471e-b64d-a5c0476a9380",
      isAdded: false,
    },
    {
      name: "Worksheet Import",
      value: "survey/worksheet-import",
      id: "5fb9aeb9-e1a3-4763-ada6-1eb83a8491b0",
      isAdded: false,
    },
    {
      name: "Weight Ticket",
      value: "survey/weight-ticket",
      id: "e57266c3-322b-4a90-858b-806d2557e605",
      isAdded: false,
    },
    {
      name: "Wooden Lift Van Quality Check Report",
      value: "survey/wooden-lift-van-quality-check-report",
      id: "7ee33eb8-5471-47d3-837b-aeb0914bea73",
      isAdded: false,
    },
    {
      name: "Shipping Instructions",
      value: "survey/shipping-instructions",
      id: "3d6d82dd-e980-4fe9-bb5f-2b0877de9354",
      isAdded: false,
    },
    {
      name: "Item Condition Report at Origin",
      value: "survey/item-condition-report-at-origin",
      id: "19d7db4c-2bdd-492e-8b5f-5e030345fa47",
      isAdded: false,
    },
    {
      name: "Delivery Receipt",
      value: "survey/delivery-receipt",
      id: "0cc862ad-1e4b-4167-a50b-942747f35f57",
      isAdded: false,
    },
    {
      name: "Delivery Request Form",
      value: "survey/delivery-request-form",
      isAdded: false,
      id: "a4a04109-6d40-4c84-9116-d82fbd27133a",
    },
    {
      name: "Invoice",
      value: "survey/invoice",
      id: "664aa0b2-661d-4311-a496-747c2b45ec87",
      isAdded: false,
    },
    // {
    //   name: "Inbound Move Checklist 01",
    //   value: "survey/move-checklist",
    //   id: "a72bee18-cfd0-4ffc-971c-198b5e5b6a63",
    //   isAdded: false,
    // },
    // {
    //   name: "Inbound Move Checklist 02",
    //   value: "survey/inbound-move-checklist-form-02",
    //   isAdded: false,
    // },
    // {
    //   name: "Outbound Move Checklist 01",
    //   value: "survey/outbound-move-checklist-form-01",
    //   isAdded: false,
    // },
    // {
    //   name: "Outbound Move Checklist 02",
    //   value: "survey/outbound-move-checklist-form-02",
    //   isAdded: false,
    // },
    {
      name: "Load Unload Report",
      value: "survey/load-unload-report",
      id: "ba57ab91-9724-4614-af97-61705090fd37",
      isAdded: false,
    },
    {
      name: "Packing Inventory",
      value: "survey/packing-inventory",
      id: "1f81d381-5fdf-453f-ba00-e1ca03404eac",
      isAdded: false,
    },
    // {
    //   name: "Inter Trans Insurance Services",
    //   value: "survey/inter-trans-insurance-services",
    //   id: "63b9c5c6-ca4e-40b1-a332-edcbecba98fa",
    //   isAdded: false,
    // },
    {
      name: "Acknowledgement Receipt",
      value: "survey/acknowledge-receipt",
      id: "efa3d3dc-7681-4603-9871-f368ce0edde5",
      isAdded: false,
    },
  ]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  //
  useEffect(() => {
    getSurveyFormList(surveyId)
      .then((res) => {
        let values = [...filesList];
        res?.data?.data?.map((fm, ind) => {
          filesList?.map((ls, index) => {
            if (fm?.form?.formId === ls?.id) {
              values[index].isAdded = true;
            }
          });
        });

        setFilesList(values);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }
      });
  }, []);

  //
  const convertDate = (unixTimestamp) => {
    const date = new Date(unixTimestamp * 1000); // Multiply by 1000 to convert from seconds to milliseconds
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1 and format with leading zero
    const dd = String(date.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  //
  const getSurveyPreview = () => {
    surveyResultPreview(surveyId)
      .then((response) => {
        setSurveyData(response?.data?.data?.surveyDetail);
        let values = { ...formContent };
        values.originAddress = `${response?.data?.data?.surveyDetail?.loadPlaceStAddress}, ${response?.data?.data?.surveyDetail?.loadPlaceCity}, ${response?.data?.data?.surveyDetail?.loadPlaceCountry}`;
        values.destinationAddress = `${response?.data?.data?.surveyDetail?.unloadPlaceStAddress}, ${response?.data?.data?.surveyDetail?.unloadPlaceCity}, ${response?.data?.data?.surveyDetail?.unloadPlaceCountry}`;
        values.clientName = `${response?.data?.data?.surveyDetail?.name}`;
        values.clientEmail = `${response?.data?.data?.surveyDetail?.email}`;
        values.removalDate = `${convertDate(
          response?.data?.data?.surveyDetail?.loadDate
        )}`;
        values.refNo = {
          refPart1: `${response?.data?.data?.surveyDetail?.id}`,
          refPart2: "",
        };
        values.status = "submitted";

        setFormContent(values);
        setMediaKeysToSend([]);
        setMediaKeysToRemove([]);
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }
        setMediaKeysToSend([]);
        setMediaKeysToRemove([]);
      });
  };

  //
  const getSurvey = () => {
    getSingleSurvey(surveyId)
      .then((response) => {
        if (response?.data?.data?.status == "submitted") getSurveyPreview();
        else {
          setSurveyData(response?.data?.data);
          let values = { ...formContent };
          values.originAddress = `${response?.data?.data?.loadPlaceStAddress}, ${response?.data?.data?.loadPlaceCity}, ${response?.data?.data?.loadPlaceCountry}`;
          values.destinationAddress = `${response?.data?.data?.unloadPlaceStAddress}, ${response?.data?.data?.unloadPlaceCity}, ${response?.data?.data?.unloadPlaceCountry}`;
          values.clientName = `${response?.data?.data?.name}`;
          values.clientEmail = `${response?.data?.data?.email}`;
          values.removalDate = `${convertDate(response?.data?.data?.loadDate)}`;
          values.refNo = {
            refPart1: `${response?.data?.data?.id}`,
            refPart2: "",
          };

          setFormContent(values);
        }
        setMediaKeysToSend([]);
        setMediaKeysToRemove([]);
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }
        setMediaKeysToSend([]);
        setMediaKeysToRemove([]);
      });
  };

  // useEffect(() => {
  //   getSurvey();
  // }, []);

  //
  const addContent = () => {
    let keys = [];
    if (formContent.wayBillOfOriginAgent.trim().length > 0)
      keys[0] = formContent.wayBillOfOriginAgent;

    if (formContent.wayBillOfBooker.trim().length > 0)
      keys[1] = formContent.wayBillOfBooker;

    if (formContent.oceanBillOfLoading.trim().length > 0)
      keys[2] = formContent.oceanBillOfLoading;

    if (formContent.airWayBill.trim().length > 0)
      keys[3] = formContent.airWayBill;

    setIsLoading(true);
    const body = {
      formId: "a72bee18-cfd0-4ffc-971c-198b5e5b6a63",
      surveyId: surveyId,
      content: formContent,
      mediaKeys: mediaKeysToSend,
      removedMediaKeys: mediaKeysToRemove,
    };
    addFormContent(body)
      .then(() => {
        setSnackbarMessage("Move Checklist has been added successfully!");
        setOpenSnackbar(true);
        setIsEdit(false);

        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  //
  const updateContent = () => {
    setIsLoading(true);

    let keys = [];
    if (formContent.wayBillOfOriginAgent.trim().length > 0)
      keys[0] = formContent.wayBillOfOriginAgent;

    if (formContent.wayBillOfBooker.trim().length > 0)
      keys[1] = formContent.wayBillOfBooker;

    if (formContent.oceanBillOfLoading.trim().length > 0)
      keys[2] = formContent.oceanBillOfLoading;

    if (formContent.airWayBill.trim().length > 0)
      keys[3] = formContent.airWayBill;

    const body = {
      formId: "a72bee18-cfd0-4ffc-971c-198b5e5b6a63",
      surveyId: surveyId,
      content: formContent,
      mediaKeys: mediaKeysToSend,
      removedMediaKeys: mediaKeysToRemove,
    };
    updateFormContent(body, formContentId)
      .then((res) => {
        setSnackbarMessage("Move Checklist has been updated successfully!");
        setOpenSnackbar(true);
        getContent();
        setIsEdit(false);

        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  //
  const getContent = () => {
    setIsLoading(true);
    getFormContent("a72bee18-cfd0-4ffc-971c-198b5e5b6a63", surveyId)
      .then((res) => {
        setFormContentId(res.data.data.uuid);
        setFormContent(res.data.data.content);

        setIsLoading(false);
        setMediaKeysToSend([]);
        setMediaKeysToRemove([]);

        let values = [];
        res.data.data.content.otherDocuments.map((dc, ind) => {
          values[ind] = {
            src: Bucket + dc,
          };
        });
        console.log(values);
        setSlides(values);
      })
      .catch((error) => {
        // setSnackbarMessage(error?.response?.data?.message);
        // setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
        setMediaKeysToSend([]);
        setMediaKeysToRemove([]);
        getSurvey();
      });
  };

  useEffect(() => {
    //  if (flg == "2")
    getContent();
  }, []);

  //
  const uploadFile = async (event, name) => {
    event.preventDefault();

    // Check if a file is selected
    if (event.target.files.length === 0) {
      // console.error("No file selected");
      return;
    }

    // Create a FormData object and append the selected file
    const fd = new FormData();
    fd.append("image", event.target.files[0]);

    handleOpen();

    for (let pair of fd.entries()) {
      // console.log(pair[0] + ", " + pair[1]);
    }

    try {
      // Call the imageUpload function and await its result
      const res = await imageUpload(fd);

      // Handle response
      let values = { ...formContent };
      if (name === "waybill of origin agent") {
        values.wayBillOfOriginAgent = res?.data?.data?.key;
      } else if (name == "waybill of booker") {
        values.wayBillOfBooker = res?.data?.data?.key;
      } else if (name == "oceanbill of loading") {
        values.oceanBillOfLoading = res?.data?.data?.key;
      } else if (name == "air way bill") {
        values.airWayBill = res?.data?.data?.key;
      }

      let md = [...mediaKeysToSend];

      md.push(res?.data?.data?.key);

      setFormContent(values);
      setMediaKeysToSend(md);
      handleClose();
    } catch (error) {
      // Example error handling (you may adjust this part)
      setSnackbarMessage(error?.response?.data?.message);
      setOpenSnackbar(true);

      if (error?.response?.data?.statusCode === 401) {
        localStorage.clear();
        window.location.pathname = "/login";
      }
      handleClose();
    } finally {
      // Optionally, perform any cleanup or additional actions
      setIsLoading(false);
      handleClose();
    }
  };

  //
  const uploadOtherFiles = async (event, name) => {
    event.preventDefault();

    // Check if files are selected
    if (event.target.files.length === 0) {
      console.error("No files selected");
      return;
    }

    // Create an array to hold the promises for each file upload
    const uploadPromises = [];
    handleOpen();

    // Loop through each selected file
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];

      // Create a FormData object and append the current file
      const fd = new FormData();
      fd.append("image", file);

      // Log the FormData entries for debugging
      for (let pair of fd.entries()) {
        // console.log(pair[0] + ", " + pair[1]);
      }

      // Add the file upload promise to the array
      uploadPromises.push(imageUpload(fd));
    }

    try {
      // Wait for all file uploads to complete
      const responses = await Promise.all(uploadPromises);

      // Handle responses
      let values = { ...formContent };
      let md = [...mediaKeysToSend];
      responses.forEach((res) => {
        values.otherDocuments.push(res?.data?.data?.key);
        md.push(res?.data?.data?.key);
      });

      setFormContent(values);
      setMediaKeysToSend(md);
      handleClose();
    } catch (error) {
      // Example error handling (you may adjust this part)
      setSnackbarMessage(error?.response?.data?.message);
      setOpenSnackbar(true);

      if (error?.response?.data?.statusCode === 401) {
        localStorage.clear();
        window.location.pathname = "/login";
      }
      handleClose();
    } finally {
      // Optionally, perform any cleanup or additional actions
      setIsLoading(false);
      handleClose();
    }
  };

  const truncateFileName = (name, length) => {
    if (name.length <= length) return name;

    const parts = name.split(".");
    const extension = parts.length > 1 ? "." + parts.pop() : "";
    const truncatedName =
      parts.join(".").slice(0, length - 3 - extension.length) + "...";

    return truncatedName + extension;
  };

  return (
    <>
      <MainTemplate>
        <div>
          <div className="d-block d-sm-flex align-items-center py-4">
            <div className="d-flex align-items-center ">
              <div
                className="light-blue-color back-bg cursor-pointer"
                onClick={() => navigate(-1)}
              >
                <img src={backArrow} height="24" width="24" />{" "}
              </div>
              <div>
                <p className="main-head ps-3 mb-0">Move Checklist</p>
              </div>
            </div>
            <div className="d-flex ms-auto justify-content-center mt-2 mt-sm-0">
              {/*Edit Button */}
              {!isEdit && (
                <Button
                  className="active-btn"
                  onClick={() => {
                    setIsEdit(true);
                  }}
                >
                  Edit
                </Button>
              )}
              {/* Cancel button */}
              {isEdit && (
                <Button
                  className="active-btn"
                  onClick={() => {
                    getContent();
                    setTimeout(() => {
                      setIsEdit(false);
                    }, 300);
                  }}
                >
                  Cancel
                </Button>
              )}
              {/* Save Button */}
              {isEdit && (
                <Button
                  className="active-btn ms-2"
                  onClick={() => {
                    if (formContentId == "") addContent();
                    else updateContent();
                  }}
                >
                  Save
                </Button>
              )}
            </div>
          </div>
        </div>

        {/*  Details */}
        <div className="mt-5 pe-1 mb-5">
          <Typography variant="body1" className="text-start w-100" gutterBottom>
            <div>
              <Typography variant="body1" gutterBottom>
                <div className="d-flex align-items-center row">
                  {isEdit ? (
                    <>
                      <div
                        className={`${
                          isEdit ? "mb-4" : "mb-2"
                        } d-flex align-items-baseline contactDetail-label`}
                      >
                        <label
                          htmlFor={"refNo"}
                          className={` me-3 ${isEdit ? "w-25" : ""} ${
                            style || "fw-bold"
                          }`}
                          style={{ minWidth: "120px" }}
                        >
                          <b>Ref No:</b>
                        </label>
                        <span
                          className="input-field"
                          style={{ width: "fit-content" }}
                        >{`${formContent?.refNo?.refPart1}`}</span>
                        <input
                          type={"text"}
                          id="refNo"
                          // className={`${style || "input-field "}`}
                          className={`input-field`}
                          value={`${formContent?.refNo?.refPart2}`}
                          onChange={(e) => {
                            let values = { ...formContent };
                            values.refNo.refPart2 = e.target.value;
                            setFormContent(values);
                          }}
                          placeholder={""}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <ContactDetailField
                        label="File Ref"
                        value={`${formContent?.refNo?.refPart1}${formContent?.refNo?.refPart2}`}
                        isEdit={isEdit}
                        type="text"
                        disabled={false}
                        minWidth={"120px"}
                      />
                    </>
                  )}
                </div>
                <ContactDetailField
                  label="Client Name"
                  value={formContent?.clientName ? formContent?.clientName : ""}
                  isEdit={isEdit}
                  type="text"
                  disabled={true}
                  minWidth={"120px"}
                />

                <ContactDetailField
                  label="Origin Address"
                  value={
                    formContent?.originAddress ? formContent?.originAddress : ""
                  }
                  isEdit={isEdit}
                  type="text"
                  minWidth={"120px"}
                />
                <ContactDetailField
                  label="Destination  Address"
                  value={
                    formContent?.destinationAddress
                      ? formContent?.destinationAddress
                      : ""
                  }
                  isEdit={isEdit}
                  type="text"
                  disabled={false}
                  minWidth={"120px"}
                />
                <ContactDetailField
                  label="Arranged By"
                  value={formContent?.arrangedBy ? formContent?.arrangedBy : ""}
                  isEdit={isEdit}
                  type="text"
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.arrangedBy = e.target.value;
                    setFormContent(values);
                  }}
                  disabled={false}
                  minWidth={"120px"}
                />
                <ContactDetailField
                  label="Removal Date"
                  value={
                    formContent?.removalDate ? formContent?.removalDate : ""
                  }
                  isEdit={isEdit}
                  type="date"
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.removalDate = e.target.value;
                    setFormContent(values);
                  }}
                  disabled={false}
                  minWidth={"120px"}
                />
              </Typography>
            </div>
          </Typography>
        </div>
        {/* / Details */}

        {/* checkboxes */}
        <Typography variant="body1" className="text-start" gutterBottom>
          <div className="mb-2">
            <div className="row">
              <div className="col-md-3 col-sm-6 mb-3 hover-checklist">
                <CustomCheckbox
                  style="mb-3"
                  id="submittedBySurveyor"
                  label="Submitted By Surveyor"
                  value={
                    surveyData?.status == "submitted" ||
                    formContent?.status == "submitted"
                      ? true
                      : false
                  }
                  path={`/survey/${surveyId}`}
                  disabled={isEdit ? true : false}
                />
              </div>

              {filesList?.map((file, ind) => {
                return (
                  <div className="col-md-3 col-sm-6 mb-3 ">
                    <CustomCheckbox
                      style="mb-3"
                      id={file?.value}
                      label={file?.name}
                      value={file?.isAdded ? true : false}
                      path={
                        file?.isAdded == true
                          ? `/${file.value}/2/${surveyId}/${file.id}`
                          : `/${file.value}/1/${surveyId}/${file.id}`
                      }
                      disabled={
                        isEdit
                          ? true
                          : file?.value ==
                              "survey/item-condition-report-at-origin" &&
                            formContent?.status != "submitted"
                          ? true
                          : false
                      }
                    />
                  </div>
                );
              })}

              {/* <div className="col-md-3 col-sm-6 mb-3">
                <CustomCheckbox
                  style="mb-3"
                  id="acknowledgementReceipt"
                  label="Acknowledgement Receipt"
                  disabled={isEdit ? true : false}
                />
              </div> */}

              {/* <div className="col-md-3 col-sm-6 mb-3 ">
                <CustomCheckbox
                  style="mb-3"
                  id="customerFeedBack"
                  label="Customer FeedBack"
                  disabled={isEdit ? true : false}
                />
              </div> */}
            </div>

            {/* Upload Documents */}
            <div className="row">
              <div className="col-md-3 col-sm-6 mb-3">
                <div className="d-flex">
                  {/* <a 
                href={formContent?.wayBillOfOriginAgent} target="_blank" > */}
                  <CustomCheckbox
                    style="mb-3"
                    id="waybillOfOriginAgent"
                    label="Waybill of Origin Agent"
                    value={formContent?.wayBillOfOriginAgent}
                    path={""}
                  />
                  {/* </a> */}
                  {isEdit && (
                    <>
                      <label htmlFor="originUpload">
                        <img
                          src={upload}
                          height={18}
                          width={18}
                          className="ms-3 cursor-pointer"
                        />
                      </label>
                      <input
                        type="file"
                        hidden
                        name="originUploaddd"
                        id="originUpload"
                        onChange={(e) =>
                          uploadFile(e, "waybill of origin agent")
                        }
                      />
                    </>
                  )}
                </div>
                {formContent?.wayBillOfOriginAgent != "" && (
                  <>
                    <div
                      className="text-decoration-underline cursor-pointer d-flex align-items-center"
                      style={{ color: "#00aff0 " }}
                    >
                      <p
                        onClick={() => {
                          setSlides([
                            { src: Bucket + formContent?.wayBillOfOriginAgent },
                          ]);
                          setIndex(0);
                        }}
                        className="my-1"
                        style={{ wordBreak: "break-word" }}
                      >
                        {formContent?.wayBillOfOriginAgent}
                      </p>
                      <a
                        href={Bucket + formContent?.wayBillOfOriginAgent}
                        target="_blank"
                      >
                        {" "}
                        <img
                          src={downloadIcon}
                          height={18}
                          width={18}
                          className="ms-3 cursor-pointer"
                        />
                      </a>

                      {isEdit && (
                        <img
                          src={trashImg}
                          height={18}
                          width={18}
                          className="ms-2 cursor-pointer"
                          onClick={() => {
                            let keysToDelete = [...mediaKeysToRemove];
                            keysToDelete.push(
                              formContent?.wayBillOfOriginAgent
                            );
                            setMediaKeysToRemove(keysToDelete);

                            let values = { ...formContent };
                            values.wayBillOfOriginAgent = "";
                            setFormContent(values);
                          }}
                        />
                      )}
                    </div>
                    {/* <a href={formContent?.wayBillOfOriginAgent} target="_blank">
                    <img
                      src={Bucket + formContent?.wayBillOfOriginAgent}
                      height={116}
                      width={116}
                      style={{ borderRadius: "10px", marginLeft: "32px" }}
                      className=" mt-2"
                      alt={"way bill of origin agent upload..."}
                    />
                  </a> */}
                  </>
                )}
              </div>
              {/* <CustomCheckbox
                  style="mb-3"
                  id="insuranceCertificate"
                  label="Insurance Certificate"
                /> */}
              {/* <CustomCheckbox
                  style="mb-3"
                  id="customerFeedbackReport"
                  label="Customer Feedback Report"
                /> */}
              <div className="col-md-3 col-sm-6 mb-3">
                <div className="d-flex">
                  <CustomCheckbox
                    style="mb-3"
                    id="waybillOfBooker"
                    label="Waybill of Booker"
                    value={formContent?.wayBillOfBooker}
                    path={""}
                  />
                  {isEdit && (
                    <>
                      <label htmlFor="bookerUpload">
                        <img
                          src={upload}
                          height={18}
                          width={18}
                          className="ms-3 cursor-pointer"
                        />
                      </label>
                      <input
                        type="file"
                        hidden
                        name="bookerUpload"
                        id="bookerUpload"
                        onChange={(e) => uploadFile(e, "waybill of booker")}
                      />
                    </>
                  )}
                </div>
                {formContent?.wayBillOfBooker != "" && (
                  <>
                    <div
                      className="text-decoration-underline cursor-pointer d-flex align-items-center"
                      style={{ color: "#00aff0 " }}
                    >
                      <p
                        onClick={() => {
                          setSlides([
                            { src: Bucket + formContent?.wayBillOfBooker },
                          ]);
                          setIndex(0);
                        }}
                        className="my-1"
                        style={{ wordBreak: "break-word" }}
                      >
                        {truncateFileName(formContent?.wayBillOfBooker, 50)}
                      </p>
                      <a
                        href={Bucket + formContent?.wayBillOfBooker}
                        target="_blank"
                      >
                        {" "}
                        <img
                          src={downloadIcon}
                          height={18}
                          width={18}
                          className="ms-3 cursor-pointer"
                        />
                      </a>

                      {isEdit && (
                        <img
                          src={trashImg}
                          height={18}
                          width={18}
                          className="ms-2 cursor-pointer"
                          onClick={() => {
                            let keysToDelete = [...mediaKeysToRemove];
                            keysToDelete.push(formContent?.wayBillOfBooker);
                            setMediaKeysToRemove(keysToDelete);

                            let values = { ...formContent };
                            values.wayBillOfBooker = "";
                            setFormContent(values);
                          }}
                        />
                      )}
                    </div>
                    {/* <a href={formContent?.wayBillOfBooker} target="_blank">
                    <img
                      src={Bucket + formContent?.wayBillOfBooker}
                      height={116}
                      width={116}
                      style={{ borderRadius: "10px", marginLeft: "32px" }}
                      className="mt-2"
                      alt={"way bill of booker upload..."}
                    />
                  </a> */}
                  </>
                )}
              </div>
              {/* <CustomCheckbox
                  style="mb-3"
                  id="packingListInventory"
                  label="Packing List Inventory"
                /> */}
              {/* <CustomCheckbox
                  style="mb-3"
                  id="confirmationOfDelivery"
                  label="Confirmation of Delivery"
                /> */}

              <div className="col-md-3 col-sm-6 mb-3">
                <div className="d-flex">
                  <CustomCheckbox
                    style="mb-3"
                    id="oceanBillOfLoading"
                    label="Bill of Lading"
                    value={formContent?.oceanBillOfLoading}
                    path={""}
                  />
                  {isEdit && (
                    <>
                      <label htmlFor="oceanUpload">
                        <img
                          src={upload}
                          height={18}
                          width={18}
                          className="ms-3 cursor-pointer"
                        />
                      </label>
                      <input
                        type="file"
                        hidden
                        name="oceanUpload"
                        id="oceanUpload"
                        onChange={(e) => uploadFile(e, "oceanbill of loading")}
                      />
                    </>
                  )}
                </div>
                {formContent?.oceanBillOfLoading != "" && (
                  <>
                    <div
                      className="text-decoration-underline cursor-pointer d-flex align-items-center"
                      style={{ color: "#00aff0 " }}
                    >
                      <p
                        onClick={() => {
                          setSlides([
                            { src: Bucket + formContent?.oceanBillOfLoading },
                          ]);
                          setIndex(0);
                        }}
                        className="my-1"
                        style={{ wordBreak: "break-word" }}
                      >
                        {truncateFileName(formContent?.oceanBillOfLoading, 50)}
                      </p>
                      <a
                        href={Bucket + formContent?.oceanBillOfLoading}
                        target="_blank"
                      >
                        {" "}
                        <img
                          src={downloadIcon}
                          height={18}
                          width={18}
                          className="ms-3 cursor-pointer"
                        />
                      </a>

                      {isEdit && (
                        <img
                          src={trashImg}
                          height={18}
                          width={18}
                          className="ms-2 cursor-pointer"
                          onClick={() => {
                            let keysToDelete = [...mediaKeysToRemove];
                            keysToDelete.push(formContent?.oceanBillOfLoading);
                            setMediaKeysToRemove(keysToDelete);

                            let values = { ...formContent };
                            values.oceanBillOfLoading = "";
                            setFormContent(values);
                          }}
                        />
                      )}
                    </div>

                    {/* <a href={formContent?.oceanBillOfLoading} target="_blank">
                    <img
                      src={Bucket + formContent?.oceanBillOfLoading}
                      height={116}
                      width={116}
                      style={{ borderRadius: "10px", marginLeft: "32px" }}
                      className="mt-2"
                      alt={"ocean bill of loading upload..."}
                    />
                  </a> */}
                  </>
                )}
              </div>
              {/* <CustomCheckbox style="mb-3" id="Worksheet" label="Worksheet" /> */}
              {/* <CustomCheckbox
                  style="mb-3"
                  id="crewInstructionForm "
                  label="Crew Instruction Form "
                /> */}

              <div className="col-md-3 col-sm-6 mb-3">
                <div className="d-flex">
                  <CustomCheckbox
                    style="mb-3"
                    id="airWayBill"
                    label="Air Way Bill"
                    value={formContent?.airWayBill}
                    path={""}
                  />
                  {isEdit && (
                    <>
                      <label htmlFor="airUpload">
                        <img
                          src={upload}
                          height={18}
                          width={18}
                          className="ms-3 cursor-pointer"
                        />
                      </label>
                      <input
                        type="file"
                        hidden
                        name="airUpload"
                        id="airUpload"
                        onChange={(e) => uploadFile(e, "air way bill")}
                      />
                    </>
                  )}
                </div>
                {formContent?.airWayBill != "" && (
                  <>
                    <div
                      className="text-decoration-underline cursor-pointer d-flex align-items-center"
                      style={{ color: "#00aff0 " }}
                    >
                      <p
                        onClick={() => {
                          setSlides([
                            { src: Bucket + formContent?.airWayBill },
                          ]);
                          setIndex(0);
                        }}
                        className="my-1"
                        style={{ wordBreak: "break-word" }}
                      >
                        {truncateFileName(formContent?.airWayBill, 50)}
                      </p>
                      <a
                        href={Bucket + formContent?.airWayBill}
                        target="_blank"
                      >
                        {" "}
                        <img
                          src={downloadIcon}
                          height={18}
                          width={18}
                          className="ms-3 cursor-pointer"
                        />
                      </a>

                      {isEdit && (
                        <img
                          src={trashImg}
                          height={18}
                          width={18}
                          className="ms-2 cursor-pointer"
                          onClick={() => {
                            let keysToDelete = [...mediaKeysToRemove];
                            keysToDelete.push(formContent?.airWayBill);
                            setMediaKeysToRemove(keysToDelete);

                            let values = { ...formContent };
                            values.airWayBill = "";
                            setFormContent(values);
                          }}
                        />
                      )}
                    </div>
                    {/* <a href={formContent?.airWayBill} target="_blank">
                    <img
                      src={Bucket + formContent?.airWayBill}
                      height={116}
                      width={116}
                      style={{ borderRadius: "10px", marginLeft: "32px" }}
                      className="mt-2"
                      alt={"air way bill upload..."}
                    />
                  </a> */}
                  </>
                )}
              </div>
            </div>

            {/* Other Documents */}
            <div className="row">
              <div className="col-md-12 col-sm-12 mb-3">
                <div className="d-flex">
                  {/* <a 
                href={formContent?.wayBillOfOriginAgent} target="_blank" > */}
                  <CustomCheckbox
                    style="mb-3"
                    id="otherdocuments"
                    label="Other Documents"
                    value={formContent?.otherDocuments?.length > 0}
                    path={""}
                  />
                  {/* </a> */}
                  {isEdit && (
                    <>
                      <label htmlFor="otherUpload">
                        <img
                          src={upload}
                          height={18}
                          width={18}
                          className="ms-3 cursor-pointer"
                        />
                      </label>
                      <input
                        type="file"
                        hidden
                        name="otherUploaddd"
                        id="otherUpload"
                        onChange={(e) => uploadOtherFiles(e)}
                        multiple
                      />
                    </>
                  )}
                </div>
                <div className="">
                  {formContent?.otherDocuments != "" &&
                    formContent?.otherDocuments?.map((oth, ind) => {
                      return (
                        <>
                          <div
                            className="text-decoration-underline cursor-pointer d-flex align-items-center"
                            style={{ color: "#00aff0 " }}
                          >
                            <p
                              onClick={() => {
                                let values = [];

                                formContent?.otherDocuments?.map((ot, ind) => {
                                  values[ind] = {
                                    src: Bucket + ot,
                                  };
                                });
                                setSlides(values);

                                setIndex(ind);
                              }}
                              className="my-1"
                            >
                              {ind + 1} {"."}
                              {truncateFileName(oth, 50)}
                            </p>
                            <a href={Bucket + oth} target="_blank">
                              {" "}
                              <img
                                src={downloadIcon}
                                height={18}
                                width={18}
                                className="ms-3 cursor-pointer"
                              />
                            </a>
                            {/* <img
                              src={Bucket + oth}
                              height={116}
                              width={116}
                              style={{
                                borderRadius: "10px",
                                marginLeft: "32px",
                              }}
                              className="my-2"
                              alt={"Other documents..."}
                              onClick={() => setIndex(ind)}
                            /> */}
                            {/* </a> */}
                            {isEdit && (
                              <img
                                src={trashImg}
                                height={18}
                                width={18}
                                className="ms-2 cursor-pointer"
                                onClick={() => {
                                  let values = { ...formContent };
                                  values.otherDocuments.splice(ind, 1);
                                  setFormContent(values);

                                  let keysToDelete = [...mediaKeysToRemove];
                                  keysToDelete.push(oth);
                                  setMediaKeysToRemove(keysToDelete);
                                }}
                              />
                            )}
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </Typography>
        {/* /checkboxes*/}
        {/* Remarks */}
        <div className="mt-4 pe-1 mb-5">
          <Typography variant="body1" className="text-start" gutterBottom>
            <DynamicText
              label="Remarks (If Any):"
              value={formContent?.remarks}
              isEdit={isEdit}
              onChange={(e) => {
                let values = { ...formContent };
                values.remarks = e.target.value;
                setFormContent(values);
              }}
            />
          </Typography>
        </div>
        {/* /Remarks */}
        <Snackbar
          className="no-print"
          open={openSnackbar}
          autoHideDuration={6000} // Adjust the duration as needed
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={
              snackbarMessage?.includes("successful") ? "success" : "error"
            }
            onClose={handleCloseSnackbar}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </MainTemplate>

      <LoadingModal open={open} handleClose={handleClose} />

      <Lightbox
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        slides={slides}
        carousel={{ finite: slides.length <= 1 }}
        render={{
          buttonPrev: slides.length <= 1 ? () => null : undefined,
          buttonNext: slides.length <= 1 ? () => null : undefined,
        }}
      />
    </>
  );
};
export default MoveCheckList;
