import React, { useEffect, useState } from "react";
import {
  updateFormContentPreview,
  getFormContentPreview,
  imageUploadClientPreview,
} from "../../../../api/services/services";
import { Button, Snackbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import Input from "../../../atoms/Input";
import ContactDetailField from "../../../molecules/ContactDetailField";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useParams } from "react-router-dom";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import html2pdf from "html2pdf.js";
import { useLocation } from "react-router-dom";

const ShippingInstructionsDownload = ({ formContent, surveyData }) => {
  const [formContentId, setFormContentId] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // 11th, 12th, 13th, etc.
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  // Helper function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const ordinalSuffix = getOrdinalSuffix(day);

    return `${day}${ordinalSuffix} ${month}, ${year}`;
  };

  return (
    <>
      <div className="mx-4">
        {/* <MainTemplate> */}
        <div>
          <div className="d-flex align-items-center justify-content-center">
            <p className="ps-0 mb-0 head-top">Shipping Instructions</p>
          </div>
        </div>

        {/*  Details */}
        <div className="mt-2 pe-1 mb-3">
          <Typography variant="body1" className="text-start" gutterBottom>
            <div>
              <Typography variant="body1" gutterBottom className=" ps-1">
                {/* <ContactDetailField
                    label="Air Shipment"
                    isEdit={isEdit}
                    type="text"
                    minWidth={"150px"}
                    value={formContent?.airShipment}
                    
                  /> */}
                {formContent?.airShipment?.trim()?.length > 0 && (
                  <div className="d-flex">
                    <p
                      className="p-word mb-1 text-start"
                      style={{ minWidth: "100px", fontSize: "11px" }}
                    >
                      <b>Mode of Shipment:</b>
                    </p>

                    <p className="p-word mb-1 text-start">
                      <span className="ms-1 ">
                        {" "}
                        {formContent?.airShipment
                          ? formContent?.airShipment
                          : "-"}
                      </span>
                    </p>
                  </div>
                )}
                {/* <ContactDetailField
                    label="Date"
                    isEdit={isEdit}
                    type="date"
                    minWidth={"150px"}
                    value={formContent?.date}
                    
                  /> */}
                {formContent?.date?.trim()?.length > 0 && (
                  <div className="d-flex">
                    <p
                      className="p-word mb-0 text-start"
                      style={{ minWidth: "100px", fontSize: "11px" }}
                    >
                      <b>Date:</b>
                    </p>

                    <p className="p-word mb-0 text-start">
                      <span className="ms-1 ">
                        {" "}
                        {formContent?.date
                          ? formatDate(formContent?.date)
                          : "-"}
                      </span>
                    </p>
                  </div>
                )}
              </Typography>
            </div>
          </Typography>
        </div>
        {/* / Details */}
        {/* table */}
        <TableContainer component={Paper} className="mb-0">
          <Table>
            <TableBody>
              {/* Shipper */}
              {formContent?.shipper?.trim()?.length > 0 && (
                <TableRow>
                  <TableCell
                    className="border p-1 w-25"
                    style={{ fontSize: "10px" }}
                  >
                    <b>Shipper</b>
                  </TableCell>
                  <TableCell
                    className="border p-1 w-75 text-start"
                    style={{ fontSize: "10px" }}
                  >
                    {isEdit ? (
                      <Input
                        id={"shipper"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Shipper"}
                        value={formContent?.shipper}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>{formContent?.shipper ? formContent?.shipper : "-"}</>
                    )}
                  </TableCell>
                </TableRow>
              )}
              {/* Consignee */}
              {formContent?.consignee?.trim()?.length > 0 && (
                <TableRow>
                  <TableCell
                    className="border p-1 w-25"
                    style={{ fontSize: "10px" }}
                  >
                    <b>Consignee</b>
                  </TableCell>
                  <TableCell
                    className="border p-1 w-75 text-start"
                    style={{ fontSize: "10px" }}
                  >
                    {isEdit ? (
                      <Input
                        id={"consignee"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Consignee"}
                        value={formContent?.consignee}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.consignee ? formContent?.consignee : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              )}

              {/* Notify */}
              {formContent?.notify?.trim()?.length > 0 && (
                <TableRow>
                  <TableCell
                    className="border p-1 w-25"
                    style={{ fontSize: "10px" }}
                  >
                    <b>Notify</b>
                  </TableCell>
                  <TableCell
                    className="border p-1 w-75 text-start"
                    style={{ fontSize: "10px" }}
                  >
                    {isEdit ? (
                      <Input
                        id={"notify"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Notify"}
                        value={formContent?.notify}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>{formContent?.notify ? formContent?.notify : "-"}</>
                    )}
                  </TableCell>
                </TableRow>
              )}
              {/* Description */}
              {formContent?.description?.trim()?.length > 0 && (
                <TableRow>
                  <TableCell
                    className="border p-1 w-25"
                    style={{ fontSize: "10px" }}
                  >
                    <b>Description</b>
                  </TableCell>
                  <TableCell
                    className="border p-1 w-75 text-start"
                    style={{ fontSize: "10px" }}
                  >
                    {isEdit ? (
                      <Input
                        id={"description"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Description"}
                        value={formContent?.description}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.description
                          ? formContent?.description
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              )}
              {/* Volume/Gross Weight */}
              {formContent?.grossWeight?.trim()?.length > 0 && (
                <TableRow>
                  <TableCell
                    className="border p-1 w-25"
                    style={{ fontSize: "10px" }}
                  >
                    <b>Volume/Gross Weight</b>
                  </TableCell>
                  <TableCell
                    className="border p-1 w-75 text-start"
                    style={{ fontSize: "10px" }}
                  >
                    {isEdit ? (
                      <Input
                        id={"gross-weight"}
                        type="number"
                        style="full-border-field bg-app"
                        label={"Gross Weight"}
                        value={formContent?.grossWeight}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.grossWeight
                          ? formContent?.grossWeight
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              )}
              {/* Port of Discharge */}
              {formContent?.portOfDischarge?.trim()?.length > 0 && (
                <TableRow>
                  <TableCell
                    className="border p-1 w-25"
                    style={{ fontSize: "10px" }}
                  >
                    <b>Port of Discharge</b>
                  </TableCell>
                  <TableCell
                    className="border p-1 w-75 text-start"
                    style={{ fontSize: "10px" }}
                  >
                    {isEdit ? (
                      <Input
                        id={"port-of-discharge"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Port of Discharge"}
                        value={formContent?.portOfDischarge}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.portOfDischarge
                          ? formContent?.portOfDischarge
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              )}

              {/* Destination Country */}
              {formContent?.destinationCountry?.trim()?.length > 0 && (
                <TableRow>
                  <TableCell
                    className="border p-1 w-25"
                    style={{ fontSize: "10px" }}
                  >
                    <b>Destination Country</b>
                  </TableCell>
                  <TableCell
                    className="border p-1 w-75 text-start"
                    style={{ fontSize: "10px" }}
                  >
                    {isEdit ? (
                      <Input
                        id={"country"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Destination Country"}
                        value={formContent?.destinationCountry}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.destinationCountry
                          ? formContent?.destinationCountry
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              )}

              {/* Remarks */}
              {formContent?.remarks?.trim()?.length > 0 && (
                <TableRow>
                  <TableCell
                    className="border p-1 w-25"
                    style={{ fontSize: "10px" }}
                  >
                    <b>Remarks</b>
                  </TableCell>
                  <TableCell
                    className="border p-1 w-75 text-start"
                    style={{ fontSize: "10px" }}
                  >
                    {isEdit ? (
                      <Input
                        id={"remarks"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Remarks"}
                        value={formContent?.remarks}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>{formContent?.remarks ? formContent?.remarks : "-"}</>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* /table */}

        {/* </MainTemplate> */}
      </div>

      {/* snackbar */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={
            snackbarMessage?.includes("successful") ? "success" : "error"
          }
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      {/* /snackbar */}
    </>
  );
};
export default ShippingInstructionsDownload;
