import React, { useEffect, useState } from "react";
import {
  getFormContent,
  getSingleSurvey,
  updateFormContent,
  surveyResultPreview,
  sharePreviewLink,
  addFormContent,
  imageUpload,
} from "../../../api/services/services";
import backArrow from "../../../imgs/BackArrow.svg";
import MainTemplate from "../../templates/MainTemplate";
// import "./style.css";
import { useNavigate } from "react-router-dom";
import { Button, Snackbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import { Bucket } from "../../../api/config/apiConfig";
import ContactDetailField from "../../molecules/ContactDetailField";
import DynamicText from "../../molecules/DynamicText";
import DynamicCheckbox from "../../molecules/DynamicCheckbox";
import { useParams } from "react-router-dom";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import html2pdf from "html2pdf.js";
import PackingRequestPageDownload from "./ClientSide/download";
import PrintComponent from "../PrintComponent";
import editIcn from "../../../imgs/edit.png";
import trashIcn from "../../../imgs/trash.png";
import LoadingModal from "../../molecules/LoadingModal";
import moment from "moment";
import MySignatureCanvas from "../../molecules/signature_canvas";
import ShareWithClientsModal from "../../molecules/ShareWithClients";
import { sendSuperAdminVerificationForEdit } from "../PrebidAgreement/creation/service";
import SuperAdminVerifationModal from "../PrebidAgreement/creation/super_admin_verification_modal";

const PackingRequestPage = () => {
  const { surveyId, id, flg } = useParams();
  const [surveyData, setSurveyData] = useState(null);
  const navigate = useNavigate();

  const [formContentId, setFormContentId] = useState("");
  const [isEdit, setIsEdit] = useState(flg == "1" ? true : false);

  const [isLoading, setIsLoading] = useState(false);
  const [formContent, setFormContent] = useState({
    customerName: "",
    date: "",
    packingAddress: "",
    packingDate: "",
    packingTime: "",
    permissions: false,
    adviseRequirement: "",
    firstPriority: "",
    secondPriority: "",
    thirdPriority: "",
    packUpSuitable: false,
    suggestions: "",
    officeInstructions: "",
    clientSign: "",
    clientEmail: "",
    moveCordinatorSign: "",
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [mediaKeysToSend, setMediaKeysToSend] = useState([]);
  const [mediaKeysToRemove, setMediaKeysToRemove] = useState([]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isSignCanvas, setSignCanvas] = useState(false);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  //
  const getSurveyPreview = () => {
    surveyResultPreview(surveyId)
      .then((response) => {
        setSurveyData(response?.data?.data?.surveyDetail);
        let values = { ...formContent };
        values.packingAddress = `${response?.data?.data?.surveyDetail?.loadPlaceStAddress}, ${response?.data?.data?.surveyDetail?.loadPlaceCity}, ${response?.data?.data?.surveyDetail?.loadPlaceCountry}`;
        values.customerName = `${response?.data?.data?.surveyDetail?.name}`;
        values.clientEmail = `${response?.data?.data?.surveyDetail?.email}`;
        setFormContent(values);
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }
      });
  };

  //
  const getSurvey = () => {
    getSingleSurvey(surveyId)
      .then((response) => {
        if (response?.data?.data?.status == "submitted") getSurveyPreview();
        else {
          setSurveyData(response?.data?.data);
          let values = { ...formContent };
          values.packingAddress = `${response?.data?.data?.loadPlaceStAddress}, ${response?.data?.data?.loadPlaceCity}, ${response?.data?.data?.loadPlaceCountry}`;
          values.customerName = `${response?.data?.data?.name}`;
          values.clientEmail = `${response?.data?.data?.email}`;
          setFormContent(values);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }
      });
  };

  //
  const addContent = () => {
    setIsLoading(true);
    const body = {
      formId: id,
      surveyId: surveyId,
      content: formContent,
      mediaKeys: mediaKeysToSend,
    };
    addFormContent(body)
      .then(() => {
        setSnackbarMessage("Packing Request Form has been added successfully!");
        setOpenSnackbar(true);
        navigate(-1);

        setIsLoading(false);
        setMediaKeysToSend([]);
        setMediaKeysToRemove([]);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  //
  const updateContent = () => {
    setIsLoading(true);
    const body = {
      formId: id,
      surveyId: surveyId,
      content: formContent,
      mediaKeys: mediaKeysToSend,
      removedMediaKeys: mediaKeysToRemove,
    };
    updateFormContent(body, formContentId)
      .then((res) => {
        setSnackbarMessage(
          "Packing Request Form has been updated successfully!"
        );
        setOpenSnackbar(true);
        getContent();
        setIsEdit(false);

        setIsLoading(false);
        setMediaKeysToSend([]);
        setMediaKeysToRemove([]);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  //
  const getContent = () => {
    setIsLoading(true);
    setMediaKeysToSend([]);
    setMediaKeysToRemove([]);
    getFormContent(id, surveyId)
      .then((res) => {
        setFormContentId(res.data.data.uuid);
        setFormContent(res.data.data.content);
        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (flg == "1") getSurvey();
    else getContent();
  }, []);

  const [show, setShow] = useState(false);
  const options = {
    // resolution: Resolution.HIGH,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      format: "letter",
      // default is 'portrait'
      orientation: "portrait",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/png",
      qualityRatio: 1,
    },
    overrides: {
      pdf: {
        compress: true,
      },
    },
  };

  //
  const generatePdf = () => {
    setShow(true);

    setTimeout(() => {
      const content = document.getElementById("content-to-convert"); // Replace with the ID of the element you want to convert to PDF

      const currentDateTime = new Date();
      const filename = `survey_${currentDateTime.toISOString()}.pdf`;

      const pdfOptions = {
        margin: 5,
        filename: filename,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
        }, // Set the page size here
      };

      let temp = html2pdf().from(content).set(pdfOptions).outputPdf();
      temp.save(filename);

      setShow(false);
    }, 3500);
  };

  const [downloadText, setDownloadText] = useState("download");

  const getTargetElement = () => document.getElementById("content-id");

  const [isShare, setIsShare] = useState(false);

  //
  const shareWithClient = (data) => {
    let ems = [];
    let emsInd = 0;
    data.map((dt) => {
      if (dt.trim().length != 0) {
        ems[emsInd] = dt;
        emsInd++;
      }
    });

    setIsLoading(true);
    const body = {
      surveyId: surveyId,
      formId: id,
      emails: ems,
      formRoute: "packing-request-form-preview",
    };
    sharePreviewLink(body)
      .then(() => {
        setSnackbarMessage(
          "Packing Request Form has been shared with client successfully!"
        );
        setOpenSnackbar(true);

        setIsLoading(false);
        setIsShare(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  const printAction = () => {
    const PAGE_HEIGHT = 1045;
    const printElement = document.getElementById("print-component");
    if (printElement) {
      printElement.classList.add("temp-class-for-height");
      const height = printElement.clientHeight - 500;
      const numberOfPage = Math.ceil(height / PAGE_HEIGHT);
      const heightWithSingleHeader = numberOfPage * PAGE_HEIGHT;
      let requiredHeight = heightWithSingleHeader;
      if (numberOfPage > 1) {
        const headerHeight =
          printElement.getElementsByTagName("thead")?.[0]?.clientHeight;
        const footerHeight =
          printElement.getElementsByTagName("tfoot")?.[0]?.clientHeight;
        requiredHeight -= (numberOfPage - 1) * (headerHeight + footerHeight);
      }
      printElement.style.height = `${requiredHeight}px`;
      printElement.classList.remove("temp-class-for-height");
    }
    window.print();
    if (printElement) {
      printElement.style.height = `auto`;
    }
  };

  const [uploadText, setUploadText] = useState("Upload Sign");

  //
  const uploadFile = async (file) => {
    setIsLoading(true);

    handleOpen();
    setUploadText("Uploading...");

    const fd = new FormData();
    fd.append("image", file);

    imageUpload(fd)
      .then((res) => {
        let values = { ...formContent };
        if (formContent.moveCordinatorSign?.trim()?.length != 0) {
          let dlt = [...mediaKeysToRemove];
          dlt.push(formContent.moveCordinatorSign);
          setMediaKeysToRemove(dlt);
        }

        values.moveCordinatorSign = res?.data?.data?.key;

        setFormContent(values);

        let md = [...mediaKeysToSend];
        md.push(res?.data?.data?.key);
        setMediaKeysToSend(md);

        setIsLoading(false);
        setUploadText("Upload Sign");
        handleClose();
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setUploadText("Upload Sign");

        setIsLoading(false);
        handleClose();
      });
  };

  const [isVerified, setIsVerified] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);

  const sendEditRequestToAdmin = () => {
    setIsEditLoading(true);
    sendSuperAdminVerificationForEdit()
      .then((response) => {
        setIsVerified(true);
        setIsEditLoading(false);
      })
      .catch((error) => {
        setIsVerified(false);
        setIsEditLoading(false);
      });
  };

  return (
    <>
      <MainTemplate>
        <div className="no-print">
          <div className="d-block d-sm-flex align-items-center py-4">
            <div className="d-flex align-items-center ">
              <div
                className="light-blue-color back-bg cursor-pointer"
                onClick={() => navigate(-1)}
              >
                <img src={backArrow} height="24" width="24" />{" "}
              </div>
              <div>
                <p className="main-head ps-3 mb-0">Packing Request Form</p>
              </div>
            </div>
            <div className="d-flex ms-auto justify-content-center mt-2 mt-sm-0">
              {/*Edit Button */}
              {!isEdit && (
                <Button
                  className="active-btn"
                  onClick={() => {
                    sendEditRequestToAdmin();
                  }}
                  disabled={isEditLoading}
                >
                  Edit
                </Button>
              )}

              {/*Share Button */}
              {!isEdit && (
                <Button
                  className="active-btn ms-3"
                  onClick={() => {
                    setIsShare(true);
                  }}
                  disabled={isLoading}
                >
                  Share
                </Button>
              )}

              {/* Cancel button */}
              {isEdit && flg == "2" && (
                <Button
                  className="active-btn ms-3"
                  onClick={() => {
                    getContent();
                    setIsEdit(false);
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
              )}

              {/* Save Button */}
              {isEdit && (
                <Button
                  className="active-btn ms-3"
                  onClick={() => {
                    if (flg == "1") addContent();
                    else updateContent();
                  }}
                  disabled={isLoading}
                >
                  Save
                </Button>
              )}

              {/* Download Button */}
              {!isEdit && (
                <Button
                  className="active-btn ms-3 px-0"
                  onClick={() => {
                    setIsLoading(true);
                    // printAction();
                    var tempTitle = document.title;
                    document.title = `Packing Request-${
                      formContent?.customerName
                    }-${moment(new Date()).format("DD.MM.YYYY")}`;

                    window.print();
                    document.title = tempTitle;
                    setIsLoading(false);
                  }}
                  disabled={isLoading}
                >
                  Save as PDF
                </Button>
              )}
            </div>
          </div>
        </div>
        {/* customer detail*/}
        <div className="no-print">
          <div className="contact-detail d-flex mt-2 ">
            {/* left col */}
            <div className={`left-col w-50 text-start`}>
              <Typography variant="body1" gutterBottom>
                <ContactDetailField
                  label="Customer Name:"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"170px"}
                  value={formContent?.customerName}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.customerName = e.target.value;
                    setFormContent(values);
                  }}
                />
                <ContactDetailField
                  label="Date"
                  isEdit={isEdit}
                  type="date"
                  minWidth={"170px"}
                  value={formContent?.date}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.date = e.target.value;
                    setFormContent(values);
                  }}
                />
              </Typography>
            </div>
          </div>
        </div>
        {/* /customer detail */}
        {/* Packing Address */}
        <div className="mt-4 pe-1 no-print">
          <Typography variant="body1" className="text-start" gutterBottom>
            <DynamicText
              label="Packing Address"
              textAreaLabel="Packing Address"
              isEdit={isEdit}
              value={formContent?.packingAddress}
              onChange={(e) => {
                let values = { ...formContent };
                values.packingAddress = e.target.value;
                setFormContent(values);
              }}
            />
          </Typography>
        </div>
        {/* /Packing Address */}
        {/* dates */}

        <div className="no-print">
          <div className="mt-4 pe-1 d-flex justify-content-start">
            <p className="mb-2">
              <b>
                Which dates and time suits you to start packing of your goods?
              </b>
            </p>
          </div>
          <div className="mt-0 pe-1 d-flex">
            {isEdit ? (
              <>
                <div
                  className={`left-col ${
                    !isEdit ? " w-50" : "w-50"
                  } text-start`}
                >
                  <Typography
                    variant="body1"
                    gutterBottom
                    style={{ width: "80%" }}
                  >
                    <ContactDetailField
                      label="Packing Date"
                      isEdit={isEdit}
                      type="date"
                      value={formContent?.packingDate}
                      onChange={(e) => {
                        let values = { ...formContent };
                        values.packingDate = e.target.value;
                        setFormContent(values);
                      }}
                      minWidth={"125px"}
                    />
                  </Typography>
                </div>
                <div
                  className={`right-col ${
                    !isEdit ? "w-50 pe-5 " : "w-50 pe-2"
                  }  d-flex flex-column align-items-end justify-content-end text-end`}
                >
                  <Typography
                    variant="body1"
                    className="text-start "
                    gutterBottom
                    style={{ width: "80%" }}
                  >
                    <ContactDetailField
                      label="Packing Time"
                      isEdit={isEdit}
                      type="time"
                      value={formContent?.packingTime}
                      onChange={(e) => {
                        let values = { ...formContent };
                        values.packingTime = e.target.value;
                        setFormContent(values);
                      }}
                      minWidth={"125px"}
                    />
                  </Typography>
                </div>
              </>
            ) : (
              <>
                <p className="mb-1">
                  {formContent?.packingDate} {formContent?.packingTime}
                </p>
              </>
            )}
          </div>
        </div>
        {/* /dates */}
        {/* permissions */}
        <div className="mt-4 pe-1 no-print">
          <Typography variant="body1" className="text-start" gutterBottom>
            <DynamicCheckbox
              label="Is there any vehicle/crew permission/security access/permit required for your residence?"
              checkId1="check1"
              checkLabel1="Yes"
              checkId2="check2"
              checkLabel2="No"
              value1={formContent?.permissions}
              value2={formContent?.permissions}
              onChange1={(e) => {
                let values = { ...formContent };
                values.permissions = true;
                setFormContent(values);
              }}
              onChange2={(e) => {
                let values = { ...formContent };
                values.permissions = false;
                setFormContent(values);
              }}
              disabled={!isEdit ? true : false}
            />
          </Typography>
        </div>
        {/* /permissions */}
        {/* requirement */}
        {formContent?.permissions && (
          <div className="mt-4 pe-1 no-print">
            <Typography variant="body1" className="text-start" gutterBottom>
              <DynamicText
                label="If yes, please advise requirement:"
                textAreaLabel="Lorem ipsum"
                isEdit={isEdit}
                value={formContent?.adviseRequirement}
                onChange={(e) => {
                  let values = { ...formContent };
                  values.adviseRequirement = e.target.value;
                  setFormContent(values);
                }}
              />
            </Typography>
          </div>
        )}
        {/* /requirement */}
        {/* 1st priority */}
        <div className="mt-4 pe-1 no-print">
          <Typography variant="body1" className="text-start" gutterBottom>
            <DynamicText
              label="1st priority items to pack according to your need or requirement:"
              textAreaLabel="1st priority items..."
              isEdit={isEdit}
              value={formContent?.firstPriority}
              onChange={(e) => {
                let values = { ...formContent };
                values.firstPriority = e.target.value;
                setFormContent(values);
              }}
            />
          </Typography>
        </div>
        {/* /1st priority */}
        {/* 2nd priority */}
        <div className="mt-4 pe-1 no-print">
          <Typography variant="body1" className="text-start" gutterBottom>
            <DynamicText
              label="2nd priority items to pack according to your need or requirement:"
              textAreaLabel="2nd priority items... "
              isEdit={isEdit}
              value={formContent?.secondPriority}
              onChange={(e) => {
                let values = { ...formContent };
                values.secondPriority = e.target.value;
                setFormContent(values);
              }}
            />
          </Typography>
        </div>
        {/* /2nd priority */}
        {/* 3rd priority */}
        <div className="mt-4 pe-1 no-print">
          <Typography variant="body1" className="text-start" gutterBottom>
            <DynamicText
              label="3rd priority items to pack according to your need or requirement:"
              textAreaLabel="3rd priority items..."
              isEdit={isEdit}
              value={formContent?.thirdPriority}
              onChange={(e) => {
                let values = { ...formContent };
                values.thirdPriority = e.target.value;
                setFormContent(values);
              }}
            />
          </Typography>
        </div>
        {/* /3rd priority */}
        {/* marked/labeled items */}
        <div className="mt-4 pe-1 no-print">
          <Typography variant="body1" className="text-start" gutterBottom>
            <DynamicCheckbox
              label="Residence’s rooms or items marked/labeled with suitable Info/Numbers/Colors are not going to pack up:"
              checkId1="labeled_items_yes"
              checkLabel1="Yes"
              checkId2="labeled_items_no"
              checkLabel2="No"
              value1={formContent?.packUpSuitable}
              value2={formContent?.packUpSuitable}
              onChange1={(e) => {
                let values = { ...formContent };
                values.packUpSuitable = true;
                setFormContent(values);
              }}
              onChange2={(e) => {
                let values = { ...formContent };
                values.packUpSuitable = false;
                setFormContent(values);
              }}
              disabled={!isEdit ? true : false}
            />
          </Typography>
        </div>
        {/* /marked/labeled items  */}
        {/* Customer Suggestions */}
        <div className="mt-4 pe-1 no-print">
          <Typography variant="body1" className="text-start" gutterBottom>
            <DynamicText
              label="Customer Suggestions/Remarks if any:"
              textAreaLabel="Customer Suggestions..."
              isEdit={isEdit}
              value={formContent?.suggestions}
              onChange={(e) => {
                let values = { ...formContent };
                values.suggestions = e.target.value;
                setFormContent(values);
              }}
            />
          </Typography>
        </div>
        {/* /Customer Suggestions */}
        {/* Office Instructions/remarks */}
        <div className="mt-4 pe-1 mb-5 no-print">
          <Typography variant="body1" className="text-start" gutterBottom>
            <DynamicText
              label="Office Instructions/remarks:"
              textAreaLabel="Office Instructions/remarks..."
              isEdit={isEdit}
              value={formContent?.officeInstructions}
              onChange={(e) => {
                let values = { ...formContent };
                values.officeInstructions = e.target.value;
                setFormContent(values);
              }}
            />
          </Typography>
        </div>
        {/* /Office Instructions/remarks */}

        {/* Signatures */}

        <Row className="my-5 pt-5 no-print">
          <Col sm={6} className="mt-5 pt-5">
            <div
              style={{ height: "90px" }}
              className="d-flex justify-content-center align-items-end"
            >
              {formContent?.moveCordinatorSign?.trim()?.length == 0 ? (
                <>
                  {isEdit && (
                    <div className="d-flex justify-content-center align-items-end">
                      <label
                        htmlFor="uploadSign"
                        className="active-btn ms-2 w-200 d-flex align-items-center justify-content-center cursor-pointer btn"
                      >
                        {uploadText}
                      </label>
                      <input
                        type="button"
                        hidden
                        id="uploadSign"
                        name="uploadSign"
                        onClick={() => {
                          setSignCanvas(true);
                        }}
                      />
                    </div>
                  )}
                </>
              ) : (
                <div className="d-flex align-items-start">
                  <img
                    src={`${Bucket}${formContent?.moveCordinatorSign}`}
                    height={80}
                    width={200}
                  />
                  {isEdit && (
                    <>
                      <label
                        htmlFor="editSign"
                        className=" ms-2  d-flex align-items-center justify-content-center cursor-pointer btn p-0"
                      >
                        <img
                          src={editIcn}
                          height={18}
                          width={18}
                          className="ms-2 cursor-pointer"
                        />
                      </label>
                      <input
                        type="file"
                        hidden
                        id="editSign"
                        name="uploadSign"
                        onChange={(e) => {
                          if (!isLoading) uploadFile(e);
                        }}
                      />
                      <img
                        src={trashIcn}
                        height={18}
                        width={18}
                        className="ms-2 cursor-pointer"
                        onClick={() => {
                          let keysToDelete = [...mediaKeysToRemove];
                          keysToDelete.push(formContent.moveCordinatorSign);
                          setMediaKeysToRemove(keysToDelete);

                          let values = { ...formContent };
                          values.moveCordinatorSign = "";
                          setFormContent(values);
                        }}
                      />
                    </>
                  )}
                </div>
              )}
            </div>

            <p>{"_______________________________"}</p>
            <p className="my-1">
              <b>{"Move Coordinator"}</b>
            </p>

            {""}
          </Col>
          <Col sm={6} className="mt-5 pt-5">
            <div
              style={{ height: "90px" }}
              className="d-flex justify-content-center align-items-end"
            >
              {formContent.clientSign != "" &&
                formContent.clientSign != undefined && (
                  <img
                    src={`${Bucket}${formContent.clientSign}`}
                    height={80}
                    width={200}
                  />
                )}
            </div>
            <p>{"_______________________________"}</p>
            <p className="my-1">
              <b>{"Customer/Authorized"}</b>
            </p>

            {""}
          </Col>
        </Row>

        <PrintComponent>
          <PackingRequestPageDownload
            id="content-id"
            surveyData={surveyData}
            formContent={formContent}
          />
        </PrintComponent>

        {/* Snackbar */}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000} // Adjust the duration as needed
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={
              snackbarMessage?.includes("successful") ? "success" : "error"
            }
            onClose={handleCloseSnackbar}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>

        <LoadingModal open={open} handleClose={handleClose} />

        {/* Add signature Modal */}
        {isSignCanvas && (
          <MySignatureCanvas
            open={isSignCanvas}
            onClose={() => {
              setSignCanvas(false);
            }}
            onSuccess={(file) => {
              if (!isLoading) uploadFile(file);
            }}
          />
        )}

        {isShare && (
          <ShareWithClientsModal
            open={isShare}
            onClose={() => {
              setIsShare(false);
            }}
            onSuccess={(emails) => {
              shareWithClient(emails);
            }}
          />
        )}

        {isVerified && (
          <SuperAdminVerifationModal
            open={isVerified}
            onClose={() => {
              setIsVerified(false);
            }}
            onSuccess={() => {
              setIsEdit(true);
              setIsVerified(false);
            }}
            onError={() => {
              // shareWithClient(emails);
              setSnackbarMessage("Invalid Confirm Pin");
              setOpenSnackbar(true);
            }}
          />
        )}
      </MainTemplate>
    </>
  );
};
export default PackingRequestPage;
