// SplashScreen

import React from 'react';
import './style.css'; // You can create a CSS file for styling
import logo from '../../../imgs/logo-final.png'

const SplashScreen = () => {
  return (
    <div id="splash-screen" className="splash-screen">
    <img
        src={logo}
        alt="Shezlon Logo"
        className='logo-shel d-none d-sm-flex'
      />
    <div className="loading-spinner"></div>
    </div>
  );
};

export default SplashScreen;
