import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import surveyIcon from "../../imgs/survey-icon.png";

const SingleCustomCard = ({
  primColor,
  secondColor,
  figureTotal,
  figureNew,
  figureAssigned,
  figureRejected,
  figureInProgress,
}) => {
  return (
    <Grid item xs={12}>
      <Card className="card">
        <CardContent className="" gutterBottom>
          <div style={{ height: "0px", textAlign: "-webkit-center" }}>
            <div
              className="content-ellipse p-1"
              style={{ transform: "translate(0px, -40px)" }}
            >
              <div className={`outer-ellipse p-1 mx-auto ${primColor}`}>
                <div className={`inner-ellipse ${secondColor}`}>
                  <img
                    src={surveyIcon}
                    height="26"
                    width="26"
                    className="m-1 text-center "
                  />
                </div>
              </div>
            </div>
          </div>
          <Typography className="card-header-single mt-4 d-flex " gutterBottom>
            <b style={{ color: "#898989" }}>Total Surveys </b>{" "}
            <p className="ms-auto mb-1">{figureTotal}</p>
          </Typography>

          <Typography className="card-header-single mt-1 d-flex " gutterBottom>
            <b style={{ color: "#898989" }}>New Surveys </b>{" "}
            <p className="ms-auto mb-1">{figureNew}</p>
          </Typography>

          <Typography className="card-header-single mt-1 d-flex " gutterBottom>
            <b style={{ color: "#898989" }}>Assigned Surveys </b>{" "}
            <p className="ms-auto mb-1">{figureAssigned}</p>
          </Typography>

          <Typography className="card-header-single mt-1 d-flex " gutterBottom>
            <b style={{ color: "#898989" }}>In Progress Surveys </b>{" "}
            <p className="ms-auto mb-1">{figureInProgress}</p>
          </Typography>

          <Typography className="card-header-single mt-1 d-flex " gutterBottom>
            <b style={{ color: "#898989" }}>Rejected Surveys </b>{" "}
            <p className="ms-auto mb-0">{figureRejected}</p>
          </Typography>

        </CardContent>
      </Card>
    </Grid>
  );
};

export default SingleCustomCard;
