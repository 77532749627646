// Header.js
import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, Typography, useScrollTrigger } from "@mui/material";
import userIcon from "../../../../src/imgs/userIcon.svg";
import downArrow from "../../../../src/imgs/downArrow.svg";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const Header = () => {
  const [showLogout, setShowLogout] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentUser, setCurrentUse] = useState(null)

  const logout = () => {
    localStorage.clear();
    window.location.pathname = '/login'
  }

    useEffect(() => {
      const userData = localStorage.getItem("shelUserData");
      if (userData) setCurrentUse(JSON.parse(userData))
    }, []);
  
  return (
    <AppBar
      position="sticky"
      className="bg-white no-print"
      style={{ boxShadow: "none", height: "70px" }}
    >
      <Toolbar>
        <div className="d-flex align-items-center ms-auto pt-3 p-sm-2 p-lg-0">
          <img src={userIcon} height="35" width="35" />
          <div className="text-dark mx-2">{currentUser?.name}</div>
          <img
            src={downArrow}
            height="24"
            width="24"
            className="cursor-pointer"
            onClick={() => setShowLogout(!showLogout)}
          />
        </div>
        <div className="logout-outer bg-white text-dark">
          {showLogout && (
            <p className="logout-inner mx-2 cursor-pointer" onClick={logout}>
              Log out
            </p>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header
