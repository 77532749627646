import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Snackbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import ContactDetailField from "../../../molecules/ContactDetailField";
import Input from "../../../atoms/Input";
import DynamicText from "../../../molecules/DynamicText";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import html2pdf from "html2pdf.js";
import { useParams } from "react-router-dom";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

const AcknowledgementReceiptDownload = ({ formContent, surveyData }) => {
  const { surveyId, id, flg } = useParams();
  const [isEdit, setIsEdit] = useState(flg == "1" ? true : false);

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // 11th, 12th, 13th, etc.
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  // Helper function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const ordinalSuffix = getOrdinalSuffix(day);

    return `${day}${ordinalSuffix} ${month}, ${year}`;
  };

  return (
    <div className="mx-4">
      {/* <MainTemplate> */}
      <div className="d-flex align-items-center justify-content-center">
        <p className="ps-0 mb-0 head-top">Acknowledgement Receipt</p>
      </div>
      {/* file detail*/}
      <div className="contact-detail d-flex mt-4">
        {/* left col */}
        <div
          className={`left-col ${!isEdit ? " w-75" : "w-50 pe-5"} text-start`}
        >
          <Typography variant="body1" className="text-start w-100" gutterBottom>
            {/* <ContactDetailField
                label="Date"
                isEdit={isEdit}
                type="date"
                wrapperStyle="justify-content-start"
                minWidth={"150px"}
                value={formContent?.date}
                onChange={(e) => {
                  let values = { ...formContent };
                  values.date = e.target.value;
                  setFormContent(values);
                }}
              /> */}

            <div className="d-flex">
              <p
                className="p-word mb-1 text-start"
                style={{ minWidth: "80px", fontSize: "11px" }}
              >
                <b>Date:</b>
              </p>

              <p className="p-word mb-1 text-start">
                <span className="ms-1 ">
                  {" "}
                  {formContent?.date ? formatDate(formContent?.date) : "-"}
                </span>
              </p>
            </div>

            {/* <ContactDetailField
                label="Received From"
                isEdit={isEdit}
                type="text"
                wrapperStyle="justify-content-start"
                minWidth={"150px"}
                value={formContent?.receivedFrom}
                onChange={(e) => {
                  let values = { ...formContent };
                  values.receivedFrom = e.target.value;
                  setFormContent(values);
                }}
              /> */}

            <div className="d-flex">
              <p
                className="p-word mb-1 text-start"
                style={{ minWidth: "80px", fontSize: "11px" }}
              >
                <b>Received From:</b>
              </p>

              <p className="p-word mb-1 text-start">
                <span className="ms-1 ">
                  {" "}
                  {formContent?.receivedFrom ? formContent?.receivedFrom : "-"}
                </span>
              </p>
            </div>
          </Typography>
        </div>
      </div>
      {/* /file detail */}

      <TableContainer component={Paper} className="mb-0 mt-2">
        <Table>
          <TableBody>
            {/* Origin Address */}
            {formContent?.address?.trim()?.length > 0 && (
              <TableRow>
                <TableCell
                  className="border p-1 w-25"
                  style={{ fontSize: "10px" }}
                >
                  <b>Address</b>
                </TableCell>
                <TableCell
                  className="border p-1 w-75 text-start"
                  style={{ fontSize: "10px" }}
                >
                  {isEdit ? (
                    <Input
                      id={"shipper"}
                      type="text"
                      style="full-border-field bg-app"
                      label={"Shipper"}
                      value={formContent?.address}
                      inline={{ width: "-webkit-fill-available" }}
                    />
                  ) : (
                    <>{formContent?.address ? formContent?.address : "-"}</>
                  )}
                </TableCell>
              </TableRow>
            )}

            {formContent?.onAccountOf?.trim()?.length > 0 && (
              <TableRow>
                <TableCell
                  className="border p-1 w-25"
                  style={{ fontSize: "10px" }}
                >
                  <b> On Account of</b>
                </TableCell>
                <TableCell
                  className="border p-1 w-75 text-start"
                  style={{ fontSize: "10px" }}
                >
                  {isEdit ? (
                    <Input
                      id={"shipper"}
                      type="text"
                      style="full-border-field bg-app"
                      label={"Shipper"}
                      value={formContent?.onAccountOf}
                      inline={{ width: "-webkit-fill-available" }}
                    />
                  ) : (
                    <>
                      {formContent?.onAccountOf
                        ? formContent?.onAccountOf
                        : "-"}
                    </>
                  )}
                </TableCell>
              </TableRow>
            )}

            {formContent?.amount?.trim()?.length > 0 && (
              <TableRow>
                <TableCell
                  className="border p-1 w-25"
                  style={{ fontSize: "10px" }}
                >
                  <b> Amount</b>
                </TableCell>
                <TableCell
                  className="border p-1 w-75 text-start"
                  style={{ fontSize: "10px" }}
                >
                  {isEdit ? (
                    <Input
                      id={"shipper"}
                      type="text"
                      style="full-border-field bg-app"
                      label={"Shipper"}
                      value={formContent?.amount}
                      inline={{ width: "-webkit-fill-available" }}
                    />
                  ) : (
                    <>{formContent?.amount ? formContent?.amount : "-"}</>
                  )}
                </TableCell>
              </TableRow>
            )}

            {formContent?.chequeNo?.trim()?.length > 0 && (
              <TableRow>
                <TableCell
                  className="border p-1 w-25"
                  style={{ fontSize: "10px" }}
                >
                  <b> Cash/Cheque #</b>
                </TableCell>
                <TableCell
                  className="border p-1 w-75 text-start"
                  style={{ fontSize: "10px" }}
                >
                  {isEdit ? (
                    <Input
                      id={"shipper"}
                      type="text"
                      style="full-border-field bg-app"
                      label={"Shipper"}
                      value={formContent?.chequeNo}
                      inline={{ width: "-webkit-fill-available" }}
                    />
                  ) : (
                    <>{formContent?.chequeNo ? formContent?.chequeNo : "-"}</>
                  )}
                </TableCell>
              </TableRow>
            )}

            {formContent?.drawnOnBank?.trim()?.length > 0 && (
              <TableRow>
                <TableCell
                  className="border p-1 w-25"
                  style={{ fontSize: "10px" }}
                >
                  <b> Drawn on Bank</b>
                </TableCell>
                <TableCell
                  className="border p-1 w-75 text-start"
                  style={{ fontSize: "10px" }}
                >
                  {isEdit ? (
                    <Input
                      id={"shipper"}
                      type="text"
                      style="full-border-field bg-app"
                      label={"Shipper"}
                      value={formContent?.drawnOnBank}
                      inline={{ width: "-webkit-fill-available" }}
                    />
                  ) : (
                    <>
                      {formContent?.drawnOnBank
                        ? formContent?.drawnOnBank
                        : "-"}
                    </>
                  )}
                </TableCell>
              </TableRow>
            )}

            {formContent?.inWords?.trim()?.length > 0 && (
              <TableRow>
                <TableCell
                  className="border p-1 w-25"
                  style={{ fontSize: "10px" }}
                >
                  <b> In words</b>
                </TableCell>
                <TableCell
                  className="border p-1 w-75 text-start"
                  style={{ fontSize: "10px" }}
                >
                  {isEdit ? (
                    <Input
                      id={"shipper"}
                      type="text"
                      style="full-border-field bg-app"
                      label={"Shipper"}
                      value={formContent?.inWords}
                      inline={{ width: "-webkit-fill-available" }}
                    />
                  ) : (
                    <>{formContent?.inWords ? formContent?.inWords : "-"}</>
                  )}
                </TableCell>
              </TableRow>
            )}

            {formContent?.receivedBy?.trim()?.length > 0 && (
              <TableRow>
                <TableCell
                  className="border p-1 w-25"
                  style={{ fontSize: "10px" }}
                >
                  <b> Received By</b>
                </TableCell>
                <TableCell
                  className="border p-1 w-75 text-start"
                  style={{ fontSize: "10px" }}
                >
                  {isEdit ? (
                    <Input
                      id={"shipper"}
                      type="text"
                      style="full-border-field bg-app"
                      label={"Shipper"}
                      value={formContent?.receivedBy}
                      inline={{ width: "-webkit-fill-available" }}
                    />
                  ) : (
                    <>
                      {formContent?.receivedBy ? formContent?.receivedBy : "-"}
                    </>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* </MainTemplate> */}
    </div>
  );
};
export default AcknowledgementReceiptDownload;
