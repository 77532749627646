import React, { useEffect, useState } from "react";
import {
  getFormContent,
  getSingleSurvey,
  updateFormContent,
  surveyResultPreview,
  sharePreviewLink,
  addFormContent,
} from "../../../api/services/services";
import backArrow from "../../../imgs/BackArrow.svg";
import MainTemplate from "../../templates/MainTemplate";
import { useNavigate } from "react-router-dom";
import { Button, Snackbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import Input from "../../atoms/Input";
import ContactDetailField from "../../molecules/ContactDetailField";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useParams } from "react-router-dom";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import html2pdf from "html2pdf.js";
import ShareWithClientsModal from "../../molecules/ShareWithClients";
import ShippingInstructionsDownload from "./ClientSide/download";
import PrintComponent from "../PrintComponent";
import TextArea from "../../atoms/Textarea";
import moment from "moment";
import { sendSuperAdminVerificationForEdit } from "../PrebidAgreement/creation/service";
import SuperAdminVerifationModal from "../PrebidAgreement/creation/super_admin_verification_modal";

const ShippingInstructionsPage = () => {
  const { surveyId, id, flg } = useParams();
  const [surveyData, setSurveyData] = useState(null);
  const navigate = useNavigate();

  const [formContentId, setFormContentId] = useState("");
  const [isEdit, setIsEdit] = useState(flg == "1" ? true : false);

  const [isLoading, setIsLoading] = useState(false);
  const [formContent, setFormContent] = useState({
    airShipment: "",
    date: "",
    shipper: "",
    consignee: "",
    notify: "",
    description: "",
    grossWeight: "",
    portOfDischarge: "",
    destinationCountry: "",
    remarks: "",
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [isShare, setIsShare] = useState(false);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  //
  const getSurveyPreview = () => {
    surveyResultPreview(surveyId)
      .then((response) => {
        setSurveyData(response?.data?.data?.surveyDetail);
        let values = { ...formContent };
        values.destinationCountry = ` ${response?.data?.data?.surveyDetail?.unloadPlaceCountry}`;
        values.customerName = `${response?.data?.data?.surveyDetail?.name}`;
        setFormContent(values);
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }
      });
  };

  //
  const getSurvey = () => {
    getSingleSurvey(surveyId)
      .then((response) => {
        if (response?.data?.data?.status == "submitted") getSurveyPreview();
        else {
          setSurveyData(response?.data?.data);
          let values = { ...formContent };
          values.destinationCountry = ` ${response?.data?.data?.unloadPlaceCountry}`;
          values.customerName = `${response?.data?.data?.name}`;
          setFormContent(values);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }
      });
  };

  //
  const addContent = () => {
    setIsLoading(true);
    const body = {
      formId: id,
      surveyId: surveyId,
      content: formContent,
      mediaKeys: [],
      removedMediaKeys: [],
    };
    addFormContent(body)
      .then(() => {
        setSnackbarMessage(
          "Shipping Instructions has been added successfully!"
        );
        setOpenSnackbar(true);

        setTimeout(() => {
          navigate(-1);
          setIsLoading(false);
        }, 1000);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  //
  const updateContent = () => {
    setIsLoading(true);
    const body = {
      formId: id,
      surveyId: surveyId,
      content: formContent,
      mediaKeys: [],
      removedMediaKeys: [],
    };
    updateFormContent(body, formContentId)
      .then((res) => {
        setSnackbarMessage(
          "Shipping Instructions has been updated successfully!"
        );
        setOpenSnackbar(true);
        getContent();
        setIsEdit(false);

        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  //
  const getContent = () => {
    setIsLoading(true);
    getFormContent(id, surveyId)
      .then((res) => {
        setFormContentId(res.data.data.uuid);
        setFormContent(res.data.data.content);
        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (flg == "1") getSurvey();
    else getContent();
  }, []);

  const [show, setShow] = useState(false);
  const options = {
    // resolution: Resolution.HIGH,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      format: "letter",
      // default is 'portrait'
      orientation: "portrait",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/png",
      qualityRatio: 1,
    },
    overrides: {
      pdf: {
        compress: true,
      },
    },
  };

  //
  const generatePdf = () => {
    setShow(true);

    setTimeout(() => {
      const content = document.getElementById("content-to-convert"); // Replace with the ID of the element you want to convert to PDF

      const currentDateTime = new Date();
      const filename = `survey_${currentDateTime.toISOString()}.pdf`;

      const pdfOptions = {
        margin: 5,
        filename: filename,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
        }, // Set the page size here
      };

      let temp = html2pdf().from(content).set(pdfOptions).outputPdf();
      temp.save(filename);

      setShow(false);
    }, 3500);
  };

  const [downloadText, setDownloadText] = useState("download");

  const getTargetElement = () => document.getElementById("content-id");

  //
  const shareWithClient = (data) => {
    let ems = [];
    let emsInd = 0;
    data.map((dt) => {
      if (dt.trim().length != 0) {
        ems[emsInd] = dt;
        emsInd++;
      }
    });

    setIsLoading(true);
    const body = {
      surveyId: surveyId,
      formId: id,
      emails: ems,
      formRoute: "shipping-instructions",
    };
    sharePreviewLink(body)
      .then(() => {
        setSnackbarMessage(
          "Shipping Instructions have been shared with the respective emails successfully!"
        );
        setOpenSnackbar(true);

        setIsLoading(false);
        setIsShare(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  const printAction = () => {
    const PAGE_HEIGHT = 1045;
    const printElement = document.getElementById("print-component");
    if (printElement) {
      printElement.classList.add("temp-class-for-height");
      const height = printElement.clientHeight;
      const numberOfPage = Math.ceil(height / PAGE_HEIGHT);
      const heightWithSingleHeader = numberOfPage * PAGE_HEIGHT;
      let requiredHeight = heightWithSingleHeader;
      if (numberOfPage > 1) {
        const headerHeight =
          printElement.getElementsByTagName("thead")?.[0]?.clientHeight;
        const footerHeight =
          printElement.getElementsByTagName("tfoot")?.[0]?.clientHeight;
        requiredHeight -= (numberOfPage - 1) * (headerHeight + footerHeight);
      }
      printElement.style.height = `${requiredHeight}px`;
      printElement.classList.remove("temp-class-for-height");
    }
    window.print();
    if (printElement) {
      printElement.style.height = `auto`;
    }
  };

  const [isVerified, setIsVerified] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);

  const sendEditRequestToAdmin = () => {
    setIsEditLoading(true);
    sendSuperAdminVerificationForEdit()
      .then((response) => {
        setIsVerified(true);
        setIsEditLoading(false);
      })
      .catch((error) => {
        setIsVerified(false);
        setIsEditLoading(false);
      });
  };

  return (
    <>
      <MainTemplate>
        <div className="no-print">
          <div>
            <div className="d-block d-sm-flex align-items-center py-4">
              <div className="d-flex align-items-center ">
                <div
                  className="light-blue-color back-bg cursor-pointer"
                  onClick={() => navigate(-1)}
                >
                  <img src={backArrow} height="24" width="24" />{" "}
                </div>
                <div>
                  <p className="main-head ps-3 mb-0">Shipping Instructions</p>
                </div>
              </div>
              <div className="d-flex ms-auto justify-content-center mt-2 mt-sm-0">
                {/*Edit Button */}
                {!isEdit && (
                  <Button
                    className="active-btn"
                    onClick={() => {
                      sendEditRequestToAdmin();
                    }}
                    disabled={isEditLoading}
                  >
                    Edit
                  </Button>
                )}

                {/*Share Button */}
                {!isEdit && (
                  <Button
                    className="active-btn ms-3"
                    onClick={() => {
                      setIsShare(true);
                    }}
                    disabled={isLoading}
                  >
                    Share
                  </Button>
                )}

                {/* Cancel button */}
                {isEdit && flg == "2" && (
                  <Button
                    className="active-btn ms-3"
                    onClick={() => {
                      getContent();
                      setIsEdit(false);
                    }}
                    disabled={isLoading}
                  >
                    Cancel
                  </Button>
                )}

                {/* Save Button */}
                {isEdit && (
                  <Button
                    className="active-btn ms-3"
                    onClick={() => {
                      if (flg == "1") addContent();
                      else updateContent();
                    }}
                    disabled={isLoading}
                  >
                    Save
                  </Button>
                )}

                {/* Download Button */}
                {!isEdit && (
                  <Button
                    className="active-btn ms-3 px-0"
                    onClick={() => {
                      setIsLoading(true);
                      // printAction();
                      var tempTitle = document.title;
                      document.title = `Shipping Instructions-${
                        formContent?.customerName
                      }-${moment(new Date()).format("DD.MM.YYYY")}`;

                      window.print();
                      document.title = tempTitle;
                      setIsLoading(false);
                    }}
                    disabled={isLoading}
                  >
                    Save as PDF
                  </Button>
                )}
              </div>
            </div>
          </div>
          {/*  Details */}
          <div className="mt-5 pe-1 mb-4">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div>
                <Typography variant="body1" gutterBottom className="">
                  <ContactDetailField
                    label="Mode of Shipment"
                    isEdit={isEdit}
                    type="text"
                    minWidth={"150px"}
                    value={formContent?.airShipment}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.airShipment = e.target.value;
                      setFormContent(values);
                    }}
                  />
                  <ContactDetailField
                    label="Date"
                    isEdit={isEdit}
                    type="date"
                    minWidth={"150px"}
                    value={formContent?.date}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.date = e.target.value;
                      setFormContent(values);
                    }}
                  />
                </Typography>
              </div>
            </Typography>
          </div>
          {/* / Details */}
          {/* table */}
          <TableContainer component={Paper} className="mb-5 shipping">
            <Table>
              <TableBody>
                {/* Shipper */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Shipper</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"shipper"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Shipper"}
                        value={formContent?.shipper}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.shipper = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>{formContent?.shipper ? formContent?.shipper : "-"}</>
                    )}
                  </TableCell>
                </TableRow>
                {/* Consignee */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Consignee</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"consignee"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Consignee"}
                        value={formContent?.consignee}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.consignee = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.consignee ? formContent?.consignee : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>

                {/* Notify */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Notify</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"notify"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Notify"}
                        value={formContent?.notify}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.notify = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>{formContent?.notify ? formContent?.notify : "-"}</>
                    )}
                  </TableCell>
                </TableRow>
                {/* Description */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Description</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"description"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Description"}
                        value={formContent?.description}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.description = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.description
                          ? formContent?.description
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>
                {/* Volume/Gross Weight */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Volume/Gross Weight</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"gross-weight"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Gross Weight"}
                        value={formContent?.grossWeight}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.grossWeight = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.grossWeight
                          ? formContent?.grossWeight
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>
                {/* Port of Discharge */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Port of Discharge</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"port-of-discharge"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Port of Discharge"}
                        value={formContent?.portOfDischarge}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.portOfDischarge = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.portOfDischarge
                          ? formContent?.portOfDischarge
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>

                {/* Destination Country */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Destination Country</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"country"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Destination Country"}
                        value={formContent?.destinationCountry}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.destinationCountry = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.destinationCountry
                          ? formContent?.destinationCountry
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>

                {/* Remarks */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Remarks</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start shipping">
                    {isEdit ? (
                      <TextArea
                        id={"remarks"}
                        type="text"
                        style=""
                        label={"Remarks"}
                        value={formContent?.remarks}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.remarks = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                        rows="5"
                      />
                    ) : (
                      <>{formContent?.remarks ? formContent?.remarks : "-"}</>
                    )}
                  </TableCell>
                </TableRow>
                {/* {data.map((name, index) => (
                <TableRow key={index}>
                  <TableCell className="border p-3 w-25">{name}</TableCell>
                  <TableCell className="border p-3 w-75 text-center">
                    {isEdit ? (
                      name === "Volume/Gross Weight" ? (
                        <Input
                          id={name}
                          type="number"
                          style="full-border-field"
                          label={name}
                        />
                      ) : (
                        <Input
                          id={name}
                          type="text"
                          style="full-border-field"
                          label={name}
                        />
                      )
                    ) : (
                      "-"
                    )}
                  </TableCell>
                </TableRow>
              ))} */}
              </TableBody>
            </Table>
          </TableContainer>
          {/* /table */}
          {/* snackbar */}
        </div>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000} // Adjust the duration as needed
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={
              snackbarMessage?.includes("successful") ? "success" : "error"
            }
            onClose={handleCloseSnackbar}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
        {/* /snackbar */}

        {/* share with client */}
        {isShare && (
          <ShareWithClientsModal
            open={isShare}
            onClose={() => {
              setIsShare(false);
            }}
            onSuccess={(emails) => {
              shareWithClient(emails);
            }}
          />
        )}
        <PrintComponent>
          <ShippingInstructionsDownload
            surveyData={surveyData}
            formContent={formContent}
          />
        </PrintComponent>

        {isVerified && (
          <SuperAdminVerifationModal
            open={isVerified}
            onClose={() => {
              setIsVerified(false);
            }}
            onSuccess={() => {
              setIsEdit(true);
              setIsVerified(false);
            }}
            onError={() => {
              // shareWithClient(emails);
              setSnackbarMessage("Invalid Confirm Pin");
              setOpenSnackbar(true);
            }}
          />
        )}
        
      </MainTemplate>
    </>
  );
};
export default ShippingInstructionsPage;
