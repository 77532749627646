// api/services/services.js
// import { json } from 'react-router-dom';
import axiosInstance from "./../config/axiosConfig";

let token = localStorage.getItem("shelLoginToken");

// Confirm Invitation
export const confirmInvite = (token, type) => {
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return axiosInstance.get(`/admin/auth/confirm_email?type=${type}`, {
    headers: headers,
  });
};

// create password
export const createPassword = (token, body) => {
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return axiosInstance.patch(`/admin/auth/set_password`, body, {
    headers: headers,
  });
};

//
export const getTOTPUri = (token) => {
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return axiosInstance.get(`/admin/auth/get_totp_uri`, {
    headers: headers,
  });
};

//
export const verify2FA = (token, body) => {
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return axiosInstance.post(`/admin/auth/verify_2fa`, body, {
    headers: headers,
  });
};

// login service
export const loginFunc = (body) => {
  return axiosInstance.post(`/admin/auth/login`, body);
};

// forgot-password service
export const forgotPassword = (body) => {
  return axiosInstance.post(`/admin/auth/forgot_password`, body);
};

// verfy forgot password token
export const verifyForgotToken = (token) => {
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return axiosInstance.get(`/admin/auth/verify_token`, {
    headers: headers,
  });
};

// reset password
export const resetPassword = (token, body) => {
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return axiosInstance.post(`/admin/auth/confirm_forgot_password`, body, {
    headers: headers,
  });
};

// get all surveys
export const getSurveys = (all, status, page, perPage, search) => {
  // let token = JSON.parse(temp)
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  if (search?.trim()?.length > 0) {
    if (all)
      return axiosInstance.get(
        `/admin/survey/list?all=${all}&status=${status}&page=${page}&pageSize=${perPage}&search=${search}`,
        {
          headers: headers,
        }
      );
    else
      return axiosInstance.get(
        `/admin/survey/list?status=${status}&page=${page}&pageSize=${perPage}&search=${search}`,
        {
          headers: headers,
        }
      );
  } else {
    if (all)
      return axiosInstance.get(
        `/admin/survey/list?all=${all}&status=${status}&page=${page}&pageSize=${perPage}`,
        {
          headers: headers,
        }
      );
    else
      return axiosInstance.get(
        `/admin/survey/list?status=${status}&page=${page}&pageSize=${perPage}`,
        {
          headers: headers,
        }
      );
  }
};

// update status of survey
export const updateStatus = (id, body) => {
  let headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  return axiosInstance.put(`/admin/survey/update_status/${id}`, body, {
    headers: headers,
  });
};

// get Single Survey
export const getSingleSurvey = (id) => {
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return axiosInstance.get(`/admin/survey/${id}`, {
    headers: headers,
  });
};

// get All Surveyors
export const getSurveyors = (page, perPage, dropDown, search) => {
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return axiosInstance.get(
    `/admin/user/list?page=${page}&pageSize=${perPage}&isDropDown=${dropDown}&search=${search}`,
    {
      headers: headers,
    }
  );
};

// assign surveyor
export const assignSurveyor = (body) => {
  let headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  return axiosInstance.put(`/admin/survey/assign_to_surveyer`, body, {
    headers: headers,
  });
};

// get admins
export const getAdmins = (page, perPage, search) => {
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return axiosInstance.get(
    `/admin?page=${page}&pageSize=${perPage}&search=${search}`,
    {
      headers: headers,
    }
  );
};

// add role
export const addRole = (body) => {
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return axiosInstance.post(`/admin/auth/invite_user`, body, {
    headers: headers,
  });
};

// dashboard summary count
export const getSummary = () => {
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return axiosInstance.get(`/admin/survey/statuswise_summary`, {
    headers: headers,
  });
};

//
export const surveyResultPreview = (id) => {
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return axiosInstance.get(`/admin/survey/preview/${id}`, {
    headers: headers,
  });
};

//
export const sendQuote = (body) => {
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return axiosInstance.post(`/admin/survey/send_quote`, body, {
    headers: headers,
  });
};

//
export const clientPreview = (email, id, tkn) => {
  let headers = {
    Authorization: `Bearer ${tkn}`,
  };
  return axiosInstance.get(
    `/survey/client_preview?email=${email}&surveyId=${id}`,
    {
      headers: headers,
    }
  );
};

//
export const quoteStatus = (email, id, body, tkn) => {
  let headers = {
    Authorization: `Bearer ${tkn}`,
  };
  return axiosInstance.put(
    `/survey/quote_status?email=${email}&surveyId=${id}`,
    body,
    {
      headers: headers,
    }
  );
};

//
export const getFormsList = () => {
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return axiosInstance.get(`admin/surveyform/formlist`, {
    headers: headers,
  });
};

//
export const getSurveyFormList = (id) => {
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return axiosInstance.get(`/admin/surveyform/surveyformlist/${id}`, {
    headers: headers,
  });
};

//
export const addFormContent = (body) => {
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return axiosInstance.post(`/admin/surveyform`, body, {
    headers: headers,
  });
};

//
export const getFormContent = (id, surveyId) => {
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return axiosInstance.get(
    `/admin/surveyform/surveyformbyid?formId=${id}&surveyId=${surveyId}`,
    {
      headers: headers,
    }
  );
};

//
export const updateFormContent = (body, id) => {
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return axiosInstance.patch(`admin/surveyform/${id}`, body, {
    headers: headers,
  });
};

//
export const imageUpload = (data) => {
  let headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data",
  };

  return axiosInstance.post(`/admin/media/upload_image`, data, {
    headers: headers,
  });
};

//
export const getFormContentPreview = (
  surveyId,
  formId,
  email,
  previewToken
) => {
  let headers = {
    Authorization: `Bearer ${previewToken}`,
  };
  return axiosInstance.get(
    `/admin/surveyform/formpreview?email=${email}&surveyId=${surveyId}&formId=${formId}`,
    {
      headers: headers,
    }
  );
};

//
export const updateFormContentPreview = (
  surveyId,
  formId,
  email,
  previewToken,
  body,
  id
) => {
  let headers = {
    Authorization: `Bearer ${previewToken}`,
  };

  return axiosInstance.patch(
    `/admin/surveyform/previewcontent/${id}?email=${email}&surveyId=${surveyId}&formId=${formId}`,
    body,
    {
      headers: headers,
    }
  );
};

//
export const sharePreviewLink = (body) => {
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return axiosInstance.post(`/admin/surveyform/sendpreviewlink`, body, {
    headers: headers,
  });
};

//
export const imageUploadClientPreview = (
  data,
  surveyId,
  formId,
  email,
  previewToken
) => {
  let headers = {
    Authorization: `Bearer ${previewToken}`,
    "Content-Type": "multipart/form-data",
  };

  return axiosInstance.post(
    `/admin/media/preview/upload_image?email=${email}&surveyId=${surveyId}&formId=${formId}`,
    data,
    {
      headers: headers,
    }
  );
};

// update Survey
export const updateSurvey= (body, id) => {
  let headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  return axiosInstance.put(`/admin/survey/survey_info/${id}`, body, {
    headers: headers,
  });
};

// 
export const initiateSurvey = (body) => {
  let headers = {
    // Authorization: `Bearer ${token}`,
    // "Content-Type": "application/json",
  };

  return axiosInstance.post(`/survey/register_survey`, body, {
    headers: headers,
  });
};

// 
export const deleteSurvey = (id) => {
  let headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  return axiosInstance.delete(`/admin/survey/${id}`, {
    headers: headers,
  });
};

//
export const sendResult = (body) => {
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return axiosInstance.post(`/admin/survey/send_result`, body, {
    headers: headers,
  });
};
