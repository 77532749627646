// components/atoms/Input.js
import React from "react";
import { useState } from "react";

const Input = ({ value, onChange, type, id, label = "", style, inline, disabled }) => {
  const [isTyping, setIsTyping] = useState(false);

  // const handleInput = (e) => {
  //   console.log(e)
  //   onChange && onChange(e);
  //   setIsTyping(!!e.target.value);
  // };

  return (
    <>
      {inline != undefined ? (
        <input
          type={type}
          className={`${style || "input-field "}`}
          value={value}
          onChange={onChange}
          id={id}
          required
          placeholder={label}
          style={inline}
          disabled={disabled ? true : false}
        />
      ) : (
        <div className="input-container">
          <input
            type={type}
            className={`${style || "input-field "}`}
            value={value}
            onChange={onChange}
            id={id}
            required
            placeholder={label}
            style={inline}
            disabled={disabled ? true : false}
          />
          {/* <label
        htmlFor="username"
        className={`input-label ${isTyping ? "hidden" : ""}`}
      >
        {label}
      </label> */}
        </div>
      )}
    </>
  );
};

export default Input;
