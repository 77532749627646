import React, { useEffect, useState } from "react";
import { clientPreview } from "../../../../api/services/services";
import { Button, Snackbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import { Bucket } from "../../../../api/config/apiConfig";
import Input from "../../../atoms/Input";
import TextArea from "../../../atoms/Textarea";
import ContactDetailField from "../../../molecules/ContactDetailField";
import { useLocation } from "react-router-dom";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import SurveyPreviewDownload from "../download";
import html2pdf from "html2pdf.js";
import PrintComponent from "../../PrintComponent";
import callIcon from "../../../../imgs/call.svg";
import calenderIcon from "../../../../imgs/calendar.svg";
import calenderMove from "../../../../imgs/calendarMinus.svg";
import emailIcon from "../../../../imgs/email.svg";
import locationImg from "../../../../imgs/location.svg";
import moment from "moment";
import gifLoading from "../../../../imgs/giphy.gif";
import LazyImage from "../../LazyLoadImage";

const ClientSurveyPreview = () => {
  const [formContent, setFormContent] = useState({});
  const [formContentId, setFormContentId] = useState("");

  const [surveyData, setSurveyData] = useState([]);

  const [isEdit, setIsEdit] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  var totalWeight = 0;
  var totalCbm = 0;
  var totalItems = 0;

  let movableItems = 0;
  let nonmovableItems = 0;

  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();
  const urlData = new URLSearchParams(location.search);
  const email = urlData.get("email");
  const surveyId = urlData.get("surveyId");
  const formId = urlData.get("formId");
  const token = urlData.get("token");

  var totalAmount = 0;

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const [isValid, setIsValid] = useState(false);

  //
  const getClientPreview = () => {
    clientPreview(email, surveyId, token)
      .then((response) => {
        setSurveyData(response?.data?.data);
        setIsLoading(false);
        setIsValid(true);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);
        setIsLoading(false);
        setIsValid(false);
      });
  };

  useEffect(() => {
    getClientPreview();
  }, []);

  const convertDate = (unixTimestamp) => {
    const date = new Date(unixTimestamp * 1000); // Multiply by 1000 to convert from seconds to milliseconds
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1 and format with leading zero
    const dd = String(date.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // 11th, 12th, 13th, etc.
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  // Helper function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const ordinalSuffix = getOrdinalSuffix(day);

    return `${day}${ordinalSuffix} ${month}, ${year}`;
  };

  return (
    <>
      {isValid ? (
        <div className="mx-3 mx-md-5">
          <div className="no-print">
            <div className="d-flex align-items-center py-4 ">
              <div className="d-flex align-items-center  w-100">
                <div>
                  <p className="main-head ps-3 mb-0">Survey Preview</p>
                </div>
                <div className="d-flex ms-auto">
                  <Button
                    className="active-btn ms-3 px-2"
                    onClick={() => {
                      setIsLoading(true);
                    // printAction();
                    var tempTitle = document.title;
                    document.title = `Survey Preview-${
                      surveyData?.surveyDetail?.name
                    }-${moment(new Date()).format("DD.MM.YYYY")}`;

                    window.print();
                    document.title = tempTitle;
                    setIsLoading(false);
                    }}
                  >
                    {"Save as PDF"}
                  </Button>
                </div>
              </div>
            </div>

            {surveyData && (
              <>
                <Row className="mx-0 px-2 py-3 preview-card mb-4">
                  <Row>
                    <Col md={12} className="text-start">
                      <h3 className="fn">
                        <b>{surveyData?.surveyDetail?.name}</b>
                      </h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6} md={3} className="mb-2">
                      <div className="d-flex align-items-start">
                        <div className="white-circle mt-1">
                          <img src={emailIcon} className="img-pr" />
                        </div>
                        <div className="ps-2">
                          <p className="surve-name mb-0">
                            <b>Email</b>
                          </p>
                          <p className="surve-email mb-0 p-word">
                            {surveyData?.surveyDetail?.email}
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col sm={6} md={2} className="mb-2">
                      <div className="d-flex align-items-start">
                        <div className="white-circle mt-1">
                          <img src={callIcon} className="img-pr" />
                        </div>
                        <div className="ms-2">
                          <p className="surve-name mb-0">Phone No.</p>
                          <p className="surve-email mb-0 p-word">
                            {surveyData?.surveyDetail?.loadPlacePhone}
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col sm={6} md={3} className="mb-2">
                      <div className="d-flex align-items-start">
                        <div className="white-circle mt-1">
                          <img
                            src={locationImg}
                            height={24}
                            width={24}
                            className="img-pr"
                          />
                        </div>
                        <div className="ms-2">
                          <p className="surve-name mb-0">Origin Address</p>
                          <p className="surve-email mb-0 p-word">
                            {surveyData?.surveyDetail?.loadPlaceStAddress},{" "}
                            {surveyData?.surveyDetail?.loadPlaceCity},{" "}
                            {surveyData?.surveyDetail?.loadPlaceCountry}
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col sm={6} md={3} className="mb-2">
                      <div className="d-flex align-items-start">
                        <div className="white-circle mt-1">
                          <img
                            src={locationImg}
                            height={24}
                            width={24}
                            className="img-pr"
                          />
                        </div>
                        <div className="ms-2">
                          <p className="surve-name mb-0">Destination Address</p>
                          <p className="surve-email mb-0 p-word">
                            {surveyData?.surveyDetail?.unloadPlaceStAddress},{" "}
                            {surveyData?.surveyDetail?.unloadPlaceCity},{" "}
                            {surveyData?.surveyDetail?.unloadPlaceCountry}
                          </p>
                        </div>
                      </div>
                    </Col>

                    {/* <Col sm={6} md={2} className="mb-2">
                  <div className="d-flex align-items-start">
                    <div className="white-circle mt-1">
                      <img src={calenderIcon} className="img-pr" />
                    </div>
                    <div className="ms-2">
                      <p className="surve-name mb-0">Created Date</p>
                      <p className="surve-email mb-0 p-word">
                        {surveyData?.surveyDetail?.createdAt
                          ? convertDate(surveyData?.surveyDetail?.createdAt)
                          : "-"}
                      </p>
                    </div>
                  </div>
                </Col> */}

                    <Col sm={6} md={3} className="mb-2">
                      <div className="d-flex align-items-start">
                        <div className="white-circle mt-1">
                          <img src={calenderMove} className="img-pr" />
                        </div>
                        <div className="ms-2">
                          <p className="surve-name mb-0">Removal Date</p>
                          <p className="surve-email mb-0 p-word">
                            {surveyData?.surveyDetail?.loadDate
                              ? formatDate(
                                  convertDate(
                                    surveyData?.surveyDetail?.loadDate
                                  )
                                )
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col sm={6} md={2} className="mb-2">
                      <div className="d-flex align-items-start">
                        <div className="white-circle mt-1">
                          <img src={calenderIcon} className="img-pr" />
                        </div>
                        <div className="ms-2">
                          <p className="surve-name mb-0">Survey Date</p>
                          <p className="surve-email mb-0 p-word">
                            {surveyData?.surveyDetail?.surveyDate
                              ? formatDate(
                                  convertDate(
                                    surveyData?.surveyDetail?.surveyDate
                                  )
                                )
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Row>

                <Row className="mx-1">
                  <Col sm={12} md={12} className="text-start px-0 fn">
                    <b>Survey Description</b>
                  </Col>
                </Row>

                <Row
                  className="mx-0 bg-white mt-1 py-1 px-2 px-md-4"
                  style={{ borderRadius: "5px" }}
                >
                  <Col sm={12} md={12} className="text-start px-0 fn">
                    <p className="surve-email mb-0 p-word">
                      {surveyData?.surveyDetail?.description}
                    </p>
                  </Col>
                </Row>

                <Row className="mx-1  mt-3">
                  <Col sm={12} md={12} className="text-start px-0 fn">
                    <b>Survey Overview</b>
                  </Col>
                </Row>
                <Row
                  className="mx-0 bg-white mt-3 py-1 px-2 px-md-4"
                  style={{ borderRadius: "5px" }}
                >
                  <div className="detail-row row">
                    <div className="col-3 text-start px-0 ">
                      <p className="surve-email mb-0 p-word">
                        <b>Room</b>
                      </p>
                    </div>

                    <div className="col-3 text-start px-0 ">
                      <p className="surve-email mb-0 p-word">
                        <b>Weight</b>
                      </p>
                    </div>
                    <div className="col-3 text-start px-0 ">
                      <p className="surve-email mb-0 p-word">
                        <b>CBM</b>
                      </p>
                    </div>
                    <div className="col-3 text-start px-0 ">
                      <p className="surve-email mb-0 p-word">
                        <b>Items</b>
                      </p>
                    </div>
                  </div>
                  {surveyData?.roomWiseCalculation?.length > 0 &&
                    surveyData?.roomWiseCalculation?.map((cat, index) => {
                      totalWeight = totalWeight + cat?.totalWeightKg;
                      totalCbm = totalCbm + cat?.totalCbm;
                      totalItems = totalItems + cat?.totalItems;
                      return (
                        <div className="detail-row row">
                          <div className="col-3 text-start px-0 ">
                            <p className="surve-email mb-0 p-word">
                              {cat?.roomName ? cat?.roomName : ""}
                            </p>
                          </div>

                          <div className="col-3 text-start px-0 ">
                            <p className="surve-email mb-0 p-word">
                              {cat?.totalWeightKg
                                ? `${cat?.totalWeightKg?.toFixed(4)} kg`
                                : "0 kg"}
                            </p>
                          </div>
                          {cat?.totalCbm ? (
                            <div className="col-3 text-start px-0 ">
                              <p className="surve-email mb-0 p-word">
                                {`${cat?.totalCbm?.toFixed(4)} m`}
                                <sup>3</sup>
                              </p>
                            </div>
                          ) : (
                            <div className="col-3 text-start px-0 ">
                              <p className="surve-email mb-0 p-word">
                                {"0 m"}
                                <sup>3</sup>
                              </p>
                            </div>
                          )}
                          <div className="col-3 text-start px-0 ">
                            <p className="surve-email mb-0 p-word">
                              {cat?.totalItems ? cat?.totalItems : "-"}
                            </p>
                          </div>
                        </div>
                      );
                    })}

                  <div className="detail-row-last row">
                    <div className="col-3 text-start px-0 ">
                      <p className="surve-email mb-0 p-word">
                        <b>Total</b>
                      </p>
                    </div>

                    <div className="col-3 text-start px-0 ">
                      <p className="surve-email mb-0 p-word">
                        <b>
                          {totalWeight?.toFixed(4)}
                          {" kg"}
                        </b>
                      </p>
                    </div>
                    <div className="col-3 text-start px-0 ">
                      <p className="surve-email mb-0 p-word">
                        <b>
                          {totalCbm?.toFixed(4)} m<sup>3</sup>
                        </b>
                      </p>
                    </div>
                    <div className="col-3 text-start px-0 ">
                      <p className="surve-email mb-0 p-word">
                        <b>{totalItems}</b>
                      </p>
                    </div>
                  </div>
                </Row>

                {surveyData?.surveyDetail?.status == "submitted" && (
                  <>
                    {surveyData?.surveyDetail?.surveyResult?.surveyRooms
                      ?.length > 0 && (
                      <Row className="mx-1 mb-1 mt-4">
                        <Col sm={12} md={12} className="text-start px-0 ">
                          <b>Rooms Detail</b>
                        </Col>
                      </Row>
                    )}
                  </>
                )}

                {surveyData?.surveyDetail?.surveyResult?.surveyRooms?.length >
                  0 &&
                  surveyData?.surveyDetail?.surveyResult?.surveyRooms?.map(
                    (sr, ind) => {
                      return (
                        <Row
                          className="bg-white my-4 mx-0  p-3 py-4 mb-4"
                          style={{ borderRadius: "10px" }}
                        >
                          <Row className="">
                            <Col
                              sm={12}
                              md={12}
                              className="text-start px-0 left-col"
                            >
                              <h4 className="fn text-capitalize">
                                <b>
                                  {ind + 1}
                                  {". "}
                                  {sr?.name}
                                </b>
                              </h4>
                            </Col>
                          </Row>
                          <Row>
                            {/* Images */}
                            <Col sm={6} md={6} className="px-10">
                              <div className="d-flex flex-wrap">
                                {sr?.images?.map((image) => {
                                  return (
                                    <>
                                    <div className="me-2 mb-2">
                                      <LazyImage
                                        key={ind}
                                        src={Bucket + image}
                                        placeholderSrc={gifLoading}
                                        height={290}
                                        width={210}
                                        alt={`Image ${ind}`}
                                      />
                                        {/* <img
                                          src={Bucket + image}
                                          height={290}
                                          width={210}
                                          // style={{ borderRadius: "10px" }}
                                          className="me-2 mb-2"
                                        /> */}
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </Col>

                            {/* Details */}
                            <Col sm={6} md={6} className="px-10 ">
                              <Row>
                                {/* Moving */}
                                <Col
                                  sm={12}
                                  md={12}
                                  className="px-0 bg-white card-border"
                                >
                                  {/* <div className="bg-white card-border"> */}
                                  <Row
                                    className="px-3 mx-0"
                                    style={{
                                      backgroundColor: "#00aff0 ",
                                      color: "white",
                                    }}
                                  >
                                    <Col
                                      sm={12}
                                      md={12}
                                      className="text-start px-0 left-col"
                                    >
                                      <h5 className="fn text-capitalize my-2">
                                        <b>Moving</b>
                                      </h5>
                                    </Col>
                                  </Row>

                                  {sr?.roomItems?.map((item) => {
                                    if (item?.type == "movable")
                                      movableItems = movableItems + 1;
                                    if (item?.type == "nonmovable")
                                      nonmovableItems = nonmovableItems + 1;

                                    return <></>;
                                  })}

                                  {movableItems > 0 ? (
                                    <>
                                      {sr?.roomItems?.map((item) => {
                                        return (
                                          <>
                                            {item?.type == "movable" && (
                                              <Row className="detail-row mx-3 detail-row-mg">
                                                <Col
                                                  xs={7}
                                                  sm={6}
                                                  md={7}
                                                  className="text-start px-0 left-col pt-2 card-item"
                                                >
                                                  {item?.name
                                                    ? item?.name
                                                    : "-"}
                                                </Col>
                                                <Col
                                                  xs={2}
                                                  sm={2}
                                                  md={2}
                                                  className="text-end px-0 left-col pt-2 card-item"
                                                  style={{ fontWeight: "500" }}
                                                >
                                                  {item?.quantity != undefined
                                                    ? `${item?.quantity}x`
                                                    : "-"}
                                                </Col>
                                                <Col
                                                  xs={3}
                                                  sm={4}
                                                  md={3}
                                                  className="text-end px-0 right-col pt-2 card-item"
                                                >
                                                  {item?.cbm != undefined
                                                    ? item?.cbm?.toFixed(4)
                                                    : "-"}{" "}
                                                  m<sup>3</sup>
                                                </Col>
                                              </Row>
                                            )}
                                          </>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    <Row className="detail-row mx-3 text-center">
                                      <i>No Items</i>
                                    </Row>
                                  )}

                                  {/* </div> */}
                                </Col>

                                {/* Not Moving */}
                                <Col
                                  sm={12}
                                  md={12}
                                  className="px-0 bg-white card-border mt-4"
                                >
                                  {/* <div className="bg-white card-border"> */}
                                  <Row
                                    className="px-3 mx-0"
                                    style={{
                                      backgroundColor: "#00aff0 ",
                                      color: "white",
                                    }}
                                  >
                                    <Col
                                      sm={12}
                                      md={12}
                                      className="text-start px-0 left-col"
                                    >
                                      <h5 className="fn text-capitalize my-2">
                                        <b>Not Moving</b>
                                      </h5>
                                    </Col>
                                  </Row>
                                  {nonmovableItems > 0 ? (
                                    <>
                                      {sr?.roomItems?.map((item) => {
                                        return (
                                          <>
                                            {item?.type == "nonmovable" && (
                                              <Row className="detail-row mx-3 detail-row-mg">
                                                <Col
                                                  xs={7}
                                                  sm={6}
                                                  md={7}
                                                  className="text-start px-0 left-col pt-2 card-item"
                                                >
                                                  {item?.name
                                                    ? item?.name
                                                    : "-"}
                                                </Col>
                                                <Col
                                                  xs={2}
                                                  sm={2}
                                                  md={2}
                                                  className="text-end px-0 left-col pt-2 card-item"
                                                  style={{ fontWeight: "500" }}
                                                >
                                                  {item?.quantity != undefined
                                                    ? `${item?.quantity}x`
                                                    : "-"}
                                                </Col>
                                                <Col
                                                  xs={3}
                                                  sm={4}
                                                  md={3}
                                                  className="text-end px-0 right-col pt-2 card-item"
                                                >
                                                  {item?.cbm != undefined
                                                    ? item?.cbm?.toFixed(4)
                                                    : "-"}{" "}
                                                  m<sup>3</sup>
                                                </Col>
                                              </Row>
                                            )}
                                          </>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    <Row className="detail-row mx-3 text-center">
                                      <i>No Items</i>
                                    </Row>
                                  )}

                                  {/* </div> */}
                                </Col>

                                {/* Packing Material */}
                                <Col
                                  sm={12}
                                  md={12}
                                  className="px-0 bg-white card-border mt-4"
                                >
                                  {/* <div className="bg-white card-border"> */}
                                  <Row
                                    className="px-3 mx-0"
                                    style={{
                                      backgroundColor: "#00aff0 ",
                                      color: "white",
                                    }}
                                  >
                                    <Col
                                      sm={12}
                                      md={12}
                                      className="text-start px-0 left-col"
                                    >
                                      <h5 className="fn text-capitalize my-2">
                                        <b>Packing Material</b>
                                      </h5>
                                    </Col>
                                  </Row>

                                  {sr?.packingMaterial?.map((pack) => {
                                    return (
                                      <>
                                        <Row className="detail-row mx-3 detail-row-mg">
                                          <Col
                                            xs={7}
                                            sm={6}
                                            md={7}
                                            className="text-start px-0 left-col pt-2 card-item"
                                          >
                                            {pack?.name ? pack?.name : "-"}
                                          </Col>
                                          <Col
                                            xs={2}
                                            sm={2}
                                            md={2}
                                            className="text-end px-0 left-col pt-2 card-item"
                                            style={{ fontWeight: "500" }}
                                          >
                                            {pack?.quantity != undefined
                                              ? `${pack?.quantity}x`
                                              : "-"}
                                          </Col>
                                          <Col
                                            xs={3}
                                            sm={4}
                                            md={3}
                                            className="text-end px-0 right-col pt-2 card-item"
                                          >
                                            {pack?.cbm != undefined
                                              ? pack?.cbm?.toFixed(4)
                                              : "-"}{" "}
                                            m<sup>3</sup>
                                          </Col>
                                        </Row>
                                      </>
                                    );
                                  })}

                                  {/* </div> */}
                                </Col>

                                {/* Remarks */}
                                <Col
                                  sm={12}
                                  md={12}
                                  className="px-0 bg-white card-border mt-4"
                                >
                                  {/* <div className="bg-white card-border"> */}
                                  <Row
                                    className="px-3 mx-0"
                                    style={{
                                      backgroundColor: "#00aff0 ",
                                      color: "white",
                                    }}
                                  >
                                    <Col
                                      sm={12}
                                      md={12}
                                      className="text-start px-0 left-col"
                                    >
                                      <h5 className="fn text-capitalize my-2">
                                        <b>Remarks</b>
                                      </h5>
                                    </Col>
                                  </Row>
                                  <Row className="detail-row mx-3 detail-row-mg">
                                    <Col
                                      sm={12}
                                      md={12}
                                      className="text-start px-0 left-col pt-2 card-item"
                                    >
                                      {sr?.remarks ? sr?.remarks : "-"}
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Row>
                      );
                    }
                  )}

                {/* Survey Overview*/}
                {/* <Accordion className="mb-4">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-content"
                id="panel-header"
              >
                <Typography className="accord-head">Survey Overview</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Row
                    className="mx-0 bg-white mt-3 py-1 px-2 px-md-4"
                    style={{ borderRadius: "5px" }}
                  >
                    <div className="detail-row row">
                      <div className="col-3 text-start px-0 ">
                        <p className="surve-email mb-0 p-word">
                          <b>Items</b>
                        </p>
                      </div>
                      <div className="col-3 text-start px-0 ">
                        <p className="surve-email mb-0 p-word">
                          <b>Room</b>
                        </p>
                      </div>
                      <div className="col-3 text-start px-0 ">
                        <p className="surve-email mb-0 p-word">
                          <b>Weight</b>
                        </p>
                      </div>
                      <div className="col-3 text-start px-0 ">
                        <p className="surve-email mb-0 p-word">
                          <b>CBM</b>
                        </p>
                      </div>
                    </div>
                    {surveyData?.roomWiseCalculation?.length > 0 &&
                      surveyData?.roomWiseCalculation?.map((cat, index) => {
                        totalWeight = totalWeight + cat?.totalWeight;
                        totalCbm = totalCbm + cat?.totalCbm;
                        totalItems = totalItems + cat?.totalItems;
                        return (
                          <div className="detail-row row">
                            <div className="col-3 text-start px-0 ">
                              <p className="surve-email mb-0 p-word">
                                {cat?.totalItems ? cat?.totalItems : "-"}
                              </p>
                            </div>
                            <div className="col-3 text-start px-0 ">
                              <p className="surve-email mb-0 p-word">
                                {cat?.roomName ? cat?.roomName : ""}
                              </p>
                            </div>
                            <div className="col-3 text-start px-0 ">
                              <p className="surve-email mb-0 p-word">
                                {cat?.totalWeightKg
                                  ? `${cat?.totalWeightKg} KGS`
                                  : "-"}
                              </p>
                            </div>
                            {cat?.totalCbm ? (
                              <div className="col-3 text-start px-0 ">
                                <p className="surve-email mb-0 p-word">
                                  {`${cat?.totalCbm} cm`}
                                  <sup>3</sup>
                                </p>
                              </div>
                            ) : (
                              <div className="col-3 text-start px-0 ">
                                {"-"}
                              </div>
                            )}
                          </div>
                        );
                      })}

                    <div className="detail-row-last row">
                      <div className="col-3 text-start px-0 ">
                        <p className="surve-email mb-0 p-word">
                          <b>Total</b>
                        </p>
                      </div>
                      <div className="col-3 text-start px-0 ">
                        <p className="surve-email mb-0 p-word">
                          <b>{totalWeight} kg</b>
                        </p>
                      </div>
                      <div className="col-3 text-start px-0 ">
                        <p className="surve-email mb-0 p-word">
                          <b>
                            {totalCbm} cm<sup>3</sup>
                          </b>
                        </p>
                      </div>
                      <div className="col-3 text-start px-0 ">
                        <p className="surve-email mb-0 p-word">
                          <b>{totalItems}</b>
                        </p>
                      </div>
                    </div>
                  </Row>
                </Typography>
              </AccordionDetails>
            </Accordion> */}

                {/* {surveyData?.surveyDetail?.surveyResult?.status != "quotesent" ? (
              <>
                <Row className="mx-1">
                  <Col sm={12} md={12} className="text-start px-0 fn">
                    <b>Shipment Mode Selection</b>
                  </Col>
                </Row>
                <Row
                  className="mx-0  bg-white mt-3 py-1"
                  style={{ borderRadius: "5px" }}
                >
                  <Col sm={12} md={12}>
                    <RadioGroup
                      aria-label="mode"
                      name="mode"
                      value={selectedMode}
                      onChange={handleModeChange}
                      row
                    >
                      <FormControlLabel
                        value="sea"
                        control={<Radio />}
                        label="By Sea"
                        className="me-5 fn"
                      />
                      <FormControlLabel
                        value="air"
                        control={<Radio />}
                        label="By Air"
                        className="me-5 fn"
                      />
                      <FormControlLabel
                        value="road"
                        control={<Radio />}
                        label="By Road"
                        className="fn"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
                <Row className="mx-1 mt-4">
                  <Col sm={12} md={12} className="text-start px-0 fn">
                    <b>Shipment Payment Plans</b>
                  </Col>
                </Row>

                {selectedMode == "road" || selectedMode == "air" ? (
                  <PaymentPlanForm onSubmit={sendQuotation} />
                ) : (
                  <SeaPaymentPlanForm onSubmit={sendQuotation} />
                )}
              </>
            ) : (
              <>
                <Row className="mx-1 mt-3">
                  <Col sm={12} md={12} className="text-start px-0 fn">
                    <b>Shipment Mode</b>
                  </Col>
                </Row>
                <Row
                  className="mx-0 bg-white mt-3 py-2 text-start px-1"
                  style={{ borderRadius: "5px" }}
                >
                  <Col sm={12} md={12} className="text-capitalize">
                    <p className="surve-email mb-0 p-word">
                      {" "}
                      By{" "}
                      {
                        surveyData?.surveyDetail?.surveyResult?.surveyQuote
                          ?.shipmentMethod
                      }
                    </p>
                  </Col>
                </Row>

                <Row className="mx-1 mt-4">
                  <Col sm={12} md={12} className="text-start px-0 fn">
                    <b>Shipment Payment Details</b>
                  </Col>
                </Row>

                {surveyData?.surveyDetail?.surveyResult?.surveyQuote
                  ?.shipmentMethod == "air" ||
                surveyData?.surveyDetail?.surveyResult?.surveyQuote
                  ?.shipmentMethod == "road" ? (
                  <Row
                    className="mx-0 bg-white mt-3 py-2 text-start px-1 px-md-3 "
                    style={{ borderRadius: "5px" }}
                  >
                    <Col
                      sm={6}
                      md={4}
                      lg={2}
                      className="text-capitalize col-b-right"
                    >
                      <p className="mode-grey">Door to Door</p>
                      <p className="mode-black">
                        {
                          surveyData?.surveyDetail?.surveyResult?.surveyQuote
                            ?.pricing?.doorToDoor
                        }
                      </p>
                    </Col>

                    <Col
                      sm={6}
                      md={4}
                      lg={2}
                      className="text-capitalize col-b-right"
                    >
                      <p className="mode-grey">Door to Port</p>
                      <p className="mode-black">
                        {
                          surveyData?.surveyDetail?.surveyResult?.surveyQuote
                            ?.pricing?.doorToPort
                        }
                      </p>
                    </Col>

                    <Col
                      sm={6}
                      md={4}
                      lg={2}
                      className="text-capitalize col-b-right"
                    >
                      <p className="mode-grey">Port to Door</p>
                      <p className="mode-black">
                        {
                          surveyData?.surveyDetail?.surveyResult?.surveyQuote
                            ?.pricing?.portToDoor
                        }
                      </p>
                    </Col>

                    <Col
                      sm={6}
                      md={4}
                      lg={2}
                      className="text-capitalize col-b-right"
                    >
                      <p className="mode-grey">Transportation Services</p>
                      <p className="mode-black">
                        {
                          surveyData?.surveyDetail?.surveyResult?.surveyQuote
                            ?.pricing?.transportService
                        }
                      </p>
                    </Col>
                    <Col
                      sm={6}
                      md={4}
                      lg={2}
                      className="text-capitalize col-b-right"
                    >
                      <p className="mode-grey">
                        Vehicle Import & Export Services
                      </p>
                      <p className="mode-black">
                        {
                          surveyData?.surveyDetail?.surveyResult?.surveyQuote
                            ?.pricing?.vehicleService
                        }
                      </p>
                    </Col>

                    <Col
                      sm={6}
                      md={4}
                      lg={2}
                      className="text-capitalize col-b-right"
                    >
                      <p className="mode-grey">Shipping & Packing Services</p>
                      <p className="mode-black">
                        {
                          surveyData?.surveyDetail?.surveyResult?.surveyQuote
                            ?.pricing?.packingService
                        }
                      </p>
                    </Col>
                  </Row>
                ) : (
                  <>
                    <Row
                      className="mx-0 bg-white mt-3 py-2 text-start px-1 px-md-3 "
                      style={{ borderRadius: "5px" }}
                    >
                      {surveyData?.surveyDetail?.surveyResult?.surveyQuote?.seaShipmentPricing?.map(
                        (pr, index) => {
                          return (
                            <>
                              <Row className="mx-0 mt-3 py-2 text-start px-1 ">
                                {pr?.containerSize == "twentyft" ? (
                                  <b>{index + 1}. Twenty Feet Container</b>
                                ) : (
                                  <b>{index + 1}. Forty Feet Container</b>
                                )}
                              </Row>

                              <Row className="mx-0 mt-3 py-2 text-start px-2 ">
                                <Col
                                  sm={6}
                                  md={4}
                                  lg={2}
                                  className="text-capitalize col-b-right"
                                >
                                  <p className="mode-grey">Door to Door</p>
                                  <p className="mode-black">
                                    {pr?.pricing?.doorToDoor}
                                  </p>
                                </Col>

                                <Col
                                  sm={6}
                                  md={4}
                                  lg={2}
                                  className="text-capitalize col-b-right"
                                >
                                  <p className="mode-grey">Door to Port</p>
                                  <p className="mode-black">
                                    {pr?.pricing?.doorToPort}
                                  </p>
                                </Col>

                                <Col
                                  sm={6}
                                  md={4}
                                  lg={2}
                                  className="text-capitalize col-b-right"
                                >
                                  <p className="mode-grey">Port to Door</p>
                                  <p className="mode-black">
                                    {pr?.pricing?.portToDoor}
                                  </p>
                                </Col>

                                <Col
                                  sm={6}
                                  md={4}
                                  lg={2}
                                  className="text-capitalize col-b-right"
                                >
                                  <p className="mode-grey">
                                    Transportation Services
                                  </p>
                                  <p className="mode-black">
                                    {pr?.pricing?.transportService}
                                  </p>
                                </Col>
                                <Col
                                  sm={6}
                                  md={4}
                                  lg={2}
                                  className="text-capitalize col-b-right"
                                >
                                  <p className="mode-grey">
                                    Vehicle Import & Export Services
                                  </p>
                                  <p className="mode-black">
                                    {pr?.pricing?.vehicleService}
                                  </p>
                                </Col>

                                <Col
                                  sm={6}
                                  md={4}
                                  lg={2}
                                  className="text-capitalize col-b-right"
                                >
                                  <p className="mode-grey">
                                    Shipping & Packing Services
                                  </p>
                                  <p className="mode-black">
                                    {pr?.pricing?.packingService}
                                  </p>
                                </Col>
                              </Row>
                            </>
                          );
                        }
                      )}
                    </Row>
                  </>
                )}
              </>
            )} */}
              </>
            )}
          </div>
        </div>
      ) : (
        <>
          {isLoading ? (
            <div> Loading...</div>
          ) : (
            <div> Ooops... Something went wrong...</div>
          )}
        </>
      )}

      <PrintComponent>
        <SurveyPreviewDownload id="content-id" surveyData={surveyData} />
      </PrintComponent>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={
            snackbarMessage?.includes("successful") ? "success" : "error"
          }
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
};
export default ClientSurveyPreview;
