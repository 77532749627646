import React, { useEffect, useState } from "react";
import { Button, Modal, Backdrop, Fade, Snackbar } from "@mui/material";
import crossImg from "../../imgs/cross.png";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import MuiAlert from "@mui/material/Alert";
import fileIcon from "../../imgs/file-icon.svg";
import { useNavigate } from "react-router-dom";
import { getSurveyFormList, getFormsList } from "../../api/services/services";

const CreateFileModal = ({ open, onClose, id }) => {
  const navigate = useNavigate();
  const [filesList, setFilesList] = useState([
    {
      name: "Quotaion Form",
      value: "survey/quotation-form",
      id: "be5690e1-df05-4791-a214-2235c757f0f3",
      isAdded: false,
    },
    {
      name: "Crew Instruction Document",
      value: "survey/crew-instruction-document",
      id: "3dcad948-1923-4a77-aecf-35c44f610753",
      isAdded: false,
    },
    {
      name: "Packing Request Form",
      value: "survey/packing-request-form",
      id: "795ed973-d542-45bb-b63d-f29f0c0f1693",
      isAdded: false,
    },
    {
      name: "Packing Material Issued Sheet",
      value: "survey/packing-material-issued-sheet",
      id: "178c68ff-0f74-471e-b64d-a5c0476a9380",
      isAdded: false,
    },
    {
      name: "Worksheet Import",
      value: "survey/worksheet-import",
      id: "5fb9aeb9-e1a3-4763-ada6-1eb83a8491b0",
      isAdded: false,
    },
    {
      name: "Weight Ticket",
      value: "survey/weight-ticket",
      id: "e57266c3-322b-4a90-858b-806d2557e605",
      isAdded: false,
    },
    {
      name: "Wooden Lift Van Quality Check Report",
      value: "survey/wooden-lift-van-quality-check-report",
      id: "7ee33eb8-5471-47d3-837b-aeb0914bea73",
      isAdded: false,
    },
    {
      name: "Shipping Instructions",
      value: "survey/shipping-instructions",
      id: "3d6d82dd-e980-4fe9-bb5f-2b0877de9354",
      isAdded: false,
    },
    {
      name: "Item Condition Report at Origin",
      value: "survey/item-condition-report-at-origin",
      id: "19d7db4c-2bdd-492e-8b5f-5e030345fa47",
      isAdded: false,
    },
    {
      name: "Delivery Receipt",
      value: "survey/delivery-receipt",
      id: "0cc862ad-1e4b-4167-a50b-942747f35f57",
      isAdded: false,
    },
    {
      name: "Delivery Request Form",
      value: "survey/delivery-request-form",
      isAdded: false,
      id: "a4a04109-6d40-4c84-9116-d82fbd27133a",
    },
    {
      name: "Invoice",
      value: "survey/invoice",
      id: "664aa0b2-661d-4311-a496-747c2b45ec87",
      isAdded: false,
    },
    {
      name: "Inbound Move Checklist 01",
      value: "survey/inbound-move-checklist-form-01",
      id: "a72bee18-cfd0-4ffc-971c-198b5e5b6a63",
      isAdded: false,
    },
    {
      name: "Inbound Move Checklist 02",
      value: "survey/inbound-move-checklist-form-02",
      isAdded: false,
    },
    {
      name: "Outbound Move Checklist 01",
      value: "survey/outbound-move-checklist-form-01",
      isAdded: false,
    },
    {
      name: "Outbound Move Checklist 02",
      value: "survey/outbound-move-checklist-form-02",
      isAdded: false,
    },
    {
      name: "Load Unload Report",
      value: "survey/load-unload-report",
      id: "ba57ab91-9724-4614-af97-61705090fd37",
      isAdded: false,
    },
    {
      name: "Packing Inventory",
      value: "survey/packing-inventory",
      id: "1f81d381-5fdf-453f-ba00-e1ca03404eac",
      isAdded: false,
    },
    {
      name: "Inter Trans Insurance Services",
      value: "survey/inter-trans-insurance-services",
      id: "63b9c5c6-ca4e-40b1-a332-edcbecba98fa",
      isAdded: false,
    },
  ]);

  const [disableCreate, setDisableCreate] = useState(true);
  const [selectedFile, setSelectedFile] = useState({
    value:'abc',
    name:'Select File'
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    getSurveyFormList(id)
      .then((res) => {
        let values = [...filesList]
        res?.data?.data?.map((fm, ind)=>{
          filesList?.map((ls, index)=>{
            if(fm?.form?.formId === ls?.id){
              values[index].isAdded = true
            }

          })

        })

        setFilesList(values)
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

      });
  }, []);

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="modal-container">
            {" "}
            {/* CSS class for centering */}
            <div className="modal-content assign-modal px-4">
              <div className="d-flex">
                <img
                  src={crossImg}
                  height="26"
                  width="26"
                  className="ms-auto cursor-pointer"
                  onClick={() => onClose()}
                />{" "}
              </div>
              {/* Modal content goes here */}
              <p className="modal-head mb-0">Create File</p>
              <p className="modal-subhead">
                You can create your valuable files here{" "}
              </p>

              {/*  */}
              <FormControl sx={{ m: 1, minWidth: 120, margin: "8px 0px" }}>
                <Select
                  value={selectedFile}
                  onChange={(e) => {
                    setSelectedFile(e?.target?.value);
                    // console.log(e?.target?.value?.value);
                    setDisableCreate(false);
                  }}
                  // displayEmpty
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  placeholder="Select Surveyor.."
                >
                  <MenuItem value={"abc"} disabled>
                    Select File
                  </MenuItem>
                  {filesList?.length == 0 ? (
                    <>
                      <MenuItem value={"abc"} disabled>
                        No data to select..
                      </MenuItem>
                    </>
                  ) : (
                    filesList?.map((sr, index) => {
                      return (
                        <MenuItem value={sr}>
                          <div className="d-flex align-items-center mb-1">
                            <div className="blue-circle">
                              <img src={fileIcon} className="img-pr" />
                            </div>
                            <div className="ms-2">
                              <p className="surve-name mb-0">{sr?.name}</p>
                            </div>
                          </div>
                        </MenuItem>
                      );
                    })
                  )}
                </Select>
              </FormControl>

              <Grid container spacing={2} className="mt-4">
                <Grid item xs={12}>
                  <Button
                    type="button"
                    variant="contained"
                    className={
                      disableCreate
                        ? "disabled-btn mt-3 mb-3"
                        : "submit-btn mt-3 mb-3"
                    }
                    disabled={disableCreate}
                    onClick={() => {
                      console.log(selectedFile)
                      let flg = 1;
                      if (selectedFile?.isAdded) flg = 2;
                      navigate(
                        `/${selectedFile.value}/${flg}/${id}/${selectedFile.id}`
                      );
                    }}
                  >
                    Create
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </Fade>
      </Modal>

      {/*  */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={
            snackbarMessage?.includes("successful") ? "success" : "error"
          }
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default CreateFileModal;
