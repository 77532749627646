import React from "react";
import TextField from "@mui/material/TextField";
import "./style.css";

const ReasonInput = ({ value, onChange, error }) => {
  const inputLabelProps = {
    shrink: true,
    style: {
      color: "black", // Set the color to black
      fontWeight: "600", // Set the font weight to bold
      fontSize: '15px'
    },
  };

  const inputProps = {
    style: {
    //   height: "45px", // Set the height to 40 pixels
    padding: '8px 8px'
    },
  };
  return (
    <TextField
    rows={3} // Set the number of rows to control the height
      type="text"
      label="Reason"
      variant="outlined"
      placeholder="Enter reason for rejecting.."
      fullWidth
      value={value}
      onChange={onChange}
      error={error}
      helperText={error && "Please enter reason"}
      InputLabelProps={inputLabelProps}
      InputProps={inputProps}
      multiline
      required
    />
  );
};

export default ReasonInput;
