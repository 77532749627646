import React, { useEffect, useState } from "react";
import {
  getSingleSurvey,
  updateStatus,
  getSurveyFormList,
} from "../../../api/services/services";
import backArrow from "../../../imgs/BackArrow.svg";
import MainTemplate from "../../templates/MainTemplate";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { Button, Snackbar } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Row, Col } from "react-bootstrap";
import AssignSurveyorModal from "../../../components/molecules/AssignSurveyorModal";
import SuccessModal from "../../molecules/SuccessModal";
import MuiAlert from "@mui/material/Alert";
import RejectModal from "../../molecules/RejectModal";
import { Bucket } from "../../../api/config/apiConfig";
import CreateFileModal from "../../molecules/CreateFileModal";
import fileIcon from "../../../imgs/file-icon.svg";
import gifLoading from "../../../imgs/giphy.gif";
import LazyImage from "../LazyLoadImage";

const SurveyDetailsPage = () => {
  const id = window.location.pathname.split("/")[2];
  const [surveyData, setSurveyData] = useState(null);
  const navigate = useNavigate();
  const [showAssign, setShowAssign] = useState(false);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState(null);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [reject, setReject] = useState(false);

  const [successHead, setSuccessHead] = useState("");
  const [successSub, setSuccessSub] = useState("");

  const [showCreateFile, setShowCreateFile] = useState(false);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // fetch single survey details
  const getSurvey = () => {
    // console.log(id);
    getSingleSurvey(id)
      .then((response) => {
        setSurveyData(response?.data?.data);
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }
        console.error("Error fetching survey:", error?.response?.data);
      });
  };

  //
  const handleStatus = (val, id) => {
    const body = {
      status: val,
    };
    if (val === "rejected") {
      setReject(true);
    } else {
      updateStatus(id, body)
        .then((response) => {
          setSnackbarMessage("Survey status updated successfully!");
          setOpenSnackbar(true);
          getSurvey();
        })
        .catch((error) => {
          if (error?.response?.data?.statusCode == 401) {
            localStorage.clear();
            window.location.pathname = "/login";
          } else if (error?.response?.data?.statusCode == 1002) {
            // setSnackbarMessage("Token has been expired");
            // setOpenSnackbar(true);
          } else {
            setSnackbarMessage(error?.response?.data?.message);
            setOpenSnackbar(true);
          }
        });
    }
  };

  useEffect(() => {
    getSurvey();
  }, []);

  const convertDate = (unixTimestamp) => {
    const date = new Date(unixTimestamp * 1000); // Multiply by 1000 to convert from seconds to milliseconds
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1 and format with leading zero
    const dd = String(date.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  let movableItems = 0;
  let nonmovableItems = 0;

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // 11th, 12th, 13th, etc.
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  // Helper function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const ordinalSuffix = getOrdinalSuffix(day);

    return `${day}${ordinalSuffix} ${month}, ${year}`;
  };

  return (
    <>
      <MainTemplate>
        <div className="d-block d-sm-flex align-items-center py-4">
          <div className="d-flex align-items-center ">
            <div
              className="light-blue-color back-bg cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <img src={backArrow} height="24" width="24" />{" "}
            </div>
            <div>
              <p className="main-head ps-3 mb-0">Survey Details</p>
            </div>
          </div>

          {/* CTA */}
          <div className="d-flex ms-auto justify-content-center mt-2 mt-sm-0">
            {/* Create File */}
            {/* <Button
                className="active-btn"
                onClick={() => {
                  setShowCreateFile(true);
                }}
              >
                Create File
              </Button> */}

            {/* Assign Button */}
            {(surveyData?.status == "new" ||
              surveyData?.status == "rejected") && (
              <Button
                className="active-btn ms-2"
                onClick={() => {
                  setShowAssign(true);
                }}
              >
                Assign
              </Button>
            )}

            {/* Unassign Button */}
            {surveyData?.status == "assigned" && (
              <Button
                className="active-btn  ms-2"
                onClick={() => {
                  handleStatus("un_assign", surveyData?.uuid);
                }}
              >
                Un Assign
              </Button>
            )}

            {/* Reject Button */}
            {(surveyData?.status == "new" ||
              surveyData?.status == "assigned") && (
              <Button
                className="active-btn ms-2"
                onClick={() => {
                  handleStatus("rejected", surveyData?.uuid);
                }}
              >
                Reject
              </Button>
            )}
          </div>
        </div>

        {/* Personal Information */}
        <Accordion className="mb-4">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-content"
            id="panel-header"
          >
            <Typography className="accord-head">
              Personal Information
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Row className="detail-row">
                <Col sm={6} md={8} className="text-start px-0 left-col">
                  Name
                </Col>
                <Col
                  sm={6}
                  md={4}
                  className="text-start px-0 right-col text-capitalize"
                >
                  {surveyData?.name ? surveyData?.name : "-"}
                </Col>
              </Row>

              <Row className="detail-row">
                <Col sm={6} md={8} className="text-start px-0 left-col">
                  Email
                </Col>
                <Col sm={6} md={4} className="text-start px-0 right-col">
                  {surveyData?.email ? surveyData?.email : "-"}
                </Col>
              </Row>

              <Row
                className={
                  surveyData?.status == "new" ? "detail-row" : "detail-row"
                }
              >
                <Col sm={6} md={8} className="text-start px-0 left-col">
                  Status
                </Col>
                <Col
                  sm={6}
                  md={4}
                  className="text-start px-0 right-col text-capitalize"
                >
                  {surveyData?.status ? surveyData?.status : "-"}
                </Col>
              </Row>
              {surveyData?.status != "new" && (
                <Row className="detail-row">
                  <Col sm={6} md={8} className="text-start px-0 left-col">
                    Assignee
                  </Col>
                  <Col
                    sm={6}
                    md={4}
                    className="text-start px-0 right-col text-capitalize"
                  >
                    {surveyData?.surveyer?.name
                      ? surveyData?.surveyer?.name
                      : "-"}
                  </Col>
                </Row>
              )}
              <Row className="detail-row-last">
                <Col sm={6} md={8} className="text-start px-0 left-col">
                  Description
                </Col>
                <Col
                  sm={6}
                  md={4}
                  className="text-start px-0 right-col text-capitalize"
                >
                  {surveyData?.description ? surveyData?.description : "-"}
                </Col>
              </Row>
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* Loading Place Information */}
        <Accordion className="mb-4">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-content"
            id="panel-header"
          >
            <Typography className="accord-head">Origin</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Row className="detail-row">
                <Col sm={6} md={8} className="text-start px-0 left-col">
                  Telephone
                </Col>
                <Col sm={6} md={4} className="text-start px-0 right-col">
                  {surveyData?.loadPlacePhone
                    ? surveyData?.loadPlacePhone
                    : "-"}
                </Col>
              </Row>
              <Row className="detail-row">
                <Col sm={6} md={8} className="text-start px-0 left-col">
                  Street Address
                </Col>
                <Col sm={6} md={4} className="text-start px-0 right-col">
                  {surveyData?.loadPlaceStAddress
                    ? surveyData?.loadPlaceStAddress
                    : "-"}
                </Col>
              </Row>
              <Row className="detail-row">
                <Col sm={6} md={8} className="text-start px-0 left-col">
                  Zip Code
                </Col>
                <Col sm={6} md={4} className="text-start px-0 right-col">
                  {surveyData?.loadPlaceZip ? surveyData?.loadPlaceZip : "-"}
                </Col>
              </Row>
              <Row className="detail-row">
                <Col sm={6} md={8} className="text-start px-0 left-col">
                  Country
                </Col>
                <Col
                  sm={6}
                  md={4}
                  className="text-start px-0 right-col text-capitalize"
                >
                  {surveyData?.loadPlaceCountry
                    ? surveyData?.loadPlaceCountry
                    : "-"}
                </Col>
              </Row>
              <Row className="detail-row">
                <Col sm={6} md={8} className="text-start px-0 left-col">
                  City
                </Col>
                <Col
                  sm={6}
                  md={4}
                  className="text-start px-0 right-col text-capitalize"
                >
                  {surveyData?.loadPlaceCity ? surveyData?.loadPlaceCity : "-"}
                </Col>
              </Row>
              <Row className="detail-row">
                <Col sm={6} md={8} className="text-start px-0 left-col">
                  Removal Date
                </Col>
                <Col sm={6} md={4} className="text-start px-0 right-col">
                  {surveyData?.loadDate
                    ? formatDate(convertDate(surveyData?.loadDate))
                    : "-"}
                </Col>
              </Row>
              <Row className="detail-row-last">
                <Col sm={6} md={8} className="text-start px-0 left-col">
                  Survey Date
                </Col>
                <Col sm={6} md={4} className="text-start px-0 right-col">
                  {surveyData?.loadDate
                    ? formatDate(convertDate(surveyData?.surveyDate))
                    : "-"}
                </Col>
              </Row>
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* Unloading Place Information */}
        <Accordion className="mb-4">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-content"
            id="panel-header"
          >
            <Typography className="accord-head">Destination</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Row className="detail-row">
                <Col sm={6} md={8} className="text-start px-0 left-col">
                  Telephone
                </Col>
                <Col sm={6} md={4} className="text-start px-0 right-col">
                  {surveyData?.unloadPlacePhone
                    ? surveyData?.unloadPlacePhone
                    : "-"}
                </Col>
              </Row>
              <Row className="detail-row">
                <Col sm={6} md={8} className="text-start px-0 left-col">
                  Street Address
                </Col>
                <Col sm={6} md={4} className="text-start px-0 right-col">
                  {surveyData?.unloadPlaceStAddress
                    ? surveyData?.unloadPlaceStAddress
                    : "-"}
                </Col>
              </Row>
              <Row className="detail-row">
                <Col sm={6} md={8} className="text-start px-0 left-col">
                  Zip Code
                </Col>
                <Col sm={6} md={4} className="text-start px-0 right-col">
                  {surveyData?.unloadPlaceZip
                    ? surveyData?.unloadPlaceZip
                    : "-"}
                </Col>
              </Row>
              <Row className="detail-row">
                <Col sm={6} md={8} className="text-start px-0 left-col ">
                  Country
                </Col>
                <Col
                  sm={6}
                  md={4}
                  className="text-start px-0 right-col text-capitalize"
                >
                  {surveyData?.unloadPlaceCountry
                    ? surveyData?.unloadPlaceCountry
                    : "-"}
                </Col>
              </Row>
              <Row className="detail-row-last">
                <Col sm={6} md={8} className="text-start px-0 left-col">
                  City
                </Col>
                <Col
                  sm={6}
                  md={4}
                  className="text-start px-0 right-col text-capitalize"
                >
                  {surveyData?.unloadPlaceCity
                    ? surveyData?.unloadPlaceCity
                    : "-"}
                </Col>
              </Row>
            </Typography>
          </AccordionDetails>
        </Accordion>

        {surveyData?.status == "submitted" && (
          <>
            {surveyData?.surveyResult?.surveyRooms?.length > 0 && (
              <Row className="mx-1 mb-3">
                <Col sm={12} md={12} className="text-start px-0 ">
                  <b>Rooms Detail</b>
                </Col>
              </Row>
            )}
          </>
        )}

        {surveyData?.surveyResult?.surveyRooms?.length > 0 &&
          surveyData?.surveyResult?.surveyRooms?.map((sr, ind) => {
            return (
              <Row
                className="bg-white my-4 mx-0  p-3 py-4 mb-4"
                style={{ borderRadius: "10px" }}
              >
                <Row className="">
                  <Col sm={12} md={12} className="text-start px-0 left-col">
                    <h4 className="fn text-capitalize">
                      <b>
                        {ind + 1}
                        {". "}
                        {sr?.name}
                      </b>
                    </h4>
                  </Col>
                </Row>
                <Row>
                  {/* Images */}
                  <Col sm={6} md={6} className="px-10">
                    <div className="d-flex flex-wrap">
                      {sr?.images?.map((image, index) => {
                        return (
                          <>
                            <div className="me-2 mb-2">
                              <LazyImage
                                key={index}
                                src={Bucket + image}
                                placeholderSrc={gifLoading}
                                height={290}
                                width={210}
                                alt={`Image ${ind}`}
                              />
                              {/* <img
                                src={Bucket + image}
                                height={290}
                                width={210}
                                // style={{ borderRadius: "10px" }}
                                className="me-2 mb-2"
                              /> */}
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </Col>

                  {/* Details */}
                  <Col sm={6} md={6} className="px-10 ">
                    <Row>
                      {/* Moving */}
                      <Col
                        sm={12}
                        md={12}
                        className="px-0 bg-white card-border"
                      >
                        {/* <div className="bg-white card-border"> */}
                        <Row
                          className="px-3 mx-0"
                          style={{
                            backgroundColor: "#00aff0 ",
                            color: "white",
                          }}
                        >
                          <Col
                            sm={12}
                            md={12}
                            className="text-start px-0 left-col"
                          >
                            <h5 className="fn text-capitalize my-2">
                              <b>Moving</b>
                            </h5>
                          </Col>
                        </Row>

                        {sr?.roomItems?.map((item) => {
                          if (item?.type == "movable")
                            movableItems = movableItems + 1;
                          if (item?.type == "nonmovable")
                            nonmovableItems = nonmovableItems + 1;

                          return <></>;
                        })}

                        {movableItems > 0 ? (
                          <>
                            {sr?.roomItems?.map((item) => {
                              return (
                                <>
                                  {item?.type == "movable" && (
                                    <Row className="detail-row mx-3 detail-row-mg">
                                      <Col
                                        xs={7}
                                        sm={6}
                                        md={7}
                                        className="text-start px-0 left-col pt-2 card-item"
                                      >
                                        {item?.name ? item?.name : "-"}
                                      </Col>
                                      <Col
                                        xs={2}
                                        sm={2}
                                        md={2}
                                        className="text-end px-0 left-col pt-2 card-item"
                                        style={{ fontWeight: "500" }}
                                      >
                                        {item?.quantity != undefined
                                          ? `${item?.quantity}x`
                                          : "-"}
                                      </Col>
                                      <Col
                                        xs={3}
                                        sm={4}
                                        md={3}
                                        className="text-end px-0 right-col pt-2 card-item"
                                      >
                                        {item?.cbm != undefined
                                          ? item?.cbm?.toFixed(4)
                                          : "-"}{" "}
                                        m<sup>3</sup>
                                      </Col>
                                    </Row>
                                  )}
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <Row className="detail-row mx-3 text-center">
                            <i>No Items</i>
                          </Row>
                        )}

                        {/* </div> */}
                      </Col>

                      {/* Not Moving */}
                      <Col
                        sm={12}
                        md={12}
                        className="px-0 bg-white card-border mt-4"
                      >
                        {/* <div className="bg-white card-border"> */}
                        <Row
                          className="px-3 mx-0"
                          style={{
                            backgroundColor: "#00aff0 ",
                            color: "white",
                          }}
                        >
                          <Col
                            sm={12}
                            md={12}
                            className="text-start px-0 left-col"
                          >
                            <h5 className="fn text-capitalize my-2">
                              <b>Not Moving</b>
                            </h5>
                          </Col>
                        </Row>
                        {nonmovableItems > 0 ? (
                          <>
                            {sr?.roomItems?.map((item) => {
                              return (
                                <>
                                  {item?.type == "nonmovable" && (
                                    <Row className="detail-row mx-3 detail-row-mg">
                                      <Col
                                        xs={7}
                                        sm={6}
                                        md={7}
                                        className="text-start px-0 left-col pt-2 card-item"
                                      >
                                        {item?.name ? item?.name : "-"}
                                      </Col>
                                      <Col
                                        xs={2}
                                        sm={2}
                                        md={2}
                                        className="text-end px-0 left-col pt-2 card-item"
                                        style={{ fontWeight: "500" }}
                                      >
                                        {item?.quantity != undefined
                                          ? `${item?.quantity}x`
                                          : "-"}
                                      </Col>
                                      <Col
                                        xs={3}
                                        sm={4}
                                        md={3}
                                        className="text-end px-0 right-col pt-2 card-item"
                                      >
                                        {item?.cbm != undefined
                                          ? item?.cbm?.toFixed(4)
                                          : "-"}{" "}
                                        m<sup>3</sup>
                                      </Col>
                                    </Row>
                                  )}
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <Row className="detail-row mx-3 text-center">
                            <i>No Items</i>
                          </Row>
                        )}

                        {/* </div> */}
                      </Col>

                      {/* Packing Material */}
                      <Col
                        sm={12}
                        md={12}
                        className="px-0 bg-white card-border mt-4"
                      >
                        {/* <div className="bg-white card-border"> */}
                        <Row
                          className="px-3 mx-0"
                          style={{
                            backgroundColor: "#00aff0 ",
                            color: "white",
                          }}
                        >
                          <Col
                            sm={12}
                            md={12}
                            className="text-start px-0 left-col"
                          >
                            <h5 className="fn text-capitalize my-2">
                              <b>Packing Material</b>
                            </h5>
                          </Col>
                        </Row>

                        {sr?.packingMaterial?.map((pack) => {
                          return (
                            <>
                              <Row className="detail-row mx-3 detail-row-mg">
                                <Col
                                  xs={7}
                                  sm={6}
                                  md={7}
                                  className="text-start px-0 left-col pt-2 card-item"
                                >
                                  {pack?.name ? pack?.name : "-"}
                                </Col>
                                <Col
                                  xs={2}
                                  sm={2}
                                  md={2}
                                  className="text-end px-0 left-col pt-2 card-item"
                                  style={{ fontWeight: "500" }}
                                >
                                  {pack?.quantity != undefined
                                    ? `${pack?.quantity}x`
                                    : "-"}
                                </Col>
                                <Col
                                  xs={3}
                                  sm={4}
                                  md={3}
                                  className="text-end px-0 right-col pt-2 card-item"
                                >
                                  {pack?.cbm != undefined
                                    ? pack?.cbm?.toFixed(4)
                                    : "-"}{" "}
                                  m<sup>3</sup>
                                </Col>
                              </Row>
                            </>
                          );
                        })}

                        {/* </div> */}
                      </Col>

                      {/* Remarks */}
                      <Col
                        sm={12}
                        md={12}
                        className="px-0 bg-white card-border mt-4"
                      >
                        {/* <div className="bg-white card-border"> */}
                        <Row
                          className="px-3 mx-0"
                          style={{
                            backgroundColor: "#00aff0 ",
                            color: "white",
                          }}
                        >
                          <Col
                            sm={12}
                            md={12}
                            className="text-start px-0 left-col"
                          >
                            <h5 className="fn text-capitalize my-2">
                              <b>Remarks</b>
                            </h5>
                          </Col>
                        </Row>
                        <Row className="detail-row mx-3 detail-row-mg">
                          <Col
                            sm={12}
                            md={12}
                            className="text-start px-0 left-col pt-2 card-item"
                          >
                            {sr?.remarks ? sr?.remarks : "-"}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Row>
            );
          })}

        {/* Items Details */}
        {/* {surveyData?.status == "submitted" && ( */}
        <>
          {/* {surveyData?.surveyResult?.surveyItems?.length > 0 && (
              <Row className="mx-1 mb-3">
                <Col sm={12} md={12} className="text-start px-0 ">
                  <b>Items Detail</b>
                </Col>
              </Row>
            )} */}

          {/* {surveyData?.surveyResult?.surveyItems?.length > 0 &&
              surveyData?.surveyResult?.surveyItems?.map((sr, ind) => {
                return (
                  <Row
                    className="bg-white my-4 mx-0  p-3 py-4 mb-4"
                    style={{ borderRadius: "10px" }}
                  >
                    <Row className="">
                      <Col sm={12} md={12} className="text-start px-0 left-col">
                        <h3 className="fn text-capitalize">
                          <b>{sr?.name}</b>
                        </h3>
                      </Col>
                    </Row>
                    <Row className="">
                      <Col sm={6} md={4} className="text-start px-0 left-col">
                        <img
                          src={Bucket + sr?.image}
                          height={116}
                          width={116}
                          style={{ borderRadius: "10px" }}
                        />
                      </Col>
                    </Row>
                    <Row className="detail-row ">
                      <Col
                        xs={7}
                        sm={6}
                        md={8}
                        className="text-start px-0 left-col pt-2"
                      >
                        Quantity
                      </Col>
                      <Col
                        xs={5}
                        sm={6}
                        md={4}
                        className="text-start px-0 right-col pt-2"
                      >
                        {sr?.quantity ? sr?.quantity : "-"}
                      </Col>
                    </Row>
                    <Row className="detail-row">
                      <Col
                        xs={7}
                        sm={6}
                        md={8}
                        className="text-start px-0 left-col"
                      >
                        Item Type
                      </Col>
                      <Col
                        xs={5}
                        sm={6}
                        md={4}
                        className="text-start px-0 right-col text-capitalize"
                      >
                        {sr?.type ? sr?.type : "-"}
                      </Col>
                    </Row>
                    <Row className="detail-row">
                      <Col
                        xs={7}
                        sm={6}
                        md={8}
                        className="text-start px-0 left-col"
                      >
                        Length
                      </Col>
                      <Col
                        xs={5}
                        sm={6}
                        md={4}
                        className="text-start px-0 right-col"
                      >
                        {sr?.lengthCm ? `${sr?.lengthCm} cm` : "-"}
                      </Col>
                    </Row>
                    <Row className="detail-row">
                      <Col
                        xs={7}
                        sm={6}
                        md={8}
                        className="text-start px-0 left-col"
                      >
                        Height
                      </Col>
                      <Col
                        xs={5}
                        sm={6}
                        md={4}
                        className="text-start px-0 right-col"
                      >
                        {sr?.heightCm ? `${sr?.heightCm} cm` : "-"}
                      </Col>
                    </Row>
                    <Row className="detail-row">
                      <Col
                        xs={7}
                        sm={6}
                        md={8}
                        className="text-start px-0 left-col"
                      >
                        Width
                      </Col>
                      <Col
                        xs={5}
                        sm={6}
                        md={4}
                        className="text-start px-0 right-col"
                      >
                        {sr?.widthCm ? `${sr?.widthCm} cm` : "-"}
                      </Col>
                    </Row>
                    <Row className="detail-row">
                      <Col
                        xs={7}
                        sm={6}
                        md={8}
                        className="text-start px-0 left-col"
                      >
                        Weight
                      </Col>
                      <Col
                        xs={5}
                        sm={6}
                        md={4}
                        className="text-start px-0 right-col"
                      >
                        {sr?.weightKg ? `${sr?.weightKg} kg` : "-"}
                      </Col>
                    </Row>

                    <Row className="detail-row-last">
                      <Col
                        xs={7}
                        sm={6}
                        md={8}
                        className="text-start px-0 left-col"
                      >
                        CBM
                      </Col>
                      {sr?.cbm ? (
                        <Col
                          xs={5}
                          sm={6}
                          md={4}
                          className="text-start px-0 right-col"
                        >
                          {`${sr?.cbm} cm`}
                          <sup>3</sup>
                        </Col>
                      ) : (
                        <Col
                          xs={5}
                          sm={6}
                          md={4}
                          className="text-start px-0 right-col"
                        >
                          {"-"}
                        </Col>
                      )}
                    </Row>
                  </Row>
                );
              })} */}
        </>
        {/* )} */}

        {surveyData?.status == "submitted" && (
          <>
            {surveyData?.surveyResult?.surveyRooms?.length > 0 && (
              <Row className="mx-1 mb-3">
                <Col sm={4} md={4} className="text-start px-0 ">
                  <Button
                    type="button"
                    variant="contained"
                    className="submit-btn mt-1 text-capitalize"
                    onClick={() =>
                      navigate(`/survey-preview/${surveyData?.uuid}`)
                    }
                  >
                    Preview
                  </Button>
                </Col>
              </Row>
            )}
          </>
        )}

        {/* Assign Surveyor */}
        {showAssign && (
          <AssignSurveyorModal
            open={showAssign}
            onClose={() => setShowAssign(false)}
            onSucces={(val) => {
              setEmail(val);
              setSuccessHead("Survey Assigned Successfully");
              setSuccessSub("You have successfully assigned survey to ");
              setShowAssign(false);
              setSuccess(true);
              getSurvey();
            }}
            id={id}
          />
        )}

        {/* Success Modal */}
        <SuccessModal
          open={success}
          onClose={() => setSuccess(false)}
          email={email}
          head={successHead}
          sub={successSub}
        />

        {/* Reject Survey */}
        {reject && (
          <RejectModal
            open={reject}
            onClose={() => setReject(false)}
            onSucces={() => {
              setEmail("");
              setReject(false);
              setSuccess(true);
              setSuccessHead("Survey Rejected Successfully");
              setSuccessSub("You have successfully rejected the survey");
              getSurvey();
            }}
            id={id}
          />
        )}

        {/* Create File Modal */}
        <CreateFileModal
          open={showCreateFile}
          onClose={() => setShowCreateFile(false)}
          id={id}
        />

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000} // Adjust the duration as needed
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={
              snackbarMessage?.includes("successful") ? "success" : "error"
            }
            onClose={handleCloseSnackbar}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </MainTemplate>
    </>
  );
};
export default SurveyDetailsPage;
