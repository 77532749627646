import React, { useEffect, useState } from "react";
import { surveyResultPreview, sendQuote } from "../../../api/services/services";
import backArrow from "../../../imgs/BackArrow.svg";
import MainTemplate from "../../templates/MainTemplate";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { Button, Snackbar } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Row, Col } from "react-bootstrap";
import SuccessModal from "../../molecules/SuccessModal";
import MuiAlert from "@mui/material/Alert";
import PaymentPlanForm from "../../molecules/PaymentPlanForm";
import callIcon from "../../../imgs/call.svg";
import calenderIcon from "../../../imgs/calendar.svg";
import calenderMove from "../../../imgs/calendarMinus.svg";
import emailIcon from "../../../imgs/email.svg";
import location from "../../../imgs/location.svg";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import SeaPaymentPlanForm from "../../molecules/SeaPaymentPlanForm";
import "./../SurveyPreviewPage/style.css";
import { Bucket } from "../../../api/config/apiConfig";

const SurveyPreviewDownload = ({ surveyData }) => {
  const id = window.location.pathname.split("/")[2];

  const navigate = useNavigate();

  const [success, setSuccess] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  var totalWeight = 0;
  var totalCbm = 0;
  var totalItems = 0;

  const [selectedMode, setSelectedMode] = useState("air"); // Initialize with an empty string or the default value

  const handleModeChange = (event) => {
    console.log(event.target.value);
    setSelectedMode(event.target.value);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const convertDate = (unixTimestamp) => {
    const date = new Date(unixTimestamp * 1000); // Multiply by 1000 to convert from seconds to milliseconds
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1 and format with leading zero
    const dd = String(date.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // 11th, 12th, 13th, etc.
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  // Helper function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const ordinalSuffix = getOrdinalSuffix(day);

    return `${day}${ordinalSuffix} ${month}, ${year}`;
  };

  let movableItems = 0;
  let nonmovableItems = 0;

  return (
    <>
      {/* <MainTemplate> */}
      <div className="mx-4">
        {/* <div className="d-block d-sm-flex align-items-center pb-1 pt-0 ">
          <div className="d-flex align-items-center justify-content-center w-100">
            <p className="ps-0 mb-0 head-top"> Survey Preview</p>
          </div>
        </div> */}

        {surveyData && (
          <>
            <Row className="mx-0 px-2 py-3 preview-card mt-1">
              <Row>
                <Col md={12} className="text-start">
                  <h4 className="fn">
                    <b>{surveyData?.surveyDetail?.name}</b>
                  </h4>
                </Col>
              </Row>
              <Row >
                <Col sm={4} className="mb-2 pe-0">
                  <div className="d-flex align-items-start">
                    <div className="white-circle mt-1">
                      <img src={emailIcon} className="img-pr" />
                    </div>
                    <div className="ps-2">
                      <p className="surve-name mb-0">
                        <b>Email</b>
                      </p>
                      <p className="surve-email mb-0 p-word">
                        {surveyData?.surveyDetail?.email}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col sm={4} className="mb-2">
                  <div className="d-flex align-items-start">
                    <div className="white-circle mt-1">
                      <img
                        src={location}
                        height={24}
                        width={24}
                        className="img-pr"
                      />
                    </div>
                    <div className="ms-2">
                      <p className="surve-name mb-0">Origin Address</p>
                      <p className="surve-email mb-0 p-word">
                        {surveyData?.surveyDetail?.loadPlaceStAddress},{" "}
                        {surveyData?.surveyDetail?.loadPlaceCity},{" "}
                        {surveyData?.surveyDetail?.loadPlaceCountry}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col sm={4}  className="mb-2 pe-0">
                  <div className="d-flex align-items-start">
                    <div className="white-circle mt-1">
                      <img
                        src={location}
                        height={24}
                        width={24}
                        className="img-pr"
                      />
                    </div>
                    <div className="ms-2">
                      <p className="surve-name mb-0">Destination Address</p>
                      <p className="surve-email mb-0 p-word">
                        {surveyData?.surveyDetail?.unloadPlaceStAddress},{" "}
                        {surveyData?.surveyDetail?.unloadPlaceCity},{" "}
                        {surveyData?.surveyDetail?.unloadPlaceCountry}
                      </p>
                    </div>
                  </div>
                </Col>
               
              

                {/* <Col sm={6} md={2} className="mb-2">
                  <div className="d-flex align-items-start">
                    <div className="white-circle mt-1">
                      <img src={calenderIcon} className="img-pr" />
                    </div>
                    <div className="ms-2">
                      <p className="surve-name mb-0">Created Date</p>
                      <p className="surve-email mb-0 p-word">
                        {surveyData?.surveyDetail?.createdAt
                          ? convertDate(surveyData?.surveyDetail?.createdAt)
                          : "-"}
                      </p>
                    </div>
                  </div>
                </Col> */}

                <Col sm={4} className="mb-2">
                  <div className="d-flex align-items-start">
                    <div className="white-circle mt-1">
                      <img src={callIcon} className="img-pr" />
                    </div>
                    <div className="ms-2">
                      <p className="surve-name mb-0">Phone No.</p>
                      <p className="surve-email mb-0 p-word">
                        {surveyData?.surveyDetail?.loadPlacePhone}
                      </p>
                    </div>
                  </div>
                </Col>

                <Col sm={4} className="mb-2">
                  <div className="d-flex align-items-start">
                    <div className="white-circle mt-1">
                      <img src={calenderMove} className="img-pr" />
                    </div>
                    <div className="ms-2">
                      <p className="surve-name mb-0">Removal Date</p>
                      <p className="surve-email mb-0 p-word">
                        {surveyData?.surveyDetail?.loadDate
                          ? formatDate(
                              convertDate(surveyData?.surveyDetail?.loadDate)
                            )
                          : "-"}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col sm={4} className="mb-2">
                  <div className="d-flex align-items-start">
                    <div className="white-circle mt-1">
                      <img src={calenderMove} className="img-pr" />
                    </div>
                    <div className="ms-2">
                      <p className="surve-name mb-0">Survey Date</p>
                      <p className="surve-email mb-0 p-word">
                        {surveyData?.surveyDetail?.surveyDate
                          ? formatDate(
                              convertDate(surveyData?.surveyDetail?.surveyDate)
                            )
                          : "-"}
                      </p>
                    </div>
                  </div>
                </Col>
               
                
              </Row>
            </Row>

            <Row className="mx-1 mt-2">
              <Col sm={12} md={12} className="text-start px-0 fn">
                <b>Description</b>
              </Col>
            </Row>

            <Row className="mx-0 bg-white mt-0 py-1 px-2 px-md-3"  style={{ borderRadius: "5px" }}>
              <Col sm={12} md={12} className="text-start px-0 fn">
              <p className="surve-email mb-0 p-word">{surveyData?.surveyDetail?.description}</p>
              </Col>
            </Row>

            <Row className="mx-1 mt-2">
              <Col sm={12} md={12} className="text-start px-0 fn">
                <b>Overview</b>
              </Col>
            </Row>
            <Row
              className="mx-0 bg-white mt-1 py-1 px-2 px-md-3"
              style={{ borderRadius: "5px" }}
            >
              <div className="detail-row row">
                <div className="col-3 text-start px-0 ">
                  <p className="surve-email mb-0 p-word">
                    <b>Room</b>
                  </p>
                </div>

                <div className="col-3 text-start px-0 ">
                  <p className="surve-email mb-0 p-word">
                    <b>Weight</b>
                  </p>
                </div>
                <div className="col-3 text-start px-0 ">
                  <p className="surve-email mb-0 p-word">
                    <b>CBM</b>
                  </p>
                </div>
                <div className="col-3 text-start px-0 ">
                  <p className="surve-email mb-0 p-word">
                    <b>Items</b>
                  </p>
                </div>
              </div>
              {surveyData?.roomWiseCalculation?.length > 0 &&
                surveyData?.roomWiseCalculation?.map((cat, index) => {
                  totalWeight = totalWeight + cat?.totalWeightKg;
                  totalCbm = totalCbm + cat?.totalCbm;
                  totalItems = totalItems + cat?.totalItems;
                  return (
                    <div className="detail-row row">
                      <div className="col-3 text-start px-0 ">
                        <p className="surve-email mb-0 p-word">
                          {cat?.roomName ? cat?.roomName : ""}
                        </p>
                      </div>

                      <div className="col-3 text-start px-0 ">
                        <p className="surve-email mb-0 p-word">
                          {cat?.totalWeightKg
                            ? `${cat?.totalWeightKg?.toFixed(4)} kg`
                            : "0 kg"}
                        </p>
                      </div>
                      {cat?.totalCbm ? (
                        <div className="col-3 text-start px-0 ">
                          <p className="surve-email mb-0 p-word">
                            {`${cat?.totalCbm?.toFixed(4)} m`}
                            <sup>3</sup>
                          </p>
                        </div>
                      ) : (
                        <div className="col-3 text-start px-0 ">
                          <p className="surve-email mb-0 p-word">
                            {"0 m"}
                            <sup>3</sup>
                          </p>
                        </div>
                      )}
                      <div className="col-3 text-start px-0 ">
                        <p className="surve-email mb-0 p-word">
                          {cat?.totalItems ? cat?.totalItems : "-"}
                        </p>
                      </div>
                    </div>
                  );
                })}

              <div className="detail-row-last row">
                <div className="col-3 text-start px-0 ">
                  <p className="surve-email mb-0 p-word">
                    <b>Total</b>
                  </p>
                </div>

                <div className="col-3 text-start px-0 ">
                  <p className="surve-email mb-0 p-word">
                    <b>
                      {totalWeight?.toFixed(4)}
                      {" kg"}
                    </b>
                  </p>
                </div>
                <div className="col-3 text-start px-0 ">
                  <p className="surve-email mb-0 p-word">
                    <b>
                      {totalCbm?.toFixed(4)} m<sup>3</sup>
                    </b>
                  </p>
                </div>
                <div className="col-3 text-start px-0 ">
                  <p className="surve-email mb-0 p-word">
                    <b>{totalItems}</b>
                  </p>
                </div>
              </div>
            </Row>

            

            {/* {surveyData?.surveyDetail?.status == "submitted" && (
              <>
                {surveyData?.surveyDetail?.surveyResult?.surveyRooms?.length >
                  0 && (
                  <Row className="mx-1 mb-1 mt-4">
                    <Col sm={12} md={12} className="text-start px-0 ">
                      <b>Rooms Detail</b>
                    </Col>
                  </Row>
                )}
              </>
            )} */}

            {surveyData?.surveyDetail?.surveyResult?.surveyRooms?.length > 0 &&
              surveyData?.surveyDetail?.surveyResult?.surveyRooms?.map(
                (sr, ind) => {
                  return (
                    <>
                    <div className="page-break"></div>
                      <Row
                        className="bg-white mx-0  py-2 mb-1 px-1"
                        style={{ borderRadius: "10px" }}
                      >
                        <Row className="">
                          <Col
                            sm={12}
                            md={12}
                            className="text-start px-0 left-col"
                          >
                            <h6 className="fn text-capitalize">
                              <b>
                                {ind + 1}
                                {". "}
                                {sr?.name}
                              </b>
                            </h6>
                          </Col>
                        </Row>
                        <Row>
                          {/* Images */}
                          <Col sm={6} md={6} className="px-3">
                            <div className="d-flex flex-wrap">
                              {sr?.images?.map((image) => {
                                return (
                                  <>
                                    <div>
                                      <img
                                        src={Bucket + image}
                                        height={200}
                                        width={140}
                                        // style={{ borderRadius: "10px" }}
                                        className="me-2 mb-2"
                                      />
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </Col>

                          {/* Details */}
                          <Col sm={6} md={6} className=" px-0 ">
                            <Row>
                              {/* Moving */}
                              <Col
                                sm={12}
                                md={12}
                                className="px-0 bg-white card-border"
                              >
                                {/* <div className="bg-white card-border"> */}
                                <Row
                                  className="px-2 mx-0"
                                  style={{
                                    backgroundColor: "#00aff0 ",
                                    color: "white",
                                  }}
                                >
                                  <Col
                                    sm={12}
                                    md={12}
                                    className="text-start px-0 left-col"
                                  >
                                    <p
                                      className="fn text-capitalize my-1 preview-head"
                                      style={{ fontSize: "12px" }}
                                    >
                                      <b>Moving</b>
                                    </p>
                                  </Col>
                                </Row>

                                {sr?.roomItems?.map((item) => {
                                  if (item?.type == "movable")
                                    movableItems = movableItems + 1;
                                  if (item?.type == "nonmovable")
                                    nonmovableItems = nonmovableItems + 1;

                                  return <></>;
                                })}

                                {movableItems > 0 ? (
                                  <>
                                    {sr?.roomItems?.map((item) => {
                                      return (
                                        <>
                                          {item?.type == "movable" && (
                                            <Row className="detail-row mx-2 detail-row-mg">
                                              <Col
                                                xs={7}
                                                sm={6}
                                                md={7}
                                                className="text-start px-0 left-col card-item"
                                                style={{ fontSize: "10px" }}
                                              >
                                                <p className="mb-0">
                                                  {item?.name
                                                    ? item?.name
                                                    : "-"}
                                                </p>
                                              </Col>
                                              <Col
                                                xs={2}
                                                sm={2}
                                                md={2}
                                                className="text-end px-0 left-col card-item"
                                                style={{
                                                  fontWeight: "500",
                                                  fontSize: "10px",
                                                }}
                                              >
                                                <p className="mb-0">
                                                  {item?.quantity != undefined
                                                    ? `${item?.quantity}x`
                                                    : "-"}
                                                </p>
                                              </Col>
                                              <Col
                                                xs={3}
                                                sm={4}
                                                md={3}
                                                className="text-end px-0 right-col card-item"
                                                style={{ fontSize: "10px" }}
                                              >
                                                <p className="mb-0">
                                                  {item?.cbm != undefined
                                                    ? item?.cbm?.toFixed(4)
                                                    : "-"}{" "}
                                                  m<sup>3</sup>
                                                </p>
                                              </Col>
                                            </Row>
                                          )}
                                        </>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <Row className="detail-row mx-3 text-center">
                                    <p className="mb-0">
                                      {" "}
                                      <i>No Items</i>
                                    </p>
                                  </Row>
                                )}

                                {/* </div> */}
                              </Col>

                              {/* Not Moving */}
                              <Col
                                sm={12}
                                md={12}
                                className="px-0 bg-white card-border mt-2"
                              >
                                {/* <div className="bg-white card-border"> */}
                                <Row
                                  className="px-2 mx-0"
                                  style={{
                                    backgroundColor: "#00aff0 ",
                                    color: "white",
                                  }}
                                >
                                  <Col
                                    sm={12}
                                    md={12}
                                    className="text-start px-0 left-col"
                                  >
                                    <p
                                      className="fn text-capitalize my-1 preview-head"
                                      style={{ fontSize: "12px" }}
                                    >
                                      <b>Not Moving</b>
                                    </p>
                                  </Col>
                                </Row>
                                {nonmovableItems > 0 ? (
                                  <>
                                    {sr?.roomItems?.map((item) => {
                                      return (
                                        <>
                                          {item?.type == "nonmovable" && (
                                            <Row className="detail-row mx-2 detail-row-mg">
                                              <Col
                                                xs={7}
                                                sm={6}
                                                md={7}
                                                className="text-start px-0 left-col card-item"
                                                style={{ fontSize: "10px" }}
                                              >
                                                <p className="mb-0">
                                                  {item?.name
                                                    ? item?.name
                                                    : "-"}
                                                </p>
                                              </Col>
                                              <Col
                                                xs={2}
                                                sm={2}
                                                md={2}
                                                className="text-end px-0 left-col card-item"
                                                style={{
                                                  fontWeight: "500",
                                                  fontSize: "10px",
                                                }}
                                              >
                                                <p className="mb-0">
                                                  {item?.quantity != undefined
                                                    ? `${item?.quantity}x`
                                                    : "-"}
                                                </p>
                                              </Col>
                                              <Col
                                                xs={3}
                                                sm={4}
                                                md={3}
                                                className="text-end px-0 right-col card-item"
                                                style={{ fontSize: "10px" }}
                                              >
                                                <p className="mb-0">
                                                  {item?.cbm != undefined
                                                    ? item?.cbm?.toFixed(4)
                                                    : "-"}{" "}
                                                  m<sup>3</sup>
                                                </p>
                                              </Col>
                                            </Row>
                                          )}
                                        </>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <Row className="detail-row mx-3 text-center">
                                    <p className="mb-0">
                                      {" "}
                                      <i>No Items</i>
                                    </p>
                                  </Row>
                                )}

                                {/* </div> */}
                              </Col>

                              {/* Packing Material */}
                              <Col
                                sm={12}
                                md={12}
                                className="px-0 bg-white card-border mt-2"
                              >
                                {/* <div className="bg-white card-border"> */}
                                <Row
                                  className="px-2 mx-0"
                                  style={{
                                    backgroundColor: "#00aff0 ",
                                    color: "white",
                                  }}
                                >
                                  <Col
                                    sm={12}
                                    md={12}
                                    className="text-start px-0 left-col"
                                  >
                                    <p
                                      className="fn text-capitalize my-1 preview-head"
                                      style={{ fontSize: "12px" }}
                                    >
                                      <b>Packing Material</b>
                                    </p>
                                  </Col>
                                </Row>

                                {sr?.packingMaterial?.map((pack) => {
                                  return (
                                    <>
                                      <Row className="detail-row mx-2 detail-row-mg">
                                        <Col
                                          xs={7}
                                          sm={6}
                                          md={7}
                                          className="text-start px-0 left-col card-item"
                                          style={{ fontSize: "10px" }}
                                        >
                                          <p className="mb-0">
                                            {pack?.name ? pack?.name : "-"}
                                          </p>
                                        </Col>
                                        <Col
                                          xs={2}
                                          sm={2}
                                          md={2}
                                          className="text-end px-0 left-col card-item"
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "10px",
                                          }}
                                        >
                                          <p className="mb-0">
                                            {pack?.quantity != undefined
                                              ? `${pack?.quantity}x`
                                              : "-"}
                                          </p>
                                        </Col>
                                        <Col
                                          xs={3}
                                          sm={4}
                                          md={3}
                                          className="text-end px-0 right-col card-item"
                                          style={{ fontSize: "10px" }}
                                        >
                                          <p className="mb-0">
                                            {pack?.cbm != undefined
                                              ? pack?.cbm?.toFixed(4)
                                              : "-"}{" "}
                                            m<sup>3</sup>
                                          </p>
                                        </Col>
                                      </Row>
                                    </>
                                  );
                                })}

                                {/* </div> */}
                              </Col>

                              {/* Remarks */}
                              <Col
                                sm={12}
                                md={12}
                                className="px-0 bg-white card-border mt-2"
                              >
                                {/* <div className="bg-white card-border"> */}
                                <Row
                                  className="px-2 mx-0"
                                  style={{
                                    backgroundColor: "#00aff0 ",
                                    color: "white",
                                  }}
                                >
                                  <Col
                                    sm={12}
                                    md={12}
                                    className="text-start px-0 left-col"
                                  >
                                    <p
                                      className="fn text-capitalize my-1 preview-head"
                                      style={{ fontSize: "12px" }}
                                    >
                                      <b>Remarks</b>
                                    </p>
                                  </Col>
                                </Row>
                                <Row className="detail-row mx-2 detail-row-mg">
                                  <Col
                                    sm={12}
                                    md={12}
                                    className="text-start px-0 left-col pt-0 card-item"
                                  >
                                    <p className="mb-0">
                                      {sr?.remarks ? sr?.remarks : "-"}
                                    </p>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Row>
                     
                    </>
                  );
                }
              )}

            {/* Survey Overview*/}
            {/* <Accordion className="mb-4">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-content"
                id="panel-header"
              >
                <Typography className="accord-head">Survey Overview</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Row
                    className="mx-0 bg-white mt-3 py-1 px-2 px-md-4"
                    style={{ borderRadius: "5px" }}
                  >
                    <div className="detail-row row">
                      <div className="col-3 text-start px-0 ">
                        <p className="surve-email mb-0 p-word">
                          <b>Items</b>
                        </p>
                      </div>
                      <div className="col-3 text-start px-0 ">
                        <p className="surve-email mb-0 p-word">
                          <b>Room</b>
                        </p>
                      </div>
                      <div className="col-3 text-start px-0 ">
                        <p className="surve-email mb-0 p-word">
                          <b>Weight</b>
                        </p>
                      </div>
                      <div className="col-3 text-start px-0 ">
                        <p className="surve-email mb-0 p-word">
                          <b>CBM</b>
                        </p>
                      </div>
                    </div>
                    {surveyData?.roomWiseCalculation?.length > 0 &&
                      surveyData?.roomWiseCalculation?.map((cat, index) => {
                        totalWeight = totalWeight + cat?.totalWeight;
                        totalCbm = totalCbm + cat?.totalCbm;
                        totalItems = totalItems + cat?.totalItems;
                        return (
                          <div className="detail-row row">
                            <div className="col-3 text-start px-0 ">
                              <p className="surve-email mb-0 p-word">
                                {cat?.totalItems ? cat?.totalItems : "-"}
                              </p>
                            </div>
                            <div className="col-3 text-start px-0 ">
                              <p className="surve-email mb-0 p-word">
                                {cat?.roomName ? cat?.roomName : ""}
                              </p>
                            </div>
                            <div className="col-3 text-start px-0 ">
                              <p className="surve-email mb-0 p-word">
                                {cat?.totalWeightKg
                                  ? `${cat?.totalWeightKg} KGS`
                                  : "-"}
                              </p>
                            </div>
                            {cat?.totalCbm ? (
                              <div className="col-3 text-start px-0 ">
                                <p className="surve-email mb-0 p-word">
                                  {`${cat?.totalCbm} cm`}
                                  <sup>3</sup>
                                </p>
                              </div>
                            ) : (
                              <div className="col-3 text-start px-0 ">
                                {"-"}
                              </div>
                            )}
                          </div>
                        );
                      })}

                    <div className="detail-row-last row">
                      <div className="col-3 text-start px-0 ">
                        <p className="surve-email mb-0 p-word">
                          <b>Total</b>
                        </p>
                      </div>
                      <div className="col-3 text-start px-0 ">
                        <p className="surve-email mb-0 p-word">
                          <b>{totalWeight} kg</b>
                        </p>
                      </div>
                      <div className="col-3 text-start px-0 ">
                        <p className="surve-email mb-0 p-word">
                          <b>
                            {totalCbm} cm<sup>3</sup>
                          </b>
                        </p>
                      </div>
                      <div className="col-3 text-start px-0 ">
                        <p className="surve-email mb-0 p-word">
                          <b>{totalItems}</b>
                        </p>
                      </div>
                    </div>
                  </Row>
                </Typography>
              </AccordionDetails>
            </Accordion> */}

            {/* {surveyData?.surveyDetail?.surveyResult?.status != "quotesent" ? (
              <>
                <Row className="mx-1">
                  <Col sm={12} md={12} className="text-start px-0 fn">
                    <b>Shipment Mode Selection</b>
                  </Col>
                </Row>
                <Row
                  className="mx-0  bg-white mt-3 py-1"
                  style={{ borderRadius: "5px" }}
                >
                  <Col sm={12} md={12}>
                    <RadioGroup
                      aria-label="mode"
                      name="mode"
                      value={selectedMode}
                      onChange={handleModeChange}
                      row
                    >
                      <FormControlLabel
                        value="sea"
                        control={<Radio />}
                        label="By Sea"
                        className="me-5 fn"
                      />
                      <FormControlLabel
                        value="air"
                        control={<Radio />}
                        label="By Air"
                        className="me-5 fn"
                      />
                      <FormControlLabel
                        value="road"
                        control={<Radio />}
                        label="By Road"
                        className="fn"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
                <Row className="mx-1 mt-4">
                  <Col sm={12} md={12} className="text-start px-0 fn">
                    <b>Shipment Payment Plans</b>
                  </Col>
                </Row>

                {selectedMode == "road" || selectedMode == "air" ? (
                  <PaymentPlanForm onSubmit={sendQuotation} />
                ) : (
                  <SeaPaymentPlanForm onSubmit={sendQuotation} />
                )}
              </>
            ) : (
              <>
                <Row className="mx-1 mt-3">
                  <Col sm={12} md={12} className="text-start px-0 fn">
                    <b>Shipment Mode</b>
                  </Col>
                </Row>
                <Row
                  className="mx-0 bg-white mt-3 py-2 text-start px-1"
                  style={{ borderRadius: "5px" }}
                >
                  <Col sm={12} md={12} className="text-capitalize">
                    <p className="surve-email mb-0 p-word">
                      {" "}
                      By{" "}
                      {
                        surveyData?.surveyDetail?.surveyResult?.surveyQuote
                          ?.shipmentMethod
                      }
                    </p>
                  </Col>
                </Row>

                <Row className="mx-1 mt-4">
                  <Col sm={12} md={12} className="text-start px-0 fn">
                    <b>Shipment Payment Details</b>
                  </Col>
                </Row>

                {surveyData?.surveyDetail?.surveyResult?.surveyQuote
                  ?.shipmentMethod == "air" ||
                surveyData?.surveyDetail?.surveyResult?.surveyQuote
                  ?.shipmentMethod == "road" ? (
                  <Row
                    className="mx-0 bg-white mt-3 py-2 text-start px-1 px-md-3 "
                    style={{ borderRadius: "5px" }}
                  >
                    <Col
                      sm={6}
                      md={4}
                      lg={2}
                      className="text-capitalize col-b-right"
                    >
                      <p className="mode-grey">Door to Door</p>
                      <p className="mode-black">
                        {
                          surveyData?.surveyDetail?.surveyResult?.surveyQuote
                            ?.pricing?.doorToDoor
                        }
                      </p>
                    </Col>

                    <Col
                      sm={6}
                      md={4}
                      lg={2}
                      className="text-capitalize col-b-right"
                    >
                      <p className="mode-grey">Door to Port</p>
                      <p className="mode-black">
                        {
                          surveyData?.surveyDetail?.surveyResult?.surveyQuote
                            ?.pricing?.doorToPort
                        }
                      </p>
                    </Col>

                    <Col
                      sm={6}
                      md={4}
                      lg={2}
                      className="text-capitalize col-b-right"
                    >
                      <p className="mode-grey">Port to Door</p>
                      <p className="mode-black">
                        {
                          surveyData?.surveyDetail?.surveyResult?.surveyQuote
                            ?.pricing?.portToDoor
                        }
                      </p>
                    </Col>

                    <Col
                      sm={6}
                      md={4}
                      lg={2}
                      className="text-capitalize col-b-right"
                    >
                      <p className="mode-grey">Transportation Services</p>
                      <p className="mode-black">
                        {
                          surveyData?.surveyDetail?.surveyResult?.surveyQuote
                            ?.pricing?.transportService
                        }
                      </p>
                    </Col>
                    <Col
                      sm={6}
                      md={4}
                      lg={2}
                      className="text-capitalize col-b-right"
                    >
                      <p className="mode-grey">
                        Vehicle Import & Export Services
                      </p>
                      <p className="mode-black">
                        {
                          surveyData?.surveyDetail?.surveyResult?.surveyQuote
                            ?.pricing?.vehicleService
                        }
                      </p>
                    </Col>

                    <Col
                      sm={6}
                      md={4}
                      lg={2}
                      className="text-capitalize col-b-right"
                    >
                      <p className="mode-grey">Shipping & Packing Services</p>
                      <p className="mode-black">
                        {
                          surveyData?.surveyDetail?.surveyResult?.surveyQuote
                            ?.pricing?.packingService
                        }
                      </p>
                    </Col>
                  </Row>
                ) : (
                  <>
                    <Row
                      className="mx-0 bg-white mt-3 py-2 text-start px-1 px-md-3 "
                      style={{ borderRadius: "5px" }}
                    >
                      {surveyData?.surveyDetail?.surveyResult?.surveyQuote?.seaShipmentPricing?.map(
                        (pr, index) => {
                          return (
                            <>
                              <Row className="mx-0 mt-3 py-2 text-start px-1 ">
                                {pr?.containerSize == "twentyft" ? (
                                  <b>{index + 1}. Twenty Feet Container</b>
                                ) : (
                                  <b>{index + 1}. Forty Feet Container</b>
                                )}
                              </Row>

                              <Row className="mx-0 mt-3 py-2 text-start px-2 ">
                                <Col
                                  sm={6}
                                  md={4}
                                  lg={2}
                                  className="text-capitalize col-b-right"
                                >
                                  <p className="mode-grey">Door to Door</p>
                                  <p className="mode-black">
                                    {pr?.pricing?.doorToDoor}
                                  </p>
                                </Col>

                                <Col
                                  sm={6}
                                  md={4}
                                  lg={2}
                                  className="text-capitalize col-b-right"
                                >
                                  <p className="mode-grey">Door to Port</p>
                                  <p className="mode-black">
                                    {pr?.pricing?.doorToPort}
                                  </p>
                                </Col>

                                <Col
                                  sm={6}
                                  md={4}
                                  lg={2}
                                  className="text-capitalize col-b-right"
                                >
                                  <p className="mode-grey">Port to Door</p>
                                  <p className="mode-black">
                                    {pr?.pricing?.portToDoor}
                                  </p>
                                </Col>

                                <Col
                                  sm={6}
                                  md={4}
                                  lg={2}
                                  className="text-capitalize col-b-right"
                                >
                                  <p className="mode-grey">
                                    Transportation Services
                                  </p>
                                  <p className="mode-black">
                                    {pr?.pricing?.transportService}
                                  </p>
                                </Col>
                                <Col
                                  sm={6}
                                  md={4}
                                  lg={2}
                                  className="text-capitalize col-b-right"
                                >
                                  <p className="mode-grey">
                                    Vehicle Import & Export Services
                                  </p>
                                  <p className="mode-black">
                                    {pr?.pricing?.vehicleService}
                                  </p>
                                </Col>

                                <Col
                                  sm={6}
                                  md={4}
                                  lg={2}
                                  className="text-capitalize col-b-right"
                                >
                                  <p className="mode-grey">
                                    Shipping & Packing Services
                                  </p>
                                  <p className="mode-black">
                                    {pr?.pricing?.packingService}
                                  </p>
                                </Col>
                              </Row>
                            </>
                          );
                        }
                      )}
                    </Row>
                  </>
                )}
              </>
            )} */}
          </>
        )}

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000} // Adjust the duration as needed
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={
              snackbarMessage?.includes("successful") ? "success" : "error"
            }
            onClose={handleCloseSnackbar}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
        </div>
      {/* </MainTemplate> */}
    </>
  );
};
export default SurveyPreviewDownload;
