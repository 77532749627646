import MainTemplate from "../../../templates/MainTemplate";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Snackbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import ContactDetailField from "../../../molecules/ContactDetailField";
import TextArea from "../../../atoms/Textarea";
import { Row, Col, Stack } from "react-bootstrap";
import { Bucket } from "../../../../api/config/apiConfig";
import editIcn from "../../../../imgs/edit.png";
import trashIcn from "../../../../imgs/trash.png";
import backArrow from "../../../../imgs/BackArrow.svg";
import LazyImage from "../../LazyLoadImage";
import gifLoading from "../../../../imgs/giphy.gif";
import MySignatureCanvas from "../../../molecules/signature_canvas";
import { imageUpload } from "../../../../api/services/services";
import MuiAlert from "@mui/material/Alert";
import {
  getPrebidByUuid,
  postUpdateAdminPrebid,
  sendSuperAdminVerificationForEdit,
  sharePreviewLink,
} from "./service";
import moment from "moment";
import PrintComponent from "../../PrintComponent";
import PrebidFormDownload from "./save_as_pdf";
import ShareWithClientsModal from "../../../molecules/ShareWithClients";
import SuperAdminVerifationModal from "./super_admin_verification_modal";
import CircularProgress from "@mui/material/CircularProgress";

const CreateUpdatePrebid = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const { uuid } = useParams();
  const [isSignCanvas, setSignCanvas] = useState(false);
  const [isShare, setIsShare] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(uuid == "uuid" ? true : false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [uploadText, setUploadText] = useState("Upload Sign");

  const [imageText, setImageText] = useState("Image +");

  const [formContent, setFormContent] = useState({
    issueDate: "",
    notice: "",
    contractPrice: "",
    date: "",
    importantNote: "",
    instructions:
      "1. Passport Copy:  A clear and legible copy of the passport of the authorized representative.\r2. Exemption Certificate:An official exemption certificate or import permit issued by the Ministry of Foreign Affairs.\r3. Copy of Diplomatic ID:  A copy of the diplomatic identification card of the authorized representative.\r4. Authority Letter: o An authority letter issued by the Embassy/Organization,authorizing the collection of the consignment. o Authority Letter for Collecting and Clearing Consignment from Destination Port.\r5. Specimen Signature:  A specimen signature of the authorized representative for verification purposes.\rA specimen signature of the authorized representative for verification purposes. A formal request letter addressed to the Collector of Customs,detailing the consignment and requesting necessary actions.\r7. Letter to Excise and Taxation Officer Karachi:  A letter addressed to the Excise and Taxation Officer in Karachi, pertaining to the consignment. A letter addressed to the Excise and Taxation Officer in Karachi, pertaining to the consignment.\r8. Undertaking for Transportation: A letter of undertaking, ensuring the transportation of the consignment is conducted as per the regulations and requirements.",
    inclusions:
      "• Purchase of Vehicle in Japan Auction\r • Export documentation, Taxes, Auction Fees and Handling in Japan\r• Sea Freight: Shipping costs from Yokohama/Nagoya, Japan to Karachi Port, Pakistan.\r• Destination Terminal Handling Charges (DTHC): Charges for handling the vehicle at the destination terminal in Karachi,Pakistan\r• Wharfage Charges\r• DO Charges: Delivery Order charges, necessary for the release of the cargo.\r• Transportation: Costs of transporting the vehicle from the port of Karachi to Islamabad.\r • Handling Charges during Local Transportation: Charges for handling the vehicle during its transportation from Karachi to Islamabad.",
    exclusions:
      "At the destination (Port of Karachi, Pakistan), the above-stated price excludes the following costs:",
    demurrage:
      "Demurrage refers to official storage charges imposed by the port authority for cargo that remains at the port beyond the allowed free time. Detention Charges: Fees for the extended use of containers beyond the agreed free period.",
    paymentTerms: "1. 50% Payment at the Time of Bill of Lading: price shall be paid at the submission of the Bill of Lading for the vehicle to the 1st Party (Buyer)\r 2. 50% Payment at the Time of Delivery:  The remaining 50% of the total contract price shall be paid upon the delivery of the vehicle to the 1 st Party (Buyer) designated location in Islamabad.",

    carDescription: {
      make: "",
      model: "",
      year: "",
      grade: "",
      specification: "",
      mileage: "",
      images: [],
    },
    firstParty: {
      name: "",
      email: "",
      mofaIdNo: "",
      sign: "",
      organization: "",
    },
    secondParty: {
      name: "",
      email: "",
      mofaIdNo: "",
      sign: "",
      organization: "",
    },
  });

  const [isVerified, setIsVerified] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);

  /// this method update password for second party
  const updateSignature = (key) => {
    let values = { ...formContent };
    values.secondParty.sign = key;
    setFormContent(values);
    setUploadText("Upload Sign");
    setIsLoading(false);
  };

  // upload file to cloud storage and get the keys
  const uploadFile = async (file, callBack) => {
    setIsLoading(true);

    const fd = new FormData();
    fd.append("image", file);

    imageUpload(fd)
      .then((res) => {
        callBack(res?.data?.data?.key);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);
        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }
        setUploadText("Upload Sign");
        setImageText("Image +");
        setIsLoading(false);
      });
  };

  /// this method is used to handle close snackbar
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setImageText("Image....");
      uploadFile(file, (key) => {
        setImageText("Image +");
        setFormContent((prevContent) => ({
          ...prevContent,
          carDescription: {
            ...prevContent.carDescription,
            images: [...prevContent.carDescription.images, key], // Append a new image
          },
        }));
        setIsLoading(false);
      });
    }
  };

  const [loadingPrebid, setLoadingPrebid] = useState(true);
  const updatePrebid = () => {
    setLoadingPrebid(true);
    postUpdateAdminPrebid(uuid, formContent)
      .then((_) => {
        setSnackbarMessage(
          uuid ? "Prebid updated successfully" : "Prebid created sucessfully"
        );
        setOpenSnackbar(true);
        navigate(-1);
        setLoadingPrebid(false);
      })
      .catch((error) => {
        console.error("Error:", error.response.data.message);
        setSnackbarMessage(error.response.data.message);
        setOpenSnackbar(true);
        setLoadingPrebid(false);
      });
  };

  const getPrebidOnTheBasisOfUUId = async () => {
    if (uuid)
      await getPrebidByUuid(uuid)
        .then((_) => {
          setFormContent(_.data.data);
        })
        .catch((e) => {
          console.log(e);
        });
  };

  useEffect(() => {
    getPrebidOnTheBasisOfUUId();
  }, []);

  const shareWithClient = (data) => {
    let ems = [];
    let emsInd = 0;
    data.map((dt) => {
      if (dt.trim().length != 0) {
        ems[emsInd] = dt;
        emsInd++;
      }
    });

    setIsLoading(true);
    const body = {
      uuid: uuid,
      emails: ems,
    };
    sharePreviewLink(body)
      .then(() => {
        setSnackbarMessage(
          "Prebid Agreement has been shared with the emails successfully!"
        );
        setOpenSnackbar(true);

        setIsLoading(false);

        setIsShare(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  const sendEditRequestToAdmin = () => {
    setIsEditLoading(true);
    sendSuperAdminVerificationForEdit()
      .then((response) => {
        setIsVerified(true);
        setIsEditLoading(false);
      })
      .catch((error) => {
        setIsVerified(false);
        setIsEditLoading(false);
      });
  };

  return (
    <div>
      <MainTemplate>
        <div className="no-print">
          <div className="no-print">
            <div className="d-block d-sm-flex align-items-center py-4 ">
              <div className="d-flex align-items-center no-print">
                <div
                  className="light-blue-color back-bg cursor-pointer"
                  onClick={() => navigate(-1)}
                >
                  <img src={backArrow} height="24" width="24" />{" "}
                </div>
                <div>
                  <p className="main-head ps-3 mb-0">Prebid Agreement</p>
                </div>
              </div>
              <div className="d-flex ms-auto justify-content-center mt-2 mt-sm-0 flex-wrap no-print">
                {/*Edit Button */}
                {!isEdit && (
                  <Button
                    className="active-btn"
                    onClick={() => {
                      sendEditRequestToAdmin();
                    }}
                    disabled={isEditLoading}
                  >
                    Edit
                  </Button>
                )}

                {/*Share Button */}
                {!isEdit && (
                  <Button
                    className="active-btn ms-3"
                    onClick={() => {
                      setIsShare(true);
                    }}
                    disabled={isLoading}
                  >
                    Share
                  </Button>
                )}

                {/* Cancel button */}
                {isEdit && uuid != "uuid" && (
                  <Button
                    className="active-btn ms-3"
                    onClick={() => {
                      setIsEdit(false);
                    }}
                    disabled={isLoading}
                  >
                    Cancel
                  </Button>
                )}

                {/* Save Button */}
                {isEdit && (
                  <Button
                    className="active-btn ms-3"
                    onClick={() => {
                      updatePrebid();
                    }}
                    disabled={isLoading}
                  >
                    Save
                  </Button>
                )}

                {/* Download Button */}
                {!isEdit && (
                  <Button
                    className="pr-btn ms-3 px-0"
                    onClick={() => {
                      setIsLoading(true);
                      var tempTitle = document.title;
                      document.title = `Quotation-${
                        formContent?.issueDate
                      }-${moment(new Date()).format("DD.MM.YYYY")}`;
                      window.print();
                      document.title = tempTitle;
                      setIsLoading(false);
                    }}
                    disabled={isLoading}
                  >
                    {"Save as PDF"}
                  </Button>
                )}
              </div>
            </div>
          </div>
          {/* contact detail*/}
          <div className="no-print">
            <div className="contact-detail d-flex mt-2 no-print">
              <div
                className={`left-col ${
                  !isEdit ? " w-75" : "w-50 pe-5"
                } text-start`}
              >
                <Typography variant="body1" gutterBottom>
                  <ContactDetailField
                    label="Issue Date"
                    isEdit={isEdit}
                    type="Date"
                    value={formContent?.issueDate}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.issueDate = e.target.value;
                      setFormContent(values);
                    }}
                  />
                </Typography>
              </div>
              {/* right col */}
            </div>
          </div>

          <div className="no-print">
            <div className="contact-detail d-flex mt-2 no-print">
              <div
                className={`left-col ${
                  !isEdit ? " w-75" : "w-50 pe-5"
                } text-start`}
              >
                <Typography variant="body1" gutterBottom>
                  <ContactDetailField
                    label="Contract Price"
                    isEdit={isEdit}
                    type="text"
                    value={formContent?.contractPrice}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.contractPrice = e.target.value;
                      setFormContent(values);
                    }}
                  />
                </Typography>
              </div>
              {/* right col */}
            </div>
          </div>

          <div className="no-print">
            <div className="contact-detail d-flex mt-2 no-print">
              <div
                className={`left-col ${
                  !isEdit ? " w-75" : "w-50 pe-5"
                } text-start`}
              >
                <Typography variant="body1" gutterBottom>
                  <ContactDetailField
                    label="Date"
                    isEdit={isEdit}
                    type="Date"
                    value={formContent?.date}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.date = e.target.value;
                      setFormContent(values);
                    }}
                  />
                </Typography>
              </div>
              {/* right col */}
            </div>
          </div>
          <div className="mt-5 pe-1 no-print">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div
                className={`table-text-wrapper mb-2 d-flex ${
                  isEdit ? "flex-column" : ""
                } justify-content-between`}
              >
                <label
                  htmlFor="confidentiality"
                  className={`fw-bold  ${isEdit ? "mb-3" : "mb-1"}`}
                >
                  Confidentiality Notice
                </label>
                {!isEdit ? (
                  <span className="table-text w-50 text-end">
                    {formContent?.notice ? formContent?.notice : "-"}
                  </span>
                ) : (
                  <TextArea
                    rows="3"
                    label="Confidentiality Notice..."
                    id="confidentialityNotice"
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.notice = e.target.value;
                      setFormContent(values);
                    }}
                    value={formContent?.notice}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          <div className="mt-5 pe-1 no-print">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div
                className={`table-text-wrapper mb-2 d-flex ${
                  isEdit ? "flex-column" : ""
                } justify-content-between`}
              >
                <label
                  htmlFor="confidentiality"
                  className={`fw-bold  ${isEdit ? "mb-3" : "mb-1"}`}
                >
                  Important Note
                </label>
                {!isEdit ? (
                  <span className="table-text w-50 text-end">
                    {formContent?.importantNote
                      ? formContent?.importantNote
                      : "-"}
                  </span>
                ) : (
                  <TextArea
                    rows="3"
                    label="Important note..."
                    id="importantNote"
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.importantNote = e.target.value;
                      setFormContent(values);
                    }}
                    value={formContent?.importantNote}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>

          <div className="mt-5 pe-1 no-print">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div
                className={`table-text-wrapper mb-2 d-flex ${
                  isEdit ? "flex-column" : ""
                } justify-content-between`}
              >
                <label
                  htmlFor="confidentiality"
                  className={`fw-bold  ${isEdit ? "mb-3" : "mb-1"}`}
                >
                  Instructions
                </label>
                {!isEdit ? (
                  <span className="table-text w-50 text-end">
                    {formContent?.instructions
                      ? formContent?.instructions
                      : "-"}
                  </span>
                ) : (
                  <TextArea
                    rows="3"
                    label="Instruction..."
                    id="importantNote"
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.instructions = e.target.value;
                      setFormContent(values);
                    }}
                    value={formContent?.instructions}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>

          <div className="mt-5 pe-1 no-print">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div
                className={`table-text-wrapper mb-2 d-flex ${
                  isEdit ? "flex-column" : ""
                } justify-content-between`}
              >
                <label
                  htmlFor="confidentiality"
                  className={`fw-bold  ${isEdit ? "mb-3" : "mb-1"}`}
                >
                  Inclusions
                </label>
                {!isEdit ? (
                  <span className="table-text w-50 text-end">
                    {formContent?.inclusions ? formContent?.inclusions : "-"}
                  </span>
                ) : (
                  <TextArea
                    rows="3"
                    label="Inclusions..."
                    id="importantNote"
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.inclusions = e.target.value;
                      setFormContent(values);
                    }}
                    value={formContent?.inclusions}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>

          <div className="mt-5 pe-1 no-print">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div
                className={`table-text-wrapper mb-2 d-flex ${
                  isEdit ? "flex-column" : ""
                } justify-content-between`}
              >
                <label
                  htmlFor="confidentiality"
                  className={`fw-bold  ${isEdit ? "mb-3" : "mb-1"}`}
                >
                  Exclusions
                </label>
                {!isEdit ? (
                  <span className="table-text w-50 text-end">
                    {formContent?.exclusions ? formContent?.exclusions : "-"}
                  </span>
                ) : (
                  <TextArea
                    rows="3"
                    label="Exclusions..."
                    id="importantNote"
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.exclusions = e.target.value;
                      setFormContent(values);
                    }}
                    value={formContent?.exclusions}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>

          <div className="mt-5 pe-1 no-print">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div
                className={`table-text-wrapper mb-2 d-flex ${
                  isEdit ? "flex-column" : ""
                } justify-content-between`}
              >
                <label
                  htmlFor="confidentiality"
                  className={`fw-bold  ${isEdit ? "mb-3" : "mb-1"}`}
                >
                  Demurrage
                </label>
                {!isEdit ? (
                  <span className="table-text w-50 text-end">
                    {formContent?.demurrage ? formContent?.demurrage : "-"}
                  </span>
                ) : (
                  <TextArea
                    rows="3"
                    label="Demurrage..."
                    id="importantNote"
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.demurrage = e.target.value;
                      setFormContent(values);
                    }}
                    value={formContent?.demurrage}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>

          <div className="mt-5 pe-1 no-print">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div
                className={`table-text-wrapper mb-2 d-flex ${
                  isEdit ? "flex-column" : ""
                } justify-content-between`}
              >
                <label
                  htmlFor="confidentiality"
                  className={`fw-bold  ${isEdit ? "mb-3" : "mb-1"}`}
                >
                  Payment Terms
                </label>
                {!isEdit ? (
                  <span className="table-text w-50 text-end">
                    {formContent?.demurrage ? formContent?.demurrage : "-"}
                  </span>
                ) : (
                  <TextArea
                    rows="3"
                    label="Payment Terms..."
                    id="importantNote"
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.paymentTerms = e.target.value;
                      setFormContent(values);
                    }}
                    value={formContent?.paymentTerms}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>

          {/* /description */}

          <div className="no-print">
            <div className="contact-detail d-flex mt-2 no-print">
              <div
                className={`left-col ${
                  !isEdit ? " w-75" : "w-50 pe-5"
                } text-start`}
              >
                <label
                  htmlFor="confidentiality"
                  className={`fw-bold  ${isEdit ? "mb-3" : "mb-1"}`}
                >
                  Car Description
                </label>
                <Typography variant="body1" gutterBottom>
                  <ContactDetailField
                    label="Make"
                    isEdit={isEdit}
                    type="text"
                    value={formContent?.carDescription.make}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.carDescription.make = e.target.value;
                      setFormContent(values);
                    }}
                  />
                </Typography>

                <Typography variant="body1" gutterBottom>
                  <ContactDetailField
                    label="Model"
                    isEdit={isEdit}
                    type="text"
                    value={formContent?.carDescription.model}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.carDescription.model = e.target.value;
                      setFormContent(values);
                    }}
                  />
                </Typography>

                <Typography variant="body1" gutterBottom>
                  <ContactDetailField
                    label="Year"
                    isEdit={isEdit}
                    type="text"
                    value={formContent?.carDescription.year}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.carDescription.year = e.target.value;
                      setFormContent(values);
                    }}
                  />
                </Typography>

                <Typography variant="body1" gutterBottom>
                  <ContactDetailField
                    label="Specification"
                    isEdit={isEdit}
                    type="text"
                    value={formContent?.carDescription.specification}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.carDescription.specification = e.target.value;
                      setFormContent(values);
                    }}
                  />
                </Typography>

                <Typography variant="body1" gutterBottom>
                  <ContactDetailField
                    label="Mileage"
                    isEdit={isEdit}
                    type="text"
                    value={formContent?.carDescription.mileage}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.carDescription.mileage = e.target.value;
                      setFormContent(values);
                    }}
                  />
                </Typography>
              </div>
              {/* right col */}
            </div>
          </div>

          <Row
            className="bg-white my-4 mx-0  p-3 py-4 mb-4"
            style={{ borderRadius: "10px" }}
          >
            <Row>
              {/* Images */}
              {/* <Col sm={6} md={6} className="px-10"> */}
              <div className="d-flex flex-wrap">
                {formContent?.carDescription.images?.map((image, index) => {
                  return (
                    <Stack key={index}>
                      <img
                        src={trashIcn}
                        height={18}
                        width={18}
                        className="ms-2 cursor-pointer"
                        onClick={() => {}}
                      />
                      <LazyImage
                        key={index}
                        src={`${Bucket}${image}`}
                        placeholderSrc={gifLoading}
                        height={290}
                        width={210}
                        alt={`Image ${index}`}
                      />
                    </Stack>
                  );
                })}
              </div>
            </Row>
          </Row>

          <div className="d-flex ms-auto justify-content-center mt-2 mt-sm-0 flex-wrap no-print">
            {/*Edit Button */}
            {isEdit && (
              <Button
                className="add-new-btn ms-auto"
                onClick={handleButtonClick}
              >
                {imageText}
                <input
                  type="file"
                  ref={fileInputRef}
                  hidden
                  onChange={handleFileChange}
                />
              </Button>
            )}
          </div>

          <Row className="mt-5 pt-5 pb-5 no-print">
            <Col sm={6} className="mt-5 pt-5">
              {" "}
              <div className="no-print">
                <div className="contact-detail d-flex mt-2 no-print">
                  <div
                    className={`left-col ${
                      !isEdit ? " w-75" : "w-50 pe-5"
                    } text-start`}
                  >
                    <label
                      htmlFor="confidentiality"
                      className={`fw-bold  ${isEdit ? "mb-3" : "mb-1"}`}
                    >
                      1st Party (Buyer)
                    </label>

                    <Typography variant="body1" gutterBottom>
                      <ContactDetailField
                        label="Name"
                        isEdit={isEdit}
                        type="text"
                        value={formContent?.firstParty.name}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.firstParty.name = e.target.value;
                          setFormContent(values);
                        }}
                      />
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      <ContactDetailField
                        label="Email"
                        isEdit={isEdit}
                        type="text"
                        value={formContent?.firstParty.email}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.firstParty.email = e.target.value;
                          setFormContent(values);
                        }}
                      />
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      <ContactDetailField
                        label="MOFA ID No"
                        isEdit={isEdit}
                        type="text"
                        value={formContent?.firstParty.mofaIdNo}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.firstParty.mofaIdNo = e.target.value;
                          setFormContent(values);
                        }}
                      />
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      <ContactDetailField
                        label="Organization"
                        isEdit={isEdit}
                        type="text"
                        value={formContent?.firstParty.organization}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.firstParty.organization = e.target.value;
                          setFormContent(values);
                        }}
                      />
                    </Typography>

                    <Col sm={6} className="mt-5 pt-5">
                      <div className="d-flex justify-content-center align-items-end">
                        {formContent.firstParty.sign != "" &&
                          formContent.firstParty.sign != undefined && (
                            <img
                              src={`${Bucket}${formContent.firstParty.sign}`}
                              height={80}
                              width={200}
                            />
                          )}
                      </div>

                      <p>{"_______________________________"}</p>
                      <p className="my-1">
                        <b>{"Buyer Signature"}</b>
                      </p>

                      {""}
                    </Col>
                  </div>
                  {/* right col */}
                </div>
              </div>
            </Col>

            <Col sm={6} className="mt-5 pt-5">
              <div className="no-print">
                <div className="contact-detail d-flex mt-2 no-print">
                  <div
                    className={`left-col ${
                      !isEdit ? " w-75" : "w-50 pe-5"
                    } text-start`}
                  >
                    <label
                      htmlFor="confidentiality"
                      className={`fw-bold  ${isEdit ? "mb-3" : "mb-1"}`}
                    >
                      2nd Party
                    </label>

                    <Typography variant="body1" gutterBottom>
                      <ContactDetailField
                        label="Name"
                        isEdit={isEdit}
                        type="text"
                        value={formContent?.secondParty.name}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.secondParty.name = e.target.value;
                          setFormContent(values);
                        }}
                      />
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      <ContactDetailField
                        label="Email"
                        isEdit={isEdit}
                        type="text"
                        value={formContent?.secondParty.email}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.secondParty.email = e.target.value;
                          setFormContent(values);
                        }}
                      />
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      <ContactDetailField
                        label="MOFA ID No"
                        isEdit={isEdit}
                        type="text"
                        value={formContent?.secondParty.mofaIdNo}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.secondParty.mofaIdNo = e.target.value;
                          setFormContent(values);
                        }}
                      />
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      <ContactDetailField
                        label="Organization"
                        isEdit={isEdit}
                        type="text"
                        value={formContent?.secondParty.organization}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.secondParty.organization = e.target.value;
                          setFormContent(values);
                        }}
                      />
                    </Typography>

                    <Col sm={6} className="mt-5 pt-5">
                      <div className="d-flex justify-content-center align-items-end">
                        {formContent?.secondParty.sign?.trim()?.length == 0 ? (
                          <>
                            {isEdit && (
                              <div className="d-flex justify-content-center align-items-end">
                                <label
                                  htmlFor="uploadSign"
                                  className="active-btn ms-2 w-200 d-flex align-items-center justify-content-center cursor-pointer btn"
                                >
                                  {uploadText}
                                </label>
                                <input
                                  type="button"
                                  hidden
                                  id="uploadSign"
                                  name="uploadSign"
                                  onClick={() => {
                                    setSignCanvas(true);
                                  }}
                                />
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="d-flex align-items-start">
                            <img
                              src={`${Bucket}${formContent.secondParty.sign}`}
                              height={80}
                              width={200}
                            />
                            {isEdit && (
                              <>
                                <label
                                  htmlFor="editSign"
                                  className=" ms-2  d-flex align-items-center justify-content-center cursor-pointer btn p-0"
                                >
                                  <img
                                    src={editIcn}
                                    height={18}
                                    width={18}
                                    className="ms-2 cursor-pointer"
                                  />
                                </label>
                                <input
                                  type="button"
                                  hidden
                                  id="editSign"
                                  name="uploadSign"
                                  onClick={() => {
                                    setSignCanvas(true);
                                  }}
                                />
                                <img
                                  src={trashIcn}
                                  height={18}
                                  width={18}
                                  className="ms-2 cursor-pointer"
                                  onClick={() => {
                                    let values = { ...formContent };
                                    values.secondParty.sign = "";
                                    setFormContent(values);
                                  }}
                                />
                              </>
                            )}
                          </div>
                        )}
                      </div>
                      <p>{"_______________________________"}</p>
                      <p className="my-1">
                        <b>{"Shelozon Movers Packers & Logistics Pvt Ltd"}</b>
                      </p>

                      {""}
                    </Col>
                  </div>
                  {/* right col */}
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000} // Adjust the duration as needed
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={
              snackbarMessage?.includes("successful") ? "success" : "error"
            }
            onClose={handleCloseSnackbar}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>

        {/* Add signature Modal */}
        {isSignCanvas && (
          <MySignatureCanvas
            open={isSignCanvas}
            onClose={() => {
              setSignCanvas(false);
            }}
            onSuccess={(file) => {
              setUploadText("Uploading...");
              if (!isLoading) uploadFile(file, updateSignature);
            }}
          />
        )}

        {isShare && (
          <ShareWithClientsModal
            open={isShare}
            onClose={() => {
              setIsShare(false);
            }}
            onSuccess={(emails) => {
              shareWithClient(emails);
            }}
          />
        )}

        {isVerified && (
          <SuperAdminVerifationModal
            open={isVerified}
            onClose={() => {
              setIsVerified(false);
            }}
            onSuccess={() => {
              setIsEdit(true);
              setIsVerified(false);
            }}
            onError={() => {
              // shareWithClient(emails);
              setSnackbarMessage("Invalid Confirm Pin");
              setOpenSnackbar(true);
            }}
          />
        )}

        <PrintComponent>
          <PrebidFormDownload formContent={formContent} />
        </PrintComponent>
      </MainTemplate>
    </div>
  );
};

export default CreateUpdatePrebid;

//diffrennce between var & let js
