import React, { useEffect, useState } from "react";
import {
  getFormContent,
  getSingleSurvey,
  updateFormContent,
  surveyResultPreview,
  sharePreviewLink,
  addFormContent,
  imageUpload,
} from "../../../api/services/services";
import backArrow from "../../../imgs/BackArrow.svg";
import MainTemplate from "../../templates/MainTemplate";
import { useNavigate } from "react-router-dom";
import { Button, Snackbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import { Bucket } from "../../../api/config/apiConfig";
import ContactDetailField from "../../molecules/ContactDetailField";
import Input from "../../atoms/Input";
import DynamicText from "../../molecules/DynamicText";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import html2pdf from "html2pdf.js";
import { useParams } from "react-router-dom";
import FourOptionsDynamicCheckbox from "../../molecules/FourOptionsCheckbox";
import "./style.css";
import ShareWithClientsModal from "../../molecules/ShareWithClients";
import LoadUnloadDownload from "./ClientSide/download";
import PrintComponent from "../PrintComponent";
import editIcn from "../../../imgs/edit.png";
import trashIcn from "../../../imgs/trash.png";
import LoadingModal from "../../molecules/LoadingModal";
import moment from "moment";
import MySignatureCanvas from "../../molecules/signature_canvas";
import { sendSuperAdminVerificationForEdit } from "../PrebidAgreement/creation/service";
import SuperAdminVerifationModal from "../PrebidAgreement/creation/super_admin_verification_modal";

const LoadUnloadReport = () => {
  const { surveyId, id, flg } = useParams();
  const [surveyData, setSurveyData] = useState(null);
  const navigate = useNavigate();

  const [formContentId, setFormContentId] = useState("");
  const [isEdit, setIsEdit] = useState(flg == "1" ? true : false);

  const [isLoading, setIsLoading] = useState(false);
  const [formContent, setFormContent] = useState({
    fileRef: "",
    clientName: "",
    clientEmail: "",
    clientSign: "",
    driverName: "",
    contactNo: "",
    idNo: "",
    date: "",
    organization: "Shelozon",
    originAddress: "",
    destinationAddress: "",
    shipmentMode: "",
    shipmentNature: "",
    truckNo: "",
    containerNo: "",
    sealNo: "",
    doubleNo: "",
    missingNo: "",
    damagesNo: "",
    remarks: "",
    containers: [],
    supervisorSign: "",
    ownerSign: "",
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [isShare, setIsShare] = useState(false);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  //
  const getSurveyPreview = () => {
    surveyResultPreview(surveyId)
      .then((response) => {
        setSurveyData(response?.data?.data?.surveyDetail);
        let values = { ...formContent };
        values.originAddress = `${response?.data?.data?.surveyDetail?.loadPlaceStAddress}, ${response?.data?.data?.surveyDetail?.loadPlaceCity}, ${response?.data?.data?.surveyDetail?.loadPlaceCountry}`;
        values.destinationAddress = `${response?.data?.data?.surveyDetail?.unloadPlaceStAddress}, ${response?.data?.data?.surveyDetail?.unloadPlaceCity}, ${response?.data?.data?.surveyDetail?.unloadPlaceCountry}`;
        values.clientName = `${response?.data?.data?.surveyDetail?.name}`;
        values.clientEmail = `${response?.data?.data?.surveyDetail?.email}`;
        values.fileRef = `${response?.data?.data?.surveyDetail?.id}`;

        setFormContent(values);
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }
      });
  };

  //
  const getSurvey = () => {
    getSingleSurvey(surveyId)
      .then((response) => {
        if (response?.data?.data?.status == "submitted") getSurveyPreview();
        else {
          setSurveyData(response?.data?.data);
          let values = { ...formContent };
          values.originAddress = `${response?.data?.data?.loadPlaceStAddress}, ${response?.data?.data?.loadPlaceCity}, ${response?.data?.data?.loadPlaceCountry}`;
          values.destinationAddress = `${response?.data?.data?.unloadPlaceStAddress}, ${response?.data?.data?.unloadPlaceCity}, ${response?.data?.data?.unloadPlaceCountry}`;
          values.clientName = `${response?.data?.data?.name}`;
          values.clientEmail = `${response?.data?.data?.email}`;
          values.fileRef = `${response?.data?.data?.id}`;

          setFormContent(values);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }
      });
  };

  const [mediaKeysToSend, setMediaKeysToSend] = useState([]);
  const [mediaKeysToRemove, setMediaKeysToRemove] = useState([]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //
  const addContent = () => {
    setIsLoading(true);
    const body = {
      formId: id,
      surveyId: surveyId,
      content: formContent,
      mediaKeys: mediaKeysToSend,
    };
    addFormContent(body)
      .then(() => {
        setSnackbarMessage("LOAD/UNLOAD Report has been added successfully!");
        setOpenSnackbar(true);

        setTimeout(() => {
          navigate(-1);
          setIsLoading(false);
        }, 1000);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  //
  const updateContent = () => {
    setIsLoading(true);
    const body = {
      formId: id,
      surveyId: surveyId,
      content: formContent,
      mediaKeys: mediaKeysToSend,
      removedMediaKeys: mediaKeysToRemove,
    };
    updateFormContent(body, formContentId)
      .then((res) => {
        setSnackbarMessage("LOAD/UNLOAD Report has been updated successfully!");
        setOpenSnackbar(true);
        getContent();
        setIsEdit(false);

        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  //
  const getContent = () => {
    setIsLoading(true);
    getFormContent(id, surveyId)
      .then((res) => {
        setFormContentId(res.data.data.uuid);
        setFormContent(res.data.data.content);
        setIsLoading(false);
        setMediaKeysToSend([]);
        setMediaKeysToRemove([]);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
        setMediaKeysToSend([]);
        setMediaKeysToRemove([]);
      });
  };

  useEffect(() => {
    if (flg == "1") getSurvey();
    else getContent();
  }, []);

  const [show, setShow] = useState(false);
  const options = {
    // resolution: Resolution.HIGH,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      format: "letter",
      // default is 'portrait'
      orientation: "portrait",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/png",
      qualityRatio: 1,
    },
    overrides: {
      pdf: {
        compress: true,
      },
    },
  };

  //
  const generatePdf = () => {
    setShow(true);

    setTimeout(() => {
      const content = document.getElementById("content-to-convert"); // Replace with the ID of the element you want to convert to PDF

      const currentDateTime = new Date();
      const filename = `survey_${currentDateTime.toISOString()}.pdf`;

      const pdfOptions = {
        margin: 5,
        filename: filename,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
        }, // Set the page size here
      };

      let temp = html2pdf().from(content).set(pdfOptions).outputPdf();
      temp.save(filename);

      setShow(false);
    }, 3500);
  };

  const [downloadText, setDownloadText] = useState("download");

  const getTargetElement = () => document.getElementById("content-id");

  //
  const shareWithClient = (data) => {
    let ems = [];
    let emsInd = 0;
    data.map((dt) => {
      if (dt.trim().length != 0) {
        ems[emsInd] = dt;
        emsInd++;
      }
    });

    setIsLoading(true);
    const body = {
      surveyId: surveyId,
      formId: id,
      emails: ems,
      formRoute: "load-unload-report-preview",
    };
    sharePreviewLink(body)
      .then(() => {
        setSnackbarMessage(
          "LOAD/UNLOAD Report has been shared with the respective emails successfully!"
        );
        setOpenSnackbar(true);

        setIsLoading(false);
        setIsShare(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (flg == "1") {
      let values = { ...formContent };
      let valuesCont = [...formContent?.containers];

      for (let i = 0; i < 500; i++) {
        valuesCont[i] = false;
      }
      values.containers = valuesCont;
      setFormContent(values);
    }
  }, [surveyData]);

  const printAction = () => {
    const PAGE_HEIGHT = 1045;
    const printElement = document.getElementById("print-component");
    if (printElement) {
      printElement.classList.add("temp-class-for-height");
      const height = printElement.clientHeight - 2000;
      const numberOfPage = Math.ceil(height / PAGE_HEIGHT);
      const heightWithSingleHeader = numberOfPage * PAGE_HEIGHT;
      let requiredHeight = heightWithSingleHeader;
      if (numberOfPage > 1) {
        const headerHeight =
          printElement.getElementsByTagName("thead")?.[0]?.clientHeight;
        const footerHeight =
          printElement.getElementsByTagName("tfoot")?.[0]?.clientHeight;
        requiredHeight -= (numberOfPage - 1) * (headerHeight + footerHeight);
      }
      printElement.style.height = `${requiredHeight}px`;
      printElement.classList.remove("temp-class-for-height");
    }
    window.print();
    if (printElement) {
      printElement.style.height = `auto`;
    }
  };

  const [uploadText1, setUploadText1] = useState("Upload Sign");
  const [uploadText2, setUploadText2] = useState("Upload Sign");

  //
  const uploadFile = async (file) => {
    setIsLoading(true);

    handleOpen();
    if (whichSign == "supervisor") setUploadText1("Uploading...");
    if (whichSign == "owner") setUploadText2("Uploading...");

    const fd = new FormData();
    fd.append("image", file);

    imageUpload(fd)
      .then((res) => {
        let values = { ...formContent };
        let dlt = [...mediaKeysToRemove];

        if (whichSign == "supervisor") {
          if (formContent.supervisorSign?.trim()?.length != 0)
            dlt.push(formContent.supervisorSign);
          values.supervisorSign = res?.data?.data?.key;
        }
        if (whichSign == "owner") {
          if (formContent.ownerSign?.trim()?.length != 0)
            dlt.push(formContent.ownerSign);
          values.ownerSign = res?.data?.data?.key;
        }
        setFormContent(values);
        setMediaKeysToRemove(dlt);

        let md = [...mediaKeysToSend];
        md.push(res?.data?.data?.key);
        setMediaKeysToSend(md);

        setIsLoading(false);
        setUploadText1("Upload Sign");
        setUploadText2("Upload Sign");
        handleClose();
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
        setUploadText1("Upload Sign");
        setUploadText2("Upload Sign");
        handleClose();
      });
  };

  const [isSignCanvas, setSignCanvas] = useState(false);
  const [whichSign, setWhichSign] = useState("");

  const [isVerified, setIsVerified] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);

  const sendEditRequestToAdmin = () => {
    setIsEditLoading(true);
    sendSuperAdminVerificationForEdit()
      .then((response) => {
        setIsVerified(true);
        setIsEditLoading(false);
      })
      .catch((error) => {
        setIsVerified(false);
        setIsEditLoading(false);
      });
  };
  return (
    <>
      <MainTemplate>
        <div className="no-print">
          <div className="d-block d-sm-flex align-items-center py-4">
            <div className="d-flex align-items-center ">
              <div
                className="light-blue-color back-bg cursor-pointer"
                onClick={() => navigate(-1)}
              >
                <img src={backArrow} height="24" width="24" />{" "}
              </div>
              <div>
                <p className="main-head ps-3 mb-0">Load Unload Report</p>
              </div>
            </div>
            <div className="d-flex ms-auto justify-content-center mt-2 mt-sm-0">
              {/*Edit Button */}
              {!isEdit && (
                <Button
                  className="active-btn"
                  onClick={() => {
                    sendEditRequestToAdmin();
                  }}
                  disabled={isEditLoading}
                >
                  Edit
                </Button>
              )}

              {/*Share Button */}
              {!isEdit && (
                <Button
                  className="active-btn ms-3"
                  onClick={() => {
                    setIsShare(true);
                  }}
                  disabled={isLoading}
                >
                  Share
                </Button>
              )}

              {/* Cancel button */}
              {isEdit && flg == "2" && (
                <Button
                  className="active-btn ms-3"
                  onClick={() => {
                    getContent();
                    setIsEdit(false);
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
              )}

              {/* Save Button */}
              {isEdit && (
                <Button
                  className="active-btn ms-3"
                  onClick={() => {
                    if (flg == "1") addContent();
                    else updateContent();
                  }}
                  disabled={isLoading}
                >
                  Save
                </Button>
              )}

              {/* Download Button */}
              {!isEdit && (
                <Button
                  className="active-btn ms-3 px-0"
                  onClick={() => {
                    setIsLoading(true);
                    // printAction();
                    var tempTitle = document.title;
                    document.title = `Load Unload Report-${
                      formContent?.clientName
                    }-${moment(new Date()).format("DD.MM.YYYY")}`;

                    window.print();
                    document.title = tempTitle;
                    setIsLoading(false);
                  }}
                  disabled={isLoading}
                >
                  {"Save as PDF"}
                </Button>
              )}
            </div>
          </div>
          {/* file detail*/}
          <div className="contact-detail d-flex mt-4">
            {/* left col */}
            <div
              className={`left-col ${
                !isEdit ? " w-50" : "w-50 pe-5"
              } text-start`}
            >
              <Typography variant="body1" gutterBottom>
                <ContactDetailField
                  label="File Ref"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"160px"}
                  value={formContent?.fileRef}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.fileRef = e.target.value;
                    setFormContent(values);
                  }}
                  disabled
                />
                <ContactDetailField
                  label="Customer Name"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"160px"}
                  value={formContent?.clientName}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.clientName = e.target.value;
                    setFormContent(values);
                  }}
                />

                <ContactDetailField
                  label="Driver Name"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"160px"}
                  value={formContent?.driverName}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.driverName = e.target.value;
                    setFormContent(values);
                  }}
                />

                <ContactDetailField
                  label="Contact No. "
                  isEdit={isEdit}
                  type="number"
                  minWidth={"160px"}
                  value={formContent?.contactNo}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.contactNo = e.target.value;
                    setFormContent(values);
                  }}
                />
                <ContactDetailField
                  label="ID Card No. "
                  isEdit={isEdit}
                  type="number"
                  minWidth={"160px"}
                  value={formContent?.idNo}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.idNo = e.target.value;
                    setFormContent(values);
                  }}
                />
                <ContactDetailField
                  label="Origin Address"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"160px"}
                  value={formContent?.originAddress}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.originAddress = e.target.value;
                    setFormContent(values);
                  }}
                />
              </Typography>
            </div>
            {/* right col */}
            <div
              className={`right-col ${
                !isEdit ? "w-50" : "w-50 ps-5"
              }  d-flex flex-column align-items-end pe-2`}
            >
              <div
                className=""
                style={isEdit ? { width: "-webkit-fill-available" } : {}}
              >
                <Typography
                  variant="body1"
                  className="text-start w-100"
                  gutterBottom
                >
                  <ContactDetailField
                    label="Date"
                    isEdit={isEdit}
                    type="date"
                    wrapperStyle="justify-content-start"
                    minWidth={"150px"}
                    value={formContent?.date}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.date = e.target.value;
                      setFormContent(values);
                    }}
                  />
                  <ContactDetailField
                    label="Organization"
                    isEdit={isEdit}
                    type="text"
                    wrapperStyle="justify-content-start"
                    minWidth={"150px"}
                    value={formContent?.organization}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.organization = e.target.value;
                      setFormContent(values);
                    }}
                  />
                  <ContactDetailField
                    label="Serial No. "
                    isEdit={isEdit}
                    type="text"
                    wrapperStyle="justify-content-start"
                    minWidth={"150px"}
                    value={formContent?.serialNo}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.serialNo = e.target.value;
                      setFormContent(values);
                    }}
                  />
                  <ContactDetailField
                    label="Truck  No. "
                    isEdit={isEdit}
                    type="text"
                    wrapperStyle="justify-content-start"
                    minWidth={"150px"}
                    value={formContent?.truckNo}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.truckNo = e.target.value;
                      setFormContent(values);
                    }}
                  />
                  <ContactDetailField
                    label="Container No. "
                    isEdit={isEdit}
                    type="text"
                    wrapperStyle="justify-content-start"
                    minWidth={"150px"}
                    value={formContent?.containerNo}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.containerNo = e.target.value;
                      setFormContent(values);
                    }}
                  />
                  <ContactDetailField
                    label="Destination Address"
                    isEdit={isEdit}
                    type="text"
                    minWidth={"160px"}
                    value={formContent?.destinationAddress}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.destinationAddress = e.target.value;
                      setFormContent(values);
                    }}
                  />
                </Typography>
              </div>
            </div>
          </div>
          {/* /file detail */}

          {/*  */}
          {/* Shipment Mode */}
          <div className="mt-5 pe-1 pt-3">
            <Typography variant="body1" className="text-start" gutterBottom>
              <FourOptionsDynamicCheckbox
                label="Shipment Mode"
                checkId1="check1"
                checkLabel1="Import"
                checkId2="check2"
                checkLabel2="Export"
                checkId3="check3"
                checkLabel3="Local"
                checkId4="chec42"
                checkLabel4="Storage"
                value1={formContent?.shipmentMode == "import" ? true : false}
                value2={formContent?.shipmentMode == "export" ? true : false}
                value3={formContent?.shipmentMode == "local" ? true : false}
                value4={formContent?.shipmentMode == "storage" ? true : false}
                onChange1={(e) => {
                  let values = { ...formContent };
                  values.shipmentMode = "import";
                  setFormContent(values);
                }}
                onChange2={(e) => {
                  let values = { ...formContent };
                  values.shipmentMode = "export";
                  setFormContent(values);
                }}
                onChange3={(e) => {
                  let values = { ...formContent };
                  values.shipmentMode = "local";
                  setFormContent(values);
                }}
                onChange4={(e) => {
                  let values = { ...formContent };
                  values.shipmentMode = "storage";
                  setFormContent(values);
                }}
                disabled={!isEdit ? true : false}
              />
            </Typography>
          </div>
          {/* /Shipment Mode */}

          {/* Nature of Shipment */}
          <div className="mt-3 pe-1 ">
            <Typography variant="body1" className="text-start" gutterBottom>
              <FourOptionsDynamicCheckbox
                label="Nature of Shipment"
                checkId1="check1"
                checkLabel1="Loose Items"
                checkId2="check2"
                checkLabel2="Lift Vans"
                checkId3="check3"
                checkLabel3="Vehicle"
                checkId4="chec42"
                checkLabel4="Bike"
                value1={
                  formContent?.shipmentNature == "loose-items" ? true : false
                }
                value2={
                  formContent?.shipmentNature == "lift-vans" ? true : false
                }
                value3={formContent?.shipmentNature == "vehicle" ? true : false}
                value4={formContent?.shipmentNature == "bike" ? true : false}
                onChange1={(e) => {
                  let values = { ...formContent };
                  values.shipmentNature = "loose-items";
                  setFormContent(values);
                }}
                onChange2={(e) => {
                  let values = { ...formContent };
                  values.shipmentNature = "lift-vans";
                  setFormContent(values);
                }}
                onChange3={(e) => {
                  let values = { ...formContent };
                  values.shipmentNature = "vehicle";
                  setFormContent(values);
                }}
                onChange4={(e) => {
                  let values = { ...formContent };
                  values.shipmentNature = "bike";
                  setFormContent(values);
                }}
                disabled={!isEdit ? true : false}
              />
            </Typography>
          </div>
          {/* Nature of Shipment */}

          <div className="d-flex flex-wrap mt-5">
            {formContent?.containers?.map((cn, ind) => {
              return (
                <div
                  className={`cursor-pointer ${
                    cn ? "blue-container-div" : "container-div"
                  }`}
                  onClick={() => {
                    if (isEdit) {
                      let values = { ...formContent };
                      let contValues = [...formContent?.containers];
                      contValues[ind] = !contValues[ind];
                      values.containers = contValues;
                      setFormContent(values);
                    }
                  }}
                >
                  {" "}
                  {ind + 1}
                </div>
              );
            })}
          </div>

          {/*   Double No. */}
          <div className="mt-5 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex justify-content-between">
                <div className="w-50">
                  <label htmlFor="packingDate" className="fw-bold">
                    Double No.
                  </label>
                </div>
                {!isEdit && (
                  <span className="table-text w-50 text-end">
                    {formContent?.doubleNo ? formContent?.doubleNo : "-"}{" "}
                  </span>
                )}
                {isEdit && (
                  <Input
                    id="originAddress "
                    type="text"
                    label="Double No. separated by commas"
                    style="full-border-field"
                    value={formContent?.doubleNo}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.doubleNo = e.target.value;
                      setFormContent(values);
                    }}
                    inline={{ width: "-webkit-fill-available" }}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* /  Double No. */}
          {/*  Missing No. */}
          <div className="mt-5 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex justify-content-between">
                <div className="w-50">
                  <label htmlFor="packingDate" className="fw-bold">
                    Missing No.
                  </label>
                </div>
                {!isEdit && (
                  <span className="table-text w-50 text-end">
                    {formContent?.missingNo ? formContent?.missingNo : "-"}
                  </span>
                )}
                {isEdit && (
                  <Input
                    id="estinationAddress"
                    type="text"
                    label="Missing No. separated by commas"
                    style="full-border-field"
                    value={formContent?.missingNo}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.missingNo = e.target.value;
                      setFormContent(values);
                    }}
                    inline={{ width: "-webkit-fill-available" }}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* /Missing No.s */}
          {/*  Apparent damages No. */}
          <div className="mt-5 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex justify-content-between">
                <div className="w-50">
                  <label htmlFor="packingDate" className="fw-bold">
                    Apparent damages No.
                  </label>
                </div>
                {!isEdit && (
                  <span className="table-text w-50 text-end">
                    {formContent?.damagesNo ? formContent?.damagesNo : "-"}{" "}
                  </span>
                )}
                {isEdit && (
                  <Input
                    id="cmmodity"
                    type="text"
                    label=" Apparent damages No. separated by commas"
                    style="full-border-field"
                    value={formContent?.damagesNo}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.damagesNo = e.target.value;
                      setFormContent(values);
                    }}
                    inline={{ width: "-webkit-fill-available" }}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* /Apparent damages No. */}

          {/* Remarks */}
          <div className="mt-5 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <DynamicText
                label="Remarks:"
                textAreaLabel="Remarks..."
                isEdit={isEdit}
                value={formContent?.remarks}
                onChange={(e) => {
                  let values = { ...formContent };
                  values.remarks = e.target.value;
                  setFormContent(values);
                }}
              />
            </Typography>
          </div>
          {/* /Remakes */}

          <Row className="mt-5 pt-5 no-print">
            <Col sm={6} className="mt-5 pt-5">
              <div
                style={{ height: "90px" }}
                className="d-flex justify-content-center align-items-end"
              >
                {formContent?.supervisorSign?.trim()?.length == 0 ? (
                  <>
                    {isEdit && (
                      <div className="d-flex justify-content-center align-items-end">
                        <label
                          htmlFor="uploadSign"
                          className="active-btn ms-2 w-200 d-flex align-items-center justify-content-center cursor-pointer btn"
                        >
                          {uploadText1}
                        </label>
                        <input
                          type="button"
                          hidden
                          id="uploadSign"
                          name="uploadSign"
                          onClick={() => {
                            setWhichSign("supervisor");
                            setSignCanvas(true);
                          }}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="d-flex align-items-start">
                    <img
                      src={`${Bucket}${formContent?.supervisorSign}`}
                      height={80}
                      width={200}
                    />
                    {isEdit && (
                      <>
                        <label
                          htmlFor="editSign1"
                          className=" ms-2  d-flex align-items-center justify-content-center cursor-pointer btn p-0"
                        >
                          <img
                            src={editIcn}
                            height={18}
                            width={18}
                            className="ms-2 cursor-pointer"
                          />
                        </label>
                        <input
                          type="button"
                          hidden
                          id="editSign1"
                          name="uploadSign"
                          onClick={(e) => {
                            setWhichSign("supervisor");
                            setSignCanvas(true);
                          }}
                        />
                        <img
                          src={trashIcn}
                          height={18}
                          width={18}
                          className="ms-2 cursor-pointer"
                          onClick={() => {
                            let keysToDelete = [...mediaKeysToRemove];
                            keysToDelete.push(formContent.supervisorSign);
                            setMediaKeysToRemove(keysToDelete);

                            let values = { ...formContent };
                            values.supervisorSign = "";
                            setFormContent(values);
                          }}
                        />
                      </>
                    )}
                  </div>
                )}
              </div>

              <p>{"_______________________________"}</p>
              <p className="my-1">
                <b>{"Supervisor/Carrier"}</b>
              </p>

              {""}
            </Col>
            <Col sm={6} className="mt-5 pt-5">
              <div
                style={{ height: "90px" }}
                className="d-flex justify-content-center align-items-end"
              >
                {formContent?.ownerSign?.trim()?.length == 0 ? (
                  <>
                    {isEdit && (
                      <div className="d-flex justify-content-center align-items-end">
                        <label
                          htmlFor="uploadSign2"
                          className="active-btn ms-2 w-200 d-flex align-items-center justify-content-center cursor-pointer btn"
                        >
                          {uploadText2}
                        </label>
                        <input
                          type="button"
                          hidden
                          id="uploadSign2"
                          name="uploadSign2"
                          onClick={() => {
                            setWhichSign("owner");
                            setSignCanvas(true);
                          }}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="d-flex align-items-start">
                    <img
                      src={`${Bucket}${formContent.ownerSign}`}
                      height={80}
                      width={200}
                    />
                    {isEdit && (
                      <>
                        <label
                          htmlFor="editSign2"
                          className=" ms-2  d-flex align-items-center justify-content-center cursor-pointer btn p-0"
                        >
                          <img
                            src={editIcn}
                            height={18}
                            width={18}
                            className="ms-2 cursor-pointer"
                          />
                        </label>
                        <input
                          type="file"
                          hidden
                          id="editSign2"
                          name="uploadSign"
                          onChange={(e) => {
                            if (!isLoading) uploadFile(e, "owner");
                          }}
                        />
                        <img
                          src={trashIcn}
                          height={18}
                          width={18}
                          className="ms-2 cursor-pointer"
                          onClick={() => {
                            let keysToDelete = [...mediaKeysToRemove];
                            keysToDelete.push(formContent.ownerSign);
                            setMediaKeysToRemove(keysToDelete);

                            let values = { ...formContent };
                            values.ownerSign = "";
                            setFormContent(values);
                          }}
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
              <p>{"_______________________________"}</p>
              <p className="my-1">
                <b>{"Owner/Authorized"}</b>
              </p>

              {""}
            </Col>
          </Row>
        </div>

        <PrintComponent>
          <LoadUnloadDownload
            surveyData={surveyData}
            formContent={formContent}
          />
        </PrintComponent>

        {/* snackbar */}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000} // Adjust the duration as needed
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={
              snackbarMessage?.includes("successful") ? "success" : "error"
            }
            onClose={handleCloseSnackbar}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
        {/* /snackbar */}

        {/* share with client */}
        {isShare && (
          <ShareWithClientsModal
            open={isShare}
            onClose={() => {
              setIsShare(false);
            }}
            onSuccess={(emails) => {
              shareWithClient(emails);
            }}
          />
        )}

        <LoadingModal open={open} handleClose={handleClose} />

        {/* Add signature Modal */}
        {isSignCanvas && (
          <MySignatureCanvas
            open={isSignCanvas}
            onClose={() => {
              setSignCanvas(false);
            }}
            onSuccess={(file) => {
              if (!isLoading) uploadFile(file);
            }}
          />
        )}

        {isVerified && (
          <SuperAdminVerifationModal
            open={isVerified}
            onClose={() => {
              setIsVerified(false);
            }}
            onSuccess={() => {
              setIsEdit(true);
              setIsVerified(false);
            }}
            onError={() => {
              // shareWithClient(emails);
              setSnackbarMessage("Invalid Confirm Pin");
              setOpenSnackbar(true);
            }}
          />
        )}
      </MainTemplate>
    </>
  );
};
export default LoadUnloadReport;
