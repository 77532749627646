// /src/api/config/axiosConfig.js
import axios from 'axios';
import {API_BASE_URL} from './apiConfig'

// Axios instance with custom configuration
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    // Add any other common headers here
  },
});

// Add request interceptors, response interceptors, and error handling here

export default axiosInstance;
