import React, { useEffect, useState } from "react";
import {
  updateFormContentPreview,
  getFormContentPreview,
  imageUploadClientPreview,
} from "../../../../api/services/services";
import { Button, Snackbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import { Bucket } from "../../../../api/config/apiConfig";
import Input from "../../../atoms/Input";
import TextArea from "../../../atoms/Textarea";
import ContactDetailField from "../../../molecules/ContactDetailField";
import { useLocation } from "react-router-dom";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
// import QuotationFormDownload from "./download";
import html2pdf from "html2pdf.js";
import CustomCheckbox from "../../../atoms/CustomCheckbox";
import DynamicText from "../../../molecules/DynamicText";
import PrintComponent from "../../PrintComponent";
import CrewInstructionDownload from "./download";
import moment from "moment";
import MySignatureCanvas from "../../../molecules/signature_canvas";

const CrewInstructionFormPage = () => {
  const [formContent, setFormContent] = useState({});
  const [formContentId, setFormContentId] = useState("");

  const [surveyData, setSurveyData] = useState([]);

  const [isEdit, setIsEdit] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const urlData = new URLSearchParams(location.search);
  const email = urlData.get("email");
  const surveyId = urlData.get("surveyId");
  const formId = urlData.get("formId");
  const token = urlData.get("token");

  var totalAmount = 0;

  const [mediaKeysToSend, setMediaKeysToSend] = useState([]);

  const [clientInd, setClientInd] = useState(-1);

  const [isSignCanvas, setSignCanvas] = useState(false);
  const [whichSign, setWhichSign] = useState("");


  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  //
  const updateContent = (data) => {
    setIsLoading(true);

    let media = [];
    let mediaInd = 0;
    formContent?.clients?.map((cl, ind) => {
      if (cl?.sign?.trim()?.length > 0) {
        media[mediaInd] = cl?.sign;
        mediaInd++;
      }

      return <></>;
    });

    const body = {
      formContentId: formContentId,
      content: data ? data : formContent,
      mediaKeys: mediaKeysToSend,
      removedMediaKeys: [],
    };
    updateFormContentPreview(
      surveyId,
      formId,
      email,
      token,
      body,
      formContentId
    )
      .then((res) => {
        setSnackbarMessage("Signature has been added successfully!");

        setOpenSnackbar(true);
        getContent();
        setIsEdit(false);

        setIsLoading(false);
        setUploadText("Upload");
        setMediaKeysToSend([]);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          setIsValid(false);
        }
        setUploadText("Upload");
        setIsLoading(false);
      });
  };

  const [isValid, setIsValid] = useState(false);

  //
  const getContent = () => {
    setIsLoading(true);
    setMediaKeysToSend([]);
    getFormContentPreview(surveyId, formId, email, token)
      .then((res) => {
        setIsValid(true);
        setFormContentId(res.data.data.uuid);
        setFormContent(res.data.data.content);

        for (let i = 0; i < res.data.data.content?.clients?.length; i++) {
          if (res.data.data.content?.clients?.[i]?.email == email) {
            setClientInd(i);
            i = res.data.data.content?.length;
          }
        }

        setIsLoading(false);
      })
      .catch((error) => {
        setIsValid(false);
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        setIsLoading(false);
      });
  };

  useEffect(() => {
    getContent();
  }, []);

  const [show, setShow] = useState(false);
  const options = {
    // resolution: Resolution.HIGH,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      format: "letter",
      // default is 'portrait'
      orientation: "portrait",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/png",
      qualityRatio: 1,
    },
    overrides: {
      pdf: {
        compress: true,
      },
    },
  };

  //
  const generatePdf = () => {
    setShow(true);

    setTimeout(() => {
      const content = document.getElementById("content-to-convert"); // Replace with the ID of the element you want to convert to PDF

      const currentDateTime = new Date();
      const filename = `survey_${currentDateTime.toISOString()}.pdf`;

      const pdfOptions = {
        margin: 5,
        filename: filename,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
        }, // Set the page size here
      };

      let temp = html2pdf().from(content).set(pdfOptions).outputPdf();
      temp.save(filename);

      setShow(false);
    }, 3500);
  };

  const [downloadText, setDownloadText] = useState("download");

  const getTargetElement = () => document.getElementById("content-id");

  const [uploadText, setUploadText] = useState("Upload");
  const [uploadText1, setUploadText1] = useState("Upload Sign");
  const [uploadText2, setUploadText2] = useState("Upload Sign");
  //
  const uploadFile = async (file) => {

    setIsLoading(true);

    if (whichSign == "move") setUploadText1("Uploading...");
    if (whichSign == "manager") setUploadText2("Uploading...");

    setUploadText("Uploading...");
    const fd = new FormData();
    fd.append("image", file);

    imageUploadClientPreview(fd, surveyId, formId, email, token)
      .then((res) => {
        let values = { ...formContent };

        if (whichSign == "move") values.moveCordinatorSign = res?.data?.data?.key;
        if (whichSign == "manager")
          values.packingManagerSign = res?.data?.data?.key;

        setFormContent(values);

        let md = [...mediaKeysToSend];
        md.push(res?.data?.data?.key);
        setMediaKeysToSend(md);

        setTimeout(() => {
          updateContent(values);
        }, 1500);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          setIsValid(false);
        }

        setUploadText1("Upload Sign");
        setUploadText2("Upload Sign");

        setIsLoading(false);
      });

  };

  const printAction = () => {
    const PAGE_HEIGHT = 1045;
    const printElement = document.getElementById("print-component");
    if (printElement) {
      printElement.classList.add("temp-class-for-height");
      const height = printElement.clientHeight - 500;
      const numberOfPage = Math.ceil(height / PAGE_HEIGHT);
      const heightWithSingleHeader = numberOfPage * PAGE_HEIGHT;
      let requiredHeight = heightWithSingleHeader;

      if (numberOfPage > 1) {
        const headerHeight =
          printElement.getElementsByTagName("thead")?.[0]?.clientHeight;
        const footerHeight =
          printElement.getElementsByTagName("tfoot")?.[0]?.clientHeight;
        requiredHeight -= (numberOfPage - 1) * (headerHeight + footerHeight);
      }
      printElement.style.height = `${requiredHeight}px`;
      printElement.classList.remove("temp-class-for-height");
    }
    window.print();
    if (printElement) {
      printElement.style.height = `auto`;
    }
  };

  return (
    <>
      {isValid ? (
        <div className="mx-3 mx-md-5 no-print" style={{ minHeight: "100vh" }}>
          {/* <MainTemplate> */}
          {/* header */}

          <div className="d-flex align-items-center py-4">
            <p className="main-head ps-0 mb-0 w-50 text-end">
              Crew Instruction Document
            </p>
            <div className="d-flex ms-auto">
              {formContent?.clientSign?.trim()?.length == 0 && (
                <div className="">
                  <label
                    htmlFor="uploadSign"
                    className="active-btn ms-2 w-200 d-flex align-items-center justify-content-center cursor-pointer btn"
                  >
                    {uploadText}
                  </label>
                  <input
                    type="file"
                    hidden
                    id="uploadSign"
                    name="uploadSign"
                    onChange={(e) => {
                      if (!isLoading) uploadFile(e);
                    }}
                  />
                </div>
              )}

              {/* Download Button */}
              <Button
                className="pr-btn ms-3 w-150"
              
                onClick={() => {
                  setIsLoading(true);
                  // printAction();
                  var tempTitle = document.title;
                  document.title = `Crew Instruction-${formContent?.customerName
                    }-${moment(new Date()).format("DD.MM.YYYY")}`;

                  window.print();
                  document.title = tempTitle;
                  setIsLoading(false);

                 
                }}
                disabled={isLoading}
              >
                {"Save as PDF"}
              </Button>
            </div>
          </div>

         
          {/* /header */}
          {/* crew Details */}
          <div className="mt-2 pe-1 mb-5">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div>
                <Typography variant="body1" gutterBottom>
                  <ContactDetailField
                    label="File Ref"
                    isEdit={isEdit}
                    type="text"
                    minWidth={"170px"}
                    value={formContent?.fielRef}
                    disabled={true}
                  />
                  <ContactDetailField
                    label="Customer Name"
                    isEdit={isEdit}
                    type="text"
                    minWidth={"170px"}
                    value={formContent?.customerName}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.customerName = e.target.value;
                      setFormContent(values);
                    }}
                  />
                  <ContactDetailField
                    label="Organization"
                    isEdit={isEdit}
                    type="text"
                    minWidth={"170px"}
                    value={formContent?.organization}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.organization = e.target.value;
                      setFormContent(values);
                    }}
                  />
                  <ContactDetailField
                    label="Origin Address"
                    isEdit={isEdit}
                    type="text"
                    minWidth={"170px"}
                    value={formContent?.originAddress}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.originAddress = e.target.value;
                      setFormContent(values);
                    }}
                  />
                  <ContactDetailField
                    label="Destination Address"
                    isEdit={isEdit}
                    type="text"
                    minWidth={"170px"}
                    value={formContent?.destinationAddress}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.destinationAddress = e.target.value;
                      setFormContent(values);
                    }}
                  />
                  <ContactDetailField
                    label="Date"
                    isEdit={isEdit}
                    type="date"
                    minWidth={"170px"}
                    value={formContent?.date}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.date = e.target.value;
                      setFormContent(values);
                    }}
                  />
                  <ContactDetailField
                    label="Document Date"
                    isEdit={isEdit}
                    type="date"
                    minWidth={"170px"}
                    value={formContent?.documentdate}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.documentdate = e.target.value;
                      setFormContent(values);
                    }}
                  />
                </Typography>
              </div>
            </Typography>
          </div>
          {/* /crew Details */}

          {/* Description of Services */}
          <Typography variant="body1" className="text-start" gutterBottom>
            <div className="mb-2">
              <label htmlFor="Description of Services" className="fw-bold mb-4">
                Description of Services
              </label>
              <div className="row crew">
                <div className="col-md-3 col-sm-6">
                  <CustomCheckbox
                    style="mb-3"
                    id="preMoveSurvey"
                    label="Pre Move Survey"
                    value={formContent?.preMoveSurcey}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.preMoveSurcey = e.target.checked;
                      setFormContent(values);
                    }}
                    path={""}
                    disabled={!isEdit ? true : false}
                  />
                </div>
                <div className="col-md-3 col-sm-6">
                  <CustomCheckbox
                    style="mb-3"
                    id="localMove"
                    label="Local Move"
                    value={formContent?.localMove}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.localMove = e.target.checked;
                      setFormContent(values);
                    }}
                    path={""}
                    disabled={!isEdit ? true : false}
                  />
                </div>
                <div className="col-md-3 col-sm-6">
                  <CustomCheckbox
                    style="mb-3"
                    id="InternationalMove"
                    label="International Move"
                    value={formContent?.internationalMove}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.internationalMove = e.target.checked;
                      setFormContent(values);
                    }}
                    path={""}
                    disabled={!isEdit ? true : false}
                  />
                </div>
                <div className="col-md-3 col-sm-6">
                  <CustomCheckbox
                    style="mb-3"
                    id="Road"
                    label="Road"
                    value={formContent?.road}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.road = e.target.checked;
                      setFormContent(values);
                    }}
                    path={""}
                    disabled={!isEdit ? true : false}
                  />
                </div>
                <div className="col-md-3 col-sm-6">
                  <CustomCheckbox
                    style="mb-3"
                    id="Assembling"
                    label="Assembling"
                    value={formContent?.assembling}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.assembling = e.target.checked;
                      setFormContent(values);
                    }}
                    path={""}
                    disabled={!isEdit ? true : false}
                  />
                </div>
                <div className="col-md-3 col-sm-6">
                  <CustomCheckbox
                    style="mb-3"
                    id="debrisRemoval"
                    label="Debris Removal"
                    value={formContent?.debrisRemoval}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.debrisRemoval = e.target.checked;
                      setFormContent(values);
                    }}
                    path={""}
                    disabled={!isEdit ? true : false}
                  />
                </div>
                <div className="col-md-3 col-sm-6">
                  <CustomCheckbox
                    style="mb-3"
                    id="Payments"
                    label="Payments"
                    value={formContent?.payments}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.payments = e.target.checked;
                      setFormContent(values);
                    }}
                    path={""}
                    disabled={!isEdit ? true : false}
                  />
                </div>
                <div className="col-md-3 col-sm-6">
                  <CustomCheckbox
                    style="mb-3"
                    id="Documents"
                    label="Documents"
                    value={formContent?.documents}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.documents = e.target.checked;
                      setFormContent(values);
                    }}
                    path={""}
                    disabled={!isEdit ? true : false}
                  />
                </div>
                <div className="col-md-3 col-sm-6">
                  <CustomCheckbox
                    style="mb-3"
                    id="Air"
                    label="Air"
                    value={formContent?.air}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.air = e.target.checked;
                      setFormContent(values);
                    }}
                    path={""}
                    disabled={!isEdit ? true : false}
                  />
                </div>
                <div className="col-md-3 col-sm-6">
                  <CustomCheckbox
                    style="mb-3"
                    id="Unloading"
                    label="Unloading"
                    value={formContent?.unloading}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.unloading = e.target.checked;
                      setFormContent(values);
                    }}
                    path={""}
                    disabled={!isEdit ? true : false}
                  />
                </div>
                <div className="col-md-3 col-sm-6">
                  <CustomCheckbox
                    style="mb-3"
                    id="Loading"
                    label="Loading"
                    value={formContent?.loading}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.loading = e.target.checked;
                      setFormContent(values);
                    }}
                    path={""}
                    disabled={!isEdit ? true : false}
                  />
                </div>
                <div className="col-md-3 col-sm-6">
                  <CustomCheckbox
                    style="mb-3"
                    id="Invoice"
                    label="Invoice"
                    value={formContent?.invoice}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.invoice = e.target.checked;
                      setFormContent(values);
                    }}
                    path={""}
                    disabled={!isEdit ? true : false}
                  />
                </div>
                <div className="col-md-3 col-sm-6">
                  <CustomCheckbox
                    style="mb-3"
                    id="Delivery"
                    label="Delivery"
                    value={formContent?.delivery}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.delivery = e.target.checked;
                      setFormContent(values);
                    }}
                    path={""}
                    disabled={!isEdit ? true : false}
                  />
                </div>
                <div className="col-md-3 col-sm-6">
                  <CustomCheckbox
                    style="mb-3"
                    id="Sea"
                    label="Sea"
                    value={formContent?.sea}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.sea = e.target.checked;
                      setFormContent(values);
                    }}
                    path={""}
                    disabled={!isEdit ? true : false}
                  />
                </div>
                <div className="col-md-3 col-sm-6">
                  <CustomCheckbox
                    style="mb-3"
                    id="Packing"
                    label="Packing"
                    value={formContent?.packing}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.packing = e.target.checked;
                      setFormContent(values);
                    }}
                    path={""}
                    disabled={!isEdit ? true : false}
                  />
                </div>

                <div className="col-md-3 col-sm-6">
                  <CustomCheckbox
                    style="mb-3"
                    id="Unpacking"
                    label="Unpacking"
                    value={formContent?.unpacking}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.unpacking = e.target.checked;
                      setFormContent(values);
                    }}
                    path={""}
                    disabled={!isEdit ? true : false}
                  />
                </div>
              </div>
            </div>
          </Typography>
          {/* /Description of Services */}
          {/* date and time*/}
          <div className="contact-detail d-flex mt-5">
            {/* left col */}
            <div
              className={`left-col ${!isEdit ? " w-50" : "w-50"} text-start`}
            >
              <Typography variant="body1" gutterBottom style={{ width: "80%" }}>
                <ContactDetailField
                  label="Job Start Date"
                  isEdit={isEdit}
                  type="date"
                  value={formContent?.jobStartDate}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.jobStartDate = e.target.value;
                    setFormContent(values);
                  }}
                  minWidth={"125px"}
                />
                <ContactDetailField
                  label="Job End Date"
                  isEdit={isEdit}
                  type="date"
                  value={formContent?.jobEndDate}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.jobEndDate = e.target.value;
                    setFormContent(values);
                  }}
                  minWidth={"125px"}
                />
              </Typography>
            </div>
            {/* right col */}
            <div
              className={`right-col ${!isEdit ? "w-50 pe-5 " : "w-50 pe-2"
                }  d-flex flex-column align-items-end justify-content-end text-end`}
            >
              {isEdit ? (
                <Typography
                  variant="body1"
                  className="text-start "
                  gutterBottom
                  style={{ width: "80%" }}
                >
                  <ContactDetailField
                    label="Job Start Time"
                    isEdit={isEdit}
                    type="time"
                    value={formContent?.jobStartTime}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.jobStartTime = e.target.value;
                      setFormContent(values);
                    }}
                    minWidth={"125px"}
                  />
                  <ContactDetailField
                    label="Job End Time"
                    isEdit={isEdit}
                    type="time"
                    value={formContent?.jobEndTime}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.jobEndTime = e.target.value;
                      setFormContent(values);
                    }}
                    minWidth={"125px"}
                  />
                </Typography>
              ) : (
                <div>
                  <Typography
                    variant="body1"
                    className="text-start "
                    gutterBottom
                    style={{ width: "80%" }}
                  >
                    <ContactDetailField
                      label="Job Start Time"
                      isEdit={isEdit}
                      type="time"
                      value={formContent?.jobStartTime}
                      onChange={(e) => {
                        let values = { ...formContent };
                        values.jobStartTime = e.target.value;
                        setFormContent(values);
                      }}
                      minWidth={"125px"}
                    />
                    <ContactDetailField
                      label="Job End Time"
                      isEdit={isEdit}
                      type="time"
                      value={formContent?.jobEndTime}
                      onChange={(e) => {
                        let values = { ...formContent };
                        values.jobEndTime = e.target.value;
                        setFormContent(values);
                      }}
                      minWidth={"125px"}
                    />
                  </Typography>
                </div>
              )}
            </div>
          </div>

          {/* /date and time */}
          {/* Standard Exclusions */}
          <div className="mt-4 pe-1 mb-5">
            <Typography variant="body1" className="text-start" gutterBottom>
              <DynamicText
                label="Standard Exclusions:"
                textAreaLabel="Standard Exclusions"
                isEdit={isEdit}
                value={formContent?.exclusions}
                onChange={(e) => {
                  let values = { ...formContent };
                  values.exclusions = e.target.value;
                  setFormContent(values);
                }}
              />
            </Typography>
          </div>
          {/* /Standard Exclusions */}

          {/* Signatures */}
          <Row className="mt-5 pt-5 no-print">
            <Col sm={6} className="mt-5 pt-5">
              <div
                style={{ height: "90px" }}
                className="d-flex justify-content-center align-items-end"
              >
                {formContent?.moveCordinatorSign?.trim()?.length == 0 ? (
                  <>
                    {/* {isEdit && */}
                    <div className="d-flex justify-content-center align-items-end">
                      <label
                        htmlFor="uploadSign"
                        className="active-btn ms-2 w-200 d-flex align-items-center justify-content-center cursor-pointer btn"
                      >
                        {uploadText1}
                      </label>
                      <input
                        type="button"
                        hidden
                        id="uploadSign"
                        name="uploadSign"
                        onClick={() => {
                          setWhichSign("move");
                          setSignCanvas(true)
                        }}
                      />
                    </div>
                    {/* } */}
                  </>
                ) : (
                  <img
                    src={`${Bucket}${formContent?.moveCordinatorSign}`}
                    height={80}
                    width={200}
                  />
                )}
              </div>

              <p>{"_______________________________"}</p>
              <p className="my-1">
                <b>{"Move Coordinator"}</b>
              </p>

              {""}
            </Col>
            <Col sm={6} className="mt-5 pt-5">
              <div
                style={{ height: "90px" }}
                className="d-flex justify-content-center align-items-end"
              >
                {formContent?.packingManagerSign?.trim()?.length == 0 ? (
                  <>
                    {/* {isEdit && */}
                    <div className="d-flex justify-content-center align-items-end">
                      <label
                        htmlFor="uploadSign2"
                        className="active-btn ms-2 w-200 d-flex align-items-center justify-content-center cursor-pointer btn"
                      >
                        {uploadText2}
                      </label>
                      <input
                        type="button"
                        hidden
                        id="uploadSign2"
                        name="uploadSign2"
                        onClick={() => {
                          setWhichSign("manager");
                          setSignCanvas(true);
                        }}
                      />
                    </div>
                    {/* } */}
                  </>
                ) : (
                  <img
                    src={`${Bucket}${formContent.packingManagerSign}`}
                    height={80}
                    width={200}
                  />
                )}
              </div>
              <p>{"_______________________________"}</p>
              <p className="my-1">
                <b>{"Packing Manager"}</b>
              </p>

              {""}
            </Col>
          </Row>

          {/* </MainTemplate> */}
        </div>
      ) : (
        <>
          {isLoading ? (
            <div> Loading...</div>
          ) : (
            <div> Ooops... Something went wrong...</div>
          )}
        </>
      )}

      <PrintComponent>
        <CrewInstructionDownload
          surveyData={surveyData}
          formContent={formContent}
        />
      </PrintComponent>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={
            snackbarMessage?.includes("successful") ? "success" : "error"
          }
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      {/* Add signature Modal */}
      {isSignCanvas && (
        <MySignatureCanvas
          open={isSignCanvas}
          onClose={() => {
            setSignCanvas(false);
          }}
          onSuccess={(file) => {
            if (!isLoading) uploadFile(file);
          }}
        />
      )}
    </>
  );
};

export default CrewInstructionFormPage;
