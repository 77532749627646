import React, { useEffect, useState } from "react";
import {
  updateFormContentPreview,
  getFormContentPreview,
  imageUploadClientPreview,
} from "../../../../api/services/services";

import MainTemplate from "../../../templates/MainTemplate";
import { useNavigate } from "react-router-dom";
import { Button, Snackbar, Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import { Bucket } from "../../../../api/config/apiConfig";
import ContactDetailField from "../../../molecules/ContactDetailField";
import TextArea from "../../../atoms/Textarea";
import DynamicCheckbox from "../../../molecules/DynamicCheckbox";
import Table from "@mui/material/Table";
import CircularProgress from "@mui/material/CircularProgress";
import Input from "../../../atoms/Input";
import removeImg from "../../../../imgs/remove.png";
import { useParams } from "react-router-dom";
import { ToWords } from "to-words";
import { useLocation } from "react-router-dom";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import html2pdf from "html2pdf.js";

const WoodenLiftPageDownload = ({ formContent, surveyDaata }) => {
  const [formContentId, setFormContentId] = useState("");

  const [isEdit, setIsEdit] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const urlData = new URLSearchParams(location.search);
  const email = urlData.get("email");
  const surveyId = urlData.get("surveyId");
  const formId = urlData.get("formId");
  const token = urlData.get("token");

  var totalAmount = 0;

  const words = new ToWords();

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // 11th, 12th, 13th, etc.
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  // Helper function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const ordinalSuffix = getOrdinalSuffix(day);

    return `${day}${ordinalSuffix} ${month}, ${year}`;
  };

  return (
    <>
      <div className="mx-4">
        {/* <MainTemplate> */}
        <div className="d-flex align-items-center justify-content-center pt-2">
          <p className="ps-0 mb-0 head-top">
            Wooden Lift Van Quality Check Report
          </p>
        </div>

        {/* customer detail*/}
        <div className={`${isEdit ? "p-3 px-0 rounded mb-3" : ""}`}>
          <div className="contact-detail d-flex mt-0 row">
            {/* left col */}
            <div className={`left-col col-12 col-md-12 text-start`}>
              <Typography variant="body1" gutterBottom>
                {/* <ContactDetailField
                  label="Customer Name"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"100px"}
                  value={formContent?.customerName}
                /> */}
                <div className="d-flex">
                  <p className="p-word mb-0 text-start" minWidth={"170px"}>
                    <b>Customer Name:</b>
                  </p>

                  <p className="p-word mb-0 text-start">
                    <span className="ms-1 ">
                      {" "}
                      {formContent?.customerName
                        ? formContent?.customerName
                        : "-"}
                    </span>
                  </p>
                </div>
                {/* <ContactDetailField
                  label="File Ref"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"100px"}
                  value={formContent?.fileRef}
                /> */}
                <div className="d-flex">
                  <p className="p-word mb-0 text-start" minWidth={"170px"}>
                    <b>File Ref:</b>
                  </p>

                  <p className="p-word mb-0 text-start">
                    <span className="ms-1 ">
                      {" "}
                      {formContent?.fileRef ? formContent?.fileRef : "-"}
                    </span>
                  </p>
                </div>
              </Typography>
            </div>
          </div>
        </div>
        {/* /customer detail */}

        <div className="mb-3 mt-1">
          <Table responsive>
            {/* Table Header */}
            <thead
              className="bg-white"
              // className="bg-body-tertiary"
            >
              <tr>
                <th
                  className="border p-1 text-start"
                  style={{ width: "20%", fontSize: "10px" }}
                >
                  {" "}
                  No. of Wooden lift vans
                </th>
                <th
                  className="border p-1 text-start "
                  style={{ width: "30%", fontSize: "10px" }}
                >
                  Lift van or Box Sizes IN/OUT
                </th>
                <th
                  className="border p-1 text-start"
                  style={{ width: "15%", fontSize: "10px" }}
                >
                  Tare weight
                </th>
                <th
                  className="border p-1 text-start min-w-100"
                  style={{ width: "15%", fontSize: "10px" }}
                >
                  Manufacturer Date
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {formContent?.dataRows?.length > 0 ? (
                <>
                  {formContent?.dataRows?.map((row, index) => {
                    return (
                      <tr>
                        <td
                          className="border p-1 text-start"
                          style={{ fontSize: "10px" }}
                        >
                          {index + 1}
                        </td>
                        <td
                          className="border p-1 text-start"
                          style={{ fontSize: "10px" }}
                        >
                          {isEdit ? (
                            <Input
                              id="condition"
                              label="Lift van or Box Sizes.."
                              type="text"
                              style="full-border-field"
                              value={row?.liftVanBoxSizes}
                              inline={{ width: "-webkit-fill-available" }}
                              disabled={true}
                            />
                          ) : row.liftVanBoxSizes ? (
                            row.liftVanBoxSizes
                          ) : (
                            "-"
                          )}
                        </td>
                        <td
                          className="border p-1 text-start min-w-100"
                          style={{ fontSize: "10px" }}
                        >
                          {isEdit ? (
                            <>
                              <Input
                                id="condition"
                                label="Tare weight..."
                                type="text"
                                style="full-border-field"
                                value={row?.tareWt}
                                inline={{ width: "-webkit-fill-available" }}
                                disabled={true}
                              />
                            </>
                          ) : row.tareWt ? (
                            `${row.tareWt} KGS`
                          ) : (
                            "-"
                          )}
                        </td>
                        <td
                          className="border p-1 text-start min-w-100"
                          style={{ fontSize: "10px" }}
                        >
                          {isEdit ? (
                            <>
                              <Input
                                id="condition"
                                label="Date..."
                                type="date"
                                style="full-border-field"
                                value={row?.date}
                                inline={{ width: "-webkit-fill-available" }}
                                disabled={true}
                              />
                            </>
                          ) : row.date ? (
                            formatDate(row.date)
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </>
              ) : (
                <tr>
                  <td className="text-center" colSpan={isEdit ? 5 : 4}>
                    {isLoading ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "10px 0px",
                        }}
                      >
                        <CircularProgress
                          style={{
                            color: "#00aff0",
                            height: "25px",
                            width: "25px",
                          }}
                        />
                      </Box>
                    ) : (
                      <div className="d-flex justify-content-center">
                        <p className="my-3">No Records to Show</p>
                      </div>
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>

        <Row>
          <Col sm={6} className="mb-0">
            <Typography
              variant="body1"
              className="text-start"
              gutterBottom
              style={{ fontSize: "10px" }}
            >
              <DynamicCheckbox
                label="1. The correct size of lift van was measured."
                checkId1="check1"
                checkLabel1="Yes"
                checkId2="check2"
                checkLabel2="No"
                value1={formContent?.correctSize}
                value2={formContent?.correctSize}
                onChange1={(e) => {}}
                onChange2={(e) => {}}
                disabled={true}
              />
            </Typography>
          </Col>
          <Col sm={6} className="mb-0">
            <div className="">
              <Typography
                variant="body1"
                className="text-start"
                gutterBottom
                style={{ fontSize: "10px" }}
              >
                <DynamicCheckbox
                  label="2. The crates build with new ply wood sheets."
                  checkId1="check1"
                  checkLabel1="Yes"
                  checkId2="check2"
                  checkLabel2="No"
                  value1={formContent?.plyWood}
                  value2={formContent?.plyWood}
                  onChange1={(e) => {}}
                  onChange2={(e) => {}}
                  disabled={true}
                />
              </Typography>
            </div>
          </Col>
          <Col sm={6} className="mb-0">
            <Typography
              variant="body1"
              className="text-start"
              gutterBottom
              style={{ fontSize: "10px" }}
            >
              <DynamicCheckbox
                label="3. The crates build with new wood bars."
                checkId1="check1"
                checkLabel1="Yes"
                checkId2="check2"
                checkLabel2="No"
                value1={formContent?.woodBars}
                value2={formContent?.woodBars}
                onChange1={(e) => {}}
                onChange2={(e) => {}}
                disabled={true}
              />
            </Typography>
          </Col>
          <Col sm={6} className="mb-0">
            <div className="">
              <Typography
                variant="body1"
                className="text-start"
                gutterBottom
                style={{ fontSize: "10px" }}
              >
                <DynamicCheckbox
                  label="4. All the parts and joints are well together."
                  checkId1="check1"
                  checkLabel1="Yes"
                  checkId2="check2"
                  checkLabel2="No"
                  value1={formContent?.partsWellTogether}
                  value2={formContent?.partsWellTogether}
                  onChange1={(e) => {}}
                  onChange2={(e) => {}}
                  disabled={true}
                />
              </Typography>
            </div>
          </Col>
          <Col sm={6} className="mb-0">
            <Typography
              variant="body1"
              className="text-start"
              gutterBottom
              style={{ fontSize: "10px" }}
            >
              <DynamicCheckbox
                label="5. Is there any holes/cracks found in the crate?"
                checkId1="check1"
                checkLabel1="Yes"
                checkId2="check2"
                checkLabel2="No"
                value1={formContent?.holesCrakes}
                value2={formContent?.holesCrakes}
                onChange1={(e) => {}}
                onChange2={(e) => {}}
                disabled={true}
              />
            </Typography>
          </Col>
          <Col sm={6} className="mb-0">
            <div className="">
              <Typography
                variant="body1"
                className="text-start"
                gutterBottom
                style={{ fontSize: "10px" }}
              >
                <DynamicCheckbox
                  label="6. If yes, has the holes/cracks filled up with silicon?"
                  checkId1="check1"
                  checkLabel1="Yes"
                  checkId2="check2"
                  checkLabel2="No"
                  value1={formContent?.holesFilledWithSilicon}
                  value2={formContent?.holesFilledWithSilicon}
                  onChange1={(e) => {}}
                  onChange2={(e) => {}}
                  disabled={true}
                />
              </Typography>
            </div>
          </Col>
          <Col sm={6} className="mb-1">
            <Typography
              variant="body1"
              className="text-start"
              gutterBottom
              style={{ fontSize: "10px" }}
            >
              <DynamicCheckbox
                label="7. The crates fully waterproofed from inside with plastic sheets."
                checkId1="check1"
                checkLabel1="Yes"
                checkId2="check2"
                checkLabel2="No"
                value1={formContent?.cratesWaterproof}
                value2={formContent?.cratesWaterproof}
                onChange1={(e) => {}}
                onChange2={(e) => {}}
                disabled={true}
              />
            </Typography>
          </Col>
          <Col sm={6} className="mb-0">
            <div className="">
              <Typography
                variant="body1"
                className="text-start"
                gutterBottom
                style={{ fontSize: "10px" }}
              >
                <DynamicCheckbox
                  label="8. The crates roof top has intact with tin sheet."
                  checkId1="check1"
                  checkLabel1="Yes"
                  checkId2="check2"
                  checkLabel2="No"
                  value1={formContent?.cratesRoof}
                  value2={formContent?.cratesRoof}
                  onChange1={(e) => {}}
                  onChange2={(e) => {}}
                  disabled={true}
                />
              </Typography>
            </div>
          </Col>
          <Col sm={6} className="mb-0">
            <Typography
              variant="body1"
              className="text-start"
              gutterBottom
              style={{ fontSize: "10px" }}
            >
              <DynamicCheckbox
                label="9 .The signs and shipper details are clearly mentioned on crates"
                checkId1="check1"
                checkLabel1="Yes"
                checkId2="check2"
                checkLabel2="No"
                value1={formContent?.clearlyMentioned}
                value2={formContent?.clearlyMentioned}
                onChange1={(e) => {}}
                onChange2={(e) => {}}
                disabled={true}
              />
            </Typography>
          </Col>
          <Col sm={6} className="mb-0">
            <div className="">
              <Typography
                variant="body1"
                className="text-start"
                gutterBottom
                style={{ fontSize: "10px" }}
              >
                <DynamicCheckbox
                  label="10. The crates tie-up with steel bindings all around."
                  checkId1="check1"
                  checkLabel1="Yes"
                  checkId2="check2"
                  checkLabel2="No"
                  value1={formContent?.tiedUpCrates}
                  value2={formContent?.tiedUpCrates}
                  onChange1={(e) => {}}
                  onChange2={(e) => {}}
                  disabled={true}
                />
              </Typography>
            </div>
          </Col>
          <Col sm={6} className="mb-0">
            <Typography
              variant="body1"
              className="text-start"
              gutterBottom
              style={{ fontSize: "10px" }}
            >
              <DynamicCheckbox
                label="11. The tare weight of the lift van noted."
                checkId1="check1"
                checkLabel1="Yes"
                checkId2="check2"
                checkLabel2="No"
                value1={formContent?.tareWeightNoted}
                value2={formContent?.tareWeightNoted}
                onChange1={(e) => {}}
                onChange2={(e) => {}}
                disabled={true}
              />
            </Typography>
          </Col>
        </Row>

        {/*  Carpenter Remarks*/}
        {formContent?.carpenterRemarks?.trim()?.length != 0 && (
          <div className="mt-0 pe-1">
            <Typography
              variant="body1"
              className="text-start"
              gutterBottom
              style={{ fontSize: "10px" }}
            >
              <div className="mb-0 d-flex">
                <label
                  htmlFor="carpenterRemarks"
                  className={`fw-bold ${isEdit ? "mb-0" : "mb-0"}`}
                >
                  Carpenter Remarks:
                </label>
                {!isEdit ? (
                  <p className="mb-0 ps-1">
                    {formContent?.carpenterRemarks
                      ? formContent?.carpenterRemarks
                      : "-"}
                  </p>
                ) : (
                  <TextArea
                    rows="3"
                    label="Carpenter remarks..."
                    id="carpenterRemarks"
                    value={formContent?.carpenterRemarks}
                  />
                )}
              </div>
            </Typography>
          </div>
        )}
        {/* / Carpenter Remarks */}

        {/*  Manager Warehouse remarks:*/}
        {formContent?.managerRemarks?.trim()?.length > 0 && (
          <div className="mt-2 pe-1 ">
            <Typography
              variant="body1"
              className="text-start"
              gutterBottom
              style={{ fontSize: "10px" }}
            >
              <div className="mb-0 d-flex">
                <label
                  htmlFor="managerWarehouse"
                  className={`fw-bold ${isEdit ? "mb-0" : "mb-0"}`}
                >
                  Manager Warehouse Remarks:
                </label>
                {!isEdit ? (
                  <p className="mb-0 ps-1">
                    {formContent?.managerRemarks
                      ? formContent?.managerRemarks
                      : "-"}
                  </p>
                ) : (
                  <TextArea
                    rows="3"
                    label="Manager warehouse remarks:..."
                    id="managerWarehouse"
                    value={formContent?.managerRemarks}
                  />
                )}
              </div>
            </Typography>
          </div>
        )}
        {/* / Manager Warehouse remarks */}

        {/* Signatures */}
        <Row className="mt-1">
          <Col sm={6} className="">
            <div
              style={{ height: "90px" }}
              className="d-flex justify-content-center align-items-end"
            >
              <img
                src={`${Bucket}${formContent?.managerWarehouseSign}`}
                height={80}
                width={200}
              />
            </div>

            <p className="mb-0">{"_______________________________"}</p>
            <p className="my-0">
              <b>{"Manager Warehouse"}</b>
            </p>

            {""}
          </Col>
          <Col sm={6} className="">
            <div
              style={{ height: "90px" }}
              className="d-flex justify-content-center align-items-end"
            >
              <img
                src={`${Bucket}${formContent.carpenterSign}`}
                height={80}
                width={200}
              />
            </div>
            <p className="mb-0">{"_______________________________"}</p>
            <p className="my-0">
              <b>{"Carpenter"}</b>
            </p>

            {""}
          </Col>
        </Row>

        {/* </MainTemplate> */}
      </div>

      {/* snackbar */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={
            snackbarMessage?.includes("successful") ? "success" : "error"
          }
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      {/* /snackbar */}
    </>
  );
};

export default WoodenLiftPageDownload;
