import React, { useEffect, useState } from "react";
import { surveyResultPreview, sendQuote } from "../../../api/services/services";
import backArrow from "../../../imgs/BackArrow.svg";
import MainTemplate from "../../templates/MainTemplate";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { Button, Snackbar } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import SuccessModal from "../../molecules/SuccessModal";
import MuiAlert from "@mui/material/Alert";
import callIcon from "../../../imgs/call.svg";
import calenderIcon from "../../../imgs/calendar.svg";
import calenderMove from "../../../imgs/calendarMinus.svg";
import emailIcon from "../../../imgs/email.svg";
import location from "../../../imgs/location.svg";
import html2pdf from "html2pdf.js";
import { Bucket } from "../../../api/config/apiConfig";

const DownloadQuotePage = ({ surveyData }) => {
  const id = window.location.pathname.split("/")[2];
  // const [surveyData, setSurveyData] = useState(null);
  const navigate = useNavigate();
  const [status, setStatus] = useState("");
  const [success, setSuccess] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  var totalWeight = 0;
  var totalCbm = 0;
  var totalItems = 0;

  const [selectedMode, setSelectedMode] = useState("air"); // Initialize with an empty string or the default value

  const handleModeChange = (event) => {
    console.log(event.target.value);
    setSelectedMode(event.target.value);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const convertDate = (unixTimestamp) => {
    const date = new Date(unixTimestamp * 1000); // Multiply by 1000 to convert from seconds to milliseconds
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1 and format with leading zero
    const dd = String(date.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  return (
    <div className="mx-0 px-1 ">
      {/* <MainTemplate> */}
      <div className="d-flex align-items-center pt-1 pb-2">
        <div className="row w-100 mx-0">
          <div className="col-4 ps-0">
            <p className="down-head ps-1 mb-0">Survey Details</p>
          </div>
        </div>
      </div>

      <Row className="mx-0 px-2 py-3 preview-card mb-4">
        <Row>
          <Col md={12} className="text-start text-capitalize">
            <h5 className="fn">
              <b>{surveyData?.surveyDetail?.name}'s Move</b>
            </h5>
          </Col>
        </Row>
        <div className="row">
          <div className="col-4">
            <div className="d-flex align-items-start mb-2">
              <div className="white-circle mt-1">
                <img src={emailIcon} className="img-pr" />
              </div>
              <div className="ps-2">
                <p className="surve-name mb-0">
                  <b>Email</b>
                </p>
                <p className="surve-email mb-0 p-word">
                  {surveyData?.surveyDetail?.email}
                </p>
              </div>
            </div>
          </div>

          <div className="col-4">
            <div className="d-flex align-items-start mb-2">
              <div className="white-circle mt-1">
                <img src={calenderIcon} className="img-pr" />
              </div>
              <div className="ms-2">
                <p className="surve-name mb-0">Created Date</p>
                <p className="surve-email mb-0 p-word">
                  {surveyData?.surveyDetail?.createdAt
                    ? convertDate(surveyData?.surveyDetail?.createdAt)
                    : "-"}
                </p>
              </div>
            </div>
          </div>

          <div className="col-4">
            <div className="d-flex align-items-start mb-2">
              <div className="white-circle mt-1">
                <img src={calenderMove} className="img-pr" />
              </div>
              <div className="ms-2">
                <p className="surve-name mb-0">Move Date</p>
                <p className="surve-email mb-0 p-word">
                  {surveyData?.surveyDetail?.loadDate
                    ? convertDate(surveyData?.surveyDetail?.loadDate)
                    : "-"}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-4">
          <div className="d-flex align-items-start mb-2">
            <div className="white-circle mt-1">
              <img src={location} height={24} width={24} className="img-pr" />
            </div>
            <div className="ms-2">
              <p className="surve-name mb-0">Location</p>
              <p className="surve-email mb-0 p-word">
                {surveyData?.surveyDetail?.loadPlaceStAddress}
              </p>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="d-flex align-items-start mb-2">
            <div className="white-circle mt-1">
              <img src={callIcon} className="img-pr" />
            </div>
            <div className="ms-2">
              <p className="surve-name mb-0">Phone No.</p>
              <p className="surve-email mb-0 p-word">
                {surveyData?.surveyDetail?.loadPlacePhone}
              </p>
            </div>
          </div>
        </div>
      </Row>

      {/* Survey Overview*/}
      <Row className="mx-0">
        <Col sm={12} md={12} className="text-start px-0 fn">
          <b>Survey Overview</b>
        </Col>
      </Row>

      <Row
        className="mx-0 mt-3 py-1 px-3"
        style={{ borderRadius: "5px", backgroundColor: "#f4f5fa " }}
      >
        <div className="detail-row row">
          <div className="col-4 text-start px-0 ">
            <p className="surve-email mb-0 p-word">
              <b>Room</b>
            </p>
          </div>
          <div className="col-3 text-start px-0">
            <p className="surve-email mb-0 p-word">
              <b>Weight</b>
            </p>
          </div>
          <div className="col-3 text-start px-0 ">
            <p className="surve-email mb-0 p-word">
              {" "}
              <b>CBM</b>
            </p>
          </div>
          <div className="col-2 text-start px-0 ">
            <p className="surve-email mb-0 p-word">
              {" "}
              <b>Items</b>
            </p>
          </div>
        </div>

        {surveyData?.categoryWiseCalculation?.length > 0 &&
          surveyData?.categoryWiseCalculation?.map((cat, index) => {
            totalWeight = totalWeight + cat?.totalWeight;
            totalCbm = totalCbm + cat?.totalCbm;
            totalItems = totalItems + cat?.totalItems;
            return (
              <div className="detail-row row">
                <div className="col-4 text-start px-0 ">
                  <p className="surve-email mb-0 p-word">
                    {cat?.categoryName ? cat?.categoryName : ""}
                  </p>
                </div>
                <div className="col-3 text-start px-0">
                  <p className="surve-email mb-0 p-word">
                    {cat?.totalWeight ? `${cat?.totalWeight} kg` : "-"}
                  </p>
                </div>
                {cat?.totalCbm ? (
                  <div className="col-3 text-start px-0">
                    <p className="surve-email mb-0 p-word">
                      {`${cat?.totalCbm} cm`}
                      <sup>3</sup>
                    </p>
                  </div>
                ) : (
                  <div className="col-3 text-start px-0">{"-"}</div>
                )}
                <div className="col-2 text-start px-0">
                  <p className="surve-email mb-0 p-word">
                    {cat?.totalItems ? cat?.totalItems : "-"}
                  </p>
                </div>
              </div>
            );
          })}

        <div className="detail-row-last row">
          <div className="col-4 text-start px-0">
            <p className="surve-email mb-0 p-word">
              <b>Total</b>
            </p>
          </div>
          <div className="col-3 text-start px-0">
            <p className="surve-email mb-0 p-word">
              <b>{totalWeight} kg</b>
            </p>
          </div>
          <div className="col-3 text-start px-0">
            <p className="surve-email mb-0 p-word">
              <b>
                {totalCbm} cm<sup>3</sup>
              </b>
            </p>
          </div>
          <div className="col-2 text-start px-0">
            <p className="surve-email mb-0 p-word">
              <b>{totalItems}</b>
            </p>
          </div>
        </div>
      </Row>
      <Row className="mx-0 mt-3">
        <Col sm={12} md={12} className="text-start px-0 fn">
          <b>Shipment Mode</b>
        </Col>
      </Row>
      <Row
        className="mx-0 mt-3 py-2 text-start"
        style={{ borderRadius: "5px", backgroundColor: "#f4f5fa " }}
      >
        <Col sm={12} md={12} className="text-capitalize">
          <p className="surve-email mb-0 p-word">
            {" "}
            By{" "}
            {
              surveyData?.surveyDetail?.surveyResult?.surveyQuote
                ?.shipmentMethod
            }
          </p>
        </Col>
      </Row>

      <Row className="mx-1 mt-4">
        <Col sm={12} md={12} className="text-start px-0 fn">
          <b>Shipment Payment Details</b>
        </Col>
      </Row>

      {surveyData?.surveyDetail?.surveyResult?.surveyQuote?.shipmentMethod ==
        "air" ||
      surveyData?.surveyDetail?.surveyResult?.surveyQuote?.shipmentMethod ==
        "road" ? (
        <div
          className="mx-0  mt-3 py-2 text-start row"
          style={{ borderRadius: "5px", backgroundColor: "#f4f5fa " }}
        >
          <div className="col-4 text-capitalize col-b-right">
            <p className="mode-grey">Door to Door</p>
            <p className="mode-black">
              {
                surveyData?.surveyDetail?.surveyResult?.surveyQuote?.pricing
                  ?.doorToDoor
              }
            </p>
          </div>

          <div className="col-4 text-capitalize col-b-right">
            <p className="mode-grey">Door to Port</p>
            <p className="mode-black">
              {
                surveyData?.surveyDetail?.surveyResult?.surveyQuote?.pricing
                  ?.doorToPort
              }
            </p>
          </div>

          <div className="col-4 text-capitalize ">
            <p className="mode-grey">Port to Door</p>
            <p className="mode-black">
              {
                surveyData?.surveyDetail?.surveyResult?.surveyQuote?.pricing
                  ?.portToDoor
              }
            </p>
          </div>

          <div className="col-4 text-capitalize col-b-right">
            <p className="mode-grey">Transportation Services</p>
            <p className="mode-black">
              {
                surveyData?.surveyDetail?.surveyResult?.surveyQuote?.pricing
                  ?.transportService
              }
            </p>
          </div>
          <div className="col-4 text-capitalize col-b-right">
            <p className="mode-grey">Vehicle Import & Export Services</p>
            <p className="mode-black">
              {
                surveyData?.surveyDetail?.surveyResult?.surveyQuote?.pricing
                  ?.vehicleService
              }
            </p>
          </div>

          <div className="col-4 text-capitalize ">
            <p className="mode-grey">Shipping & Packing Services</p>
            <p className="mode-black">
              {
                surveyData?.surveyDetail?.surveyResult?.surveyQuote?.pricing
                  ?.packingService
              }
            </p>
          </div>
        </div>
      ) : (
        <>
          <div
            className="row mx-0 bg-white mt-3 py-2 text-start"
            style={{ borderRadius: "5px" }}
          >
            {surveyData?.surveyDetail?.surveyResult?.surveyQuote?.seaShipmentPricing?.map(
              (pr, index) => {
                return (
                  <>
                    <div className="mx-0 mt-3 py-2 text-start px-3 row">
                      {pr?.containerSize == "twentyft" ? (
                        <b>{index + 1}. Twenty Feet Container</b>
                      ) : (
                        <b>{index + 1}. Forty Feet Container</b>
                      )}
                    </div>

                    <div className="mx-0 mt-3 py-2 text-start px-3 row">
                      <div className="col-4 text-capitalize col-b-right">
                        <p className="mode-grey">Door to Door</p>
                        <p className="mode-black">{pr?.pricing?.doorToDoor}</p>
                      </div>

                      <div className="col-4 text-capitalize col-b-right">
                        <p className="mode-grey">Door to Port</p>
                        <p className="mode-black">{pr?.pricing?.doorToPort}</p>
                      </div>

                      <div className="col-4 text-capitalize ">
                        <p className="mode-grey">Port to Door</p>
                        <p className="mode-black">{pr?.pricing?.portToDoor}</p>
                      </div>

                      <div className="col-4 text-capitalize col-b-right">
                        <p className="mode-grey">Transportation Services</p>
                        <p className="mode-black">
                          {pr?.pricing?.transportService}
                        </p>
                      </div>
                      <div className="col-4 text-capitalize col-b-right">
                        <p className="mode-grey">
                          Vehicle Import & Export Services
                        </p>
                        <p className="mode-black">
                          {pr?.pricing?.vehicleService}
                        </p>
                      </div>

                      <div className="col-4 text-capitalize ">
                        <p className="mode-grey">Shipping & Packing Services</p>
                        <p className="mode-black">
                          {pr?.pricing?.packingService}
                        </p>
                      </div>
                    </div>
                  </>
                );
              }
            )}
          </div>
        </>
      )}

      {/* Items Details */}

      {surveyData?.surveyDetail?.surveyResult?.surveyItems?.length > 0 && (
        <Row className="mx-1 mb-3 mt-4">
          <Col sm={12} md={12} className="text-start px-0 ">
            <b>Items Detail</b>
          </Col>
        </Row>
      )}

      {surveyData?.surveyDetail?.surveyResult?.surveyItems?.length > 0 &&
        surveyData?.surveyDetail?.surveyResult?.surveyItems?.map((sr, ind) => {
          return (
            <Row
              className=" my-2 mx-0  p-3 py-4 mb-4"
              style={{ borderRadius: "10px",  backgroundColor: "#f4f5fa "  }}
            >
              <Row className="">
                <Col sm={12} md={12} className="text-start px-0 left-col">
                  <h3 className="fn">
                    <b>{sr?.name}</b>
                  </h3>
                </Col>
              </Row>
              <Row className="">
                <Col xs={6} className="text-start px-0 left-col">
                  <img
                    src={Bucket+sr?.image}
                    height={116}
                    width={116}
                    style={{ borderRadius: "10px" }}
                    
                  />
                </Col>
              </Row>
              <Row className="detail-row ">
                <Col xs={5} className="text-start px-0 left-col pt-2">
                  <p className="surve-email mb-0 p-word"> <b>Quantity</b></p>
                </Col>
                <Col xs={7} className="text-start px-0 right-col pt-2">
                  <p className="surve-email mb-0 p-word">
                    {sr?.quantity ? sr?.quantity : "-"}
                  </p>
                </Col>
              </Row>
              <Row className="detail-row">
                <Col xs={5} className="text-start px-0 left-col">
                  <p className="surve-email mb-0 p-word"><b>Item Type</b></p>
                </Col>
                <Col
                  xs={7}
                  className="text-start px-0 right-col text-capitalize"
                >
                  <p className="surve-email mb-0 p-word">
                    {sr?.type ? sr?.type : "-"}
                  </p>
                </Col>
              </Row>
              <Row className="detail-row">
                <Col xs={5} className="text-start px-0 left-col">
                  <p className="surve-email mb-0 p-word"><b>Length</b></p>
                </Col>
                <Col xs={7} className="text-start px-0 right-col">
                  <p className="surve-email mb-0 p-word">
                    {sr?.lengthCm ? `${sr?.lengthCm} cm` : "-"}
                  </p>
                </Col>
              </Row>
              <Row className="detail-row">
                <Col xs={5} className="text-start px-0 left-col">
                  <p className="surve-email mb-0 p-word"><b>Height</b></p>
                </Col>
                <Col xs={7} className="text-start px-0 right-col">
                  <p className="surve-email mb-0 p-word">
                    {sr?.heightCm ? `${sr?.heightCm} cm` : "-"}
                  </p>
                </Col>
              </Row>
              <Row className="detail-row">
                <Col xs={5} className="text-start px-0 left-col">
                  <p className="surve-email mb-0 p-word"><b>Width</b></p>
                </Col>
                <Col xs={7} className="text-start px-0 right-col">
                  <p className="surve-email mb-0 p-word">
                    {sr?.widthCm ? `${sr?.widthCm} cm` : "-"}
                  </p>
                </Col>
              </Row>
              <Row className="detail-row">
                <Col xs={5} className="text-start px-0 left-col">
                  <p className="surve-email mb-0 p-word"><b>Weight</b></p>
                </Col>
                <Col xs={7} className="text-start px-0 right-col">
                  <p className="surve-email mb-0 p-word">
                    {sr?.weightKg ? `${sr?.weightKg} kg` : "-"}
                  </p>
                </Col>
              </Row>

              <Row className="detail-row-last">
                <Col xs={5} className="text-start px-0 left-col">
                  <p className="surve-email mb-0 p-word"><b>CBM </b></p>
                </Col>
                {sr?.cbm ? (
                  <Col xs={7} className="text-start px-0 right-col">
                    <p className="surve-email mb-0 p-word">
                      {`${sr?.cbm} cm`}
                      <sup>3</sup>
                    </p>
                  </Col>
                ) : (
                  <Col xs={7} className="text-start px-0 right-col">
                    <p className="surve-email mb-0 p-word">{"-"}</p>
                  </Col>
                )}
              </Row>
            </Row>
          );
        })}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={
            snackbarMessage?.includes("successful") ? "success" : "error"
          }
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      {/* </MainTemplate> */}
    </div>
  );
};
export default DownloadQuotePage;
