import React, { useEffect, useState } from "react";
import {
  getFormContent,
  getSingleSurvey,
  updateFormContent,
  surveyResultPreview,
  sharePreviewLink,
  addFormContent,
  imageUpload,
} from "../../../api/services/services";
import backArrow from "../../../imgs/BackArrow.svg";
import MainTemplate from "../../templates/MainTemplate";
import { useNavigate } from "react-router-dom";
import { Button, Snackbar, Box } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import { Bucket } from "../../../api/config/apiConfig";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import Input from "../../atoms/Input";
import removeImg from "../../../imgs/remove.png";
import { useParams } from "react-router-dom";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import html2pdf from "html2pdf.js";
import ShareWithClientsModal from "../../molecules/ShareWithClients";
import PrintComponent from "../PrintComponent";
import ItemConditionDownload from "./ClientSide/download";
import editIcn from "../../../imgs/edit.png";
import trashIcn from "../../../imgs/trash.png";
import LoadingModal from "../../molecules/LoadingModal";
import moment from "moment";
import MySignatureCanvas from "../../molecules/signature_canvas";
import { sendSuperAdminVerificationForEdit } from "../PrebidAgreement/creation/service";
import SuperAdminVerifationModal from "../PrebidAgreement/creation/super_admin_verification_modal";

const ItemConditionReportPage = () => {
  const { surveyId, id, flg } = useParams();
  const [surveyData, setSurveyData] = useState(null);
  const navigate = useNavigate();

  const [formContentId, setFormContentId] = useState("");
  const [isEdit, setIsEdit] = useState(flg == "1" ? true : false);

  const [mediaKeysToSend, setMediaKeysToSend] = useState([]);
  const [mediaKeysToRemove, setMediaKeysToRemove] = useState([]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isLoading, setIsLoading] = useState(false);
  const [formContent, setFormContent] = useState({
    clientName: "",
    clientEmail: "",
    clientSign: "",
    dataRows: [],
    supervisorSign: "",
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [isShare, setIsShare] = useState(false);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  //
  const getSurveyPreview = () => {
    surveyResultPreview(surveyId)
      .then((response) => {
        setSurveyData(response?.data?.data?.surveyDetail);
        let values = { ...formContent };
        values.clientEmail = `${response?.data?.data?.surveyDetail?.email}`;
        values.clientName = `${response?.data?.data?.surveyDetail?.name}`;

        let dR = [];

        // response?.data?.data?.surveyDetail?.surveyResult?.surveyItems?.map(
        //   (itm, ind) => {
        //     dR[ind] = {
        //       no: ind + 1,
        //       description: itm?.name,
        //       condition: "",
        //       disclamer: "",
        //     };
        //   }
        // );

        // values.dataRows = dR;
        let itemsInd = 0;

        // console.log(response?.data?.data?.surveyDetail?.surveyResult?.surveyRooms)
        response?.data?.data?.surveyDetail?.surveyResult?.surveyRooms?.forEach(
          (rm, ind) => {
            // console.log(rm?.roomItems);
            rm?.roomItems?.forEach((rmIt) => {
              // console.log(itemsInd);
              // console.log(values.dataRows);

              // Adding a new object to the dataRows array
              values.dataRows.push({ description: rmIt?.name });
              itemsInd += 1;
            });
          }
        );
        // console.log(values)

        setFormContent(values);
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }
      });
  };

  //
  const getSurvey = () => {
    getSingleSurvey(surveyId)
      .then((response) => {
        if (response?.data?.data?.status == "submitted") getSurveyPreview();
        else {
          setSurveyData(response?.data?.data);
          let values = { ...formContent };
          values.clientEmail = `${response?.data?.data?.email}`;
          values.clientName = `${response?.data?.data?.name}`;
          setFormContent(values);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }
      });
  };

  //
  const addContent = () => {
    setIsLoading(true);
    const body = {
      formId: id,
      surveyId: surveyId,
      content: formContent,
      mediaKeys: mediaKeysToSend,
      removedMediaKeys: [],
    };
    addFormContent(body)
      .then(() => {
        setSnackbarMessage(
          "Items Conditions Report has been added successfully!"
        );
        setOpenSnackbar(true);

        setTimeout(() => {
          navigate(-1);
          setIsLoading(false);
        }, 1000);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  //
  const updateContent = () => {
    setIsLoading(true);
    const body = {
      formId: id,
      surveyId: surveyId,
      content: formContent,
      mediaKeys: mediaKeysToSend,
      removedMediaKeys: mediaKeysToRemove,
    };
    updateFormContent(body, formContentId)
      .then((res) => {
        setSnackbarMessage(
          "Items Conditions Report has been updated successfully!"
        );
        setOpenSnackbar(true);
        getContent();
        setIsEdit(false);

        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  //
  const getContent = () => {
    setIsLoading(true);
    getFormContent(id, surveyId)
      .then((res) => {
        setFormContentId(res.data.data.uuid);
        setFormContent(res.data.data.content);
        setIsLoading(false);
        setMediaKeysToSend([]);
        setMediaKeysToRemove([]);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
        setMediaKeysToSend([]);
        setMediaKeysToRemove([]);
      });
  };

  useEffect(() => {
    if (flg == "1") getSurvey();
    else getContent();
  }, []);

  const [show, setShow] = useState(false);
  const options = {
    // resolution: Resolution.HIGH,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      format: "letter",
      // default is 'portrait'
      orientation: "portrait",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/png",
      qualityRatio: 1,
    },
    overrides: {
      pdf: {
        compress: true,
      },
    },
  };

  //
  const generatePdf = () => {
    setShow(true);

    setTimeout(() => {
      const content = document.getElementById("content-to-convert"); // Replace with the ID of the element you want to convert to PDF

      const currentDateTime = new Date();
      const filename = `survey_${currentDateTime.toISOString()}.pdf`;

      const pdfOptions = {
        margin: 5,
        filename: filename,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
        }, // Set the page size here
      };

      let temp = html2pdf().from(content).set(pdfOptions).outputPdf();
      temp.save(filename);

      setShow(false);
    }, 3500);
  };

  const [downloadText, setDownloadText] = useState("download");

  const getTargetElement = () => document.getElementById("content-id");

  //
  const shareWithClient = (data) => {
    let ems = [];
    let emsInd = 0;
    data.map((dt) => {
      if (dt.trim().length != 0) {
        ems[emsInd] = dt;
        emsInd++;
      }
    });

    setIsLoading(true);
    const body = {
      surveyId: surveyId,
      formId: id,
      emails: ems,
      formRoute: "item-condition-report-at-origin",
    };
    sharePreviewLink(body)
      .then(() => {
        setSnackbarMessage(
          "Item Condition Report been shared with the respective emails successfully!"
        );
        setOpenSnackbar(true);

        setIsLoading(false);
        setIsShare(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  const handleAddNewDataRows = () => {
    const values = { ...formContent };
    const valuesRows = [...formContent?.dataRows];
    valuesRows.push({
      no: "",
      description: "",
      condition: "",
      disclamer: "",
    });
    values.dataRows = valuesRows;

    setFormContent(values);
  };

  const handleRemoveDataRows = (ind) => {
    const values = { ...formContent };
    const valuesRows = [...formContent?.dataRows];
    valuesRows.splice(ind, 1);
    values.dataRows = valuesRows;

    setFormContent(values);
  };

  const printAction = () => {
    const PAGE_HEIGHT = 1045;
    const printElement = document.getElementById("print-component");
    if (printElement) {
      printElement.classList.add("temp-class-for-height");
      const height = printElement.clientHeight;
      const numberOfPage = Math.ceil(height / PAGE_HEIGHT);
      const heightWithSingleHeader = numberOfPage * PAGE_HEIGHT;
      let requiredHeight = heightWithSingleHeader;
      if (numberOfPage > 1) {
        const headerHeight =
          printElement.getElementsByTagName("thead")?.[0]?.clientHeight;
        const footerHeight =
          printElement.getElementsByTagName("tfoot")?.[0]?.clientHeight;
        requiredHeight -= (numberOfPage - 1) * (headerHeight + footerHeight);
      }
      printElement.style.height = `${requiredHeight}px`;
      printElement.classList.remove("temp-class-for-height");
    }
    window.print();
    if (printElement) {
      printElement.style.height = `auto`;
    }
  };

  const [uploadText, setUploadText] = useState("Upload Sign");

  const [isSignCanvas, setSignCanvas] = useState(false);

  //
  const uploadFile = async (file) => {
    setIsLoading(true);

    handleOpen();
    setUploadText("Uploading...");

    const fd = new FormData();
    fd.append("image", file);

    imageUpload(fd)
      .then((res) => {
        let values = { ...formContent };

        let dlt = [...mediaKeysToRemove];
        if (formContent?.supervisorSign?.trim()?.length != 0) {
          dlt.push(formContent?.supervisorSign);
        }

        values.supervisorSign = res?.data?.data?.key;
        setMediaKeysToRemove(dlt);
        setFormContent(values);

        let md = [...mediaKeysToSend];
        md.push(res?.data?.data?.key);
        setMediaKeysToSend(md);

        setIsLoading(false);
        setUploadText("Upload Sign");
        handleClose();
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setUploadText("Upload Sign");

        setIsLoading(false);
        handleClose();
      });
  };

  const [isVerified, setIsVerified] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);

  const sendEditRequestToAdmin = () => {
    setIsEditLoading(true);
    sendSuperAdminVerificationForEdit()
      .then((response) => {
        setIsVerified(true);
        setIsEditLoading(false);
      })
      .catch((error) => {
        setIsVerified(false);
        setIsEditLoading(false);
      });
  };

  return (
    <>
      <MainTemplate>
        <div className="no-print">
          <div className="d-block d-sm-flex align-items-center py-4">
            <div className="d-flex align-items-center ">
              <div
                className="light-blue-color back-bg cursor-pointer"
                onClick={() => navigate(-1)}
              >
                <img src={backArrow} height="24" width="24" />{" "}
              </div>
              <div>
                <p className="main-head ps-3 mb-0">
                  Item Condition Report at Origin
                </p>
              </div>
            </div>
            <div className="d-flex ms-auto justify-content-center mt-2 mt-sm-0">
              {/*Edit Button */}
              {!isEdit && (
                <Button
                  className="active-btn"
                  onClick={() => {
                    sendEditRequestToAdmin();
                  }}
                  disabled={isEditLoading}
                >
                  Edit
                </Button>
              )}

              {/*Share Button */}
              {!isEdit && (
                <Button
                  className="active-btn ms-3"
                  onClick={() => {
                    setIsShare(true);
                  }}
                  disabled={isLoading}
                >
                  Share
                </Button>
              )}

              {/* Cancel button */}
              {isEdit && flg == "2" && (
                <Button
                  className="active-btn ms-3"
                  onClick={() => {
                    getContent();
                    setIsEdit(false);
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
              )}

              {/* Save Button */}
              {isEdit && (
                <Button
                  className="active-btn ms-3"
                  onClick={() => {
                    if (flg == "1") addContent();
                    else updateContent();
                  }}
                  disabled={isLoading}
                >
                  Save
                </Button>
              )}

              {/* Download Button */}
              {!isEdit && (
                <Button
                  className="active-btn ms-3 px-0"
                  onClick={() => {
                    setIsLoading(true);
                    // printAction();
                    var tempTitle = document.title;
                    document.title = `Item Condition Report at Origin-${
                      formContent?.clientName
                    }-${moment(new Date()).format("DD.MM.YYYY")}`;

                    window.print();
                    document.title = tempTitle;
                    setIsLoading(false);
                  }}
                  disabled={isLoading}
                >
                  {"Save as PDF"}
                </Button>
              )}
            </div>
          </div>

          <Grid container className="pe-1 my-4 ">
            <TableContainer component={Paper} className="pb-3">
              <Table>
                {/* Table Header */}
                <TableHead>
                  <TableRow>
                    <TableCell className="table-head min-w-100">
                      Item No.
                    </TableCell>
                    <TableCell className="table-head min-w-400">
                      Item Description
                    </TableCell>
                    <TableCell className="table-head min-w-100">
                      Present Condition
                    </TableCell>
                    <TableCell className="table-head min-w-150">
                      Disclaimer Box
                    </TableCell>
                    {isEdit && (
                      <TableCell className="table-head min-w-25"></TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formContent?.dataRows?.length > 0 ? (
                    <>
                      {formContent?.dataRows.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell> {index + 1}</TableCell>
                          <TableCell>
                            {isEdit ? (
                              <>
                                {/* <TextArea
                              rows="2"
                              label="Description..."
                              id="description"
                              value={row?.description}
                              onChange={(e) => {
                                const values = { ...formContent };
                                const valuesRows = [...formContent?.dataRows];
                                valuesRows.description = e.target.value;
                                values.dataRows = valuesRows;
                                setFormContent(values);
                              }}
                            /> */}

                                <Input
                                  id="condition"
                                  label="Present Condition..."
                                  type="text"
                                  style="full-border-field"
                                  value={row?.description}
                                  onChange={(e) => {
                                    const values = { ...formContent };
                                    const valuesRows = [
                                      ...formContent?.dataRows,
                                    ];
                                    valuesRows[index].description =
                                      e.target.value;
                                    values.dataRows = valuesRows;
                                    setFormContent(values);
                                  }}
                                  inline={{ width: "-webkit-fill-available" }}
                                />
                              </>
                            ) : row?.description ? (
                              row?.description
                            ) : (
                              "-"
                            )}
                          </TableCell>
                          <TableCell>
                            {isEdit ? (
                              <Input
                                id="condition"
                                label="Present Condition..."
                                type="text"
                                style="full-border-field"
                                value={row?.condition}
                                onChange={(e) => {
                                  const values = { ...formContent };
                                  const valuesRows = [...formContent?.dataRows];
                                  valuesRows[index].condition = e.target.value;
                                  values.dataRows = valuesRows;
                                  setFormContent(values);
                                }}
                                inline={{ width: "-webkit-fill-available" }}
                              />
                            ) : row?.condition ? (
                              row?.condition
                            ) : (
                              "-"
                            )}
                          </TableCell>
                          <TableCell>
                            {isEdit ? (
                              <Input
                                id="disclamer"
                                label="Disclamer..."
                                type="text"
                                style="full-border-field"
                                value={row?.disclamer}
                                onChange={(e) => {
                                  const values = { ...formContent };
                                  const valuesRows = [...formContent?.dataRows];
                                  valuesRows[index].disclamer = e.target.value;
                                  values.dataRows = valuesRows;
                                  setFormContent(values);
                                }}
                                inline={{ width: "-webkit-fill-available" }}
                              />
                            ) : row?.disclamer ? (
                              row?.disclamer
                            ) : (
                              "-"
                            )}
                          </TableCell>
                          {isEdit && (
                            <TableCell>
                              <img
                                src={removeImg}
                                height={18}
                                width={18}
                                className="cursor-pointer"
                                onClick={() => handleRemoveDataRows(index)}
                              />
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </>
                  ) : (
                    <TableRow className="text-center">
                      <TableCell colSpan={6}>
                        {isLoading ? (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              margin: "10px 0px",
                            }}
                          >
                            <CircularProgress
                              style={{
                                color: "#00aff0",
                                height: "25px",
                                width: "25px",
                              }}
                            />
                          </Box>
                        ) : (
                          <div className="d-flex justify-content-center">
                            <p className="my-3">No Records to Show</p>
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Row className="mb-5">
            <Col className="col-12 text-end">
              {isEdit && (
                <Button
                  className="add-new-btn ms-auto"
                  onClick={() => {
                    handleAddNewDataRows();
                  }}
                  disabled={isLoading}
                >
                  Add New
                </Button>
              )}
            </Col>
          </Row>

          {/* Signatures */}

          <Row className="mt-5 pt-5">
            <Col sm={6} className="mt-5 pt-5">
              <div
                style={{ height: "90px" }}
                className="d-flex justify-content-center align-items-end"
              >
                {formContent.clientSign != "" &&
                  formContent.clientSign != undefined && (
                    <img
                      src={`${Bucket}${formContent.clientSign}`}
                      height={80}
                      width={200}
                    />
                  )}
              </div>
              <p className="mb-0">{"_______________________________"}</p>
              <p className="mt-1 mb-1">
                <b>{"Customer/Authorized"}</b>
              </p>

              {""}
            </Col>
            <Col sm={6} className="mt-5 pt-5">
              <div
                style={{ height: "90px" }}
                className="d-flex justify-content-center align-items-end"
              >
                {formContent?.supervisorSign?.trim()?.length == 0 ? (
                  <>
                    {isEdit && (
                      <div className="d-flex justify-content-center align-items-end">
                        <label
                          htmlFor="uploadSign"
                          className="active-btn ms-2 w-200 d-flex align-items-center justify-content-center cursor-pointer btn"
                        >
                          {uploadText}
                        </label>
                        <input
                          type="button"
                          hidden
                          id="uploadSign"
                          name="uploadSign"
                          onClick={() => {
                            setSignCanvas(true);
                          }}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="d-flex align-items-start">
                    <img
                      src={`${Bucket}${formContent?.supervisorSign}`}
                      height={80}
                      width={200}
                    />

                    {isEdit && (
                      <>
                        <label
                          htmlFor="editSign"
                          className=" ms-2  d-flex align-items-center justify-content-center cursor-pointer btn p-0"
                        >
                          <img
                            src={editIcn}
                            height={18}
                            width={18}
                            className="ms-2 cursor-pointer"
                          />
                        </label>
                        <input
                          type="button"
                          hidden
                          id="editSign"
                          name="uploadSign"
                          onClick={() => {
                            setSignCanvas(true);
                          }}
                        />
                        <img
                          src={trashIcn}
                          height={18}
                          width={18}
                          className="ms-2 cursor-pointer"
                          onClick={() => {
                            let keysToDelete = [...mediaKeysToRemove];
                            keysToDelete.push(formContent.supervisorSign);
                            setMediaKeysToRemove(keysToDelete);

                            let values = { ...formContent };
                            values.supervisorSign = "";
                            setFormContent(values);
                          }}
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
              <p className="mb-0">{"_______________________________"}</p>
              <p className="mt-1 mb-1">
                <b>{"Supervisor"}</b>
              </p>

              {""}
            </Col>
          </Row>
        </div>

        <PrintComponent>
          <ItemConditionDownload
            surveyData={surveyData}
            formContent={formContent}
          />
        </PrintComponent>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000} // Adjust the duration as needed
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={
              snackbarMessage?.includes("successful") ? "success" : "error"
            }
            onClose={handleCloseSnackbar}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>

        {/* share with client */}
        {isShare && (
          <ShareWithClientsModal
            open={isShare}
            onClose={() => {
              setIsShare(false);
            }}
            onSuccess={(emails) => {
              shareWithClient(emails);
            }}
          />
        )}

        <LoadingModal open={open} handleClose={handleClose} />

        {/* Add signature Modal */}
        {isSignCanvas && (
          <MySignatureCanvas
            open={isSignCanvas}
            onClose={() => {
              setSignCanvas(false);
            }}
            onSuccess={(file) => {
              if (!isLoading) uploadFile(file);
            }}
          />
        )}

        {isVerified && (
          <SuperAdminVerifationModal
            open={isVerified}
            onClose={() => {
              setIsVerified(false);
            }}
            onSuccess={() => {
              setIsEdit(true);
              setIsVerified(false);
            }}
            onError={() => {
              // shareWithClient(emails);
              setSnackbarMessage("Invalid Confirm Pin");
              setOpenSnackbar(true);
            }}
          />
        )}
      </MainTemplate>
    </>
  );
};
export default ItemConditionReportPage;
