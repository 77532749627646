import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
  Snackbar,
  TextField,
  Box
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import TwoFactorAuth from "../../molecules/TwoFactorAuth";
import { useLocation, useNavigate } from "react-router-dom";
import { getTOTPUri, verify2FA } from "../../../api/services/services";
import successImg from "../../../imgs/succes.svg";
import { login, logout } from "../../../modules/redux/reducers/authActions";
import { useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import useMediaQuery from "@mui/material/useMediaQuery";

const TwoFactorAuthPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const [text, setText] = useState("");
  const [qrData, setQrData] = useState("");
  const [authKey, setAuthKey] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [verify, setVerify] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [digits, setDigits] = useState(["", "", "", "", "", ""]);
  const [success, setSuccess] = useState(false);

  const inputProps = {
    style: {
      height: "50px", // Set the height to 40 pixels
      width: "50px",
      textAlign: "center",
      fontSize: "20px",
      paddingLeft: "5px",
    },
    maxLength: 1,
  };

  const inputRefs = [
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ];

  const handleChange = (index, value) => {
    if (value.match(/^\d{0,1}$/)) {
      const updatedDigits = [...digits];
      updatedDigits[index] = value;
      setDigits(updatedDigits);
      if (index < 5 && value !== "") {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Confirming 2FA
  const confirm2Fa = () => {
    let code = "";
    for (let i = 0; i < digits.length; i++) {
      code = code + digits[i];
    }
    const body = {
      code: code,
    };

    verify2FA(state.token, body)
      .then((response) => {
        setSuccess(true);
        localStorage.setItem("shelLoginToken", response?.data?.data?.token);
        localStorage.setItem(
          "shelUserData",
          JSON.stringify(response?.data?.data?.admin)
        );

        const currentTime = new Date();
        localStorage.setItem("loginTime", currentTime);

        localStorage.removeItem("isAuthPending");
      })
      .catch((error) => {
        console.error("Error ", error?.response?.data);
        if (error?.response?.data?.statusCode == 1002) {
          setSnackbarMessage("Token has been expired");
          setOpenSnackbar(true);
        } else {
          setSnackbarMessage(error?.response?.data?.message);
          setOpenSnackbar(true);
          // setDigits(["", "", "", "", "", ""])
        }
      });
  };

  useEffect(() => {
    let val = localStorage.getItem('fa-token')

    getTOTPUri(val)
      .then((response) => {
        setQrData(response?.data?.data?.toTpURI);
        setAuthKey(response?.data?.data?.formattedKey);
        setIsLoading(false);
        // setVerify(false)
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error ", error?.response?.data);
        if (error?.response?.data?.statusCode == 1002) {
          setSnackbarMessage("Token has been expired");
          setOpenSnackbar(true);
        } else {
          setSnackbarMessage(error?.response?.data?.message);
          setOpenSnackbar(true);
        }
        localStorage.removeItem("isAuthPending");
      });
  }, []);

  const isConfirmButtonDisabled =
    digits[0].length == 0 ||
    digits[1].length == 0 ||
    digits[2].length == 0 ||
    digits[3].length == 0 ||
    digits[4].length == 0 ||
    digits[5].length == 0;

  return (
    <div className="login-bg">
      <Container
        maxWidth="xs"
        sx={{
          display: "flex",
          alignItems: "center",
          minHeight: "100vh",
          justifyContent: "center",
        }}
      >
        {success == false ? (
          <>
            {verify == false ? (
              <Card
               
                style={{ borderRadius: "15px" }}
                className="fa-card py-2 pb-0"
              > 
                <CardContent>
                  <Typography variant="p" align="center" gutterBottom>
                    <p className="title p-0 mb-1">Enable Authenticator</p>
                  </Typography>
                  <Typography variant="p" align="center" gutterBottom>
                  <p className="desc mb-3 mb-md-5">
                      Authenticator app should be installed on your device to
                      use 2FA
                    </p>
                  </Typography>

                  {isLoading ? (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              margin: "10px 0px",
                            }}
                          >
                            <CircularProgress
                              style={{
                                color: "#00aff0",
                                height: "25px",
                                width: "25px",
                              }}
                            />
                          </Box>
                        ) : (

                  <TwoFactorAuth qrData={qrData} authKey={authKey} />
                        )}

                  <Grid item xs={12}>
                    <Button
                      type="button"
                      variant="contained"
                      className="submit-btn mt-4 mt-md-5 mb-1 mb-md-3 text-capitalize"
                      onClick={() => setVerify(true)}
                    >
                      Next
                    </Button>
                  </Grid>
                </CardContent>
              </Card>
            ) : (
              <Card
                
                style={{ borderRadius: "15px" }}
                className="fa-card py-2 pb-0"
              >
                <CardContent>
                  <Typography variant="p" align="center" gutterBottom>
                    <p className="title p-0 mb-1">Enable Authenticator</p>
                  </Typography>
                  <Typography variant="p" align="center" gutterBottom>
                  <p className="desc mb-3 mb-md-5">
                      Authenticator app should be installed on your device to
                      use 2FA
                    </p>
                  </Typography>
                  <Typography variant="p" align="center" gutterBottom>
                    <p className="mb-3 text-capitalize">
                      <b> ENTER THE 6 DIGIT CODE</b>
                    </p>
                  </Typography>
                  <div className="d-flex align-items-center justify-content-center mb-3 test">
                    {digits.map((digit, index) => (
                      <TextField
                        key={index}
                        inputRef={inputRefs[index]}
                        variant="outlined"
                        size="small"
                        type="text"
                        value={digit}
                        onChange={(e) => handleChange(index, e.target.value)}
                        style={{
                          margin: isSmallScreen ? "5px" : "10px",
                          width: isSmallScreen ? "40px" : "50px",
                          height: isSmallScreen ? "40px" : "50px",
                          textAlign: "center",
                          fontSize: isSmallScreen ? "18px" : "20px",
                          // paddingLeft: "5px",
                        }}
                      />
                    ))}
                  </div>

                  <Grid item xs={12}>
                    <Button
                      type="button"
                      variant="contained"
                      className={
                        isConfirmButtonDisabled
                          ? "disabled-btn mt-3 mb-3 text-capitalize"
                          : "submit-btn mt-3 text-capitalize"
                      }
                      onClick={confirm2Fa}
                      disabled={isConfirmButtonDisabled}
                    >
                      Confirm
                    </Button>
                  </Grid>
                </CardContent>
              </Card>
            )}
          </>
        ) : (
          <Card
            sx={{ py: 3, pb: 1 }}
            style={{ borderRadius: "15px" }}
            className="success-card mx-2"
          >
            <CardContent>
              <img
                src={successImg}
                alt="success"
                width="84"
                height="84"
                className="mb-4"
              />
              <Typography variant="p" align="center" gutterBottom>
              <h4 className="success-title p-0 mb-1 text-capitalize">
                  Account created successfully
                </h4>
              </Typography>
              <Typography variant="p" align="center" gutterBottom>
                <p className="desc mb-5">
                  {" "}
                  Your onboarding has been completed successfully.
                </p>
              </Typography>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  className="submit-btn mt-1 mb-3 text-capitalize"
                  onClick={() => {
                    setTimeout(() => {
                       // dispatch(login());
                       window.location.pathname = "/";
                    }, 500);
                  }}
                >
                  Continue
                </Button>
              </Grid>
            </CardContent>
          </Card>
        )}
      </Container>

      {/*  */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={
            snackbarMessage?.includes("successful") ? "success" : "error"
          }
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default TwoFactorAuthPage;
