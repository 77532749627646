import "./../styles.css";
import headerLeft from "../../../imgs/logo-final.png";
import headerRight1 from "../../../imgs/header-right-01.png";
import headerRight2 from "../../../imgs/header-right-02.png";
import headerRight3 from "../../../imgs/header-right-03.png";
import footer from "../../../imgs/footer.png";
import letterHeadLeft from "../../../imgs/letter-head-left.png";
import letterHeadRight from "../../../imgs/letter-head-right.jpeg";
import "./style.css";

const PrintComponent = ({ children }) => {
  const printAction = () => {
    const PAGE_HEIGHT = 1045;
    const printElement = document.getElementById("print-component");
    // console.log(printElement)
    if (printElement) {
      printElement.classList.add("temp-class-for-height");
      const height = printElement.clientHeight;
      const numberOfPage = Math.ceil(height / PAGE_HEIGHT);
      const heightWithSingleHeader = numberOfPage * PAGE_HEIGHT;
      let requiredHeight = heightWithSingleHeader;
      // console.log(numberOfPage)
      if (numberOfPage > 1) {
        const headerHeight =
          printElement.getElementsByTagName("thead")?.[0]?.clientHeight;
        const footerHeight =
          printElement.getElementsByTagName("tfoot")?.[0]?.clientHeight;
        requiredHeight -= (numberOfPage - 1) * (headerHeight + footerHeight);
      }
      printElement.style.height = `${requiredHeight}px`;
      printElement.classList.remove("temp-class-for-height");
    }
    window.print();
    if (printElement) {
      printElement.style.height = `auto`;
    }
  };
  return (
    <>
      {/* <button className={"print-preview-button"} onClick={printAction}>
        {"Print Preview"}
      </button> */}

      <div class="page-header" style={{ textAlign: "center" }}>
        <div className="d-flex align-items-center pb-1">
          <img src={letterHeadLeft} height={70} width={240} className="mt-3 ms-2" />
          <img
            src={letterHeadRight}
            height={83}
            // width={83}
            className="ms-auto me-3"
          />
        </div>
        {/* I'm The Header */}
        <br />
      </div>

      <div class="page-footer">
        <div>
          <img src={footer} height={190} style={{ width: "100%" }} />
        </div>
        {/* I'm The Footer */}
      </div>

      <div className="print-component">
        <table>
          <thead>
            <tr>
              <td>
                <div class="page-header-space"></div>
              </td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <div class="page">{children}</div>
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td>
                <div class="page-footer-space"></div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  );
};

export default PrintComponent;
