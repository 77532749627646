// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* LazyImage.css */
.image-wrapper {
    position: relative;
    display: inline-block;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  
  .placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    border: 1px solid grey
  }
  
  .main-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .main-image.loaded {
    opacity: 1;
    z-index: 2;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/pages/LazyLoadImage/style.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;IACI,kBAAkB;IAClB,qBAAqB;IACrB,gBAAgB;IAChB,WAAW;IACX,YAAY;EACd;;EAEA;IACE,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,UAAU;IACV;EACF;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,UAAU;IACV,oCAAoC;EACtC;;EAEA;IACE,UAAU;IACV,UAAU;EACZ","sourcesContent":["/* LazyImage.css */\n.image-wrapper {\n    position: relative;\n    display: inline-block;\n    overflow: hidden;\n    width: 100%;\n    height: 100%;\n  }\n  \n  .placeholder {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n    z-index: 1;\n    border: 1px solid grey\n  }\n  \n  .main-image {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n    opacity: 0;\n    transition: opacity 0.5s ease-in-out;\n  }\n  \n  .main-image.loaded {\n    opacity: 1;\n    z-index: 2;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
