import React, { useEffect, useState } from "react";
import {
  updateFormContentPreview,
  getFormContentPreview,
  imageUploadClientPreview,
} from "../../../../api/services/services";
import { Button, Snackbar, Box } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import { Bucket } from "../../../../api/config/apiConfig";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import Input from "../../../atoms/Input";
import removeImg from "../../../../imgs/remove.png";
import { useParams } from "react-router-dom";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import html2pdf from "html2pdf.js";
import Typography from "@mui/material/Typography";
import ContactDetailField from "../../../molecules/ContactDetailField";
import DynamicText from "../../../molecules/DynamicText";
import { useLocation } from "react-router-dom";
import PrintComponent from "../../PrintComponent";
import PackingInventoryDownload from "./download";
import moment from "moment";
import MySignatureCanvas from "../../../molecules/signature_canvas";

const PackingInventoryClientSide = () => {
  const [formContent, setFormContent] = useState({});
  const [formContentId, setFormContentId] = useState("");

  const [surveyData, setSurveyData] = useState([]);

  const [isEdit, setIsEdit] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const urlData = new URLSearchParams(location.search);
  const email = urlData.get("email");
  const surveyId = urlData.get("surveyId");
  const formId = urlData.get("formId");
  const token = urlData.get("token");

  var totalAmount = 0;

  const [clientInd, setClientInd] = useState(-1);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const [mediaKeysToSend, setMediaKeysToSend] = useState([]);

  //
  const updateContent = (data, md) => {
    setIsLoading(true);

    let media = [];
    let mediaInd = 0;
    formContent?.clients?.map((cl, ind) => {
      if (cl?.sign?.trim()?.length > 0) {
        media[mediaInd] = cl?.sign;
        mediaInd++;
      }

      return <></>;
    });

    const body = {
      formContentId: formContentId,
      content: data ? data : formContent,
      mediaKeys: md,
      removedMediaKeys: [],
    };
    updateFormContentPreview(
      surveyId,
      formId,
      email,
      token,
      body,
      formContentId
    )
      .then((res) => {
        setSnackbarMessage("Signature has been added successfully!");

        setOpenSnackbar(true);
        getContent();
        setIsEdit(false);

        setIsLoading(false);
        setUploadTextClient1("Upload Sign");
        setUploadTextClient2("Upload Sign");
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          setIsValid(false);
        }
        setUploadTextClient1("Upload Sign");
        setUploadTextClient2("Upload Sign");
        setIsLoading(false);
      });
  };

  const [isValid, setIsValid] = useState(false);

  //
  const getContent = () => {
    setIsLoading(true);
    getFormContentPreview(surveyId, formId, email, token)
      .then((res) => {
        setIsValid(true);
        setFormContentId(res.data.data.uuid);
        setFormContent(res.data.data.content);

        for (let i = 0; i < res.data.data.content?.clients?.length; i++) {
          if (res.data.data.content?.clients?.[i]?.email == email) {
            setClientInd(i);
            i = res.data.data.content?.length;
          }
        }

        setIsLoading(false);
      })
      .catch((error) => {
        setIsValid(false);
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        setIsLoading(false);
      });
  };

  useEffect(() => {
    getContent();
  }, []);

  const [show, setShow] = useState(false);
  const options = {
    // resolution: Resolution.HIGH,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      format: "letter",
      // default is 'portrait'
      orientation: "portrait",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/png",
      qualityRatio: 1,
    },
    overrides: {
      pdf: {
        compress: true,
      },
    },
  };

  //
  const generatePdf = () => {
    setShow(true);

    setTimeout(() => {
      const content = document.getElementById("content-to-convert"); // Replace with the ID of the element you want to convert to PDF

      const currentDateTime = new Date();
      const filename = `survey_${currentDateTime.toISOString()}.pdf`;

      const pdfOptions = {
        margin: 5,
        filename: filename,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
        }, // Set the page size here
      };

      let temp = html2pdf().from(content).set(pdfOptions).outputPdf();
      temp.save(filename);

      setShow(false);
    }, 3500);
  };

  const [downloadText, setDownloadText] = useState("download");

  const getTargetElement = () => document.getElementById("content-id");

  const [uploadTextClient1, setUploadTextClient1] = useState("Upload Sign");
  const [uploadTextClient2, setUploadTextClient2] = useState("Upload Sign");

  const [uploadText1, setUploadText1] = useState("Upload Sign");
  const [uploadText2, setUploadText2] = useState("Upload Sign");


  const [isSignCanvas, setSignCanvas] = useState(false);
  const [whichSign, setWhichSign] = useState("");

  //
  const uploadFile = async (file) => {

    setIsLoading(true);

    if (whichSign == "destClient") setUploadTextClient1("Uploading...");
    if (whichSign == "originClient") setUploadTextClient2("Uploading...");
    const fd = new FormData();
    fd.append("image", file);

    imageUploadClientPreview(fd, surveyId, formId, email, token)
      .then((res) => {
        let values = { ...formContent };
        // values.clients[clientInd].sign = res?.data?.data?.key;
        // if (email == formContent?.clientEmail)
        //   values.clientSign = res?.data?.data?.key;

        if (whichSign == "destClient")
          values.clientSignDestination = res?.data?.data?.key;
        if (whichSign == "originClient") values.clientSign = res?.data?.data?.key;

        let md = [...mediaKeysToSend];
        md.push(res?.data?.data?.key);
        setMediaKeysToSend(md);

        setFormContent(values);

        setTimeout(() => {
          updateContent(values, [res?.data?.data?.key]);
        }, 1500);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          setIsValid(false);
        }

        setUploadTextClient1("Upload Sign");
        setUploadTextClient2("Upload Sign");

        setIsLoading(false);
      });

  };

  //
  const uploadFileContractor = async (event, val) => {
    event.preventDefault();
    setIsLoading(true);

    if (event.target.files.length > 0) {
      if (val == "destContractor") setUploadText1("Uploading...");
      if (val == "originContractor") setUploadText2("Uploading...");

      const fd = new FormData();
      fd.append("image", event.target.files[0]);

      imageUploadClientPreview(fd, surveyId, formId, email, token)
        .then((res) => {
          let values = { ...formContent };
          if (val == "destContractor")
            values.destContractorSign = res?.data?.data?.key;
          if (val == "originContractor")
            values.originContractorSign = res?.data?.data?.key;
          setFormContent(values);

          let md = [...mediaKeysToSend];
          md.push(res?.data?.data?.key);
          setMediaKeysToSend(md);

          setIsLoading(false);
          setUploadText1("Upload Sign");
          setUploadText2("Upload Sign");

          setTimeout(() => {
            updateContent(values, [res?.data?.data?.key]);
          }, 1500);
        })
        .catch((error) => {
          setSnackbarMessage(error?.response?.data?.message);
          setOpenSnackbar(true);

          if (error?.response?.data?.statusCode == 401) {
            localStorage.clear();
            window.location.pathname = "/login";
          }

          setUploadText1("Upload Sign");
          setUploadText2("Upload Sign");

          setIsLoading(false);
        });
    }
  };

  const printAction = () => {
    const PAGE_HEIGHT = 1045;
    const printElement = document.getElementById("print-component");
    if (printElement) {
      printElement.classList.add("temp-class-for-height");
      const height = printElement.clientHeight - 500;
      const numberOfPage = Math.ceil(height / PAGE_HEIGHT);
      const heightWithSingleHeader = numberOfPage * PAGE_HEIGHT;
      let requiredHeight = heightWithSingleHeader;
      if (numberOfPage > 1) {
        const headerHeight =
          printElement.getElementsByTagName("thead")?.[0]?.clientHeight;
        const footerHeight =
          printElement.getElementsByTagName("tfoot")?.[0]?.clientHeight;
        requiredHeight -= (numberOfPage - 1) * (headerHeight + footerHeight);
      }
      printElement.style.height = `${requiredHeight}px`;
      printElement.classList.remove("temp-class-for-height");
    }
    window.print();
    if (printElement) {
      printElement.style.height = `auto`;
    }
  };

  return (
    <>
      {isValid ? (
        <div className="mx-3 mx-md-5 no-print" style={{ minHeight: "100vh" }}>
          <div className="d-block d-sm-flex align-items-center py-4">
            <div className="d-flex align-items-center ">
              <p className="main-head  mb-0">Packing Inventory</p>
            </div>
            <div className="d-flex ms-auto justify-content-center mt-2 mt-sm-0">


              {/* Download Button */}
              {!isEdit && (
                <Button
                  className="active-btn ms-3 px-2"
                  onclick={() => {
                    setIsLoading(true);
                    // printAction();
                    var tempTitle = document.title;
                    document.title = `Packing Inventory-${formContent?.clientName
                      }-${moment(new Date()).format("DD.MM.YYYY")}`;

                    window.print();
                    document.title = tempTitle;
                    setIsLoading(false);
                  }}
                  disabled={isLoading}
                >
                  {"Save as PDF"}
                </Button>
              )}
            </div>
          </div>

          {/* file detail*/}
          <div className="contact-detail d-flex mt-4">
            {/* left col */}
            <div
              className={`left-col ${!isEdit ? " w-50" : "w-50 pe-5"
                } text-start`}
            >
              <Typography variant="body1" gutterBottom>
                <ContactDetailField
                  label="Client Name"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"160px"}
                  value={formContent?.clientName}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.clientName = e.target.value;
                    setFormContent(values);
                  }}
                />
                <ContactDetailField
                  label="Origin Address"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"160px"}
                  value={formContent?.originAddress}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.originAddress = e.target.value;
                    setFormContent(values);
                  }}
                />
                <ContactDetailField
                  label="Destination Address"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"160px"}
                  value={formContent?.destinationAddress}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.destinationAddress = e.target.value;
                    setFormContent(values);
                  }}
                />

                <ContactDetailField
                  label="Organization"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"160px"}
                  value={formContent?.organization}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.organization = e.target.value;
                    setFormContent(values);
                  }}
                />
              </Typography>
            </div>
            {/* right col */}
            <div
              className={`right-col ${!isEdit ? "w-50" : "w-50 ps-5"
                }  d-flex flex-column align-items-end pe-2`}
            >
              <div
                className=""
                style={isEdit ? { width: "-webkit-fill-available" } : {}}
              >
                <Typography
                  variant="body1"
                  className="text-start w-100"
                  gutterBottom
                >
                  <ContactDetailField
                    label="File Ref"
                    isEdit={isEdit}
                    type="text"
                    minWidth={"120px"}
                    value={formContent?.fileRef}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.fileRef = e.target.value;
                      setFormContent(values);
                    }}
                    disabled
                  />

                  <ContactDetailField
                    label="Van No. "
                    isEdit={isEdit}
                    type="text"
                    wrapperStyle="justify-content-start"
                    minWidth={"120px"}
                    value={formContent?.vanNo}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.vanNo = e.target.value;
                      setFormContent(values);
                    }}
                  />
                  <ContactDetailField
                    label="Date"
                    isEdit={isEdit}
                    type="date"
                    wrapperStyle="justify-content-start"
                    minWidth={"120px"}
                    value={formContent?.date}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.date = e.target.value;
                      setFormContent(values);
                    }}
                  />
                </Typography>
              </div>
            </div>
          </div>
          {/* /file detail */}

          {/* Symbols */}
          <Row className="mx-0 mt-3">
            {" "}
            <Col className="col-12 col-md-5  text-start ps-0 mb-1">
              <h5>DESCRIPTIVE SYMBOLS</h5>
            </Col>
            <Col className="col-12 col-md-5  text-start ps-0 mb-1">
              <h5>EXCEPTION SYMBOLS</h5>
            </Col>
          </Row>
          <Row className="mx-0">
            <Col className="col-12 col-md-5  ">
              <div className="p-3 row  bg-white me-3">
                <div className="col-6 px-2">
                  <div
                    className={`p-2 px-0 d-flex justify-content-center  mb-3  ${formContent?.descriptiveSymbols?.professionalBooks
                      ? "symbols-active"
                      : "symbols-inactive"
                      }`}
                    onClick={() => {
                      let values = { ...formContent };
                      values.descriptiveSymbols.professionalBooks =
                        !values.descriptiveSymbols.professionalBooks;
                      setFormContent(values);
                    }}
                  >
                    {" "}
                    PB - PROFESSIONAL BOOKS
                  </div>
                </div>
                {/*  */}
                <div className="col-6 px-2">
                  <div
                    className={`p-2 px-0 d-flex justify-content-center  mb-3  ${formContent?.descriptiveSymbols?.colorTV
                      ? "symbols-active"
                      : "symbols-inactive"
                      }`}
                    onClick={() => {
                      let values = { ...formContent };
                      values.descriptiveSymbols.colorTV =
                        !values.descriptiveSymbols.colorTV;
                      setFormContent(values);
                    }}
                  >
                    {" "}
                    C/T - COLOR TV
                  </div>
                </div>

                {/*  */}
                <div className="col-6 px-2">
                  <div
                    className={`p-2 px-0 d-flex justify-content-center  mb-3  ${formContent?.descriptiveSymbols?.professionalEquipments
                      ? "symbols-active"
                      : "symbols-inactive"
                      }`}
                    onClick={() => {
                      let values = { ...formContent };
                      values.descriptiveSymbols.professionalEquipments =
                        !values.descriptiveSymbols.professionalEquipments;
                      setFormContent(values);
                    }}
                  >
                    {" "}
                    PE - PROFESSIONAL EQUIPMENTS
                  </div>
                </div>

                {/*  */}
                <div className="col-6 px-2">
                  <div
                    className={`p-2 px-0 d-flex justify-content-center  mb-3  ${formContent?.descriptiveSymbols?.carrierDis
                      ? "symbols-active"
                      : "symbols-inactive"
                      }`}
                    onClick={() => {
                      let values = { ...formContent };
                      values.descriptiveSymbols.carrierDis =
                        !values.descriptiveSymbols.carrierDis;
                      setFormContent(values);
                    }}
                  >
                    {" "}
                    CD - CARRIER DISASSEMBLED
                  </div>
                </div>

                {/*  */}

                <div className="col-6 px-2">
                  <div
                    className={`p-2 px-0 d-flex justify-content-center  mb-3 ${formContent?.descriptiveSymbols?.professionalPapers
                      ? "symbols-active"
                      : "symbols-inactive"
                      }`}
                    onClick={() => {
                      let values = { ...formContent };
                      values.descriptiveSymbols.professionalPapers =
                        !values.descriptiveSymbols.professionalPapers;
                      setFormContent(values);
                    }}
                  >
                    {" "}
                    PP - PROFESSIONAL PAPERS
                  </div>
                </div>

                {/*  */}

                <div className="col-6 px-2">
                  <div
                    className={`p-2 px-0 d-flex justify-content-center  mb-3  ${formContent?.descriptiveSymbols?.disByOwner
                      ? "symbols-active"
                      : "symbols-inactive"
                      }`}
                    onClick={() => {
                      let values = { ...formContent };
                      values.descriptiveSymbols.disByOwner =
                        !values.descriptiveSymbols.disByOwner;
                      setFormContent(values);
                    }}
                  >
                    {" "}
                    DBO - DISASSEMBLED BY OWNER
                  </div>
                </div>

                {/*  */}
                <div className="col-6 px-2">
                  <div
                    className={`p-2 px-0 d-flex justify-content-center  mb-0 ${formContent?.descriptiveSymbols?.carrierPacked
                      ? "symbols-active"
                      : "symbols-inactive"
                      }`}
                    onClick={() => {
                      let values = { ...formContent };
                      values.descriptiveSymbols.carrierPacked =
                        !values.descriptiveSymbols.carrierPacked;
                      setFormContent(values);
                    }}
                  >
                    {" "}
                    CP - CARRIER PACKED
                  </div>
                </div>

                {/*  */}
              </div>
            </Col>
            <Col className="col-12 col-md-7 ">
              <div className="p-3 row  bg-white ">
                {/*  */}
                <div className="col-4 px-2">
                  <div
                    className={`p-2 px-0 d-flex justify-content-center  mb-3  ${formContent?.exceptions?.rent
                      ? "symbols-active"
                      : "symbols-inactive"
                      }`}
                    onClick={() => {
                      let values = { ...formContent };
                      values.exceptions.rent = !values.exceptions?.rent;
                      setFormContent(values);
                    }}
                  >
                    {" "}
                    RE- RENT
                  </div>
                </div>

                {/*  */}
                <div className="col-4 px-2">
                  <div
                    className={`p-2 px-0 d-flex justify-content-center mb-3  ${formContent?.exceptions?.dented
                      ? "symbols-active"
                      : "symbols-inactive"
                      }`}
                    onClick={() => {
                      let values = { ...formContent };
                      values.exceptions.dented = !values.exceptions?.dented;
                      setFormContent(values);
                    }}
                  >
                    {" "}
                    D- DENTED
                  </div>
                </div>

                {/*  */}
                <div className="col-4 px-2">
                  <div
                    className={`p-2 px-0 d-flex justify-content-center mb-3  ${formContent?.exceptions?.rubbed
                      ? "symbols-active"
                      : "symbols-inactive"
                      }`}
                    onClick={() => {
                      let values = { ...formContent };
                      values.exceptions.rubbed = !values.exceptions?.rubbed;
                      setFormContent(values);
                    }}
                  >
                    {" "}
                    R- RUBBED
                  </div>
                </div>

                {/*  */}
                <div className="col-4 px-2">
                  <div
                    className={`p-2 px-0 d-flex justify-content-center mb-3  ${formContent?.exceptions?.broken
                      ? "symbols-active"
                      : "symbols-inactive"
                      }`}
                    onClick={() => {
                      let values = { ...formContent };
                      values.exceptions.broken = !values.exceptions?.broken;
                      setFormContent(values);
                    }}
                  >
                    {" "}
                    BR-BROKEN
                  </div>
                </div>

                {/*  */}

                <div className="col-4 px-2">
                  <div
                    className={`p-2 px-0 d-flex justify-content-center mb-3  ${formContent?.exceptions?.faded
                      ? "symbols-active"
                      : "symbols-inactive"
                      }`}
                    onClick={() => {
                      let values = { ...formContent };
                      values.exceptions.faded = !values.exceptions?.faded;
                      setFormContent(values);
                    }}
                  >
                    {" "}
                    F-FADED
                  </div>
                </div>

                {/*  */}
                <div className="col-4 px-2">
                  <div
                    className={`p-2 px-0 d-flex justify-content-center mb-3  ${formContent?.exceptions?.rusted
                      ? "symbols-active"
                      : "symbols-inactive"
                      }`}
                    onClick={() => {
                      let values = { ...formContent };
                      values.exceptions.rusted = !values.exceptions?.rusted;
                      setFormContent(values);
                    }}
                  >
                    {" "}
                    RU-RUSTED
                  </div>
                </div>

                {/*  */}
                <div className="col-4 px-2">
                  <div
                    className={`p-2 px-0 d-flex justify-content-center mb-3  ${formContent?.exceptions?.burned
                      ? "symbols-active"
                      : "symbols-inactive"
                      }`}
                    onClick={() => {
                      let values = { ...formContent };
                      values.exceptions.burned = !values.exceptions?.burned;
                      setFormContent(values);
                    }}
                  >
                    {" "}
                    BU-BURNED
                  </div>
                </div>

                {/*  */}
                {/*  */}
                <div className="col-4 px-2">
                  <div
                    className={`p-2 px-0 d-flex justify-content-center mb-3  ${formContent?.exceptions?.loose
                      ? "symbols-active"
                      : "symbols-inactive"
                      }`}
                    onClick={() => {
                      let values = { ...formContent };
                      values.exceptions.loose = !values.exceptions?.loose;
                      setFormContent(values);
                    }}
                  >
                    {" "}
                    L-LOOSE
                  </div>
                </div>

                {/*  */}
                <div className="col-4 px-2">
                  <div
                    className={`p-2 px-0 d-flex justify-content-center mb-3  ${formContent?.exceptions?.badly
                      ? "symbols-active"
                      : "symbols-inactive"
                      }`}
                    onClick={() => {
                      let values = { ...formContent };
                      values.exceptions.badly = !values.exceptions?.badly;
                      setFormContent(values);
                    }}
                  >
                    {" "}
                    W-BADLY
                  </div>
                </div>

                {/*  */}
                <div className="col-4 px-2">
                  <div
                    className={`p-2 px-0 d-flex justify-content-center ${formContent?.exceptions?.chipped
                      ? "symbols-active"
                      : "symbols-inactive"
                      }`}
                    onClick={() => {
                      let values = { ...formContent };
                      values.exceptions.chipped = !values.exceptions?.chipped;
                      setFormContent(values);
                    }}
                  >
                    {" "}
                    CH-CHIPPED
                  </div>
                </div>

                {/*  */}
                <div className="col-4 px-2">
                  <div
                    className={`p-2 px-0 d-flex justify-content-center   ${formContent?.exceptions?.marred
                      ? "symbols-active"
                      : "symbols-inactive"
                      }`}
                    onClick={() => {
                      let values = { ...formContent };
                      values.exceptions.marred = !values.exceptions?.marred;
                      setFormContent(values);
                    }}
                  >
                    {" "}
                    M-MARRED
                  </div>
                </div>

                {/*  */}
              </div>
            </Col>
          </Row>

          <Grid container className="pe-1 my-4 ">
            <TableContainer component={Paper} className="pb-3">
              <Table>
                {/* Table Header */}
                <TableHead>
                  <TableRow>
                    <TableCell className="table-head min-w-100">
                      Item No.
                    </TableCell>
                    <TableCell className="table-head min-w-400">
                      Goods Description
                    </TableCell>
                    <TableCell className="table-head min-w-100">
                      Condition at Origin
                    </TableCell>
                    <TableCell className="table-head min-w-150">
                      Condition at Destination
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formContent?.dataRows?.length > 0 ? (
                    <>
                      {formContent?.dataRows.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell> {index + 1}</TableCell>
                          <TableCell>
                            {isEdit ? (
                              <>
                                {/* <TextArea
                              rows="2"
                              label="Description..."
                              id="description"
                              value={row?.description}
                              onChange={(e) => {
                                const values = { ...formContent };
                                const valuesRows = [...formContent?.dataRows];
                                valuesRows.description = e.target.value;
                                values.dataRows = valuesRows;
                                setFormContent(values);
                              }}
                            /> */}

                                <Input
                                  id="condition"
                                  label="Description..."
                                  type="text"
                                  style="full-border-field"
                                  value={row?.description}
                                  onChange={(e) => {
                                    const values = { ...formContent };
                                    const valuesRows = [
                                      ...formContent?.dataRows,
                                    ];
                                    valuesRows[index].description =
                                      e.target.value;
                                    values.dataRows = valuesRows;
                                    setFormContent(values);
                                  }}
                                  inline={{ width: "-webkit-fill-available" }}
                                />
                              </>
                            ) : row?.description ? (
                              row?.description
                            ) : (
                              "-"
                            )}
                          </TableCell>
                          <TableCell>
                            {isEdit ? (
                              <Input
                                id="condition"
                                label="Condition at origin..."
                                type="text"
                                style="full-border-field"
                                value={row?.conditionAtOrigin}
                                onChange={(e) => {
                                  const values = { ...formContent };
                                  const valuesRows = [...formContent?.dataRows];
                                  valuesRows[index].conditionAtOrigin =
                                    e.target.value;
                                  values.dataRows = valuesRows;
                                  setFormContent(values);
                                }}
                                inline={{ width: "-webkit-fill-available" }}
                              />
                            ) : row?.conditionAtOrigin ? (
                              row?.conditionAtOrigin
                            ) : (
                              "-"
                            )}
                          </TableCell>
                          <TableCell>
                            {isEdit ? (
                              <Input
                                id="disclamer"
                                label="Condition at destination..."
                                type="text"
                                style="full-border-field"
                                value={row?.conditionAtDestination}
                                onChange={(e) => {
                                  const values = { ...formContent };
                                  const valuesRows = [...formContent?.dataRows];
                                  valuesRows[index].conditionAtDestination =
                                    e.target.value;
                                  values.dataRows = valuesRows;
                                  setFormContent(values);
                                }}
                                inline={{ width: "-webkit-fill-available" }}
                              />
                            ) : row?.conditionAtDestination ? (
                              row?.conditionAtDestination
                            ) : (
                              "-"
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  ) : (
                    <TableRow className="text-center">
                      <TableCell colSpan={5}>
                        {isLoading ? (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              margin: "10px 0px",
                            }}
                          >
                            <CircularProgress
                              style={{
                                color: "#00aff0",
                                height: "25px",
                                width: "25px",
                              }}
                            />
                          </Box>
                        ) : (
                          <div className="d-flex justify-content-center">
                            <p className="my-3">No Records to Show</p>
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          {/* Remarks */}
          <div className="mt-5 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <DynamicText
                label="Remarks:"
                textAreaLabel="Remarks..."
                isEdit={isEdit}
                value={formContent?.remarks}
                onChange={(e) => {
                  let values = { ...formContent };
                  values.remarks = e.target.value;
                  setFormContent(values);
                }}
              />
            </Typography>
          </div>
          {/* /Remakes */}

          <div className="mt-5 pe-1 d-flex justify-content-start flex-wrap align-items-baseline mb-5">
            <div>
              <p className="mb-2">
                WE HAVE CHECKED ALL THE ITEM LISTED AND NUMBERED
              </p>
            </div>
            {isEdit ? (
              <input
                type={"text"}
                // className={`${style || "input-field "}`}
                className={`mx-3 input-field`}
                // placeholder={label}
                value={formContent?.number1}
                onChange={(e) => {
                  let values = { ...formContent };
                  values.number1 = e.target.value;
                  setFormContent(values);
                }}
                style={{ width: "300px" }}
              />
            ) : (
              <div>
                <p className="mb-2 mx-2 text-decoration-underline">
                  <b>{formContent?.number1}</b>
                </p>
              </div>
            )}
            <div>
              <p className="mb-2">to</p>
            </div>
            {isEdit ? (
              <input
                type={"text"}
                // className={`${style || "input-field "}`}
                className={`mx-3 input-field`}
                // placeholder={label}
                value={formContent?.number2}
                onChange={(e) => {
                  let values = { ...formContent };
                  values.number2 = e.target.value;
                  setFormContent(values);
                }}
                style={{ width: "300px" }}
              />
            ) : (
              <div>
                <p className="mb-2 mx-2 text-decoration-underline">
                  <b>{formContent?.number2}</b>
                </p>
              </div>
            )}
            <div>
              <p className="mb-2 ">
                INCLUSIVE AND ACKNOWLEDGE THAT THIS IS A TRUE AND COMPLETE LIST
                OF THE GOODS.
              </p>
            </div>
          </div>

          <Row className="mt-5 pt-5 no-print">
            <Col sm={6} className="mt-5 pt-5">
              <div
                style={{ height: "90px" }}
                className="d-flex justify-content-center align-items-end"
              >
                {formContent?.destContractorSign?.trim()?.length == 0 ? (
                  <>
                    {formContent?.clientEmail != email && (
                      <div className="d-flex justify-content-center align-items-end">
                        <label
                          htmlFor="uploadSign2"
                          className="active-btn ms-2 w-200 d-flex align-items-center justify-content-center cursor-pointer btn"
                        >
                          {uploadText1}
                        </label>
                        <input
                          type="file"
                          hidden
                          id="uploadSign2"
                          name="uploadSign2"
                          onChange={(e) => {
                            if (!isLoading)
                              uploadFileContractor(e, "destContractor");
                          }}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <img
                    src={`${Bucket}${formContent.destContractorSign}`}
                    height={80}
                    width={200}
                  />
                )}
              </div>
              <p>{"_______________________________"}</p>
              <p className="my-1">
                <b>{"Contractor Carrier OR Authorized Agent at DESTINATION"}</b>
              </p>

              {""}
            </Col>
            <Col sm={6} className="mt-5 pt-5">
              <div
                style={{ height: "90px" }}
                className="d-flex justify-content-center align-items-end"
              >
                {formContent?.originContractorSign?.trim()?.length == 0 ? (
                  <>
                    {formContent?.clientEmail != email && (
                      <div className="d-flex justify-content-center align-items-end">
                        <label
                          htmlFor="uploadSignn"
                          className="active-btn ms-2 w-200 d-flex align-items-center justify-content-center cursor-pointer btn"
                        >
                          {uploadText2}
                        </label>
                        <input
                          type="file"
                          hidden
                          id="uploadSignn"
                          name="uploadSign"
                          onChange={(e) => {
                            if (!isLoading)
                              uploadFileContractor(e, "originContractor");
                          }}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <img
                    src={`${Bucket}${formContent?.originContractorSign}`}
                    height={80}
                    width={200}
                  />
                )}
              </div>

              <p>{"_______________________________"}</p>
              <p className="my-1">
                <b>{"Contractor Carrier OR Authorized Agent at ORIGIN"}</b>
              </p>

              {""}
            </Col>

            <Col sm={6} className="mt-5 pt-5">
              <div
                style={{ height: "90px" }}
                className="d-flex justify-content-center align-items-end"
              >
                {formContent.clientSignDestination?.trim()?.length != 0 ? (
                  <img
                    src={`${Bucket}${formContent.clientSignDestination}`}
                    height={80}
                    width={200}
                  />
                ) : (
                  <>
                    {formContent?.clientEmail == email && (
                      <label
                        htmlFor="uploadSignClient1"
                        className="active-btn ms-2 w-150 d-flex align-items-center justify-content-center cursor-pointer btn"
                      >
                        {uploadTextClient1}
                      </label>
                    )}
                    <input
                      type="button"
                      hidden
                      id="uploadSignClient1"
                      name="uploadSign"
                      onClick={() => {
                        setWhichSign("destClient");
                        setSignCanvas(true);

                      }}
                    />
                  </>
                )}
              </div>
              <p>{"_______________________________"}</p>
              <p className="mt-1 mb-1">
                <b>{"Client OR Authorized Person at DESTINATION"}</b>
              </p>

              {""}
            </Col>

            <Col sm={6} className="mt-5 pt-5">
              <div
                style={{ height: "90px" }}
                className="d-flex justify-content-center align-items-end"
              >
                {formContent.clientSign?.trim()?.length != 0 ? (
                  <img
                    src={`${Bucket}${formContent.clientSign}`}
                    height={80}
                    width={200}
                  />
                ) : (
                  <>
                    {formContent?.clientEmail == email && (
                      <label
                        htmlFor="uploadSignClient2"
                        className="active-btn ms-2 w-150 d-flex align-items-center justify-content-center cursor-pointer btn"
                      >
                        {uploadTextClient2}
                      </label>
                    )}
                    <input
                      type="button"
                      hidden
                      id="uploadSignClient2"
                      name="uploadSign"
                      onClick={() => {
                        setWhichSign("originClient");
                        setSignCanvas(true);
                      }}
                    />
                  </>
                )}
              </div>
              <p>{"_______________________________"}</p>
              <p className="mt-1 mb-1">
                <b>{"Client OR Authorized Person at ORIGIN"}</b>
              </p>

              {""}
            </Col>
          </Row>
        </div>
      ) : (
        <>
          {isLoading ? (
            <div> Loading...</div>
          ) : (
            <div> Ooops... Something went wrong...</div>
          )}
        </>
      )}

      <PrintComponent>
        <PackingInventoryDownload
          id="content-id"
          surveyData={surveyData}
          formContent={formContent}
        />
      </PrintComponent>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={
            snackbarMessage?.includes("successful") ? "success" : "error"
          }
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
       {/* Add signature Modal */}
       {isSignCanvas && (
          <MySignatureCanvas
            open={isSignCanvas}
            onClose={() => {
              setSignCanvas(false);
            }}
            onSuccess={(file) => {
              if (!isLoading) uploadFile(file);
            }}
          />
        )}
    </>
  );
};
export default PackingInventoryClientSide;
