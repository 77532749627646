// FourOptionsDynamicCheckbox.js
import React from "react";
import CustomCheckbox from "../atoms/CustomCheckbox";

const FourOptionsDynamicCheckbox = ({
  label,
  value1,
  value2,
  value3,
  value4,
  checkId1,
  checkLabel1,
  checkId2,
  checkLabel2,
  checkId3,
  checkLabel3,
  checkId4,
  checkLabel4,
  onChange1,
  onChange2,
  onChange3,
  onChange4,
  disabled

}) => {
  
  return (
    <div className="mb-2 d-flex">
      <label htmlFor={checkId1} className="fw-bold mb-2" style={{minWidth: '200px'}}>
        {label}
      </label>
      <div className="d-flex gap-5">
        <CustomCheckbox value={value1 ? true  : false} id={checkId1} label={checkLabel1} checked={value1 ? true  : false} onChange={onChange1} disabled={disabled ? true : false}/>
        <CustomCheckbox value={value2 ? true  : false} id={checkId2} label={checkLabel2} checked={value2 ? true  : false} onChange={onChange2} disabled={disabled ? true : false}/>
        <CustomCheckbox value={value3 ? true  : false} id={checkId3} label={checkLabel3} checked={value3 ? true  : false} onChange={onChange3} disabled={disabled ? true : false}/>
        <CustomCheckbox value={value4 ? true  : false} id={checkId4} label={checkLabel4} checked={value4 ? true  : false} onChange={onChange4} disabled={disabled ? true : false}/>
      </div>
    </div>
  );
};

export default FourOptionsDynamicCheckbox;
