// src/modules/redux/reducers/index.js
import { combineReducers } from 'redux';
import authReducer from './authReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  // Add other reducers if needed

  ///* needed to createe a survey data
});

export default rootReducer;
