import React, { useEffect, useState } from "react";
import {
  getFormContent,
  getSingleSurvey,
  updateFormContent,
  surveyResultPreview,
  sharePreviewLink,
  addFormContent,
} from "../../../api/services/services";
import backArrow from "../../../imgs/BackArrow.svg";
import MainTemplate from "../../templates/MainTemplate";
import { useNavigate } from "react-router-dom";
import { Button, Snackbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import { Bucket } from "../../../api/config/apiConfig";
import ContactDetailField from "../../molecules/ContactDetailField";
import Input from "../../atoms/Input";
import DynamicText from "../../molecules/DynamicText";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import html2pdf from "html2pdf.js";
import { useParams } from "react-router-dom";
import PrintComponent from "../PrintComponent";
import AcknowledgementReceiptDownload from "./ClientSide/download";
import TextArea from "../../atoms/Textarea";
import moment from "moment";
import ShareWithClientsModal from "../../molecules/ShareWithClients";
import { sendSuperAdminVerificationForEdit } from "../PrebidAgreement/creation/service";
import SuperAdminVerifationModal from "../PrebidAgreement/creation/super_admin_verification_modal";

const AcknowledgementReceiptPage = () => {
  const { surveyId, id, flg } = useParams();
  const [surveyData, setSurveyData] = useState(null);
  const navigate = useNavigate();

  const [formContentId, setFormContentId] = useState("");
  const [isEdit, setIsEdit] = useState(flg == "1" ? true : false);

  const [isLoading, setIsLoading] = useState(false);
  const [formContent, setFormContent] = useState({
    date: "",
    receivedFrom: "",
    address: "",
    onAccountOf: "",
    amount: "",
    chequeNo: "",
    drawnOnBank: "",
    inWords: "",
    receivedBy: "",
    clientEmail: "",
    clientName: "",
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  //
  const getSurveyPreview = () => {
    surveyResultPreview(surveyId)
      .then((response) => {
        setSurveyData(response?.data?.data?.surveyDetail);
        let values = { ...formContent };

        values.clientEmail = `${response?.data?.data?.surveyDetail?.email}`;
        values.clientName = `${response?.data?.data?.surveyDetail?.name}`;

        setFormContent(values);
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }
      });
  };

  //
  const getSurvey = () => {
    getSingleSurvey(surveyId)
      .then((response) => {
        if (response?.data?.data?.status == "submitted") getSurveyPreview();
        else {
          setSurveyData(response?.data?.data);
          let values = { ...formContent };

          values.clientEmail = `${response?.data?.data?.email}`;
          values.clientName = `${response?.data?.data?.name}`;

          setFormContent(values);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }
      });
  };

  //
  const addContent = () => {
    setIsLoading(true);
    const body = {
      formId: id,
      surveyId: surveyId,
      content: formContent,
      mediaKeys: [],
    };
    addFormContent(body)
      .then(() => {
        setSnackbarMessage(
          "Acknowledgement Receipt has been added successfully!"
        );
        setOpenSnackbar(true);

        setTimeout(() => {
          navigate(-1);
          setIsLoading(false);
        }, 1000);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  //
  const updateContent = () => {
    setIsLoading(true);
    const body = {
      formId: id,
      surveyId: surveyId,
      content: formContent,
      mediaKeys: [],
      removedMediaKeys: [],
    };
    updateFormContent(body, formContentId)
      .then((res) => {
        setSnackbarMessage(
          "Acknowledgement Receipt has been updated successfully!"
        );
        setOpenSnackbar(true);
        getContent();
        setIsEdit(false);

        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  //
  const getContent = () => {
    setIsLoading(true);
    getFormContent(id, surveyId)
      .then((res) => {
        setFormContentId(res.data.data.uuid);
        setFormContent(res.data.data.content);
        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (flg == "1") getSurvey();
    else getContent();
  }, []);

  const [show, setShow] = useState(false);
  const options = {
    // resolution: Resolution.HIGH,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      format: "letter",
      // default is 'portrait'
      orientation: "portrait",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/png",
      qualityRatio: 1,
    },
    overrides: {
      pdf: {
        compress: true,
      },
    },
  };

  //
  const generatePdf = () => {
    setShow(true);

    setTimeout(() => {
      const content = document.getElementById("content-to-convert"); // Replace with the ID of the element you want to convert to PDF

      const currentDateTime = new Date();
      const filename = `survey_${currentDateTime.toISOString()}.pdf`;

      const pdfOptions = {
        margin: 5,
        filename: filename,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
        }, // Set the page size here
      };

      let temp = html2pdf().from(content).set(pdfOptions).outputPdf();
      temp.save(filename);

      setShow(false);
    }, 3500);
  };

  const [downloadText, setDownloadText] = useState("download");
  const [isShare, setIsShare] = useState(false);

  const getTargetElement = () => document.getElementById("content-id");

  //
  const shareWithClient = (data) => {
    let ems = [];
    let emsInd = 0;
    data.map((dt) => {
      if (dt.trim().length != 0) {
        ems[emsInd] = dt;
        emsInd++;
      }
    });

    setIsLoading(true);
    const body = {
      surveyId: surveyId,
      formId: id,
      emails: ems,
      formRoute: "acknowledge-receipt",
    };
    sharePreviewLink(body)
      .then(() => {
        setSnackbarMessage(
          "Acknowledgement Receipt has been shared with client successfully!"
        );
        setOpenSnackbar(true);

        setIsLoading(false);

        setIsShare(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  const printAction = () => {
    const PAGE_HEIGHT = 1045;
    const printElement = document.getElementById("print-component");
    if (printElement) {
      printElement.classList.add("temp-class-for-height");
      const height = printElement.clientHeight;
      const numberOfPage = Math.ceil(height / PAGE_HEIGHT);
      const heightWithSingleHeader = numberOfPage * PAGE_HEIGHT;
      let requiredHeight = heightWithSingleHeader;
      if (numberOfPage > 1) {
        const headerHeight =
          printElement.getElementsByTagName("thead")?.[0]?.clientHeight;
        const footerHeight =
          printElement.getElementsByTagName("tfoot")?.[0]?.clientHeight;
        requiredHeight -= (numberOfPage - 1) * (headerHeight + footerHeight);
      }
      printElement.style.height = `${requiredHeight}px`;
      printElement.classList.remove("temp-class-for-height");
    }
    window.print();
    if (printElement) {
      printElement.style.height = `auto`;
    }
  };

  const [isVerified, setIsVerified] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);

  const sendEditRequestToAdmin = () => {
    setIsEditLoading(true);
    sendSuperAdminVerificationForEdit()
      .then((response) => {
        setIsVerified(true);
        setIsEditLoading(false);
      })
      .catch((error) => {
        setIsVerified(false);
        setIsEditLoading(false);
      });
  };

  return (
    <>
      <MainTemplate>
        <div className="no-print">
          <div className="d-block d-sm-flex align-items-center py-4">
            <div className="d-flex align-items-center ">
              <div
                className="light-blue-color back-bg cursor-pointer"
                onClick={() => navigate(-1)}
              >
                <img src={backArrow} height="24" width="24" />{" "}
              </div>
              <div>
                <p className="main-head ps-3 mb-0">Acknowledgement Receipt</p>
              </div>
            </div>
            <div className="d-flex ms-auto justify-content-center mt-2 mt-sm-0">
              {/*Edit Button */}
              {!isEdit && (
                <Button
                  className="active-btn"
                  onClick={() => {
                    sendEditRequestToAdmin();
                  }}
                  disabled={isEditLoading}
                >
                  Edit
                </Button>
              )}
              {/*Share Button */}
              {!isEdit && (
                <Button
                  className="active-btn ms-3"
                  onClick={() => {
                    setIsShare(true);
                  }}
                  disabled={isLoading}
                >
                  Share
                </Button>
              )}

              {/* Cancel button */}
              {isEdit && flg == "2" && (
                <Button
                  className="active-btn ms-3"
                  onClick={() => {
                    getContent();
                    setIsEdit(false);
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
              )}

              {/* Save Button */}
              {isEdit && (
                <Button
                  className="active-btn ms-3"
                  onClick={() => {
                    if (flg == "1") addContent();
                    else updateContent();
                  }}
                  disabled={isLoading}
                >
                  Save
                </Button>
              )}

              {/* Download Button */}
              {!isEdit && (
                <Button
                  className="active-btn ms-3 px-2"
                  onClick={() => {
                    setIsLoading(true);
                    // printAction();
                    var tempTitle = document.title;
                    document.title = `Acknowledgement Receipt-${
                      formContent?.clientName
                    }-${moment(new Date()).format("DD.MM.YYYY")}`;

                    window.print();
                    document.title = tempTitle;
                    setIsLoading(false);
                  }}
                  disabled={isLoading}
                >
                  {"Save as PDF"}
                </Button>
              )}
            </div>
          </div>
          {/* file detail*/}
          <div className="contact-detail d-flex mt-4">
            {/* left col */}
            <div
              className={`left-col ${
                !isEdit ? " w-75" : "w-50 pe-5"
              } text-start`}
            >
              <Typography
                variant="body1"
                className="text-start w-100"
                gutterBottom
              >
                <ContactDetailField
                  label="Date"
                  isEdit={isEdit}
                  type="date"
                  wrapperStyle="justify-content-start"
                  minWidth={"150px"}
                  value={formContent?.date}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.date = e.target.value;
                    setFormContent(values);
                  }}
                />
                <ContactDetailField
                  label="Received From"
                  isEdit={isEdit}
                  type="text"
                  wrapperStyle="justify-content-start"
                  minWidth={"150px"}
                  value={formContent?.receivedFrom}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.receivedFrom = e.target.value;
                    setFormContent(values);
                  }}
                />
              </Typography>
            </div>
          </div>
          {/* /file detail */}
          {/*  Address */}
          <div className="mt-5 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex">
                <div>
                  <label
                    htmlFor="packingDate"
                    className="fw-bold"
                    style={{ width: "200px" }}
                  >
                    Address
                  </label>
                </div>
                {!isEdit && (
                  <span className="table-text ">
                    {formContent?.address ? formContent?.address : "-"}{" "}
                  </span>
                )}
                {isEdit && (
                  <Input
                    id="Address"
                    type="text"
                    label="Address"
                    style="full-border-field"
                    value={formContent?.address}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.address = e.target.value;
                      setFormContent(values);
                    }}
                    inline={{ width: "-webkit-fill-available" }}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* / Address */}
          {/*  On Account Of */}
          <div className="mt-5 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex">
                <div>
                  <label
                    htmlFor="packingDate"
                    className="fw-bold"
                    style={{ width: "200px" }}
                  >
                    On Account of
                  </label>
                </div>
                {!isEdit && (
                  <span className="table-text ">
                    {formContent?.onAccountOf ? formContent?.onAccountOf : "-"}
                  </span>
                )}
                {isEdit && (
                  <TextArea
                    id="on-account-of"
                    type="text"
                    label="On Account of"
                    style=""
                    value={formContent?.onAccountOf}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.onAccountOf = e.target.value;
                      setFormContent(values);
                    }}
                    inline={{ width: "-webkit-fill-available" }}
                    rows={5}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* /On Account Of */}
          {/*  Amount */}
          <div className="mt-5 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex">
                <div>
                  <label
                    htmlFor="packingDate"
                    className="fw-bold"
                    style={{ width: "200px" }}
                  >
                    Amount
                  </label>
                </div>
                {!isEdit && (
                  <span className="table-text ">
                    {formContent?.amount ? formContent?.amount : "-"}{" "}
                  </span>
                )}
                {isEdit && (
                  <Input
                    id="ammount"
                    type="text"
                    label="Amount"
                    style="full-border-field"
                    value={formContent?.amount}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.amount = e.target.value;
                      setFormContent(values);
                    }}
                    inline={{ width: "-webkit-fill-available" }}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* /Amount */}
          {/*  Cash/Cheque # */}
          <div className="mt-5 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex">
                <div>
                  <label
                    htmlFor="packingDate"
                    className="fw-bold"
                    style={{ width: "200px" }}
                  >
                    Cash/Cheque #
                  </label>
                </div>
                {!isEdit && (
                  <span className="table-text ">
                    {formContent?.chequeNo ? formContent?.chequeNo : "-"}
                  </span>
                )}
                {isEdit && (
                  <Input
                    id="cheque-no"
                    type="text"
                    label="Cash/Cheque #"
                    style="full-border-field"
                    value={formContent?.chequeNo}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.chequeNo = e.target.value;
                      setFormContent(values);
                    }}
                    inline={{ width: "-webkit-fill-available" }}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* /Cash/Cheque # */}
          {/*  Drawn on Bank */}
          <div className="mt-5 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex">
                <div>
                  <label
                    htmlFor="packingDate"
                    className="fw-bold"
                    style={{ width: "200px" }}
                  >
                    Drawn on Bank
                  </label>
                </div>
                {!isEdit && (
                  <span className="table-text ">
                    {formContent?.drawnOnBank ? formContent?.drawnOnBank : "-"}
                  </span>
                )}
                {isEdit && (
                  <Input
                    id="drawn-on-bank"
                    type="text"
                    label="Drawn on Bank"
                    style="full-border-field"
                    value={formContent?.drawnOnBank}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.drawnOnBank = e.target.value;
                      setFormContent(values);
                    }}
                    inline={{ width: "-webkit-fill-available" }}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* /Drawn on Bank */}
          {/*  In words */}
          <div className="mt-5 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex">
                <div>
                  <label
                    htmlFor="packingDate"
                    className="fw-bold"
                    style={{ width: "200px" }}
                  >
                    In words
                  </label>
                </div>
                {!isEdit && (
                  <span className="table-text ">
                    {formContent?.inWords ? formContent?.inWords : "-"}{" "}
                  </span>
                )}
                {isEdit && (
                  <Input
                    id="in-words"
                    type="text"
                    label="In words"
                    style="full-border-field"
                    value={formContent?.inWords}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.inWords = e.target.value;
                      setFormContent(values);
                    }}
                    inline={{ width: "-webkit-fill-available" }}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* /In words*/}
          {/*  Received By */}
          <div className="mt-5 pe-1 ">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex">
                <div>
                  <label
                    htmlFor="packingDate"
                    className="fw-bold"
                    style={{ width: "200px" }}
                  >
                    Received By
                  </label>
                </div>
                {!isEdit && (
                  <span className="table-text ">
                    {formContent?.receivedBy ? formContent?.receivedBy : "-"}
                  </span>
                )}
                {isEdit && (
                  <TextArea
                    id="truckNo"
                    type="text"
                    label="Received By"
                    style=""
                    value={formContent?.receivedBy}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.receivedBy = e.target.value;
                      setFormContent(values);
                    }}
                    inline={{ width: "-webkit-fill-available" }}
                    rows={5}
                  />
                )}
              </div>
            </Typography>
            <hr className="mb-10" />
          </div>
          {/* /Received By*/}

          {/* snackbar */}
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000} // Adjust the duration as needed
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              severity={
                snackbarMessage?.includes("successful") ? "success" : "error"
              }
              onClose={handleCloseSnackbar}
            >
              {snackbarMessage}
            </MuiAlert>
          </Snackbar>
          {/* /snackbar */}
        </div>

        <PrintComponent>
          <AcknowledgementReceiptDownload
            surveyData={surveyData}
            formContent={formContent}
          />
        </PrintComponent>

        {isShare && (
          <ShareWithClientsModal
            open={isShare}
            onClose={() => {
              setIsShare(false);
            }}
            onSuccess={(emails) => {
              shareWithClient(emails);
            }}
          />
        )}

        {isVerified && (
          <SuperAdminVerifationModal
            open={isVerified}
            onClose={() => {
              setIsVerified(false);
            }}
            onSuccess={() => {
              setIsEdit(true);
              setIsVerified(false);
            }}
            onError={() => {
              // shareWithClient(emails);
              setSnackbarMessage("Invalid Confirm Pin");
              setOpenSnackbar(true);
            }}
          />
        )}
      </MainTemplate>
    </>
  );
};
export default AcknowledgementReceiptPage;
