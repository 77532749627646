import React, { useEffect, useState } from "react";
import MainTemplate from "../../../templates/MainTemplate";
import { useNavigate } from "react-router-dom";
import { Button, Snackbar, Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import { Bucket } from "../../../../api/config/apiConfig";
import ContactDetailField from "../../../molecules/ContactDetailField";
import Table from "@mui/material/Table";
import CircularProgress from "@mui/material/CircularProgress";
import Input from "../../../atoms/Input";
import removeImg from "../../../../imgs/remove.png";
import { useParams } from "react-router-dom";

const PackingMaterialDownload = ({ surveyData, formContent }) => {
  const { surveyId, id, flg } = useParams();

  const [isEdit, setIsEdit] = useState(flg == "1" ? true : false);

  const [isLoading, setIsLoading] = useState(false);

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // 11th, 12th, 13th, etc.
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  // Helper function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    const ordinalSuffix = getOrdinalSuffix(day);

    return `${day}${ordinalSuffix} ${month}, ${year}`;
  };

  return (
    <>
      {/* <MainTemplate> */}
        <div className="mx-4">
          <div className="d-block d-sm-flex align-items-center pb-1 pt-0 ">
            <div className="d-flex align-items-center justify-content-center w-100">
              <p className="ps-0 mb-0 head-top">
                {" "}
                Packing Material Issued Sheet
              </p>
            </div>
          </div>

          {/*  table */}
          <div className="my-2 ps-0">
            <Table responsive>
              {/* Table Header */}
              <thead
                // className="bg-white"
                className="bg-body-tertiary"
              >
                <tr>
                  <th
                    className="border p-1 text-start"
                    style={{ width: "10%", fontSize: "10px" }}
                  >
                    {" "}
                    No.
                  </th>
                  <th
                    className="border p-1 text-start"
                    style={{ width: "30%", fontSize: "10px" }}
                  >
                    Item Name
                  </th>
                  <th
                    className="border p-1 text-start"
                    
                    style={{ width: "20%", fontSize: "10px" }}
                  >
                    Date Issue
                  </th>
                 
                  <th
                    className="border p-1 text-start "
                    style={{ width: "10%", fontSize: "10px" }}
                  >
                    Qty Issued
                  </th>
                  <th
                    className="border p-1 text-start "
                    style={{ width: "10%", fontSize: "10px" }}
                  >
                    Qty Used
                  </th>
                  <th
                    className="border p-1 text-start "
                    style={{ width: "10%", fontSize: "10px" }}
                  >
                    Qty Returned
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {formContent?.dataRows?.length > 0 ? (
                  <>
                    {formContent?.dataRows?.map((row, index) => {
                      return (
                        <tr>
                          <td
                            className="border p-1 text-start"
                            style={{ fontSize: "10px" }}
                          >
                            <p className="p-word mb-0">{index + 1}</p>
                          </td>
                          <td
                            className="border p-1 text-start"
                            style={{ fontSize: "10px" }}
                          >
                            {isEdit ? (
                              <Input
                                id="condition"
                                label="Description..."
                                type="text"
                                style="full-border-field"
                                value={row?.description}
                                inline={{ width: "-webkit-fill-available" }}
                              />
                            ) : (
                              <>
                                {row.description ? (
                                  <p className="p-word mb-0">
                                    {row.description}
                                  </p>
                                ) : (
                                  "-"
                                )}
                              </>
                            )}
                          </td>
                          <td
                            className="border p-1 text-start"
                            style={{ fontSize: "10px" }}
                          >
                            {isEdit ? (
                              <>
                                <Input
                                  id="condition"
                                  label="Date..."
                                  type="date"
                                  style="full-border-field"
                                  value={row?.date1}
                                  inline={{ width: "-webkit-fill-available" }}
                                />
                              </>
                            ) : row.date1 ? (
                              formatDate(row.date1)
                            ) : (
                              "-"
                            )}
                          </td>
                          {/* <td
                            className="border p-1 text-start "
                            style={{ fontSize: "10px" }}
                          >
                            {isEdit ? (
                              <>
                                <Input
                                  id="condition"
                                  label="Date..."
                                  type="date"
                                  style="full-border-field"
                                  value={row?.date2}
                                  inline={{ width: "-webkit-fill-available" }}
                                />
                              </>
                            ) : row.date2 ? (
                              formatDate(row.date2)
                            ) : (
                              "-"
                            )}
                          </td> */}
                          {/* <td
                            className="border p-1 text-start "
                            style={{ fontSize: "10px" }}
                          >
                            {isEdit ? (
                              <>
                                <Input
                                  id="condition"
                                  label="Date..."
                                  type="date"
                                  style="full-border-field"
                                  value={row?.date3}
                                  inline={{ width: "-webkit-fill-available" }}
                                />
                              </>
                            ) : row.date3 ? (
                              formatDate(row.date3)
                            ) : (
                              "-"
                            )}
                          </td> */}
                          {/* <td
                            className="border p-1 text-start "
                            style={{ fontSize: "10px" }}
                          >
                            {isEdit ? (
                              <>
                                <Input
                                  id="condition"
                                  label="Date..."
                                  type="date"
                                  style="full-border-field"
                                  value={row?.date4}
                                  inline={{ width: "-webkit-fill-available" }}
                                />
                              </>
                            ) : row.date4 ? (
                              formatDate(row.date4)
                            ) : (
                              "-"
                            )}
                          </td> */}
                          {/* <td
                            className="border p-1 text-start"
                            style={{ fontSize: "10px" }}
                          >
                            {isEdit ? (
                              <>
                                <Input
                                  id="condition"
                                  label="Date..."
                                  type="date"
                                  style="full-border-field"
                                  value={row?.date5}
                                  inline={{ width: "-webkit-fill-available" }}
                                />
                              </>
                            ) : row.date5 ? (
                              formatDate(row.date5)
                            ) : (
                              "-"
                            )}
                          </td> */}
                          {/* <td
                            className="border p-1 text-start "
                            style={{ fontSize: "10px" }}
                          >
                            {isEdit ? (
                              <>
                                <Input
                                  id="condition"
                                  label="Date..."
                                  type="date"
                                  style="full-border-field"
                                  value={row?.date6}
                                  inline={{ width: "-webkit-fill-available" }}
                                />
                              </>
                            ) : row.date6 ? (
                              formatDate(row.date6)
                            ) : (
                              "-"
                            )}
                          </td> */}
                          {/* <td
                            className="border p-1 text-start"
                            style={{ fontSize: "10px" }}
                          >
                            {isEdit ? (
                              <>
                                <Input
                                  id="condition"
                                  label="Date..."
                                  type="date"
                                  style="full-border-field"
                                  value={row?.date7}
                                  inline={{ width: "-webkit-fill-available" }}
                                />
                              </>
                            ) : row.date7 ? (
                              formatDate(row.date7)
                            ) : (
                              "-"
                            )}
                          </td> */}
                          <td
                            className="border p-1 text-start "
                            style={{ fontSize: "10px" }}
                          >
                            {isEdit ? (
                              <>
                                <Input
                                  id="condition"
                                  label="QTY Used..."
                                  type="number"
                                  className="full-border-field"
                                  style="full-border-field"
                                  value={row?.qtyIssued}
                                  inline={{ width: "-webkit-fill-available" }}
                                />
                              </>
                            ) : (
                              <>
                                {row.qtyIssued ? (
                                  <p className="p-word mb-0">{row.qtyIssued}</p>
                                ) : (
                                  "-"
                                )}
                              </>
                            )}
                          </td>
                          <td
                            className="border p-1 text-start "
                            style={{ fontSize: "10px" }}
                          >
                            {isEdit ? (
                              <>
                                <Input
                                  id="condition"
                                  label="QTY Used..."
                                  type="number"
                                  className="full-border-field"
                                  style="full-border-field"
                                  value={Number(row.qtyIssued)-Number(row?.qtyReturned)}
                                  inline={{ width: "-webkit-fill-available" }}
                                />
                              </>
                            ) : (
                              <>
                                {(row.qtyIssued || row?.qtyReturned) ? (
                                  <p className="p-word mb-0">{Number(row.qtyIssued)-Number(row?.qtyReturned)}</p>
                                ) : (
                                  "-"
                                )}
                              </>
                            )}
                          </td>
                         
                          <td
                            className="border p-1 text-start"
                            style={{ fontSize: "10px" }}
                          >
                            {isEdit ? (
                              <>
                                <Input
                                  id="condition"
                                  label="QTY Returned..."
                                  type="number"
                                  className="full-border-field"
                                  style="full-border-field"
                                  value={row?.qtyReturned}
                                  inline={{ width: "-webkit-fill-available" }}
                                />
                              </>
                            ) : (
                              <>
                                {row.qtyReturned ? (
                                  <p className="p-word mb-0">
                                    {row.qtyReturned}
                                  </p>
                                ) : (
                                  "-"
                                )}
                              </>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <tr>
                    <td className="text-center" colSpan={isEdit ? 12 : 11}>
                      {isLoading ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "10px 0px",
                          }}
                        >
                          <CircularProgress
                            style={{
                              color: "#00aff0",
                              height: "25px",
                              width: "25px",
                            }}
                          />
                        </Box>
                      ) : (
                        <div className="d-flex justify-content-center">
                          <p className="my-3">No Records to Show</p>
                        </div>
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>

          {/* Details */}
          <div className="mt-3 pe-1 row">
            <Typography
              variant="body1"
              className="text-start col-12 col-md-8"
              gutterBottom
            >
              <div className="mb-2">
                <Typography variant="body1" gutterBottom>
                  <div className="d-flex">
                    <p className="p-word mb-1 text-start" minWidth={"170px"}>
                      <b>Customer Name/Organization:</b>
                    </p>

                    <p className="p-word mb-1 text-start">
                      <span className="ms-1 ">
                        {" "}
                        {formContent?.customerName
                          ? formContent?.customerName
                          : "-"}
                      </span>
                    </p>
                  </div>
                  {/* 
                  <ContactDetailField
                    label="Customer Name/Organization"
                    isEdit={isEdit}
                    type="text"
                    style="fw-normal"
                    value={formContent?.customerName}
                    minWidth={"340px"}
                  /> */}

                  <div className="d-flex">
                    <p className="p-word mb-1 text-start" minWidth={"170px"}>
                      <b> Packing/Shifting/Storage/Others -Date wise:</b>
                    </p>

                    <p className="p-word mb-1 text-start">
                      <span className="ms-1 ">
                        {" "}
                        {formContent?.packingShifting
                          ? formContent?.packingShifting
                          : "-"}
                      </span>
                    </p>
                  </div>
                  {/* <ContactDetailField
                    label=" Packing/Shifting/Storage/Others -Date wise"
                    isEdit={isEdit}
                    type="text"
                    style="fw-normal"
                    value={formContent?.packingShifting}
                    minWidth={"340px"}
                  /> */}

                  <div className="d-flex">
                    <p className="p-word mb-1 text-start" minWidth={"170px"}>
                      <b> Vehicles used for the move:</b>
                    </p>

                    <p className="p-word mb-1 text-start">
                      <span className="ms-1 ">
                        {" "}
                        {formContent?.vehicles ? formContent?.vehicles : "-"}
                      </span>
                    </p>
                  </div>
                  {/* <ContactDetailField
                    label="Vehicles used for the move"
                    isEdit={isEdit}
                    type="text"
                    style="fw-normal"
                    value={formContent?.vehicles}
                    minWidth={"340px"}
                  /> */}
                  <div className="d-flex">
                    <p className="p-word mb-1 text-start" minWidth={"170px"}>
                      <b> Packers used for the move:</b>
                    </p>

                    <p className="p-word mb-1 text-start">
                      <span className="ms-1 ">
                        {" "}
                        {formContent?.packers ? formContent?.packers : "-"}
                      </span>
                    </p>
                  </div>
                  {/* <ContactDetailField
                    label="Packers used for the move"
                    isEdit={isEdit}
                    type="text"
                    style="fw-normal"
                    value={formContent?.packers}
                    minWidth={"340px"}
                  /> */}
                </Typography>
              </div>
            </Typography>
          </div>
          {/* / Details */}

          {/* Signatures */}
          {/* {!isEdit && (
            <Row className="pb-5">
              <Col sm={6} className="pt-5">
                <div style={{ height: "90px" }}></div>
                <p>{"_______________________________"}</p>
                <p className="my-1">
                  <b>{"Packing Supervisor"}</b>
                </p>
                <p className="mt-1">{"(Sign Required)"}</p>
                {""}
              </Col>
              <Col sm={6} className="pt-5">
                <div style={{ height: "90px" }}></div>
                <p>{"_______________________________"}</p>
                <p className="my-1">
                  <b>{"W/H Supervisor"}</b>
                </p>
                <p className="mt-1">{"(Sign Required)"}</p>
                {""}
              </Col>
            </Row>
          )} */}
        </div>
      {/* </MainTemplate> */}
    </>
  );
};
export default PackingMaterialDownload;
