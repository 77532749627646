import React, { useEffect, useState } from "react";
import {
  getFormContent,
  getSingleSurvey,
  updateFormContent,
  updateStatus,
  surveyResultPreview,
} from "../../../../api/services/services";
import { Button, Snackbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
// import { Bucket } from "../../../api/config/apiConfig";
import "./../style.css";
import Input from "../../../atoms/Input";
import TextArea from "../../../atoms/Textarea";
import ContactDetailField from "../../../molecules/ContactDetailField";
import { useParams } from "react-router-dom";
import { addFormContent } from "../../../../api/services/services";
import headerImg from "../../../../imgs/pdf-header.png";
import footerImg from "../../../../imgs/pdf-footer.png";
import { Bucket } from "../../../../api/config/apiConfig";
import headerLeft from "../../../../imgs/logo-upd.png";
import headerRight1 from "../../../../imgs/header-right-01.png";
import headerRight2 from "../../../../imgs/header-right-02.png";
import headerRight3 from "../../../../imgs/header-right-03.png";
import footer from "../../../../imgs/footer.png";
import callIcon from "../../../../imgs/call.svg";
import calenderIcon from "../../../../imgs/calendar.svg";
import calenderMove from "../../../../imgs/calendarMinus.svg";
import emailIcon from "../../../../imgs/email.svg";
import location from "../../../../imgs/location.svg";
import Table from "@mui/material/Table";

const QuotationFormDownload = ({ surveyData, formContent }) => {
  const { surveyId, id, flg } = useParams();

  const [formContentId, setFormContentId] = useState("");
  const [isEdit, setIsEdit] = useState(flg == "1" ? true : false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    // Function to calculate and adjust the padding of the main content for printing
    // const adjustMainContentPaddingForPrinting = () => {
    //   const headerHeight = document.querySelector('header').offsetHeight;
    //   const footerHeight = document.querySelector('footer').offsetHeight;
    //   const mainContent = document.querySelector('main');
    //   if (mainContent) {
    //     mainContent.style.paddingTop = headerHeight + 'px';
    //     mainContent.style.paddingBottom = footerHeight + 'px';
    //   }
    // };
    // // Call the adjustment function when the component mounts and on window resize
    // adjustMainContentPaddingForPrinting();
    // window.addEventListener('resize', adjustMainContentPaddingForPrinting);
    // Cleanup function to remove the resize event listener
    // return () => {
    //   window.removeEventListener('resize', adjustMainContentPaddingForPrinting);
    // };
  }, [formContent]);

  let movableItems = 0;
  let nonmovableItems = 0;

  const convertDate = (unixTimestamp) => {
    const date = new Date(unixTimestamp * 1000); // Multiply by 1000 to convert from seconds to milliseconds
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1 and format with leading zero
    const dd = String(date.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // 11th, 12th, 13th, etc.
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  // Helper function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const ordinalSuffix = getOrdinalSuffix(day);

    return `${day}${ordinalSuffix} ${month}, ${year}`;
  };

  return (
    <>
      <div className="mx-4">
        <div className="d-block d-sm-flex align-items-center pb-1 pt-0 ">
          <div className="d-flex align-items-center justify-content-center w-100">
            <p className="ps-0 mb-0 head-top"> QUOTATION</p>
          </div>
        </div>
        {/* contact detail*/}
        <div className="download contact-detail d-flex mt-0  mb-2">
          {/* left col */}
          <div
            className={`left-col ${
              !isEdit ? " w-50" : "w-50 pe-5"
            } text-start quote`}
          >
            <Typography variant="body1" gutterBottom>
              {/* <ContactDetailField
                label="To"
                isEdit={isEdit}
                type="text"
                value={formContent?.to}
                minWidth={"75px"}
              /> */}
              <p className="p-word mb-0 text-start">
                {/* <b> To:</b>{" "} */}
                <span className="ms-0">
                  {" "}
                  {formContent?.to ? formContent?.to : "-"}
                </span>
              </p>
              {/* <ContactDetailField
                label="Location"
                isEdit={isEdit}
                type="text"
                value={formContent?.location}
                minWidth={"75px"}
              /> */}
              <p className="p-word mb-0 text-start">
                {/* <b> Location:</b>{" "} */}
                <span className="ms-0">
                  {" "}
                  {formContent?.location ? formContent?.location : "-"}
                </span>
              </p>
              {/* <ContactDetailField
                label="Email"
                isEdit={isEdit}
                type="email"
                value={formContent?.email}
                minWidth={"75px"}
              /> */}
              <p className="p-word mb-0 text-start">
                Email:
                <span className="ms-1 text-decoration-underline">
                  {" "}
                  {formContent?.email ? formContent?.email : "-"}
                </span>
              </p>
            </Typography>
          </div>
          {/* right col */}
          <div
            className={`right-col ${
              !isEdit ? "w-50" : "w-50 ps-5"
            }  d-flex flex-column align-items-end pe-2`}
          >
            <div className="">
              <Typography
                variant="body1"
                className="text-start w-100"
                gutterBottom
              >
                {/* <ContactDetailField
                  label="Date"
                  isEdit={isEdit}
                  type="date"
                  wrapperStyle="justify-content-start"
                  value={formContent?.date}
                  minWidth={"75px"}
                /> */}

                <p className="p-word mb-0 text-start">
                  Date:
                  <span className="ms-1">
                    {" "}
                    {formContent?.date ? formatDate(formContent?.date) : "-"}
                  </span>
                </p>

                {/* <ContactDetailField
                  label="Invoice #"
                  isEdit={isEdit}
                  type="number"
                  wrapperStyle="justify-content-start"
                  value={formContent?.invoiceNo}
                  disabled={true}
                  minWidth={"75px"}
                /> */}

                <p className="p-word mb-0 text-start">
                  Validity:
                  <span className="ms-1">
                    {" "}
                    {formContent?.quoteValidity
                      ? formContent?.quoteValidity
                      : "-"}
                  </span>
                </p>
                {/* <ContactDetailField
                  label="Tel"
                  isEdit={isEdit}
                  type="number"
                  wrapperStyle="justify-content-start"
                  value={formContent?.phoneNo}
                  minWidth={"75px"}
                /> */}
                {/* <p className="p-word mb-0 text-start">
                  Email:
                  <span className="ms-1 text-decoration-underline">
                    {"Gohar.rehman@shelozon.com"}
                  </span>
                </p> */}
              </Typography>
            </div>
          </div>
        </div>
        {/* /contact detail */}

        {/* info */}
        <div className="mt-3 pe-1  ">
          <Typography variant="body1" className="text-start" gutterBottom>
            <div className="mb-2 download-standard">
              <label
                htmlFor="to"
                className={`fw-bold ${isEdit ? "mb-3" : "mb-1"}`}
                style={{ fontSize: "11px" }}
              >
                Dear {formContent?.recipient},
              </label>
              {!isEdit ? (
                <p style={{ fontSize: "11px" }}>
                  {formContent?.details ? formContent?.details : "-"}
                </p>
              ) : (
                <TextArea
                  rows="3"
                  label="Details here..."
                  id="description"
                  value={formContent?.details}
                />
              )}
            </div>
          </Typography>
        </div>
        {/* /info */}

        <div className="mt-2 ps-0">
          <Table responsive>
            {/* Table Header */}
            <tbody className="bg-white">
              {formContent?.description?.trim()?.length != 0 && (
                <tr>
                  <td
                    className="border px-2 py-1  text-start w-25"
                    style={{ fontSize: "12px" }}
                  >
                    <p className="p-word mb-0 ">Description</p>
                  </td>
                  <td
                    className="border px-2 py-1  text-start w-50"
                    style={{ fontSize: "12px" }}
                  >
                    <p className="p-word mb-0 ">
                      {" "}
                      {formContent?.description
                        ? formContent?.description
                        : "-"}
                    </p>
                  </td>
                </tr>
              )}
              {formContent?.serviceRequired?.trim()?.length != 0 && (
                <tr>
                  <td
                    className="border px-2 py-1  text-start w-25"
                    style={{ fontSize: "12px" }}
                  >
                    <p className="p-word mb-0 ">Service Required</p>
                  </td>
                  <td
                    className="border px-2 py-1  text-start w-50"
                    style={{ fontSize: "12px" }}
                  >
                    <p className="p-word mb-0 ">
                      {" "}
                      {formContent?.serviceRequired
                        ? formContent?.serviceRequired
                        : "-"}
                    </p>
                  </td>
                </tr>
              )}
              {formContent?.packingDate?.trim()?.length != 0 && (
                <tr>
                  <td
                    className="border px-2 py-1  text-start w-25"
                    style={{ fontSize: "12px" }}
                  >
                    <p className="p-word mb-0 ">
                      {" "}
                      Tentative/Requested Packing Date
                    </p>
                  </td>
                  <td
                    className="border px-2 py-1  text-start w-50"
                    style={{ fontSize: "12px" }}
                  >
                    <p className="p-word mb-0 ">
                      {formContent?.packingDate
                        ? formatDate(formContent?.packingDate)
                        : "-"}
                    </p>
                  </td>
                </tr>
              )}
              {formContent?.originAddress?.trim()?.length != 0 && (
                <tr>
                  <td
                    className="border px-2 py-1  text-start w-25"
                    style={{ fontSize: "12px" }}
                  >
                    <p className="p-word mb-0 "> Origin Address</p>
                  </td>
                  <td
                    className="border px-2 py-1  text-start w-50"
                    style={{ fontSize: "12px" }}
                  >
                    <p className="p-word mb-0 ">
                      {formContent?.originAddress
                        ? formContent?.originAddress
                        : "-"}
                    </p>
                  </td>
                </tr>
              )}
              {formContent?.destinationAddress?.trim()?.length != 0 && (
                <tr>
                  <td
                    className="border px-2 py-1  text-start w-25"
                    style={{ fontSize: "12px" }}
                  >
                    <p className="p-word mb-0 "> Destination Addresss</p>
                  </td>
                  <td
                    className="border px-2 py-1  text-start w-25"
                    style={{ fontSize: "12px" }}
                  >
                    <p className="p-word mb-0 ">
                      {formContent?.destinationAddress
                        ? formContent?.destinationAddress
                        : "-"}
                    </p>
                  </td>
                </tr>
              )}
              {formContent?.estimatedWeight != "" && (
                <tr>
                  <td
                    className="border px-2 py-1  text-start w-25"
                    style={{ fontSize: "12px" }}
                  >
                    <p className="p-word mb-0 "> Estimated Weight</p>
                  </td>
                  <td
                    className="border px-2 py-1  text-start w-50"
                    style={{ fontSize: "12px" }}
                  >
                    <p className="p-word mb-0 ">
                      {formContent?.estimatedWeight
                        ? `${formContent?.estimatedWeight}`
                        : "-"}
                    </p>
                  </td>
                </tr>
              )}
              {formContent?.estimatedVolume != "" && (
                <tr>
                  <td
                    className="border px-2 py-1  text-start w-25"
                    style={{ fontSize: "12px" }}
                  >
                    <p className="p-word mb-0 "> Estimated Volume</p>
                  </td>
                  <td
                    className="border px-2 py-1  text-start w-50"
                    style={{ fontSize: "12px" }}
                  >
                    <p className="p-word mb-0 ">
                      {formContent?.estimatedVolume ? (
                        <>
                          {Number(formContent?.estimatedVolume)?.toFixed(2)} m{" "}
                          <sup>3</sup>{" "}
                        </>
                      ) : (
                        "-"
                      )}
                    </p>
                  </td>
                </tr>
              )}
              {formContent?.transportationMode?.trim()?.length != 0 && (
                <tr>
                  <td
                    className="border px-2 py-1  text-start w-25"
                    style={{ fontSize: "12px" }}
                  >
                    <p className="p-word mb-0 "> Transportation Mode</p>
                  </td>
                  <td
                    className="border px-2 py-1  text-start w-50"
                    style={{ fontSize: "12px" }}
                  >
                    <p className="p-word mb-0 ">
                      {formContent?.transportationMode
                        ? formContent?.transportationMode
                        : "-"}
                    </p>
                  </td>
                </tr>
              )}
              {formContent?.charges?.trim()?.length != 0 && (
                <tr>
                  <td
                    className="border px-2 py-1  text-start w-25"
                    style={{ fontSize: "12px" }}
                  >
                    <p className="p-word mb-0 ">
                      {" "}
                      Complete Charges
                    </p>
                  </td>
                  <td
                    className="border px-2 py-1  text-start w-50"
                    style={{ fontSize: "12px" }}
                  >
                    <p className="p-word mb-0 ">
                      {formContent?.charges ? formContent?.charges : "-"}
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        
        {formContent?.priceIncludeAtOrigin?.trim()?.length != 0 && (
          <div className="row ">
            <div className="col-12">
              <p className="p-word mb-0 text-start">
                <b> Price Include at Origin:</b>{" "}
                <span className="ms-1">
                  {" "}
                  {formContent?.priceIncludeAtOrigin
                    ? formContent?.priceIncludeAtOrigin
                    : "-"}
                </span>
              </p>
            </div>
          </div>
        )}
        {formContent?.standardExclusions?.trim()?.length != 0 && (
          <div className="row ">
            <div className="col-12">
              <p className="p-word mb-0 text-start">
                <b> Standard Exclusions:</b>{" "}
                <span className="ms-1">
                  {" "}
                  {formContent?.standardExclusions
                    ? formContent?.standardExclusions
                    : "-"}
                </span>
              </p>
            </div>
          </div>
        )}
        {formContent?.insurance?.trim()?.length != 0 && (
          <div className="row ">
            <div className="col-12">
              <p className="p-word mb-0 text-start">
                <b> Insurance:</b>{" "}
                <span className="ms-1">
                  {" "}
                  {formContent?.insurance ? formContent?.insurance : "-"}
                </span>
              </p>
            </div>
          </div>
        )}
        {formContent?.documentsRequired?.trim()?.length != 0 && (
          <div className="row ">
            <div className="col-12">
              <p className="p-word mb-0 text-start">
                <b> Documents Required:</b>{" "}
                <span className="ms-1">
                  {" "}
                  {formContent?.documentsRequired
                    ? formContent?.documentsRequired
                    : "-"}
                </span>
              </p>
            </div>
          </div>
        )}
        {formContent?.paymentTerms?.trim()?.length != 0 && (
          <div className="row ">
            <div className="col-12">
              <p className="p-word mb-0 text-start">
                <b> Payment Terms:</b>{" "}
                <span className="ms-1">
                  {" "}
                  {formContent?.paymentTerms ? formContent?.paymentTerms : "-"}
                </span>
              </p>
            </div>
          </div>
        )}

        <div className="row mt-2">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b> Bank Details:</b>{" "}
            </p>
          </div>
        </div>

        {formContent?.bankDetails?.title?.trim()?.length != 0 && (
          <div className="row">
            <div className="col-12">
              <p className="p-word mb-0 text-start">
                <b> Account Title:</b>{" "}
                <span className="ms-1">
                  {" "}
                  {formContent?.bankDetails?.title
                    ? formContent?.bankDetails?.title
                    : "-"}
                </span>
              </p>
            </div>
          </div>
        )}

        {formContent?.bankDetails?.iban?.trim()?.length != 0 && (
          <div className="row">
            <div className="col-12">
              <p className="p-word mb-0 text-start">
                <b>IBAN:</b>{" "}
                <span className="ms-1">
                  {" "}
                  {formContent?.bankDetails?.iban
                    ? formContent?.bankDetails?.iban
                    : "-"}
                </span>
              </p>
            </div>
          </div>
        )}

        {formContent?.bankDetails?.swiftCode?.trim()?.length != 0 && (
          <div className="row">
            <div className="col-12">
              <p className="p-word mb-0 text-start">
                <b>Swift Code:</b>{" "}
                <span className="ms-1">
                  {" "}
                  {formContent?.bankDetails?.swiftCode
                    ? formContent?.bankDetails?.swiftCode
                    : "-"}
                </span>
              </p>
            </div>
          </div>
        )}

        {formContent?.bankDetails?.branchNameCode?.trim()?.length != 0 && (
          <div className="row">
            <div className="col-12">
              <p className="p-word mb-0 text-start">
                <b>Swift Code:</b>{" "}
                <span className="ms-1">
                  {" "}
                  {formContent?.bankDetails?.branchNameCode
                    ? formContent?.bankDetails?.branchNameCode
                    : "-"}
                </span>
              </p>
            </div>
          </div>
        )}

        {/* Signatures */}
        {!isEdit && (
          <Row className="mt-1">
            <Col sm={6} className="">
              <div
                style={{ height: "90px" }}
                className="d-flex justify-content-center align-items-end"
              >
                {formContent.clientSign != "" &&
                  formContent.clientSign != undefined && (
                    <img
                      src={`${Bucket}${formContent.clientSign}`}
                      height={80}
                      width={200}
                    />
                  )}
              </div>

              <p className="mb-0">{"_______________________________"}</p>
              <p className="my-0">
                <b>{"QUOTATION Acceptance"}</b>
              </p>

              {""}
            </Col>

            <Col sm={6} className="">
              <div
                style={{ height: "90px" }}
                className="d-flex justify-content-center align-items-end"
              >
                {formContent?.adminSign?.trim()?.length == 0 ? (
                  <div className="d-flex justify-content-center align-items-end"></div>
                ) : (
                  <img
                    src={`${Bucket}${formContent.adminSign}`}
                    height={80}
                    width={200}
                  />
                )}
              </div>
              <p className="mb-0">{"_______________________________"}</p>
              <p className="my-0">
                <b>{"Shelozon Movers Packers & Logistics Pvt Ltd"}</b>
              </p>

              {""}
            </Col>
          </Row>
        )}
        {/* <div className="page-break"></div> */}
        {/* 
        {(surveyData?.status == "submitted" && surveyData != null) && (
          <Row
            className="mx-0 px-2 py-3 preview-card mb-1"
            style={{ marginTop: "-20px" }}
          >
            <Row>
              <Col md={12} className="text-start text-capitalize">
                <h5 className="fn">
                  <b>{surveyData?.name}'s Move</b>
                </h5>
              </Col>
            </Row>
            <div className="row">
              <div className="col-4">
                <div className="d-flex align-items-start mb-2">
                  <div className="white-circle mt-1">
                    <img src={emailIcon} className="img-pr" />
                  </div>
                  <div className="ps-2">
                    <p className="surve-name mb-0">
                      <b>Email</b>
                    </p>
                    <p className="surve-email mb-0 p-word">
                      {surveyData?.email}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-4">
                <div className="d-flex align-items-start mb-2">
                  <div className="white-circle mt-1">
                    <img src={calenderIcon} className="img-pr" />
                  </div>
                  <div className="ms-2">
                    <p className="surve-name mb-0">Created Date</p>
                    <p className="surve-email mb-0 p-word">
                      {surveyData?.createdAt
                        ? convertDate(surveyData?.createdAt)
                        : "-"}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-4">
                <div className="d-flex align-items-start mb-2">
                  <div className="white-circle mt-1">
                    <img src={calenderMove} className="img-pr" />
                  </div>
                  <div className="ms-2">
                    <p className="surve-name mb-0">Move Date</p>
                    <p className="surve-email mb-0 p-word">
                      {surveyData?.loadDate
                        ? convertDate(surveyData?.loadDate)
                        : "-"}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-4">
              <div className="d-flex align-items-start mb-2">
                <div className="white-circle mt-1">
                  <img
                    src={location}
                    height={24}
                    width={24}
                    className="img-pr"
                  />
                </div>
                <div className="ms-2">
                  <p className="surve-name mb-0">Location</p>
                  <p className="surve-email mb-0 p-word">
                    {surveyData?.loadPlaceStAddress}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="d-flex align-items-start mb-2">
                <div className="white-circle mt-1">
                  <img src={callIcon} className="img-pr" />
                </div>
                <div className="ms-2">
                  <p className="surve-name mb-0">Phone No.</p>
                  <p className="surve-email mb-0 p-word">
                    {surveyData?.loadPlacePhone}
                  </p>
                </div>
              </div>
            </div>
          </Row>
        )} */}

        {/* {surveyData?.status == "submitted" && surveyData != null && ( */}
        {/* <> */}
        {/* {surveyData?.surveyResult?.surveyRooms?.length > 0 && (
              <Row className="mx-1 my-1">
                <Col sm={12} md={12} className="text-start px-0 ">
                  <h5>
                    <b>Rooms Detail</b>
                  </h5>
                </Col>
              </Row>
            {/* )} */}
        {/* </> */}
        {/* )} */}

        {/* {surveyData?.surveyResult?.surveyRooms?.length > 0 &&
          surveyData?.surveyResult?.surveyRooms?.map((sr, ind) => {
            return (
              <>
                <Row className="bg-white mb-4 mx-0  p-3 py-4 mb-0 px-1">
                  <Row className="">
                    <Col
                      sm={12}
                      md={12}
                      className="text-start px-0 left-col mb-3"
                    >
                      <h6 className="fn text-capitalize ">
                        <b>
                          {ind + 1}
                          {". "}
                          {sr?.name}
                        </b>
                      </h6>
                    </Col>
                  </Row>
                  <Row>
                    {/* Images */}
        {/* <Col sm={6} md={6} className="px-2">
                      <div className="d-flex flex-wrap">
                        {sr?.images?.map((image) => {
                          return (
                            <>
                              <div>
                                <img
                                  src={Bucket + image}
                                  height={180}
                                  width={140}
                                  // style={{ borderRadius: "10px" }}
                                  className="me-1 mb-2"
                                />
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </Col> */}

        {/* Details */}
        {/* <Col sm={6} md={6} className="px-4 ">
                      <Row>
                        {/* Moving */}
        {/* <Col
                          sm={12}
                          md={12}
                          className="px-0 bg-white card-border"
                        > */}
        {/* <div className="bg-white card-border"> */}
        {/* <Row
                            className="px-3 mx-0"
                            style={{
                              backgroundColor: "#00aff0 ",
                              color: "white",
                            }}
                          >
                            <Col
                              sm={12}
                              md={12}
                              className="text-start px-0 left-col py-2"
                            >
                              <p className="surve-email mb-0 p-word text-capitalize">
                                <b>Moving</b>
                              </p>
                            </Col>
                          </Row> */}

        {/* {sr?.roomItems?.map((item) => {
                            if (item?.type == "movable")
                              movableItems = movableItems + 1;
                            if (item?.type == "nonmovable")
                              nonmovableItems = nonmovableItems + 1;

                            return <></>;
                          })} */}

        {/* {movableItems > 0 ? (
                            <>
                              {sr?.roomItems?.map((item) => {
                                return (
                                  <>
                                    {item?.type == "movable" && (
                                      <Row className="detail-row mx-3 detail-row-mg">
                                        <Col
                                          xs={7}
                                          sm={6}
                                          md={7}
                                          className="text-start px-0 left-col card-item"
                                        >
                                          <p className="surve-email mb-0 p-word">
                                            {item?.name ? item?.name : "-"}
                                          </p>
                                        </Col>
                                        <Col
                                          xs={2}
                                          sm={2}
                                          md={2}
                                          className="text-end px-0 left-col card-item"
                                          style={{ fontWeight: "500" }}
                                        >
                                          <p className="surve-email mb-0 p-word">
                                            {item?.quantity
                                              ? `${item?.quantity}x`
                                              : "-"}
                                          </p>
                                        </Col>
                                        <Col
                                          xs={3}
                                          sm={4}
                                          md={3}
                                          className="text-end px-0 right-col card-item"
                                        >
                                          <p className="surve-email mb-0 p-word">
                                            {item?.cbm ? item?.cbm : "-"} cm
                                            <sup>3</sup>
                                          </p>
                                        </Col>
                                      </Row>
                                    )}
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <Row className="detail-row mx-3 text-center">
                              <p className="surve-email mb-0 p-word">
                                {" "}
                                <i>No Items</i>
                              </p>
                            </Row> */}
        {/* )} */}

        {/* </div> */}
        {/* </Col> */}

        {/* Not Moving */}
        {/* <Col
                          sm={12}
                          md={12}
                          className="px-0 bg-white card-border mt-4"
                        > */}
        {/* <div className="bg-white card-border"> */}
        {/* <Row
                            className="px-3 mx-0"
                            style={{
                              backgroundColor: "#00aff0 ",
                              color: "white",
                            }}
                          >
                            <Col
                              sm={12}
                              md={12}
                              className="text-start px-0 left-col py-2"
                            >
                              <p className="surve-email mb-0 p-word text-capitalize">
                                <b>Not Moving</b>
                              </p>
                            </Col>
                          </Row> */}
        {/* {nonmovableItems > 0 ? (
                            <>
                              {sr?.roomItems?.map((item) => {
                                return (
                                  <>
                                    {item?.type == "nonmovable" && (
                                      <Row className="detail-row mx-3 detail-row-mg">
                                        <Col
                                          xs={7}
                                          sm={6}
                                          md={7}
                                          className="text-start px-0 left-col card-item"
                                        >
                                          <p className="surve-email mb-0 p-word">
                                            {item?.name ? item?.name : "-"}
                                          </p>
                                        </Col>
                                        <Col
                                          xs={2}
                                          sm={2}
                                          md={2}
                                          className="text-end px-0 left-col card-item"
                                          style={{ fontWeight: "500" }}
                                        >
                                          {" "}
                                          <p className="surve-email mb-0 p-word">
                                            {item?.quantity
                                              ? `${item?.quantity}x`
                                              : "-"}
                                          </p>
                                        </Col>
                                        <Col
                                          xs={3}
                                          sm={4}
                                          md={3}
                                          className="text-end px-0 right-col card-item"
                                        >
                                          <p className="surve-email mb-0 p-word">
                                            {item?.cbm ? item?.cbm : "-"} cm
                                            <sup>3</sup>
                                          </p>
                                        </Col>
                                      </Row>
                                    )}
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <Row className="detail-row mx-3 text-center">
                              <p className="surve-email mb-0 p-word">
                                {" "}
                                <i>No Items</i>
                              </p>
                            </Row>
                          )} */}

        {/* </div> */}
        {/* </Col> */}

        {/* Packing Material */}
        {/* <Col
                          sm={12}
                          md={12}
                          className="px-0 bg-white card-border mt-4"
                        > */}
        {/* <div className="bg-white card-border"> */}
        {/* <Row
                            className="px-3 mx-0"
                            style={{
                              backgroundColor: "#00aff0 ",
                              color: "white",
                            }}
                          >
                            <Col
                              sm={12}
                              md={12}
                              className="text-start px-0 left-col py-2"
                            >
                              <p className="surve-email mb-0 p-word text-capitalize">
                                {" "}
                                <b>Packing Material</b>
                              </p>
                            </Col>
                          </Row> */}

        {/* {sr?.packingMaterial?.map((pack) => {
                            return (
                              <>
                                <Row className="detail-row mx-3 detail-row-mg">
                                  <Col
                                    xs={7}
                                    sm={6}
                                    md={7}
                                    className="text-start px-0 left-col card-item"
                                  >
                                    <p className="surve-email mb-0 p-word">
                                      {pack?.name ? pack?.name : "-"}
                                    </p>
                                  </Col>
                                  <Col
                                    xs={2}
                                    sm={2}
                                    md={2}
                                    className="text-end px-0 left-col  card-item"
                                    style={{ fontWeight: "500" }}
                                  >
                                    <p className="surve-email mb-0 p-word">
                                      {pack?.quantity
                                        ? `${pack?.quantity}x`
                                        : "-"}
                                    </p>
                                  </Col>
                                  <Col
                                    xs={3}
                                    sm={4}
                                    md={3}
                                    className="text-end px-0 right-col card-item"
                                  >
                                    <p className="surve-email mb-0 p-word">
                                      {" "}
                                      {pack?.cbm ? pack?.cbm : "-"} cm
                                      <sup>3</sup>
                                    </p>
                                  </Col>
                                </Row>
                              </>
                            );
                          })} */}

        {/* </div> */}
        {/* </Col> */}
        {/* </Row> */}
        {/* </Col> */}
        {/* </Row> */}
        {/* </Row> */}
        {/* <div className="page-break"></div> */}
        {/* </> */}
        {/* ); */}
        {/* })} */}
      </div>
    </>
  );
};
export default QuotationFormDownload;
