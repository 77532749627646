import React, { useEffect, useState } from "react";
import {
  updateFormContentPreview,
  getFormContentPreview,
  imageUploadClientPreview,
} from "../../../../api/services/services";
import { Button, Snackbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import { Bucket } from "../../../../api/config/apiConfig";
import Input from "../../../atoms/Input";
import TextArea from "../../../atoms/Textarea";
import ContactDetailField from "../../../molecules/ContactDetailField";
import { useLocation } from "react-router-dom";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
// import QuotationFormDownload from "./download";
import html2pdf from "html2pdf.js";
import DynamicCheckbox from "../../../molecules/DynamicCheckbox";
import DynamicText from "../../../molecules/DynamicText";

const PackingRequestPageDownload = ({ formContent, surveyData }) => {
  const [isEdit, setIsEdit] = useState(false);

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // 11th, 12th, 13th, etc.
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  // Helper function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const ordinalSuffix = getOrdinalSuffix(day);

    return `${day}${ordinalSuffix} ${month}, ${year}`;
  };

  return (
    <>
      <div className="mx-4">
        {/* <MainTemplate> */}

        <div className="d-block d-sm-flex align-items-center pb-1 pt-0 ">
          <div className="d-flex align-items-center justify-content-center w-100">
            <p className="ps-0 mb-0 head-top"> Packing Request </p>
          </div>
        </div>

        {/* customer detail*/}
        <div className="contact-detail d-flex mt-2 dynamic-div">
          {/* left col */}
          <div className={`left-col w-100 text-start`}>
            <Typography variant="body1" gutterBottom>
              {/* <ContactDetailField
                label="Customer Name"
                isEdit={isEdit}
                type="text"
                minWidth={"120px"}
                value={formContent?.customerName}
              /> */}
              <p className="p-word mb-1 text-start">
                <b>Customer Name</b>
                <span className="ms-1">
                  {" "}
                  {formContent?.customerName ? formContent?.customerName : "-"}
                </span>
              </p>
              {/* <ContactDetailField
                label="Date"
                isEdit={isEdit}
                type="date"
                minWidth={"120px"}
                value={formContent?.date}
              /> */}

              <p className="p-word mb-0 text-start">
                <b>Date</b>
                <span className="ms-1">
                  {" "}
                  {formContent?.date ? formContent?.date : "-"}
                </span>
              </p>
            </Typography>
          </div>
        </div>
        {/* /customer detail */}
        {/* Packing Address */}
        <div className="mt-3 pe-1 dynamic-div">
          <Typography variant="body1" className="text-start" gutterBottom>
            <p className="p-word mb-1 text-start">
              <b>Packing Address</b>
            </p>
            <p className="p-word mb-0 text-start">
              <span className="ms-0">
                {" "}
                {formContent?.packingAddress
                  ? formContent?.packingAddress
                  : "-"}
              </span>
            </p>
            {/* <DynamicText
              label="Packing Address"
              textAreaLabel="Packing Address"
              isEdit={isEdit}
              value={formContent?.packingAddress}
            /> */}
          </Typography>
        </div>
        {/* /Packing Address */}
        {/* dates */}

        <div className="mt-2 pe-1 pt-1">
          <p className="p-word mb-1 text-start">
            <b>
              {" "}
              Which dates and time suits you to start packing of your goods?
            </b>
          </p>
          <p className="p-word mb-0 text-start">
            <span className="ms-0">
              {" "}
              {formContent?.packingAddress ? (
                <>
                  {formatDate(formContent?.packingDate)} {" - "}{" "}
                  {formContent?.packingTime}
                </>
              ) : (
                "-"
              )}
            </span>
          </p>
        </div>
        {/* <div className="mt-0 pe-1 d-flex dynamic-div">
          {isEdit ? (
            <>
              <div
                className={`left-col ${!isEdit ? " w-50" : "w-50"} text-start`}
              >
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{ width: "80%" }}
                >
                  <ContactDetailField
                    label="Packing Date"
                    isEdit={isEdit}
                    type="date"
                    value={formContent?.packingDate}
                    minWidth={"125px"}
                  />
                </Typography>
              </div>
              <div
                className={`right-col ${
                  !isEdit ? "w-50 pe-5 " : "w-50 pe-2"
                }  d-flex flex-column align-items-end justify-content-end text-end`}
              >
                <Typography
                  variant="body1"
                  className="text-start "
                  gutterBottom
                  style={{ width: "80%" }}
                >
                  <ContactDetailField
                    label="Packing Time"
                    isEdit={isEdit}
                    type="time"
                    value={formContent?.packingTime}
                    minWidth={"125px"}
                  />
                </Typography>
              </div>
            </>
          ) : (
            <>
              <p className="mb-1">
                {formContent?.packingDate} {formContent?.packingTime}
              </p>
            </>
          )}
        </div> */}
        {/* /dates */}
        {/* permissions */}
        <div className="mt-2 pe-1 dynamic-div">
          <Typography
            variant="body1"
            className="text-start"
            gutterBottom
            style={{ fontSize: "10px" }}
          >
            <DynamicCheckbox
              label="Is there any vehicle/crew permission/security access/permit required for your residence?"
              checkId1="check1"
              checkLabel1="Yes"
              checkId2="check2"
              checkLabel2="No"
              value1={formContent?.permissions}
              value2={formContent?.permissions}
              disabled={true}
            />
          </Typography>
        </div>
        {/* /permissions */}
        {/* requirement */}
        {formContent?.permissions && (
          <div className="mt-2 pe-1 dynamic-div">
            <Typography variant="body1" className="text-start" gutterBottom>
              <DynamicText
                label="If yes, please advise requirement:"
                textAreaLabel="Lorem ipsum"
                isEdit={isEdit}
                value={formContent?.adviseRequirement}
              />
            </Typography>
          </div>
        )}
        {/* /requirement */}
        {/* 1st priority */}
        <div className="mt-2 pe-1 dynamic-div">
          <Typography variant="body1" className="text-start" gutterBottom>
            <DynamicText
              label="1st priority items to pack according to your need or requirement:"
              textAreaLabel="1st priority items..."
              isEdit={isEdit}
              value={formContent?.firstPriority}
            />
          </Typography>
        </div>
        {/* /1st priority */}
        {/* 2nd priority */}
        <div className="mt-2 pe-1 dynamic-div">
          <Typography variant="body1" className="text-start" gutterBottom>
            <DynamicText
              label="2nd priority items to pack according to your need or requirement:"
              textAreaLabel="2nd priority items... "
              isEdit={isEdit}
              value={formContent?.secondPriority}
            />
          </Typography>
        </div>
        {/* /2nd priority */}
        {/* 3rd priority */}
        <div className="mt-2 pe-1 dynamic-div">
          <Typography variant="body1" className="text-start" gutterBottom>
            <DynamicText
              label="3rd priority items to pack according to your need or requirement:"
              textAreaLabel="3rd priority items..."
              isEdit={isEdit}
              value={formContent?.thirdPriority}
            />
          </Typography>
        </div>
        {/* /3rd priority */}
        {/* marked/labeled items */}
        <div className="mt-2 pe-1 dynamic-div">
          <Typography variant="body1" className="text-start" gutterBottom>
            <DynamicCheckbox
              label="Residence’s rooms or items marked/labeled with suitable Info/Numbers/Colors are not going to pack up:"
              checkId1="labeled_items_yes"
              checkLabel1="Yes"
              checkId2="labeled_items_no"
              checkLabel2="No"
              value1={formContent?.packUpSuitable}
              value2={formContent?.packUpSuitable}
            />
          </Typography>
        </div>
        {/* /marked/labeled items  */}
        {/* Customer Suggestions */}
        <div className="mt-2 pe-1 dynamic-div">
          <Typography variant="body1" className="text-start" gutterBottom>
            <DynamicText
              label="Customer Suggestions/Remarks if any:"
              textAreaLabel="Customer Suggestions..."
              isEdit={isEdit}
              value={formContent?.suggestions}
            />
          </Typography>
        </div>
        {/* /Customer Suggestions */}
        {/* Office Instructions/remarks */}
        <div className="mt-2 pe-1 mb-0 dynamic-div">
          <Typography variant="body1" className="text-start" gutterBottom>
            <DynamicText
              label="Office Instructions/remarks:"
              textAreaLabel="Office Instructions/remarks..."
              isEdit={isEdit}
              value={formContent?.officeInstructions}
            />
          </Typography>
        </div>
        {/* /Office Instructions/remarks */}

        {/* Signatures */}
        <Row className="mt-1">
          <Col sm={6} className="">
            <div
              style={{ height: "90px" }}
              className="d-flex justify-content-center align-items-end"
            >
              {formContent?.moveCordinatorSign?.trim()?.length == 0 ? (
                <></>
              ) : (
                <img
                  src={`${Bucket}${formContent?.moveCordinatorSign}`}
                  height={80}
                  width={200}
                />
              )}
            </div>

            <p className="mb-0">{"_______________________________"}</p>
            <p className="my-0">
              <b>{"Move Coordinator"}</b>
            </p>

            {""}
          </Col>
          <Col sm={6} className="">
            <div
              style={{ height: "90px" }}
              className="d-flex justify-content-center align-items-end"
            >
              {formContent.clientSign != "" &&
                formContent.clientSign != undefined && (
                  <img
                    src={`${Bucket}${formContent.clientSign}`}
                    height={80}
                    width={200}
                  />
                )}
            </div>
            <p className="mb-0">{"_______________________________"}</p>
            <p className="my-0">
              <b>{"Customer/Authorized"}</b>
            </p>

            {""}
          </Col>
        </Row>

        {/* </MainTemplate> */}
      </div>
    </>
  );
};
export default PackingRequestPageDownload;
