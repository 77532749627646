// DynamicCheckbox.js
import React from "react";
import CustomCheckbox from "../atoms/CustomCheckbox";

const DynamicCheckbox = ({
  label,
  value1,
  value2,
  checkId1,
  checkLabel1,
  checkId2,
  checkLabel2,
  onChange1,
  onChange2,
  disabled

}) => {

  return (
    <div className="mb-2">
      <label htmlFor={checkId1} className="fw-bold check-mb">
        {label}
      </label>
      <div className="d-flex gap-5">
        <CustomCheckbox value={(value1) ? true  : false} id={checkId1} label={checkLabel1} checked={(value1 ) ? true  : false} onChange={onChange1} disabled={disabled ? true : false}/>
        <CustomCheckbox value={(!value1) ? true  : false} id={checkId2} label={checkLabel2} checked={(!value1) ? true  : false} onChange={onChange2} disabled={disabled ? true : false}/>
      </div>
    </div>
  );
};

export default DynamicCheckbox;
