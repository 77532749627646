import React from "react";
import TextField from "@mui/material/TextField";
import "./style.css";

const InfoInput = ({ value, onChange, error, name, type, placeholder, required}) => {
  const inputLabelProps = {
    shrink: true,
    style: {
      color: "black", // Set the color to black
      fontWeight: "600", // Set the font weight to bold
      fontSize: '15px'
    },
  };

  const inputProps = {
    style: {
      height: "45px", // Set the height to 40 pixels
    },
  };
  return (
    <TextField
      type={type}
      label={name}
      variant="outlined"
      placeholder={placeholder}
      fullWidth
      value={value}
      onChange={onChange}
      error={error}
      helperText={error && "Please Enter a Valid Amount"}
      InputLabelProps={inputLabelProps}
      InputProps={inputProps}
      required={required}
    />
  );
};

export default InfoInput;
