import React, { useEffect, useState, useRef } from "react";
import { surveyResultPreview, sendQuote } from "../../../api/services/services";
import "./style.css";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Snackbar, Card, CardContent, Typography } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import SuccessModal from "../../molecules/SuccessModal";
import MuiAlert from "@mui/material/Alert";
import callIcon from "../../../imgs/call.svg";
import calenderIcon from "../../../imgs/calendar.svg";
import calenderMove from "../../../imgs/calendarMinus.svg";
import emailIcon from "../../../imgs/email.svg";
import locationImg from "../../../imgs/location.svg";
import html2pdf from "html2pdf.js";
import DownloadQuotePage from "../DownloadPdfPage";
import { clientPreview, quoteStatus } from "../../../api/services/services";
import TextField from "@mui/material/TextField";
import successImg from "../../../imgs/succes.svg";
import { usePDF } from 'react-to-pdf';
import generatePDF, { Resolution, Margin } from 'react-to-pdf';

const PreviewQuotePage = () => {
  const componentRef = useRef();
  const { toPDF, targetRef } = usePDF({filename: 'page.pdf'});

  const [isLoading, setIsLoading] = useState(true);
  const [comment, setComment] = useState(null);
  const location = useLocation();
  const urlData = new URLSearchParams(location.search);
  const email = urlData.get("email");
  const surveyId = urlData.get("surveyId");
  const token = urlData.get("token");

  const inputLabelProps = {
    shrink: true,
    style: {
      color: "black", // Set the color to black
      fontWeight: "600", // Set the font weight to bold
      fontSize: "15px",
    },
  };

  const inputProps = {
    style: {
      //   height: "45px", // Set the height to 40 pixels
      padding: "8px 8px",
    },
  };

  const id = window.location.pathname.split("/")[2];
  const [surveyData, setSurveyData] = useState(null);
  const navigate = useNavigate();
  const [status, setStatus] = useState("");
  const [success, setSuccess] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [show, setShow] = useState(false);

  var totalWeight = 0;
  var totalCbm = 0;
  var totalItems = 0;

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // fetch single survey details
  const getSurvey = () => {
    surveyResultPreview(id)
      .then((response) => {
        setSurveyData(response?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching survey:", error?.response?.data);
      });
  };

  const generatePdf = () => {
    setShow(true);

    setTimeout(() => {
      const content = document.getElementById("content-to-convert"); // Replace with the ID of the element you want to convert to PDF

      const currentDateTime = new Date();
      const filename = `survey_${currentDateTime.toISOString()}.pdf`;

      const pdfOptions = {
        margin: 5,
        filename: filename,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
        }, // Set the page size here
      };

      let temp = html2pdf().from(content).set(pdfOptions).outputPdf();
      temp.save(filename);

      setShow(false);
    }, 3500);
  };

  //
  const getClientPreview = () => {
    clientPreview(email, surveyId, token)
      .then((response) => {
        setSurveyData(response?.data?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);
        setIsLoading(false);
      });
  };

  //
  const updateQuoteStatus = (st) => {
    const body = {
      status: st,
      comment: comment,
    };
    quoteStatus(email, surveyId, body, token)
      .then((response) => {
        setSnackbarMessage("Status updated successfully");
        getClientPreview();
        setOpenSnackbar(true);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);
      });
  };

  useEffect(() => {
    getClientPreview();
  }, []);

  //
  const convertDate = (unixTimestamp) => {
    const date = new Date(unixTimestamp * 1000); // Multiply by 1000 to convert from seconds to milliseconds
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1 and format with leading zero
    const dd = String(date.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const options = {
    // resolution: Resolution.HIGH,
    page: {
       // margin is in MM, default is Margin.NONE = 0
       margin: Margin.SMALL,
       // default is 'A4'
       format: 'letter',
       // default is 'portrait'
       orientation: 'portrait',
    },
    canvas: {
       // default is 'image/jpeg' for better size performance
       mimeType: 'image/png',
       qualityRatio: 1
    },
    overrides: {
      pdf: {
         compress: true
      },
      
   },
 };

 const [downloadText, setDownloadText] = useState('download')

 const getTargetElement = () => document.getElementById('content-id');

  return (
    <div className="mx-3 mx-md-5">
      {/* <MainTemplate> */}

      {!success ? (
        <>
          {surveyData ? (
            <>
              <div className="d-flex align-items-center py-3 py-md-4 ">
                <div className="row w-100 mx-0">
                  <div className="col-12 col-md-4 ps-0 d-none d-md-flex">
                    <p className="main-head ps-1 mb-0 ">Survey Details</p>
                  </div>
                  <div className="col-12 col-md-8 text-center text-md-end  px-0 ">
                    <Button
                      className="pr-btn ms-auto"
                      // onClick={() => generatePdf()}
                      onClick={() => {
                        setShow(true)
                        setDownloadText("Downloading..")
                        setTimeout(()=>{
                          // toPDF()
                          generatePDF(getTargetElement, options)
                      }, 500)
                      setTimeout(()=>{
                        setDownloadText("Download")
                        setShow(false)
                      }, 1000)
                        }
                      }
                    >
                      {downloadText}
                    </Button>
                    {surveyData?.surveyDetail?.surveyResult?.surveyQuote
                      ?.status != "canceled" &&
                      surveyData?.surveyDetail?.surveyResult?.surveyQuote
                        ?.status != "approved" && (
                        <>
                          <Button
                            className="pr-btn ms-2"
                            onClick={() => {
                              updateQuoteStatus("approved");
                            }}
                          >
                            Approve
                          </Button>

                          <Button
                            className="pr-btn  ms-2"
                            onClick={() => {
                              updateQuoteStatus("canceled");
                            }}
                          >
                            Reject
                          </Button>
                        </>
                      )}
                  </div>
                </div>
              </div>

              {surveyData?.surveyDetail?.surveyResult?.surveyQuote?.status !=
                "canceled" &&
                surveyData?.surveyDetail?.surveyResult?.surveyQuote?.status !=
                  "approved" && (
                  <Row className="mx-0 px-0 py-2 py-md-3  mb-3">
                    <Col md={12} className="text-start px-0">
                      <TextField
                        rows={2} // Set the number of rows to control the height
                        type="text"
                        label="Comments"
                        variant="outlined"
                        placeholder="Enter comments (Optional)..."
                        fullWidth
                        value={comment}
                        onChange={(e) => {
                          if (e.target.value.trim().length == 0)
                            setComment(null);
                          else setComment(e.target.value);
                        }}
                        InputLabelProps={inputLabelProps}
                        InputProps={inputProps}
                        multiline
                      />
                    </Col>
                  </Row>
                )}

              <div className="row w-100 mx-0">
                <div className="col-12 col-md-4 ps-0 d-flex d-md-none">
                  <p className="main-head ps-1 mb-0 ">Survey Details</p>
                </div>
              </div>

              <Row className="mx-0 px-2 py-3 preview-card mb-4">
                <Row>
                  <Col md={12} className="text-start">
                    <h3 className="fn">
                      <b>{surveyData?.surveyDetail?.name}'s Move</b>
                    </h3>
                  </Col>
                </Row>
                <Row style={{ justifyContent: "space-between" }}>
                  <Col sm={6} md={2} className="mb-2">
                    <div className="d-flex align-items-start">
                      <div className="white-circle mt-1">
                        <img src={emailIcon} className="img-pr" />
                      </div>
                      <div className="ps-2">
                        <p className="surve-name mb-0">
                          <b>Email</b>
                        </p>
                        <p className="surve-email mb-0 p-word">
                          {surveyData?.surveyDetail?.email}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col sm={6} md={2} className="mb-2">
                    <div className="d-flex align-items-start">
                      <div className="white-circle mt-1">
                        <img src={callIcon} className="img-pr" />
                      </div>
                      <div className="ms-2">
                        <p className="surve-name mb-0">Phone No.</p>
                        <p className="surve-email mb-0 p-word">
                          {surveyData?.surveyDetail?.loadPlacePhone}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col sm={6} md={2} className="mb-2">
                    <div className="d-flex align-items-start">
                      <div className="white-circle mt-1">
                        <img
                          src={locationImg}
                          height={24}
                          width={24}
                          className="img-pr"
                        />
                      </div>
                      <div className="ms-2">
                        <p className="surve-name mb-0">Location</p>
                        <p className="surve-email mb-0 p-word">
                          {surveyData?.surveyDetail?.loadPlaceStAddress}
                        </p>
                      </div>
                    </div>
                  </Col>

                  <Col sm={6} md={2} className="mb-2">
                    <div className="d-flex align-items-start">
                      <div className="white-circle mt-1">
                        <img src={calenderIcon} className="img-pr" />
                      </div>
                      <div className="ms-2">
                        <p className="surve-name mb-0">Created Date</p>
                        <p className="surve-email mb-0 p-word">
                          {surveyData?.surveyDetail?.createdAt
                            ? convertDate(surveyData?.surveyDetail?.createdAt)
                            : "-"}
                        </p>
                      </div>
                    </div>
                  </Col>

                  <Col sm={6} md={2} className="mb-2">
                    <div className="d-flex align-items-start">
                      <div className="white-circle mt-1">
                        <img src={calenderMove} className="img-pr" />
                      </div>
                      <div className="ms-2">
                        <p className="surve-name mb-0">Move Date</p>
                        <p className="surve-email mb-0 p-word">
                          {surveyData?.surveyDetail?.loadDate
                            ? convertDate(surveyData?.surveyDetail?.loadDate)
                            : "-"}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Row>

              {/* Survey Overview*/}
              <Row className="mx-1">
                <Col sm={12} md={12} className="text-start px-0 fn">
                  <b>Survey Overview</b>
                </Col>
              </Row>

              <Row
                className="mx-0 bg-white mt-3 py-1 px-2 px-md-4"
                style={{ borderRadius: "5px" }}
              >
                <div className="detail-row row">
                  <div className="col-3 text-start px-0 ">
                    <p className="surve-email mb-0 p-word">
                      <b>Room</b>
                    </p>
                  </div>
                  <div className="col-3 text-start px-0 ">
                    <p className="surve-email mb-0 p-word">
                      <b>Weight</b>
                    </p>
                  </div>
                  <div className="col-3 text-start px-0 ">
                    <p className="surve-email mb-0 p-word">
                      <b>CBM</b>
                    </p>
                  </div>
                  <div className="col-3 text-start px-0 ">
                    <p className="surve-email mb-0 p-word">
                      <b>Items</b>
                    </p>
                  </div>
                </div>
                {surveyData?.categoryWiseCalculation?.length > 0 &&
                  surveyData?.categoryWiseCalculation?.map((cat, index) => {
                    totalWeight = totalWeight + cat?.totalWeight;
                    totalCbm = totalCbm + cat?.totalCbm;
                    totalItems = totalItems + cat?.totalItems;
                    return (
                      <div className="detail-row row">
                        <div className="col-3 text-start px-0 ">
                          <p className="surve-email mb-0 p-word">
                            {cat?.categoryName ? cat?.categoryName : ""}
                          </p>
                        </div>
                        <div className="col-3 text-start px-0 ">
                          <p className="surve-email mb-0 p-word">
                            {cat?.totalWeight ? `${cat?.totalWeight} kg` : "-"}
                          </p>
                        </div>
                        {cat?.totalCbm ? (
                          <div className="col-3 text-start px-0 ">
                            <p className="surve-email mb-0 p-word">
                              {`${cat?.totalCbm} cm`}
                              <sup>3</sup>
                            </p>
                          </div>
                        ) : (
                          <div className="col-3 text-start px-0 ">{"-"}</div>
                        )}
                        <div className="col-3 text-start px-0 ">
                          <p className="surve-email mb-0 p-word">
                            {cat?.totalItems ? cat?.totalItems : "-"}
                          </p>
                        </div>
                      </div>
                    );
                  })}

                <div className="detail-row-last row">
                  <div className="col-3 text-start px-0 ">
                    <p className="surve-email mb-0 p-word">
                      <b>Total</b>
                    </p>
                  </div>
                  <div className="col-3 text-start px-0 ">
                    <p className="surve-email mb-0 p-word">
                      <b>{totalWeight} kg</b>
                    </p>
                  </div>
                  <div className="col-3 text-start px-0 ">
                    <p className="surve-email mb-0 p-word">
                      <b>
                        {totalCbm} cm<sup>3</sup>
                      </b>
                    </p>
                  </div>
                  <div className="col-3 text-start px-0 ">
                    <p className="surve-email mb-0 p-word">
                      <b>{totalItems}</b>
                    </p>
                  </div>
                </div>
              </Row>

              <Row className="mx-1 mt-3">
                <Col sm={12} md={12} className="text-start px-0 fn">
                  <b>Shipment Mode</b>
                </Col>
              </Row>
              <Row
                className="mx-0 bg-white mt-3 py-2 text-start px-1 px-md-3"
                style={{ borderRadius: "5px" }}
              >
                <Col sm={12} md={12} className="text-capitalize">
                  <p className="surve-email mb-0 p-word">
                    {" "}
                    By{" "}
                    {
                      surveyData?.surveyDetail?.surveyResult?.surveyQuote
                        ?.shipmentMethod
                    }
                  </p>
                </Col>
              </Row>

              <Row className="mx-1 mt-4">
                <Col sm={12} md={12} className="text-start px-0 fn">
                  <b>Shipment Payment Details</b>
                </Col>
              </Row>

              {surveyData?.surveyDetail?.surveyResult?.surveyQuote
                ?.shipmentMethod == "air" ||
              surveyData?.surveyDetail?.surveyResult?.surveyQuote
                ?.shipmentMethod == "road" ? (
                <Row
                  className="mx-0 bg-white mt-3 py-2 text-start px-1 px-md-3 "
                  style={{ borderRadius: "5px" }}
                >
                  <Col
                    sm={6}
                    md={4}
                    lg={2}
                    className="text-capitalize col-b-right"
                  >
                    <p className="mode-grey">Door to Door</p>
                    <p className="mode-black">
                      {
                        surveyData?.surveyDetail?.surveyResult?.surveyQuote
                          ?.pricing?.doorToDoor
                      }
                    </p>
                  </Col>

                  <Col
                    sm={6}
                    md={4}
                    lg={2}
                    className="text-capitalize col-b-right"
                  >
                    <p className="mode-grey">Door to Port</p>
                    <p className="mode-black">
                      {
                        surveyData?.surveyDetail?.surveyResult?.surveyQuote
                          ?.pricing?.doorToPort
                      }
                    </p>
                  </Col>

                  <Col
                    sm={6}
                    md={4}
                    lg={2}
                    className="text-capitalize col-b-right"
                  >
                    <p className="mode-grey">Port to Door</p>
                    <p className="mode-black">
                      {
                        surveyData?.surveyDetail?.surveyResult?.surveyQuote
                          ?.pricing?.portToDoor
                      }
                    </p>
                  </Col>

                  <Col
                    sm={6}
                    md={4}
                    lg={2}
                    className="text-capitalize col-b-right"
                  >
                    <p className="mode-grey">Transportation Services</p>
                    <p className="mode-black">
                      {
                        surveyData?.surveyDetail?.surveyResult?.surveyQuote
                          ?.pricing?.transportService
                      }
                    </p>
                  </Col>
                  <Col
                    sm={6}
                    md={4}
                    lg={2}
                    className="text-capitalize col-b-right"
                  >
                    <p className="mode-grey">
                      Vehicle Import & Export Services
                    </p>
                    <p className="mode-black">
                      {
                        surveyData?.surveyDetail?.surveyResult?.surveyQuote
                          ?.pricing?.vehicleService
                      }
                    </p>
                  </Col>

                  <Col sm={6} md={4} lg={2} className="text-capitalize ">
                    <p className="mode-grey">Shipping & Packing Services</p>
                    <p className="mode-black">
                      {
                        surveyData?.surveyDetail?.surveyResult?.surveyQuote
                          ?.pricing?.packingService
                      }
                    </p>
                  </Col>
                </Row>
              ) : (
                <>
                  <Row
                    className="mx-0 bg-white mt-3 py-2 text-start px-1 px-md-3 "
                    style={{ borderRadius: "5px" }}
                  >
                    {surveyData?.surveyDetail?.surveyResult?.surveyQuote?.seaShipmentPricing?.map(
                      (pr, index) => {
                        return (
                          <>
                            <Row className="mx-0 mt-3 py-2 text-start px-1 ">
                              {pr?.containerSize == "twentyft" ? (
                                <b>{index + 1}. Twenty Feet Container</b>
                              ) : (
                                <b>{index + 1}. Forty Feet Container</b>
                              )}
                            </Row>

                            <Row className="mx-0 mt-3 py-2 text-start px-2 ">
                              <Col
                                sm={6}
                                md={4}
                                lg={2}
                                className="text-capitalize col-b-right"
                              >
                                <p className="mode-grey">Door to Door</p>
                                <p className="mode-black">
                                  {pr?.pricing?.doorToDoor}
                                </p>
                              </Col>

                              <Col
                                sm={6}
                                md={4}
                                lg={2}
                                className="text-capitalize col-b-right"
                              >
                                <p className="mode-grey">Door to Port</p>
                                <p className="mode-black">
                                  {pr?.pricing?.doorToPort}
                                </p>
                              </Col>

                              <Col
                                sm={6}
                                md={4}
                                lg={2}
                                className="text-capitalize col-b-right"
                              >
                                <p className="mode-grey">Port to Door</p>
                                <p className="mode-black">
                                  {pr?.pricing?.portToDoor}
                                </p>
                              </Col>

                              <Col
                                sm={6}
                                md={4}
                                lg={2}
                                className="text-capitalize col-b-right"
                              >
                                <p className="mode-grey">
                                  Transportation Services
                                </p>
                                <p className="mode-black">
                                  {pr?.pricing?.transportService}
                                </p>
                              </Col>
                              <Col
                                sm={6}
                                md={4}
                                lg={2}
                                className="text-capitalize col-b-right"
                              >
                                <p className="mode-grey">
                                  Vehicle Import & Export Services
                                </p>
                                <p className="mode-black">
                                  {pr?.pricing?.vehicleService}
                                </p>
                              </Col>

                              <Col
                                sm={6}
                                md={4}
                                lg={2}
                                className="text-capitalize "
                              >
                                <p className="mode-grey">
                                  Shipping & Packing Services
                                </p>
                                <p className="mode-black">
                                  {pr?.pricing?.packingService}
                                </p>
                              </Col>
                            </Row>
                          </>
                        );
                      }
                    )}
                  </Row>
                </>
              )}
              <Row className="mt-5 mb-5"></Row>
            </>
          ) : (
            <>
              {isLoading ? (
                <div> Loading...</div>
              ) : (
                <div> Ooops... Something went wrong...</div>
              )}
            </>
          )}

            {/* <div id="content-to-convert">
              <DownloadQuotePage surveyData={surveyData} />
            </div> */}
  
          {show == true && (
            <div id="content-id" style={{width: '794px'}}>
              <DownloadQuotePage surveyData={surveyData} id='content-id'/>
            </div>
          )}
        </>
      ) : (
        <>
          <Card
            sx={{ py: 3, pb: 1 }}
            style={{ borderRadius: "15px" }}
            className="success-card mx-2"
          >
            <CardContent>
              <img
                src={successImg}
                alt="Shezlon Logo"
                width="84"
                height="84"
                className="mb-4"
              />
              <Typography variant="p" align="center" gutterBottom>
                <h4 className="success-title p-0 mb-1 text-capitalize">
                  Status updated successfully
                </h4>
              </Typography>
            </CardContent>
          </Card>
        </>
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={
            snackbarMessage?.includes("successful") ? "success" : "error"
          }
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      {/* </MainTemplate> */}
    </div>
  );
};
export default PreviewQuotePage;
