import React, { useEffect, useState } from "react";
import {
  getSingleSurvey,
  addFormContent,
  updateFormContent,
  getFormContent,
  surveyResultPreview,
} from "../../../api/services/services";
import backArrow from "../../../imgs/BackArrow.svg";
import MainTemplate from "../../templates/MainTemplate";
import { useNavigate } from "react-router-dom";
import { Button, Snackbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import { Bucket } from "../../../api/config/apiConfig";
import Input from "../../atoms/Input";
import ContactDetailField from "../../molecules/ContactDetailField";
import "./style.css";
import { Table, Box, CircularProgress } from "@mui/material";
import DynamicText from "../../molecules/DynamicText";
import { useParams } from "react-router-dom";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import html2pdf from "html2pdf.js";
import removeImg from "../../../imgs/remove.png";

const WeightTicketDownload = ({ surveyData, formContent }) => {
  const { surveyId, id, flg } = useParams();

  const navigate = useNavigate();

  const [formContentId, setFormContentId] = useState("");
  const [isEdit, setIsEdit] = useState(flg == "1" ? true : false);

  const [isLoading, setIsLoading] = useState(false);

  var totalNoOfPieces = 0;
  var totalGrossWeight = 0;
  var totalNetWeight = 0;
  var totalVolWeight = 0;
  var totalCFT = 0;
  var totalCBM = 0;
  var CMS = 0;
  var totalCFTbyCBM = 0;
  var totalCMS = 0;

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // 11th, 12th, 13th, etc.
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  // Helper function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const ordinalSuffix = getOrdinalSuffix(day);

    return `${day}${ordinalSuffix} ${month}, ${year}`;
  };

  return (
    <div className="mx-4">
      {/* <MainTemplate> */}
      <div>
        <div className="d-block d-sm-flex align-items-center pb-1 pt-0 ">
          <div className="d-flex align-items-center justify-content-center w-100">
            <p className="ps-0 mb-0 head-top"> Weight Ticket</p>
          </div>
        </div>
      </div>
      {/* contact detail*/}
      <div className="contact-detail row mt-2">
        {/* left col */}
        <div
          className={`left-col ${!isEdit ? " w-50" : "w-50 pe-5"} text-start`}
        >
          <Typography variant="body1" gutterBottom>
            {/* <ContactDetailField
                label="Our Ref"
                isEdit={isEdit}
                type="text"
                minWidth={"100px"}
                value={formContent?.refNo}
                disabled
              /> */}
            <p className="p-word mb-1 text-start">
              <b> Our Ref:</b>{" "}
              <span className="ms-0">
                {" "}
                {formContent?.refNo ? formContent?.refNo : "-"}
              </span>
            </p>
            {/* <ContactDetailField
                label="Air Shipment"
                isEdit={isEdit}
                type="text"
                minWidth={"100px"}
                value={formContent?.airShippment}
              /> */}
            {/* <ContactDetailField
                label="Date"
                isEdit={isEdit}
                type="date"
                minWidth={"100px"}
                value={formContent?.date}
              /> */}
            <p className="p-word mb-1 text-start">
              <b> Date:</b>{" "}
              <span className="ms-0">
                {" "}
                {formContent?.date ? formatDate(formContent?.date) : "-"}
              </span>
            </p>
          </Typography>
        </div>
        {/* right col */}
        <div
          className={`right-col ${
            !isEdit ? "w-50" : "w-50 ps-5"
          }  d-flex flex-column align-items-end pe-2`}
        >
          <div
            className=""
            style={isEdit ? { width: "-webkit-fill-available" } : {}}
          >
            <Typography
              variant="body1"
              className="text-start w-100"
              gutterBottom
            >
              {/* <ContactDetailField
                  label="Origin Address"
                  isEdit={isEdit}
                  type="text"
                  wrapperStyle="justify-content-start"
                  value={formContent?.originAddress}
                /> */}
              <p className="p-word mb-1 text-start">
                <b> Origin Address:</b>{" "}
                <span className="ms-0">
                  {" "}
                  {formContent?.originAddress
                    ? formContent?.originAddress
                    : "-"}
                </span>
              </p>
              {/* <ContactDetailField
                  label="Destination"
                  isEdit={isEdit}
                  type="text"
                  wrapperStyle="justify-content-start"
                  value={formContent?.destination}
                /> */}
              <p className="p-word mb-1 text-start">
                <b> Destination:</b>{" "}
                <span className="ms-0">
                  {" "}
                  {formContent?.destination ? formContent?.destination : "-"}
                </span>
              </p>
              {/* <ContactDetailField
                  label="Shipment Mode"
                  isEdit={isEdit}
                  type="text"
                  wrapperStyle="justify-content-start"
                  value={formContent?.shipmentMode}
                /> */}
              <p className="p-word mb-1 text-start">
                <b> Shipment Mode:</b>{" "}
                <span className="ms-0">
                  {" "}
                  {formContent?.shipmentMode ? formContent?.shipmentMode : "-"}
                </span>
              </p>
            </Typography>
          </div>
        </div>
      </div>
      {/* /contact detail */}

      <div className="mt-2 pe-1 d-flex justify-content-start flex-wrap align-items-baseline">
        <div>
          <p className="mb-2" style={{ fontSize: "10px" }}>
            This is to certify that the air shipment of used household goods &
            personal effects belonging to {formContent?.belongsToA} C/O{" "}
            {formContent?.belongsToB} {formContent?.originAddress}
          </p>
        </div>
      </div>

      {formContent?.dataRows.map((row, index) => {
        totalNoOfPieces = Number(totalNoOfPieces) + Number(row?.noOfPieces);
        totalGrossWeight = Number(totalGrossWeight) + Number(row?.grossWeight);
        totalNetWeight = Number(totalNetWeight) + Number(row?.netWeight);
        totalVolWeight = Number(totalVolWeight) + Number(row?.volWeight);
        totalCFT = Number(totalCFT) + Number(row?.cft);
        totalCBM = Number(totalCBM) + Number(row?.cbm);
        totalCFTbyCBM = Number(totalCFTbyCBM) + Number(row?.CFTbyCBM);
        totalCMS =
          Number(totalCMS) +
          Number(row?.length) * Number(row?.width) * Number(row?.height);

        return <></>;
      })}

      {/* table */}
      <Typography variant="body1" className="text-start mt-2" gutterBottom>
        <label htmlFor="" className="fw-bold mb-1" style={{ fontSize: "10px" }}>
          Measuring & weights are as follows:
        </label>
      </Typography>

      {/*  table */}
      <div className=" mb-2 ">
        <Table responsive>
          {/* Table Header */}
          <thead className="bg-white">
            <tr>
              <th
                className="border p-1 text-start"
                style={{ width: "10%", fontSize: "10px" }}
              >
                No of Pieces
              </th>
              <th
                className="border p-1 text-start"
                style={{ width: "15%", fontSize: "10px" }}
              >
                Gross weight in KGS
              </th>
              <th
                className="border p-1 text-start"
                style={{ width: "15%", fontSize: "10px" }}
              >
                Net weight in KGS
              </th>
              <th
                className="border p-1 text-start"
                style={{ width: "15%", fontSize: "10px" }}
              >
                Vol weight in KGS
              </th>
              <th
                className="border p-1 text-center"
                colSpan={3}
                style={{ width: "30%", fontSize: "10px" }}
              >
                Size In Inches
              </th>
              <th
                className="border p-1  text-start"
                style={{ width: "10%", fontSize: "10px" }}
              >
                CFT/CBM
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {formContent?.dataRows?.length > 0 ? (
              <>
                {formContent?.dataRows.map((row, index) => (
                  <tr key={index}>
                    <td
                      className="border p-1 text-start"
                      style={{ fontSize: "10px" }}
                    >
                      {isEdit ? (
                        <Input
                          id="condition"
                          label="No of pieces"
                          type="number"
                          style="full-border-field"
                          value={row?.noOfPieces}
                          inline={{ width: "-webkit-fill-available" }}
                        />
                      ) : row.noOfPieces ? (
                        row.noOfPieces
                      ) : (
                        "-"
                      )}
                    </td>
                    <td
                      className="border p-1 text-start"
                      style={{ fontSize: "10px" }}
                    >
                      {isEdit ? (
                        <Input
                          id="condition"
                          label="Gross weight"
                          type="number"
                          style="full-border-field"
                          value={row?.grossWeight}
                          inline={{ width: "-webkit-fill-available" }}
                        />
                      ) : row.grossWeight ? (
                        `${row.grossWeight} KGS`
                      ) : (
                        "-"
                      )}
                    </td>
                    <td
                      className="border p-1 text-start"
                      style={{ fontSize: "10px" }}
                    >
                      {isEdit ? (
                        <Input
                          id="condition"
                          label="Net weight"
                          type="number"
                          style="full-border-field"
                          value={row?.netWeight}
                          inline={{ width: "-webkit-fill-available" }}
                        />
                      ) : row.netWeight ? (
                        `${row.netWeight} KGS`
                      ) : (
                        "-"
                      )}
                    </td>
                    <td
                      className="border p-1 text-start"
                      style={{ fontSize: "10px" }}
                    >
                      {isEdit ? (
                        <Input
                          id="condition"
                          label="Vol weight"
                          type="number"
                          style="full-border-field"
                          value={row?.volWeight}
                          inline={{ width: "-webkit-fill-available" }}
                        />
                      ) : row.volWeight ? (
                        `${row.volWeight} KGS`
                      ) : (
                        "-"
                      )}
                    </td>
                    {/* Size In CMS columns */}
                    <td
                      className="border p-1 text-center"
                      style={{ fontSize: "10px" }}
                    >
                      {isEdit ? (
                        <Input
                          id="condition"
                          label="Length in inches"
                          type="number"
                          style="full-border-field"
                          value={row?.length}
                          inline={{ width: "-webkit-fill-available" }}
                        />
                      ) : row.length ? (
                        `L : ${row.length}″`
                      ) : (
                        "-"
                      )}
                    </td>
                    <td
                      className="border p-1 text-center"
                      style={{ fontSize: "10px" }}
                    >
                      {isEdit ? (
                        <Input
                          id="condition"
                          label="Width in inches"
                          type="number"
                          style="full-border-field"
                          value={row?.width}
                          inline={{ width: "-webkit-fill-available" }}
                        />
                      ) : row.width ? (
                        `W : ${row.width}″`
                      ) : (
                        "-"
                      )}
                    </td>
                    <td
                      className="border p-1 text-center"
                      style={{ fontSize: "10px" }}
                    >
                      {isEdit ? (
                        <Input
                          id="condition"
                          label="Height in inches"
                          type="number"
                          style="full-border-field"
                          value={row?.height}
                          inline={{ width: "-webkit-fill-available" }}
                        />
                      ) : row.height ? (
                        `H : ${row.height}″`
                      ) : (
                        "-"
                      )}
                    </td>
                    {/* End of Size In CMS columns */}
                    <td
                      className="border p-1 text-start"
                      style={{ fontSize: "10px" }}
                    >
                      {isEdit ? (
                        <Input
                          id="condition"
                          label="CFT/CBM"
                          type="text"
                          style="full-border-field"
                          value={`${row?.cft?.toFixed(4)}/${row?.cbm?.toFixed(
                            4
                          )}`}
                          inline={{ width: "-webkit-fill-available" }}
                          disabled
                        />
                      ) : row.CFTbyCBM ? (
                        `${row.cft}/${row.cbm}`
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td className="text-center" colSpan={9}>
                  {isLoading ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "10px 0px",
                      }}
                    >
                      <CircularProgress
                        style={{
                          color: "#00aff0",
                          height: "25px",
                          width: "25px",
                        }}
                      />
                    </Box>
                  ) : (
                    <div
                      className="d-flex justify-content-center"
                      style={{ fontSize: "10px" }}
                    >
                      <p className="my-3">No Records to Show</p>
                    </div>
                  )}
                </td>
              </tr>
            )}

            <tr style={{ height: "30px" }}>
              <td className="py-1 pb-0 ps-0 text-start" colSpan={8}>
                <label className="fw-bold" style={{ fontSize: "10px" }}>
                  {" "}
                  Total:
                </label>
              </td>
            </tr>
            <tr className="bg-white mt-5">
              <td
                className="border p-1 text-start"
                style={{ fontSize: "10px" }}
              >
                {totalNoOfPieces ? `${totalNoOfPieces}` : "-"}
              </td>
              <td
                className="border p-1 text-start"
                style={{ fontSize: "10px" }}
              >
                {totalGrossWeight ? `${totalGrossWeight} KGS` : "-"}
              </td>
              <td
                className="border p-1 text-start"
                style={{ fontSize: "10px" }}
              >
                {totalNetWeight ? `${totalNetWeight} KGS` : "-"}
              </td>
              <td
                className="border p-1 text-start"
                style={{ fontSize: "10px" }}
              >
                {totalVolWeight ? `${totalVolWeight} KGS` : "-"}
              </td>
              <td
                className="border p-1 text-center"
                colSpan={3}
                style={{ fontSize: "10px" }}
              >
                {totalCMS ? `${totalCMS}` : "-"}
              </td>
              <td
                className="border p-1 text-start"
                style={{ fontSize: "10px" }}
              >
                {totalCFT || totalCBM
                  ? `${totalCFT?.toFixed(4)}/${totalCBM?.toFixed(4)}`
                  : "-"}
              </td>
              {isEdit && <td className="border p-1 text-start"></td>}
            </tr>
          </tbody>
        </Table>
      </div>
      {/* /table */}

      <Row className="mt-1">
        <Col sm={6} className="">
          <div
            style={{ height: "90px" }}
            className="d-flex justify-content-center align-items-end"
          >
            {formContent?.weightMasterSign?.trim()?.length == 0 ? (
              <></>
            ) : (
              <img
                src={`${Bucket}${formContent.weightMasterSign}`}
                height={80}
                width={200}
              />
            )}
          </div>

          <p className="mb-0">{"_______________________________"}</p>

          <p className="my-0">
            <b>{"Weight Master "}</b>
          </p>

          {""}
        </Col>

        <Col sm={6} className="">
          <div
            style={{ height: "90px" }}
            className="d-flex justify-content-center align-items-end"
          >
            <p className="my-0">
              {formContent?.weightMasterName
                ? formContent?.weightMasterName
                : "-"}
            </p>
          </div>
          <p className="mb-0">{"_______________________________"}</p>

          <p className="my-0">
            <b>{"Weight Master Name"}</b>
          </p>

          {""}
        </Col>
      </Row>

      {/* snackbar */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={
            snackbarMessage?.includes("successful") ? "success" : "error"
          }
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      {/* snackbar */}
      {/* </MainTemplate> */}
    </div>
  );
};
export default WeightTicketDownload;
