import React, { useState } from "react";
import { Modal, Backdrop, Fade } from "@mui/material";
import { Typography, Button, Grid, TextField } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import { confirmSuperAdminVerificationForEdit } from "./service";

const SuperAdminVerifationModal = ({ open, onClose, onSuccess, onError }) => {
  const inputRefs = [
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ];

  const [digits, setDigits] = useState(["", "", "", "", "", ""]);

  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const isConfirmButtonDisabled =
    digits[0].length == 0 ||
    digits[1].length == 0 ||
    digits[2].length == 0 ||
    digits[3].length == 0 ||
    digits[4].length == 0 ||
    digits[5].length == 0;

  const handleChange = (index, value) => {
    if (value.match(/^\d{0,1}$/)) {
      const updatedDigits = [...digits];
      updatedDigits[index] = value;
      setDigits(updatedDigits);

      if (value == "" && index > 0) {
        inputRefs[index - 1].current.focus();
      }

      if (index < 5 && value !== "") {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  // Confirming 2FA
  const confirm2Fa = () => {
    let code = "";
    for (let i = 0; i < digits.length; i++) {
      code = code + digits[i];
    }

    confirmSuperAdminVerificationForEdit(code)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        onError();
      });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="mx-3"
      >
        <Fade in={open}>
          <div className="modal-container">
            {/* <Card
              style={{ borderRadius: "15px" }}
              className="fa-card py-2 pb-0"
            > */}

            <div className="modal-content assign-modal px-4">
              <Typography variant="p" align="center" gutterBottom>
                <p className="title p-0 mb-1">Super Admin Edit Authenticator</p>
              </Typography>
              <Typography variant="p" align="center" gutterBottom>
                <p className="desc mb-3 mb-md-5">
                  Please check your your super admin for the otp
                </p>
              </Typography>
              <Typography variant="p" align="center" gutterBottom>
                <p className="mb-3 text-capitalize">
                  <b> ENTER THE 6 DIGIT CODE</b>
                </p>
              </Typography>
              <div className="d-flex align-items-center justify-content-center mb-3 test">
                {digits.map((digit, index) => (
                  <TextField
                    key={index}
                    inputRef={inputRefs[index]}
                    variant="outlined"
                    size="small"
                    type="text"
                    value={digit}
                    onChange={(e) => handleChange(index, e.target.value)}
                    style={{
                      margin: isSmallScreen ? "5px" : "10px",
                      width: isSmallScreen ? "40px" : "50px",
                      height: isSmallScreen ? "40px" : "50px",
                      textAlign: "center",
                      fontSize: isSmallScreen ? "18px" : "20px",
                      // paddingLeft: "5px",
                    }}
                  />
                ))}
              </div>

              <Grid item xs={12}>
                <Button
                  type="button"
                  variant="contained"
                  className={
                    isConfirmButtonDisabled
                      ? "disabled-btn mt-3 mb-3 text-capitalize"
                      : "submit-btn mt-3 text-capitalize"
                  }
                  onClick={confirm2Fa}
                  disabled={isConfirmButtonDisabled}
                >
                  Confirm
                </Button>
              </Grid>
            </div>

            {/* </Card> */}
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default SuperAdminVerifationModal;
