import React, { useEffect, useState } from "react";
import {
  getFormContent,
  getSingleSurvey,
  updateFormContent,
  surveyResultPreview,
  sharePreviewLink,
  addFormContent,
} from "../../../api/services/services";
import backArrow from "../../../imgs/BackArrow.svg";
import MainTemplate from "../../templates/MainTemplate";
import { useNavigate } from "react-router-dom";
import { Button, Snackbar, Box } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import { Bucket } from "../../../api/config/apiConfig";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import Input from "../../atoms/Input";
import removeImg from "../../../imgs/remove.png";
import { useParams } from "react-router-dom";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import html2pdf from "html2pdf.js";
import ShareWithClientsModal from "../../molecules/ShareWithClients";
import Typography from "@mui/material/Typography";
import ContactDetailField from "../../molecules/ContactDetailField";
import DynamicText from "../../molecules/DynamicText";

const InsuranceServicesPage = () => {
  const { surveyId, id, flg } = useParams();
  const [surveyData, setSurveyData] = useState(null);
  const navigate = useNavigate();

  const [formContentId, setFormContentId] = useState("");
  const [isEdit, setIsEdit] = useState(flg == "1" ? true : false);

  const [isLoading, setIsLoading] = useState(true);
  const [formContent, setFormContent] = useState({
    clientName: "",
    clientEmail: "",
    clientSign: "",
    originAddress: "",
    destinationAddress: "",
    addres:"",
    email:"iti@intertransins.com",
    web:"www.intertransins.com",
    dataRows: [
      {
        description: "",
        conditionAtOrigin: "",
        conditionAtDestination: "",
      },
    ],
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [isShare, setIsShare] = useState(false);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  //
  const getSurveyPreview = () => {
    surveyResultPreview(surveyId)
      .then((response) => {
        setSurveyData(response?.data?.data?.surveyDetail);
        let values = { ...formContent };
        values.originAddress = `${response?.data?.data?.surveyDetail?.loadPlaceStAddress}, ${response?.data?.data?.surveyDetail?.loadPlaceCity}, ${response?.data?.data?.surveyDetail?.loadPlaceCountry}`;
        values.destinationAddress = `${response?.data?.data?.surveyDetail?.unloadPlaceStAddress}, ${response?.data?.data?.surveyDetail?.unloadPlaceCity}, ${response?.data?.data?.surveyDetail?.unloadPlaceCountry}`;
        values.clientName = `${response?.data?.data?.surveyDetail?.name}`;
        values.clientEmail = `${response?.data?.data?.surveyDetail?.email}`;
        values.fileRef = `${response?.data?.data?.surveyDetail?.id}`;

        let dR = [];

        response?.data?.data?.surveyDetail?.surveyResult?.surveyItems?.map(
          (itm, ind) => {
            dR[ind] = {
              no: ind + 1,
              description: itm?.name,
              conditionAtOrigin: "",
              conditionAtDestination: "",
            };
          }
        );

        values.dataRows = dR;

        setFormContent(values);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }
      });
  };

  //
  const getSurvey = () => {
    getSingleSurvey(surveyId)
      .then((response) => {
        if (response?.data?.data?.status == "submitted") getSurveyPreview();
        else {
          setSurveyData(response?.data?.data);
          let values = { ...formContent };
          values.originAddress = `${response?.data?.data?.loadPlaceStAddress}, ${response?.data?.data?.loadPlaceCity}, ${response?.data?.data?.loadPlaceCountry}`;
          values.destinationAddress = `${response?.data?.data?.unloadPlaceStAddress}, ${response?.data?.data?.unloadPlaceCity}, ${response?.data?.data?.unloadPlaceCountry}`;
          values.clientName = `${response?.data?.data?.name}`;
          values.clientEmail = `${response?.data?.data?.email}`;
          values.fileRef = `${response?.data?.data?.id}`;
          setFormContent(values);

          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }
      });
  };

  //
  const addContent = () => {
    setIsLoading(true);
    const body = {
      formId: id,
      surveyId: surveyId,
      content: formContent,
      mediaKeys: [],
      removedMediaKeys: [],
    };
    addFormContent(body)
      .then(() => {
        setSnackbarMessage("Inter Trans Insurance Services has been added successfully!");
        setOpenSnackbar(true);

        setTimeout(() => {
          navigate(-1);
          setIsLoading(false);
        }, 1000);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  //
  const updateContent = () => {
    setIsLoading(true);
    const body = {
      formId: id,
      surveyId: surveyId,
      content: formContent,
      mediaKeys: [],
      removedMediaKeys: [],
    };
    updateFormContent(body, formContentId)
      .then((res) => {
        setSnackbarMessage("Inter Trans Insurance Services has been updated successfully!");
        setOpenSnackbar(true);
        getContent();
        setIsEdit(false);

        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  //
  const getContent = () => {
    setIsLoading(true);
    getFormContent(id, surveyId)
      .then((res) => {
        setFormContentId(res.data.data.uuid);
        setFormContent(res.data.data.content);
        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (flg == "1") getSurvey();
    else getContent();
  }, []);

  const [show, setShow] = useState(false);
  const options = {
    // resolution: Resolution.HIGH,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      format: "letter",
      // default is 'portrait'
      orientation: "portrait",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/png",
      qualityRatio: 1,
    },
    overrides: {
      pdf: {
        compress: true,
      },
    },
  };

  //
  const generatePdf = () => {
    setShow(true);

    setTimeout(() => {
      const content = document.getElementById("content-to-convert"); // Replace with the ID of the element you want to convert to PDF

      const currentDateTime = new Date();
      const filename = `survey_${currentDateTime.toISOString()}.pdf`;

      const pdfOptions = {
        margin: 5,
        filename: filename,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
        }, // Set the page size here
      };

      let temp = html2pdf().from(content).set(pdfOptions).outputPdf();
      temp.save(filename);

      setShow(false);
    }, 3500);
  };

  const [downloadText, setDownloadText] = useState("download");

  const getTargetElement = () => document.getElementById("content-id");

  //
  const shareWithClient = () => {
    setIsLoading(true);
    const body = {
      surveyId: surveyId,
      formId: id,
      emails: [formContent?.clientEmail],
      formRoute: "inter-trans-insurance-services-preview",
    };
    sharePreviewLink(body)
      .then(() => {
        setSnackbarMessage(
          "Inter Trans Insurance Services has been shared with the client successfully!"
        );
        setOpenSnackbar(true);

        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  const handleAddNewDataRows = () => {
    const values = { ...formContent };
    const valuesRows = [...formContent?.dataRows];
    valuesRows.push({
      no: "",
      description: "",
      condition: "",
      disclamer: "",
    });
    values.dataRows = valuesRows;

    setFormContent(values);
  };

  const handleRemoveDataRows = (ind) => {
    const values = { ...formContent };
    const valuesRows = [...formContent?.dataRows];
    valuesRows.splice(ind, 1);
    values.dataRows = valuesRows;

    setFormContent(values);
  };

  return (
    <>
      <MainTemplate>
        <div className="d-block d-sm-flex align-items-center py-4">
          <div className="d-flex align-items-center ">
            <div
              className="light-blue-color back-bg cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <img src={backArrow} height="24" width="24" />{" "}
            </div>
            <div>
              <p className="main-head ps-3 mb-0">Inter Trans Insurance Services</p>
            </div>
          </div>
          <div className="d-flex ms-auto justify-content-center mt-2 mt-sm-0">
            {/*Edit Button */}
            {!isEdit && (
              <Button
                className="active-btn"
                onClick={() => {
                  setIsEdit(true);
                }}
                disabled={isLoading}
              >
                Edit
              </Button>
            )}

            {/*Share Button */}
            {!isEdit && (
              <Button
                className="active-btn ms-3"
                onClick={() => {
                    shareWithClient()
                }}
                disabled={isLoading}
              >
                Share
              </Button>
            )}

            {/* Cancel button */}
            {isEdit && flg == "2" && (
              <Button
                className="active-btn ms-3"
                onClick={() => {
                  getContent();
                  setIsEdit(false);
                }}
                disabled={isLoading}
              >
                Cancel
              </Button>
            )}

            {/* Save Button */}
            {isEdit && (
              <Button
                className="active-btn ms-3"
                onClick={() => {
                  if (flg == "1") addContent();
                  else updateContent();
                }}
                disabled={isLoading}
              >
                Save
              </Button>
            )}

            {/* Download Button */}
            {!isEdit && (
              <Button
                className="active-btn ms-3"
                onClick={() => {}}
                disabled={isLoading}
              >
                Download
              </Button>
            )}
          </div>
        </div>

        {/* file detail*/}
        <div className="contact-detail d-flex mt-4">
          {/* left col */}
          <div
            className={`left-col ${!isEdit ? " w-50" : "w-50 pe-5"} text-start`}
          >
            <Typography variant="body1" gutterBottom>
              <ContactDetailField
                label="Client Name"
                isEdit={isEdit}
                type="text"
                minWidth={"160px"}
                value={formContent?.clientName}
                onChange={(e) => {
                  let values = { ...formContent };
                  values.clientName = e.target.value;
                  setFormContent(values);
                }}
              />
              <ContactDetailField
                label="Origin Address"
                isEdit={isEdit}
                type="text"
                minWidth={"160px"}
                value={formContent?.originAddress}
                onChange={(e) => {
                  let values = { ...formContent };
                  values.originAddress = e.target.value;
                  setFormContent(values);
                }}
              />
              <ContactDetailField
                label="Destination Address"
                isEdit={isEdit}
                type="text"
                minWidth={"160px"}
                value={formContent?.destinationAddress}
                onChange={(e) => {
                  let values = { ...formContent };
                  values.destinationAddress = e.target.value;
                  setFormContent(values);
                }}
              />

              <ContactDetailField
                label="Email Address"
                isEdit={isEdit}
                type="text"
                minWidth={"160px"}
                value={formContent?.clientEmail}
                onChange={(e) => {
                  let values = { ...formContent };
                  values.clientEmail = e.target.value;
                  setFormContent(values);
                }}
              />
            </Typography>
          </div>
          {/* right col */}
          <div
            className={`right-col ${
              !isEdit ? "w-50" : "w-50 ps-5"
            }  d-flex flex-column align-items-end pe-2`}
          >
            <div
              className=""
              style={isEdit ? { width: "-webkit-fill-available" } : {}}
            >
              <Typography
                variant="body1"
                className="text-start w-100"
                gutterBottom
              >
                <ContactDetailField
                  label="Address"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"120px"}
                  value={formContent?.address}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.address = e.target.value;
                    setFormContent(values);
                  }}
                  disabled
                />

                <ContactDetailField
                  label="Email"
                  isEdit={isEdit}
                  type="text"
                  wrapperStyle="justify-content-start"
                  minWidth={"120px"}
                  value={formContent?.email}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.email = e.target.value;
                    setFormContent(values);
                  }}
                />
                <ContactDetailField
                  label="Web"
                  isEdit={isEdit}
                  type="text"
                  wrapperStyle="justify-content-start"
                  minWidth={"120px"}
                  value={formContent?.web}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.web = e.target.value;
                    setFormContent(values);
                  }}
                />
              </Typography>
            </div>
          </div>
        </div>
        {/* /file detail */}

        <div className="mt-5 d-flex justify-content-center flex-wrap align-items-baseline mb-5 bg-white px-2 py-3">
          <div className="">
            <p className="mb-2">
             <b>DECLARATION OF VALUES OF PERSONAL AND HOUSEHOLD EFFECTS TO BE COVERED</b>
            </p>
            <p className="mb-2">
            This form is not an insurance certificate. Insurance must be issued by your agent for coverage to be in effect. 
            </p> <p className="mb-0">
          Please contact your agent for a copy of the insurance certificate.</p>
          </div>
          </div>

        <div className="mt-5 pe-1 d-flex justify-content-start flex-wrap align-items-baseline mb-5">
          <ol className="ps-3 text-start">
            <li className="mb-3">
             Insurance will only cover items listed and valued on this form. The terms and conditions of the insurance certificate, including exclusions, are noted on the last page of this form.
            </li>
            <li className="mb-3">  Miscellaneous items valued at under usd$500.00 may be grouped together. in the event of a claim, grouped items will be averaged. high value items valued at over usd$500.00 per item or set must be specifically declared and valued. failure to comply will limit recovery to a maximum of usd$500.00 per item or set.</li>
            <li className="mb-3">
             You should be aware of the fact that the replacement value of your goods at the final destination might be higher than at origin. insure your shipment accordingly as it will be co-insured if adequate protection has not been secured. all items in the shipment must be insured. any policy that covers only a limited number of items in the shipment may be subject to rejection, co-insurance or an average will apply.
            </li>
            <li className="mb-3">  Do not list jewellery, cash, watches, stamps, negotiable instruments or items of a similar nature as they are excluded from coverage. Additional exclusions are noted on the last page of this form.</li>
            <li className="mb-3"> No one item and/or set can be more than 50% of the total insured value.</li>
            <li className="mb-3"> Use of a separate valued inventory is acceptable to the underwriters. If you have completed another inventory, sign and date this form and attach your personal list.</li>
          </ol>
        </div>

        <Grid container className="pe-1 my-4 ">
          <TableContainer component={Paper} className="pb-3">
            <Table>
              {/* Table Header */}
              <TableHead>
                <TableRow>
                  <TableCell className="table-head min-w-100">
                    Item No.
                  </TableCell>
                  <TableCell className="table-head min-w-400">
                    Goods Description
                  </TableCell>
                  <TableCell className="table-head min-w-100">
                    Condition at Origin
                  </TableCell>
                  <TableCell className="table-head min-w-150">
                    Condition at Destination
                  </TableCell>
                  {/* {isEdit && (
                    <TableCell className="table-head min-w-25"></TableCell>
                  )} */}
                </TableRow>
              </TableHead>
              <TableBody>
                {formContent?.dataRows?.length > 0 ? (
                  <>
                    {formContent?.dataRows.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell> {index + 1}</TableCell>
                        <TableCell>
                          {isEdit ? (
                            <>
                              <Input
                                id="condition"
                                label="Description..."
                                type="text"
                                style="full-border-field"
                                value={row?.description}
                                onChange={(e) => {
                                  const values = { ...formContent };
                                  const valuesRows = [...formContent?.dataRows];
                                  valuesRows[index].description =
                                    e.target.value;
                                  values.dataRows = valuesRows;
                                  setFormContent(values);
                                }}
                                inline={{ width: "-webkit-fill-available" }}
                              />
                            </>
                          ) : row?.description ? (
                            row?.description
                          ) : (
                            "-"
                          )}
                        </TableCell>
                        <TableCell>
                          {isEdit ? (
                            <Input
                              id="condition"
                              label="Condition at origin..."
                              type="text"
                              style="full-border-field"
                              value={row?.conditionAtOrigin}
                              onChange={(e) => {
                                const values = { ...formContent };
                                const valuesRows = [...formContent?.dataRows];
                                valuesRows[index].conditionAtOrigin =
                                  e.target.value;
                                values.dataRows = valuesRows;
                                setFormContent(values);
                              }}
                              inline={{ width: "-webkit-fill-available" }}
                            />
                          ) : row?.conditionAtOrigin ? (
                            row?.conditionAtOrigin
                          ) : (
                            "-"
                          )}
                        </TableCell>
                        <TableCell>
                          {isEdit ? (
                            <Input
                              id="disclamer"
                              label="Condition at destination..."
                              type="text"
                              style="full-border-field"
                              value={row?.conditionAtDestination}
                              onChange={(e) => {
                                const values = { ...formContent };
                                const valuesRows = [...formContent?.dataRows];
                                valuesRows[index].conditionAtDestination =
                                  e.target.value;
                                values.dataRows = valuesRows;
                                setFormContent(values);
                              }}
                              inline={{ width: "-webkit-fill-available" }}
                            />
                          ) : row?.conditionAtDestination ? (
                            row?.conditionAtDestination
                          ) : (
                            "-"
                          )}
                        </TableCell>
                        {/* {isEdit && (
                          <TableCell>
                            <img
                              src={removeImg}
                              height={18}
                              width={18}
                              className="cursor-pointer"
                              onClick={() => handleRemoveDataRows(index)}
                            />
                          </TableCell>
                        )} */}
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <TableRow className="text-center">
                    <TableCell colSpan={6}>
                      {isLoading ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "10px 0px",
                          }}
                        >
                          <CircularProgress
                            style={{
                              color: "#00aff0",
                              height: "25px",
                              width: "25px",
                            }}
                          />
                        </Box>
                      ) : (
                        <div className="d-flex justify-content-center">
                          <p className="my-3">No Records to Show</p>
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* <Row className="mb-5">
          <Col className="col-12 text-end">
            {isEdit && (
              <Button
                className="add-new-btn ms-auto"
                onClick={() => {
                  handleAddNewDataRows();
                }}
                disabled={isLoading}
              >
                Add New
              </Button>
            )}
          </Col>
        </Row> */}

      
        {/* Signatures */}
        {/* {!isEdit && (
          <Row className="mt-5 pt-5">
            <Col sm={6} className="mt-5 pt-5">
              <div style={{ height: "90px" }}></div>
              <p>{"_______________________________"}</p>
              <p className="my-1">
                <b>{"Contractor Carrier OR Authorized Agent at ORIGIN"}</b>
              </p>
              <p className="mt-0">{"(Sign Required)"}</p>
              {""}
            </Col>

            <Col sm={6} className="mt-5 pt-5">
              <div style={{ height: "90px" }}>
                {formContent.contractorSign != "" &&
                  formContent.contractorSign != undefined && (
                    <img
                      src={`${Bucket}${formContent.contractorSign}`}
                      height={80}
                      width={140}
                    />
                  )}
              </div>
              <p>{"_______________________________"}</p>
              <p className="mt-1 mb-1">
                <b>{"Contractor Carrier OR Authorized Agent at DESTINATION"}</b>
              </p>
              <p className="mt-0">{"(Sign Required)"}</p>
              {""}
            </Col>

            <Col sm={6} className="mt-5 pt-5">
              <div style={{ height: "90px" }}>
                {formContent.clientSign != "" &&
                  formContent.clientSign != undefined && (
                    <img
                      src={`${Bucket}${formContent.clientSign}`}
                      height={80}
                      width={140}
                    />
                  )}
              </div>
              <p>{"_______________________________"}</p>
              <p className="mt-1 mb-1">
                <b>{"Client OR Authorized Person"}</b>
              </p>
              <p className="mt-0">{"(Sign Required)"}</p>
              {""}
            </Col>
          </Row>
        )} */}

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000} // Adjust the duration as needed
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={
              snackbarMessage?.includes("successful") ? "success" : "error"
            }
            onClose={handleCloseSnackbar}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>

     
        
      </MainTemplate>
    </>
  );
};
export default InsuranceServicesPage;
