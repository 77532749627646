// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Weight-Ticket-Table {
    width: calc(100vw - 290px);
}

@media screen and (max-width: 992px) {
    .Weight-Ticket-Table {
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/pages/WeightTicketPage/style.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;AAC9B;;AAEA;IACI;QACI,WAAW;IACf;AACJ","sourcesContent":[".Weight-Ticket-Table {\r\n    width: calc(100vw - 290px);\r\n}\r\n\r\n@media screen and (max-width: 992px) {\r\n    .Weight-Ticket-Table {\r\n        width: 100%;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
