// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logout-outer{
    height: 0px !important;
    width: 0px !important;
}

.logout-inner{
    width: 120px;
   padding: 10px 20px;
   transform: translate(-133px, 40px);
   background-color: white;
   font-family: Aspira !important;
   font-size: 16px;
   font-style: normal;
}

.cursor-pointer{
    cursor: pointer !important
}
`, "",{"version":3,"sources":["webpack://./src/components/organisms/Header/style.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,qBAAqB;AACzB;;AAEA;IACI,YAAY;GACb,kBAAkB;GAClB,kCAAkC;GAClC,uBAAuB;GACvB,8BAA8B;GAC9B,eAAe;GACf,kBAAkB;AACrB;;AAEA;IACI;AACJ","sourcesContent":[".logout-outer{\n    height: 0px !important;\n    width: 0px !important;\n}\n\n.logout-inner{\n    width: 120px;\n   padding: 10px 20px;\n   transform: translate(-133px, 40px);\n   background-color: white;\n   font-family: Aspira !important;\n   font-size: 16px;\n   font-style: normal;\n}\n\n.cursor-pointer{\n    cursor: pointer !important\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
