import React, { useState } from "react";
import {
  Container,
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
  Snackbar,
  TextField,
} from "@mui/material";
import LoginForm from "../../molecules/LoginForm";
import "./style.css";
import logo from "../../../imgs/logo-final.png";
import { Link } from "react-router-dom";
import { loginFunc, verify2FA } from "../../../api/services/services";
import MuiAlert from "@mui/material/Alert";
import { connect } from "react-redux";
import { login, logout } from "../../../modules/redux/reducers/authActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import successImg from "../../../imgs/succes.svg";
import useMediaQuery from "@mui/material/useMediaQuery";

const LoginPage = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [verify, setVerify] = useState(false);
  const [token, setToken] = useState("");

  const [digits, setDigits] = useState(["", "", "", "", "", ""]);
  const [success, setSuccess] = useState(false);

  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const inputProps = {
    style: {
      height: "50px", // Set the height to 40 pixels
      width: "50px",
      textAlign: "center",
      fontSize: "20px",
      paddingLeft: "5px",
    },

    maxLength: 1,
  };

  const inputRefs = [
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ];

  const handleChange = (index, value) => {
    if (value.match(/^\d{0,1}$/)) {
      const updatedDigits = [...digits];
      updatedDigits[index] = value;
      setDigits(updatedDigits);

      if(value == "" && index>0) {
        inputRefs[index - 1].current.focus();

      }

      if (index < 5 && value !== "") {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Confirming 2FA
  const confirm2Fa = () => {
    let code = "";
    for (let i = 0; i < digits.length; i++) {
      code = code + digits[i];
    }
    const body = {
      code: code,
    };

    verify2FA(token, body)
      .then((response) => {
        // setSuccess(true);
        setSnackbarMessage("Login successful!");
        setOpenSnackbar(true);
        localStorage.setItem("shelLoginToken", response?.data?.data?.token);
        localStorage.setItem(
          "shelUserData",
          JSON.stringify(response?.data?.data?.admin)
        );

        const currentTime = new Date();
        localStorage.setItem("loginTime", currentTime);
        setTimeout(() => {
          // dispatch(login());
          window.location.pathname = "/";
        }, 500);
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode == 1002) {
          setSnackbarMessage("Token has been expired");
          setOpenSnackbar(true);
        } else {
          setSnackbarMessage(error?.response?.data?.message);
          setOpenSnackbar(true);
        }
      });
  };

  const handleLogin = (formData) => {
    // console.log("Login data:", formData);

    const body = {
      email: formData?.email,
      password: formData?.password,
    };

    loginFunc(body)
      .then((response) => {
        localStorage.setItem("fa-token", response?.data?.data?.token);
        setToken(response?.data?.data?.token);
        setVerify(true);
      })
      .catch((error) => {
        console.error("Login Error", error);
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);
      });
  };

  const isConfirmButtonDisabled =
    digits[0].length == 0 ||
    digits[1].length == 0 ||
    digits[2].length == 0 ||
    digits[3].length == 0 ||
    digits[4].length == 0 ||
    digits[5].length == 0;

  return (
    <div className="login-bg">
      <Container
        maxWidth="xs"
        className="main-container"
      >
        {!verify ? (
          <Card
            style={{ borderRadius: "15px" }}
            className="login-card mx-1 pt-0 pt-md-0 pb-1 pb-md-2 "
          >
            <CardContent>
              <img
                src={logo}
                alt="Shezlon Logo"
                width="260"
                height="70"
                className="mt-2"
              //  style={{transform: 'translate(0px , 15px)'}}
              />
              <Typography variant="p" align="center" gutterBottom>
                <p className="title p-0 mb-1 mt-3"  style={{position: 'relative',
                zIndex: '999'}}>Login to Continue</p>
              </Typography>
              <Typography variant="p" align="center" gutterBottom>
                <p className="desc mb-4 mb-md-5">
                  {" "}
                  Enter credentials to continue
                </p>
              </Typography>
              <LoginForm onSubmit={handleLogin} />

              <Link to="/forget-password" className="forget">
                {" "}
                Forget Password ?
              </Link>
            </CardContent>
          </Card>
        ) : (
          <>
            {success == false ? (
              <Card
                style={{ borderRadius: "15px" }}
                className="fa-card py-2 pb-0"
              >
                <CardContent>
                  <Typography variant="p" align="center" gutterBottom>
                    <p className="title p-0 mb-1">Enable Authenticator</p>
                  </Typography>
                  <Typography variant="p" align="center" gutterBottom>
                    <p className="desc mb-3 mb-md-5">
                      Authenticator app should be installed on your device to
                      use 2FA
                    </p>
                  </Typography>
                  <Typography variant="p" align="center" gutterBottom>
                    <p className="mb-3 text-capitalize">
                      <b> ENTER THE 6 DIGIT CODE</b>
                    </p>
                  </Typography>
                  <div className="d-flex align-items-center justify-content-center mb-3 test">
                    {digits.map((digit, index) => (
                      <TextField
                        key={index}
                        inputRef={inputRefs[index]}
                        variant="outlined"
                        size="small"
                        type="text"
                        value={digit}
                        onChange={(e) => handleChange(index, e.target.value)}
                        style={{
                          margin: isSmallScreen ? "5px" : "10px",
                          width: isSmallScreen ? "40px" : "50px",
                          height: isSmallScreen ? "40px" : "50px",
                          textAlign: "center",
                          fontSize: isSmallScreen ? "18px" : "20px",
                         
                        }}
                      />
                    ))}
                  </div>

                  <Grid item xs={12}>
                    <Button
                      type="button"
                      variant="contained"
                      className={
                        isConfirmButtonDisabled
                          ? "disabled-btn mt-3 mb-3 text-capitalize"
                          : "submit-btn mt-3 mb-3 text-capitalize"
                      }
                      onClick={confirm2Fa}
                      disabled={isConfirmButtonDisabled}
                    >
                      Confirm
                    </Button>
                  </Grid>
                </CardContent>
              </Card>
            ) : (
              <Card
                sx={{py: 3, pb: 1 }}
                style={{ borderRadius: "15px" }}
                className="success-card mx-2"
              >
                <CardContent>
                  <img
                    src={successImg}
                    alt="success"
                    width="84"
                    height="84"
                    className="mb-4"
                  />
                  <Typography variant="p" align="center" gutterBottom>
                    <h4 className="title p-0 mb-1 text-capitalize">
                      Verified successfully
                    </h4>
                  </Typography>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      className="submit-btn mt-5 mb-3 text-capitalize"
                      onClick={() => {
                        dispatch(login());
                        navigate("/");
                      }}
                    >
                      Continue
                    </Button>
                  </Grid>
                </CardContent>
              </Card>
            )}
          </>
        )}
      </Container>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={
            snackbarMessage?.includes("successful") ? "success" : "error"
          }
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = {
  login,
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
