// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiInputBase-input::placeholder {
    font-size: 12px; /* Adjust the font size as needed */
  }

  .form-check-input:checked[type=checkbox] {
    background-color: #00aff0 !important;
  }
`, "",{"version":3,"sources":["webpack://./src/components/atoms/style.css"],"names":[],"mappings":"AAAA;IACI,eAAe,EAAE,mCAAmC;EACtD;;EAEA;IACE,oCAAoC;EACtC","sourcesContent":[".MuiInputBase-input::placeholder {\n    font-size: 12px; /* Adjust the font size as needed */\n  }\n\n  .form-check-input:checked[type=checkbox] {\n    background-color: #00aff0 !important;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
