import React, { useEffect, useState } from "react";
import {
  getFormContent,
  getSingleSurvey,
  updateFormContent,
  surveyResultPreview,
  sharePreviewLink,
  addFormContent,
  imageUpload,
} from "../../../api/services/services";
import backArrow from "../../../imgs/BackArrow.svg";
import MainTemplate from "../../templates/MainTemplate";
// import "./style.css";
import { useNavigate } from "react-router-dom";
import { Button, Snackbar, Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import { Bucket } from "../../../api/config/apiConfig";
import ContactDetailField from "../../molecules/ContactDetailField";
import TextArea from "../../atoms/Textarea";
import DynamicCheckbox from "../../molecules/DynamicCheckbox";
import html2pdf from "html2pdf.js";
import { useParams } from "react-router-dom";
import PrintComponent from "../PrintComponent";
import DeliveryRequestDownload from "./ClientSide/download";
import editIcn from "../../../imgs/edit.png";
import trashIcn from "../../../imgs/trash.png";
import LoadingModal from "../../molecules/LoadingModal";
import moment from "moment";
import MySignatureCanvas from "../../molecules/signature_canvas";
import ShareWithClientsModal from "../../molecules/ShareWithClients";
import { Margin } from "react-to-pdf";
import { sendSuperAdminVerificationForEdit } from "../PrebidAgreement/creation/service";
import SuperAdminVerifationModal from "../PrebidAgreement/creation/super_admin_verification_modal";

const DeliveryRequestPage = () => {
  const { surveyId, id, flg } = useParams();
  const [surveyData, setSurveyData] = useState(null);
  const navigate = useNavigate();

  const [formContentId, setFormContentId] = useState("");
  const [isEdit, setIsEdit] = useState(flg == "1" ? true : false);

  const [isLoading, setIsLoading] = useState(false);
  const [formContent, setFormContent] = useState({
    clientName: "",
    clientEmail: "",
    clientSign: "",
    date: "",
    deliveryAddress: "",
    deliveryDate: "",
    deliveryTime: "",
    permissions: false,
    adviseRequirement: "",
    firstPriority: "",
    secondPriority: "",
    thirdPriority: "",
    suitableInfo: false,
    suggestions: "",
    officeInstructions: "",
    adminSign: "",
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [isShare, setIsShare] = useState(false);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  //
  const getSurveyPreview = () => {
    surveyResultPreview(surveyId)
      .then((response) => {
        setSurveyData(response?.data?.data?.surveyDetail);
        let values = { ...formContent };
        values.deliveryAddress = `${response?.data?.data?.surveyDetail?.unloadPlaceStAddress}, ${response?.data?.data?.surveyDetail?.unloadPlaceCity}, ${response?.data?.data?.surveyDetail?.unloadPlaceCountry}`;
        values.clientName = `${response?.data?.data?.surveyDetail?.name}`;
        values.clientEmail = `${response?.data?.data?.surveyDetail?.email}`;

        setFormContent(values);
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }
      });
  };

  //
  const getSurvey = () => {
    getSingleSurvey(surveyId)
      .then((response) => {
        if (response?.data?.data?.status == "submitted") getSurveyPreview();
        else {
          setSurveyData(response?.data?.data);
          let values = { ...formContent };
          values.deliveryAddress = `${response?.data?.data?.unloadPlaceStAddress}, ${response?.data?.data?.unloadPlaceCity}, ${response?.data?.data?.unloadPlaceCountry}`;
          values.clientName = `${response?.data?.data?.name}`;
          values.clientEmail = `${response?.data?.data?.email}`;
          setFormContent(values);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }
      });
  };

  const [uploadText, setUploadText] = useState("Upload Sign");

  const [mediaKeysToSend, setMediaKeysToSend] = useState([]);
  const [mediaKeysToRemove, setMediaKeysToRemove] = useState([]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //
  const addContent = () => {
    setIsLoading(true);
    const body = {
      formId: id,
      surveyId: surveyId,
      content: formContent,
      mediaKeys: mediaKeysToSend,
    };
    addFormContent(body)
      .then(() => {
        setSnackbarMessage(
          "Delivery Request Form has been added successfully!"
        );
        setOpenSnackbar(true);

        setTimeout(() => {
          navigate(-1);
          setIsLoading(false);
        }, 1000);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  //
  const updateContent = () => {
    setIsLoading(true);
    const body = {
      formId: id,
      surveyId: surveyId,
      content: formContent,
      mediaKeys: mediaKeysToSend,
      removedMediaKeys: mediaKeysToRemove,
    };
    updateFormContent(body, formContentId)
      .then((res) => {
        setSnackbarMessage(
          "Delivery Request Form has been updated successfully!"
        );
        setOpenSnackbar(true);
        getContent();
        setIsEdit(false);

        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  //
  const getContent = () => {
    setIsLoading(true);
    getFormContent(id, surveyId)
      .then((res) => {
        setFormContentId(res.data.data.uuid);
        setFormContent(res.data.data.content);
        setIsLoading(false);
        setMediaKeysToSend([]);
        setMediaKeysToRemove([]);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
        setMediaKeysToSend([]);
        setMediaKeysToRemove([]);
      });
  };

  useEffect(() => {
    if (flg == "1") getSurvey();
    else getContent();
  }, []);

  const [show, setShow] = useState(false);
  const options = {
    // resolution: Resolution.HIGH,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      format: "letter",
      // default is 'portrait'
      orientation: "portrait",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/png",
      qualityRatio: 1,
    },
    overrides: {
      pdf: {
        compress: true,
      },
    },
  };

  //
  const generatePdf = () => {
    setShow(true);

    setTimeout(() => {
      const content = document.getElementById("content-to-convert"); // Replace with the ID of the element you want to convert to PDF

      const currentDateTime = new Date();
      const filename = `survey_${currentDateTime.toISOString()}.pdf`;

      const pdfOptions = {
        margin: 5,
        filename: filename,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
        }, // Set the page size here
      };

      let temp = html2pdf().from(content).set(pdfOptions).outputPdf();
      temp.save(filename);

      setShow(false);
    }, 3500);
  };

  const [downloadText, setDownloadText] = useState("download");

  const getTargetElement = () => document.getElementById("content-id");

  //
  const shareWithClient = (data) => {
    let ems = [];
    let emsInd = 0;
    data.map((dt) => {
      if (dt.trim().length != 0) {
        ems[emsInd] = dt;
        emsInd++;
      }
    });
    setIsLoading(true);
    const body = {
      surveyId: surveyId,
      formId: id,
      emails: ems,
      formRoute: "delivery-request-form",
    };
    sharePreviewLink(body)
      .then(() => {
        setSnackbarMessage(
          "Delivery Request Form has been shared with client successfully!"
        );
        setOpenSnackbar(true);

        setIsLoading(false);

        setIsShare(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  const printAction = () => {
    const PAGE_HEIGHT = 1045;
    const printElement = document.getElementById("print-component");
    if (printElement) {
      printElement.classList.add("temp-class-for-height");
      const height = printElement.clientHeight - 500;
      const numberOfPage = Math.ceil(height / PAGE_HEIGHT);
      const heightWithSingleHeader = numberOfPage * PAGE_HEIGHT;
      let requiredHeight = heightWithSingleHeader;
      if (numberOfPage > 1) {
        const headerHeight =
          printElement.getElementsByTagName("thead")?.[0]?.clientHeight;
        const footerHeight =
          printElement.getElementsByTagName("tfoot")?.[0]?.clientHeight;
        requiredHeight -= (numberOfPage - 1) * (headerHeight + footerHeight);
      }
      printElement.style.height = `${requiredHeight}px`;
      printElement.classList.remove("temp-class-for-height");
    }
    window.print();
    if (printElement) {
      printElement.style.height = `auto`;
    }
  };

  //
  const uploadFile = async (file) => {
    setIsLoading(true);

    setUploadText("Uploading...");
    handleOpen();
    const fd = new FormData();
    fd.append("image", file);

    imageUpload(fd)
      .then((res) => {
        let values = { ...formContent };

        if (
          formContent.adminSign != null &&
          formContent.adminSign?.trim()?.length != 0
        ) {
          let dlt = [...mediaKeysToRemove];
          dlt.push(formContent.adminSign);
          setMediaKeysToRemove(dlt);
        }

        values.adminSign = res?.data?.data?.key;
        setFormContent(values);

        let md = [...mediaKeysToSend];
        md.push(res?.data?.data?.key);
        setMediaKeysToSend(md);

        setIsLoading(false);
        setUploadText("Upload Sign");
        handleClose();
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setUploadText("Upload Sign");

        setIsLoading(false);
        handleClose();
      });
  };

  const [isSignCanvas, setSignCanvas] = useState(false);

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // 11th, 12th, 13th, etc.
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  // Helper function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const ordinalSuffix = getOrdinalSuffix(day);

    return `${day}${ordinalSuffix} ${month}, ${year}`;
  };

  const [isVerified, setIsVerified] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);

  const sendEditRequestToAdmin = () => {
    setIsEditLoading(true);
    sendSuperAdminVerificationForEdit()
      .then((response) => {
        setIsVerified(true);
        setIsEditLoading(false);
      })
      .catch((error) => {
        setIsVerified(false);
        setIsEditLoading(false);
      });
  };

  return (
    <>
      <MainTemplate>
        <div className="no-print">
          <div className="d-block d-sm-flex align-items-center py-4">
            <div className="d-flex align-items-center ">
              <div
                className="light-blue-color back-bg cursor-pointer"
                onClick={() => navigate(-1)}
              >
                <img src={backArrow} height="24" width="24" />{" "}
              </div>
              <div>
                <p className="main-head ps-3 mb-0">Delivery Request Form</p>
              </div>
            </div>
            <div className="d-flex ms-auto justify-content-center mt-2 mt-sm-0">
              {/*Edit Button */}
              {/*Edit Button */}
              {!isEdit && (
                <Button
                  className="active-btn"
                  onClick={() => {
                    sendEditRequestToAdmin();
                  }}
                  disabled={isEditLoading}
                >
                  Edit
                </Button>
              )}

              {/*Share Button */}
              {!isEdit && (
                <Button
                  className="active-btn ms-3"
                  onClick={() => {
                    if (formContent?.officeInstructions?.trim()?.length == 0) {
                      setSnackbarMessage(
                        "Please first fill in the office instructions"
                      );
                      setOpenSnackbar(true);
                    } else setIsShare(true);
                  }}
                  disabled={isLoading}
                >
                  Share
                </Button>
              )}

              {/* Cancel button */}
              {isEdit && flg == "2" && (
                <Button
                  className="active-btn ms-3"
                  onClick={() => {
                    getContent();
                    setIsEdit(false);
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
              )}

              {/* Save Button */}
              {isEdit && (
                <Button
                  className="active-btn ms-3"
                  onClick={() => {
                    if (flg == "1") addContent();
                    else updateContent();
                  }}
                  disabled={isLoading}
                >
                  Save
                </Button>
              )}

              {/* Download Button */}
              {/* Download Button */}
              {!isEdit && (
                <Button
                  className="active-btn ms-3 px-0"
                  onClick={() => {
                    setIsLoading(true);
                    // printAction();
                    var tempTitle = document.title;
                    document.title = `Delivery Request-${
                      formContent?.clientName
                    }-${moment(new Date()).format("DD.MM.YYYY")}`;

                    window.print();
                    document.title = tempTitle;
                    setIsLoading(false);
                  }}
                  disabled={isLoading}
                >
                  {"Save as PDF"}
                </Button>
              )}
            </div>
          </div>

          {/* customer detail*/}
          <div className={`${isEdit ? "p-3 px-0 rounded mb-5" : ""}`}>
            <div className="contact-detail d-flex mt-2">
              {/* left col */}
              <div className={`left-col w-50 text-start`}>
                <Typography variant="body1" gutterBottom>
                  <ContactDetailField
                    label="Customer Name"
                    isEdit={isEdit}
                    type="text"
                    value={formContent?.clientName}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.clientName = e.target.value;
                      setFormContent(values);
                    }}
                    minWidth={"120px"}
                  />
                  <ContactDetailField
                    label="Date"
                    isEdit={isEdit}
                    type="date"
                    value={formContent?.date}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.date = e.target.value;
                      setFormContent(values);
                    }}
                    minWidth={"120px"}
                  />
                </Typography>
              </div>
            </div>
          </div>
          {/* /customer detail */}

          {/*  Delivery Address:*/}
          <div className="mt-5 pe-1 ">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="mb-2">
                <label
                  htmlFor="deliveryAddress"
                  className={`fw-bold ${isEdit ? "mb-3" : "mb-1"}`}
                >
                  Delivery Address:
                </label>
                {!isEdit ? (
                  <p>
                    {formContent?.deliveryAddress
                      ? formContent?.deliveryAddress
                      : "-"}
                  </p>
                ) : (
                  <TextArea
                    rows="3"
                    label="Delivery Address..."
                    id="deliveryAddress"
                    value={formContent?.deliveryAddress}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.deliveryAddress = e.target.value;
                      setFormContent(values);
                    }}
                  />
                )}
              </div>
            </Typography>
          </div>
          {/* / Delivery Address */}

          <div className="d-flex justify-content-start mt-5 pe-1">
            <p className="mb-2">
              <b>On which date and time you want to receive your goods?</b>
            </p>
          </div>

          {/*  Date & Time:*/}
          <div className="mt-0 pe-1 d-flex">
            {isEdit ? (
              <>
                <div
                  className={`left-col ${
                    !isEdit ? " w-50" : "w-50"
                  } text-start`}
                >
                  <Typography
                    variant="body1"
                    gutterBottom
                    style={{ width: "80%" }}
                  >
                    <ContactDetailField
                      label="Date"
                      isEdit={isEdit}
                      type="date"
                      value={formContent?.deliveryDate}
                      onChange={(e) => {
                        let values = { ...formContent };
                        values.deliveryDate = e.target.value;
                        setFormContent(values);
                      }}
                      minWidth={"125px"}
                    />
                  </Typography>
                </div>
                <div
                  className={`right-col ${
                    !isEdit ? "w-50 pe-5 " : "w-50 pe-2"
                  }  d-flex flex-column align-items-end justify-content-end text-end`}
                >
                  <Typography
                    variant="body1"
                    className="text-start "
                    gutterBottom
                    style={{ width: "80%" }}
                  >
                    <ContactDetailField
                      label="Time"
                      isEdit={isEdit}
                      type="time"
                      value={formContent?.deliveryTime}
                      onChange={(e) => {
                        let values = { ...formContent };
                        values.deliveryTime = e.target.value;
                        setFormContent(values);
                      }}
                      minWidth={"125px"}
                    />
                  </Typography>
                </div>
              </>
            ) : (
              <>
                <p className="mb-1">
                  {formatDate(formContent?.deliveryDate)} {" - "}{" "}
                  {formContent?.deliveryTime}
                </p>
              </>
            )}
          </div>
          {/* / Date & Time */}

          {/* permissions */}
          <div className="mt-5 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <DynamicCheckbox
                label="Is there any vehicle/crew permission/security access/permit required of our team/vehicle at your residence?"
                checkId1="check1"
                checkLabel1="Yes"
                checkId2="check2"
                checkLabel2="No"
                value1={formContent?.permissions}
                value2={formContent?.permissions}
                onChange1={(e) => {
                  let values = { ...formContent };
                  values.permissions = true;
                  setFormContent(values);
                }}
                onChange2={(e) => {
                  let values = { ...formContent };
                  values.permissions = false;
                  setFormContent(values);
                }}
                disabled={!isEdit ? true : false}
              />
            </Typography>
          </div>
          {/* /permissions */}

          {/*  If yes, please advise requirement::*/}
          {formContent?.permissions && (
            <div className="mt-5 pe-1 ">
              <Typography variant="body1" className="text-start" gutterBottom>
                <div className="mb-2">
                  <label
                    htmlFor="advise"
                    className={`fw-bold ${isEdit ? "mb-3" : "mb-1"}`}
                  >
                    If yes, please advise requirement::
                  </label>
                  {!isEdit ? (
                    <p>
                      {formContent?.adviseRequirement
                        ? formContent?.adviseRequirement
                        : "-"}
                    </p>
                  ) : (
                    <TextArea
                      rows="3"
                      label="If yes, please advise requirement..."
                      id="advise"
                      value={formContent?.adviseRequirement}
                      onChange={(e) => {
                        let values = { ...formContent };
                        values.adviseRequirement = e.target.value;
                        setFormContent(values);
                      }}
                    />
                  )}
                </div>
              </Typography>
            </div>
          )}
          {/* / If yes, please advise requirement: */}

          {/*  1st priority items to receive according to the packing list*/}
          <div className="mt-5 pe-1 ">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="mb-2">
                <label
                  htmlFor="firstPriority"
                  className={`fw-bold ${isEdit ? "mb-3" : "mb-1"}`}
                >
                  1st priority items to receive according to the packing list:
                </label>
                {!isEdit ? (
                  <p>
                    {formContent?.firstPriority
                      ? formContent?.firstPriority
                      : "-"}
                  </p>
                ) : (
                  <TextArea
                    rows="3"
                    label="1st priority items to receive according to the packing list..."
                    id="firstPriority"
                    value={formContent?.firstPriority}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.firstPriority = e.target.value;
                      setFormContent(values);
                    }}
                  />
                )}
              </div>
            </Typography>
          </div>
          {/* / 1st priority items to receive according to the packing list */}

          {/*  2nd priority items to receive according to the packing list*/}
          <div className="mt-5 pe-1 ">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="mb-2">
                <label
                  htmlFor="secondPriority"
                  className={`fw-bold ${isEdit ? "mb-3" : "mb-1"}`}
                >
                  2nd priority items to receive according to the packing list:
                </label>
                {!isEdit ? (
                  <p>
                    {formContent?.secondPriority
                      ? formContent?.secondPriority
                      : "-"}
                  </p>
                ) : (
                  <TextArea
                    rows="3"
                    label="2nd priority items to receive according to the packing list..."
                    id="secondPriority"
                    value={formContent?.secondPriority}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.secondPriority = e.target.value;
                      setFormContent(values);
                    }}
                  />
                )}
              </div>
            </Typography>
          </div>
          {/* / 2nd priority items to receive according to the packing list */}

          {/*  3rd priority items to receive according to the packing list*/}
          <div className="mt-5 pe-1 ">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="mb-2">
                <label
                  htmlFor="thirdPriority"
                  className={`fw-bold ${isEdit ? "mb-3" : "mb-1"}`}
                >
                  3rd priority items to receive according to the packing list:
                </label>
                {!isEdit ? (
                  <p>
                    {formContent?.thirdPriority
                      ? formContent?.thirdPriority
                      : "-"}
                  </p>
                ) : (
                  <TextArea
                    rows="3"
                    label="3rd priority items to receive according to the packing list..."
                    id="thirdPriority"
                    value={formContent?.thirdPriority}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.thirdPriority = e.target.value;
                      setFormContent(values);
                    }}
                  />
                )}
              </div>
            </Typography>
          </div>
          {/* / 3rd priority items to receive according to the packing list */}

          {/* Residence rooms*/}
          <div className="mt-5 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <DynamicCheckbox
                label="Residence’s rooms are marked / labeled with suitable Info/Numbers/Colors to place items in."
                checkId1="check1"
                checkLabel1="Yes"
                checkId2="check2"
                checkLabel2="No"
                value1={formContent?.suitableInfo}
                value2={formContent?.suitableInfo}
                onChange1={(e) => {
                  let values = { ...formContent };
                  values.suitableInfo = true;
                  setFormContent(values);
                }}
                onChange2={(e) => {
                  let values = { ...formContent };
                  values.suitableInfo = false;
                  setFormContent(values);
                }}
                disabled={!isEdit ? true : false}
              />
            </Typography>
          </div>
          {/* Residence rooms */}

          {/*  Customer Suggestions/Remarks if any:*/}
          <div className="mt-5 pe-1 ">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="mb-2">
                <label
                  htmlFor="suggestion"
                  className={`fw-bold ${isEdit ? "mb-3" : "mb-1"}`}
                >
                  Customer Suggestions/Remarks if any:
                </label>
                {!isEdit ? (
                  <p>
                    {formContent?.suggestions ? formContent?.suggestions : "-"}
                  </p>
                ) : (
                  <TextArea
                    rows="3"
                    label="Customer Suggestions/Remarks if any..."
                    id="suggestion"
                    value={formContent?.suggestions}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.suggestions = e.target.value;
                      setFormContent(values);
                    }}
                  />
                )}
              </div>
            </Typography>
          </div>
          {/* / Customer Suggestions/Remarks if any */}

          {/*  Office Instructions/remarks:*/}
          <div className="my-5 pe-1 ">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="mb-2">
                <label
                  htmlFor="instructions"
                  className={`fw-bold ${isEdit ? "mb-3" : "mb-1"}`}
                >
                  Office Instructions/remarks:
                </label>
                {!isEdit ? (
                  <p>
                    {formContent?.officeInstructions
                      ? formContent?.officeInstructions
                      : "-"}
                  </p>
                ) : (
                  <TextArea
                    rows="3"
                    label="Office Instructions/remarks..."
                    id="instructions"
                    value={formContent?.officeInstructions}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.officeInstructions = e.target.value;
                      setFormContent(values);
                    }}
                  />
                )}
              </div>
            </Typography>
          </div>
          {/* / Office Instructions/remarks */}

          {/* Signatures */}
          <Row className="mt-5 pt-5">
            <Col sm={6} className="mt-5 pt-5">
              <div
                style={{ height: "90px" }}
                className="d-flex justify-content-center align-items-end"
              >
                {formContent.clientSign != "" &&
                  formContent.clientSign != undefined && (
                    <img
                      src={`${Bucket}${formContent.clientSign}`}
                      height={80}
                      width={200}
                    />
                  )}
              </div>
              <p>{"_______________________________"}</p>
              <p className="my-1">
                <b>{"Customer/Authorized"}</b>
              </p>

              {""}
            </Col>
            <Col sm={6} className="mt-5 pt-5">
              <div
                style={{ height: "90px" }}
                className="d-flex justify-content-center align-items-end"
              >
                {formContent?.adminSign?.trim()?.length == 0 ? (
                  <>
                    {isEdit && (
                      <div className="d-flex justify-content-center align-items-end">
                        <label
                          htmlFor="uploadSign"
                          className="active-btn ms-2 w-200 d-flex align-items-center justify-content-center cursor-pointer btn"
                        >
                          {uploadText}
                        </label>
                        <input
                          type="button"
                          hidden
                          id="uploadSign"
                          name="uploadSign"
                          onClick={() => {
                            setSignCanvas(true);
                          }}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="d-flex align-items-start">
                    <img
                      src={`${Bucket}${formContent.adminSign}`}
                      height={80}
                      width={200}
                    />
                    {isEdit && (
                      <>
                        <label
                          htmlFor="editSign"
                          className=" ms-2  d-flex align-items-center justify-content-center cursor-pointer btn p-0"
                        >
                          <img
                            src={editIcn}
                            height={18}
                            width={18}
                            className="ms-2 cursor-pointer"
                          />
                        </label>
                        <input
                          type="button"
                          hidden
                          id="editSign"
                          name="uploadSign"
                          onClick={() => {
                            setSignCanvas(true);
                          }}
                        />
                        <img
                          src={trashIcn}
                          height={18}
                          width={18}
                          className="ms-2 cursor-pointer"
                          onClick={() => {
                            let keysToDelete = [...mediaKeysToRemove];
                            keysToDelete.push(formContent.adminSign);
                            setMediaKeysToRemove(keysToDelete);

                            let values = { ...formContent };
                            values.adminSign = "";
                            setFormContent(values);
                          }}
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
              <p>{"_______________________________"}</p>
              <p className="my-1">
                <b>{"Shelozon Movers Packers & Logistics Pvt Ltd."}</b>
              </p>

              {""}
            </Col>
          </Row>
        </div>
        <PrintComponent>
          <DeliveryRequestDownload
            surveyData={surveyData}
            formContent={formContent}
          />
        </PrintComponent>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000} // Adjust the duration as needed
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={
              snackbarMessage?.includes("successful") ? "success" : "error"
            }
            onClose={handleCloseSnackbar}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>

        <LoadingModal open={open} handleClose={handleClose} />

        {/* Add signature Modal */}
        {isSignCanvas && (
          <MySignatureCanvas
            open={isSignCanvas}
            onClose={() => {
              setSignCanvas(false);
            }}
            onSuccess={(file) => {
              if (!isLoading) uploadFile(file);
            }}
          />
        )}

        {/* Add Role Modal */}
        {isShare && (
          <ShareWithClientsModal
            open={isShare}
            onClose={() => {
              setIsShare(false);
            }}
            onSuccess={(emails) => {
              shareWithClient(emails);
            }}
          />
        )}

        {isVerified && (
          <SuperAdminVerifationModal
            open={isVerified}
            onClose={() => {
              setIsVerified(false);
            }}
            onSuccess={() => {
              setIsEdit(true);
              setIsVerified(false);
            }}
            onError={() => {
              // shareWithClient(emails);
              setSnackbarMessage("Invalid Confirm Pin");
              setOpenSnackbar(true);
            }}
          />
        )}
      </MainTemplate>
    </>
  );
};
export default DeliveryRequestPage;
