import React, { useEffect, useState } from "react";
import {
  updateFormContentPreview,
  getFormContentPreview,
  imageUploadClientPreview,
} from "../../../../api/services/services";
import { Button, Snackbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import Input from "../../../atoms/Input";
import ContactDetailField from "../../../molecules/ContactDetailField";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import html2pdf from "html2pdf.js";
import DynamicText from "../../../molecules/DynamicText";
import { useLocation } from "react-router-dom";
import PrintComponent from "../../PrintComponent";
import WorksheetImportDownload from "./download";
import { Bucket } from "../../../../api/config/apiConfig";
import moment from "moment";
import MySignatureCanvas from "../../../molecules/signature_canvas";

const WorksheetImportPageClientSide = () => {
  const [formContent, setFormContent] = useState({});
  const [formContentId, setFormContentId] = useState("");

  const [surveyData, setSurveyData] = useState([]);

  const [isEdit, setIsEdit] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [mediaKeysToSend, setMediaKeysToSend] = useState([]);

  const location = useLocation();
  const urlData = new URLSearchParams(location.search);
  const email = urlData.get("email");
  const surveyId = urlData.get("surveyId");
  const formId = urlData.get("formId");
  const token = urlData.get("token");

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  //
  const updateContent = (data) => {
    setIsLoading(true);

    let media = [];
    if (formContent?.clientSign != "") media[0] = formContent?.clientSign;

    const body = {
      formContentId: formContentId,
      content: data ? data : formContent,
      mediaKeys: mediaKeysToSend,
      removedMediaKeys: [],
    };
    updateFormContentPreview(
      surveyId,
      formId,
      email,
      token,
      body,
      formContentId
    )
      .then((res) => {
        if (data != undefined)
          setSnackbarMessage("Signature has been added successfully!");
        else
          setSnackbarMessage(
            "Delivery Request Form has been updated successfully!"
          );

        setOpenSnackbar(true);
        getContent();
        setIsEdit(false);

        setIsLoading(false);
        setMediaKeysToSend([]);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          setIsValid(false);
        }

        setIsLoading(false);
      });
  };

  const [isValid, setIsValid] = useState(false);

  //
  const getContent = () => {
    setIsLoading(true);
    setMediaKeysToSend([]);
    getFormContentPreview(surveyId, formId, email, token)
      .then((res) => {
        setIsValid(true);
        setFormContentId(res.data.data.uuid);
        setFormContent(res.data.data.content);

        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          setIsValid(false);
        }

        setIsLoading(false);
      });
  };

  useEffect(() => {
    getContent();
  }, []);

  const printAction = () => {
    const PAGE_HEIGHT = 1045;
    const printElement = document.getElementById("print-component");
    if (printElement) {
      printElement.classList.add("temp-class-for-height");
      const height = printElement.clientHeight - 500;
      const numberOfPage = Math.ceil(height / PAGE_HEIGHT);
      const heightWithSingleHeader = numberOfPage * PAGE_HEIGHT;
      let requiredHeight = heightWithSingleHeader;
      if (numberOfPage > 1) {
        const headerHeight =
          printElement.getElementsByTagName("thead")?.[0]?.clientHeight;
        const footerHeight =
          printElement.getElementsByTagName("tfoot")?.[0]?.clientHeight;
        requiredHeight -= (numberOfPage - 1) * (headerHeight + footerHeight);
      }
      printElement.style.height = `${requiredHeight}px`;
      printElement.classList.remove("temp-class-for-height");
    }
    window.print();
    if (printElement) {
      printElement.style.height = `auto`;
    }
  };

  const [uploadText1, setUploadText1] = useState("Upload Sign");
  const [uploadText2, setUploadText2] = useState("Upload Sign");

  const [isSignCanvas, setSignCanvas] = useState(false);
  const [whichSign, setWhichSign] = useState("");

  //
  const uploadFile = async (file) => {

    setIsLoading(true);

    if (whichSign == "manager") setUploadText1("Uploading...");
    if (whichSign == "assist") setUploadText2("Uploading...");

    const fd = new FormData();
    fd.append("image", file);

    imageUploadClientPreview(fd, surveyId, formId, email, token)
      .then((res) => {
        let values = { ...formContent };
        if (whichSign == "manager") values.managerSign = res?.data?.data?.key;
        if (whichSign == "assist") values.assistOperSign = res?.data?.data?.key;
        setFormContent(values);

        let md = [...mediaKeysToSend];
        md.push(res?.data?.data?.key);
        setMediaKeysToSend(md);

        setIsLoading(false);

        setTimeout(() => {
          updateContent(values);
        }, 2000);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setUploadText1("Upload Sign");
        setUploadText2("Upload Sign");

        setIsLoading(false);
      });

  };

  return (
    <>
      {isValid ? (
        <div className="mx-3 mx-md-5 no-print" style={{ minHeight: "100vh" }}>
          <div>
            <div className="d-flex align-items-center py-4">
              <p className="main-head ps-0 mb-0 w-50 text-end">
                Work Sheet Import
              </p>
              <div className="d-flex ms-auto">
                {!isEdit && (
                  <Button
                    className="active-btn ms-3 px-0"
                    onClick={() => {
                      setIsLoading(true);
                      // printAction();
                      var tempTitle = document.title;
                      document.title = `Work Sheet Import-${formContent?.customerName
                        }-${moment(new Date()).format("DD.MM.YYYY")}`;

                      window.print();
                      document.title = tempTitle;
                      setIsLoading(false);
                    }}
                    disabled={isLoading}
                  >
                    {"Save as PDF"}
                  </Button>
                )}
              </div>
            </div>
          
          </div>
          {/* file detail*/}
          <div className="contact-detail d-flex mt-4">
            {/* left col */}
            <div
              className={`left-col ${!isEdit ? " w-50" : "w-50 pe-5"
                } text-start`}
            >
              <Typography variant="body1" gutterBottom>
                <ContactDetailField
                  label="Name"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"150px"}
                  value={formContent?.customerName}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.customerName = e.target.value;
                    setFormContent(values);
                  }}
                />
                <ContactDetailField
                  label="Organization"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"150px"}
                  value={formContent?.organization}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.organization = e.target.value;
                    setFormContent(values);
                  }}
                />
                <ContactDetailField
                  label="Delivery Address"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"150px"}
                  value={formContent?.deliveryAddres}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.deliveryAddres = e.target.value;
                    setFormContent(values);
                  }}
                />
              </Typography>
            </div>
            {/* right col */}
            <div
              className={`right-col ${!isEdit ? "w-50" : "w-50 ps-5"
                }  d-flex flex-column align-items-end pe-2`}
            >
              <div
                className=""
                style={isEdit ? { width: "-webkit-fill-available" } : {}}
              >
                <Typography
                  variant="body1"
                  className="text-start w-100"
                  gutterBottom
                >
                  <ContactDetailField
                    label="File No. "
                    isEdit={isEdit}
                    type="text"
                    wrapperStyle="justify-content-start"
                    minWidth={"150px"}
                    value={formContent?.fileNo}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.fileNo = e.target.value;
                      setFormContent(values);
                    }}
                    disabled={true}
                  />
                  <ContactDetailField
                    label="Contact No"
                    isEdit={isEdit}
                    type="number"
                    wrapperStyle="justify-content-start"
                    minWidth={"150px"}
                    value={
                      formContent?.contactNo != "null" &&
                        formContent?.contactNo != undefined
                        ? formContent?.contactNo
                        : ""
                    }
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.contactNo = e.target.value;
                      setFormContent(values);
                    }}
                  />
                  <ContactDetailField
                    label="Fax"
                    isEdit={isEdit}
                    type="number"
                    wrapperStyle="justify-content-start"
                    minWidth={"150px"}
                    value={formContent?.fax}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.fax = e.target.value;
                      setFormContent(values);
                    }}
                  />
                  <ContactDetailField
                    label="Email"
                    isEdit={isEdit}
                    type="text"
                    wrapperStyle="justify-content-start"
                    minWidth={"150px"}
                    value={formContent?.email}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.email = e.target.value;
                      setFormContent(values);
                    }}
                  />
                </Typography>
              </div>
            </div>
          </div>
          {/* table */}
          <TableContainer component={Paper} className="mb-5 mt-4">
            <Table>
              <TableBody>
                {/* Entitlement1 */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Entitlement</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"shipper"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Entitlement"}
                        value={formContent?.entitlement1}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.entitlement1 = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.entitlement1
                          ? formContent?.entitlement1
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>
                {/* Estimated Weight */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Estimated Weight</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"consignee"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Estimated Weight"}
                        value={formContent?.estimatedWeight}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.estimatedWeight = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.estimatedWeight
                          ? `${formContent?.estimatedWeight}`
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>
                {/* Actual Weight */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Actual Weight</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"consignee"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Actual Weight"}
                        value={formContent?.actualWeight}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.actualWeight = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.actualWeight
                          ? `${formContent?.actualWeight}`
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>
               
                {/* Estimated Volume */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Estimated Volume</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"consignee"}
                        type="number"
                        style="full-border-field bg-app"
                        label={"Estimated Volume"}
                        value={formContent?.estimatedVolume}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.estimatedVolume = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.estimatedVolume ? (
                          <>
                            {Number(formContent?.estimatedVolume)?.toFixed(2)} m
                            <sup>3</sup>
                          </>
                        ) : (
                          "-"
                        )}
                      </>
                    )}
                  </TableCell>
                </TableRow>
                {/* Actual Volume */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Actual Volume</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"consignee"}
                        type="number"
                        style="full-border-field bg-app"
                        label={"Actual Volume"}
                        value={formContent?.actualVolume}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.actualVolume = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.actualVolume ? (
                          <>
                            {Number(formContent?.actualVolume)?.toFixed(2)} m
                            <sup>3</sup>
                          </>
                        ) : (
                          "-"
                        )}
                      </>
                    )}
                  </TableCell>
                </TableRow>
                {/* Delivery Started Date */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Delivery Started Date</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"description"}
                        type="date"
                        style="full-border-field bg-app"
                        label={"Delivery Started Date"}
                        value={formContent?.deliveryStartedDate}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.deliveryStartedDate = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.deliveryStartedDate
                          ? formContent?.deliveryStartedDate
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>
                {/* Delivery Completed Date */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Delivery Completed Date</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"description"}
                        type="date"
                        style="full-border-field bg-app"
                        label={"Delivery Completed Date"}
                        value={formContent?.deliveryCompletedDate}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.deliveryCompletedDate = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.deliveryCompletedDate
                          ? formContent?.deliveryCompletedDate
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>

                {/* Shifting Started Date */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Shifting Started Date</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"description"}
                        type="date"
                        style="full-border-field bg-app"
                        label={"Shifting Started Date"}
                        value={formContent?.shiftingStartedDate}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.shiftingStartedDate = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.shiftingStartedDate
                          ? formContent?.shiftingStartedDate
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>
                {/* Shifting Completed Date */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Shifting Completed Date</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"description"}
                        type="date"
                        style="full-border-field bg-app"
                        label={"Shifting Completed Date"}
                        value={formContent?.shiftingCompletedDate}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.shiftingCompletedDate = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.shiftingCompletedDate
                          ? formContent?.shiftingCompletedDate
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>
                {/* Warehouse In Date */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Warehouse In Date</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"gross-weight"}
                        type="date"
                        style="full-border-field bg-app"
                        label={"Warehouse In Date"}
                        value={formContent?.wareHouseInDate}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.wareHouseInDate = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.wareHouseInDate
                          ? formContent?.wareHouseInDate
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>

                {/* Warehouse Out Date */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Warehouse Out Date</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"gross-weight"}
                        type="date"
                        style="full-border-field bg-app"
                        label={"Warehouse Out Date"}
                        value={formContent?.wareHouseOutDate}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.wareHouseOutDate = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.wareHouseOutDate
                          ? formContent?.wareHouseOutDate
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>
                {/* Shipment Date */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Shipment Date</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"port-of-discharge"}
                        type="date"
                        style="full-border-field bg-app"
                        label={"Shipment Date"}
                        value={formContent?.shipmentDate}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.shipmentDate = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.shipmentDate
                          ? formContent?.shipmentDate
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>

                {/* ORIGIN/Destination Country */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Origin / Destination</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"country"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Origin / Destination"}
                        value={formContent?.originDestination}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.originDestination = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.originDestination
                          ? formContent?.originDestination
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>

                {/* BOL/AWB No. */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>BOL/AWB No.</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"remarks"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"BOL/AWB No."}
                        value={formContent?.bolAWBNo}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.bolAWBNo = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>{formContent?.bolAWBNo ? formContent?.bolAWBNo : "-"}</>
                    )}
                  </TableCell>
                </TableRow>

                {/* Container No. */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Container No.</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"remarks"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Container No."}
                        value={formContent?.containerNo}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.containerNo = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.containerNo
                          ? formContent?.containerNo
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>

                {/* Vessel */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Vessel</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"remarks"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Vessel"}
                        value={formContent?.vessel}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.vessel = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>{formContent?.vessel ? formContent?.vessel : "-"}</>
                    )}
                  </TableCell>
                </TableRow>

                {/* ETD/ETA */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>ETD/ETA</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"remarks"}
                        type="date"
                        style="full-border-field bg-app"
                        label={"ETD/ETA"}
                        value={formContent?.ETDbyETA}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.ETDbyETA = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>{formContent?.ETDbyETA ? formContent?.ETDbyETA : "-"}</>
                    )}
                  </TableCell>
                </TableRow>

                {/* Airline */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Airline</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"remarks"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Airline"}
                        value={formContent?.airline}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.airline = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>{formContent?.airline ? formContent?.airline : "-"}</>
                    )}
                  </TableCell>
                </TableRow>

                {/* Flight No. */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Flight No.</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"remarks"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Flight No."}
                        value={formContent?.flightNo}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.flightNo = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>{formContent?.flightNo ? formContent?.flightNo : "-"}</>
                    )}
                  </TableCell>
                </TableRow>

               
              </TableBody>
            </Table>
          </TableContainer>

          {/* Remarks */}
          <div className="mt-4 pe-1 mb-5">
            <Typography variant="body1" className="text-start" gutterBottom>
              <DynamicText
                label="Remarks / Instructions (If any)"
                textAreaLabel="Reamarks"
                isEdit={isEdit}
                value={formContent?.remarks}
                onChange={(e) => {
                  let values = { ...formContent };
                  values.remarks = e.target.value;
                  setFormContent(values);
                }}
              />
            </Typography>
          </div>
          {/* /Office Instructions/remarks */}
          {/* /table */}

          {/* Signatures */}

          <Row className="mt-5 pt-5 no-print">
            <Col sm={6} className="mt-5 pt-5">
              <div
                style={{ height: "90px" }}
                className="d-flex justify-content-center align-items-end"
              >
                {formContent?.managerSign?.trim()?.length == 0 ? (
                  <>
                    <div className="d-flex justify-content-center align-items-end">
                      <label
                        htmlFor="uploadSign"
                        className="active-btn ms-2 w-200 d-flex align-items-center justify-content-center cursor-pointer btn"
                      >
                        {uploadText1}
                      </label>
                      <input
                        type="button"
                        hidden
                        id="uploadSign"
                        name="uploadSign"
                        onClick={() => {
                          setWhichSign("manager");
                          setSignCanvas(true);
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <img
                    src={`${Bucket}${formContent?.managerSign}`}
                    height={80}
                    width={200}
                  />
                )}
              </div>

              <p>{"_______________________________"}</p>
              <p className="my-1">
                <b>{"Manager Operations"}</b>
              </p>

              {""}
            </Col>
            <Col sm={6} className="mt-5 pt-5">
              <div
                style={{ height: "90px" }}
                className="d-flex justify-content-center align-items-end"
              >
                {formContent?.assistOperSign?.trim()?.length == 0 ? (
                  <>
                    <div className="d-flex justify-content-center align-items-end">
                      <label
                        htmlFor="uploadSign2"
                        className="active-btn ms-2 w-200 d-flex align-items-center justify-content-center cursor-pointer btn"
                      >
                        {uploadText2}
                      </label>
                      <input
                        type="button"
                        hidden
                        id="uploadSign2"
                        name="uploadSign2"
                        onClick={(e) => {
                          setWhichSign("assist");
                          setSignCanvas(true);
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <img
                    src={`${Bucket}${formContent.assistOperSign}`}
                    height={80}
                    width={200}
                  />
                )}
              </div>
              <p>{"_______________________________"}</p>
              <p className="my-1">
                <b>{"Assistant Operations"}</b>
              </p>

              {""}
            </Col>
          </Row>
        </div>
      ) : (
        <>
          {isLoading ? (
            <div> Loading...</div>
          ) : (
            <div> Ooops... Something went wrong...</div>
          )}
        </>
      )}

      <PrintComponent>
        <WorksheetImportDownload
          surveyData={surveyData}
          formContent={formContent}
        />
      </PrintComponent>

      {/* snackbar */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={
            snackbarMessage?.includes("successful") ? "success" : "error"
          }
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      {/* /snackbar */}

      {/* Add signature Modal */}
      {isSignCanvas && (
          <MySignatureCanvas
            open={isSignCanvas}
            onClose={() => {
              setSignCanvas(false);
            }}
            onSuccess={(file) => {
              if (!isLoading) uploadFile(file);
            }}
          />
        )}
    </>
  );
};
export default WorksheetImportPageClientSide;
