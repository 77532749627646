import React, { useEffect, useState } from "react";
import {
  updateFormContentPreview,
  getFormContentPreview,
  imageUploadClientPreview,
} from "../../../../api/services/services";
import { Button, Snackbar, Box } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import { Bucket } from "../../../../api/config/apiConfig";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import Input from "../../../atoms/Input";
import removeImg from "../../../../imgs/remove.png";
import { useParams } from "react-router-dom";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import html2pdf from "html2pdf.js";
import { useLocation } from "react-router-dom";

const ItemConditionDownload = ({ formContent, surveyData }) => {
  const [formContentId, setFormContentId] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const urlData = new URLSearchParams(location.search);
  const email = urlData.get("email");
  const surveyId = urlData.get("surveyId");
  const formId = urlData.get("formId");
  const token = urlData.get("token");

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      <div className="mx-4">
        {/* <MainTemplate> */}
        <div className="d-flex align-items-center justify-content-center mb-1">
          <p className="ps-0 mb-0 head-top">Item Condition Report at Origin</p>
        </div>

        <Table responsive className="mt-2">
          {/* Table Header */}
          <thead className="bg-white">
            <tr>
              <th
                className="border p-1 text-start"
                style={{ width: "10%", fontSize: "10px" }}
              >
                Item No.
              </th>
              <th
                className="border p-1 text-start"
                style={{ width: "50%", fontSize: "10px" }}
              >
                Item Description
              </th>
              <th
                className="border p-1 text-start"
                style={{ width: "20%", fontSize: "10px" }}
              >
                Present Condition
              </th>
              <th
                className="border p-1 text-start"
                style={{ width: "20%", fontSize: "10px" }}
              >
                Disclaimer Box
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {formContent?.dataRows?.length > 0 ? (
              <>
                {formContent?.dataRows.map((row, index) => (
                  <tr key={index}>
                    <td
                      className="border p-1 text-start"
                      style={{ fontSize: "10px" }}
                    >
                      {index + 1}
                    </td>
                    <td
                      className="border p-1 text-start"
                      style={{ fontSize: "10px" }}
                    >
                      {isEdit ? (
                        <>
                          <Input
                            id="condition"
                            label="Present Condition..."
                            type="text"
                            style="full-border-field"
                            value={row?.description}
                            inline={{ width: "-webkit-fill-available" }}
                          />
                        </>
                      ) : row?.description ? (
                        row?.description
                      ) : (
                        "-"
                      )}
                    </td>
                    <td
                      className="border p-1 text-start"
                      style={{ fontSize: "10px" }}
                    >
                      {isEdit ? (
                        <Input
                          id="condition"
                          label="Present Condition..."
                          type="text"
                          style="full-border-field"
                          value={row?.condition}
                          inline={{ width: "-webkit-fill-available" }}
                        />
                      ) : row?.condition ? (
                        row?.condition
                      ) : (
                        "-"
                      )}
                    </td>

                    <td
                      className="border p-1 text-start"
                      style={{ fontSize: "10px" }}
                    >
                      {isEdit ? (
                        <Input
                          id="condition"
                          label="Disclaimer.."
                          type="text"
                          style="full-border-field"
                          value={row?.disclamer}
                          inline={{ width: "-webkit-fill-available" }}
                        />
                      ) : row?.disclamer ? (
                        row?.disclamer
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td className="text-center" colSpan={9}>
                  {isLoading ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "10px 0px",
                      }}
                    >
                      <CircularProgress
                        style={{
                          color: "#00aff0",
                          height: "25px",
                          width: "25px",
                        }}
                      />
                    </Box>
                  ) : (
                    <div
                      className="d-flex justify-content-center"
                      style={{ fontSize: "10px" }}
                    >
                      <p className="my-3">No Records to Show</p>
                    </div>
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        {/* Signatures */}
        <Row className="mt-1">
          <Col sm={6} className="">
            <div
              style={{ height: "90px" }}
              className="d-flex justify-content-center align-items-end"
            >
              {formContent.clientSign != "" &&
                formContent.clientSign != undefined && (
                  <img
                    src={`${Bucket}${formContent.clientSign}`}
                    height={80}
                    width={200}
                  />
                )}
            </div>
            <p className="mb-0">{"_______________________________"}</p>
            <p className="my-0">
              <b>{"Customer/Authorized"}</b>
            </p>

            {""}
          </Col>
          <Col sm={6} className="">
            <div
              style={{ height: "90px" }}
              className="d-flex justify-content-center align-items-end"
            >
              <img
                src={`${Bucket}${formContent?.supervisorSign}`}
                height={80}
                width={200}
              />
            </div>
            <p className="mb-0">{"_______________________________"}</p>
            <p className="my-0">
              <b>{"Supervisor"}</b>
            </p>

            {""}
          </Col>
        </Row>

        {/* </MainTemplate> */}
      </div>
    </>
  );
};
export default ItemConditionDownload;
