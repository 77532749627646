import React, { useEffect, useState } from "react";
import {
  getFormContent,
  getSingleSurvey,
  updateFormContent,
  surveyResultPreview,
  sharePreviewLink,
  imageUpload,
} from "../../../api/services/services";
import backArrow from "../../../imgs/BackArrow.svg";
import MainTemplate from "../../templates/MainTemplate";
import { useNavigate } from "react-router-dom";
import { Button, Snackbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import { Bucket } from "../../../api/config/apiConfig";
import "./style.css";
import Input from "../../atoms/Input";
import TextArea from "../../atoms/Textarea";
import ContactDetailField from "../../molecules/ContactDetailField";
import { useParams } from "react-router-dom";
import { addFormContent } from "../../../api/services/services";
import { Margin } from "react-to-pdf";
import QuotationFormDownload from "./ClientSide/download";
import html2pdf from "html2pdf.js";
import PrintComponent from "../PrintComponent";
import editIcn from "../../../imgs/edit.png";
import trashIcn from "../../../imgs/trash.png";
import moment from "moment";
import MySignatureCanvas from "../../molecules/signature_canvas";
import ShareWithClientsModal from "../../molecules/ShareWithClients";
import { sendSuperAdminVerificationForEdit } from "../PrebidAgreement/creation/service";
import SuperAdminVerifationModal from "../PrebidAgreement/creation/super_admin_verification_modal";

const QuotationFormPage = () => {
  const { surveyId, id, flg } = useParams();
  const navigate = useNavigate();

  const [surveyData, setSurveyData] = useState(null);
  const [formContent, setFormContent] = useState({
    clientName: "",
    recipient: "",
    clientEmail: "",
    clientSign: "",
    adminSign: "",
    to: "",
    location: "",
    email: "",
    date: "",
    invoiceNo: "",
    phoneNo: "",
    details: `I would like to thank you for the opportunity to quote on your upcoming relocation from ISLAMABAD to APOE MOSCOW. Please see our estimated rate below for the requested services. Should you have any questions then feel free to contact me.`,
    description: "",
    serviceRequired: "",
    packingDate: "",
    originAddress: "",
    destinationAddress: "",
    estimatedWeight: 0,
    estimatedVolume: 0,
    transportationMode: "",
    charges: "",
    quoteValidity: "",
    priceIncludeAtOrigin: "",
    standardExclusions: "",
    insurance: "",
    documentsRequired: "",
    paymentTerms: "",
    bankDetails: {
      iban: "",
      title: "",
      swiftCode: "",
      branchNameCode: "",
    },
  });

  const [formContentId, setFormContentId] = useState("");
  const [isEdit, setIsEdit] = useState(flg == "1" ? true : false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const [uploadText, setUploadText] = useState("Upload Sign");

  const [mediaKeysToSend, setMediaKeysToSend] = useState([]);
  const [mediaKeysToRemove, setMediaKeysToRemove] = useState([]);

  const [isSignCanvas, setSignCanvas] = useState(false);

  const [isShare, setIsShare] = useState(false);

  //
  const uploadFile = async (file) => {
    setIsLoading(true);

    setUploadText("Uploading...");
    const fd = new FormData();
    fd.append("image", file);

    imageUpload(fd)
      .then((res) => {
        let values = { ...formContent };
        if (values.adminSign?.trim()?.length != 0) {
          let dlt = [...mediaKeysToRemove];
          dlt.push(values.adminSign);
          setMediaKeysToRemove(dlt);
        }

        values.adminSign = res?.data?.data?.key;
        setFormContent(values);

        let md = [...mediaKeysToSend];
        md.push(res?.data?.data?.key);
        setMediaKeysToSend(md);

        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setUploadText("Upload Sign");

        setIsLoading(false);
      });
  };

  //
  const getSurveyPreview = () => {
    surveyResultPreview(surveyId)
      .then((response) => {
        setSurveyData(response?.data?.data?.surveyDetail);
        let values = { ...formContent };
        values.details = `I would like to thank you for the opportunity to quote on your upcoming relocation from ${response?.data?.data?.surveyDetail?.loadPlaceStAddress}, ${response?.data?.data?.surveyDetail?.loadPlaceCity}, ${response?.data?.data?.surveyDetail?.loadPlaceCountry}  to ${response?.data?.data?.surveyDetail?.unloadPlaceStAddress}, ${response?.data?.data?.surveyDetail?.unloadPlaceCity},  ${response?.data?.data?.surveyDetail?.unloadPlaceCountry}. Please see our estimated rate below for the requested services. Should you have any questions then feel free to contact me.`;
        values.invoiceNo = response?.data?.data?.surveyDetail?.id;
        values.location = `${response?.data?.data?.surveyDetail?.loadPlaceCity}, ${response?.data?.data?.surveyDetail?.loadPlaceCountry}`;
        values.originAddress = `${response?.data?.data?.surveyDetail?.loadPlaceStAddress}, ${response?.data?.data?.surveyDetail?.loadPlaceCity}, ${response?.data?.data?.surveyDetail?.loadPlaceCountry}`;
        values.destinationAddress = `${response?.data?.data?.surveyDetail?.unloadPlaceStAddress}, ${response?.data?.data?.surveyDetail?.unloadPlaceCity}, ${response?.data?.data?.surveyDetail?.unloadPlaceCountry}`;
        values.clientName = `${response?.data?.data?.surveyDetail?.name}`;
        values.clientEmail = `${response?.data?.data?.surveyDetail?.email}`;
        values.to = `${response?.data?.data?.surveyDetail?.name}`;
        values.email = `${response?.data?.data?.surveyDetail?.email}`;
        values.recipient = `${response?.data?.data?.surveyDetail?.name}`;

        let totalWeight = 0;
        let totalCbm = 0;
        response?.data?.data?.roomWiseCalculation?.map((cat, index) => {
          totalWeight = totalWeight + cat?.totalWeightKg;
          totalCbm = totalCbm + cat?.totalCbm;
        });

        values.estimatedWeight = totalWeight;
        values.estimatedVolume = totalCbm;

        setFormContent(values);
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }
      });
  };

  //
  const getSurvey = () => {
    getSingleSurvey(surveyId)
      .then((response) => {
        if (response?.data?.data?.status == "submitted") getSurveyPreview();
        else {
          setSurveyData(response?.data?.data);
          let values = { ...formContent };
          values.details = `I would like to thank you for the opportunity to quote on your upcoming relocation from ${response?.data?.data?.loadPlaceStAddress}, ${response?.data?.data?.loadPlaceCity}, ${response?.data?.data?.loadPlaceCountry}  to ${response?.data?.data?.unloadPlaceStAddress}, ${response?.data?.data?.unloadPlaceCity},  ${response?.data?.data?.unloadPlaceCountry}. Please see our estimated rate below for the requested services. Should you have any questions then feel free to contact me.`;
          values.invoiceNo = response?.data?.data?.id;
          values.location = `${response?.data?.data?.loadPlaceCity}, ${response?.data?.data?.loadPlaceCountry}`;

          values.originAddress = `${response?.data?.data?.loadPlaceStAddress}, ${response?.data?.data?.loadPlaceCity}, ${response?.data?.data?.loadPlaceCountry}`;
          values.destinationAddress = `${response?.data?.data?.unloadPlaceStAddress}, ${response?.data?.data?.unloadPlaceCity}, ${response?.data?.data?.unloadPlaceCountry}`;
          values.clientName = `${response?.data?.data?.name}`;
          values.clientEmail = `${response?.data?.data?.email}`;
          values.to = `${response?.data?.data?.name}`;
          values.email = `${response?.data?.data?.email}`;
          values.location = `${response?.data?.data?.loadPlaceCity}`;
          values.recipient = `${response?.data?.data?.name}`;

          setFormContent(values);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }
      });
  };

  //
  const addContent = () => {
    setIsLoading(true);
    let media = [];

    if (formContent?.adminSign != "") media[0] = formContent?.adminSign;

    const body = {
      formId: id,
      surveyId: surveyId,
      content: formContent,
      mediaKeys: mediaKeysToSend,
    };
    addFormContent(body)
      .then(() => {
        setSnackbarMessage("Quotation form has been added successfully!");
        setOpenSnackbar(true);
        navigate(-1);

        setIsLoading(false);
        setMediaKeysToSend([]);
        setMediaKeysToRemove([]);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  //
  const updateContent = () => {
    setIsLoading(true);
    let media = [];
    // if (formContent?.clientSign != "") media[0] = formContent?.clientSign;
    // if (formContent?.adminSign != "") media[1] = formContent?.adminSign;

    let newMedia = [];
    // let mdInd = 0;
    // media?.map((md, ind) => {
    //   if (md != null) {
    //     newMedia[mdInd] = md;
    //     mdInd++;
    //   }
    // });

    const body = {
      formId: id,
      surveyId: surveyId,
      content: formContent,
      mediaKeys: mediaKeysToSend,
      removedMediaKeys: mediaKeysToRemove,
    };
    updateFormContent(body, formContentId)
      .then((res) => {
        setSnackbarMessage("Quotation form has been updated successfully!");
        setOpenSnackbar(true);
        getContent();
        setIsEdit(false);

        setIsLoading(false);
        setMediaKeysToSend([]);
        setMediaKeysToRemove([]);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  //
  const getContent = () => {
    setIsLoading(true);
    setMediaKeysToSend([]);
    setMediaKeysToRemove([]);
    getFormContent(id, surveyId)
      .then((res) => {
        setFormContentId(res.data.data.uuid);
        setFormContent(res.data.data.content);

        // let values = {...res.data.data.content}
        // values.adminSign = ''
        // setFormContent(values)
        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (flg == "1") getSurvey();
    else getContent();
  }, []);

  const [show, setShow] = useState(true);
  const options = {
    // resolution: Resolution.HIGH,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      format: "letter",
      // default is 'portrait'
      orientation: "portrait",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/png",
      qualityRatio: 1,
    },
    overrides: {
      pdf: {
        compress: true,
      },
    },
  };

  //
  const generatePdf = () => {
    setShow(true);

    setTimeout(() => {
      const content = document.getElementById("content-to-convert"); // Replace with the ID of the element you want to convert to PDF

      const currentDateTime = new Date();
      const filename = `survey_${currentDateTime.toISOString()}.pdf`;

      const pdfOptions = {
        margin: 5,
        filename: filename,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
        }, // Set the page size here
      };

      let temp = html2pdf().from(content).set(pdfOptions).outputPdf();
      temp.save(filename);

      setShow(false);
    }, 3500);
  };

  //
  const shareWithClient = (data) => {
    let ems = [];
    let emsInd = 0;
    data.map((dt) => {
      if (dt.trim().length != 0) {
        ems[emsInd] = dt;
        emsInd++;
      }
    });

    setIsLoading(true);
    const body = {
      surveyId: surveyId,
      formId: id,
      emails: ems,
      formRoute: "quotation-form-preview",
    };
    sharePreviewLink(body)
      .then(() => {
        setSnackbarMessage(
          "Quotation form has been shared with the client successfully!"
        );
        setOpenSnackbar(true);

        setIsLoading(false);

        setIsShare(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  const printAction = () => {
    const PAGE_HEIGHT = 1045;
    const printElement = document.getElementById("print-component");
    if (printElement) {
      printElement.classList.add("temp-class-for-height");
      const height = printElement.clientHeight - 500;
      const numberOfPage = Math.ceil(height / PAGE_HEIGHT);
      const heightWithSingleHeader = numberOfPage * PAGE_HEIGHT;
      let requiredHeight = heightWithSingleHeader;
      if (numberOfPage > 1) {
        const headerHeight =
          printElement.getElementsByTagName("thead")?.[0]?.clientHeight;
        const footerHeight =
          printElement.getElementsByTagName("tfoot")?.[0]?.clientHeight;
        requiredHeight -= (numberOfPage - 1) * (headerHeight + footerHeight);
      }
      printElement.style.height = `${requiredHeight}px`;
      printElement.classList.remove("temp-class-for-height");
    }
    window.print();
    if (printElement) {
      printElement.style.height = `auto`;
    }
  };

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // 11th, 12th, 13th, etc.
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  // Helper function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const ordinalSuffix = getOrdinalSuffix(day);

    return `${day}${ordinalSuffix} ${month}, ${year}`;
  };

  const [isVerified, setIsVerified] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);

  const sendEditRequestToAdmin = () => {
    setIsEditLoading(true);
    sendSuperAdminVerificationForEdit()
      .then((response) => {
        setIsVerified(true);
        setIsEditLoading(false);
      })
      .catch((error) => {
        setIsVerified(false);
        setIsEditLoading(false);
      });
  };

  return (
    <>
      <MainTemplate>
        <div className="no-print">
          <div className="no-print">
            <div className="d-block d-sm-flex align-items-center py-4 ">
              <div className="d-flex align-items-center no-print">
                <div
                  className="light-blue-color back-bg cursor-pointer"
                  onClick={() => navigate(-1)}
                >
                  <img src={backArrow} height="24" width="24" />{" "}
                </div>
                <div>
                  <p className="main-head ps-3 mb-0">Quotation Form</p>
                </div>
              </div>
              <div className="d-flex ms-auto justify-content-center mt-2 mt-sm-0 flex-wrap no-print">
                {/*Edit Button */}
                {!isEdit && (
                  <Button
                    className="active-btn"
                    onClick={() => {
                      sendEditRequestToAdmin();
                    }}
                    disabled={isEditLoading}
                  >
                    Edit
                  </Button>
                )}

                {/*Share Button */}
                {!isEdit && (
                  <Button
                    className="active-btn ms-3"
                    onClick={() => {
                      setIsShare(true);
                    }}
                    disabled={isLoading}
                  >
                    Share
                  </Button>
                )}

                {/* Cancel button */}
                {isEdit && flg == "2" && (
                  <Button
                    className="active-btn ms-3"
                    onClick={() => {
                      getContent();
                      setIsEdit(false);
                    }}
                    disabled={isLoading}
                  >
                    Cancel
                  </Button>
                )}

                {/* Save Button */}
                {isEdit && (
                  <Button
                    className="active-btn ms-3"
                    onClick={() => {
                      if (flg == "1") addContent();
                      else updateContent();
                    }}
                    disabled={isLoading}
                  >
                    Save
                  </Button>
                )}

                {/* Download Button */}
                {!isEdit && (
                  <Button
                    className="pr-btn ms-3 px-0"
                    // onClick={() => generatePdf()}
                    onClick={() => {
                      setIsLoading(true);
                      // printAction();
                      var tempTitle = document.title;
                      document.title = `Quotation-${formContent?.to}-${moment(
                        new Date()
                      ).format("DD.MM.YYYY")}`;

                      window.print();
                      document.title = tempTitle;
                      setIsLoading(false);

                      // setShow(true);
                      // setDownloadText("Downloading..");
                      // setTimeout(() => {
                      //   // toPDF()
                      //   generatePDF(getTargetElement, options);
                      // }, 500);
                      // setTimeout(() => {
                      //   setDownloadText("Download");
                      //   setShow(false);
                      // }, 1000);
                    }}
                    disabled={isLoading}
                  >
                    {"Save as PDF"}
                  </Button>
                )}
              </div>
            </div>
          </div>
          {/* contact detail*/}
          <div className="no-print">
            <div className="contact-detail d-flex mt-2 no-print">
              {/* left col */}
              <div
                className={`left-col ${
                  !isEdit ? " w-75" : "w-50 pe-5"
                } text-start`}
              >
                <Typography variant="body1" gutterBottom>
                  <ContactDetailField
                    label="To"
                    isEdit={isEdit}
                    type="text"
                    value={formContent?.to}
                    onChange={(e) => {
                      console.log(e.target.value);
                      let values = { ...formContent };
                      values.to = e.target.value;
                      setFormContent(values);
                    }}
                  />
                  <ContactDetailField
                    label="Location"
                    isEdit={isEdit}
                    type="text"
                    value={formContent?.location}
                    onChange={(e) => {
                      console.log(e.target.value);
                      let values = { ...formContent };
                      values.location = e.target.value;
                      setFormContent(values);
                    }}
                  />
                  <ContactDetailField
                    label="Email"
                    isEdit={isEdit}
                    type="email"
                    value={formContent?.email}
                    onChange={(e) => {
                      console.log(e.target.value);
                      let values = { ...formContent };
                      values.email = e.target.value;
                      setFormContent(values);
                    }}
                  />
                </Typography>
              </div>
              {/* right col */}
              <div
                className={`right-col ${
                  !isEdit ? "w-25" : "w-50 ps-5"
                }  d-flex flex-column align-items-end pe-2`}
              >
                <div className="">
                  <Typography
                    variant="body1"
                    className="text-start w-100"
                    gutterBottom
                  >
                    <ContactDetailField
                      label="Date"
                      isEdit={isEdit}
                      type="date"
                      wrapperStyle="justify-content-start"
                      value={formContent?.date}
                      onChange={(e) => {
                        console.log(e.target.value);
                        let values = { ...formContent };
                        values.date = e.target.value;
                        setFormContent(values);
                      }}
                    />
                    {/* <ContactDetailField
                      label="Invoice #"
                      isEdit={isEdit}
                      type="number"
                      wrapperStyle="justify-content-start"
                      value={formContent?.invoiceNo}
                      onChange={(e) => {
                        console.log(e.target.value);
                        let values = { ...formContent };
                        values.invoiceNo = e.target.value;
                        setFormContent(values);
                      }}
                      disabled={true}
                    /> */}
                    <ContactDetailField
                      label="Tel"
                      isEdit={isEdit}
                      type="number"
                      wrapperStyle="justify-content-start"
                      value={formContent?.phoneNo}
                      onChange={(e) => {
                        console.log(e.target.value);
                        let values = { ...formContent };
                        values.phoneNo = e.target.value;
                        setFormContent(values);
                      }}
                    />
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          {/* /contact detail */}

          {/* info */}
          <div className="mt-4 pe-1 no-print">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="mb-2">
                <div className="d-flex align-items-center mb-3">
                  <label
                    htmlFor={"refNo"}
                    className={`me-2 ${isEdit ? "" : ""} ${"fw-bold"}`}
                    // style={{ minWidth: "50px" }}
                  >
                    <b>
                      {"Dear "}{" "}
                      {!isEdit
                        ? `${
                            formContent?.recipient
                              ? formContent?.recipient
                              : "-"
                          }`
                        : ""}
                    </b>
                  </label>
                  {isEdit && (
                    <input
                      type={"text"}
                      id="refNo"
                      // className={`${style || "input-field "}`}
                      className={`input-field ms-2`}
                      value={`${formContent?.recipient}`}
                      onChange={(e) => {
                        let values = { ...formContent };
                        values.recipient = e.target.value;
                        setFormContent(values);
                      }}
                      placeholder={""}
                      style={{ width: "200px" }}
                    />
                  )}
                  {/* ) : (
                    <span
                      style={{ width: "fit-content" }}
                    >{`${formContent?.recipient}`}</span>
                  )} */}
                </div>
                {/* <label
                  htmlFor="to"
                  className={`fw-bold ${isEdit ? "mb-3" : "mb-1"}`}
                >
                  Dear {formContent?.clientName},
                </label> */}
                {!isEdit ? (
                  <p>{formContent?.details ? formContent?.details : "-"}</p>
                ) : (
                  <TextArea
                    rows="3"
                    label="Details here..."
                    id="description"
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.details = e.target.value;
                      setFormContent(values);
                    }}
                    value={formContent?.details}
                  />
                )}
              </div>
            </Typography>
          </div>
          {/* /info */}

          {/* description */}
          <div className="mt-5 pe-1 no-print">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div
                className={`table-text-wrapper mb-2 d-flex ${
                  isEdit ? "flex-column" : ""
                } justify-content-between`}
              >
                <label
                  htmlFor="description"
                  className={`fw-bold  ${isEdit ? "mb-3" : "mb-1"}`}
                >
                  Description
                </label>
                {!isEdit ? (
                  <span className="table-text w-50 text-end">
                    {formContent?.description ? formContent?.description : "-"}
                  </span>
                ) : (
                  <TextArea
                    rows="3"
                    label="Description..."
                    id="description"
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.description = e.target.value;
                      setFormContent(values);
                    }}
                    value={formContent?.description}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* /description */}

          {/* Service Required */}
          <div className="mt-5 pe-1 no-print">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div
                className={`table-text-wrapper mb-2 d-flex ${
                  isEdit ? "flex-column" : ""
                } justify-content-between`}
              >
                <label
                  htmlFor="serviceRequired"
                  className={`fw-bold  ${isEdit ? "mb-3" : "mb-1"}`}
                >
                  Service Required
                </label>
                {!isEdit && (
                  <span className="table-text w-50 text-end">
                    {formContent?.serviceRequired
                      ? formContent?.serviceRequired
                      : "-"}
                  </span>
                )}
                {isEdit && (
                  <TextArea
                    rows="3"
                    label="Service Required..."
                    id="serviceRequired"
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.serviceRequired = e.target.value;
                      setFormContent(values);
                    }}
                    value={formContent?.serviceRequired}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* /Service Required */}

          {/* Requested Packing Date */}
          <div className="mt-5 pe-1 no-print">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex justify-content-between">
                <div className="w-50">
                  <label htmlFor="packingDate" className="fw-bold mb-1">
                    Tentative/Requested Packing Date
                  </label>
                </div>
                {!isEdit && (
                  <span className="table-text w-50 text-end">
                    {formContent?.packingDate
                      ? formatDate(formContent?.packingDate)
                      : "-"}
                  </span>
                )}
                {isEdit && (
                  <Input
                    id="packingDate"
                    type="date"
                    style="full-border-field"
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.packingDate = e.target.value;
                      setFormContent(values);
                    }}
                    value={formContent?.packingDate}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* /Requested Packing Date */}

          {/* Origin Address */}
          <div className="mt-5 pe-1 no-print">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div
                className={`table-text-wrapper mb-2 d-flex ${
                  isEdit ? "flex-column" : ""
                } justify-content-between`}
              >
                <label
                  htmlFor="serviceRequired"
                  className={`fw-bold  ${isEdit ? "mb-3" : "mb-1"}`}
                >
                  Origin Address
                </label>
                {!isEdit && (
                  <span className="table-text w-50 text-end">
                    {" "}
                    {formContent?.originAddress
                      ? formContent?.originAddress
                      : "-"}
                  </span>
                )}
                {isEdit && (
                  <TextArea
                    rows="3"
                    label="Origin Address..."
                    id="originAddress"
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.originAddress = e.target.value;
                      setFormContent(values);
                    }}
                    value={formContent?.originAddress}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* /Origin Address */}

          {/* Destination Address */}
          <div className="mt-5 pe-1 no-print">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div
                className={`table-text-wrapper mb-2 d-flex ${
                  isEdit ? "flex-column" : ""
                } justify-content-between`}
              >
                <label
                  htmlFor="serviceRequired"
                  className={`fw-bold  ${isEdit ? "mb-3" : "mb-1"}`}
                >
                  Destination Address
                </label>
                {!isEdit && (
                  <span className="table-text w-50 text-end">
                    {formContent?.destinationAddress
                      ? formContent?.destinationAddress
                      : "-"}
                  </span>
                )}
                {isEdit && (
                  <TextArea
                    rows="3"
                    label="Destination Address..."
                    id="destinationAddress"
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.destinationAddress = e.target.value;
                      setFormContent(values);
                    }}
                    value={formContent?.destinationAddress}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* /Destination Address */}

          {/* Estimated Weight */}
          <div className="mt-5 pe-1 no-print">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex justify-content-between">
                <label htmlFor="estimatedWeight" className="fw-bold mb-1">
                  Estimated Weight
                </label>
                {!isEdit && (
                  <span className="table-text w-50 text-end">
                    {formContent?.estimatedWeight
                      ? `${formContent?.estimatedWeight}`
                      : "-"}
                  </span>
                )}
                {isEdit && (
                  <Input
                    id="estimatedWeight"
                    label="Estimated Weight..."
                    type="text"
                    style="full-border-field"
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.estimatedWeight = e.target.value;
                      setFormContent(values);
                    }}
                    value={formContent?.estimatedWeight}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* /Estimated Weight */}

          {/* Estimated Volume */}
          <div className="mt-5 pe-1 no-print">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex justify-content-between">
                <label htmlFor="estimatedVolume" className="fw-bold mb-1">
                  Estimated Volume
                </label>
                {!isEdit ? (
                  <span className="table-text w-50 text-end">
                    {formContent?.estimatedVolume ? (
                      <>
                        {Number(formContent?.estimatedVolume)?.toFixed(2)} m{" "}
                        <sup>3</sup>{" "}
                      </>
                    ) : (
                      "-"
                    )}
                  </span>
                ) : (
                  <Input
                    id="estimatedVolume"
                    label="Estimated Volume..."
                    type="number"
                    style="full-border-field"
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.estimatedVolume = e.target.value;
                      setFormContent(values);
                    }}
                    value={formContent?.estimatedVolume}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* /Estimated Volume */}

          {/* Transportation Mode */}
          <div className="mt-5 pe-1 no-print">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex justify-content-between">
                <label htmlFor="transportationMode" className="fw-bold mb-1">
                  Transportation Mode
                </label>
                {!isEdit ? (
                  <span className="table-text w-50 text-end">
                    {formContent?.transportationMode
                      ? formContent?.transportationMode
                      : "-"}
                  </span>
                ) : (
                  <Input
                    id="transportationMode"
                    label="Transportation Mode..."
                    type="text"
                    style="full-border-field"
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.transportationMode = e.target.value;
                      setFormContent(values);
                    }}
                    value={formContent?.transportationMode}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* /Transportation Mode */}

          {/* Charges up to MOSCOW airport */}
          <div className="mt-5 pe-1 no-print">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex justify-content-between">
                <label htmlFor="MoscowCharges" className="fw-bold mb-1">
                  Our Complete Charges
                </label>
                {!isEdit ? (
                  <span className="table-text w-50 text-end">
                    {formContent?.charges ? formContent?.charges : "-"}
                  </span>
                ) : (
                  <Input
                    id="MoscowCharges"
                    label="Complete Charges..."
                    type="text"
                    style="full-border-field"
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.charges = e.target.value;
                      setFormContent(values);
                    }}
                    value={formContent?.charges}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* /Charges up to MOSCOW airport*/}

          {/* Quote Validity */}
          <div className="mt-5 pe-1 no-print">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="mb-2">
                <label
                  htmlFor="quoteValidity"
                  className={`fw-bold ${isEdit ? "mb-3" : "mb-1"}`}
                >
                  Quote Validity:
                </label>
                {!isEdit ? (
                  <p>
                    {formContent?.quoteValidity
                      ? formContent?.quoteValidity
                      : "-"}
                  </p>
                ) : (
                  <TextArea
                    rows="3"
                    label="Quote Validity..."
                    id="quoteValidity"
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.quoteValidity = e.target.value;
                      setFormContent(values);
                    }}
                    value={formContent?.quoteValidity}
                  />
                )}
              </div>
            </Typography>
          </div>
          {/* /Quote Validity */}

          {/* Price Include at Origin */}
          <div className="mt-5 pe-1 no-print">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="mb-2">
                <label
                  htmlFor="priceInclude"
                  className={`fw-bold ${isEdit ? "mb-3" : "mb-1"}`}
                >
                  Price Include at Origin:
                </label>
                {!isEdit ? (
                  <p>
                    {formContent?.priceIncludeAtOrigin
                      ? formContent?.priceIncludeAtOrigin
                      : "-"}
                  </p>
                ) : (
                  <>
                    {/* <TextArea
                    rows="3"
                    label="Price Include at Origin..."
                    id="priceInclude"
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.priceIncludeAtOrigin = e.target.value;
                      setFormContent(values);
                    }}
                    value={formContent?.priceIncludeAtOrigin}
                  /> */}
                    <Input
                      id="estimatedVolume"
                      label="Price include at origin..."
                      type="text"
                      style="full-border-field w-100"
                      onChange={(e) => {
                        let values = { ...formContent };
                        values.priceIncludeAtOrigin = e.target.value;
                        setFormContent(values);
                      }}
                      value={formContent?.priceIncludeAtOrigin}
                      inline={{ width: "-webkit-fill-available" }}
                    />
                  </>
                )}
              </div>
            </Typography>
          </div>
          {/* /Price Include at Origin */}

          {/* Standard Exclusions */}
          <div className="mt-5 pe-1 no-print">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="mb-2">
                <label
                  htmlFor="standardExclusions"
                  className={`fw-bold ${isEdit ? "mb-3" : "mb-1"}`}
                >
                  Standard Exclusions:
                </label>
                {!isEdit ? (
                  <p>
                    {formContent?.standardExclusions
                      ? formContent?.standardExclusions
                      : "-"}
                  </p>
                ) : (
                  <TextArea
                    rows="3"
                    label="Standard Exclusions..."
                    id="standardExclusions"
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.standardExclusions = e.target.value;
                      setFormContent(values);
                    }}
                    value={formContent?.standardExclusions}
                  />
                )}
              </div>
            </Typography>
          </div>
          {/* /Standard Exclusions: */}

          {/* Insurance */}
          <div className="mt-5 pe-1 no-print">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="mb-2">
                <label
                  htmlFor="insurance"
                  className={`fw-bold ${isEdit ? "mb-3" : "mb-1"}`}
                >
                  Insurance:
                </label>
                {!isEdit ? (
                  <p>
                    {formContent?.insurance ? formContent?.insurance : "-"}{" "}
                  </p>
                ) : (
                  <TextArea
                    rows="3"
                    label="Insurance..."
                    id="insurance"
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.insurance = e.target.value;
                      setFormContent(values);
                    }}
                    value={formContent?.insurance}
                  />
                )}
              </div>
            </Typography>
          </div>
          {/* /Insurance: */}

          {/* Documents Required */}
          <div className="mt-5 pe-1 no-print">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="mb-2">
                <label
                  htmlFor="documentsRequired"
                  className={`fw-bold ${isEdit ? "mb-3" : "mb-1"}`}
                >
                  Documents Required:
                </label>
                {!isEdit ? (
                  <p>
                    {formContent?.documentsRequired
                      ? formContent?.documentsRequired
                      : "-"}
                  </p>
                ) : (
                  <TextArea
                    rows="3"
                    label="Documents Required..."
                    id="documentsRequired"
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.documentsRequired = e.target.value;
                      setFormContent(values);
                    }}
                    value={formContent?.documentsRequired}
                  />
                )}
              </div>
            </Typography>
          </div>
          {/* /Documents Required */}

          {/* Payment Terms */}
          <div className="mt-5 pe-1 no-print">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="mb-2">
                <label
                  htmlFor="paymentTerms"
                  className={`fw-bold ${isEdit ? "mb-3" : "mb-1"}`}
                >
                  Payment Terms:
                </label>
                {!isEdit ? (
                  <p>
                    {formContent?.paymentTerms
                      ? formContent?.paymentTerms
                      : "-"}
                  </p>
                ) : (
                  <TextArea
                    rows="3"
                    label="Payment Terms..."
                    id="paymentTerms"
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.paymentTerms = e.target.value;
                      setFormContent(values);
                    }}
                    value={formContent?.paymentTerms}
                  />
                )}
              </div>
            </Typography>
          </div>
          {/* /Payment Terms: */}

          {/* Bank Details */}
          <div className="mt-5 pe-1 mb-5 row no-print">
            <Typography
              variant="body1"
              className="text-start col-8"
              gutterBottom
            >
              <div className="mb-2">
                <label htmlFor="to" className="mb-2 fw-bold">
                  Bank Details:
                </label>
                <Typography variant="body1" gutterBottom>
                  <ContactDetailField
                    label="Account Title"
                    isEdit={isEdit}
                    type="text"
                    style="fw-normal"
                    value={formContent?.bankDetails?.title}
                    onChange={(e) => {
                      console.log(e.target.value);
                      let values = { ...formContent };
                      values.bankDetails.title = e.target.value;
                      setFormContent(values);
                    }}
                  />
                  <ContactDetailField
                    label=" IBAN No"
                    isEdit={isEdit}
                    type="text"
                    style="fw-normal"
                    value={formContent?.bankDetails?.iban}
                    onChange={(e) => {
                      console.log(e.target.value);
                      let values = { ...formContent };
                      values.bankDetails.iban = e.target.value;
                      setFormContent(values);
                    }}
                  />
                  <ContactDetailField
                    label="Swift Code"
                    isEdit={isEdit}
                    type="text"
                    style="fw-normal"
                    value={formContent?.bankDetails?.swiftCode}
                    onChange={(e) => {
                      console.log(e.target.value);
                      let values = { ...formContent };
                      values.bankDetails.swiftCode = e.target.value;
                      setFormContent(values);
                    }}
                  />
                  <ContactDetailField
                    label="Branch Name & Code"
                    isEdit={isEdit}
                    type="text"
                    style="fw-normal"
                    value={formContent?.bankDetails?.branchNameCode}
                    onChange={(e) => {
                      console.log(e.target.value);
                      let values = { ...formContent };
                      values.bankDetails.branchNameCode = e.target.value;
                      setFormContent(values);
                    }}
                  />
                </Typography>
              </div>
            </Typography>
          </div>
          {/* /Bank Details */}

          {/* <img src={footerImg} /> */}

          {/* Signatures */}
          {/* {!isEdit && ( */}
          <Row className="mt-5 pt-5 pb-5 no-print">
            <Col sm={6} className="mt-5 pt-5">
              <div
                style={{ height: "90px" }}
                className="d-flex justify-content-center align-items-end"
              >
                {formContent.clientSign != "" &&
                  formContent.clientSign != undefined && (
                    <img
                      src={`${Bucket}${formContent.clientSign}`}
                      height={80}
                      width={200}
                    />
                  )}
              </div>

              <p>{"_______________________________"}</p>
              <p className="my-1">
                <b>{"QUOTATION Acceptance"}</b>
              </p>

              {""}
            </Col>
            <Col sm={6} className="mt-5 pt-5">
              <div
                style={{ height: "90px" }}
                className="d-flex justify-content-center align-items-end"
              >
                {formContent?.adminSign?.trim()?.length == 0 ? (
                  <>
                    {isEdit && (
                      <div className="d-flex justify-content-center align-items-end">
                        <label
                          htmlFor="uploadSign"
                          className="active-btn ms-2 w-200 d-flex align-items-center justify-content-center cursor-pointer btn"
                        >
                          {uploadText}
                        </label>
                        <input
                          type="button"
                          hidden
                          id="uploadSign"
                          name="uploadSign"
                          onClick={() => {
                            setSignCanvas(true);
                          }}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="d-flex align-items-start">
                    <img
                      src={`${Bucket}${formContent.adminSign}`}
                      height={80}
                      width={200}
                    />
                    {isEdit && (
                      <>
                        <label
                          htmlFor="editSign"
                          className=" ms-2  d-flex align-items-center justify-content-center cursor-pointer btn p-0"
                        >
                          <img
                            src={editIcn}
                            height={18}
                            width={18}
                            className="ms-2 cursor-pointer"
                          />
                        </label>
                        <input
                          type="button"
                          hidden
                          id="editSign"
                          name="uploadSign"
                          onClick={() => {
                            setSignCanvas(true);
                          }}
                        />
                        <img
                          src={trashIcn}
                          height={18}
                          width={18}
                          className="ms-2 cursor-pointer"
                          onClick={() => {
                            let keysToDelete = [...mediaKeysToRemove];
                            keysToDelete.push(formContent.adminSign);
                            setMediaKeysToRemove(keysToDelete);

                            let values = { ...formContent };
                            values.adminSign = "";
                            setFormContent(values);
                          }}
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
              <p>{"_______________________________"}</p>
              <p className="my-1">
                <b>{"Shelozon Movers Packers & Logistics Pvt Ltd"}</b>
              </p>

              {""}
            </Col>
          </Row>
          {/* )} */}

          {/* {show == true && (
            <div
              id="content-id"
              className="show-print m-2"
              style={{ width: "794px" }}
            > */}

          {/* </div>
          )} */}
        </div>

        <PrintComponent>
          <QuotationFormDownload
            id="content-id"
            surveyData={surveyData}
            formContent={formContent}
          />
        </PrintComponent>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000} // Adjust the duration as needed
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={
              snackbarMessage?.includes("successful") ? "success" : "error"
            }
            onClose={handleCloseSnackbar}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>

        {/* Add signature Modal */}
        {isSignCanvas && (
          <MySignatureCanvas
            open={isSignCanvas}
            onClose={() => {
              setSignCanvas(false);
            }}
            onSuccess={(file) => {
              if (!isLoading) uploadFile(file);
            }}
          />
        )}

        {isShare && (
          <ShareWithClientsModal
            open={isShare}
            onClose={() => {
              setIsShare(false);
            }}
            onSuccess={(emails) => {
              shareWithClient(emails);
            }}
          />
        )}

        {isVerified && (
          <SuperAdminVerifationModal
            open={isVerified}
            onClose={() => {
              setIsVerified(false);
            }}
            onSuccess={() => {
              setIsEdit(true);
              setIsVerified(false);
            }}
            onError={() => {
              // shareWithClient(emails);
              setSnackbarMessage("Invalid Confirm Pin");
              setOpenSnackbar(true);
            }}
          />
        )}
      </MainTemplate>
    </>
  );
};
export default QuotationFormPage;
