import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Backdrop,
  Fade,
  Snackbar,
  InputLabel,
} from "@mui/material";
import crossImg from "../../imgs/cross.png";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { addRole } from "../../api/services/services";
import Grid from "@mui/material/Grid";
import EmailInput from "../atoms/EmailInput";
import MuiAlert from "@mui/material/Alert";

const AddRoleForm = ({ open, onClose, onSuccess }) => {
    const labelStyles = {
        color: "black", // Set the color to black
        fontWeight: "600", // Set the font weight to bold
        fontSize: '15px'
      };

  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(false);
  const [role, setRole] = useState(1);
  const [RoleError, setRoleError] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  //
  const add = () => {
    if (email != null && role != null) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isEmailValid = emailRegex.test(email);

      setEmailError(!isEmailValid);

      if (isEmailValid) {
        const body = {
          email: email,
          type: role,
        };

        addRole(body)
          .then((response) => {
            onSuccess();
          })
          .catch((error) => {
            setSnackbarMessage(error?.response?.data?.message);
            setOpenSnackbar(true);
            console.error(error?.response?.data?.message);
          });
      }
    } else {
      if (email == null) setEmailError(true);
      if (role == null) setRoleError(true);
    }
  };

  useEffect(() => {}, []);

  const disableAdd = email == null || role == '' || role == 1;

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="mx-3"
      >
        <Fade in={open}>
          <div className="modal-container">
            {" "}
            {/* CSS class for centering */}
            <div className="modal-content assign-modal px-4">
              <div className="d-flex">
                <img
                  src={crossImg}
                  height="26"
                  width="26"
                  className="ms-auto cursor-pointer"
                  onClick={() => onClose()}
                />{" "}
              </div>
              {/* Modal content goes here */}
              <p className="modal-head mb-0">Add New Role</p>
              <p className="modal-subhead">Add a new role here</p>

              {/*  */}
              <Grid item xs={12} className="mt-2 mt-md-3">
                <EmailInput
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  error={emailError}
                />
              </Grid>

              <Grid item xs={12} className="mt-2 mt-md-3">
                <FormControl
                  sx={{ m: 1, margin: "8px 0px", width: "100%" }}
                  variant="outlined"
                  fullWidth
                >
                  <InputLabel htmlFor="select-label" style={labelStyles} required>Role</InputLabel>
                  <Select
                    label="Select "
                    labelId="select-label"
                    id="select-label"
                    value={role}
                    onChange={(e) => {
                      setRole(e.target.value);
                    }}
                    required
                  >
                    <MenuItem disabled value="" selected>
                      {/* Select Role */} Select Role
                    </MenuItem>
                    <MenuItem value={"admin"}>Admin</MenuItem>
                    <MenuItem value={"surveyer"}>Surveyor</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid container spacing={2} className="mt-4">
                <Grid item xs={12}>
                  <Button
                    type="button"
                    variant="contained"
                    className={
                      disableAdd
                        ? "disabled-btn mt-0 mt-md-3 mb-3"
                        : "submit-btn mt-0 mt-md-3 mb-3"
                    }
                    disabled={disableAdd}
                    onClick={() => {
                      add();
                    }}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </Fade>
      </Modal>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={
            snackbarMessage?.includes("successful") ? "success" : "error"
          }
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

       
    </>
  );
};

export default AddRoleForm;
