// MainTemplate.js
import React, { useEffect, useState } from "react";
import Sidebar from "./../../organisms/SideBar";
import Header from "./../../organisms/Header";
import "./style.css";

const MainTemplate = ({ children }) => {
  let [temp, setTemp] = useState(false);

  useEffect(() => {
    let mainApp = document.getElementById("main-app");
    let isSide = localStorage.getItem("isSideBarOpen");

    if (isSide != null) {
      if (isSide == false || isSide == "false")
        mainApp.classList.add("app-toggler-mg");
      else mainApp.classList.remove("app-toggler-mg");
    } else {
      mainApp.classList.add("app-toggler-mg");
    }

    setTimeout(() => {
      setTemp(!temp);
    }, 1000);
  }, [temp]);

  return (
    <div>
      <Header />
      <div style={{ display: "flex" }}>
        <Sidebar />
        <main className="w-100 main-app" id="main-app">
          {children}
        </main>
      </div>
    </div>
  );
};

export default MainTemplate;
