// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preview-card {
  background-color: #00aff0;
  border-radius: 10px;
  color: #fff;
}

.fn {
  font-family: Aspira !important;
}

.white-circle {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MuiButtonBase-root-MuiRadio-root.Mui-checked{
  color: #00aff0 !important
}

.img-pr {
  height: 24px !important;
    width: 24px !important;
    margin: 10px !important;
}

.p-word{
  word-break: break-word !important;
}


@media print{
  .surve-name {
    font-size: 11px !important;
  }

  .surve-email {
    font-size: 11px !important;
  }

  .white-circle {
    height: 30px;
    width: 30px;
  }

  .img-pr {
    height: 22px !important;
    width: 22px !important;
  }

  .detail-row-mg {
    margin: 0px 2px !important;
  }

  .detail-row {
    padding: 2px 0px !important;
  }

  .preview-head{
    font-size: 13px !important;
  }
}

`, "",{"version":3,"sources":["webpack://./src/components/pages/SurveyPreviewPage/style.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,sBAAsB;EACtB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE;AACF;;AAEA;EACE,uBAAuB;IACrB,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;EACE,iCAAiC;AACnC;;;AAGA;EACE;IACE,0BAA0B;EAC5B;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,YAAY;IACZ,WAAW;EACb;;EAEA;IACE,uBAAuB;IACvB,sBAAsB;EACxB;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,2BAA2B;EAC7B;;EAEA;IACE,0BAA0B;EAC5B;AACF","sourcesContent":[".preview-card {\n  background-color: #00aff0;\n  border-radius: 10px;\n  color: #fff;\n}\n\n.fn {\n  font-family: Aspira !important;\n}\n\n.white-circle {\n  height: 36px;\n  width: 36px;\n  border-radius: 50%;\n  background-color: #fff;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.MuiButtonBase-root-MuiRadio-root.Mui-checked{\n  color: #00aff0 !important\n}\n\n.img-pr {\n  height: 24px !important;\n    width: 24px !important;\n    margin: 10px !important;\n}\n\n.p-word{\n  word-break: break-word !important;\n}\n\n\n@media print{\n  .surve-name {\n    font-size: 11px !important;\n  }\n\n  .surve-email {\n    font-size: 11px !important;\n  }\n\n  .white-circle {\n    height: 30px;\n    width: 30px;\n  }\n\n  .img-pr {\n    height: 22px !important;\n    width: 22px !important;\n  }\n\n  .detail-row-mg {\n    margin: 0px 2px !important;\n  }\n\n  .detail-row {\n    padding: 2px 0px !important;\n  }\n\n  .preview-head{\n    font-size: 13px !important;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
