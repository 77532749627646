import React, { useEffect, useState } from "react";
import {
  surveyResultPreview,
  sendQuote,
  sendResult,
} from "../../../api/services/services";
import backArrow from "../../../imgs/BackArrow.svg";
import MainTemplate from "../../templates/MainTemplate";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { Button, Snackbar } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Row, Col } from "react-bootstrap";
import SuccessModal from "../../molecules/SuccessModal";
import MuiAlert from "@mui/material/Alert";
import PaymentPlanForm from "../../molecules/PaymentPlanForm";
import callIcon from "../../../imgs/call.svg";
import calenderIcon from "../../../imgs/calendar.svg";
import calenderMove from "../../../imgs/calendarMinus.svg";
import emailIcon from "../../../imgs/email.svg";
import location from "../../../imgs/location.svg";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import SeaPaymentPlanForm from "../../molecules/SeaPaymentPlanForm";
import "./../SurveyPreviewPage/style.css";
import { Bucket } from "../../../api/config/apiConfig";
import PrintComponent from "../PrintComponent";
import SurveyPreviewDownload from "./download";
import moment from "moment";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import gifLoading from "../../../imgs/giphy.gif";
import LazyImage from "../LazyLoadImage";

const SurveyPreviewPage = () => {
  const id = window.location.pathname.split("/")[2];
  const [surveyData, setSurveyData] = useState(null);
  const navigate = useNavigate();

  const [success, setSuccess] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  var totalWeight = 0;
  var totalCbm = 0;
  var totalItems = 0;

  const [selectedMode, setSelectedMode] = useState("air"); // Initialize with an empty string or the default value

  const handleModeChange = (event) => {
    console.log(event.target.value);
    setSelectedMode(event.target.value);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // fetch single survey details
  const getSurvey = () => {
    surveyResultPreview(id)
      .then((response) => {
        setSurveyData(response?.data?.data);
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }
        console.error("Error fetching survey:", error?.response?.data);
      });
  };

  //
  const sendQuotation = (formData) => {
    let body = {};

    if (selectedMode == "road" || selectedMode == "air") {
      body = {
        surveyResultId: surveyData?.surveyDetail?.surveyResult?.uuid,
        shipmentMethod: selectedMode, // valid : air , road
        pricing: {
          doorToDoor: parseFloat(formData?.doorToDoor),
          doorToPort: parseFloat(formData?.doorToPort),
          portToDoor: parseFloat(formData?.portToDoor),
          transportService: parseFloat(formData?.transt),
          vehicleService: parseFloat(formData?.impExp),
          packingService: parseFloat(formData?.ship),
        },
      };
    } else {
      body = {
        surveyResultId: surveyData?.surveyDetail?.surveyResult?.uuid,
        shipmentMethod: selectedMode,
        seaShipmentPricing: [
          {
            containerSize: "twentyft",
            pricing: {
              doorToDoor: parseFloat(formData?.doorToDoor),
              doorToPort: parseFloat(formData?.doorToPort),
              portToDoor: parseFloat(formData?.portToDoor),
              transportService: parseFloat(formData?.transt),
              vehicleService: parseFloat(formData?.impExp),
              packingService: parseFloat(formData?.ship),
            },
          },
          {
            containerSize: "fortyft",
            pricing: {
              doorToDoor: parseFloat(formData?.doorToDoor1),
              doorToPort: parseFloat(formData?.doorToPort1),
              portToDoor: parseFloat(formData?.portToDoor1),
              transportService: parseFloat(formData?.transt1),
              vehicleService: parseFloat(formData?.impExp1),
              packingService: parseFloat(formData?.ship1),
            },
          },
        ],
      };
    }

    if (selectedMode != "") {
      sendQuote(body)
        .then((response) => {
          setSnackbarMessage("Quotation has been submitted successfully!");
          setOpenSnackbar(true);
          setTimeout(() => {
            navigate("/");
          }, 2000);
        })
        .catch((error) => {
          if (error?.response?.data?.statusCode == 401) {
            localStorage.clear();
            window.location.pathname = "/login";
          }
          setSnackbarMessage(error?.response?.data?.message);
          setOpenSnackbar(true);
          console.error("Error sending quote", error?.response?.data);
        });
    } else {
      setSnackbarMessage("Please Select Shipment Mode!");
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    getSurvey();
  }, []);

  const convertDate = (unixTimestamp) => {
    const date = new Date(unixTimestamp * 1000); // Multiply by 1000 to convert from seconds to milliseconds
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1 and format with leading zero
    const dd = String(date.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // 11th, 12th, 13th, etc.
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  // Helper function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const ordinalSuffix = getOrdinalSuffix(day);

    return `${day}${ordinalSuffix} ${month}, ${year}`;
  };

  let movableItems = 0;
  let nonmovableItems = 0;

  const [isLoading, setIsLoading] = useState(false);

  //
  const shareWithClient = () => {
    setIsLoading(true);
    const body = {
      surveyResultId: surveyData?.surveyDetail?.surveyResult?.uuid,
    };
    sendResult(body)
      .then((response) => {
        setSnackbarMessage(
          "Survey has been shared with the client successfully!"
        );
        setOpenSnackbar(true);
        setIsLoading(true);
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);
        console.error("Error sending quote", error?.response?.data);
        setIsLoading(true);
      });
  };

  return (
    <>
      <MainTemplate>
        <div className="no-print">
          <div className="d-flex align-items-center py-4 ">
            <div className="d-flex align-items-center  w-100">
              <div
                className="light-blue-color back-bg cursor-pointer"
                onClick={() => navigate(-1)}
              >
                <img src={backArrow} height="24" width="24" />{" "}
              </div>
              <div>
                <p className="main-head ps-3 mb-0">Survey Preview</p>
              </div>
              <div className="d-flex ms-auto">
                <Button
                  className="active-btn px-2"
                  onClick={() => {
                    shareWithClient();
                  }}
                >
                  {"Share"}
                </Button>
                <Button
                  className="active-btn ms-3 px-2"
                  onClick={() => {
                    setIsLoading(true);
                    // printAction();
                    var tempTitle = document.title;
                    document.title = `Survey Preview-${
                      surveyData?.surveyDetail?.name
                    }-${moment(new Date()).format("DD.MM.YYYY")}`;

                    window.print();
                    document.title = tempTitle;
                    setIsLoading(false);
                  }}
                >
                  {"Save as PDF"}
                </Button>
              </div>
            </div>
          </div>

          {surveyData && (
            <>
              <Row className="mx-0 px-2 py-3 preview-card mb-4">
                <Row>
                  <Col md={12} className="text-start">
                    <h3 className="fn">
                      <b>{surveyData?.surveyDetail?.name}</b>
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6} md={3} className="mb-2">
                    <div className="d-flex align-items-start">
                      <div className="white-circle mt-1">
                        <img src={emailIcon} className="img-pr" />
                      </div>
                      <div className="ps-2">
                        <p className="surve-name mb-0">
                          <b>Email</b>
                        </p>
                        <p className="surve-email mb-0 p-word">
                          {surveyData?.surveyDetail?.email}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col sm={6} md={2} className="mb-2">
                    <div className="d-flex align-items-start">
                      <div className="white-circle mt-1">
                        <img src={callIcon} className="img-pr" />
                      </div>
                      <div className="ms-2">
                        <p className="surve-name mb-0">Phone No.</p>
                        <p className="surve-email mb-0 p-word">
                          {surveyData?.surveyDetail?.loadPlacePhone}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col sm={6} md={3} className="mb-2">
                    <div className="d-flex align-items-start">
                      <div className="white-circle mt-1">
                        <img
                          src={location}
                          height={24}
                          width={24}
                          className="img-pr"
                        />
                      </div>
                      <div className="ms-2">
                        <p className="surve-name mb-0">Origin Address</p>
                        <p className="surve-email mb-0 p-word">
                          {surveyData?.surveyDetail?.loadPlaceStAddress},{" "}
                          {surveyData?.surveyDetail?.loadPlaceCity},{" "}
                          {surveyData?.surveyDetail?.loadPlaceCountry}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col sm={6} md={3} className="mb-2">
                    <div className="d-flex align-items-start">
                      <div className="white-circle mt-1">
                        <img
                          src={location}
                          height={24}
                          width={24}
                          className="img-pr"
                        />
                      </div>
                      <div className="ms-2">
                        <p className="surve-name mb-0">Destination Address</p>
                        <p className="surve-email mb-0 p-word">
                          {surveyData?.surveyDetail?.unloadPlaceStAddress},{" "}
                          {surveyData?.surveyDetail?.unloadPlaceCity},{" "}
                          {surveyData?.surveyDetail?.unloadPlaceCountry}
                        </p>
                      </div>
                    </div>
                  </Col>

                  {/* <Col sm={6} md={2} className="mb-2">
                  <div className="d-flex align-items-start">
                    <div className="white-circle mt-1">
                      <img src={calenderIcon} className="img-pr" />
                    </div>
                    <div className="ms-2">
                      <p className="surve-name mb-0">Created Date</p>
                      <p className="surve-email mb-0 p-word">
                        {surveyData?.surveyDetail?.createdAt
                          ? convertDate(surveyData?.surveyDetail?.createdAt)
                          : "-"}
                      </p>
                    </div>
                  </div>
                </Col> */}

                  <Col sm={6} md={3} className="mb-2">
                    <div className="d-flex align-items-start">
                      <div className="white-circle mt-1">
                        <img src={calenderMove} className="img-pr" />
                      </div>
                      <div className="ms-2">
                        <p className="surve-name mb-0">Removal Date</p>
                        <p className="surve-email mb-0 p-word">
                          {surveyData?.surveyDetail?.loadDate
                            ? formatDate(
                                convertDate(surveyData?.surveyDetail?.loadDate)
                              )
                            : "-"}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col sm={6} md={2} className="mb-2">
                    <div className="d-flex align-items-start">
                      <div className="white-circle mt-1">
                        <img src={calenderIcon} className="img-pr" />
                      </div>
                      <div className="ms-2">
                        <p className="surve-name mb-0">Survey Date</p>
                        <p className="surve-email mb-0 p-word">
                          {surveyData?.surveyDetail?.surveyDate
                            ? formatDate(
                                convertDate(
                                  surveyData?.surveyDetail?.surveyDate
                                )
                              )
                            : "-"}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Row>

              <Row className="mx-1">
                <Col sm={12} md={12} className="text-start px-0 fn">
                  <b>Survey Description</b>
                </Col>
              </Row>

              <Row
                className="mx-0 bg-white mt-1 py-1 px-2 px-md-4"
                style={{ borderRadius: "5px" }}
              >
                <Col sm={12} md={12} className="text-start px-0 fn">
                  <p className="surve-email mb-0 p-word">
                    {surveyData?.surveyDetail?.description}
                  </p>
                </Col>
              </Row>

              <Row className="mx-1  mt-3">
                <Col sm={12} md={12} className="text-start px-0 fn">
                  <b>Survey Overview</b>
                </Col>
              </Row>
              <Row
                className="mx-0 bg-white mt-3 py-1 px-2 px-md-4"
                style={{ borderRadius: "5px" }}
              >
                <div className="detail-row row">
                  <div className="col-3 text-start px-0 ">
                    <p className="surve-email mb-0 p-word">
                      <b>Room</b>
                    </p>
                  </div>

                  <div className="col-3 text-start px-0 ">
                    <p className="surve-email mb-0 p-word">
                      <b>Weight</b>
                    </p>
                  </div>
                  <div className="col-3 text-start px-0 ">
                    <p className="surve-email mb-0 p-word">
                      <b>CBM</b>
                    </p>
                  </div>
                  <div className="col-3 text-start px-0 ">
                    <p className="surve-email mb-0 p-word">
                      <b>Items</b>
                    </p>
                  </div>
                </div>
                {surveyData?.roomWiseCalculation?.length > 0 &&
                  surveyData?.roomWiseCalculation?.map((cat, index) => {
                    totalWeight = totalWeight + cat?.totalWeightKg;
                    totalCbm = totalCbm + cat?.totalCbm;
                    totalItems = totalItems + cat?.totalItems;
                    return (
                      <div className="detail-row row">
                        <div className="col-3 text-start px-0 ">
                          <p className="surve-email mb-0 p-word">
                            {cat?.roomName ? cat?.roomName : ""}
                          </p>
                        </div>

                        <div className="col-3 text-start px-0 ">
                          <p className="surve-email mb-0 p-word">
                            {cat?.totalWeightKg
                              ? `${cat?.totalWeightKg?.toFixed(4)} kg`
                              : "0 kg"}
                          </p>
                        </div>
                        {cat?.totalCbm ? (
                          <div className="col-3 text-start px-0 ">
                            <p className="surve-email mb-0 p-word">
                              {`${cat?.totalCbm?.toFixed(4)} m`}
                              <sup>3</sup>
                            </p>
                          </div>
                        ) : (
                          <div className="col-3 text-start px-0 ">
                            <p className="surve-email mb-0 p-word">
                              {"0 m"}
                              <sup>3</sup>
                            </p>
                          </div>
                        )}
                        <div className="col-3 text-start px-0 ">
                          <p className="surve-email mb-0 p-word">
                            {cat?.totalItems ? cat?.totalItems : "-"}
                          </p>
                        </div>
                      </div>
                    );
                  })}

                <div className="detail-row-last row">
                  <div className="col-3 text-start px-0 ">
                    <p className="surve-email mb-0 p-word">
                      <b>Total</b>
                    </p>
                  </div>

                  <div className="col-3 text-start px-0 ">
                    <p className="surve-email mb-0 p-word">
                      <b>
                        {totalWeight?.toFixed(4)}
                        {" kg"}
                      </b>
                    </p>
                  </div>
                  <div className="col-3 text-start px-0 ">
                    <p className="surve-email mb-0 p-word">
                      <b>
                        {totalCbm?.toFixed(4)} m<sup>3</sup>
                      </b>
                    </p>
                  </div>
                  <div className="col-3 text-start px-0 ">
                    <p className="surve-email mb-0 p-word">
                      <b>{totalItems}</b>
                    </p>
                  </div>
                </div>
              </Row>

              {surveyData?.surveyDetail?.status == "submitted" && (
                <>
                  {surveyData?.surveyDetail?.surveyResult?.surveyRooms?.length >
                    0 && (
                    <Row className="mx-1 mb-1 mt-4">
                      <Col sm={12} md={12} className="text-start px-0 ">
                        <b>Rooms Detail</b>
                      </Col>
                    </Row>
                  )}
                </>
              )}

              {surveyData?.surveyDetail?.surveyResult?.surveyRooms?.length >
                0 &&
                surveyData?.surveyDetail?.surveyResult?.surveyRooms?.map(
                  (sr, ind) => {
                    return (
                      <Row
                        className="bg-white my-4 mx-0  p-3 py-4 mb-4"
                        style={{ borderRadius: "10px" }}
                      >
                        <Row className="">
                          <Col
                            sm={12}
                            md={12}
                            className="text-start px-0 left-col"
                          >
                            <h4 className="fn text-capitalize">
                              <b>
                                {ind + 1}
                                {". "}
                                {sr?.name}
                              </b>
                            </h4>
                          </Col>
                        </Row>
                        <Row>
                          {/* Images */}
                          <Col sm={6} md={6} className="px-10">
                            <div className="d-flex flex-wrap">
                              {sr?.images?.map((image, ind) => {
                                return (
                                  <>
                                    <div className="me-2 mb-2">
                                      <LazyImage
                                        key={ind}
                                        src={Bucket + image}
                                        placeholderSrc={gifLoading}
                                        height={290}
                                        width={210}
                                        alt={`Image ${ind}`}
                                      />
                                      {/* <LazyLoadImage
                                        key={ind}
                                        src={Bucket + image}
                                        placeholderSrc={gifLoading}
                                        height={290}
                                        width={210}
                                        // style={{ borderRadius: "10px" }}
                                       
                                        alt="Image Alt"
                                        effect="blur"
                                      /> */}
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </Col>

                          {/* Details */}
                          <Col sm={6} md={6} className="px-10 ">
                            <Row>
                              {/* Moving */}
                              <Col
                                sm={12}
                                md={12}
                                className="px-0 bg-white card-border"
                              >
                                {/* <div className="bg-white card-border"> */}
                                <Row
                                  className="px-3 mx-0"
                                  style={{
                                    backgroundColor: "#00aff0 ",
                                    color: "white",
                                  }}
                                >
                                  <Col
                                    sm={12}
                                    md={12}
                                    className="text-start px-0 left-col"
                                  >
                                    <h5 className="fn text-capitalize my-2">
                                      <b>Moving</b>
                                    </h5>
                                  </Col>
                                </Row>

                                {sr?.roomItems?.map((item) => {
                                  if (item?.type == "movable")
                                    movableItems = movableItems + 1;
                                  if (item?.type == "nonmovable")
                                    nonmovableItems = nonmovableItems + 1;

                                  return <></>;
                                })}

                                {movableItems > 0 ? (
                                  <>
                                    {sr?.roomItems?.map((item) => {
                                      return (
                                        <>
                                          {item?.type == "movable" && (
                                            <Row className="detail-row mx-3 detail-row-mg">
                                              <Col
                                                xs={7}
                                                sm={6}
                                                md={7}
                                                className="text-start px-0 left-col pt-2 card-item"
                                              >
                                                {item?.name ? item?.name : "-"}
                                              </Col>
                                              <Col
                                                xs={2}
                                                sm={2}
                                                md={2}
                                                className="text-end px-0 left-col pt-2 card-item"
                                                style={{ fontWeight: "500" }}
                                              >
                                                {item?.quantity != undefined
                                                  ? `${item?.quantity}x`
                                                  : "-"}
                                              </Col>
                                              <Col
                                                xs={3}
                                                sm={4}
                                                md={3}
                                                className="text-end px-0 right-col pt-2 card-item"
                                              >
                                                {item?.cbm != undefined
                                                  ? item?.cbm?.toFixed(4)
                                                  : "-"}{" "}
                                                m<sup>3</sup>
                                              </Col>
                                            </Row>
                                          )}
                                        </>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <Row className="detail-row mx-3 text-center">
                                    <i>No Items</i>
                                  </Row>
                                )}

                                {/* </div> */}
                              </Col>

                              {/* Not Moving */}
                              <Col
                                sm={12}
                                md={12}
                                className="px-0 bg-white card-border mt-4"
                              >
                                {/* <div className="bg-white card-border"> */}
                                <Row
                                  className="px-3 mx-0"
                                  style={{
                                    backgroundColor: "#00aff0 ",
                                    color: "white",
                                  }}
                                >
                                  <Col
                                    sm={12}
                                    md={12}
                                    className="text-start px-0 left-col"
                                  >
                                    <h5 className="fn text-capitalize my-2">
                                      <b>Not Moving</b>
                                    </h5>
                                  </Col>
                                </Row>
                                {nonmovableItems > 0 ? (
                                  <>
                                    {sr?.roomItems?.map((item) => {
                                      return (
                                        <>
                                          {item?.type == "nonmovable" && (
                                            <Row className="detail-row mx-3 detail-row-mg">
                                              <Col
                                                xs={7}
                                                sm={6}
                                                md={7}
                                                className="text-start px-0 left-col pt-2 card-item"
                                              >
                                                {item?.name ? item?.name : "-"}
                                              </Col>
                                              <Col
                                                xs={2}
                                                sm={2}
                                                md={2}
                                                className="text-end px-0 left-col pt-2 card-item"
                                                style={{ fontWeight: "500" }}
                                              >
                                                {item?.quantity != undefined
                                                  ? `${item?.quantity}x`
                                                  : "-"}
                                              </Col>
                                              <Col
                                                xs={3}
                                                sm={4}
                                                md={3}
                                                className="text-end px-0 right-col pt-2 card-item"
                                              >
                                                {item?.cbm != undefined
                                                  ? item?.cbm?.toFixed(4)
                                                  : "-"}{" "}
                                                m<sup>3</sup>
                                              </Col>
                                            </Row>
                                          )}
                                        </>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <Row className="detail-row mx-3 text-center">
                                    <i>No Items</i>
                                  </Row>
                                )}

                                {/* </div> */}
                              </Col>

                              {/* Packing Material */}
                              <Col
                                sm={12}
                                md={12}
                                className="px-0 bg-white card-border mt-4"
                              >
                                {/* <div className="bg-white card-border"> */}
                                <Row
                                  className="px-3 mx-0"
                                  style={{
                                    backgroundColor: "#00aff0 ",
                                    color: "white",
                                  }}
                                >
                                  <Col
                                    sm={12}
                                    md={12}
                                    className="text-start px-0 left-col"
                                  >
                                    <h5 className="fn text-capitalize my-2">
                                      <b>Packing Material</b>
                                    </h5>
                                  </Col>
                                </Row>

                                {sr?.packingMaterial?.map((pack) => {
                                  return (
                                    <>
                                      <Row className="detail-row mx-3 detail-row-mg">
                                        <Col
                                          xs={7}
                                          sm={6}
                                          md={7}
                                          className="text-start px-0 left-col pt-2 card-item"
                                        >
                                          {pack?.name ? pack?.name : "-"}
                                        </Col>
                                        <Col
                                          xs={2}
                                          sm={2}
                                          md={2}
                                          className="text-end px-0 left-col pt-2 card-item"
                                          style={{ fontWeight: "500" }}
                                        >
                                          {pack?.quantity != undefined
                                            ? `${pack?.quantity}x`
                                            : "-"}
                                        </Col>
                                        <Col
                                          xs={3}
                                          sm={4}
                                          md={3}
                                          className="text-end px-0 right-col pt-2 card-item"
                                        >
                                          {pack?.cbm != undefined
                                            ? pack?.cbm?.toFixed(4)
                                            : "-"}{" "}
                                          m<sup>3</sup>
                                        </Col>
                                      </Row>
                                    </>
                                  );
                                })}

                                {/* </div> */}
                              </Col>

                              {/* Remarks */}
                              <Col
                                sm={12}
                                md={12}
                                className="px-0 bg-white card-border mt-4"
                              >
                                {/* <div className="bg-white card-border"> */}
                                <Row
                                  className="px-3 mx-0"
                                  style={{
                                    backgroundColor: "#00aff0 ",
                                    color: "white",
                                  }}
                                >
                                  <Col
                                    sm={12}
                                    md={12}
                                    className="text-start px-0 left-col"
                                  >
                                    <h5 className="fn text-capitalize my-2">
                                      <b>Remarks</b>
                                    </h5>
                                  </Col>
                                </Row>
                                <Row className="detail-row mx-3 detail-row-mg">
                                  <Col
                                    sm={12}
                                    md={12}
                                    className="text-start px-0 left-col pt-2 card-item"
                                  >
                                    {sr?.remarks ? sr?.remarks : "-"}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Row>
                    );
                  }
                )}

              {/* Survey Overview*/}
              {/* <Accordion className="mb-4">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-content"
                id="panel-header"
              >
                <Typography className="accord-head">Survey Overview</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Row
                    className="mx-0 bg-white mt-3 py-1 px-2 px-md-4"
                    style={{ borderRadius: "5px" }}
                  >
                    <div className="detail-row row">
                      <div className="col-3 text-start px-0 ">
                        <p className="surve-email mb-0 p-word">
                          <b>Items</b>
                        </p>
                      </div>
                      <div className="col-3 text-start px-0 ">
                        <p className="surve-email mb-0 p-word">
                          <b>Room</b>
                        </p>
                      </div>
                      <div className="col-3 text-start px-0 ">
                        <p className="surve-email mb-0 p-word">
                          <b>Weight</b>
                        </p>
                      </div>
                      <div className="col-3 text-start px-0 ">
                        <p className="surve-email mb-0 p-word">
                          <b>CBM</b>
                        </p>
                      </div>
                    </div>
                    {surveyData?.roomWiseCalculation?.length > 0 &&
                      surveyData?.roomWiseCalculation?.map((cat, index) => {
                        totalWeight = totalWeight + cat?.totalWeight;
                        totalCbm = totalCbm + cat?.totalCbm;
                        totalItems = totalItems + cat?.totalItems;
                        return (
                          <div className="detail-row row">
                            <div className="col-3 text-start px-0 ">
                              <p className="surve-email mb-0 p-word">
                                {cat?.totalItems ? cat?.totalItems : "-"}
                              </p>
                            </div>
                            <div className="col-3 text-start px-0 ">
                              <p className="surve-email mb-0 p-word">
                                {cat?.roomName ? cat?.roomName : ""}
                              </p>
                            </div>
                            <div className="col-3 text-start px-0 ">
                              <p className="surve-email mb-0 p-word">
                                {cat?.totalWeightKg
                                  ? `${cat?.totalWeightKg} KGS`
                                  : "-"}
                              </p>
                            </div>
                            {cat?.totalCbm ? (
                              <div className="col-3 text-start px-0 ">
                                <p className="surve-email mb-0 p-word">
                                  {`${cat?.totalCbm} cm`}
                                  <sup>3</sup>
                                </p>
                              </div>
                            ) : (
                              <div className="col-3 text-start px-0 ">
                                {"-"}
                              </div>
                            )}
                          </div>
                        );
                      })}

                    <div className="detail-row-last row">
                      <div className="col-3 text-start px-0 ">
                        <p className="surve-email mb-0 p-word">
                          <b>Total</b>
                        </p>
                      </div>
                      <div className="col-3 text-start px-0 ">
                        <p className="surve-email mb-0 p-word">
                          <b>{totalWeight} kg</b>
                        </p>
                      </div>
                      <div className="col-3 text-start px-0 ">
                        <p className="surve-email mb-0 p-word">
                          <b>
                            {totalCbm} cm<sup>3</sup>
                          </b>
                        </p>
                      </div>
                      <div className="col-3 text-start px-0 ">
                        <p className="surve-email mb-0 p-word">
                          <b>{totalItems}</b>
                        </p>
                      </div>
                    </div>
                  </Row>
                </Typography>
              </AccordionDetails>
            </Accordion> */}

              {/* {surveyData?.surveyDetail?.surveyResult?.status != "quotesent" ? (
              <>
                <Row className="mx-1">
                  <Col sm={12} md={12} className="text-start px-0 fn">
                    <b>Shipment Mode Selection</b>
                  </Col>
                </Row>
                <Row
                  className="mx-0  bg-white mt-3 py-1"
                  style={{ borderRadius: "5px" }}
                >
                  <Col sm={12} md={12}>
                    <RadioGroup
                      aria-label="mode"
                      name="mode"
                      value={selectedMode}
                      onChange={handleModeChange}
                      row
                    >
                      <FormControlLabel
                        value="sea"
                        control={<Radio />}
                        label="By Sea"
                        className="me-5 fn"
                      />
                      <FormControlLabel
                        value="air"
                        control={<Radio />}
                        label="By Air"
                        className="me-5 fn"
                      />
                      <FormControlLabel
                        value="road"
                        control={<Radio />}
                        label="By Road"
                        className="fn"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
                <Row className="mx-1 mt-4">
                  <Col sm={12} md={12} className="text-start px-0 fn">
                    <b>Shipment Payment Plans</b>
                  </Col>
                </Row>

                {selectedMode == "road" || selectedMode == "air" ? (
                  <PaymentPlanForm onSubmit={sendQuotation} />
                ) : (
                  <SeaPaymentPlanForm onSubmit={sendQuotation} />
                )}
              </>
            ) : (
              <>
                <Row className="mx-1 mt-3">
                  <Col sm={12} md={12} className="text-start px-0 fn">
                    <b>Shipment Mode</b>
                  </Col>
                </Row>
                <Row
                  className="mx-0 bg-white mt-3 py-2 text-start px-1"
                  style={{ borderRadius: "5px" }}
                >
                  <Col sm={12} md={12} className="text-capitalize">
                    <p className="surve-email mb-0 p-word">
                      {" "}
                      By{" "}
                      {
                        surveyData?.surveyDetail?.surveyResult?.surveyQuote
                          ?.shipmentMethod
                      }
                    </p>
                  </Col>
                </Row>

                <Row className="mx-1 mt-4">
                  <Col sm={12} md={12} className="text-start px-0 fn">
                    <b>Shipment Payment Details</b>
                  </Col>
                </Row>

                {surveyData?.surveyDetail?.surveyResult?.surveyQuote
                  ?.shipmentMethod == "air" ||
                surveyData?.surveyDetail?.surveyResult?.surveyQuote
                  ?.shipmentMethod == "road" ? (
                  <Row
                    className="mx-0 bg-white mt-3 py-2 text-start px-1 px-md-3 "
                    style={{ borderRadius: "5px" }}
                  >
                    <Col
                      sm={6}
                      md={4}
                      lg={2}
                      className="text-capitalize col-b-right"
                    >
                      <p className="mode-grey">Door to Door</p>
                      <p className="mode-black">
                        {
                          surveyData?.surveyDetail?.surveyResult?.surveyQuote
                            ?.pricing?.doorToDoor
                        }
                      </p>
                    </Col>

                    <Col
                      sm={6}
                      md={4}
                      lg={2}
                      className="text-capitalize col-b-right"
                    >
                      <p className="mode-grey">Door to Port</p>
                      <p className="mode-black">
                        {
                          surveyData?.surveyDetail?.surveyResult?.surveyQuote
                            ?.pricing?.doorToPort
                        }
                      </p>
                    </Col>

                    <Col
                      sm={6}
                      md={4}
                      lg={2}
                      className="text-capitalize col-b-right"
                    >
                      <p className="mode-grey">Port to Door</p>
                      <p className="mode-black">
                        {
                          surveyData?.surveyDetail?.surveyResult?.surveyQuote
                            ?.pricing?.portToDoor
                        }
                      </p>
                    </Col>

                    <Col
                      sm={6}
                      md={4}
                      lg={2}
                      className="text-capitalize col-b-right"
                    >
                      <p className="mode-grey">Transportation Services</p>
                      <p className="mode-black">
                        {
                          surveyData?.surveyDetail?.surveyResult?.surveyQuote
                            ?.pricing?.transportService
                        }
                      </p>
                    </Col>
                    <Col
                      sm={6}
                      md={4}
                      lg={2}
                      className="text-capitalize col-b-right"
                    >
                      <p className="mode-grey">
                        Vehicle Import & Export Services
                      </p>
                      <p className="mode-black">
                        {
                          surveyData?.surveyDetail?.surveyResult?.surveyQuote
                            ?.pricing?.vehicleService
                        }
                      </p>
                    </Col>

                    <Col
                      sm={6}
                      md={4}
                      lg={2}
                      className="text-capitalize col-b-right"
                    >
                      <p className="mode-grey">Shipping & Packing Services</p>
                      <p className="mode-black">
                        {
                          surveyData?.surveyDetail?.surveyResult?.surveyQuote
                            ?.pricing?.packingService
                        }
                      </p>
                    </Col>
                  </Row>
                ) : (
                  <>
                    <Row
                      className="mx-0 bg-white mt-3 py-2 text-start px-1 px-md-3 "
                      style={{ borderRadius: "5px" }}
                    >
                      {surveyData?.surveyDetail?.surveyResult?.surveyQuote?.seaShipmentPricing?.map(
                        (pr, index) => {
                          return (
                            <>
                              <Row className="mx-0 mt-3 py-2 text-start px-1 ">
                                {pr?.containerSize == "twentyft" ? (
                                  <b>{index + 1}. Twenty Feet Container</b>
                                ) : (
                                  <b>{index + 1}. Forty Feet Container</b>
                                )}
                              </Row>

                              <Row className="mx-0 mt-3 py-2 text-start px-2 ">
                                <Col
                                  sm={6}
                                  md={4}
                                  lg={2}
                                  className="text-capitalize col-b-right"
                                >
                                  <p className="mode-grey">Door to Door</p>
                                  <p className="mode-black">
                                    {pr?.pricing?.doorToDoor}
                                  </p>
                                </Col>

                                <Col
                                  sm={6}
                                  md={4}
                                  lg={2}
                                  className="text-capitalize col-b-right"
                                >
                                  <p className="mode-grey">Door to Port</p>
                                  <p className="mode-black">
                                    {pr?.pricing?.doorToPort}
                                  </p>
                                </Col>

                                <Col
                                  sm={6}
                                  md={4}
                                  lg={2}
                                  className="text-capitalize col-b-right"
                                >
                                  <p className="mode-grey">Port to Door</p>
                                  <p className="mode-black">
                                    {pr?.pricing?.portToDoor}
                                  </p>
                                </Col>

                                <Col
                                  sm={6}
                                  md={4}
                                  lg={2}
                                  className="text-capitalize col-b-right"
                                >
                                  <p className="mode-grey">
                                    Transportation Services
                                  </p>
                                  <p className="mode-black">
                                    {pr?.pricing?.transportService}
                                  </p>
                                </Col>
                                <Col
                                  sm={6}
                                  md={4}
                                  lg={2}
                                  className="text-capitalize col-b-right"
                                >
                                  <p className="mode-grey">
                                    Vehicle Import & Export Services
                                  </p>
                                  <p className="mode-black">
                                    {pr?.pricing?.vehicleService}
                                  </p>
                                </Col>

                                <Col
                                  sm={6}
                                  md={4}
                                  lg={2}
                                  className="text-capitalize col-b-right"
                                >
                                  <p className="mode-grey">
                                    Shipping & Packing Services
                                  </p>
                                  <p className="mode-black">
                                    {pr?.pricing?.packingService}
                                  </p>
                                </Col>
                              </Row>
                            </>
                          );
                        }
                      )}
                    </Row>
                  </>
                )}
              </>
            )} */}
            </>
          )}
        </div>

        <PrintComponent>
          <SurveyPreviewDownload id="content-id" surveyData={surveyData} />
        </PrintComponent>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000} // Adjust the duration as needed
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={
              snackbarMessage?.includes("successful") ? "success" : "error"
            }
            onClose={handleCloseSnackbar}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </MainTemplate>
    </>
  );
};
export default SurveyPreviewPage;
