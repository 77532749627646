import React from "react";
import MainTemplate from "../../templates/MainTemplate";

const Quotations = () => {
  return (
    <>
      <MainTemplate>
        <div className="d-flex align-items-center py-4">
          <p className="main-head ps-1 mb-0">Quotations</p>
        </div>
      </MainTemplate>
    </>
  );
};

export default Quotations;
