import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import "./style.css";
import logo from "../../../imgs/logo-final.png";
import CreateAccountForm from "../../molecules/CreateAccountForm";
import downloadAuth from "../../../imgs/download-auth.svg";
import appleStore from "../../../imgs/app-store-badge.png";
import playStore from "../../../imgs/google-store-badge.png";
import { useNavigate, useLocation } from "react-router-dom";
import { confirmInvite } from "../../../api/services/services";
import { createPassword } from "../../../api/services/services";
import successImg from "../../../imgs/succes.svg";

const CreateAccountPage = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const navigate = useNavigate();
  const [isCreate, setIsCreate] = useState(true);
  const [enable, setEnable] = useState(false);

  const [success, setSucces] = useState(false);

  const location = useLocation();
  const urlData = new URLSearchParams(location.search);
  const token = urlData.get("token");
  const type = urlData.get("type");

  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [faToSend, setFatoSend] = useState("");
  const [emailToRegister, setEmailToRegister] = useState("");

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleRegister = (formData) => {
    console.log("Create data:", formData);
    const body = {
      type: type,
      password: formData.password,
      passwordConfirmation: formData.confirmPassword,
      name: formData.name,
    };

    createPassword(token, body)
      .then((response) => {
        console.log("Password created");
        setIsValid(true);

        if (type == "admin") {
          setFatoSend(response?.data?.data?.token);
          localStorage.setItem("fa-token", response?.data?.data?.token);
          localStorage.setItem("isAuthPending", true);
          setIsCreate(false);
          setEnable(true);
        } else {
          setIsCreate(false);
          setSucces(true);
        }
      })
      .catch((error) => {
        console.error("Error creating password:", error);
        if (error?.response?.data?.statusCode == 1002) {
          setSnackbarMessage("Token has been expired");

          setOpenSnackbar(true);
        } else {
          setSnackbarMessage(error?.response?.data?.message);
          setOpenSnackbar(true);
        }
      });
  };

  useEffect(() => {
    let temp = localStorage.getItem("isAuthPending");

    if (temp != null) {
      let val = localStorage.getItem("fa-token");
      setFatoSend(val);
      setIsCreate(false);
      setIsValid(true);
      setIsLoading(false);
      setEnable(true);
    } else {
      confirmInvite(token, type)
        .then((response) => {
          console.log("Email Confirmed");
          setIsValid(true);
          setIsLoading(false);
          setEmailToRegister(response?.data?.data?.email);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Error fetching user data:", error.response.data);
          if (error.response.data.statusCode == 1002) {
            setSnackbarMessage("Token has been expired");
            setOpenSnackbar(true);
          } else {
            setSnackbarMessage(error.response.data.message);
            setOpenSnackbar(true);
          }
        });
    }
  }, []);

  return (
    <>
      {isValid ? (
        <div className="login-bg">
          <Container
            maxWidth="xs"
            sx={{
              display: "flex",
              alignItems: "center",
              minHeight: "100vh",
              justifyContent: "center",
            }}
          >
            {isCreate && (
              <Card
                sx={{ pb: 1 }}
                style={{ borderRadius: "15px" }}
                className="create-card"
              >
                <CardContent>
                  <img
                    src={logo}
                    alt="Shezlon Logo"
                    width="260"
                    height="70"
                    className="mt-2"
                  //  style={{transform: 'translate(0px , 15px)'}}
                  />
                  <Typography variant="p" align="center" gutterBottom>
                  <p className="success-title p-0 mb-1 mt-3"  style={{position: 'relative',
                zIndex: '999'}}>Create Account</p>
                  </Typography>
                  <Typography variant="p" align="center" gutterBottom>
                    <p className="desc mb-4">
                      {" "}
                      Create your account to continue
                    </p>
                  </Typography>
                  <CreateAccountForm
                    onSubmit={handleRegister}
                    emailToRegister={emailToRegister}
                  />
                </CardContent>
              </Card>
            )}
            {enable && (
              <Card
                sx={{ py: 2, pb: 0 }}
                style={{ borderRadius: "15px" }}
                className="create-card mx-2"
              >
                <CardContent>
                  <Typography variant="p" align="center" gutterBottom>
                    <p className="success-title p-0 mb-1">
                      Enable Authenticator
                    </p>
                  </Typography>
                  <Typography variant="p" align="center" gutterBottom>
                    <p className="desc mb-2 mb-md-4">
                      Authenticator app should be installed on your device to
                      use 2FA
                    </p>
                  </Typography>
                  <img
                    src={downloadAuth}
                    alt="Google Auth"
                    className="mb-2 mb-md-3 mt-2 auth-img"
                  />
                  <Typography variant="p" align="center" gutterBottom>
                    <p className="p-0 mb-1 text-uppercase download mb-0">
                      Download App
                    </p>
                  </Typography>
                  <div className="d-flex justify-content-center mb-0 mb-md-1">
                    <img
                      src={playStore}
                      alt="PlayStore"
                      className="me-3 play-store"
                    />
                    <img
                      src={appleStore}
                      alt="PlayStore"
                      className="play-store"
                    />
                  </div>
                  <Grid item xs={12}>
                    <Button
                      type="button"
                      variant="contained"
                      className="submit-btn mt-0 mt-md-2 mb-3 text-capitalize"
                      onClick={() => {
                        ///*** make them some variable separetly */
                        navigate("/two-factor-auth", {
                          state: { token: faToSend },
                        });
                      }}
                    >
                      Next
                    </Button>
                  </Grid>
                </CardContent>
              </Card>
            )}

            {success && (
              <Card
                sx={{ py: 3, pb: 1 }}
                style={{ borderRadius: "15px" }}
                className="success-card mx-2"
              >
                <CardContent>
                  <img
                    src={successImg}
                    alt="success"
                    width="84"
                    height="84"
                    className="mb-4"
                  />
                  <Typography variant="p" align="center" gutterBottom>
                    <h4 className="success-title p-0 mb-1 text-capitalize">
                      Account created successfully
                    </h4>
                  </Typography>
                  <Typography variant="p" align="center" gutterBottom>
                    <p className="desc mb-5">
                      {" "}
                      Your onboarding has been completed successfully.
                    </p>
                  </Typography>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      className="disabled-btn mt-1 mb-3 text-capitalize"
                    >
                      Continue on App
                    </Button>
                  </Grid>
                </CardContent>
              </Card>
            )}
          </Container>
        </div>
      ) : (
        <>
          {isLoading ? (
            <div> Loading...</div>
          ) : (
            <div> Ooops... Something went wrong...</div>
          )}
        </>
      )}

      {/*  */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={
            snackbarMessage?.includes("successful") ? "success" : "error"
          }
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default CreateAccountPage;
