import React from "react";
import {useNavigate} from 'react-router-dom'

const CustomCheckbox = ({ label, value, id , path, disabled, onChange }) => {
 
  const navigate = useNavigate()
  return (
    <div>
      {/* checkbox  */}
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          value={value}
          id={id}
          checked={value}
          disabled={disabled}
          onChange={onChange}
        />
        <label className={(disabled || path == '') ? `form-check-label ms-2` : `form-check-label ms-2 cursor-pointer hover-checklist`} htmlFor={id} onClick={()=>{
          if(!disabled && path != undefined)
           navigate(`${path}`)
        }} >
          {label}
        </label>
      </div>
      {/* /checkbox */}
    </div>
  );
};

export default CustomCheckbox;
