// Create a Molecule for the create account  form
import React, { useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import NameInput from "../atoms/NameInput";
import EmailInput from "../atoms/EmailInput";
import PasswordInput from "../atoms/PasswordInput";
import "./style.css";

const CreateAccountForm = ({ onSubmit, emailToRegister }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState(emailToRegister);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [matchPassword, setMatchPassword] = useState(true);

  const validatePassword = (password) => {
    // Check if the password length is between 8 and 15 characters
    if (password.length >= 8 && password.length <= 15) {
      // Check if the password contains at least one lowercase letter
      if (/[a-z]/.test(password)) {
        // Check if the password contains at least one uppercase letter
        if (/[A-Z]/.test(password)) {
          // Check if the password contains at least one number
          if (/\d/.test(password)) {
            // Check if the password contains at least one symbol
            if (/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password)) {
              return true;
            }
          }
        }
      }
    }
      
    return false;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate email and password
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isEmailValid = emailRegex.test(email);
    const isPasswordValid = validatePassword(password);
    const isConfirmPasswordValid =  validatePassword(confirmPassword);
    let isMatch

    setEmailError(!isEmailValid);
    setPasswordError(!isPasswordValid);
    setConfirmPasswordError(!isConfirmPasswordValid);

    if(isPasswordValid && isConfirmPasswordValid){
    if(password === confirmPassword){
       setMatchPassword(true)
      isMatch = true
    }
    else{
      setMatchPassword(false)
      isMatch = false
    }
    }

    if (isEmailValid && isPasswordValid && isConfirmPasswordValid && isMatch) {
      onSubmit({email, name, password, confirmPassword});
    }
  };

  const isCreateButtonDisabled = !email || !password || !name || !confirmPassword;

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
     
        <Grid item xs={12} >
          <EmailInput
            value={emailToRegister}
            onChange={(e) => {
              // setEmail(e.target.value)
            }}
            error={emailError}
            disable={true}
          />
        </Grid>

        <Grid item xs={12} className="mt-3">
          <NameInput
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={nameError}
          />
        </Grid>
        
        <Grid item xs={12} className="mt-3">
          <PasswordInput
            value={password}
            onChange={(e) => {
              if((e.target.value === confirmPassword) && matchPassword == false)
              setMatchPassword(true)
              setPasswordError(false)
              setPassword(e.target.value)}
            }
            error={passwordError}
            fieldName={'Create Password'}
          />
        </Grid>

        <Grid item xs={12} className="mt-3">
          <PasswordInput
            value={confirmPassword}
            onChange={(e) => {
              if((password === e.target.value) && matchPassword == false)
              setMatchPassword(true)
              setConfirmPasswordError(false)
              setConfirmPassword(e.target.value)}
            }
            error={confirmPasswordError}
            fieldName={'Confirm Password'}
          />
          { !matchPassword && (
            <p className="error-text">Password and Confirm Password does not match</p>
          )
          }
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            className={
              isCreateButtonDisabled ? "disabled-btn mt-3 text-capitalize" : "submit-btn mt-3 text-capitalize"
            }
            disabled={isCreateButtonDisabled}
          >
           Create Account
          </Button>
        </Grid>
      
      </Grid>
    </form>
  );
};

export default CreateAccountForm;
