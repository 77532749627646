import React, { useEffect, useState } from "react";
import {
  getFormContent,
  getSingleSurvey,
  updateFormContent,
  surveyResultPreview,
  sharePreviewLink,
  addFormContent,
  imageUpload,
} from "../../../api/services/services";
import backArrow from "../../../imgs/BackArrow.svg";
import MainTemplate from "../../templates/MainTemplate";
import { useNavigate } from "react-router-dom";
import { Button, Snackbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import Input from "../../atoms/Input";
import ContactDetailField from "../../molecules/ContactDetailField";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useParams } from "react-router-dom";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import html2pdf from "html2pdf.js";
import ShareWithClientsModal from "../../molecules/ShareWithClients";
import DynamicText from "../../molecules/DynamicText";
import PrintComponent from "../PrintComponent";
import WorksheetImportPageClientSide from "./ClientSide/download";
import WorksheetImportDownload from "./ClientSide/download";
import { Bucket } from "../../../api/config/apiConfig";
import editIcn from "../../../imgs/edit.png";
import trashIcn from "../../../imgs/trash.png";
import LoadingModal from "../../molecules/LoadingModal";
import moment from "moment";
import MySignatureCanvas from "../../molecules/signature_canvas";
import { sendSuperAdminVerificationForEdit } from "../PrebidAgreement/creation/service";
import SuperAdminVerifationModal from "../PrebidAgreement/creation/super_admin_verification_modal";

const WorksheetImportPage = () => {
  const { surveyId, id, flg } = useParams();
  const [surveyData, setSurveyData] = useState(null);
  const navigate = useNavigate();

  const [formContentId, setFormContentId] = useState("");
  const [isEdit, setIsEdit] = useState(flg == "1" ? true : false);

  const [isLoading, setIsLoading] = useState(false);
  const [formContent, setFormContent] = useState({
    clientName: "",
    clientEmail: "",
    clientSign: "",
    organization: "",
    deliveryAddres: "",
    fileNo: "",
    contactNo: "",
    fax: "",
    email: "",
    entitlement1: "",
    estimatedWeight: "",
    actualWeight: "",
    entitlement2: "",
    estimatedVolume: "",
    actualVolume: "",
    deliveryStartedDate: "",
    deliveryCompletedDate: "",
    shiftingStartedDate: "",
    shiftingCompletedDate: "",
    wareHouseInDate: "",
    wareHouseOutDate: "",
    shipmentDate: "",
    originDestination: "",
    shipmentMode: "",
    bolAWBNo: "",
    containerNo: "",
    vessel: "",
    ETDbyETA: "",
    airline: "",
    flightNo: "",
    remarks: "",
    managerSign: "",
    assistOperSign: "",
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [isShare, setIsShare] = useState(false);

  const [mediaKeysToSend, setMediaKeysToSend] = useState([]);
  const [mediaKeysToRemove, setMediaKeysToRemove] = useState([]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isSignCanvas, setSignCanvas] = useState(false);
  const [whichSign, setWhichSign] = useState("");

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  //
  const getSurveyPreview = () => {
    surveyResultPreview(surveyId)
      .then((response) => {
        setSurveyData(response?.data?.data?.surveyDetail);
        let values = { ...formContent };
        values.originAddress = `${response?.data?.data?.surveyDetail?.loadPlaceStAddress}, ${response?.data?.data?.surveyDetail?.loadPlaceCity}, ${response?.data?.data?.surveyDetail?.loadPlaceCountry}`;
        values.destinationAddress = `${response?.data?.data?.surveyDetail?.unloadPlaceStAddress}, ${response?.data?.data?.surveyDetail?.unloadPlaceCity}, ${response?.data?.data?.surveyDetail?.unloadPlaceCountry}`;
        values.deliveryAddres = `${response?.data?.data?.surveyDetail?.unloadPlaceStAddress}, ${response?.data?.data?.surveyDetail?.unloadPlaceCity}, ${response?.data?.data?.surveyDetail?.unloadPlaceCountry}`;
        values.customerName = `${response?.data?.data?.surveyDetail?.name}`;
        values.clientEmail = `${response?.data?.data?.surveyDetail?.email}`;
        values.fileNo = `${response?.data?.data?.surveyDetail?.id}`;
        values.email = `${response?.data?.data?.surveyDetail?.email}`;
        values.contactNo = `${response?.data?.data?.surveyDetail?.phone}`;

        values.originDestination = `${values.originAddress} / ${values.destinationAddress}`;

        let totalWeight = 0;
        let totalCbm = 0;
        response?.data?.data?.roomWiseCalculation?.map((cat, index) => {
          totalWeight = totalWeight + cat?.totalWeightKg;
          totalCbm = totalCbm + cat?.totalCbm;
        });

        values.estimatedWeight = totalWeight;
        values.estimatedVolume = totalCbm;

        setFormContent(values);
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }
      });
  };

  //
  const getSurvey = () => {
    getSingleSurvey(surveyId)
      .then((response) => {
        if (response?.data?.data?.status == "submitted") getSurveyPreview();
        else {
          setSurveyData(response?.data?.data);
          let values = { ...formContent };
          values.originAddress = `${response?.data?.data?.loadPlaceStAddress}, ${response?.data?.data?.loadPlaceCity}, ${response?.data?.data?.loadPlaceCountry}`;
          values.destinationAddress = `${response?.data?.data?.unloadPlaceStAddress}, ${response?.data?.data?.unloadPlaceCity}, ${response?.data?.data?.unloadPlaceCountry}`;
          values.deliveryAddres = `${response?.data?.data?.unloadPlaceStAddress}, ${response?.data?.data?.unloadPlaceCity}, ${response?.data?.data?.unloadPlaceCountry}`;
          values.customerName = `${response?.data?.data?.name}`;
          values.clientEmail = `${response?.data?.data?.email}`;
          values.fileNo = `${response?.data?.data?.id}`;
          values.email = `${response?.data?.data?.email}`;
          values.contactNo = `${response?.data?.data?.phone}`;

          values.originDestination = `${values.originAddress} / ${values.destinationAddress}`;

          setFormContent(values);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }
      });
  };

  //
  const addContent = () => {
    setIsLoading(true);
    const body = {
      formId: id,
      surveyId: surveyId,
      content: formContent,
      mediaKeys: mediaKeysToSend,
      removedMediaKeys: [],
    };
    addFormContent(body)
      .then(() => {
        setSnackbarMessage(
          "Worksheet Import Form has been added successfully!"
        );
        setOpenSnackbar(true);

        setTimeout(() => {
          navigate(-1);
          setIsLoading(false);
        }, 1000);
        setMediaKeysToSend([]);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  //
  const updateContent = () => {
    setIsLoading(true);
    const body = {
      formId: id,
      surveyId: surveyId,
      content: formContent,
      mediaKeys: mediaKeysToSend,
      removedMediaKeys: mediaKeysToRemove,
    };
    updateFormContent(body, formContentId)
      .then((res) => {
        setSnackbarMessage(
          "Worksheet Import Form has been updated successfully!"
        );
        setOpenSnackbar(true);
        getContent();
        setIsEdit(false);

        setIsLoading(false);
        setMediaKeysToSend([]);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  //
  const getContent = () => {
    setIsLoading(true);
    setMediaKeysToSend([]);
    getFormContent(id, surveyId)
      .then((res) => {
        setFormContentId(res.data.data.uuid);
        setFormContent(res.data.data.content);
        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (flg == "1") getSurvey();
    else getContent();
  }, []);

  const [show, setShow] = useState(false);
  const options = {
    // resolution: Resolution.HIGH,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      format: "letter",
      // default is 'portrait'
      orientation: "portrait",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/png",
      qualityRatio: 1,
    },
    overrides: {
      pdf: {
        compress: true,
      },
    },
  };

  //
  const generatePdf = () => {
    setShow(true);

    setTimeout(() => {
      const content = document.getElementById("content-to-convert"); // Replace with the ID of the element you want to convert to PDF

      const currentDateTime = new Date();
      const filename = `survey_${currentDateTime.toISOString()}.pdf`;

      const pdfOptions = {
        margin: 5,
        filename: filename,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
        }, // Set the page size here
      };

      let temp = html2pdf().from(content).set(pdfOptions).outputPdf();
      temp.save(filename);

      setShow(false);
    }, 3500);
  };

  const [downloadText, setDownloadText] = useState("download");

  const getTargetElement = () => document.getElementById("content-id");

  //
  const shareWithClient = (data) => {
    let ems = [];
    let emsInd = 0;
    data.map((dt) => {
      if (dt.trim().length != 0) {
        ems[emsInd] = dt;
        emsInd++;
      }
    });

    setIsLoading(true);
    const body = {
      surveyId: surveyId,
      formId: id,
      emails: ems,
      formRoute: "worksheet-import-preview",
    };
    sharePreviewLink(body)
      .then(() => {
        setSnackbarMessage(
          "Worksheet Import Form have been shared with the respective emails successfully!"
        );
        setOpenSnackbar(true);

        setIsLoading(false);
        setIsShare(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  const printAction = () => {
    const PAGE_HEIGHT = 1045;
    const printElement = document.getElementById("print-component");
    if (printElement) {
      printElement.classList.add("temp-class-for-height");
      const height = printElement.clientHeight - 500;
      const numberOfPage = Math.ceil(height / PAGE_HEIGHT);
      const heightWithSingleHeader = numberOfPage * PAGE_HEIGHT;
      let requiredHeight = heightWithSingleHeader;
      if (numberOfPage > 1) {
        const headerHeight =
          printElement.getElementsByTagName("thead")?.[0]?.clientHeight;
        const footerHeight =
          printElement.getElementsByTagName("tfoot")?.[0]?.clientHeight;
        requiredHeight -= (numberOfPage - 1) * (headerHeight + footerHeight);
      }
      printElement.style.height = `${requiredHeight}px`;
      printElement.classList.remove("temp-class-for-height");
    }
    window.print();
    if (printElement) {
      printElement.style.height = `auto`;
    }
  };

  const [uploadText1, setUploadText1] = useState("Upload Sign");
  const [uploadText2, setUploadText2] = useState("Upload Sign");

  //
  const uploadFile = async (file) => {
    setIsLoading(true);

    handleOpen();
    if (whichSign == "manager") setUploadText1("Uploading...");
    if (whichSign == "assist") setUploadText2("Uploading...");

    const fd = new FormData();
    fd.append("image", file);

    imageUpload(fd)
      .then((res) => {
        let values = { ...formContent };
        let dlt = [...mediaKeysToRemove];
        if (whichSign == "manager") {
          if (formContent.managerSign?.trim()?.length != 0)
            dlt.push(formContent.managerSign);
          values.managerSign = res?.data?.data?.key;
        }
        if (whichSign == "assist") {
          if (formContent.assistOperSign?.trim()?.length != 0)
            dlt.push(formContent.assistOperSign);
          values.assistOperSign = res?.data?.data?.key;
        }

        setMediaKeysToRemove(dlt);
        setFormContent(values);

        let md = [...mediaKeysToSend];
        md.push(res?.data?.data?.key);
        setMediaKeysToSend(md);

        setIsLoading(false);
        setUploadText1("Upload Sign");
        setUploadText2("Upload Sign");
        handleClose();
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setUploadText1("Upload Sign");
        setUploadText2("Upload Sign");

        setIsLoading(false);
        handleClose();
      });
  };

  const [isVerified, setIsVerified] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);

  const sendEditRequestToAdmin = () => {
    setIsEditLoading(true);
    sendSuperAdminVerificationForEdit()
      .then((response) => {
        setIsVerified(true);
        setIsEditLoading(false);
      })
      .catch((error) => {
        setIsVerified(false);
        setIsEditLoading(false);
      });
  };

  return (
    <>
      <MainTemplate>
        <div className="no-print">
          <div>
            <div className="d-block d-sm-flex align-items-center py-4">
              <div className="d-flex align-items-center ">
                <div
                  className="light-blue-color back-bg cursor-pointer"
                  onClick={() => navigate(-1)}
                >
                  <img src={backArrow} height="24" width="24" />{" "}
                </div>
                <div>
                  <p className="main-head ps-3 mb-0">Worksheet Import Form</p>
                </div>
              </div>
              <div className="d-flex ms-auto justify-content-center mt-2 mt-sm-0">
                {/*Edit Button */}
                {!isEdit && (
                  <Button
                    className="active-btn"
                    onClick={() => {
                      sendEditRequestToAdmin();
                    }}
                    disabled={isEditLoading}
                  >
                    Edit
                  </Button>
                )}

                {/*Share Button */}
                {!isEdit && (
                  <Button
                    className="active-btn ms-3"
                    onClick={() => {
                      setIsShare(true);
                    }}
                    disabled={isLoading}
                  >
                    Share
                  </Button>
                )}

                {/* Cancel button */}
                {isEdit && flg == "2" && (
                  <Button
                    className="active-btn ms-3"
                    onClick={() => {
                      getContent();
                      setIsEdit(false);
                    }}
                    disabled={isLoading}
                  >
                    Cancel
                  </Button>
                )}

                {/* Save Button */}
                {isEdit && (
                  <Button
                    className="active-btn ms-3"
                    onClick={() => {
                      if (flg == "1") addContent();
                      else updateContent();
                    }}
                    disabled={isLoading}
                  >
                    Save
                  </Button>
                )}

                {/* Download Button */}
                {!isEdit && (
                  <Button
                    className="active-btn ms-3 px-0"
                    onClick={() => {
                      setIsLoading(true);
                      // printAction();
                      var tempTitle = document.title;
                      document.title = `Work Sheet Import-${
                        formContent?.customerName
                      }-${moment(new Date()).format("DD.MM.YYYY")}`;

                      window.print();
                      document.title = tempTitle;
                      setIsLoading(false);
                    }}
                    disabled={isLoading}
                  >
                    {"Save as PDF"}
                  </Button>
                )}
              </div>
            </div>
          </div>
          {/* file detail*/}
          <div className="contact-detail d-flex mt-4">
            {/* left col */}
            <div
              className={`left-col ${
                !isEdit ? " w-50" : "w-50 pe-5"
              } text-start`}
            >
              <Typography variant="body1" gutterBottom>
                <ContactDetailField
                  label="Name"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"150px"}
                  value={formContent?.customerName}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.customerName = e.target.value;
                    setFormContent(values);
                  }}
                />
                <ContactDetailField
                  label="Organization"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"150px"}
                  value={formContent?.organization}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.organization = e.target.value;
                    setFormContent(values);
                  }}
                />
                <ContactDetailField
                  label="Delivery Address"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"150px"}
                  value={formContent?.deliveryAddres}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.deliveryAddres = e.target.value;
                    setFormContent(values);
                  }}
                />
              </Typography>
            </div>
            {/* right col */}
            <div
              className={`right-col ${
                !isEdit ? "w-50" : "w-50 ps-5"
              }  d-flex flex-column align-items-end pe-2`}
            >
              <div
                className=""
                style={isEdit ? { width: "-webkit-fill-available" } : {}}
              >
                <Typography
                  variant="body1"
                  className="text-start w-100"
                  gutterBottom
                >
                  <ContactDetailField
                    label="File No."
                    isEdit={isEdit}
                    type="text"
                    wrapperStyle="justify-content-start"
                    minWidth={"150px"}
                    value={formContent?.fileNo}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.fileNo = e.target.value;
                      setFormContent(values);
                    }}
                    disabled={true}
                  />
                  <ContactDetailField
                    label="Contact No"
                    isEdit={isEdit}
                    type="number"
                    wrapperStyle="justify-content-start"
                    minWidth={"150px"}
                    value={
                      formContent?.contactNo != "null" &&
                      formContent?.contactNo != undefined
                        ? formContent?.contactNo
                        : ""
                    }
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.contactNo = e.target.value;
                      setFormContent(values);
                    }}
                  />
                  <ContactDetailField
                    label="Fax"
                    isEdit={isEdit}
                    type="number"
                    wrapperStyle="justify-content-start"
                    minWidth={"150px"}
                    value={formContent?.fax}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.fax = e.target.value;
                      setFormContent(values);
                    }}
                  />
                  <ContactDetailField
                    label="Email"
                    isEdit={isEdit}
                    type="text"
                    wrapperStyle="justify-content-start"
                    minWidth={"150px"}
                    value={formContent?.email}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.email = e.target.value;
                      setFormContent(values);
                    }}
                  />
                </Typography>
              </div>
            </div>
          </div>
          {/* table */}
          <TableContainer component={Paper} className="mb-5 mt-4">
            <Table>
              <TableBody>
                {/* Entitlement1 */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Entitlement</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"shipper"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Entitlement"}
                        value={formContent?.entitlement1}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.entitlement1 = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.entitlement1
                          ? formContent?.entitlement1
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>
                {/* Estimated Weight */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Estimated Weight</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"consignee"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Estimated Weight"}
                        value={formContent?.estimatedWeight}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.estimatedWeight = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.estimatedWeight
                          ? `${formContent?.estimatedWeight}`
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>
                {/* Actual Weight */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Actual Weight</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"consignee"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Actual Weight"}
                        value={formContent?.actualWeight}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.actualWeight = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.actualWeight
                          ? `${formContent?.actualWeight}`
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>
                {/* Entitlement1 */}
                {/* <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Entitlement</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"shipper"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Entitlement"}
                        value={formContent?.entitlement2}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.entitlement2 = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.entitlement2
                          ? formContent?.entitlement2
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow> */}
                {/* Estimated Volume */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Estimated Volume</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"consignee"}
                        type="number"
                        style="full-border-field bg-app"
                        label={"Estimated Volume"}
                        value={formContent?.estimatedVolume}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.estimatedVolume = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.estimatedVolume ? (
                          <>
                            {Number(formContent?.estimatedVolume)?.toFixed(2)} m
                            <sup>3</sup>
                          </>
                        ) : (
                          "-"
                        )}
                      </>
                    )}
                  </TableCell>
                </TableRow>
                {/* Actual Volume */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Actual Volume</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"consignee"}
                        type="number"
                        style="full-border-field bg-app"
                        label={"Actual Volume"}
                        value={formContent?.actualVolume}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.actualVolume = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.actualVolume ? (
                          <>
                            {Number(formContent?.actualVolume)?.toFixed(2)} m
                            <sup>3</sup>
                          </>
                        ) : (
                          "-"
                        )}
                      </>
                    )}
                  </TableCell>
                </TableRow>
                {/* Delivery Started Date */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Delivery Started Date</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"description"}
                        type="date"
                        style="full-border-field bg-app"
                        label={"Delivery Started Date"}
                        value={formContent?.deliveryStartedDate}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.deliveryStartedDate = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.deliveryStartedDate
                          ? formContent?.deliveryStartedDate
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>
                {/* Delivery Completed Date */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Delivery Completed Date</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"description"}
                        type="date"
                        style="full-border-field bg-app"
                        label={"Delivery Completed Date"}
                        value={formContent?.deliveryCompletedDate}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.deliveryCompletedDate = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.deliveryCompletedDate
                          ? formContent?.deliveryCompletedDate
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>

                {/* Shifting Started Date */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Shifting Started Date</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"description"}
                        type="date"
                        style="full-border-field bg-app"
                        label={"Shifting Started Date"}
                        value={formContent?.shiftingStartedDate}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.shiftingStartedDate = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.shiftingStartedDate
                          ? formContent?.shiftingStartedDate
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>
                {/* Shifting Completed Date */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Shifting Completed Date</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"description"}
                        type="date"
                        style="full-border-field bg-app"
                        label={"Shifting Completed Date"}
                        value={formContent?.shiftingCompletedDate}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.shiftingCompletedDate = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.shiftingCompletedDate
                          ? formContent?.shiftingCompletedDate
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>
                {/* Warehouse In Date */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Warehouse In Date</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"gross-weight"}
                        type="date"
                        style="full-border-field bg-app"
                        label={"Warehouse In Date"}
                        value={formContent?.wareHouseInDate}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.wareHouseInDate = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.wareHouseInDate
                          ? formContent?.wareHouseInDate
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>

                {/* Warehouse Out Date */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Warehouse Out Date</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"gross-weight"}
                        type="date"
                        style="full-border-field bg-app"
                        label={"Warehouse Out Date"}
                        value={formContent?.wareHouseOutDate}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.wareHouseOutDate = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.wareHouseOutDate
                          ? formContent?.wareHouseOutDate
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>
                {/* Shipment Date */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Shipment Date</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"port-of-discharge"}
                        type="date"
                        style="full-border-field bg-app"
                        label={"Shipment Date"}
                        value={formContent?.shipmentDate}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.shipmentDate = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.shipmentDate
                          ? formContent?.shipmentDate
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>

                {/* ORIGIN/Destination Country */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Origin / Destination</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"country"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Origin / Destination"}
                        value={formContent?.originDestination}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.originDestination = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.originDestination
                          ? formContent?.originDestination
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>

                {/* BOL/AWB No. */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>BOL/AWB No.</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"remarks"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"BOL/AWB No."}
                        value={formContent?.bolAWBNo}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.bolAWBNo = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>{formContent?.bolAWBNo ? formContent?.bolAWBNo : "-"}</>
                    )}
                  </TableCell>
                </TableRow>

                {/* Container No. */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Container No.</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"remarks"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Container No."}
                        value={formContent?.containerNo}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.containerNo = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.containerNo
                          ? formContent?.containerNo
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>

                {/* Vessel */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Vessel</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"remarks"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Vessel"}
                        value={formContent?.vessel}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.vessel = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>{formContent?.vessel ? formContent?.vessel : "-"}</>
                    )}
                  </TableCell>
                </TableRow>

                {/* ETD/ETA */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>ETD/ETA</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"remarks"}
                        type="date"
                        style="full-border-field bg-app"
                        label={"ETD/ETA"}
                        value={formContent?.ETDbyETA}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.ETDbyETA = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>{formContent?.ETDbyETA ? formContent?.ETDbyETA : "-"}</>
                    )}
                  </TableCell>
                </TableRow>

                {/* Airline */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Airline</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"remarks"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Airline"}
                        value={formContent?.airline}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.airline = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>{formContent?.airline ? formContent?.airline : "-"}</>
                    )}
                  </TableCell>
                </TableRow>

                {/* Flight No. */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Flight No.</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"remarks"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Flight No."}
                        value={formContent?.flightNo}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.flightNo = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>{formContent?.flightNo ? formContent?.flightNo : "-"}</>
                    )}
                  </TableCell>
                </TableRow>

                {/* {data.map((name, index) => (
                <TableRow key={index}>
                  <TableCell className="border p-3 w-25">{name}</TableCell>
                  <TableCell className="border p-3 w-75 text-center">
                    {isEdit ? (
                      name === "Volume/Gross Weight" ? (
                        <Input
                          id={name}
                          type="number"
                          style="full-border-field"
                          label={name}
                        />
                      ) : (
                        <Input
                          id={name}
                          type="text"
                          style="full-border-field"
                          label={name}
                        />
                      )
                    ) : (
                      "-"
                    )}
                  </TableCell>
                </TableRow>
              ))} */}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Remarks */}
          <div className="mt-4 pe-1 mb-5">
            <Typography variant="body1" className="text-start" gutterBottom>
              <DynamicText
                label="Remarks / Instructions (If any)"
                textAreaLabel="Reamarks"
                isEdit={isEdit}
                value={formContent?.remarks}
                onChange={(e) => {
                  let values = { ...formContent };
                  values.remarks = e.target.value;
                  setFormContent(values);
                }}
              />
            </Typography>
          </div>
          {/* /Office Instructions/remarks */}
          {/* /table */}

          {/* Signatures */}
          <Row className="mt-5 pt-5 no-print">
            <Col sm={6} className="mt-5 pt-5">
              <div
                style={{ height: "90px" }}
                className="d-flex justify-content-center align-items-end"
              >
                {formContent?.managerSign?.trim()?.length == 0 ? (
                  <>
                    {isEdit && (
                      <div className="d-flex justify-content-center align-items-end">
                        <label
                          htmlFor="uploadSign"
                          className="active-btn ms-2 w-200 d-flex align-items-center justify-content-center cursor-pointer btn"
                        >
                          {uploadText1}
                        </label>
                        <input
                          type="button"
                          hidden
                          id="uploadSign"
                          name="uploadSign"
                          onClick={() => {
                            setWhichSign("manager");
                            setSignCanvas(true);
                          }}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="d-flex align-items-start">
                    <img
                      src={`${Bucket}${formContent?.managerSign}`}
                      height={80}
                      width={200}
                    />
                    {isEdit && (
                      <>
                        <label
                          htmlFor="editSign1"
                          className=" ms-2  d-flex align-items-center justify-content-center cursor-pointer btn p-0"
                        >
                          <img
                            src={editIcn}
                            height={18}
                            width={18}
                            className="ms-2 cursor-pointer"
                          />
                        </label>
                        <input
                          type="button"
                          hidden
                          id="editSign1"
                          name="uploadSign"
                          onClick={() => {
                            setWhichSign("manager");
                            setSignCanvas(true);
                          }}
                        />
                        <img
                          src={trashIcn}
                          height={18}
                          width={18}
                          className="ms-2 cursor-pointer"
                          onClick={() => {
                            let keysToDelete = [...mediaKeysToRemove];
                            keysToDelete.push(formContent?.managerSign);
                            setMediaKeysToRemove(keysToDelete);

                            let values = { ...formContent };
                            values.managerSign = "";
                            setFormContent(values);
                          }}
                        />
                      </>
                    )}
                  </div>
                )}
              </div>

              <p>{"_______________________________"}</p>
              <p className="my-1">
                <b>{"Manager Operations"}</b>
              </p>

              {""}
            </Col>
            <Col sm={6} className="mt-5 pt-5">
              <div
                style={{ height: "90px" }}
                className="d-flex justify-content-center align-items-end"
              >
                {formContent?.assistOperSign?.trim()?.length == 0 ? (
                  <>
                    {isEdit && (
                      <div className="d-flex justify-content-center align-items-end">
                        <label
                          htmlFor="uploadSign2"
                          className="active-btn ms-2 w-200 d-flex align-items-center justify-content-center cursor-pointer btn"
                        >
                          {uploadText2}
                        </label>
                        <input
                          type="button"
                          hidden
                          id="uploadSign2"
                          name="uploadSign2"
                          onClick={() => {
                            setWhichSign("assist");
                            setSignCanvas(true);
                          }}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="d-flex align-items-start">
                    <img
                      src={`${Bucket}${formContent.assistOperSign}`}
                      height={80}
                      width={200}
                    />
                    {isEdit && (
                      <>
                        <label
                          htmlFor="editSign2"
                          className=" ms-2  d-flex align-items-center justify-content-center cursor-pointer btn p-0"
                        >
                          <img
                            src={editIcn}
                            height={18}
                            width={18}
                            className="ms-2 cursor-pointer"
                          />
                        </label>
                        <input
                          type="button"
                          hidden
                          id="editSign2"
                          name="uploadSign"
                          onClick={() => {
                            setWhichSign("assist");
                            setSignCanvas(true);
                          }}
                        />
                        <img
                          src={trashIcn}
                          height={18}
                          width={18}
                          className="ms-2 cursor-pointer"
                          onClick={() => {
                            let keysToDelete = [...mediaKeysToRemove];
                            keysToDelete.push(formContent?.assistOperSign);
                            setMediaKeysToRemove(keysToDelete);

                            let values = { ...formContent };
                            values.assistOperSign = "";
                            setFormContent(values);
                          }}
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
              <p>{"_______________________________"}</p>
              <p className="my-1">
                <b>{"Assistant Operations"}</b>
              </p>

              {""}
            </Col>
          </Row>
        </div>

        <PrintComponent>
          <WorksheetImportDownload
            surveyData={surveyData}
            formContent={formContent}
          />
        </PrintComponent>

        {/* snackbar */}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000} // Adjust the duration as needed
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={
              snackbarMessage?.includes("successful") ? "success" : "error"
            }
            onClose={handleCloseSnackbar}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
        {/* /snackbar */}

        {/* share with client */}
        {isShare && (
          <ShareWithClientsModal
            open={isShare}
            onClose={() => {
              setIsShare(false);
            }}
            onSuccess={(emails) => {
              shareWithClient(emails);
            }}
          />
        )}

        <LoadingModal open={open} handleClose={handleClose} />

        {/* Add signature Modal */}
        {isSignCanvas && (
          <MySignatureCanvas
            open={isSignCanvas}
            onClose={() => {
              setSignCanvas(false);
            }}
            onSuccess={(file) => {
              if (!isLoading) uploadFile(file);
            }}
          />
        )}

        {isVerified && (
          <SuperAdminVerifationModal
            open={isVerified}
            onClose={() => {
              setIsVerified(false);
            }}
            onSuccess={() => {
              setIsEdit(true);
              setIsVerified(false);
            }}
            onError={() => {
              // shareWithClient(emails);
              setSnackbarMessage("Invalid Confirm Pin");
              setOpenSnackbar(true);
            }}
          />
        )}
      </MainTemplate>
    </>
  );
};
export default WorksheetImportPage;
