import React, { useEffect, useState } from "react";
import {
  updateFormContentPreview,
  getFormContentPreview,
  imageUploadClientPreview,
} from "../../../../api/services/services";
import { Button, Snackbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import { Bucket } from "../../../../api/config/apiConfig";
import ContactDetailField from "../../../molecules/ContactDetailField";
import Input from "../../../atoms/Input";
import DynamicText from "../../../molecules/DynamicText";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import html2pdf from "html2pdf.js";
import { useParams } from "react-router-dom";
import FourOptionsDynamicCheckbox from "../../../molecules/FourOptionsCheckbox";
// import "./style.css";
import { useLocation } from "react-router-dom";

const LoadUnloadDownload = ({ surveyData, formContent }) => {
  const [formContentId, setFormContentId] = useState("");

  const [isEdit, setIsEdit] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const urlData = new URLSearchParams(location.search);
  const email = urlData.get("email");
  const surveyId = urlData.get("surveyId");
  const formId = urlData.get("formId");
  const token = urlData.get("token");

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // 11th, 12th, 13th, etc.
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  // Helper function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const ordinalSuffix = getOrdinalSuffix(day);

    return `${day}${ordinalSuffix} ${month}, ${year}`;
  };

  return (
    <>
      <div className="mx-4 ">
        <div className="d-flex align-items-center justify-content-center mb-1">
          <p className="ps-0 mb-0 head-top">Load Unload Report</p>
        </div>

        {/* file detail*/}
        <div className="contact-detail d-flex mt-1">
          {/* left col */}
          <div
            className={`left-col ${!isEdit ? " w-50" : "w-50 pe-5"} text-start`}
          >
            <Typography variant="body1" gutterBottom>
              {/* <ContactDetailField
                label="File Ref"
                isEdit={isEdit}
                type="text"
                minWidth={"130px"}
                value={formContent?.fileRef}
                disabled
              /> */}
              <div className="d-flex">
                <p
                  className="p-word mb-1 text-start"
                  style={{ minWidth: "100px", fontSize: "11px" }}
                >
                  <b>File Ref:</b>
                </p>

                <p className="p-word mb-1 text-start">
                  <span className="ms-1 ">
                    {" "}
                    {formContent?.fileRef ? formContent?.fileRef : "-"}
                  </span>
                </p>
              </div>
              {/* <ContactDetailField
                label="Customer Name"
                isEdit={isEdit}
                type="text"
                minWidth={"130px"}
                value={formContent?.clientName}
                disabled
              /> */}
              <div className="d-flex">
                <p
                  className="p-word mb-1 text-start"
                  style={{ minWidth: "100px", fontSize: "11px" }}
                >
                  <b>Customer Name:</b>
                </p>

                <p className="p-word mb-1 text-start">
                  <span className="ms-1 ">
                    {" "}
                    {formContent?.clientName ? formContent?.clientName : "-"}
                  </span>
                </p>
              </div>

              {/* <ContactDetailField
                label="Driver Name"
                isEdit={isEdit}
                type="text"
                minWidth={"130px"}
                value={formContent?.driverName}
                disabled
              /> */}

              <div className="d-flex">
                <p
                  className="p-word mb-1 text-start"
                  style={{ minWidth: "100px", fontSize: "11px" }}
                >
                  <b>Driver Name:</b>
                </p>

                <p className="p-word mb-1 text-start">
                  <span className="ms-1 ">
                    {" "}
                    {formContent?.driverName ? formContent?.driverName : "-"}
                  </span>
                </p>
              </div>
              {/* <ContactDetailField
                label="Contact No. "
                isEdit={isEdit}
                type="number"
                minWidth={"130px"}
                value={formContent?.contactNo}
                disabled
              /> */}
              <div className="d-flex">
                <p
                  className="p-word mb-1 text-start"
                  style={{ minWidth: "100px", fontSize: "11px" }}
                >
                  <b>Contact No. :</b>
                </p>

                <p className="p-word mb-1 text-start">
                  <span className="ms-1 ">
                    {" "}
                    {formContent?.contactNo ? formContent?.contactNo : "-"}
                  </span>
                </p>
              </div>
              {/* <ContactDetailField
                label="ID Card No. "
                isEdit={isEdit}
                type="number"
                minWidth={"130px"}
                value={formContent?.idNo}
                disabled
              /> */}
              <div className="d-flex">
                <p
                  className="p-word mb-1 text-start"
                  style={{ minWidth: "100px", fontSize: "11px" }}
                >
                  <b>ID Card No. :</b>
                </p>

                <p className="p-word mb-1 text-start">
                  <span className="ms-1 ">
                    {" "}
                    {formContent?.idNo ? formContent?.idNo : "-"}
                  </span>
                </p>
              </div>
              {/* <ContactDetailField
                label="Origin Address"
                isEdit={isEdit}
                type="text"
                minWidth={"130px"}
                value={formContent?.originAddress}
                disabled
              /> */}
              <div className="d-flex">
                <p
                  className="p-word mb-1 text-start"
                  style={{ minWidth: "100px", fontSize: "11px" }}
                >
                  <b>Origin Address:</b>
                </p>

                <p className="p-word mb-1 text-start">
                  <span className="ms-1 ">
                    {" "}
                    {formContent?.originAddress
                      ? formContent?.originAddress
                      : "-"}
                  </span>
                </p>
              </div>
            </Typography>
          </div>
          {/* right col */}
          <div
            className={`right-col ${
              !isEdit ? "w-50" : "w-50 ps-5"
            }  d-flex flex-column align-items-end pe-2`}
          >
            <div
              className="ps-5"
              style={isEdit ? { width: "-webkit-fill-available" } : {}}
            >
              <Typography
                variant="body1"
                className="text-start w-100"
                gutterBottom
              >
                {/* <ContactDetailField
                  label="Date"
                  isEdit={isEdit}
                  type="date"
                  wrapperStyle="justify-content-start"
                  minWidth={"140px"}
                  value={formContent?.date}
                  disabled
                /> */}
                <div className="d-flex">
                  <p
                    className="p-word mb-1 text-start"
                    style={{ minWidth: "100px", fontSize: "11px" }}
                  >
                    <b>Date:</b>
                  </p>

                  <p className="p-word mb-1 text-start">
                    <span className="ms-1 ">
                      {" "}
                      {formContent?.date ? formatDate(formContent?.date) : "-"}
                    </span>
                  </p>
                </div>
                {/* <ContactDetailField
                  label="Organization"
                  isEdit={isEdit}
                  type="text"
                  wrapperStyle="justify-content-start"
                  minWidth={"140px"}
                  value={formContent?.organization}
                  disabled
                /> */}
                <div className="d-flex">
                  <p
                    className="p-word mb-1 text-start"
                    style={{ minWidth: "100px", fontSize: "11px" }}
                  >
                    <b>Organization:</b>
                  </p>

                  <p className="p-word mb-1 text-start">
                    <span className="ms-1 ">
                      {" "}
                      {formContent?.organization
                        ? formContent?.organization
                        : "-"}
                    </span>
                  </p>
                </div>
                {/* <ContactDetailField
                  label="Serial No. "
                  isEdit={isEdit}
                  type="text"
                  wrapperStyle="justify-content-start"
                  minWidth={"140px"}
                  value={formContent?.serialNo}
                  disabled
                /> */}
                <div className="d-flex">
                  <p
                    className="p-word mb-1 text-start"
                    style={{ minWidth: "100px", fontSize: "11px" }}
                  >
                    <b>Serial No.:</b>
                  </p>

                  <p className="p-word mb-1 text-start">
                    <span className="ms-1 ">
                      {" "}
                      {formContent?.serialNo ? formContent?.serialNo : "-"}
                    </span>
                  </p>
                </div>
                {/* <ContactDetailField
                  label="Truck  No. "
                  isEdit={isEdit}
                  type="text"
                  wrapperStyle="justify-content-start"
                  minWidth={"140px"}
                  value={formContent?.truckNo}
                  disabled
                /> */}
                <div className="d-flex">
                  <p
                    className="p-word mb-1 text-start"
                    style={{ minWidth: "100px", fontSize: "11px" }}
                  >
                    <b>Truck No.:</b>
                  </p>

                  <p className="p-word mb-1 text-start">
                    <span className="ms-1 ">
                      {" "}
                      {formContent?.truckNo ? formContent?.truckNo : "-"}
                    </span>
                  </p>
                </div>
                {/* <ContactDetailField
                  label="Container No. "
                  isEdit={isEdit}
                  type="text"
                  wrapperStyle="justify-content-start"
                  minWidth={"140px"}
                  value={formContent?.containerNo}
                  disabled
                /> */}
                <div className="d-flex">
                  <p
                    className="p-word mb-1 text-start"
                    style={{ minWidth: "100px", fontSize: "11px" }}
                  >
                    <b>Container No. :</b>
                  </p>

                  <p className="p-word mb-1 text-start">
                    <span className="ms-1 ">
                      {" "}
                      {formContent?.containerNo
                        ? formContent?.containerNo
                        : "-"}
                    </span>
                  </p>
                </div>
                {/* <ContactDetailField
                  label="Destination Address"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"140px"}
                  value={formContent?.destinationAddress}
                  disabled
                /> */}
                <div className="d-flex">
                  <p
                    className="p-word mb-1 text-start"
                    style={{ minWidth: "100px", fontSize: "11px" }}
                  >
                    <b>Destination Address :</b>
                  </p>

                  <p className="p-word mb-1 text-start">
                    <span className="ms-1 ">
                      {" "}
                      {formContent?.destinationAddress
                        ? formContent?.destinationAddress
                        : "-"}
                    </span>
                  </p>
                </div>
              </Typography>
            </div>
          </div>
        </div>
        {/* /file detail */}

        {/*  */}
        {/* Shipment Mode */}
        <div className="mt-0 pe-1">
          <Typography
            variant="body1"
            className="text-start"
            gutterBottom
            style={{ fontSize: "10px" }}
          >
            <FourOptionsDynamicCheckbox
              label="Shipment Mode"
              checkId1="check1"
              checkLabel1="Import"
              checkId2="check2"
              checkLabel2="Export"
              checkId3="check3"
              checkLabel3="Local"
              checkId4="chec42"
              checkLabel4="Storage"
              value1={formContent?.shipmentMode == "import" ? true : false}
              value2={formContent?.shipmentMode == "export" ? true : false}
              value3={formContent?.shipmentMode == "local" ? true : false}
              value4={formContent?.shipmentMode == "storage" ? true : false}
              disabled={true}
            />
          </Typography>
        </div>
        {/* /Shipment Mode */}

        {/* Nature of Shipment */}
        <div className="pe-1 " style={{ marginTop: "-13px" }}>
          <Typography
            variant="body1"
            className="text-start"
            gutterBottom
            style={{ fontSize: "10px" }}
          >
            <FourOptionsDynamicCheckbox
              label="Nature of Shipment"
              checkId1="check1"
              checkLabel1="Loose Items"
              checkId2="check2"
              checkLabel2="Lift Vans"
              checkId3="check3"
              checkLabel3="Vehicle"
              checkId4="chec42"
              checkLabel4="Bike"
              value1={
                formContent?.shipmentNature == "loose-items" ? true : false
              }
              value2={formContent?.shipmentNature == "lift-vans" ? true : false}
              value3={formContent?.shipmentNature == "vehicle" ? true : false}
              value4={formContent?.shipmentNature == "bike" ? true : false}
              disabled={true}
            />
          </Typography>
        </div>
        {/* Nature of Shipment */}

        <div className="d-flex flex-wrap" style={{ marginTop: "-13px" }}>
          {formContent?.containers?.map((cn, ind) => {
            return (
              <div
                className={`cursor-pointer ${
                  cn ? "blue-container-div" : "container-div"
                }`}
                // style={{ height: "35px", width: "35px" }}
              >
                {" "}
                {ind + 1}
              </div>
            );
          })}
        </div>

        {/*   Double No. */}
        {formContent?.doubleNo?.trim()?.length != 0 && (
          <div className="mt-1 pe-1">
            <div className="d-flex">
              <p
                className="p-word mb-1 text-start"
                style={{ minWidth: "100px", fontSize: "11px" }}
              >
                <b> Double No.</b>
              </p>

              <p className="p-word mb-1 text-start">
                <span className="ms-1 ">
                  {" "}
                  {formContent?.doubleNo ? formContent?.doubleNo : "-"}{" "}
                </span>
              </p>
            </div>
          </div>
        )}
        {/* /  Double No. */}
        {/*  Missing No. */}
        {formContent?.missingNo?.trim()?.length != 0 && (
          <div className="mt-1 pe-1">
            <div className="d-flex">
              <p
                className="p-word mb-1 text-start"
                style={{ minWidth: "100px", fontSize: "11px" }}
              >
                <b> Missing No.</b>
              </p>

              <p className="p-word mb-1 text-start">
                <span className="ms-1 ">
                  {" "}
                  {formContent?.missingNo ? formContent?.missingNo : "-"}
                </span>
              </p>
            </div>
          </div>
        )}

        {/* /Missing No.s */}
        {/*  Apparent damages No. */}
        {formContent?.damagesNo?.trim()?.length != 0 && (
          <div className="mt-1 pe-1">
            <div className="d-flex">
              <p
                className="p-word mb-1 text-start"
                style={{ minWidth: "100px", fontSize: "11px" }}
              >
                <b> Apparent damages No.</b>
              </p>

              <p className="p-word mb-1 text-start">
                <span className="ms-1 ">
                  {" "}
                  {formContent?.damagesNo ? formContent?.damagesNo : "-"}{" "}
                </span>
              </p>
            </div>
          </div>
        )}

        {/* /Apparent damages No. */}

        {/* Remarks */}
        {formContent?.remarks?.trim()?.length != 0 && (
          <div className="mt-0 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <DynamicText
                label="Remarks:"
                textAreaLabel="Remarks..."
                isEdit={isEdit}
                value={formContent?.remarks}
                disabled={true}
              />
            </Typography>
          </div>
        )}
        {/* /Remakes */}
        {/* Signatures */}
        <Row className="mt-1">
          <Col sm={6} className="">
            <div
              style={{ height: "90px" }}
              className="d-flex justify-content-center align-items-end"
            >
              {formContent?.supervisorSign?.trim()?.length == 0 ? (
                <></>
              ) : (
                <img
                  src={`${Bucket}${formContent?.supervisorSign}`}
                  height={80}
                  width={200}
                />
              )}
            </div>

            <p className="mb-0">{"_______________________________"}</p>
            <p className="my-0">
              <b>{"Supervisor/Carrier"}</b>
            </p>
           
            {""}
          </Col>
          <Col sm={6} className="">
            <div
              style={{ height: "90px" }}
              className="d-flex justify-content-center align-items-end"
            >
              {formContent?.ownerSign?.trim()?.length == 0 ? (
                <></>
              ) : (
                <img
                  src={`${Bucket}${formContent.ownerSign}`}
                  height={80}
                  width={200}
                />
              )}
            </div>
            <p className="mb-0">{"_______________________________"}</p>
            <p className="my-0">
              <b>{"Owner/Authorized"}</b>
            </p>
           
            {""}
          </Col>
        </Row>

        {/* </MainTemplate> */}
      </div>
    </>
  );
};

export default LoadUnloadDownload;
