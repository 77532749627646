import React, { useEffect, useState } from "react";
import {
  updateFormContentPreview,
  getFormContentPreview,
  imageUploadClientPreview,
} from "../../../../api/services/services";
import backArrow from "../../../../imgs/BackArrow.svg";
import { Button, Snackbar, Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import { Bucket } from "../../../../api/config/apiConfig";
import ContactDetailField from "../../../molecules/ContactDetailField";
import TextArea from "../../../atoms/Textarea";
import DynamicCheckbox from "../../../molecules/DynamicCheckbox";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import Input from "../../../atoms/Input";
import removeImg from "../../../../imgs/remove.png";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import html2pdf from "html2pdf.js";
import { useParams } from "react-router-dom";
import { ToWords } from "to-words";
import { useLocation } from "react-router-dom";
import InvoiceDownload from "./download";
import PrintComponent from "../../PrintComponent";
import moment from "moment";

const InvoiceClientSide = () => {
  const [formContent, setFormContent] = useState({});
  const [formContentId, setFormContentId] = useState("");

  const [isEdit, setIsEdit] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const urlData = new URLSearchParams(location.search);
  const email = urlData.get("email");
  const surveyId = urlData.get("surveyId");
  const formId = urlData.get("formId");
  const token = urlData.get("token");

  var totalAmount = 0;

  const words = new ToWords();

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  //
  const updateContent = (data) => {
    setIsLoading(true);

    let media = [];
    if (formContent?.clientSign != "") media[0] = formContent?.clientSign;

    const body = {
      formContentId: formContentId,
      content: data ? data : formContent,
      mediaKeys: media,
      removedMediaKeys: [],
    };
    updateFormContentPreview(
      surveyId,
      formId,
      email,
      token,
      body,
      formContentId
    )
      .then((res) => {
        if (data != undefined)
          setSnackbarMessage("Signature has been added successfully!");
        else
          setSnackbarMessage(
            "Delivery Request Form has been updated successfully!"
          );

        setOpenSnackbar(true);
        getContent();
        setIsEdit(false);

        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          setIsValid(false);
        }

        setIsLoading(false);
      });
  };

  const [isValid, setIsValid] = useState(false);

  //
  const getContent = () => {
    setIsLoading(true);
    getFormContentPreview(surveyId, formId, email, token)
      .then((res) => {
        setIsValid(true);
        setFormContentId(res.data.data.uuid);
        setFormContent(res.data.data.content);

        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          setIsValid(false);
        }

        setIsLoading(false);
      });
  };

  useEffect(() => {
    getContent();
  }, []);

  const [show, setShow] = useState(false);
  const options = {
    // resolution: Resolution.HIGH,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      format: "letter",
      // default is 'portrait'
      orientation: "portrait",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/png",
      qualityRatio: 1,
    },
    overrides: {
      pdf: {
        compress: true,
      },
    },
  };

  //
  const generatePdf = () => {
    setShow(true);

    setTimeout(() => {
      const content = document.getElementById("content-to-convert"); // Replace with the ID of the element you want to convert to PDF

      const currentDateTime = new Date();
      const filename = `survey_${currentDateTime.toISOString()}.pdf`;

      const pdfOptions = {
        margin: 5,
        filename: filename,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
        }, // Set the page size here
      };

      let temp = html2pdf().from(content).set(pdfOptions).outputPdf();
      temp.save(filename);

      setShow(false);
    }, 3500);
  };

  const [downloadText, setDownloadText] = useState("download");

  const getTargetElement = () => document.getElementById("content-id");

  //
  //
  const uploadFile = async (event) => {
    event.preventDefault();

    if (event.target.files.length > 0) {
      const fd = new FormData();
      fd.append("image", event.target.files[0]);

      imageUploadClientPreview(fd, surveyId, formId, email, token)
        .then((res) => {
          let values = { ...formContent };
          values.clientSign = res?.data?.data?.key;
          setFormContent(values);

          setTimeout(() => {
            updateContent(values);
          }, 1500);
          setIsLoading(false);
        })
        .catch((error) => {
          setSnackbarMessage(error?.response?.data?.message);
          setOpenSnackbar(true);

          setIsValid(false);

          setIsLoading(false);
        });
    }
  };

  const printAction = () => {
    const PAGE_HEIGHT = 1045;
    const printElement = document.getElementById("print-component");
    if (printElement) {
      printElement.classList.add("temp-class-for-height");
      const height = printElement.clientHeight - 500;
      const numberOfPage = Math.ceil(height / PAGE_HEIGHT);
      const heightWithSingleHeader = numberOfPage * PAGE_HEIGHT;
      let requiredHeight = heightWithSingleHeader;
      if (numberOfPage > 1) {
        const headerHeight =
          printElement.getElementsByTagName("thead")?.[0]?.clientHeight;
        const footerHeight =
          printElement.getElementsByTagName("tfoot")?.[0]?.clientHeight;
        requiredHeight -= (numberOfPage - 1) * (headerHeight + footerHeight);
      }
      printElement.style.height = `${requiredHeight}px`;
      printElement.classList.remove("temp-class-for-height");
    }
    window.print();
    if (printElement) {
      printElement.style.height = `auto`;
    }
  };

  const [surveyData, setSurveyData] = useState([]);

  return (
    <>
      {isValid ? (
        <div className="mx-3 mx-md-5 no-print" style={{ minHeight: "100vh" }}>
          {/* <MainTemplate> */}

          <div className="d-flex align-items-center py-4">
            <p className="main-head ps-0 mb-0 text-end w-50">Invoice</p>
            <div className="d-flex ms-auto justify-content-center mt-2 mt-sm-0">
              {/*Edit Button */}
              {!isEdit && email != formContent?.clientEmail && (
                <Button
                  className="active-btn"
                  onClick={() => {
                    setIsEdit(true);
                  }}
                  disabled={isLoading}
                >
                  Edit
                </Button>
              )}

              {/* Cancel button */}
              {isEdit && email != formContent?.clientEmail && (
                <Button
                  className="active-btn ms-3"
                  onClick={() => {
                    getContent();
                    setIsEdit(false);
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
              )}

              {/* Save Button */}
              {isEdit && email != formContent?.clientEmail && (
                <Button
                  className="active-btn ms-3"
                  onClick={() => {
                    updateContent();
                  }}
                  disabled={isLoading}
                >
                  Save
                </Button>
              )}

              {/* Download Button */}
              {!isEdit && (
                <Button
                  className="active-btn ms-3 px-0"
                  onClick={() => {
                    setIsLoading(true);
                    // printAction();
                    var tempTitle = document.title;
                    document.title = `Invoice-${
                      formContent?.clientName
                    }-${moment(new Date()).format("DD.MM.YYYY")}`;

                    window.print();
                    document.title = tempTitle;
                    setIsLoading(false);
                  }}
                  disabled={isLoading}
                >
                  {"Save as PDF"}
                </Button>
              )}
            </div>
          </div>

          {/* <div className="d-block d-sm-flex align-items-center py-4">
            <div className="d-flex align-items-center ">
              <p className="main-head  mb-0">Invoice</p>
            </div>
            <div className="d-flex ms-auto justify-content-center mt-2 mt-sm-0">
              {/*Edit Button */}
          {/* {!isEdit && email != formContent?.clientEmail && (
                <Button
                  className="active-btn"
                  onClick={() => {
                    setIsEdit(true);
                  }}
                  disabled={isLoading}
                >
                  Edit
                </Button>
              )} */}

          {/* Cancel button */}
          {/* {isEdit && email != formContent?.clientEmail && (
                <Button
                  className="active-btn ms-3"
                  onClick={() => {
                    getContent();
                    setIsEdit(false);
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
              )} */}

          {/* Save Button */}
          {/* {isEdit && email != formContent?.clientEmail && (
                <Button
                  className="active-btn ms-3"
                  onClick={() => {
                    updateContent();
                  }}
                  disabled={isLoading}
                >
                  Save
                </Button>
              )} */}

          {/* Download Button */}
          {/* {!isEdit && (
                <Button
                  className="active-btn ms-3 px-0"
                  onClick={() => {
                    setIsLoading(true);
                      // printAction();
                      var tempTitle = document.title;
                      document.title = `Invoice-${
                        formContent?.clientName
                      }-${moment(new Date()).format("DD.MM.YYYY")}`;

                      window.print();
                      document.title = tempTitle;
                      setIsLoading(false);
                  }}
                  disabled={isLoading}
                >
                  {"Save as PDF"}
                </Button>
              )}
            </div>
          </div> */}

          {/* customer detail*/}
          <div className={`${isEdit ? "p-3 px-0 rounded mb-5" : ""}`}>
            <div className="contact-detail d-flex mt-2">
              {/* left col */}
              <div className={`left-col w-100 text-start`}>
                <Typography variant="body1" gutterBottom>
                  <ContactDetailField
                    label="Invoice No."
                    isEdit={isEdit}
                    type="text"
                    minWidth={"160px"}
                    value={formContent?.invoiceNo}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.invoiceNo = e.target.value;
                      setFormContent(values);
                    }}
                    disabled
                  />

                  <ContactDetailField
                    label="Shelozon Vendor #"
                    isEdit={isEdit}
                    type="text"
                    minWidth={"160px"}
                    value={formContent?.vendorNo}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.vendorNo = e.target.value;
                      setFormContent(values);
                    }}
                  />
                  {/* <ContactDetailField
                    label="To"
                    isEdit={isEdit}
                    type="text"
                    minWidth={"160px"}
                    value={formContent?.to}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.to = e.target.value;
                      setFormContent(values);
                    }}
                  /> */}
                  {/* <ContactDetailField
                    label="For"
                    isEdit={isEdit}
                    type="text"
                    minWidth={"160px"}
                    value={formContent?.for}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.for = e.target.value;
                      setFormContent(values);
                    }}
                  /> */}
                  <div
                    className={`${
                      isEdit ? "mb-4" : "mb-2"
                    } d-flex align-items-start contactDetail-label  `}
                  >
                    <label
                      htmlFor={"To"}
                      className={` me-3 ${isEdit ? "w-25" : ""} ${"fw-bold"}`}
                      style={{ minWidth: "160px" }}
                    >
                      <b> To:</b>
                    </label>
                    {isEdit ? (
                      <TextArea
                        label="To"
                        isEdit={isEdit}
                        type="text"
                        minWidth={"160px"}
                        value={formContent?.to}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.to = e.target.value;
                          setFormContent(values);
                        }}
                        row={4}
                      />
                    ) : (
                      <span>{formContent?.to ? formContent?.to : "-"}</span>
                    )}
                  </div>
                  <div
                    className={`${
                      isEdit ? "mb-4" : "mb-2"
                    } d-flex align-items-start contactDetail-label  `}
                  >
                    <label
                      htmlFor={"To"}
                      className={` me-3 ${isEdit ? "w-25" : ""} ${"fw-bold"}`}
                      style={{ minWidth: "160px" }}
                    >
                      <b> For:</b>
                    </label>
                    {isEdit ? (
                      <TextArea
                        label="For"
                        isEdit={isEdit}
                        type="text"
                        minWidth={"160px"}
                        value={formContent?.for}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.for = e.target.value;
                          setFormContent(values);
                        }}
                        row={4}
                      />
                    ) : (
                      <span>{formContent?.for ? formContent?.for : "-"}</span>
                    )}
                  </div>
                </Typography>
              </div>

              <div
                className={`right-col w-100 d-flex justify-content-end align-items-baseline`}
              >
                <Typography variant="body1" gutterBottom>
                  <ContactDetailField
                    label="Date"
                    isEdit={isEdit}
                    type="date"
                    value={formContent?.date}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.date = e.target.value;
                      setFormContent(values);
                    }}
                  />
                </Typography>
              </div>
            </div>
          </div>
          {/* /customer detail */}

          <>
            {formContent?.dataRows?.map((row, index) => {
              totalAmount = totalAmount + row?.amount * row?.quantity;
              return <></>;
            })}
          </>

          <Grid container className="pe-1 my-4">
            <TableContainer component={Paper} className="pb-1">
              <Table>
                {/* Table Header */}
                <TableHead>
                  <TableRow>
                    <TableCell className="table-head min-w-25">
                      Item No
                    </TableCell>
                    <TableCell className="table-head min-w-400">
                      Description
                    </TableCell>
                    <TableCell className="table-head min-w-200">
                      Quantity
                    </TableCell>
                    <TableCell className="table-head min-w-200">
                      Amount
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formContent?.dataRows?.length > 0 ? (
                    <>
                      {formContent?.dataRows?.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell> {index + 1}</TableCell>
                          <TableCell>
                            {isEdit ? (
                              <TextArea
                                id="description"
                                label="Description"
                                type="text"
                                style=""
                                value={row?.description}
                                onChange={(e) => {
                                  const values = { ...formContent };
                                  const valuesRows = [...formContent?.dataRows];
                                  valuesRows[index].description =
                                    e.target.value;
                                  values.dataRows = valuesRows;
                                  setFormContent(values);
                                }}
                                inline={{ width: "-webkit-fill-available" }}
                                rows={4}
                              />
                            ) : row.description ? (
                              row.description
                            ) : (
                              "-"
                            )}
                          </TableCell>
                          <TableCell>
                            {isEdit ? (
                              <Input
                                id="amount"
                                label="Qty"
                                type="number"
                                style="full-border-field"
                                value={row?.quantity}
                                onChange={(e) => {
                                  const values = { ...formContent };
                                  const valuesRows = [...formContent?.dataRows];
                                  valuesRows[index].quantity = e.target.value;
                                  values.dataRows = valuesRows;
                                  setFormContent(values);
                                }}
                                inline={{ width: "-webkit-fill-available" }}
                              />
                            ) : row?.quantity ? (
                              row?.quantity
                            ) : (
                              "-"
                            )}
                          </TableCell>

                          <TableCell>
                            {isEdit ? (
                              <Input
                                id="amount"
                                label="Amount"
                                type="text"
                                style="full-border-field"
                                value={row?.amount}
                                onChange={(e) => {
                                  const values = { ...formContent };
                                  const valuesRows = [...formContent?.dataRows];
                                  valuesRows[index].amount = e.target.value;
                                  values.amount = valuesRows;
                                  setFormContent(values);
                                }}
                                inline={{ width: "-webkit-fill-available" }}
                              />
                            ) : row?.amount ? (
                              row?.amount
                            ) : (
                              "-"
                            )}
                          </TableCell>
                        </TableRow>
                      ))}

                      <TableRow className="text-center bg-app">
                        <TableCell
                          colSpan={4}
                          style={{
                            borderBottom: "0px solid white",
                          }}
                          className="ps-0"
                        >
                          <div className="d-flex justify-content-start mt-3 mb-2">
                            <label
                              htmlFor="managerWarehouse"
                              className={`fw-bold ${isEdit ? "mb-3" : "mb-1"}`}
                            >
                              Grand Total:
                            </label>
                          </div>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          style={{
                            borderBottom: "0px solid white",
                          }}
                        >
                          {" "}
                          <b>- </b>
                        </TableCell>
                        <TableCell
                          style={{
                            borderBottom: "0px solid white",
                          }}
                        >
                          <b>
                            {" "}
                            {/* {words?.convert(1000, { currency: true })} */}
                            {isEdit ? (
                              <Input
                                id="amount"
                                label="Total Amount in words"
                                type="text"
                                style="full-border-field"
                                value={formContent?.totalAmountinEnglish}
                                onChange={(e) => {
                                  const values = { ...formContent };
                                  const valuesRows = [...formContent?.dataRows];
                                  values.totalAmountinEnglish = e.target.value;
                                  setFormContent(values);
                                }}
                                inline={{ width: "-webkit-fill-available" }}
                              />
                            ) : formContent?.totalAmountinEnglish ? (
                              formContent?.totalAmountinEnglish
                            ) : (
                              "-"
                            )}
                          </b>
                        </TableCell>
                        <TableCell
                          style={{
                            borderBottom: "0px solid white",
                          }}
                        ></TableCell>
                        <TableCell
                          style={{
                            borderBottom: "0px solid white",
                          }}
                        >
                          {/* {totalAmount} */}
                          {isEdit ? (
                            <Input
                              id="amount"
                              label="Total Amount in words"
                              type="text"
                              style="full-border-field"
                              value={formContent?.totalAmount}
                              onChange={(e) => {
                                const values = { ...formContent };
                                const valuesRows = [...formContent?.dataRows];
                                values.totalAmount = e.target.value;
                                setFormContent(values);
                              }}
                              inline={{ width: "-webkit-fill-available" }}
                            />
                          ) : formContent?.totalAmount ? (
                            formContent?.totalAmount
                          ) : (
                            "-"
                          )}
                        </TableCell>
                        {isEdit && (
                          <TableCell
                            style={{
                              borderBottom: "0px solid white",
                            }}
                          ></TableCell>
                        )}
                      </TableRow>
                    </>
                  ) : (
                    <TableRow className="text-center">
                      <TableCell colSpan={6}>
                        {isLoading ? (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              margin: "10px 0px",
                            }}
                          >
                            <CircularProgress
                              style={{
                                color: "#00aff0",
                                height: "25px",
                                width: "25px",
                              }}
                            />
                          </Box>
                        ) : (
                          <div className="d-flex justify-content-center">
                            <p className="my-3">No Records to Show</p>
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          {/* Bank Details */}
          <div className="mt-3 pe-1 mb-5 row">
            <Typography
              variant="body1"
              className="text-start col-12"
              gutterBottom
            >
              <div className="mb-2">
                <label htmlFor="to" className="mb-2 fw-bold">
                  Bank Details:
                </label>
                <Typography variant="body1" gutterBottom>
                  <ContactDetailField
                    label="Account Title"
                    isEdit={isEdit}
                    type="text"
                    style="fw-normal"
                    value={formContent?.bankDetails?.title}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.bankDetails.title = e.target.value;
                      setFormContent(values);
                    }}
                    minWidth={"160px"}
                  />
                  <ContactDetailField
                    label=" IBAN No"
                    isEdit={isEdit}
                    type="text"
                    style="fw-normal"
                    value={formContent?.bankDetails?.iban}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.bankDetails.iban = e.target.value;
                      setFormContent(values);
                    }}
                    minWidth={"160px"}
                  />
                  <ContactDetailField
                    label="Swift Code"
                    isEdit={isEdit}
                    type="text"
                    style="fw-normal"
                    value={formContent?.bankDetails?.swiftCode}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.bankDetails.swiftCode = e.target.value;
                      setFormContent(values);
                    }}
                    minWidth={"160px"}
                  />
                  <ContactDetailField
                    label="Branch Name & Code"
                    isEdit={isEdit}
                    type="text"
                    style="fw-normal"
                    value={formContent?.bankDetails?.branchNameCode}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.bankDetails.branchNameCode = e.target.value;
                      setFormContent(values);
                    }}
                    minWidth={"160px"}
                  />
                </Typography>
              </div>
            </Typography>
          </div>
          {/* /Bank Details */}

          {/*  Terms & Conditions:*/}
          {formContent?.termsAndConditions?.trim()?.length > 0 && (
            <div className="mt-5 pe-1 ">
              <Typography variant="body1" className="text-start" gutterBottom>
                <div className="mb-2">
                  <label
                    htmlFor="instructions"
                    className={`fw-bold ${isEdit ? "mb-3" : "mb-1"}`}
                  >
                    Terms & Conditions:
                  </label>
                  {!isEdit ? (
                    <p>
                      {formContent?.termsAndConditions
                        ? formContent?.termsAndConditions
                        : "-"}
                    </p>
                  ) : (
                    <TextArea
                      rows="3"
                      label="Terms & Conditions..."
                      id="instructions"
                      value={formContent?.termsAndConditions}
                      onChange={(e) => {
                        let values = { ...formContent };
                        values.termsAndConditions = e.target.value;
                        setFormContent(values);
                      }}
                    />
                  )}
                </div>
              </Typography>
            </div>
          )}
          {/* / Terms & Conditions */}

          {/*  Validity*/}
          {formContent?.validit?.trim()?.length > 0 && (
            <div className="mt-5 pe-1 pb-5">
              <Typography variant="body1" className="text-start" gutterBottom>
                <div className="mb-2">
                  <label
                    htmlFor="instructions"
                    className={`fw-bold ${isEdit ? "mb-3" : "mb-1"}`}
                  >
                    Validity:
                  </label>
                  {!isEdit ? (
                    <p>{formContent?.validity ? formContent?.validity : "-"}</p>
                  ) : (
                    <TextArea
                      rows="3"
                      label="Terms & Conditions..."
                      id="instructions"
                      value={formContent?.validity}
                      onChange={(e) => {
                        let values = { ...formContent };
                        values.validity = e.target.value;
                        setFormContent(values);
                      }}
                    />
                  )}
                </div>
              </Typography>
            </div>
          )}
          {/* / Validity */}

          {/* Signatures */}
          <div className="d-flex ">
            <div className="ms-auto">
              <div
                style={{ height: "90px" }}
                className="d-flex justify-content-center align-items-end"
              >
                {formContent?.adminSign?.trim()?.length == 0 ? (
                  <>
                    {/* {isEdit && (
                      <div className="d-flex justify-content-center align-items-end">
                        <label
                          htmlFor="uploadSign"
                          className="active-btn ms-2 w-200 d-flex align-items-center justify-content-center cursor-pointer btn"
                        >
                          {uploadText}
                        </label>
                        <input
                          type="file"
                          hidden
                          id="uploadSign"
                          name="uploadSign"
                          onChange={(e) => {
                            if (!isLoading) uploadFile(e);
                          }}
                        />
                      </div>
                    )} */}
                  </>
                ) : (
                  <img
                    src={`${Bucket}${formContent.adminSign}`}
                    height={80}
                    width={200}
                  />
                )}
              </div>
              <p>{"_______________________________"}</p>
              <p className="my-1">
                <b>{"Shelozon Movers Packers & Logistics Pvt Ltd"}</b>
              </p>

              {""}
            </div>
          </div>

          {/* </MainTemplate> */}
        </div>
      ) : (
        <>
          {isLoading ? (
            <div> Loading...</div>
          ) : (
            <div> Ooops... Something went wrong...</div>
          )}
        </>
      )}

      <PrintComponent>
        <InvoiceDownload surveyData={surveyData} formContent={formContent} />
      </PrintComponent>

      {/* snackbar */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={
            snackbarMessage?.includes("successful") ? "success" : "error"
          }
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      {/* /snackbar */}
    </>
  );
};

export default InvoiceClientSide;
