import React, { useState } from "react";
import "./style.css";
import QRCodeAtom from "./../atoms/QRCode";
import {
  Grid,
  TextField,
  IconButton,
  InputAdornment,
  Snackbar,
} from "@mui/material";
import copy from "./../../imgs/copy.svg";

const TwoFactorAuth = ({ qrData, authKey }) => {
  const [open, setOpen] = useState(false);

  const inputLabelProps = {
    shrink: true,
    style: {
      color: "black", // Set the color to red
      fontWeight: "600", // Set the font weight to bold
      fontSize: "15px",
    },
  };

  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(authKey);
    //   navigator.clipboard.readText()
    //   .then((clipboardText) => {
    //   console.log(val);
    //   // Now you can use clipboardText as needed, such as storing it in a state variable or using it in your application.
    // })
    // .catch((error) => {
    //   console.error('Failed to read data from clipboard:', error);
    // });
  };

  return (
    <div className="">
      <Grid item xs={12}>
        <QRCodeAtom value={qrData} width={150} height={150} />
      </Grid>
      <Grid item xs={12} className="mt-5">
        <TextField
          type="text"
          variant="outlined"
          placeholder=""
          fullWidth
          value={authKey}
          onChange={(e) => {}}
          InputLabelProps={inputLabelProps}
          required
          InputProps={{
            style: {
              height: "45px", // Set the height to 40 pixels
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => handleClick()}>
                  <img src={copy} height="28" width="28" />
                </IconButton>
                <Snackbar
                  message="Copied to clipboard"
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  autoHideDuration={2000}
                  onClose={() => setOpen(false)}
                  open={open}
                />
              </InputAdornment>
            ),
          }}
          disabled
        />
      </Grid>
    </div>
  );
};

export default TwoFactorAuth;
