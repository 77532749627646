import React from "react";

const TextArea = ({
  value,
  onChange,
  rows = "",
  id = "",
  label = "",
  style,
  disabled
}) => {
  return (
    <div className={`textarea-container ${style || "w-100"}`}>
      <textarea
        className="styled-field"
        rows={rows}
        value={value}
        onChange={onChange}
        id={id}
        required
        placeholder={label}
        disabled={disabled ? true : false}
      ></textarea>
      {/* <label for="message" className="field-label">
        {label}
      </label> */}
    </div>
  );
};

export default TextArea;
