import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Backdrop,
  Fade,
  Snackbar,
} from "@mui/material";
import crossImg from "../../imgs/cross.png";
import Grid from "@mui/material/Grid";
import EmailInput from "../atoms/EmailInput";
import MuiAlert from "@mui/material/Alert";
import { Row, Col } from "react-bootstrap";
import removeImg from "../../imgs/remove.png";

const ShareWithClientsModal = ({ open, onClose, onSuccess }) => {
  const [isLoading, setIsLoading] = useState(false)

  const [addedEmails, setAddedEmails] = useState([""]);

  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  //
  const handleAddNewDataRows = () => {
    const values = [...addedEmails];
    values.push("");

    setAddedEmails(values);
  };

  //
  const handleRemoveDataRows = (ind) => {
    const values = [...addedEmails];
    values.splice(ind, 1);
    setAddedEmails(values);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="mx-3"
      >
        <Fade in={open}>
          <div className="modal-container">
            {" "}
            {/* CSS class for centering */}
            <div className="modal-content assign-modal px-4">
              <div className="d-flex">
                <img
                  src={crossImg}
                  height="26"
                  width="26"
                  className="ms-auto cursor-pointer"
                  onClick={() => onClose()}
                />{" "}
              </div>
              {/* Modal content goes here */}
              <p className="modal-head mb-0">Share with Recipients</p>
              <p className="modal-subhead">Add emails here</p>
              <div className="share-max-height">
                {addedEmails.map((file, index) => {
                  return (
                    <Row className="pe-4 mt-2">
                      <Col item xs={11} className="mt-2">
                        <EmailInput
                          value={file}
                          onChange={(e) => {
                            let values = [...addedEmails]
                            values[index] = e.target.value
                            setAddedEmails(values)
                          }}
                          error={emailError}
                        />
                      </Col>
                      <Col item xs={1} className="mt-2 mt-md-3">
                        <img
                          src={removeImg}
                          height={18}
                          width={18}
                          className="cursor-pointer"
                          onClick={() => handleRemoveDataRows(index)}
                        />
                      </Col>
                    </Row>
                  );
                })}
              </div>

              <Row className="mb-0 mt-5">
                <Col className="col-12 text-end">
                  <Button
                    className="add-new-btn ms-auto"
                    onClick={() => {
                      handleAddNewDataRows();
                    }}
                  >
                    Add New
                  </Button>
                </Col>
              </Row>

              <Grid container spacing={2} className="mt-2">
                <Grid item xs={12}>
                  <Button
                    type="button"
                    variant="contained"
                    className={
                      isLoading
                        ? "disabled-btn mt-0 mt-md-3 mb-3"
                        : "submit-btn mt-0 mt-md-3 mb-3"
                    }
                    disabled={isLoading}
                    onClick={() => {
                      onSuccess(addedEmails)
                    }}
                  >
                    Share
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </Fade>
      </Modal>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={
            snackbarMessage?.includes("successful") ? "success" : "error"
          }
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default ShareWithClientsModal;
