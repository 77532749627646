import React, { useEffect, useState } from "react";
import {
  updateFormContentPreview,
  getFormContentPreview,
  imageUploadClientPreview,
} from "../../../../api/services/services";
import { Button, Snackbar, Box } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import { Bucket } from "../../../../api/config/apiConfig";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import Input from "../../../atoms/Input";
import removeImg from "../../../../imgs/remove.png";
import { useParams } from "react-router-dom";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import html2pdf from "html2pdf.js";
import Typography from "@mui/material/Typography";
import ContactDetailField from "../../../molecules/ContactDetailField";
import DynamicText from "../../../molecules/DynamicText";
import { useLocation } from "react-router-dom";

const PackingInventoryDownload = ({ surveyData, formContent }) => {
  const [isEdit, setIsEdit] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <div className="mx-4">
        <div className="d-flex align-items-center justify-content-center w-100">
          <p className="ps-0 mb-0 head-top"> Packing Inventory</p>
        </div>

        {/* file detail*/}
        <div className="contact-detail d-flex mt-1">
          {/* left col */}
          <div
            className={`left-col ${!isEdit ? " w-50" : "w-50 pe-5"} text-start`}
          >
            <Typography variant="body1" gutterBottom>
              <div className="d-flex">
                <p
                  className="p-word mb-1 text-start"
                  style={{ minWidth: "100px", fontSize: "11px" }}
                >
                  <b>Client Name:</b>
                </p>

                <p className="p-word mb-1 text-start">
                  <span className="ms-1 ">
                    {" "}
                    {formContent?.clientName ? formContent?.clientName : "-"}
                  </span>
                </p>
              </div>

              {/*  */}
              <div className="d-flex">
                <p
                  className="p-word mb-1 text-start"
                  style={{ minWidth: "100px", fontSize: "11px" }}
                >
                  <b>Origin Address:</b>
                </p>

                <p className="p-word mb-1 text-start">
                  <span className="ms-1 ">
                    {" "}
                    {formContent?.originAddress
                      ? formContent?.originAddress
                      : "-"}
                  </span>
                </p>
              </div>
              {/*  */}
              <div className="d-flex">
                <p
                  className="p-word mb-1 text-start"
                  style={{ minWidth: "100px", fontSize: "11px" }}
                >
                  <b>Destination Address:</b>
                </p>

                <p className="p-word mb-1 text-start">
                  <span className="ms-1 ">
                    {" "}
                    {formContent?.destinationAddress
                      ? formContent?.destinationAddress
                      : "-"}
                  </span>
                </p>
              </div>

              {/*  */}
              <div className="d-flex">
                <p
                  className="p-word mb-1 text-start"
                  style={{ minWidth: "100px", fontSize: "11px" }}
                >
                  <b>Organization:</b>
                </p>

                <p className="p-word mb-1 text-start">
                  <span className="ms-1 ">
                    {" "}
                    {formContent?.organization
                      ? formContent?.organization
                      : "-"}
                  </span>
                </p>
              </div>
            </Typography>
          </div>
          {/* right col */}
          <div
            className={`right-col ${
              !isEdit ? "w-50" : "w-50 ps-5"
            }  d-flex flex-column align-items-end pe-2`}
          >
            <div
              className=""
              style={isEdit ? { width: "-webkit-fill-available" } : {}}
            >
              <Typography
                variant="body1"
                className="text-start w-100"
                gutterBottom
              >
                {/*  */}
                <div className="d-flex">
                  <p
                    className="p-word mb-1 text-start"
                    style={{ minWidth: "80px", fontSize: "11px" }}
                  >
                    <b>File Ref:</b>
                  </p>

                  <p className="p-word mb-1 text-start">
                    <span className="ms-1 ">
                      {" "}
                      {formContent?.fileRef ? formContent?.fileRef : "-"}
                    </span>
                  </p>
                </div>

                {/*  */}
                <div className="d-flex">
                  <p
                    className="p-word mb-1 text-start"
                    style={{ minWidth: "80px", fontSize: "11px" }}
                  >
                    <b>Van No. :</b>
                  </p>

                  <p className="p-word mb-1 text-start">
                    <span className="ms-1 ">
                      {" "}
                      {formContent?.vanNo ? formContent?.vanNo : "-"}
                    </span>
                  </p>
                </div>
                {/*  */}
                <div className="d-flex">
                  <p
                    className="p-word mb-1 text-start"
                    style={{ minWidth: "80px", fontSize: "11px" }}
                  >
                    <b>Date:</b>
                  </p>

                  <p className="p-word mb-1 text-start">
                    <span className="ms-1 ">
                      {" "}
                      {formContent?.date ? formContent?.date : "-"}
                    </span>
                  </p>
                </div>
              </Typography>
            </div>
          </div>
        </div>
        {/* /file detail */}

        {/* Symbols */}
        <Row className="mx-0 mt-2">
          {" "}
          <Col className="col-6  text-start ps-0 mb-1">
            <p style={{ fontSize: "11px" }} className="mb-0">
              DESCRIPTIVE SYMBOLS
            </p>
          </Col>
          <Col className="col-6 text-start ps-0 mb-1">
            <p style={{ fontSize: "11px" }} className="mb-0">
              EXCEPTION SYMBOLS
            </p>
          </Col>
        </Row>
        <Row className="mx-0">
          <Col className="col-6 ">
            <div
              className="p-3 row  me-1 px-1"
              style={{ backgroundColor: "#f4f5fa" }}
            >
              <div className="col-6 px-2">
                <div
                  className={`p-2 px-0 d-flex justify-content-center  mb-2  ${
                    formContent?.descriptiveSymbols?.professionalBooks
                      ? "symbols-active"
                      : "symbols-inactive"
                  }`}
                  onClick={() => {
                    let values = { ...formContent };
                    values.descriptiveSymbols.professionalBooks =
                      !values.descriptiveSymbols.professionalBooks;
                  }}
                >
                  {" "}
                  PB - PROFESSIONAL BOOKS
                </div>
              </div>
              {/*  */}
              <div className="col-6 px-2">
                <div
                  className={`p-2 px-0 d-flex justify-content-center  mb-2  ${
                    formContent?.descriptiveSymbols?.colorTV
                      ? "symbols-active"
                      : "symbols-inactive"
                  }`}
                  onClick={() => {
                    let values = { ...formContent };
                    values.descriptiveSymbols.colorTV =
                      !values.descriptiveSymbols.colorTV;
                  }}
                >
                  {" "}
                  C/T - COLOR TV
                </div>
              </div>

              {/*  */}
              <div className="col-6 px-2">
                <div
                  className={`p-2 px-0 d-flex   justify-content-center  mb-2  ${
                    formContent?.descriptiveSymbols?.professionalEquipments
                      ? "symbols-active"
                      : "symbols-inactive"
                  }`}
                  onClick={() => {
                    let values = { ...formContent };
                    values.descriptiveSymbols.professionalEquipments =
                      !values.descriptiveSymbols.professionalEquipments;
                  }}
                >
                  {" "}
                  PE - PROFESSIONAL EQUIPMENTS
                </div>
              </div>

              {/*  */}
              <div className="col-6 px-2">
                <div
                  className={`p-2 px-0 d-flex   justify-content-center  mb-2  ${
                    formContent?.descriptiveSymbols?.carrierDis
                      ? "symbols-active"
                      : "symbols-inactive"
                  }`}
                  onClick={() => {
                    let values = { ...formContent };
                    values.descriptiveSymbols.carrierDis =
                      !values.descriptiveSymbols.carrierDis;
                  }}
                >
                  {" "}
                  CD - CARRIER DISASSEMBLED
                </div>
              </div>

              {/*  */}

              <div className="col-6 px-2">
                <div
                  className={`p-2 px-0 d-flex   justify-content-center  mb-2 ${
                    formContent?.descriptiveSymbols?.professionalPapers
                      ? "symbols-active"
                      : "symbols-inactive"
                  }`}
                  onClick={() => {
                    let values = { ...formContent };
                    values.descriptiveSymbols.professionalPapers =
                      !values.descriptiveSymbols.professionalPapers;
                  }}
                >
                  {" "}
                  PP - PROFESSIONAL PAPERS
                </div>
              </div>

              {/*  */}

              <div className="col-6 px-2">
                <div
                  className={`p-2 px-0 d-flex   justify-content-center  mb-2  ${
                    formContent?.descriptiveSymbols?.disByOwner
                      ? "symbols-active"
                      : "symbols-inactive"
                  }`}
                  onClick={() => {
                    let values = { ...formContent };
                    values.descriptiveSymbols.disByOwner =
                      !values.descriptiveSymbols.disByOwner;
                  }}
                >
                  {" "}
                  DBO - DISASSEMBLED BY OWNER
                </div>
              </div>

              {/*  */}
              <div className="col-6 px-2">
                <div
                  className={`p-2 px-0 d-flex justify-content-center  mb-0 ${
                    formContent?.descriptiveSymbols?.carrierPacked
                      ? "symbols-active"
                      : "symbols-inactive"
                  }`}
                  onClick={() => {
                    let values = { ...formContent };
                    values.descriptiveSymbols.carrierPacked =
                      !values.descriptiveSymbols.carrierPacked;
                  }}
                >
                  {" "}
                  CP - CARRIER PACKED
                </div>
              </div>

              {/*  */}
            </div>
          </Col>
          <Col className="col-6 ">
            <div
              className="p-3 row px-1"
              style={{ backgroundColor: "#f4f5fa" }}
            >
              {/*  */}
              <div className="col-4 px-2">
                <div
                  className={`p-2 px-0 d-flex   justify-content-center  mb-2  ${
                    formContent?.exceptions?.rent
                      ? "symbols-active"
                      : "symbols-inactive"
                  }`}
                  onClick={() => {
                    let values = { ...formContent };
                    values.exceptions.rent = !values.exceptions?.rent;
                  }}
                >
                  {" "}
                  RE- RENT
                </div>
              </div>

              {/*  */}
              <div className="col-4 px-2">
                <div
                  className={`p-2 px-0 d-flex justify-content-center mb-2  ${
                    formContent?.exceptions?.dented
                      ? "symbols-active"
                      : "symbols-inactive"
                  }`}
                  onClick={() => {
                    let values = { ...formContent };
                    values.exceptions.dented = !values.exceptions?.dented;
                  }}
                >
                  {" "}
                  D- DENTED
                </div>
              </div>

              {/*  */}
              <div className="col-4 px-2">
                <div
                  className={`p-2 px-0 d-flex   justify-content-center  mb-2  ${
                    formContent?.exceptions?.rubbed
                      ? "symbols-active"
                      : "symbols-inactive"
                  }`}
                  onClick={() => {
                    let values = { ...formContent };
                    values.exceptions.rubbed = !values.exceptions?.rubbed;
                  }}
                >
                  {" "}
                  R- RUBBED
                </div>
              </div>

              {/*  */}
              <div className="col-4 px-2">
                <div
                  className={`p-2 px-0 d-flex   justify-content-center  mb-2  ${
                    formContent?.exceptions?.broken
                      ? "symbols-active"
                      : "symbols-inactive"
                  }`}
                  onClick={() => {
                    let values = { ...formContent };
                    values.exceptions.broken = !values.exceptions?.broken;
                  }}
                >
                  {" "}
                  BR-BROKEN
                </div>
              </div>

              {/*  */}

              <div className="col-4 px-2">
                <div
                  className={`p-2 px-0 d-flex   justify-content-center  mb-2  ${
                    formContent?.exceptions?.faded
                      ? "symbols-active"
                      : "symbols-inactive"
                  }`}
                  onClick={() => {
                    let values = { ...formContent };
                    values.exceptions.faded = !values.exceptions?.faded;
                  }}
                >
                  {" "}
                  F-FADED
                </div>
              </div>

              {/*  */}
              <div className="col-4 px-2">
                <div
                  className={`p-2 px-0 d-flex   justify-content-center  mb-2  ${
                    formContent?.exceptions?.rusted
                      ? "symbols-active"
                      : "symbols-inactive"
                  }`}
                  onClick={() => {
                    let values = { ...formContent };
                    values.exceptions.rusted = !values.exceptions?.rusted;
                  }}
                >
                  {" "}
                  RU-RUSTED
                </div>
              </div>

              {/*  */}
              <div className="col-4 px-2">
                <div
                  className={`p-2 px-0 d-flex   justify-content-center  mb-2  ${
                    formContent?.exceptions?.burned
                      ? "symbols-active"
                      : "symbols-inactive"
                  }`}
                  onClick={() => {
                    let values = { ...formContent };
                    values.exceptions.burned = !values.exceptions?.burned;
                  }}
                >
                  {" "}
                  BU-BURNED
                </div>
              </div>

              {/*  */}
              {/*  */}
              <div className="col-4 px-2">
                <div
                  className={`p-2 px-0 d-flex   justify-content-center  mb-2  ${
                    formContent?.exceptions?.loose
                      ? "symbols-active"
                      : "symbols-inactive"
                  }`}
                  onClick={() => {
                    let values = { ...formContent };
                    values.exceptions.loose = !values.exceptions?.loose;
                  }}
                >
                  {" "}
                  L-LOOSE
                </div>
              </div>

              {/*  */}
              <div className="col-4 px-2">
                <div
                  className={`p-2 px-0 d-flex   justify-content-center  mb-2  ${
                    formContent?.exceptions?.badly
                      ? "symbols-active"
                      : "symbols-inactive"
                  }`}
                  onClick={() => {
                    let values = { ...formContent };
                    values.exceptions.badly = !values.exceptions?.badly;
                  }}
                >
                  {" "}
                  W-BADLY
                </div>
              </div>

              {/*  */}
              <div className="col-4 px-2">
                <div
                  className={`p-2 px-0 d-flex justify-content-center ${
                    formContent?.exceptions?.chipped
                      ? "symbols-active"
                      : "symbols-inactive"
                  }`}
                  onClick={() => {
                    let values = { ...formContent };
                    values.exceptions.chipped = !values.exceptions?.chipped;
                  }}
                >
                  {" "}
                  CH-CHIPPED
                </div>
              </div>

              {/*  */}
              <div className="col-4 px-2">
                <div
                  className={`p-2 px-0 d-flex justify-content-center   ${
                    formContent?.exceptions?.marred
                      ? "symbols-active"
                      : "symbols-inactive"
                  }`}
                  onClick={() => {
                    let values = { ...formContent };
                    values.exceptions.marred = !values.exceptions?.marred;
                  }}
                >
                  {" "}
                  M-MARRED
                </div>
              </div>

              {/*  */}
            </div>
          </Col>
        </Row>

        <Table responsive className="mt-3">
          {/* Table Header */}
          <thead className="bg-white">
            <tr>
              <th
                className="border p-1 text-start"
                style={{ width: "10%", fontSize: "10px" }}
              >
                Item No.
              </th>
              <th
                className="border p-1 text-start"
                style={{ width: "50%", fontSize: "10px" }}
              >
                Goods Description
              </th>
              <th
                className="border p-1 text-start"
                style={{ width: "20%", fontSize: "10px" }}
              >
                Condition at Origin
              </th>
              <th
                className="border p-1 text-start"
                style={{ width: "20%", fontSize: "10px" }}
              >
                Condition at Destination
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {formContent?.dataRows?.length > 0 ? (
              <>
                {formContent?.dataRows.map((row, index) => (
                  <tr key={index}>
                    <td
                      className="border p-1 text-start"
                      style={{ fontSize: "10px" }}
                    >
                      {index + 1}
                    </td>
                    <td
                      className="border p-1 text-start"
                      style={{ fontSize: "10px" }}
                    >
                      {isEdit ? (
                        <>
                          <Input
                            id="condition"
                            label="Present Condition..."
                            type="text"
                            style="full-border-field"
                            value={row?.description}
                            inline={{ width: "-webkit-fill-available" }}
                          />
                        </>
                      ) : row?.description ? (
                        row?.description
                      ) : (
                        "-"
                      )}
                    </td>
                    <td
                      className="border p-1 text-start"
                      style={{ fontSize: "10px" }}
                    >
                      {isEdit ? (
                        <Input
                          id="condition"
                          label="Present Condition..."
                          type="text"
                          style="full-border-field"
                          value={row?.conditionAtOrigin}
                          inline={{ width: "-webkit-fill-available" }}
                        />
                      ) : row?.conditionAtOrigin ? (
                        row?.conditionAtOrigin
                      ) : (
                        "-"
                      )}
                    </td>

                    <td
                      className="border p-1 text-start"
                      style={{ fontSize: "10px" }}
                    >
                      {isEdit ? (
                        <Input
                          id="condition"
                          label="Present Condition..."
                          type="text"
                          style="full-border-field"
                          value={row?.conditionAtDestination}
                          inline={{ width: "-webkit-fill-available" }}
                        />
                      ) : row?.conditionAtDestination ? (
                        row?.conditionAtDestination
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td className="text-center" colSpan={9}>
                  {isLoading ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "10px 0px",
                      }}
                    >
                      <CircularProgress
                        style={{
                          color: "#00aff0",
                          height: "25px",
                          width: "25px",
                        }}
                      />
                    </Box>
                  ) : (
                    <div
                      className="d-flex justify-content-center"
                      style={{ fontSize: "10px" }}
                    >
                      <p className="my-3">No Records to Show</p>
                    </div>
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        {/* Remarks */}
        {formContent?.remarks?.trim()?.length != 0 && (
          <div className="d-flex mt-1">
            <p className="p-word mb-1 text-start" style={{ fontSize: "11px" }}>
              <b>Remarks:</b>
            </p>

            <p className="p-word mb-1 text-start ps-1">
              <span className="ms-1 ">
                {" "}
                {formContent?.remarks ? formContent?.remarks : "-"}
              </span>
            </p>
          </div>
        )}
        {/* /Remakes */}

        <div className="mt-1 pe-1">
          <p className="mb-2">
            WE HAVE CHECKED ALL THE ITEM LISTED AND NUMBERED{" "}
            <b>{formContent?.number1 ? formContent?.number1 : "-"}</b> to
            <b>{formContent?.number2 ? formContent?.number2 : "-"}</b> INCLUSIVE
            AND ACKNOWLEDGE THAT THIS IS A TRUE AND COMPLETE LIST OF THE GOODS.
          </p>
        </div>

        <Row className="mt-1">
          <Col sm={6} className="">
            <div
              style={{ height: "90px" }}
              className="d-flex justify-content-center align-items-end"
            >
              {formContent?.destContractorSign?.trim()?.length == 0 ? (
                <></>
              ) : (
                <img
                  src={`${Bucket}${formContent.destContractorSign}`}
                  height={80}
                  width={200}
                />
              )}
            </div>
            <p className="mb-0">{"_______________________________"}</p>
            <p className="my-0">
              <b>{"Contractor Carrier OR Authorized Agent at DESTINATION"}</b>
            </p>

            {""}
          </Col>
          <Col sm={6} className="">
            <div
              style={{ height: "90px" }}
              className="d-flex justify-content-center align-items-end"
            >
              {formContent?.originContractorSign?.trim()?.length == 0 ? (
                <></>
              ) : (
                <img
                  src={`${Bucket}${formContent?.originContractorSign}`}
                  height={80}
                  width={200}
                />
              )}
            </div>

            <p className="mb-0">{"_______________________________"}</p>
            <p className="my-0">
              <b>{"Contractor Carrier OR Authorized Agent at ORIGIN"}</b>
            </p>

            {""}
          </Col>

          <Col sm={6} className="">
            <div
              style={{ height: "90px" }}
              className="d-flex justify-content-center align-items-end"
            >
              {formContent.clientSignDestination != "" &&
                formContent.clientSignDestination != undefined && (
                  <img
                    src={`${Bucket}${formContent.clientSignDestination}`}
                    height={80}
                    width={200}
                  />
                )}
            </div>
            <p className="mb-0">{"_______________________________"}</p>
            <p className="my-0">
              <b>{"Client OR Authorized Person at DESTINATION"}</b>
            </p>

            {""}
          </Col>
          <Col sm={6} className="">
            <div
              style={{ height: "90px" }}
              className="d-flex justify-content-center align-items-end"
            >
              {formContent.clientSign != "" &&
                formContent.clientSign != undefined && (
                  <img
                    src={`${Bucket}${formContent.clientSign}`}
                    height={80}
                    width={200}
                  />
                )}
            </div>
            <p className="mb-0">{"_______________________________"}</p>
            <p className="my-0">
              <b>{"Client OR Authorized Person at ORIGIN"}</b>
            </p>

            {""}
          </Col>
        </Row>
      </div>
    </>
  );
};
export default PackingInventoryDownload;
