import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import logo from "../../../imgs/logo-final.png";
import ResetPasswordForm from "../../molecules/ResetPasswordForm";
import { useNavigate, useLocation } from "react-router-dom";
import {verifyForgotToken,resetPassword,} from "../../../api/services/services";
import successImg from "../../../imgs/succes.svg";

const ResetPasswordPage = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const navigate = useNavigate();
  const [isCreate, setIsCreate] = useState(true);
  const [success, setSuccess] = useState(false);

  const location = useLocation();
  const urlData = new URLSearchParams(location.search);
  const token = urlData.get("token");

  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  //
  const handleResetPassword = (formData) => {
    console.log("Reset Password Data:", formData);
    const body = {
      password: formData.password,
      passwordConfirmation: formData.confirmPassword,
    };

    resetPassword(token, body)
      .then((response) => {
        setIsValid(true);
        setIsCreate(false);
        setSuccess(true);
      })
      .catch((error) => {
        console.error("Error resetting password:", error);
        if (error.response.data.statusCode == 1002) {
          setSnackbarMessage("Token has been expired");
          setOpenSnackbar(true);
        } else {
          setSnackbarMessage(error.response.data.message);
          setOpenSnackbar(true);
        }
      });
  };

  useEffect(() => {
    verifyForgotToken(token)
      .then((response) => {
        setIsValid(true);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error verifying token:", error.response.data);
        if (error.response.data.statusCode == 1002) {
          setSnackbarMessage("Token has been expired");
          setOpenSnackbar(true);
        } else {
          setSnackbarMessage(error.response.data.message);
          setOpenSnackbar(true);
        }
      });
  }, []);

  return (
    <>
      {isValid ? (
        <div className="login-bg">
          <Container
            maxWidth="xs"
            sx={{
              display: "flex",
              alignItems: "center",
              minHeight: "100vh",
              justifyContent: "center",
            }}
          >
            {isCreate && (
              <Card
                sx={{pb: 1 }}
                style={{ borderRadius: "15px" }}
                className="create-card mx-2"
              >
                <CardContent>
                  <img
                    src={logo}
                    alt="Shezlon Logo"
                    width="260"
                    height="70"
                    className="mt-2"
                  />
                  <Typography variant="p" align="center" gutterBottom>
                  <p className="title p-0 mb-1 mt-3"  style={{position: 'relative',
                zIndex: '999'}}>Create New Password</p>
                  </Typography>
                  <Typography variant="p" align="center" gutterBottom>
                    <p className="desc mb-4">
                      {" "}
                      Create your new password to continue
                    </p>
                  </Typography>
                  <ResetPasswordForm onSubmit={handleResetPassword} />
                </CardContent>
              </Card>
            )}
            {success && (
              <Card
                sx={{ py: 3, pb: 1 }}
                style={{ borderRadius: "15px" }}
                className="success-card mx-2"
              >
                <CardContent>
                  <img
                    src={successImg}
                    alt="Shezlon Logo"
                    width="84"
                    height="84"
                    className="mb-4"
                  />
                  <Typography variant="p" align="center" gutterBottom>
                  <p className="success-title p-0 mb-1">
                      Password Created Successfully
                    </p>
                  </Typography>
                  <Typography variant="p" align="center" gutterBottom>
                  <p className="desc mb-2 mb-md-4">
                      {" "}
                      Your new password has been created successfully.
                    </p>
                  </Typography>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      className="submit-btn mt-1 mb-3 text-capitalize"
                      onClick={() => navigate("/login")}
                    >
                      Go to Login
                    </Button>
                  </Grid>
                </CardContent>
              </Card>
            )}
          </Container>
        </div>
      ) : (
        <>
          {isLoading ? (
            <div> Loading...</div>
          ) : (
            <div> Ooops... Something went wrong...</div>
          )}
        </>
      )}

      {/*  */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={
            snackbarMessage?.includes("successful") ? "success" : "error"
          }
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default ResetPasswordPage;
