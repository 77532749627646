import { getList, deletePrebid } from "./service";
import { useState, useEffect } from "react";
import MainTemplate from "../../../templates/MainTemplate";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Stack, Pagination, PaginationItem, Button } from "@mui/material";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import trashImg from "../../../../imgs/trash.png";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";

/// react functional component
const ListingPrebid = () => {
  const [listOfPrebid, setListOfPrebid] = useState([]);
  const [totalRecord, setTotalRecords] = useState(0);
  const [whichPage, setWhichPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const navigate = useNavigate();

  const getPrebidList = async () => {
    setLoading(true);
    getList(whichPage, itemsPerPage, search)
      .then((response) => {
        console.log(response.data.data);
        setListOfPrebid(response.data.data.prebidList);
        setTotalRecords(response.data.data.totalRecord);
        setLoading(false);
      })
      .catch((_) => {
        setListOfPrebid([]);
        setTotalRecords(0);
        setWhichPage(0);
        setLoading(false);
      });
  };

  const deleteP = async (uuid) => {
    setDeleteLoading(true);
    deletePrebid(uuid)
      .then((_) => {
        setDeleteLoading(false);
        setWhichPage(0);
      })
      .catch((_) => {
        setDeleteLoading(false);
        setWhichPage(0);
      });
  };

  useEffect(() => {
    console.log("call");
    getPrebidList();
  }, [itemsPerPage, search, whichPage]);

  const handleRowsPerPageChange = (event) => {
    const newSize = parseInt(event.target.value);
    setItemsPerPage(newSize);
    setWhichPage(1); // Reset to the first page when the page size changes
  };

  const handlePageChange = (event, newPage) => {
    setWhichPage(newPage);
  };

  const moveToCreateOrUpdate = async (uuid) => {
    if (uuid == null) {
      navigate(`/create-update-prebid/uuid`);
      return;
    }
    navigate(`/create-update-prebid/${uuid}`);
  };

  return (
    <MainTemplate>
      <div className="">
        <p className="main-head px-1 py-4 pb-2 pb-sm-4">Prebid Agreements</p>

        <Paper component="form" style={searchContainerStyle}>
          <IconButton style={searchIconStyle} aria-label="search">
            <SearchIcon />
          </IconButton>
          <InputBase
            placeholder="Search..."
            style={inputBaseStyle}
            inputProps={{ "aria-label": "search" }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

          <Button
            className="role-btn me-1 d-none d-sm-block"
            onClick={() => {
              // console.log(true);
              // setAddRoleModal(true);
              moveToCreateOrUpdate(null);
            }}
          >
            Add New Prebid Agreement
          </Button>
        </Paper>
        <Grid container className="px-1 my-4">
          <TableContainer component={Paper} className="">
            <Table>
              {/* Table Header */}
              <TableHead>
                <TableRow>
                  <TableCell className="table-head min-w-75">
                    Issue Date
                  </TableCell>
                  <TableCell className="table-head min-w-75">
                    Contract Price
                  </TableCell>
                  <TableCell className="table-head min-w-100">
                    Car Description
                  </TableCell>
                  <TableCell className="table-head min-w-150">Model</TableCell>
                  <TableCell className="table-head min-w-75">
                    Party Name
                  </TableCell>
                  <TableCell className="table-head min-w-75">
                    Party Email
                  </TableCell>
                  <TableCell className="table-head min-w-25">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listOfPrebid?.length > 0 ? (
                  <>
                    {listOfPrebid.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell
                          className="cursor-pointer"
                          onClick={() => {
                            moveToCreateOrUpdate(row.uuid);
                          }}
                        >
                          {/* <a
                              onClick={() => navigate(`/survey/${row?.uuid}`)}
                              className="link-cls"
                            > */}{" "}
                          {row?.issueDate ? row?.issueDate : "-"}
                          {/* </a> */}
                        </TableCell>
                        <TableCell
                          className="cursor-pointer"
                          onClick={() => {
                            moveToCreateOrUpdate(row.uuid);
                          }}
                        >
                          {row?.contractPrice ? row?.contractPrice : "-"}
                        </TableCell>
                        <TableCell
                          className="cursor-pointer"
                          onClick={() => {
                            moveToCreateOrUpdate(row.uuid);
                          }}
                        >
                          {row?.carDescription.make
                            ? row?.carDescription.make
                            : "-"}
                        </TableCell>
                        <TableCell
                          className="cursor-pointer"
                          onClick={() => {
                            moveToCreateOrUpdate(row.uuid);
                          }}
                        >
                          {row?.carDescription.model
                            ? row?.carDescription.model
                            : "-"}
                        </TableCell>
                        <TableCell
                          className="cursor-pointer"
                          onClick={() => {
                            moveToCreateOrUpdate(row.uuid);
                          }}
                        >
                          {row?.firstParty.name ? row?.firstParty.name : "-"}
                        </TableCell>
                        <TableCell>
                          {
                            <FormControl
                              sx={{ m: 1, minWidth: 120, margin: "8px 0px" }}
                            >
                              {row?.firstParty.email
                                ? row?.firstParty.email
                                : "-"}
                            </FormControl>
                          }
                        </TableCell>
                        <TableCell className="cursor-pointer">
                          {deleteLoading ? (
                            <CircularProgress
                              style={{
                                color: "#00aff0",
                                height: "15px",
                                width: "15px",
                              }}
                            />
                          ) : (
                            <img
                              src={trashImg}
                              height={20}
                              width={20}
                              onClick={() => {
                                deleteP(row.uuid);
                              }}
                            />
                          )}
                        </TableCell>

                        {/* Add more table cells for other columns */}
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <TableRow className="text-center">
                    <TableCell colSpan={6}>
                      {isLoading ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "10px 0px",
                          }}
                        >
                          <CircularProgress
                            style={{
                              color: "#00aff0",
                              height: "25px",
                              width: "25px",
                            }}
                          />
                        </Box>
                      ) : (
                        <div className="d-flex justify-content-center">
                          <p className="my-3">No Records to Show</p>
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container className="px-1 my-4">
            <Grid item xs={12} sm={6} className="">
              <div className="d-flex justify-content-center justify-content-sm-start">
                <FormControl
                  sx={{
                    minWidth: 120,
                    margin: "8px 0px",
                    backgroundColor: "#fff",
                    textAlign: "center",
                  }}
                >
                  <Select
                    value={itemsPerPage}
                    onChange={handleRowsPerPageChange}
                    displayEmpty
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                  >
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="25">25</MenuItem>
                    <MenuItem value="50">50</MenuItem>
                  </Select>
                </FormControl>
                <p className="pt-3 px-2  d-md-block">Entries per page</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} className="">
              <div className="d-flex justify-content-center justify-content-sm-end">
                <Stack spacing={2} className="mt-3">
                  <Pagination
                    count={totalRecord}
                    page={whichPage}
                    onChange={handlePageChange}
                    color="primary"
                    renderItem={(item) => (
                      <PaginationItem
                        component="button"
                        onClick={() => handlePageChange(null, item.page)}
                        {...item}
                      />
                    )}
                    prevIcon={<ArrowCircleLeftIcon />}
                    nextIcon={<ArrowCircleRightIcon />}
                  />
                </Stack>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </MainTemplate>
  );
};

const searchContainerStyle = {
  display: "flex",
  alignItems: "center",
  backgroundColor: "#fff",
  borderRadius: "10px", // Adjust as needed
  margin: "25px 5px 0px 5px",
  boxShadow: "none",
  // width: '25%'
};

const searchIconStyle = {
  padding: "10px",
  color: "gray",
};

const inputBaseStyle = {
  flex: 1,
  padding: "5px 10px",
};

export default ListingPrebid;

/// api request GET, POST,PATCH,DELETE, read

/////  promises async await  .then

//////   Data Structure  ->  array , map , graph etc

///// state
/// when list of prebid is empty
/// when list of prebid is notEmpty
/// api called inprocess
/// api called data empty
/// apu call
