// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hover-checklist:hover{
    color: #00aff0 !important;
    text-decoration: underline !important;
}

.crew .form-check-input:disabled ~ .form-check-label {
    opacity: 1 !important
}

.crew .form-check-input:disabled  {
    opacity: 0.7 !important
}

@media print {
.MuiSnackbar-root{
    display: none !important;

}
}`, "",{"version":3,"sources":["webpack://./src/components/pages/AddedFiles/style.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,qCAAqC;AACzC;;AAEA;IACI;AACJ;;AAEA;IACI;AACJ;;AAEA;AACA;IACI,wBAAwB;;AAE5B;AACA","sourcesContent":[".hover-checklist:hover{\n    color: #00aff0 !important;\n    text-decoration: underline !important;\n}\n\n.crew .form-check-input:disabled ~ .form-check-label {\n    opacity: 1 !important\n}\n\n.crew .form-check-input:disabled  {\n    opacity: 0.7 !important\n}\n\n@media print {\n.MuiSnackbar-root{\n    display: none !important;\n\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
