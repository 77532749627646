// components/molecules/DynamicText.js
import React from "react";
import TextArea from "../atoms/Textarea";

const DynamicText = ({
  label,
  value,
  isEdit,
  id,
  textAreaLabel,
  onChange,
  textAreaValue,
  disabled
}) => {
  return (
    <div className="mb-2">
      <label htmlFor={label} className={`fw-bold ${isEdit ? "mb-3" : "mb-2"}`}>
        {label}
      </label>
      {!isEdit ? (
        <p>{value ? value : '-'}</p>
      ) : (
        <TextArea
          rows="3"
          id={id}
          value={value}
          onChange={onChange}
          className="w-100"
          label={textAreaLabel}
          disabled={disabled ? true : false}
        />
      )}
    </div>
  );
};

export default DynamicText;
