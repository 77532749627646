import React, { useEffect, useState } from "react";
import {
  getFormContent,
  getSingleSurvey,
  updateFormContent,
  surveyResultPreview,
  sharePreviewLink,
  addFormContent,
  imageUpload,
} from "../../../api/services/services";
import backArrow from "../../../imgs/BackArrow.svg";
import MainTemplate from "../../templates/MainTemplate";
import { useNavigate } from "react-router-dom";
import { Button, Snackbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import { Bucket } from "../../../api/config/apiConfig";
import ContactDetailField from "../../molecules/ContactDetailField";
import Input from "../../atoms/Input";
import DynamicText from "../../molecules/DynamicText";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import html2pdf from "html2pdf.js";
import { useParams } from "react-router-dom";
import PrintComponent from "../PrintComponent";
import DeliveryReceiptDownload from "./ClientSide/download";
import editIcn from "../../../imgs/edit.png";
import trashIcn from "../../../imgs/trash.png";
import LoadingModal from "../../molecules/LoadingModal";
import moment from "moment";
import MySignatureCanvas from "../../molecules/signature_canvas";
import SuperAdminVerifationModal from "../PrebidAgreement/creation/super_admin_verification_modal";
import { sendSuperAdminVerificationForEdit } from "../PrebidAgreement/creation/service";

const DeliveryReceiptPage = () => {
  const { surveyId, id, flg } = useParams();
  const [surveyData, setSurveyData] = useState(null);
  const navigate = useNavigate();

  const [formContentId, setFormContentId] = useState("");
  const [isEdit, setIsEdit] = useState(flg == "1" ? true : false);

  const [isLoading, setIsLoading] = useState(false);
  const [formContent, setFormContent] = useState({
    fileRef: "",
    clientName: "",
    clientEmail: "",
    clientSign: "",
    organization: "Shelozon",
    originAddress: "",
    destinationAddress: "",
    commodity: "",
    noOfPackages: "",
    modeOfShippment: "",
    weightVolume: "",
    truckNo: "",
    container: "",
    remarks: "",
    adminSign: "",
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [isShare, setIsShare] = useState(false);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  //
  const getSurveyPreview = () => {
    surveyResultPreview(surveyId)
      .then((response) => {
        setSurveyData(response?.data?.data?.surveyDetail);
        let values = { ...formContent };
        values.originAddress = `${response?.data?.data?.surveyDetail?.loadPlaceStAddress}, ${response?.data?.data?.surveyDetail?.loadPlaceCity}, ${response?.data?.data?.surveyDetail?.loadPlaceCountry}`;
        values.destinationAddress = `${response?.data?.data?.surveyDetail?.unloadPlaceStAddress}, ${response?.data?.data?.surveyDetail?.unloadPlaceCity}, ${response?.data?.data?.surveyDetail?.unloadPlaceCountry}`;
        values.clientName = `${response?.data?.data?.surveyDetail?.name}`;
        values.clientEmail = `${response?.data?.data?.surveyDetail?.email}`;
        values.fileRef = `${response?.data?.data?.surveyDetail?.id}`;

        setFormContent(values);
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }
      });
  };

  //
  const getSurvey = () => {
    getSingleSurvey(surveyId)
      .then((response) => {
        if (response?.data?.data?.status == "submitted") getSurveyPreview();
        else {
          setSurveyData(response?.data?.data);
          let values = { ...formContent };
          values.originAddress = `${response?.data?.data?.loadPlaceStAddress}, ${response?.data?.data?.loadPlaceCity}, ${response?.data?.data?.loadPlaceCountry}`;
          values.destinationAddress = `${response?.data?.data?.unloadPlaceStAddress}, ${response?.data?.data?.unloadPlaceCity}, ${response?.data?.data?.unloadPlaceCountry}`;
          values.clientName = `${response?.data?.data?.name}`;
          values.clientEmail = `${response?.data?.data?.email}`;
          values.fileRef = `${response?.data?.data?.id}`;

          setFormContent(values);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }
      });
  };

  const [uploadText, setUploadText] = useState("Upload Sign");

  const [mediaKeysToSend, setMediaKeysToSend] = useState([]);
  const [mediaKeysToRemove, setMediaKeysToRemove] = useState([]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //
  const addContent = () => {
    setIsLoading(true);
    const body = {
      formId: id,
      surveyId: surveyId,
      content: formContent,
      mediaKeys: mediaKeysToSend,
    };
    addFormContent(body)
      .then(() => {
        setSnackbarMessage("Delivery Receipt has been added successfully!");
        setOpenSnackbar(true);

        setTimeout(() => {
          navigate(-1);
          setIsLoading(false);
        }, 1000);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  //
  const updateContent = () => {
    setIsLoading(true);
    const body = {
      formId: id,
      surveyId: surveyId,
      content: formContent,
      mediaKeys: mediaKeysToSend,
      removedMediaKeys: mediaKeysToRemove,
    };
    updateFormContent(body, formContentId)
      .then((res) => {
        setSnackbarMessage("Delivery Receipt has been updated successfully!");
        setOpenSnackbar(true);
        getContent();
        setIsEdit(false);

        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  //
  const getContent = () => {
    setIsLoading(true);
    getFormContent(id, surveyId)
      .then((res) => {
        setFormContentId(res.data.data.uuid);
        setFormContent(res.data.data.content);
        setIsLoading(false);
        setMediaKeysToSend([]);
        setMediaKeysToRemove([]);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
        setMediaKeysToSend([]);
        setMediaKeysToRemove([]);
      });
  };

  useEffect(() => {
    if (flg == "1") getSurvey();
    else getContent();
  }, []);

  const [show, setShow] = useState(false);
  const options = {
    // resolution: Resolution.HIGH,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      format: "letter",
      // default is 'portrait'
      orientation: "portrait",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/png",
      qualityRatio: 1,
    },
    overrides: {
      pdf: {
        compress: true,
      },
    },
  };

  //
  const generatePdf = () => {
    setShow(true);

    setTimeout(() => {
      const content = document.getElementById("content-to-convert"); // Replace with the ID of the element you want to convert to PDF

      const currentDateTime = new Date();
      const filename = `survey_${currentDateTime.toISOString()}.pdf`;

      const pdfOptions = {
        margin: 5,
        filename: filename,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
        }, // Set the page size here
      };

      let temp = html2pdf().from(content).set(pdfOptions).outputPdf();
      temp.save(filename);

      setShow(false);
    }, 3500);
  };

  const [downloadText, setDownloadText] = useState("download");

  const getTargetElement = () => document.getElementById("content-id");

  //
  const shareWithClient = () => {
    setIsLoading(true);
    const body = {
      surveyId: surveyId,
      formId: id,
      emails: [formContent?.clientEmail],
      formRoute: "delivery-receipt",
    };
    sharePreviewLink(body)
      .then(() => {
        setSnackbarMessage(
          "Delivery Receipt has been shared with client successfully!"
        );
        setOpenSnackbar(true);

        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setIsLoading(false);
      });
  };

  const printAction = () => {
    const PAGE_HEIGHT = 1045;
    const printElement = document.getElementById("print-component");
    if (printElement) {
      printElement.classList.add("temp-class-for-height");
      const height = printElement.clientHeight;
      const numberOfPage = Math.ceil(height / PAGE_HEIGHT);
      const heightWithSingleHeader = numberOfPage * PAGE_HEIGHT;
      let requiredHeight = heightWithSingleHeader;
      if (numberOfPage > 1) {
        const headerHeight =
          printElement.getElementsByTagName("thead")?.[0]?.clientHeight;
        const footerHeight =
          printElement.getElementsByTagName("tfoot")?.[0]?.clientHeight;
        requiredHeight -= (numberOfPage - 1) * (headerHeight + footerHeight);
      }
      printElement.style.height = `${requiredHeight}px`;
      printElement.classList.remove("temp-class-for-height");
    }
    window.print();
    if (printElement) {
      printElement.style.height = `auto`;
    }
  };

  //
  const uploadFile = async (file) => {
    setIsLoading(true);
    setUploadText("Uploading...");
    handleOpen();
    const fd = new FormData();
    fd.append("image", file);

    imageUpload(fd)
      .then((res) => {
        let values = { ...formContent };

        if (
          formContent?.adminSign != null &&
          formContent?.adminSign?.trim()?.length != 0
        ) {
          let dlt = [...mediaKeysToRemove];
          dlt.push(values.adminSign);
          setMediaKeysToRemove(dlt);
        }
        values.adminSign = res?.data?.data?.key;
        setFormContent(values);

        let md = [...mediaKeysToSend];
        md.push(res?.data?.data?.key);
        setMediaKeysToSend(md);

        setIsLoading(false);
        setUploadText("Upload Sign");
        handleClose();
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }

        setUploadText("Upload Sign");

        setIsLoading(false);
        handleClose();
      });
  };

  const [isSignCanvas, setSignCanvas] = useState(false);

  const [isVerified, setIsVerified] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);

  const sendEditRequestToAdmin = () => {
    setIsEditLoading(true);
    sendSuperAdminVerificationForEdit()
      .then((response) => {
        setIsVerified(true);
        setIsEditLoading(false);
      })
      .catch((error) => {
        setIsVerified(false);
        setIsEditLoading(false);
      });
  };

  return (
    <>
      <MainTemplate>
        <div className="no-print">
          <div className="d-block d-sm-flex align-items-center py-4">
            <div className="d-flex align-items-center ">
              <div
                className="light-blue-color back-bg cursor-pointer"
                onClick={() => navigate(-1)}
              >
                <img src={backArrow} height="24" width="24" />{" "}
              </div>
              <div>
                <p className="main-head ps-3 mb-0">Delivery Receipt</p>
              </div>
            </div>
            <div className="d-flex ms-auto justify-content-center mt-2 mt-sm-0">
              {/*Edit Button */}
              {!isEdit && (
                <Button
                  className="active-btn"
                  onClick={() => {
                    sendEditRequestToAdmin();
                  }}
                  disabled={isEditLoading}
                >
                  Edit
                </Button>
              )}

              {/*Share Button */}
              {!isEdit && (
                <Button
                  className="active-btn ms-3"
                  onClick={() => {
                    shareWithClient();
                  }}
                  disabled={isLoading}
                >
                  Share
                </Button>
              )}

              {/* Cancel button */}
              {isEdit && flg == "2" && (
                <Button
                  className="active-btn ms-3"
                  onClick={() => {
                    getContent();
                    setIsEdit(false);
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
              )}

              {/* Save Button */}
              {isEdit && (
                <Button
                  className="active-btn ms-3"
                  onClick={() => {
                    if (flg == "1") addContent();
                    else updateContent();
                  }}
                  disabled={isLoading}
                >
                  Save
                </Button>
              )}

              {/* Download Button */}
              {!isEdit && (
                <Button
                  className="active-btn ms-3 px-0"
                  onClick={() => {
                    setIsLoading(true);
                    // printAction();
                    var tempTitle = document.title;
                    document.title = `Delivery Receipt-${
                      formContent?.clientName
                    }-${moment(new Date()).format("DD.MM.YYYY")}`;

                    window.print();
                    document.title = tempTitle;
                    setIsLoading(false);
                  }}
                  disabled={isLoading}
                >
                  {"Save as PDF"}
                </Button>
              )}
            </div>
          </div>
          {/* file detail*/}
          <div className="contact-detail d-flex mt-4">
            {/* left col */}
            <div
              className={`left-col ${
                !isEdit ? " w-50" : "w-50 pe-5"
              } text-start`}
            >
              <Typography variant="body1" gutterBottom>
                <ContactDetailField
                  label="File Ref"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"120px"}
                  value={formContent?.fileRef}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.fileRef = e.target.value;
                    setFormContent(values);
                  }}
                  disabled
                />
                <ContactDetailField
                  label="Customer"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"120px"}
                  value={formContent?.clientName}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.clientName = e.target.value;
                    setFormContent(values);
                  }}
                />
              </Typography>
            </div>
            {/* right col */}
            <div
              className={`right-col ${
                !isEdit ? "w-50" : "w-50 ps-5"
              }  d-flex flex-column align-items-end pe-2`}
            >
              <div
                className=""
                style={isEdit ? { width: "-webkit-fill-available" } : {}}
              >
                <Typography
                  variant="body1"
                  className="text-start w-100"
                  gutterBottom
                >
                  <ContactDetailField
                    label="Date"
                    isEdit={isEdit}
                    type="date"
                    wrapperStyle="justify-content-start"
                    minWidth={"150px"}
                    value={formContent?.date}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.date = e.target.value;
                      setFormContent(values);
                    }}
                  />
                  <ContactDetailField
                    label="Organization"
                    isEdit={isEdit}
                    type="text"
                    wrapperStyle="justify-content-start"
                    minWidth={"150px"}
                    value={formContent?.organization}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.organization = e.target.value;
                      setFormContent(values);
                    }}
                  />
                </Typography>
              </div>
            </div>
          </div>
          {/* /file detail */}
          {/*  Origin Address */}
          <div className="mt-5 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex justify-content-between">
                <div className="w-50">
                  <label htmlFor="packingDate" className="fw-bold">
                    Origin Address
                  </label>
                </div>
                {!isEdit && (
                  <span className="table-text w-50 text-end">
                    {formContent?.originAddress
                      ? formContent?.originAddress
                      : "-"}{" "}
                  </span>
                )}
                {isEdit && (
                  <Input
                    id="originAddress "
                    type="text"
                    label="Origin Address"
                    style="full-border-field"
                    value={formContent?.originAddress}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.originAddress = e.target.value;
                      setFormContent(values);
                    }}
                    inline={{ width: "-webkit-fill-available" }}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* / Origin Address */}
          {/*  Destination Address */}
          <div className="mt-5 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex justify-content-between">
                <div className="w-50">
                  <label htmlFor="packingDate" className="fw-bold">
                    Destination Address
                  </label>
                </div>
                {!isEdit && (
                  <span className="table-text w-50 text-end">
                    {formContent?.destinationAddress
                      ? formContent?.destinationAddress
                      : "-"}
                  </span>
                )}
                {isEdit && (
                  <Input
                    id="estinationAddress"
                    type="text"
                    label="Destination Address"
                    style="full-border-field"
                    value={formContent?.destinationAddress}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.destinationAddress = e.target.value;
                      setFormContent(values);
                    }}
                    inline={{ width: "-webkit-fill-available" }}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* /Destination Address */}
          {/*  Commodity */}
          <div className="mt-5 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex justify-content-between">
                <div className="w-50">
                  <label htmlFor="packingDate" className="fw-bold">
                    Commodity
                  </label>
                </div>
                {!isEdit && (
                  <span className="table-text w-50 text-end">
                    {formContent?.commodity ? formContent?.commodity : "-"}{" "}
                  </span>
                )}
                {isEdit && (
                  <Input
                    id="cmmodity"
                    type="text"
                    label="Commodity"
                    style="full-border-field"
                    value={formContent?.commodity}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.commodity = e.target.value;
                      setFormContent(values);
                    }}
                    inline={{ width: "-webkit-fill-available" }}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* /Commodity */}
          {/*  No of Packages */}
          <div className="mt-5 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex justify-content-between">
                <div className="w-50">
                  <label htmlFor="packingDate" className="fw-bold">
                    No. of Packages
                  </label>
                </div>
                {!isEdit && (
                  <span className="table-text w-50 text-end">
                    {formContent?.noOfPackages
                      ? formContent?.noOfPackages
                      : "-"}
                  </span>
                )}
                {isEdit && (
                  <Input
                    id="noOfPackages"
                    type="text"
                    label="No of Packages"
                    style="full-border-field"
                    value={formContent?.noOfPackages}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.noOfPackages = e.target.value;
                      setFormContent(values);
                    }}
                    inline={{ width: "-webkit-fill-available" }}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* /No of Packages */}
          {/*  Mode of Shipment */}
          <div className="mt-5 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex justify-content-between">
                <div className="w-50">
                  <label htmlFor="packingDate" className="fw-bold">
                    Mode of Shipment
                  </label>
                </div>
                {!isEdit && (
                  <span className="table-text w-50 text-end">
                    {formContent?.modeOfShippment
                      ? formContent?.modeOfShippment
                      : "-"}
                  </span>
                )}
                {isEdit && (
                  <Input
                    id="modeOfShipment"
                    type="text"
                    label="Mode of Shipment"
                    style="full-border-field"
                    value={formContent?.modeOfShippment}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.modeOfShippment = e.target.value;
                      setFormContent(values);
                    }}
                    inline={{ width: "-webkit-fill-available" }}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* /Mode of Shipment */}
          {/*  Weight/Volume */}
          <div className="mt-5 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex justify-content-between">
                <div className="w-50">
                  <label htmlFor="packingDate" className="fw-bold">
                    Weight/Volume
                  </label>
                </div>
                {!isEdit && (
                  <span className="table-text w-50 text-end">
                    {formContent?.weightVolume
                      ? formContent?.weightVolume
                      : "-"}{" "}
                  </span>
                )}
                {isEdit && (
                  <Input
                    id="WeightVolume"
                    type="text"
                    label="Weight/Volume"
                    style="full-border-field"
                    value={formContent?.weightVolume}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.weightVolume = e.target.value;
                      setFormContent(values);
                    }}
                    inline={{ width: "-webkit-fill-available" }}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* /Weight/Volume*/}
          {/*  Truck No */}
          <div className="mt-5 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex justify-content-between">
                <div className="w-50">
                  <label htmlFor="packingDate" className="fw-bold">
                    Truck No.
                  </label>
                </div>
                {!isEdit && (
                  <span className="table-text w-50 text-end">
                    {formContent?.truckNo ? formContent?.truckNo : "-"}
                  </span>
                )}
                {isEdit && (
                  <Input
                    id="truckNo"
                    type="text"
                    label="Truck No"
                    style="full-border-field"
                    value={formContent?.truckNo}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.truckNo = e.target.value;
                      setFormContent(values);
                    }}
                    inline={{ width: "-webkit-fill-available" }}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* /Truck No*/}
          {/*  Container */}
          <div className="mt-5 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex justify-content-between">
                <div className="w-50">
                  <label htmlFor="packingDate" className="fw-bold">
                    Container
                  </label>
                </div>
                {!isEdit && (
                  <span className="table-text w-50 text-end">
                    {formContent?.container ? formContent?.container : "-"}
                  </span>
                )}
                {isEdit && (
                  <Input
                    id="Container"
                    type="text"
                    label="Container"
                    style="full-border-field"
                    value={formContent?.container}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.container = e.target.value;
                      setFormContent(values);
                    }}
                    inline={{ width: "-webkit-fill-available" }}
                  />
                )}
              </div>
            </Typography>
          </div>
          {/* /Container*/}
          {/* Remarks */}
          <div className="mt-5 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <DynamicText
                label="Remarks:"
                textAreaLabel="Remarks..."
                isEdit={isEdit}
                value={formContent?.remarks}
                onChange={(e) => {
                  let values = { ...formContent };
                  values.remarks = e.target.value;
                  setFormContent(values);
                }}
              />
            </Typography>
          </div>
          {/* /Remakes */}

          {/* Signatures */}
          <Row className="mt-5 pt-5">
            <Col sm={6} className="mt-5 pt-5">
              <div
                style={{ height: "90px" }}
                className="d-flex justify-content-center align-items-end"
              >
                {formContent.clientSign != "" &&
                  formContent.clientSign != undefined && (
                    <img
                      src={`${Bucket}${formContent.clientSign}`}
                      height={80}
                      width={200}
                    />
                  )}
              </div>
              <p>{"_______________________________"}</p>
              <p className="my-1">
                <b>{"Customer/Authorized"}</b>
              </p>

              {""}
            </Col>
            <Col sm={6} className="mt-5 pt-5">
              <div
                style={{ height: "90px" }}
                className="d-flex justify-content-center align-items-end"
              >
                {formContent?.adminSign?.trim()?.length == 0 ? (
                  <>
                    {isEdit && (
                      <div className="d-flex justify-content-center align-items-end">
                        <label
                          htmlFor="uploadSign"
                          className="active-btn ms-2 w-200 d-flex align-items-center justify-content-center cursor-pointer btn"
                        >
                          {uploadText}
                        </label>
                        <input
                          type="button"
                          hidden
                          id="uploadSign"
                          name="uploadSign"
                          onClick={() => {
                            setSignCanvas(true);
                          }}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="d-flex align-items-start">
                    <img
                      src={`${Bucket}${formContent.adminSign}`}
                      height={80}
                      width={200}
                    />
                    {isEdit && (
                      <>
                        <label
                          htmlFor="editSign"
                          className=" ms-2  d-flex align-items-center justify-content-center cursor-pointer btn p-0"
                        >
                          <img
                            src={editIcn}
                            height={18}
                            width={18}
                            className="ms-2 cursor-pointer"
                          />
                        </label>
                        <input
                          type="button"
                          hidden
                          id="editSign"
                          name="uploadSign"
                          onClick={() => {
                            setSignCanvas(true);
                          }}
                        />
                        <img
                          src={trashIcn}
                          height={18}
                          width={18}
                          className="ms-2 cursor-pointer"
                          onClick={() => {
                            let keysToDelete = [...mediaKeysToRemove];
                            keysToDelete.push(formContent.adminSign);
                            setMediaKeysToRemove(keysToDelete);

                            let values = { ...formContent };
                            values.adminSign = "";
                            setFormContent(values);
                          }}
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
              <p>{"_______________________________"}</p>
              <p className="my-1">
                <b>{"Shelozon Movers Packers & Logistics Pvt Ltd."}</b>
              </p>

              {""}
            </Col>
          </Row>
        </div>
        <PrintComponent>
          <DeliveryReceiptDownload
            surveyData={surveyData}
            formContent={formContent}
          />
        </PrintComponent>

        {/* snackbar */}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000} // Adjust the duration as needed
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={
              snackbarMessage?.includes("successful") ? "success" : "error"
            }
            onClose={handleCloseSnackbar}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
        {/* /snackbar */}

        <LoadingModal open={open} handleClose={handleClose} />

        {/* Add signature Modal */}
        {isSignCanvas && (
          <MySignatureCanvas
            open={isSignCanvas}
            onClose={() => {
              setSignCanvas(false);
            }}
            onSuccess={(file) => {
              if (!isLoading) uploadFile(file);
            }}
          />
        )}

        {isVerified && (
          <SuperAdminVerifationModal
            open={isVerified}
            onClose={() => {
              setIsVerified(false);
            }}
            onSuccess={() => {
              setIsEdit(true);
              setIsVerified(false);
            }}
            onError={() => {
              // shareWithClient(emails);
              setSnackbarMessage("Invalid Confirm Pin");
              setOpenSnackbar(true);
            }}
          />
        )}
      </MainTemplate>
    </>
  );
};
export default DeliveryReceiptPage;
