import axiosInstance from "../../../../api/config/axiosConfig";

export const getPrebidPreview = (token, uuid, email) => {
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return axiosInstance.get(
    `/admin/prebid/prebidpreview?email=${email}&uuid=${uuid}`,
    {
      headers: headers,
    }
  );
};

export const UpdateClientSignaturePrebid = (token, uuid, email, body) => {
  let headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  return axiosInstance.put(
    `/admin/prebid/client-prebid?email=${email}&uuid=${uuid}`,
    body,
    {
      headers: headers,
    }
  );
};

export const imageUploadClientPreview = (token, uuid, email, body) => {
  let headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data",
  };

  return axiosInstance.post(
    `/admin/media/preview/upload_prebid_image?email=${email}&uuid=${uuid}`,
    body,
    {
      headers: headers,
    }
  );
};
