// Sidebar.js
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Hidden,
  IconButton,
} from "@mui/material";
import "./style.css";
import surveysWhite from "../../../imgs/surveys-white.png";
import surveysBlack from "../../../imgs/surveys-black.svg";
import quoteWhite from "../../../imgs/quote-white.png";
import quoteBlack from "../../../imgs/quote-black.png";
import logo from "./../../../../src/imgs/logo-final.png";
import MenuIcon from "@mui/icons-material/Menu";
import roleWhite from "../../../imgs/role-white.png";
import roleBlack from "../../../imgs/role-black.png";
import Drawer from "@mui/material/Drawer";

const Sidebar = () => {
  const location = useLocation();
  let temp = localStorage.getItem("isSideBarOpen");
  // console.log(temp)
  const [isSidebarOpen, setSidebarOpen] = useState(
    temp != null ? JSON?.parse(temp) : false
  );

  let url = window.location.pathname.split("/")[1];

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
    localStorage.setItem("isSideBarOpen", !isSidebarOpen);
  };

  return (
    <nav>
      <Hidden
        implementation="css"
        className="d-block d-md-block  togglr no-print"
      >
        <IconButton
          onClick={toggleSidebar}
          style={{ marginTop: "10px", zIndex: "99999", marginLeft: "-198px" }}
          className={`no-print ${
            isSidebarOpen ? "menu-open-icon-mg" : "menu-close-icon-mg"
          }`}
        >
          <MenuIcon height={28} width={28} />
        </IconButton>
        <img
          src={logo}
          height="60"
          width="215"
          style={{ margin: "-45px 0px 15px 60px" }}
          className={isSidebarOpen ? "d-none toggle-logo" : "toggle-logo"}
        />
      </Hidden>

      {/* <div
    className='side-bar'
      variant="permanent"
      sx={{
        width: 240,
        flexShrink: 0,
        height: '100vh',
        display: { xs: isSidebarOpen ? 'block' : 'none', md: 'block' },
      }}
    > */}

      <Drawer
        className="side-bar no-print"
        variant="permanent"
        sx={{
          // width: 240,
          flexShrink: 0,
          height: "100vh",
          // display: { xs: isSidebarOpen ? "block" : "none", lg: "block" },
          display: { xs: isSidebarOpen ? "block" : "none" },
        }}
      >
        <img
          src={logo}
          height="60"
          width="215"
          style={{ margin: "5px 0px 15px 16px" }}
          className="d-none d-lg-block"
        />
        <List className="side-bar-menu pt-5 pt-lg-0 mt-2 mt-lg-0">
          {/* Surveys */}
          <ListItem
            button
            component={Link}
            to="/"
            selected={
              location.pathname === "/" ||
              url === "survey" ||
              url === "survey-preview"
            }
          >
            <ListItemIcon className="me-0">
              <img
                src={
                  location.pathname === "/" ||
                  url === "survey" ||
                  url === "survey-preview"
                    ? surveysWhite
                    : surveysBlack
                }
              />
            </ListItemIcon>
            <ListItemText primary="Surveys" />
          </ListItem>

          {/* Request Survey */}
          <ListItem
            button
            component={Link}
            to="/survey-request"
            selected={location.pathname === "/survey-request"}
          >
            <ListItemIcon>
              <img
                src={
                  location.pathname === "/survey-request"
                    ? quoteWhite
                    : quoteBlack
                }
                height={22}
                width={23}
              />
            </ListItemIcon>
            <ListItemText primary="Request a Survey" />
          </ListItem>

          {/* Quotations */}
          {/* <ListItem
            button
            component={Link}
            to="/quotations"
            selected={location.pathname === "/quotations"}
          >
            <ListItemIcon>
              <img
                src={
                  location.pathname === "/quotations" ? quoteWhite : quoteBlack
                }
                height={22}
                width={23}
              />
            </ListItemIcon>
            <ListItemText primary="Quotations" />
          </ListItem> */}

          {/* Role Management */}
          <ListItem
            button
            component={Link}
            to="/role-management"
            selected={location.pathname === "/role-management"}
          >
            <ListItemIcon className="me-0">
              <img
                src={
                  location.pathname === "/role-management"
                    ? roleWhite
                    : roleBlack
                }
                height={23}
                width={24}
              />
            </ListItemIcon>
            <ListItemText primary="Role Management" />
          </ListItem>

          {/* Prebid Management */}
          <ListItem
            button
            component={Link}
            to="/prebid-agreement-list"
            selected={location.pathname === "/prebid-agreement-list"}
          >
            <ListItemIcon className="me-0">
              <img
                src={
                  location.pathname === "/prebid-agreement-list"
                    ? roleWhite
                    : roleBlack
                }
                height={23}
                width={24}
              />
            </ListItemIcon>
            <ListItemText primary="Prebid Agreements" />
          </ListItem>
        </List>
      </Drawer>
      {/* </div> */}
    </nav>
  );
};

export default Sidebar;
