import React, { useEffect, useState } from "react";
import {
  updateFormContentPreview,
  getFormContentPreview,
  imageUploadClientPreview,
} from "../../../../api/services/services";
import { Button, Snackbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import Input from "../../../atoms/Input";
import ContactDetailField from "../../../molecules/ContactDetailField";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useParams } from "react-router-dom";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import html2pdf from "html2pdf.js";
import { useLocation } from "react-router-dom";
import PrintComponent from "../../PrintComponent";
import ShippingInstructionsDownload from "./download";
import TextArea from "../../../atoms/Textarea";
import moment from "moment";

const ShippingInstructionsClientSide = () => {
  const [surveyData, setSurveyData] = useState(null);
  const [formContent, setFormContent] = useState({});

  const [formContentId, setFormContentId] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const urlData = new URLSearchParams(location.search);
  const email = urlData.get("email");
  const surveyId = urlData.get("surveyId");
  const formId = urlData.get("formId");
  const token = urlData.get("token");

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const [isValid, setIsValid] = useState(false);

  //
  const getContent = () => {
    setIsLoading(true);
    getFormContentPreview(surveyId, formId, email, token)
      .then((res) => {
        setIsValid(true);
        setFormContentId(res.data.data.uuid);
        setFormContent(res.data.data.content);

        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          setIsValid(false);
        }

        setIsLoading(false);
      });
  };

  useEffect(() => {
    getContent();
  }, []);

  const [show, setShow] = useState(false);
  const options = {
    // resolution: Resolution.HIGH,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      format: "letter",
      // default is 'portrait'
      orientation: "portrait",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/png",
      qualityRatio: 1,
    },
    overrides: {
      pdf: {
        compress: true,
      },
    },
  };

  //
  const generatePdf = () => {
    setShow(true);

    setTimeout(() => {
      const content = document.getElementById("content-to-convert"); // Replace with the ID of the element you want to convert to PDF

      const currentDateTime = new Date();
      const filename = `survey_${currentDateTime.toISOString()}.pdf`;

      const pdfOptions = {
        margin: 5,
        filename: filename,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
        }, // Set the page size here
      };

      let temp = html2pdf().from(content).set(pdfOptions).outputPdf();
      temp.save(filename);

      setShow(false);
    }, 3500);
  };

  const [downloadText, setDownloadText] = useState("download");

  const getTargetElement = () => document.getElementById("content-id");

  const printAction = () => {
    const PAGE_HEIGHT = 1045;
    const printElement = document.getElementById("print-component");
    if (printElement) {
      printElement.classList.add("temp-class-for-height");
      const height = printElement.clientHeight;
      const numberOfPage = Math.ceil(height / PAGE_HEIGHT);
      const heightWithSingleHeader = numberOfPage * PAGE_HEIGHT;
      let requiredHeight = heightWithSingleHeader;
      if (numberOfPage > 1) {
        const headerHeight =
          printElement.getElementsByTagName("thead")?.[0]?.clientHeight;
        const footerHeight =
          printElement.getElementsByTagName("tfoot")?.[0]?.clientHeight;
        requiredHeight -= (numberOfPage - 1) * (headerHeight + footerHeight);
      }
      printElement.style.height = `${requiredHeight}px`;
      printElement.classList.remove("temp-class-for-height");
    }
    window.print();
    if (printElement) {
      printElement.style.height = `auto`;
    }
  };

  return (
    <>
      {isValid ? (
        <div className="mx-3 mx-md-5 no-print" style={{ minHeight: "100vh" }}>
          {/* <MainTemplate> */}

          <div className="d-flex align-items-center py-4">
            <p className="main-head ps-0 mb-0 w-50 text-end">
              Shipping Instructions
            </p>
            <div className="d-flex ms-auto">
              {!isEdit && (
                <Button
                  className="active-btn ms-3 px-0"
                  onClick={() => {
                    setIsLoading(true);
                    // printAction();
                    var tempTitle = document.title;
                    document.title = `Shipping Instructions-${
                      formContent?.customerName
                    }-${moment(new Date()).format("DD.MM.YYYY")}`;

                    window.print();
                    document.title = tempTitle;
                    setIsLoading(false);
                  }}
                  disabled={isLoading}
                >
                  {"Save as PDF"}
                </Button>
              )}
            </div>
          </div>

          {/* <div>
            <div className="d-block d-sm-flex align-items-center py-4">
              <div className="d-flex align-items-center ">
                <p className="main-head mb-0 ps-1">Shipping Instructions</p>
              </div>
              <div className="d-flex ms-auto justify-content-center mt-2 mt-sm-0">
                {/* Download Button */}
          {/* {!isEdit && (
                  <Button
                    className="active-btn ms-3 px-0"
                    onClick={() => {
                      setIsLoading(true);
                      // printAction();
                      window.print();
                      setIsLoading(false);
                    }}
                    disabled={isLoading}
                  >
                    Save as PDF
                  </Button>
                )}
              </div>
            </div>
          </div> */}

          {/*  Details */}
          <div className="mt-5 pe-1 mb-4">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div>
                <Typography variant="body1" gutterBottom className="w-50 ps-1">
                  <ContactDetailField
                    label="Mode of Shipment"
                    isEdit={isEdit}
                    type="text"
                    minWidth={"150px"}
                    value={formContent?.airShipment}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.airShipment = e.target.value;
                      setFormContent(values);
                    }}
                  />
                  <ContactDetailField
                    label="Date"
                    isEdit={isEdit}
                    type="date"
                    minWidth={"150px"}
                    value={formContent?.date}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.date = e.target.value;
                      setFormContent(values);
                    }}
                  />
                </Typography>
              </div>
            </Typography>
          </div>
          {/* / Details */}
          {/* table */}
          <TableContainer component={Paper} className="mb-5">
            <Table>
              <TableBody>
                {/* Shipper */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Shipper</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"shipper"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Shipper"}
                        value={formContent?.shipper}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.shipper = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>{formContent?.shipper ? formContent?.shipper : "-"}</>
                    )}
                  </TableCell>
                </TableRow>
                {/* Consignee */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Consignee</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"consignee"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Consignee"}
                        value={formContent?.consignee}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.consignee = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.consignee ? formContent?.consignee : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>

                {/* Notify */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Notify</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"notify"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Notify"}
                        value={formContent?.notify}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.notify = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>{formContent?.notify ? formContent?.notify : "-"}</>
                    )}
                  </TableCell>
                </TableRow>
                {/* Description */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Description</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"description"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Description"}
                        value={formContent?.description}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.description = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.description
                          ? formContent?.description
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>
                {/* Volume/Gross Weight */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Volume/Gross Weight</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"gross-weight"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Gross Weight"}
                        value={formContent?.grossWeight}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.grossWeight = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.grossWeight
                          ? formContent?.grossWeight
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>
                {/* Port of Discharge */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Port of Discharge</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"port-of-discharge"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Port of Discharge"}
                        value={formContent?.portOfDischarge}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.portOfDischarge = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.portOfDischarge
                          ? formContent?.portOfDischarge
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>

                {/* Destination Country */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Destination Country</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <Input
                        id={"country"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Destination Country"}
                        value={formContent?.destinationCountry}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.destinationCountry = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>
                        {formContent?.destinationCountry
                          ? formContent?.destinationCountry
                          : "-"}
                      </>
                    )}
                  </TableCell>
                </TableRow>

                {/* Remarks */}
                <TableRow>
                  <TableCell className="border p-3 w-25">
                    <b>Remarks</b>
                  </TableCell>
                  <TableCell className="border p-3 w-75 text-start">
                    {isEdit ? (
                      <TextArea
                        id={"remarks"}
                        type="text"
                        style="full-border-field bg-app"
                        label={"Remarks"}
                        value={formContent?.remarks}
                        onChange={(e) => {
                          let values = { ...formContent };
                          values.remarks = e.target.value;
                          setFormContent(values);
                        }}
                        inline={{ width: "-webkit-fill-available" }}
                      />
                    ) : (
                      <>{formContent?.remarks ? formContent?.remarks : "-"}</>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {/* /table */}

          {/* </MainTemplate> */}
        </div>
      ) : (
        <>
          {isLoading ? (
            <div> Loading...</div>
          ) : (
            <div> Ooops... Something went wrong...</div>
          )}
        </>
      )}

      <PrintComponent>
        <ShippingInstructionsDownload
          surveyData={surveyData}
          formContent={formContent}
        />
      </PrintComponent>

      {/* snackbar */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={
            snackbarMessage?.includes("successful") ? "success" : "error"
          }
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      {/* /snackbar */}
    </>
  );
};
export default ShippingInstructionsClientSide;
