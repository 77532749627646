// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-div{
    height: 50px !important;
    width: 50px !important;
    border: 1px solid #CECECE;
    background-color: #F4F5FA;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
}

.blue-container-div{
    height: 50px !important;
    width: 50px !important;
    border: 1px solid #CECECE;
    background-color: #00AFF0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff !important;
    font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/LoadUnloadReport/style.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,sBAAsB;IACtB,yBAAyB;IACzB,yBAAyB;IACzB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;IACvB,sBAAsB;IACtB,yBAAyB;IACzB,yBAAyB;IACzB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,gBAAgB;AACpB","sourcesContent":[".container-div{\n    height: 50px !important;\n    width: 50px !important;\n    border: 1px solid #CECECE;\n    background-color: #F4F5FA;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-weight: 500;\n}\n\n.blue-container-div{\n    height: 50px !important;\n    width: 50px !important;\n    border: 1px solid #CECECE;\n    background-color: #00AFF0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: #fff !important;\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
