import React, { useEffect, useState } from "react";
import backArrow from "../../../imgs/BackArrow.svg";
import MainTemplate from "../../templates/MainTemplate";
import { useNavigate } from "react-router-dom";
import { Button, Snackbar } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import InfoInput from "../../atoms/InfoInput";
import { updateSurvey } from "../../../api/services/services";
import MuiAlert from "@mui/material/Alert";
import { getSingleSurvey } from "../../../api/services/services";

const CompleteSurvey = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const [formData, setFormData] = useState({
    loadPlacePhone: "",
    loadPlaceStAddress: "",
    loadPlaceZip: "",
    loadPlaceCountry: "",
    loadPlaceCity: "",
    loadDate: null,
    unloadPlacePhone: "",
    unloadPlaceStAddress: "",
    unloadPlaceZip: "",
    unloadPlaceCountry: "",
    unloadPlaceCity: "",
  });

  const [unixDate, setUnixDate] = useState(null);
  const [unixDateSurvey, setUnixDateSurvey] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    setIsLoading(true);
    let data = formData;
    data.loadDate = unixDate;
    data.surveyDate = unixDateSurvey;
    data.loadPlaceZip = parseInt(formData.loadPlaceZip);
    data.unloadPlaceZip = parseInt(formData.unloadPlaceZip);
    updateSurvey(data, id)
      .then(() => {
        setSnackbarMessage("Survey details has been updated successfully!");
        setOpenSnackbar(true);
        setTimeout(() => {
          navigate(-1);
        }, 1500);
      })
      .catch((error) => {
        setIsLoading(false);

        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        } else if (error?.response?.data?.statusCode == 1002) {
          // setSnackbarMessage("Token has been expired");
          // setOpenSnackbar(true);
        } else {
          setSnackbarMessage(error?.response?.data?.message);
          setOpenSnackbar(true);
        }
      });
  };

  const disableSubmit =
    formData?.loadPlacePhone?.trim() == "" ||
    formData?.loadPlaceStAddress?.trim() == "" ||
    formData?.loadPlaceCountry?.trim() == "" ||
    // (formData?.loadPlaceZip)?.trim() == "" ||
    formData?.loadPlaceCity?.trim() == "" ||
    formData?.loadDate == null ||
    formData?.surveyDate == null ||
    formData?.unloadPlaceStAddress?.trim() == "" ||
    formData?.unloadPlaceCountry?.trim() == "" ||
    formData?.unloadPlaceCity?.trim() == "";
  //  ||
  // (formData?.unloadPlaceZip)?.trim() == "";


  // fetch single survey details
  const getSurvey = () => {
    // console.log(id);
    getSingleSurvey(id)
      .then((res) => {
       let values = {...formData};
       values.loadPlacePhone = res?.data?.data?.phone
       setFormData(values)
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode == 401) {
          localStorage.clear();
          window.location.pathname = "/login";
        }
        console.error("Error fetching survey:", error?.response?.data);
      });
  }


  useEffect(() => {
    getSurvey();
  }, []);

  return (
    <>
      <MainTemplate>
        <div className="d-block d-sm-flex align-items-center py-4">
          <div className="d-flex align-items-center w-100">
            <div
              className="light-blue-color back-bg cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <img src={backArrow} height="24" width="24" />{" "}
            </div>
            <div>
              <p className="main-head ps-3 mb-0">Survey Detail</p>
            </div>
            <div className="d-flex ms-auto">
              <Button
                className="active-btn "
                disabled={disableSubmit || isLoading}
                onClick={() => handleSubmit()}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <Row className="mx-1 mt-4">
            <Col sm={12} md={12} className="text-start px-0 fn">
              <b>Origin</b>
            </Col>
          </Row>

          <Grid
            container
            className="bg-white my-4 mx-0  p-3 py-4"
            style={{ borderRadius: "5px" }}
          >
            <Grid item xs={12} sm={6} className="mb-4 pe-4">
              <InfoInput
                type="text"
                value={formData.loadPlaceStAddress}
                placeholder="Enter Street, Address..."
                onChange={(e) => {
                  const values = { ...formData };
                  values.loadPlaceStAddress = e.target.value;
                  setFormData(values);
                }}
                // error={doorToDoorError}
                name={"Address"}
                inline={{ width: "-webkit-fill-available" }}
                required={true}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="mb-4 pe-4">
              <InfoInput
                type="text"
                value={formData.loadPlaceCity}
                placeholder="Enter City..."
                onChange={(e) => {
                  const values = { ...formData };
                  values.loadPlaceCity = e.target.value;
                  setFormData(values);
                }}
                // error={doorToDoorError}
                name={"City"}
                inline={{ width: "-webkit-fill-available" }}
                required={true}
              />
            </Grid>

            <Grid item xs={12} sm={6} className="mb-4 pe-4">
              <InfoInput
                type="text"
                value={formData.loadPlaceCountry}
                placeholder="Enter Country..."
                onChange={(e) => {
                  const values = { ...formData };
                  values.loadPlaceCountry = e.target.value;
                  setFormData(values);
                }}
                // error={doorToDoorError}
                name={"Country"}
                inline={{ width: "-webkit-fill-available" }}
                required={true}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="mb-4 pe-4">
              <InfoInput
                type="number"
                value={formData.loadPlaceZip}
                placeholder="Enter Zip Code..."
                onChange={(e) => {
                  const values = { ...formData };
                  values.loadPlaceZip = e.target.value;
                  setFormData(values);
                }}
                // error={doorToDoorError}
                name={"Zip Code"}
                inline={{ width: "-webkit-fill-available" }}
                required={false}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="mb-4 pe-4">
              <InfoInput
                type="text"
                value={formData.loadPlacePhone}
                placeholder="Enter Phone Number..."
                onChange={(e) => {
                  const values = { ...formData };
                  values.loadPlacePhone = e.target.value;
                  setFormData(values);
                }}
                // error={doorToDoorError}
                name={"Phone Number"}
                inline={{ width: "-webkit-fill-available" }}
                required={true}
              />
            </Grid>

            <Grid item xs={12} sm={6} className="mb-4 pe-4">
              <InfoInput
                type="date"
                value={formData.loadDate}
                placeholder="Select Date..."
                onChange={(e) => {
                  let temp = parseInt(
                    (new Date(e.target.value).getTime() / 1000).toFixed(0)
                  );
                  setUnixDate(temp);

                  const values = { ...formData };
                  values.loadDate = e.target.value;
                  setFormData(values);
                }}
                // error={doorToDoorError}
                name={"Removal Date"}
                inline={{ width: "-webkit-fill-available" }}
                data-date-format="DD-MM-YYYY"
                required={true}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="mb-4 pe-4">
              <InfoInput
                type="date"
                value={formData.surveyDate}
                placeholder="Select Date..."
                onChange={(e) => {
                  let temp = parseInt(
                    (new Date(e.target.value).getTime() / 1000).toFixed(0)
                  );
                  setUnixDateSurvey(temp);

                  const values = { ...formData };
                  values.surveyDate = e.target.value;
                  setFormData(values);
                }}
                // error={doorToDoorError}
                name={"Survey Date"}
                inline={{ width: "-webkit-fill-available" }}
                data-date-format="DD-MM-YYYY"
                required={true}
              />
            </Grid>
          </Grid>

          <Row className="mx-1 mt-4">
            <Col sm={12} md={12} className="text-start px-0 fn">
              <b>Destination</b>
            </Col>
          </Row>

          <Grid
            container
            className="bg-white my-4 mx-0  p-3 py-4"
            style={{ borderRadius: "5px" }}
          >
            <Grid item xs={12} sm={6} className="mb-4 pe-4">
              <InfoInput
                type="text"
                value={formData.unloadPlaceStAddress}
                placeholder="Enter Street, Address..."
                onChange={(e) => {
                  const values = { ...formData };
                  values.unloadPlaceStAddress = e.target.value;
                  setFormData(values);
                }}
                // error={doorToDoorError}
                name={"Address"}
                inline={{ width: "-webkit-fill-available" }}
                required={true}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="mb-4 pe-4">
              <InfoInput
                type="text"
                value={formData.unloadPlaceCity}
                placeholder="Enter City..."
                onChange={(e) => {
                  const values = { ...formData };
                  values.unloadPlaceCity = e.target.value;
                  setFormData(values);
                }}
                // error={doorToDoorError}
                name={"City"}
                inline={{ width: "-webkit-fill-available" }}
                required={true}
              />
            </Grid>

            <Grid item xs={12} sm={6} className="mb-4 pe-4">
              <InfoInput
                type="text"
                value={formData.unloadPlaceCountry}
                placeholder="Enter Country..."
                onChange={(e) => {
                  const values = { ...formData };
                  values.unloadPlaceCountry = e.target.value;
                  setFormData(values);
                }}
                // error={doorToDoorError}
                name={"Country"}
                inline={{ width: "-webkit-fill-available" }}
                required={true}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="mb-4 pe-4">
              <InfoInput
                type="number"
                value={formData.unloadPlaceZip}
                placeholder="Enter Zip Code..."
                onChange={(e) => {
                  const values = { ...formData };
                  values.unloadPlaceZip = e.target.value;
                  setFormData(values);
                }}
                // error={doorToDoorError}
                name={"Zip Code"}
                inline={{ width: "-webkit-fill-available" }}
                required={false}
              />
            </Grid>

            <Grid item xs={12} sm={6} className="mb-4 pe-4">
              <InfoInput
                type="number"
                value={formData.unloadPlacePhone}
                placeholder="Enter Phone No..."
                onChange={(e) => {
                  const values = { ...formData };
                  values.unloadPlacePhone = e.target.value;
                  setFormData(values);
                }}
                // error={doorToDoorError}
                name={"Phone Number"}
                inline={{ width: "-webkit-fill-available" }}
                required={true}
              />
            </Grid>
          </Grid>
        </form>
      </MainTemplate>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={
            snackbarMessage?.includes("successful") ? "success" : "error"
          }
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
};
export default CompleteSurvey;
