import axiosInstance from "../../../../api/config/axiosConfig";

let token = localStorage.getItem("shelLoginToken");

export const postUpdateAdminPrebid = (uuid, body) => {
  let headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  if (uuid == "uuid") {
    return axiosInstance.put(`/admin/prebid`, body, {
      headers: headers,
      // params: { uuid },
    });
  }
  return axiosInstance.put(`/admin/prebid`, body, {
    headers: headers,
    params: { uuid },
  });
};

export const getPrebidByUuid = (uuid) => {
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return axiosInstance.get(`/admin/prebid/${uuid}`, {
    headers: headers,
  });
};

export const sharePreviewLink = (body) => {
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return axiosInstance.post(`/admin/prebid/sendpreviewlink`, body, {
    headers: headers,
  });
};

export const sendSuperAdminVerificationForEdit = () => {
  let headers = {};

  return axiosInstance.post(`/auth/send_otp_to_super_admin`, {
    headers: headers,
  });
};

export const confirmSuperAdminVerificationForEdit = (code) => {
  let headers = {};

  console.log(code);
  return axiosInstance.post(
    `/auth/confirm_otp_to_super_admin`,
    {
      code: code,
    },
    {
      headers: headers,
    }
  );
};
