import "./App.css";
import React from "react";
import { useState, useEffect } from "react";

import SplashScreen from "./components/pages/SplashSreenPage";
import PrivateRoute from "./routing/PrivateRoute";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedLoginTime = localStorage.getItem("loginTime");
    if (storedLoginTime) {
      let temp = new Date(storedLoginTime);
      if (temp) {
        const currentTime = new Date();
        const timePassed = currentTime - temp;
        const hoursPassed = timePassed / (1000 * 60 * 60);
        const minutesPassed = (timePassed / (1000 * 60)) % 60;

        console.log(
          `${hoursPassed} hours and ${minutesPassed} mins has been passed`
        );

        if (hoursPassed >= 24) {
          localStorage.clear(); 
        } 
          setTimeout(() => {
            setLoading(false); // Set loading to false when your app is ready
          }, 1000); // Adjust the time as needed
        
      }
    } else {
      localStorage.removeItem('shelUserData'); 
      setTimeout(() => {
        setLoading(false); // Set loading to false when your app is ready
      }, 1500); // Adjust the time as needed
    }
  }, []);

  return (
    <div className="App bg-app">
      {loading ? <SplashScreen /> : <PrivateRoute />}
    </div>
  );
}

export default App;
