import React, { useEffect, useState } from "react";
import { Modal, Backdrop, Fade } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";

const LoadingModal = ({ open, onClose }) => {
  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="modal-container">
            {" "}
            {/* CSS class for centering */}
            <div className="modal-content assign-modal px-4">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "10px 0px",
                }}
              >
                <CircularProgress
                  style={{
                    color: "#00aff0",
                    height: "25px",
                    width: "25px",
                  }}
                />
              </Box>

              <p className="modal-subhead mb-0 text-dark">Uploading...</p>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default LoadingModal;
