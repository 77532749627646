import React, { useEffect, useState } from "react";
import {
  updateFormContentPreview,
  getFormContentPreview,
  imageUploadClientPreview,
} from "../../../../api/services/services";
import { Button, Snackbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import { Bucket } from "../../../../api/config/apiConfig";
import ContactDetailField from "../../../molecules/ContactDetailField";
import Input from "../../../atoms/Input";
import DynamicText from "../../../molecules/DynamicText";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import html2pdf from "html2pdf.js";
import { useLocation } from "react-router-dom";
import PrintComponent from "../../PrintComponent";
import DeliveryReceiptDownload from "./download";
import moment from "moment";
import MySignatureCanvas from "../../../molecules/signature_canvas";

const DeliveryReceiptClientSide = () => {
  const [formContent, setFormContent] = useState({});
  const [formContentId, setFormContentId] = useState("");
  const [surveyData, setSurveyData] = useState([]);

  const [isEdit, setIsEdit] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const urlData = new URLSearchParams(location.search);
  const email = urlData.get("email");
  const surveyId = urlData.get("surveyId");
  const formId = urlData.get("formId");
  const token = urlData.get("token");

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  //
  const updateContent = (data, mdKeys) => {
    setIsLoading(true);

    let media = [];
    if (formContent?.clientSign != "") media[0] = formContent?.clientSign;

    const body = {
      formContentId: formContentId,
      content: data,
      mediaKeys: mdKeys,
      removedMediaKeys: [],
    };
    updateFormContentPreview(
      surveyId,
      formId,
      email,
      token,
      body,
      formContentId
    )
      .then((res) => {
        setSnackbarMessage("Signature has been added successfully!");
        setOpenSnackbar(true);
        getContent();
        setIsEdit(false);

        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          setIsValid(false);
        }

        setIsLoading(false);
      });
  };

  const [isValid, setIsValid] = useState(false);

  //
  const getContent = () => {
    setIsLoading(true);
    getFormContentPreview(surveyId, formId, email, token)
      .then((res) => {
        setIsValid(true);
        setFormContentId(res.data.data.uuid);
        setFormContent(res.data.data.content);

        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);

        if (error?.response?.data?.statusCode == 401) {
          setIsValid(false);
        }

        setIsLoading(false);
      });
  };

  useEffect(() => {
    getContent();
  }, []);

  const [show, setShow] = useState(false);
  const options = {
    // resolution: Resolution.HIGH,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      format: "letter",
      // default is 'portrait'
      orientation: "portrait",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/png",
      qualityRatio: 1,
    },
    overrides: {
      pdf: {
        compress: true,
      },
    },
  };

  //
  const generatePdf = () => {
    setShow(true);

    setTimeout(() => {
      const content = document.getElementById("content-to-convert"); // Replace with the ID of the element you want to convert to PDF

      const currentDateTime = new Date();
      const filename = `survey_${currentDateTime.toISOString()}.pdf`;

      const pdfOptions = {
        margin: 5,
        filename: filename,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
        }, // Set the page size here
      };

      let temp = html2pdf().from(content).set(pdfOptions).outputPdf();
      temp.save(filename);

      setShow(false);
    }, 3500);
  };

  const [downloadText, setDownloadText] = useState("download");

  const getTargetElement = () => document.getElementById("content-id");

  const [isSignCanvas, setSignCanvas] = useState(false);
  //
  //
  const uploadFile = async (file) => {

      const fd = new FormData();
      fd.append("image", file);

      imageUploadClientPreview(fd, surveyId, formId, email, token)
        .then((res) => {
          let values = { ...formContent };
          values.clientSign = res?.data?.data?.key;
          setFormContent(values);

          setTimeout(() => {
            updateContent(values, [res?.data?.data?.key]);
          }, 1500);
          setIsLoading(false);
        })
        .catch((error) => {
          setSnackbarMessage(error?.response?.data?.message);
          setOpenSnackbar(true);

          if (error?.response?.data?.statusCode == 401) {
            setIsValid(false);
          }

          setIsLoading(false);
        });
    
  };

  const printAction = () => {
    const PAGE_HEIGHT = 1045;
    const printElement = document.getElementById("print-component");
    if (printElement) {
      printElement.classList.add("temp-class-for-height");
      const height = printElement.clientHeight;
      const numberOfPage = Math.ceil(height / PAGE_HEIGHT);
      const heightWithSingleHeader = numberOfPage * PAGE_HEIGHT;
      let requiredHeight = heightWithSingleHeader;
      if (numberOfPage > 1) {
        const headerHeight =
          printElement.getElementsByTagName("thead")?.[0]?.clientHeight;
        const footerHeight =
          printElement.getElementsByTagName("tfoot")?.[0]?.clientHeight;
        requiredHeight -= (numberOfPage - 1) * (headerHeight + footerHeight);
      }
      printElement.style.height = `${requiredHeight}px`;
      printElement.classList.remove("temp-class-for-height");
    }
    window.print();
    if (printElement) {
      printElement.style.height = `auto`;
    }
  };

  return (
    <>
      {isValid ? (
        <div className="mx-3 mx-md-5 no-print" style={{ minHeight: "100vh" }}>
          {/* <MainTemplate> */}

          <div className="d-flex align-items-center py-4">
            <p className="main-head ps-0 mb-0 w-50 text-end">
              Delivery Receipt
            </p>
            <div className="d-flex ms-auto justify-content-center mt-2 mt-sm-0">
              {/* Upload Sign */}
              {formContent?.clientSign == "" && (
                <>
                  <label
                    htmlFor="uploadSign"
                    className="active-btn ms-2 w-150 d-flex align-items-center justify-content-center cursor-pointer btn"
                  >
                    Upload Sign
                  </label>
                  <input
                    type="button"
                    hidden
                    id="uploadSign"
                    name="uploadSign"
                    onClick={() => {
                      setSignCanvas(true);
                    }}
                  />
                </>
              )}

              {/* Download Button */}
              {!isEdit && (
                <Button
                  className="active-btn ms-3 px-0"
                  onClick={() => {
                    setIsLoading(true);
                    // printAction();
                    var tempTitle = document.title;
                    document.title = `Delivery Receipt-${
                      formContent?.clientName
                    }-${moment(new Date()).format("DD.MM.YYYY")}`;

                    window.print();
                    document.title = tempTitle;
                    setIsLoading(false);
                  }}
                  disabled={isLoading}
                >
                  {"Save as PDF"}
                </Button>
              )}
            </div>
          </div>



          {/* <div className="d-block d-sm-flex align-items-center py-4">
            <div className="d-flex align-items-center ">
              <p className="main-head mb-0">Delivery Receipt</p>
            </div>
            <div className="d-flex ms-auto justify-content-center mt-2 mt-sm-0">
              {/* Upload Sign */}
              {/* {formContent?.clientSign == "" && (
                <>
                  <label
                    htmlFor="uploadSign"
                    className="active-btn ms-2 w-150 d-flex align-items-center justify-content-center cursor-pointer btn"
                  >
                    Upload Sign
                  </label>
                  <input
                    type="file"
                    hidden
                    id="uploadSign"
                    name="uploadSign"
                    onChange={(e) => {
                      if (!isLoading) uploadFile(e);
                    }}
                  />
                </>
              )} */}

              {/* Download Button */}
              {/* {!isEdit && (
                <Button
                  className="active-btn ms-3 px-0"
                  onClick={() => {
                    setIsLoading(true);
                    // printAction();
                    var tempTitle = document.title;
                    document.title = `Delivery Receipt-${
                      formContent?.clientName
                    }-${moment(new Date()).format("DD.MM.YYYY")}`;

                    window.print();
                    document.title = tempTitle;
                    setIsLoading(false);
                  }}
                  disabled={isLoading}
                >
                  {"Save as PDF"}
                </Button>
              )}
            </div>
          </div> */} 


          {/* file detail*/}
          <div className="contact-detail d-flex mt-4">
            {/* left col */}
            <div
              className={`left-col ${
                !isEdit ? " w-75" : "w-50 pe-5"
              } text-start`}
            >
              <Typography variant="body1" gutterBottom>
                <ContactDetailField
                  label="File Ref"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"120px"}
                  value={formContent?.fileRef}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.fileRef = e.target.value;
                    setFormContent(values);
                  }}
                  disabled
                />
                <ContactDetailField
                  label="Customer"
                  isEdit={isEdit}
                  type="text"
                  minWidth={"120px"}
                  value={formContent?.clientName}
                  onChange={(e) => {
                    let values = { ...formContent };
                    values.clientName = e.target.value;
                    setFormContent(values);
                  }}
                />
              </Typography>
            </div>
            {/* right col */}
            <div
              className={`right-col ${
                !isEdit ? "w-25" : "w-50 ps-5"
              }  d-flex flex-column align-items-end pe-2`}
            >
              <div className="">
                <Typography
                  variant="body1"
                  className="text-start w-100"
                  gutterBottom
                >
                  <ContactDetailField
                    label="Date"
                    isEdit={isEdit}
                    type="date"
                    wrapperStyle="justify-content-start"
                    minWidth={"150px"}
                    value={formContent?.date}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.date = e.target.value;
                      setFormContent(values);
                    }}
                  />
                  <ContactDetailField
                    label="Organization"
                    isEdit={isEdit}
                    type="text"
                    wrapperStyle="justify-content-start"
                    minWidth={"150px"}
                    value={formContent?.organization}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.organization = e.target.value;
                      setFormContent(values);
                    }}
                  />
                </Typography>
              </div>
            </div>
          </div>
          {/* /file detail */}
          {/*  Origin Address */}
          <div className="mt-5 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex justify-content-between">
                <div className="w-50">
                  <label htmlFor="packingDate" className="fw-bold">
                    Origin Address
                  </label>
                </div>
                {!isEdit && (
                  <span className="table-text w-50 text-end">
                    {formContent?.originAddress
                      ? formContent?.originAddress
                      : "-"}{" "}
                  </span>
                )}
                {isEdit && (
                  <Input
                    id="originAddress "
                    type="text"
                    label="Origin Address"
                    style="full-border-field"
                    value={formContent?.originAddress}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.originAddress = e.target.value;
                      setFormContent(values);
                    }}
                    inline={{ width: "-webkit-fill-available" }}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* / Origin Address */}
          {/*  Destination Address */}
          <div className="mt-5 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex justify-content-between">
                <div className="w-50">
                  <label htmlFor="packingDate" className="fw-bold">
                    Destination Address
                  </label>
                </div>
                {!isEdit && (
                  <span className="table-text w-50 text-end">
                    {formContent?.destinationAddress
                      ? formContent?.destinationAddress
                      : "-"}
                  </span>
                )}
                {isEdit && (
                  <Input
                    id="estinationAddress"
                    type="text"
                    label="Destination Address"
                    style="full-border-field"
                    value={formContent?.destinationAddress}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.destinationAddress = e.target.value;
                      setFormContent(values);
                    }}
                    inline={{ width: "-webkit-fill-available" }}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* /Destination Address */}
          {/*  Commodity */}
          <div className="mt-5 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex justify-content-between">
                <div className="w-50">
                  <label htmlFor="packingDate" className="fw-bold">
                    Commodity
                  </label>
                </div>
                {!isEdit && (
                  <span className="table-text w-50 text-end">
                    {formContent?.commodity ? formContent?.commodity : "-"}{" "}
                  </span>
                )}
                {isEdit && (
                  <Input
                    id="cmmodity"
                    type="text"
                    label="Commodity"
                    style="full-border-field"
                    value={formContent?.commodity}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.commodity = e.target.value;
                      setFormContent(values);
                    }}
                    inline={{ width: "-webkit-fill-available" }}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* /Commodity */}
          {/*  No of Packages */}
          <div className="mt-5 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex justify-content-between">
                <div className="w-50">
                  <label htmlFor="packingDate" className="fw-bold">
                    No. of Packages
                  </label>
                </div>
                {!isEdit && (
                  <span className="table-text w-50 text-end">
                    {formContent?.noOfPackages
                      ? formContent?.noOfPackages
                      : "-"}
                  </span>
                )}
                {isEdit && (
                  <Input
                    id="noOfPackages"
                    type="text"
                    label="No of Packages"
                    style="full-border-field"
                    value={formContent?.noOfPackages}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.noOfPackages = e.target.value;
                      setFormContent(values);
                    }}
                    inline={{ width: "-webkit-fill-available" }}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* /No of Packages */}
          {/*  Mode of Shipment */}
          <div className="mt-5 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex justify-content-between">
                <div className="w-50">
                  <label htmlFor="packingDate" className="fw-bold">
                    Mode of Shipment
                  </label>
                </div>
                {!isEdit && (
                  <span className="table-text w-50 text-end">
                    {formContent?.modeOfShippment
                      ? formContent?.modeOfShippment
                      : "-"}
                  </span>
                )}
                {isEdit && (
                  <Input
                    id="modeOfShipment"
                    type="text"
                    label="Mode of Shipment"
                    style="full-border-field"
                    value={formContent?.modeOfShippment}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.modeOfShippment = e.target.value;
                      setFormContent(values);
                    }}
                    inline={{ width: "-webkit-fill-available" }}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* /Mode of Shipment */}
          {/*  Weight/Volume */}
          <div className="mt-5 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex justify-content-between">
                <div className="w-50">
                  <label htmlFor="packingDate" className="fw-bold">
                    Weight/Volume
                  </label>
                </div>
                {!isEdit && (
                  <span className="table-text w-50 text-end">
                    {formContent?.weightVolume
                      ? formContent?.weightVolume
                      : "-"}{" "}
                  </span>
                )}
                {isEdit && (
                  <Input
                    id="WeightVolume"
                    type="text"
                    label="Weight/Volume"
                    style="full-border-field"
                    value={formContent?.weightVolume}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.weightVolume = e.target.value;
                      setFormContent(values);
                    }}
                    inline={{ width: "-webkit-fill-available" }}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* /Weight/Volume*/}
          {/*  Truck No */}
          <div className="mt-5 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex justify-content-between">
                <div className="w-50">
                  <label htmlFor="packingDate" className="fw-bold">
                    Truck No.
                  </label>
                </div>
                {!isEdit && (
                  <span className="table-text w-50 text-end">
                    {formContent?.truckNo ? formContent?.truckNo : "-"}
                  </span>
                )}
                {isEdit && (
                  <Input
                    id="truckNo"
                    type="text"
                    label="Truck No"
                    style="full-border-field"
                    value={formContent?.truckNo}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.truckNo = e.target.value;
                      setFormContent(values);
                    }}
                    inline={{ width: "-webkit-fill-available" }}
                  />
                )}
              </div>
            </Typography>
            <hr />
          </div>
          {/* /Truck No*/}
          {/*  Container */}
          <div className="mt-5 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <div className="table-text-wrapper mb-2 d-flex justify-content-between">
                <div className="w-50">
                  <label htmlFor="packingDate" className="fw-bold">
                    Container
                  </label>
                </div>
                {!isEdit && (
                  <span className="table-text w-50 text-end">
                    {formContent?.container ? formContent?.container : "-"}
                  </span>
                )}
                {isEdit && (
                  <Input
                    id="Container"
                    type="text"
                    label="Container"
                    style="full-border-field"
                    value={formContent?.container}
                    onChange={(e) => {
                      let values = { ...formContent };
                      values.container = e.target.value;
                      setFormContent(values);
                    }}
                    inline={{ width: "-webkit-fill-available" }}
                  />
                )}
              </div>
            </Typography>
          </div>
          {/* /Container*/}
          {/* Remarks */}
          <div className="mt-5 pe-1">
            <Typography variant="body1" className="text-start" gutterBottom>
              <DynamicText
                label="Remarks:"
                textAreaLabel="Remarks..."
                isEdit={isEdit}
                value={formContent?.remarks}
                onChange={(e) => {
                  let values = { ...formContent };
                  values.remarks = e.target.value;
                  setFormContent(values);
                }}
              />
            </Typography>
          </div>
          {/* /Remakes */}

          {/* Signatures */}
          <Row className="mt-5 pt-5">
            <Col sm={6} className="mt-5 pt-5">
              <div
                style={{ height: "90px" }}
                className="d-flex justify-content-center align-items-end"
              >
                {formContent.clientSign != "" &&
                  formContent.clientSign != undefined && (
                    <img
                      src={`${Bucket}${formContent.clientSign}`}
                      height={80}
                      width={200}
                    />
                  )}
              </div>
              <p>{"_______________________________"}</p>
              <p className="my-1">
                <b>{"Customer/Authorized"}</b>
              </p>
             
              {""}
            </Col>
            <Col sm={6} className="mt-5 pt-5">
              <div
                style={{ height: "90px" }}
                className="d-flex justify-content-center align-items-end"
              >
                {formContent?.adminSign?.trim()?.length == 0 ? (
                  <>
                    {/* {isEdit && (
                      <div className="d-flex justify-content-center align-items-end">
                        <label
                          htmlFor="uploadSign"
                          className="active-btn ms-2 w-200 d-flex align-items-center justify-content-center cursor-pointer btn"
                        >
                          {uploadText}
                        </label>
                        <input
                          type="file"
                          hidden
                          id="uploadSign"
                          name="uploadSign"
                          onChange={(e) => {
                            if (!isLoading) uploadFile(e);
                          }}
                        />
                      </div>
                    )} */}
                  </>
                ) : (
                  <img
                    src={`${Bucket}${formContent.adminSign}`}
                    height={80}
                    width={200}
                  />
                )}
              </div>
              <p>{"_______________________________"}</p>
              <p className="my-1">
                <b>{"Shelozon Movers Packers & Logistics Pvt Ltd."}</b>
              </p>
             
              {""}
            </Col>
          </Row>

          {/* </MainTemplate> */}
        </div>
      ) : (
        <>
          {isLoading ? (
            <div> Loading...</div>
          ) : (
            <div> Ooops... Something went wrong...</div>
          )}
        </>
      )}

      <PrintComponent>
        <DeliveryReceiptDownload
          surveyData={surveyData}
          formContent={formContent}
        />
      </PrintComponent>

      {/* snackbar */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={
            snackbarMessage?.includes("successful") ? "success" : "error"
          }
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      {/* Add signature Modal */}
      {isSignCanvas && (
          <MySignatureCanvas
            open={isSignCanvas}
            onClose={() => {
              setSignCanvas(false);
            }}
            onSuccess={(file) => {
              if (!isLoading) uploadFile(file);
            }}
          />
        )}
      {/* /snackbar */}
    </>
  );
};

export default DeliveryReceiptClientSide;
